import React from 'react';
import {
  Animated,
  Image,
  Platform,
  View,
  StyleSheet,
  // Text,
} from 'react-native';
import { useREM, useScaledSize, } from 'react-native-web-hooks';


import Text from '../components/CustomText'
import TouchableNativeFeedback from '../components/TouchableFeedback'
import Colors  from '../constants/Colors';
import { defaultStyles } from '../constants/style';

const Modal = Platform.OS==='web'?require('modal-enhanced-react-native-web').default:require('react-native-modal').default


export function ConfirmationModal({ children, modalVisible }) {

  return (<Modal
    animationIn={'zoomIn'}
    animationOut={'zoomOut'}
    animationInTiming={500}
    animationOutTiming={500}
    backdropColor={'darkgrey'}
    backdropOpacity={0.6}
    onBackdropPress={() => this.setState({ confirmation: false, [modalVisible]: false })}
    isVisible={this.state[modalVisible]}
    onRequestClose={() => { this.setState({ confirmation: false, [modalVisible]: false }) }}>
    <View style={{
      borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
      maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
    }}>
      {children}
    </View>
  </Modal>)

}

export const ConfirmationView = ({ style, textStyle, confirmPress, rejectPress, image, title, subtitles, confirmText, rejectText }) => {

  return <View style={{width:'100%', justifyContent: 'center', alignItems: 'center' }}>
    {/* {image?<Image style={{ width: 70, height: 70, marginTop: '10%' }} source={image} />:null} */}
    <View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={[{ margin: 5, },textStyle, defaultStyles.bold]}>{title}</Text>
      {subtitles.map((subtitle, index) => <Text key={index} style={{ margin: 5, }}>{subtitle}</Text>)}
    </View>

    <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
      <View style={{ width: '40%', }}>
        <TouchableNativeFeedback onPress={confirmPress}>
          <View style={{
            justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 20, margin: 5, backgroundColor: 'rgba(126,193,61,1)'
          }}>
            <Text style={{ fontSize: 18, marginVertical: 5, fontFamily: 'Carmen Sans Bold', color: 'white', textAlign:'center' }}>{confirmText ? confirmText : 'YES'}</Text></View>
        </TouchableNativeFeedback>
      </View>
      <View style={{ width: '40%', }}>
        <TouchableNativeFeedback onPress={rejectPress}>
          <View style={{ paddingHorizontal:7,
            justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 20, margin: 5, backgroundColor: '#EE5060'
          }}>
            <Text style={{ fontSize: 18, marginVertical: 5, fontFamily: 'Carmen Sans Bold', color: 'white', textAlign:'center' }}>{rejectText ? rejectText : 'NO'}</Text></View>
        </TouchableNativeFeedback>
      </View>
    </View>
  </View>

}

export const RejectionView = ({ title, subtitles, okText, okPress }) => {


  return <View
  onkeypress={ (event) => {
    console.log('event!!!!', event)
  }} style={{ justifyContent: 'center', alignItems: 'center' }}>

    <View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{textAlign:'center', color:Colors.gray0, fontSize: 18, margin: 5, }}>{title}</Text>
      {subtitles.map((subtitle, index) => <Text key={index} style={{ fontSize: 20, margin: 5, color: '#5aca3c', }}>{subtitle}</Text>)}
    </View>

    <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
      <View style={{ width: '80%', }}>
        <TouchableNativeFeedback
        onKeyPress={ (event) => {
          console.log('event!!!!', event)
        }}
        onPress={okPress}>
          <View style={{
            justifyContent: 'center', alignItems: 'center', borderRadius: 10, margin: 5, backgroundColor: 'rgba(126,193,61,1)'
          }}>
            <Text style={{ fontSize: 19, textAlign:'center', marginVertical: 5, marginHorizontal:15, color: 'white', ...defaultStyles.bold }}>{okText ? okText : 'OK'}</Text></View>
        </TouchableNativeFeedback>
      </View>
    </View>
  </View>

}

const styles = StyleSheet.create({
  section1: {
    flex: 1,
    backgroundColor: "white",
    justifyContent: 'flex-start'
  },
});

