import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import Text from '../CustomText';

import Colors from '../../constants/Colors';
import { IS_WEB } from '../../constants/constants';
import { FONTS } from '../../constants/fonts';

export function InputImitation(props) {
    const { containerStyle, value, placeholder, onPress, title, renderValue } = props;

    const textStyle = () => {
        return value ? styles.value : styles.placeholder;
    }

    return (
        <View style={[styles.container, containerStyle]}>
            <Text style={styles.title}>{title}</Text>
            <TouchableOpacity onPress={onPress} style={[styles.input]}>
                {renderValue ? renderValue() : <Text style={textStyle()}>{value || placeholder}</Text>}
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: { width: '100%' },
    title: {
        fontFamily: FONTS.FONT_BOLD,
    },
    input: {
        backgroundColor: '#F1F1F1',
        height: 42,
        justifyContent: 'center',
        borderRadius: 20,
        paddingLeft: 15,
        marginTop: 15,
        alignItems: 'flex-start',
    },
    text: {
        color: '#282828',
    },
    value: {},
    placeholder: {
        opacity: 0.5,
    },
});
