/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Image,
    Platform,
    TouchableOpacity,
    View,
} from 'react-native';

import { shallowEqual, useSelector } from 'react-redux'

import firebase from 'firebase/app';
import 'firebase/database';

import { useDispatch } from 'react-redux'

import Text from '../CustomText'
import TouchableNativeFeedback from '../TouchableFeedback';
import ModalContainer from '../Modal/Modal';

import { getChildName } from '../../store/actions/kids/authActions';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';

import { _launchCamera, _pickImage } from '../../helpers/changeAvatar';

export const SelectImageModal = ({ imagePickerVisible, setImagePicker, onSuccess }) => {

    const parentId = useSelector(state => state.auth.parentId);
    const childId = useSelector(state => state.child.childId);

    const dispatch = useDispatch()

    const onOpenCameraPress = () => {
        setImagePicker(false)
        _launchCamera({ parentId, childId, dispatch }).then(() => { onSuccess(); setImagePicker(false) })
    }

    const onPickImagePress = () => {
        setImagePicker(false)
        _pickImage({ parentId, childId, dispatch }).then(() => { onSuccess(); setImagePicker(false) })
    }

    return <ModalContainer visible={imagePickerVisible} onClose={() => setImagePicker(false)}>
        <View>
            <Text style={[{ color: '#7ec13d' }, defaultStyles.bold, globalStyle.h2]}>Select</Text>
        </View>
        <View style={{width: '100%', alignItems: 'center' }}>
            {Platform.OS !== 'web' && <TouchableNativeFeedback
                style={Platform.OS === 'web' ? { width: '80%', alignItems: 'center' } : {width: '80%',}}
                onPress={onOpenCameraPress}>
                <View style={{  borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                    <Text style={{ color: 'white', textAlign: 'center' }}>Open Camera</Text>
                </View>
            </TouchableNativeFeedback>}
            <TouchableNativeFeedback
                style={Platform.OS === 'web' ? { width: '80%', alignItems: 'center' } : {width: '80%',}}
                onPress={onPickImagePress}>
                <View style={{ borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                    <Text style={{ color: 'white', textAlign: 'center' }}>Pick Image</Text>
                </View>
            </TouchableNativeFeedback>
        </View>
    </ModalContainer>
}
