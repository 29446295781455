export const REQUEST_GOALS = 'REQUEST_GOALS';
export const RECEIVE_GOALS = 'RECEIVE_GOALS';
export const ADD_GOAL = 'ADD_GOAL';
export const REMOVE_GOAL = 'REMOVE_GOAL';
export const ACTIVATE_GOAL = 'ACTIVATE_GOAL';

export const REQUEST_JOBS = 'REQUEST_JOBS';
export const RECEIVE_JOBS = 'RECEIVE_JOBS';
export const COMPLETE_JOB = 'COMPLETE_JOB';
export const ADD_JOB = 'ADD_JOB';
export const REMOVE_JOB_REPEAT = 'REMOVE_JOB_REPEAT';

export const REQUEST_CHEQUING = 'REQUEST_CHEQUING';
export const RECEIVE_CHEQUING = 'RECEIVE_CHEQUING';

export const REQUEST_SAVINGS = 'REQUEST_SAVINGS';
export const RECEIVE_SAVINGS = 'RECEIVE_SAVINGS';

export const REQUEST_PURCHASES = 'REQUEST_PURCHASES';
export const RECEIVE_PURCHASES = 'RECEIVE_PURCHASES';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_SIGNUP = 'USER_SIGNUP';
export const RECEIVE_CHILDREN = 'RECEIVE_CHILDREN';
export const RECEIVE_CHILD_NAME = 'RECEIVE_CHILD_NAME';
export const CREATE_CHILD = 'CREATE_CHILD';

export const USER_LOGOUT = 'USER_LOGOUT';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const REMEMBER_ME = 'REMEMBER_ME';

export const REQUEST_STORE = 'REQUEST_STORE';
export const RECEIVE_STORE = 'RECEIVE_STORE';
export const RECEIVE_FEATURED_STORE = 'RECEIVE_FEATURED_STORE';

export const RECEIVE_STORE_ITEM = 'RECEIVE_STORE_ITEM';
export const DROP_STORE_ITEM = 'DROP_STORE_ITEM';

export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const SAVE_ALLOWANCE_SETTINGS = 'SAVE_ALLOWANCE_SETTINGS';

export const CHANGE_INDEX = 'CHANGE_INDEX';

export const SAVE_PARENT_PROFILE = 'SAVE_PARENT_PROFILE';
export const SAVE_KID_DATA = 'SAVE_KID_DATA';
export const SAVE_KID_EXPENCE = 'SAVE_KID_EXPENCE';
export const CLEAR_KID_DATA = 'CLEAR_KID_DATA';

export const REMOVE_EXPENSE = 'REMOVE_EXPENSE';
export const CREATE_EXPENSE = 'CREATE_EXPENSE';

export const RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA_LOADING = 'SET_DASHBOARD_DATA_LOADING';

export const SET_TUTORIAL_VISIBILITY = 'SET_TUTORIAL_VISIBILITY';

// Dashboard
export const RECEIVE_CHILDREN_DATA = 'RECEIVE_CHILDREN_DATA';
export const RECEIVE_CHILDREN_CHEQUING_BALANCES = 'RECEIVE_CHILDREN_CHEQUING_BALANCES';
export const RECEIVE_CHILDREN_SAVINGS_BALANCES = 'RECEIVE_CHILDREN_SAVINGS_BALANCES';
