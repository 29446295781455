/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

 import React from 'react';
 import { StyleSheet, View, Platform, Image, ScrollView } from 'react-native';
 import { useDispatch } from 'react-redux';

 import { ParentHeaderCard } from '../../../components/Header/HeaderBlock';

 const HARDCODED_ACADEMY_URL = 'https://static.wixstatic.com/media/5b0236_c7a4d2f1af30406a8ba976b635bc9d7b~mv2.png';

 const AcademyCaretoryCard = (props) => {
     const { title, description, uri = HARDCODED_ACADEMY_URL } = props;

     return (<View style={styles.academyCardContainer}>
         <Image source={{ uri }} style={styles.academyImage} />
         <Text style={styles.academyCardTitle}>{title}</Text>
         <Text style={styles.academyCardDescription}>{description}</Text>
     </View>)
 }

 const CardListContainer = (props) => {
     const { getData, title, description } = props;
     const data = useSelector(getData(store));
     return (<ScrollView horizontal>
         {data.slice(0,2).map(item => <AcademyCaretoryCard key={item.title} {...item} />)}
         <AcademyCaretoryCard  />
     </ScrollView>);
 }

 export const Videos = (props) => {
     // const {  } = useSelector(store => store);
     const dispatch = useDispatch();

     useEffect(() => {
         requestAcademyVideos();
         requestAcademyQuizzes();
         requestAcademyArticles();
         // dispatch();
     }, []);

     const getAcademyVideos = (store) => store.academy.videos;
     const getAcademyQuizzes = (store) => store.academy.quizzes;
     const getAcademyArticles = (store) => store.academy.articles;

     // TODO move container to component
     return (
         <View style={styles.container}>
             <ParentHeaderCard
                 style={{}}
                 name={''}
                 // navigation={}
                 // signOut,
                 // clearAuth,
                 pressNext={() => {}}
             />
             <View style={styles.contentContainer}>
                 <CardListContainer title={'Videos'} getData={getAcademyVideos} />
                 <CardListContainer title={'Quizzes'} getData={getAcademyQuizzes} />
                 <CardListContainer title={'Articles'} getData={getAcademyArticles} />
             </View>
         </View>
     );
 }

 const styles = StyleSheet.create({
     container: {
         flex: 1,
     },
     // TODO get max width for web
     contentContainer: {
         maxWidth: 650,
     },
     academyCardContainer: {

     },
     academyImage: {
         width: 100,
         height: 100,
         borderRadius: 10,
     },
     academyCardTitle: {

     },
     academyCardDescription: {

     }
 });
