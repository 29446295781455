import React, { useEffect } from 'react';
import { View, TextInput, Platform, StyleSheet } from 'react-native';

import Text from '../CustomText';

import globalStyle, { defaultStyles } from '../../constants/style';

import Colors from '../../constants/Colors';

export const inputConvigV2 = {
    labelStyle: {marginBottom: 20, marginTop: 50, ...defaultStyles.bold, ...globalStyle.page, color: '#282828'},
    inputContainerStyle: {borderWidth: 0, width: '100%'},
    containerStyle: {width: '100%', borderWidth: 0, },
    inputStyle: { backgroundColor: '#f1f1f1', flex: 1, borderRadius: 20, paddingLeft: 20 },
    additionalSignStyle: {marginRight: 5, marginLeft: 0},
    additionalSignTextStyle: {
        ...defaultStyles.bold,
        ...globalStyle.h2,
        color: '#282828'
    },
};


export const RegularInput = ({
    label,
    additionalSign,
    inputRef,
    centered,
    labelStyle,
    inputContainerStyle,
    additionalSignStyle,
    additionalSignTextStyle,
    containerStyle,
    secureTextEntry,
    inputStyle,
    value,
    keyboardType,
    ...otherProps
}) => {
    useEffect(() => {}, []);

    return (
        <View style={[styles.container, containerStyle]}>
            <Text style={[styles.label, labelStyle]}>{label}</Text>
            <View style={[styles.inputContainer, inputContainerStyle]}>
                {additionalSign ? <View style={[styles.additionalSign, additionalSignStyle]}>
                    <Text style={[styles.additionalSignText, additionalSignTextStyle]}>
                        {additionalSign && value ? additionalSign : ''}
                    </Text>
                </View> : null}
                <TextInput
                    className={'floating-label-input'}
                    {...otherProps}
                    value={value}
                    ref={inputRef}
                    secureTextEntry={secureTextEntry}
                    keyboardType={keyboardType ? keyboardType : 'default'}
                    style={[
                        styles.input,
                        {
                            marginLeft: centered ? 0 : 1,
                            textAlign: centered ? 'center' : 'left',
                            paddingLeft: additionalSign ? 0 : 10,
                        },
                        inputStyle,
                    ]}
                    multiline={false}
                    // placeholder={label}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        zIndex: -1,
        width: '80%',
    },
    inputContainer: {
        width: '100%',
        zIndex: -1,
        marginBottom: 12,
        justifyContent: 'center',
        flexDirection: 'row',
        height: 40,
        borderWidth: 2,
        borderRadius: 20,
        borderColor: Colors.gray,
    },
    input: {
        ...(Platform.OS === 'web' ? { outlineWidth: 0 } : {}),
        height: 40,
        width: '100%',
        fontFamily: 'Carmen Sans Regular',
        // fontSize: 20,
        color: Colors.gray1,
        // paddingRight: 25,
    },
    additionalSign: {
        justifyContent: 'center',
        marginLeft: Platform.OS === 'web' ? 20 : 40,
    },
    additionalSignText: {
        // color: '#7ec13d',
        color: Colors.gray1,
        fontSize: 20,
        marginTop: 4,
    },
    label: {
        color: Colors.gray1,
        marginTop: 2,
        fontSize: 14,
    },
});
