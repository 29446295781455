// import { appConstants } from "";
import * as actionTypes from '../../actions/kids/actionTypes';
import { filterSavings } from '../../../helpers/savings/filterSavings';

const initialState = {
    isLoading: true,
    data: [],
    type: 0,
    savings_deposit: [],
    savings_withdrawal: [],
    chequing_balance: 0,
    savings_deposit_display: 'none',
    savings_withdrawal_display: 'none',
    deposit_savings: 0,
    deposit_savings_trans: 0,
    withdraw_savings: 0,
    withdraw_savings_trans: 0,
    total_savings: 0,
    total_savings_trans: 0,
    withdrawals_arr: [],
    withdrawals: 0,
    withdrawals_trans: 0,
    withdrawals_display: 'none',
    deposits_arr: [],
    deposits: 0,
    deposits_trans: 0,
    deposits_display: 'none',
    interest_rate: 0,
    interest: 0,
    savings_fees: [],
    savings_fees_amount: 0,
    savings_fees_trans: 0,
    transferChequingVisible: false,
    transferSavingVisible: false,
    visible: false,
};

export default function chat(state = initialState, action) {
    let values = {};

    switch (action.type) {
        case actionTypes.REQUEST_SAVINGS:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.RECEIVE_SAVINGS:
            values = filterSavings(action.payload.data);

            return {
                ...state,
                isLoading: false,
                savings_balance: action.payload.savings_balance,
                interest_rate: action.payload.interest_rate,
                ...values,
            };

        default:
            return state;
    }
}
