import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    View,
    Text,
    TouchableWithoutFeedback,
    TouchableOpacity,
    findNodeHandle,
    InteractionManager,
    StyleSheet,
    StatusBar,
    Dimensions,
} from 'react-native';

// import MaskedView from '@react-native-community/masked-view';
import Modal from 'react-native-modal';
import { Svg, Defs, Rect, Mask, Circle, ClipPath, G, Path } from 'react-native-svg';
import { useDispatch, useSelector } from 'react-redux';

import { setTutorialVisibility } from '../../store/actions/parents/authActions';

import Colors from '../../constants/Colors';
import { IS_WEB } from '../../constants/constants';

const BLOCK_PADDING = 10;

const modalProps = {
    animationIn: 'zoomIn',
    animationOut: 'zoomOut',
    animationInTiming: 500,
    animationOutTiming: 500,
    backdropColor: 'darkgrey',
    backdropOpacity: 0.6,
    useNativeDriver: true,
    useNativeDriverForBackdrop: true,
};

const { width: windowWidth, height: windowHeight } = Dimensions.get('window');

export const useAppTour = ({ tooltipText }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [layout, setLayout] = useState({});
    const componentRef = useRef();

    // TODO move to separate method
    const showTutorial = useSelector((store) => store.parent.showTutorial);

    const dispatch = useDispatch();

    // TODO check for re-renders
    // console.log('showTutorial - ', showTutorial);
    // const { onLayout, ...layout } = useLayout();

    const { width, height, top, left, x, y, pageX, pageY } = layout;

    useEffect(() => {
        // TODO check for re-renders
        // console.log('layout APP TOUR 1 - ', layout);

        // console.log('componentRef ', Object.keys(componentRef.current), componentRef.current.viewConfig.style);

        const t = findNodeHandle(componentRef.current);

        console.log('FIND NODE HANDLE - ', t.toString(), typeof t);

        // TODO check it can be improved
        // InteractionManager.runAfterInteractions(() => {
        //     setModalVisible(true);
        // });
    }, [componentRef, layout]);

    const onFreeAreaPress = () => {
        console.log('BBBBBBBBBBBBB');
        setModalVisible(false);
    };

    const onLayoutMeasure = useCallback((event) => {
        // x, y, width, height, pageX, pageY
        const target = IS_WEB ? event.nativeEvent.target : event.target;

        setModalVisible(true);

        target.measure((x, y, width, height, pageX, pageY) => {
            console.log('MEASURE LAYOUT - ', x, y, width, height, pageX, pageY);

            setLayout((layout) => ({ ...layout, x, y, width, height, pageX, pageY }));
        });
    }, []);

    const closeModal = () => {
        dispatch(setTutorialVisibility(false));
        setModalVisible(false);
    };

    const renderTooltip = useCallback(
        (text) => {
            const spaceFromTop = pageY;
            const spaceFromBottom = windowHeight - pageY - height;
            const tooltipStyle = {
                position: 'absolute',
                backgroundColor: 'white',
                left: pageX,
                padding: 20,
                // marginVertical: 15,
            };

            if (spaceFromTop > spaceFromBottom) {
                tooltipStyle.bottom = windowHeight - pageY - height;
            } else {
                tooltipStyle.top = spaceFromTop + height + 10;
            }

            return (
                <View style={tooltipStyle}>
                    <Text>{text}</Text>
                </View>
            );
        },
        [height, pageX, pageY]
    );

    const renderHighlightBlock = useCallback(
        ({ style }) => {
            return (
                <View
                    needsOffscreenAlphaCompositing={true}
                    pointerEvents={'box-none'}
                    style={[
                        styles.highlightBlock,
                        {
                            width: windowWidth,
                            height: windowHeight,
                            // left: (pageX ?? 0) - BLOCK_PADDING,
                            // top: (pageY ?? 0) - (!IS_WEB ? StatusBar.currentHeight : 0),
                            // height: (height ?? 0),
                            // width: (width ?? 0) + 2 * BLOCK_PADDING,
                            // borderTopRightRadius: 20,
                            borderLeftWidth: pageX,
                            borderBottomWidth: windowHeight - pageY - height,
                            borderTopWidth: pageY,
                            borderRightWidth: windowWidth - pageX - width,
                        },
                        style,
                    ]}
                >
                    {/* <Svg width={200} height={200} viewBox="0 0 200 200">
                        <Path
                            d="M200,200 L0,200 L0,0 C0,110.45695 89.54305,200 200,200 Z"
                            fill={'red'}
                        />
                    </Svg> */}
                </View>
            );
        },
        [pageX, pageY, height, width]
    );

    const AppTourModal = useCallback(
        ({ blockStyle }) => (
            <Modal
                needsOffscreenAlphaCompositing={true}
                pointerEvents={'none'}
                style={{ margin: 0 }}
                {...modalProps}
                backdropColor={'white'}
                backdropOpacity={0}
                // isVisible={showTutorial && modalVisible}
                isVisible={true}
                onBackdropPress={closeModal}
                onRequestClose={closeModal}
            >
                {renderHighlightBlock({ style: blockStyle })}
                {renderTooltip(tooltipText)}
            </Modal>
        ),
        [
            closeModal,
            modalVisible,
            renderHighlightBlock,
            renderTooltip,
            showTutorial,
            tooltipText,
        ]
    );

    return {
        componentRef,
        onLayout: onLayoutMeasure,
        AppTourModal,
    };
};

const styles = StyleSheet.create({
    highlightBlock: {
        position: 'absolute',
        backgroundColor: 'transparent',
        // opacity: 0.1,
        borderWidth: 4,
        // borderRadius: 10,
        borderColor: `${Colors.gray1}${80}`,
        // padding: 10,
    },
});

// SVG Mask
// return (
//     <Svg height={'100%'} width="100%">
//         {/* <Rect height={height} width={width} x={pageX} y={pageY} fill="green" /> */}
//         <Defs>
//             <Mask id="mask" x={pageX} y="0" height={'100%'} width="100%">
//                 {/* <Rect height="100%" width="100%" fill="#fff" /> */}
//                 <Rect height={height} width={width} x={pageX} y={pageY} fill="#fff" />
//                 <Circle r="45" cx="50" cy="50" strokeWidth={2} />
//             </Mask>
//         </Defs>
//         <Rect height={'100%'} width="100%" fill="rgba(0, 0, 0, 0.5)" mask="url(#mask)" fill-opacity="0" />
//     </Svg>
// )
// Generic View
// return (
//     <View
//         needsOffscreenAlphaCompositing={true}
//         pointerEvents={'box-none'}
//         style={[
//             styles.highlightBlock,
//             {
//                 left: (pageX ?? 0) - BLOCK_PADDING,
//                 top: (pageY ?? 0) - (!IS_WEB ? StatusBar.currentHeight : 0),
//                 height: (height ?? 0),
//                 width: (width ?? 0) + 2 * BLOCK_PADDING,
//             },
//             style,
//         ]}
//     >
//         {/* <MaskedView
//             style={{ flex: 1, flexDirection: 'row', height: '100%' }}
//             maskElement={
//             <View
//                 style={{
//                 // Transparent background because mask is based off alpha channel.
//                 backgroundColor: 'transparent',
//                 flex: 1,
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 }}
//             >
//                 <Text
//                 style={{
//                     fontSize: 60,
//                     color: 'black',
//                     fontWeight: 'bold',
//                 }}
//                 >
//                 Basic Mask
//                 </Text>
//             </View>
//             }
//         >
//             <View style={{ flex: 1, height: '100%', backgroundColor: '#324376' }} />
//             <View style={{ flex: 1, height: '100%', backgroundColor: '#F5DD90' }} />
//             <View style={{ flex: 1, height: '100%', backgroundColor: '#F76C5E' }} />
//             <View style={{ flex: 1, height: '100%', backgroundColor: '#e1e1e1' }} />
//         </MaskedView> */}
//         <View
//             needsOffscreenAlphaCompositing={true}
//             style={{
//                 backgroundColor: 'transparent',
//                 height: 20,
//                 width: 20,
//             }}
//         ></View>
//     </View>
// );
