/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, Platform, Image, TouchableOpacity, ScrollView } from 'react-native';
import { useDispatch } from 'react-redux';

import { ParentHeaderCard } from '../../../components/Header/HeaderBlock';
import { IS_WEB } from '../../../constants/constants';
import { VideoView } from '../../../components/Video/Video';
import { AcademyHeader } from '../../../components/Academy/AcademyHeader';

import Colors from '../../../constants/Colors';

// const Video = IS_WEB ? require('../../../components/Video/Video.web.js') : require('../../../components/Video/Video.js').default;
// const Video = {};

const HARDCODED_ACADEMY_URL = 'https://static.wixstatic.com/media/5b0236_c7a4d2f1af30406a8ba976b635bc9d7b~mv2.png';

const AcademyCaretoryCard = (props) => {
    const { title, description, uri = HARDCODED_ACADEMY_URL } = props;

    return (<View style={styles.academyCardContainer}>
        <Image source={{ uri }} style={styles.academyImage} />
        <Text style={styles.academyCardTitle}>{title}</Text>
        <Text style={styles.academyCardDescription}>{description}</Text>
    </View>)
}

const CardListContainer = (props) => {
    const { getData, title, description } = props;
    const data = useSelector(getData(store));
    return (<ScrollView horizontal>

        {data.slice(0,2).map(item => <AcademyCaretoryCard key={item.title} {...item} />)}
        <AcademyCaretoryCard  />
    </ScrollView>);
}

export const VideoScreen = (props) => {
    const { route } = props;

    const [videoUrl, setVideoUrl] = useState('');
    const { title, url, id } = props.navigation.state.params;

    useEffect(() => {
        console.log('res: ', id)

        fetch(`https://player.vimeo.com/video/${id}/config`, {
          mode: 'no-cors',
        })
      .then(res => {
          console.log('prev reds; ', res);
          return res.json()})
      .then(res => {
          console.log('res: ', res)
        // console.log('res - ', res.request.files.hls.cdns[res.request.files.hls.default_cdn].url);
        // setVideoUrl(res.request.files.hls.cdns[res.request.files.hls.default_cdn].url);
    }
    )
    .catch(err => console.log('ERR; ', err))
    })
    // console.log('VIDEO screen - ', route, props);

    // TODO move container to component
    return (
        <View style={styles.container}>
                <AcademyHeader />

            <View style={styles.contentContainer}>
            <VideoView

                source={{uri: url}}   // Can be a URL or a local file.
                                   // Store reference
                // onBuffer={this.onBuffer}                // Callback when remote video is buffering
                // onError={this.videoError}               // Callback when video cannot be loaded
                style={styles.backgroundVideo}
            />
            {/* <View style={{ flexDirection: 'row', justifyContent: 'center'}}>
                <TouchableOpacity style={{padding: 20, margin: 30, borderRadius: 10, backgroundColor: Colors.green1}}>
                    <Text style={{color: Colors.white}}>{'<'}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{padding: 20, margin: 30, borderRadius: 10, backgroundColor: Colors.green1}}>
                    <Text style={{color: Colors.white}}>{'||'}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{padding: 20, margin: 30, borderRadius: 10, backgroundColor: Colors.green1}}>
                    <Text style={{color: Colors.white}}>{'>'}</Text>
                </TouchableOpacity>
            </View> */}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',

    },
    // TODO get max width for web
    contentContainer: {
        maxWidth: 650,
        // borderWidth: 1,
        width: '100%',
        alignItems: 'center',
    },
    academyCardContainer: {

    },
    academyImage: {
        width: 100,
        height: 100,
        borderRadius: 10,
    },
    academyCardTitle: {

    },
    academyCardDescription: {

    },
    backgroundVideo: {

    }
});
