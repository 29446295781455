/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { Image, StyleSheet, TextInput, View } from 'react-native';

import TouchableNativeFeedback from '../TouchableFeedback';

import { IS_WEB } from '../../constants/constants';
import Colors from '../../constants/Colors';

export function Search({ value, onChange, onSubmitEditing }) {
    return (
        <View style={styles.container}>
            <TouchableNativeFeedback onPress={onSubmitEditing}>
                <Image
                    style={styles.searchImage}
                    source={require('assets/Search.png')}
                />
            </TouchableNativeFeedback>
            <TextInput
                onChangeText={onChange}
                // onChange={text => { console.log('text ', text); onChange(text.target.value)} }
                onSubmitEditing={onSubmitEditing}
                value={value}
                style={[styles.searchInput]}
                multiline={false}
                placeholderTextColor={Colors.black}
                placeholder={'Search Item'}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '70%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchImage: { height: 20, width: 20 },
    searchInput: {
        justifyContent: 'center',
        paddingLeft: 10,
        maxWidth: 650,
        height: 40,
        width: '100%',
        ...(IS_WEB && { outlineWidth: 0, outlineStyle: 'none' }),
        fontFamily: 'Carmen Sans Regular',
        color: Colors.black,
        // borderWidth: 3, borderRadius: 20, borderColor: Colors.gray,
    },
});
