/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    Animated,
    Image,
    View,
    Platform,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';

import moment from 'moment';

import Text from '../../components/CustomText';

import TouchableNativeFeedback from '../../components/TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';
import { formatNumberFloat } from '../../helpers/formatNumber';

const sortCategories = {
    new: 'Most popular',
    priceLow: 'Price Low to High',
    priceHigh: 'Price High To Low',
};
const categories = [
    'Toys',
    'Beauty',
    'Electronics',
    'Sports',
    'Activities',
    'Arts & Crafts',
    'Food',
    'Accessories',
    'Donations',
    'Other',
];

const PriceUpdated = ({ updated }) => {
    return (
        <Text style={styles.priceUpdatedText}>
            Price as of{' '}
            {updated
                ? moment(updated).format('MMM DD, YY')
                : moment().format('MMM DD, YY')}{' '}
            {'\n'}Excludes tax and shipping fees.
        </Text>
    );
};

const BuyButton = ({ onPress }) => {
    return (
        <TouchableNativeFeedback
            style={styles.buyButton}
            onPress={() => onPress()}
        >
            <Text>Buy</Text>
        </TouchableNativeFeedback>
    );
};

const DropButton = ({ onPress, active, title, color, buttonTitle }) => {
    const isHovered = false;
    const size = 100;

    return (
        <TouchableNativeFeedback
            // -size / 1.8
            style={{right: -size / 3, top: -size / 2.4,
                position: 'absolute',
                zIndex: 1,
            }}
            onPress={() => onPress(title)}
        >
            <View
                style={{
                    zIndex: 101,
                    height: size,
                    width: size,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: size / 2,
                    backgroundColor: color || Colors.green1,
                    transform: [{ scale: isHovered ? 1.1 : 1 }],
                }}
            >
                <Text
                    style={[
                        {
                            color: 'white',
                            ...(Platform.OS === 'web'
                                ? {
                                      zIndex: 192,
                                      position: 'absolute',
                                      right: size / 1.9,
                                      top: size / 2,
                                  }
                                : { right: size / 6, top: size / 5 }),
                            // borderWidth: 1,
                            // position: 'absolute',
                            // ...buttonTitle ? { left: size / 5, top: size / 2.4 } : { left: size / 5, top: size / 2.3 }
                        },
                        globalStyles.h1,
                    ]}
                >
                    {buttonTitle || '+'}
                </Text>
            </View>
        </TouchableNativeFeedback>
    );
};

const Rating = ({ rating, vmin }) => {
    const sizem = 125;
    const size = 120;
    // rating = rating > 2 ? Math.round(rating) : 3;
    rating = Math.round(rating);

    return (
        <View
            style={{
                flex: 0.5,
                width: '100%',
                minWidth: size,
                // minWidth: sizem, zIndex: -1, width: size, maxWidth: sizem,
                height: 30,
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            {[...new Array(5)].map((item, index) => (
                <Image
                    key={index}
                    resizeMode={'contain'}
                    style={{
                        flex: 1,
                        width: '15%',
                        height: '100%',
                        opacity: index < rating ? 1 : 0,
                    }}
                    source={require('assets/Pennygem_Logo.png')}
                />
            ))}
        </View>
    );
};

function RenderShopCard({
    layout,
    item,
    auth,
    navigation,
    infoBlock,
    bottomBlock,
    children,
}) {
    const [collapse, setCollapse] = useState(true);

    const updated = item.updated?.value;
    const blockWidth = layout.width > 1300 ? layout.width * 0.3 : '90%';
    const vmin = Math.min(...[layout.width, layout.height]);
    const margin = vmin * 0.02;

    return item ? (
        <TouchableNativeFeedback
            // style={[Platform.OS === 'ios' && defaultStyles.shadow, { zIndex: -1 }]}
            disabled={auth.type === 1}
            onPress={() => {
                item.new
                    ? null
                    : navigation.navigate('StoreItem', {
                          api: null,
                          store: item.merchantname || 'Amazon',
                          index: item.key,
                      });
            }}
        >
            <View
                // renderToHardwareTextureAndroid
                style={[
                    styles.cardContainer,
                    {
                        marginHorizontal: 0.05 * vmin,
                        marginBottom: 0.05 * vmin,
                        width: blockWidth,
                    },
                    defaultStyles.shadow,
                ]}
            >
                {children}
                <View
                    style={{
                        backgroundColor: 'white',
                        width: '100%',
                        flexDirection: 'row',
                        flex: 1,
                        alignItems: 'flex-start',
                    }}
                >
                    <View
                        style={{
                            flex: 0.4,
                            height: '90%',
                            paddingRight: 15,
                            paddingLeft: 5,
                            justifyContent: 'center',
                        }}
                    >
                        <Image
                            resizeMode={'contain'}
                            style={{
                                width: '90%',
                                height: '100%',
                                alignSelf: 'center',
                                minWidth: 150,
                                minHeight: 125,
                                // maxWidth: 125, maxHeight: 125,
                            }}
                            source={{ uri: item ? item.image : null }}
                        />
                    </View>
                    <View style={{ flex: 0.6, marginLeft: margin }}>
                        <View>
                            <Text
                                style={[
                                    { color: Colors.green1 },
                                    defaultStyles.bold,
                                    globalStyles.h3,
                                ]}
                            >
                                ${formatNumberFloat(item.price)}
                            </Text>
                        </View>
                        {auth.type === 0 && !item.new ? (
                            <View
                                style={{
                                    marginTop: margin,
                                    width: '80%',
                                    flexDirection: 'row',
                                }}
                            >
                                <Text style={{ marginRight: margin }}>
                                    <Text
                                        style={[
                                            { color: Colors.green1 },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        {item.parents || 1}
                                    </Text>{' '}
                                    parents
                                </Text>
                                <Text>
                                    <Text
                                        style={[
                                            { color: Colors.green1 },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        {item.kids || 1}
                                    </Text>{' '}
                                    kids
                                </Text>
                            </View>
                        ) : null}
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={3}
                                ellipsizeMode={'tail'}
                                style={[
                                    { width: '80%', marginVertical: margin },
                                    defaultStyles.bold,
                                ]}
                            >
                                {item.title}
                            </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text
                                numberOfLines={3}
                                ellipsizeMode={'tail'}
                                style={{ marginBottom: margin }}
                            >
                                {item.description}
                            </Text>
                        </View>
                        {infoBlock}
                    </View>
                </View>

                <View
                    style={{
                        backgroundColor: 'white',
                        width: '100%',
                        marginTop: 15,
                        flexDirection: 'row',
                    }}
                >
                    <Rating rating={item.rating} />
                    {<View style={{ flex: 1 }}>{bottomBlock}</View>}
                </View>
            </View>
        </TouchableNativeFeedback>
    ) : null;
}

const RenderChildCard = ({ layout, item, auth, navigation, onGoal, onBuy }) => {
    return (
        <RenderShopCard
            layout={layout}
            item={item}
            auth={auth}
            navigation={navigation}
            infoBlock={<PriceUpdated updated={item.updated} />}
            bottomBlock={<BuyButton onPress={onBuy} />}
        >
            <DropButton
                title={item.title}
                onPress={() => {
                    onGoal();
                }}
            />
        </RenderShopCard>
    );
};

const RenderParentCard = ({
    layout,
    item,
    auth,
    navigation,
    addNew,
    onPress,
}) => {
    // {auth.type !== 0 && <Text style={{}}>Approved by <Text style={{ fontFamily: 'Carmen Sans Bold' }}>{item.parents || 1}</Text> {item.parents > 1 ? 'parents' : 'parent'}</Text>}

    return (
        <RenderShopCard
            layout={layout}
            item={item}
            auth={auth}
            navigation={navigation}
        >
            {auth.type === 0 && !item.new && (
                <DropButton
                    title={item.title}
                    onPress={() => {
                        onPress(item.title);
                    }}
                />
            )}
            {auth.type === 0 && item.new && (
                <DropButton
                    title={item.title}
                    color={Colors.yellow1}
                    onPress={() => {
                        addNew(item.title);
                    }}
                />
            )}
        </RenderShopCard>
    );
};

const RenderParentStoreChildCard = ({
    layout,
    item,
    auth,
    navigation,
    onPress,
}) => {
    return (
        <RenderShopCard
            layout={layout}
            item={item}
            auth={auth}
            navigation={navigation}
            infoBlock={<PriceUpdated updated={item.updated} />}
            bottomBlock={null}
        >
            <DropButton
                color={`${Colors.rose}80`}
                title={item.title}
                buttonTitle={'-'}
                onPress={() => {
                    onPress();
                }}
            />
        </RenderShopCard>
    );
};

const styles = StyleSheet.create({
    cardContainer: {
        // ...Platform.OS === 'android' ? { borderWidth: 0.2 } : defaultStyles.shadow,
        borderWidth: 0.2,
        borderColor: Colors.gray,
        flex: 1,
        zIndex: 10,
        overflow: 'hidden',
        backgroundColor: 'white',
        justifyContent: 'space-between',
        padding: 15,
        borderRadius: 25,
        alignSelf: 'center',
        // backgroundColor: 'rgba(255,255,255,0.9)',
    },
    buyButton: {
        flex: 0.5,
        minWidth: 100,
        maxWidth: 150,
        borderColor: Colors.gray,
        borderWidth: 3,
        borderRadius: 25,
        paddingHorizontal: 20,
        paddingVertical: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    priceUpdatedText: { marginTop: 5, color: Colors.gray0, fontSize: 12 },
});

export const ParentStoreCard = RenderParentCard;

export const ChildStoreCard = RenderChildCard;

export const ParentStoreChildCard = RenderParentStoreChildCard;
