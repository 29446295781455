/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Platform, StyleSheet, View, Image, TouchableOpacity } from 'react-native';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import Text from '../CustomText';

import textStyles, { defaultStyles } from '../../constants/style';


import WithdrawalIcon from 'assets/dashboard/WithdrawalIcon.png';
import AddJobIcon from 'assets/dashboard/WithdrawalIcon.png';
import DepositIcon from 'assets/dashboard/WithdrawalIcon.png';
import { FONTS } from '../../constants/fonts';
import Colors from '../../constants/Colors';
import { formatNumberFloat } from '../../helpers/formatNumber';
import { Avatar } from '../Avatar';
import { IS_WEB, SCREEN_WIDTH } from '../../constants/constants';
import { customModalRef } from '../Modal/CustomModal';
import TransferBlock from '../chequing/TransferBlock';
import { deposit, withdrawal } from '../../helpers/parentTransfer';
import { sendNotification } from '../../helpers/notifications/notifications';

// const WithdrawalIcon = require('assets/dashboard/WithdrawalIcon.png');

const cardButtons = [
    { title: 'Deposite', source: DepositIcon },
    { title: 'Withdrawal', source: WithdrawalIcon },
    { title: 'Add Job', source: AddJobIcon },
];

const avatars = [
    // { index: 0, source: require('assets/Avatars/icon_1.png') },
    { index: 1, source: require('assets/Avatars/icon_2.png') },
    { index: 2, source: require('assets/Avatars/icon_3.png') },
    { index: 3, source: require('assets/Avatars/icon_4.png') },
    { index: 4, source: require('assets/Avatars/icon_5.png') },
];

const maxWidth = 700;
const widthThreshold = 600;

const transferElements = {
    deposit: {
        transferFn: deposit,
        title: 'Deposit to Chequing',
        label: 'Amount to Deposit',
        notificationTitle: 'Money deposit',
        notificationBody: 'Money was deposited by parent',
        notificationAction: 'parent_deposit',
    },
    withdrawal: {
        transferFn: withdrawal,
        title: 'Withdraw from Chequing',
        label: 'Amount to Withdraw',

        notificationTitle: 'Money withdrawal',
        notificationBody: 'Money was withdrawn by parent',
        notificationAction: 'parent_withdraw',
    },
};

function ChildCardButton(props) {
    const { source, title, onPress } = props;

    return (
        <TouchableOpacity onPress={onPress} style={styles.buttonContainer}>
            <Image source={source} style={styles.buttonImage} />
            <Text>{title}</Text>
        </TouchableOpacity>
    );

}

function ChildDashboardCard(props) {
    const { childId, onNewJobPress, onPress, actions } = props;
    const parentId = useSelector(store => store.auth.parentId);
    const name = useSelector(store => store.parent.names[childId] || '');
    const balance = useSelector(store => store.chequing[childId]?.balance);
    const avatar = useSelector(store => store.parent.settings[childId]?.avatar);
    const uri = useSelector(store => store.parent.settings[childId]?.uri);

    // type = deposit|withdrawal
    const onTransferCompleteButtonPress = ({type, transfer}) => {
        const {transferFn, notificationTitle, notificationBody, notificationAction} = transferElements[type];

        transferFn({
            transfer,
            parentId,
            childId,
        })
        .then(() => {
            sendNotification({
                type: 'child',
                title: notificationTitle,
                id: childId,
                body: notificationBody,
                data: {
                    action: notificationAction,
                },
            });

            actions.requestChildrenChequingBalances({ parentId, childIds: [childId] });
            actions.getDashboardData({ parentId, childId });
        })
        .finally(() => customModalRef.current.hide());
    }

    const onTransferPress = (type) => {
        const {title, label} = transferElements[type];

        customModalRef.current.show(() => <TransferBlock
        type={type}
        title={title}
        label={label}
        onPress={(transfer) => {
            onTransferCompleteButtonPress({type, transfer})
        }}
    />);
    }

    const onButtonPress = (index) => {
        switch (index) {
            case 0:
                return () => onTransferPress('deposit');
            case 1:
                return () => onTransferPress('withdrawal');
            case 2:
                return onNewJobPress;
            default:
                return () => null;
        }
    }

    return (
        <TouchableOpacity style={styles.container} onPress={onPress}>
            <View style={{width: '100%', }}>
            <View style={styles.infoRow}>
                <Avatar
                    uri={uri}
                    margin={0}
                    index={avatar}
                    // index={0}
                    containerStyle={{}}
                />
                {/* <Image source={getAvatarImage(avatar)} style={styles.avatar} /> */}
                <View style={styles.nameContainer}>
                    <Text numberOfLines={1} style={styles.name}>{name}</Text>
                </View>
                <View style={styles.balanceContainer}>
                    <Text style={styles.balanceValue}>${formatNumberFloat(balance)}</Text>
                    <Text>Balance</Text>
                </View>
            </View>
            <View style={styles.buttonsRow}>
                {cardButtons.map((item, index) => <ChildCardButton key={item.title} title={item.title} source={item.source} onPress={onButtonPress(index)} />)}
            </View>
            </View>
        </TouchableOpacity>
    );

}

export { ChildDashboardCard };

const styles = StyleSheet.create({
    container: {
        // sfwef
        ...defaultStyles.shadow,
        width: IS_WEB ? SCREEN_WIDTH*0.9 : '90%',
        // borderWidth: 1,
        // flex: 1,
        backgroundColor: Colors.white,
        marginVertical: 10,
        padding: 25,
        borderRadius: 30,
        maxWidth: 600,
        // borderWidth: 1,
        // borderWidth: 1,
    },
    infoRow: {
        // backgroundColor: 'white',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonsRow: {
        // width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    avatar: {
        height: 80,
        width: 80,
        borderRadius: 80,
    },
    nameContainer: {
        flex: 1,
        marginLeft: 10,
    },
    name: {
        ...textStyles.h3,
        // fontWeight: '500',
        fontFamily: FONTS.FONT_BOLD,
    },
    buttonContainer: {
        alignItems: 'center',
    },
    buttonImage: {
        width: 30,
        height: 20,
    },
    balanceContainer: {
        alignItems: 'center',
    },
    balanceValue: {
        ...textStyles.h2,
        ...defaultStyles.bold,
        color: Colors.green1,
    }
});
