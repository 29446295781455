/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useCallback, useEffect } from 'react';
import { StyleSheet, View, Text, Platform, TouchableOpacity, Image, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { withNavigation } from 'react-navigation';
import WebView from 'react-native-webview';

import { ParentHeaderCard } from '../../../components/Header/HeaderBlock';
import Colors from '../../../constants/Colors';
import { ROUTE_NAMES } from '../../../navigation/navigationConstants';
import { AcademyHeader } from '../../../components/Academy/AcademyHeader';

import { IS_WEB, SCREEN_WIDTH } from '../../../constants/constants';
import globalStyles, { defaultStyles } from '../../../constants/style';
import { getVideoThumbnail } from '../../../helpers/academy/videoThumbnails';
// import { getArticle } from '../../../helpers/academy/articles';


import { requestAcademyArticle } from '../../../requests/academy/AcademyDataRequests';

const HARDCODED_ACADEMY_URL = 'https://static.wixstatic.com/media/5b0236_c7a4d2f1af30406a8ba976b635bc9d7b~mv2.png';

const AcademyCaretoryCard = withNavigation((props) => {
    const { title, description, url, image, navigation } = props;
    const uri = image || HARDCODED_ACADEMY_URL;

    const onCardPress = () => {
        navigation.navigate(props.route, {
            title, description, url
        });
    }

    return (<TouchableOpacity onPress={onCardPress} style={styles.academyCardContainer}>
        <Image source={{ uri }} style={styles.academyImage} />
        <Text style={styles.academyCardTitle}>{title}</Text>
        <Text style={styles.academyCardDescription}>{description}</Text>
    </TouchableOpacity>)
})

export const Article = (props) => {
    // const vi
    // const {  } = useSelector(store => store);
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        requestAcademyArticle('FinancialLiteracy').then((resp) => {
            // console.log('article resp - ', resp.split('\n').join('<br><br>'));
            setText(resp.split('\n').join('<br>'));
        });
        // dispatch();
    }, []);

    console.log('text - ', Boolean(text), text.length);
    const renderHtml = useCallback(() => {
        // return text;
        return (
          '<!DOCTYPE html><html>'
        //   + HTML_HEAD
        + `<body><div id="bannerText" style="font-family: 'Helvetica Neue';
            font-size: 38px;
            text-align: justify; padding: 0 10px 10px">${text}</div></body>`
        + '</html>'
        );
      }, [text]);

      renderWebHTML = () => {
          return <div style={{margin: 20}} dangerouslySetInnerHTML={{ __html: text }} />;
      }

    // TODO move container to component
    return (
        <View style={styles.container} contentContainerStyle={styles.contentContainer}>
                <AcademyHeader />


            {IS_WEB ? renderWebHTML() : <WebView
            borderWidth={{}}
                containerStyle={{ flex: 1 }}
                scrollIndicatorInsets={{ right: 1 }}
                source={{ html: renderHtml() }}
                style={{ borderWidth: 1, flex: 1 }}
            />}
        </View>
    );
}

const MAX_BLOCK_WIDTH = Math.min(SCREEN_WIDTH, 700);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // height: '100%',
        // borderWidth: 1, color: 'red',
        // borderWidth: 1,
        backgroundColor: Colors.white,
    },
    // TODO get max width for web
    contentContainer: {
        // flex: 1,
        // maxWidth: 650,
    },
    academyCardContainer: {
        ...defaultStyles.shadow,
        width: MAX_BLOCK_WIDTH / 2,
        height: 180,
        margin:40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
    },
    academyImage: {
        width: '80%',
        height: 120,
        borderRadius: 10,
    },
    academyCardTitle: {
        color: Colors.black,
        fontWeight: 'bold',
        marginVertical: 10,
    },
    academyCardDescription: {
        color: Colors.green3,
    },
    titleContainer: {
        width: '100%',
        alignItems: 'center',
    },
    title: {
        ...globalStyles.h1,
        color: Colors.green1,
    }
});
