// import { appConstants } from "";
import { filterChequing } from '../../../helpers/chequing/filterChequing';

import * as actionTypes from '../../actions/parents/actionTypes';

// TODO other state structure
const initialState = {
    isLoading: true,
    data: [],
    type: 0,
    movement: 0,
    allowance: [],
    job: [],
    deposit: [],
    online_purchase: [],
    credit_purchase: [],
    savings_deposit: [],
    savings_withdrawal: [],
    chequing_balance: 0,
    job_display: 'none',
    allowance_display: 'none',
    deposit_display: 'none',
    online_purchase_display: 'none',
    credit_purchase_display: 'none',
    savings_deposit_display: 'none',
    savings_withdrawal_display: 'none',
    interval: new Date(),
    income: 0,
    income_trans: 0,
    expense: 0,
    expense_trans: 0,
    saved: 0,
    saved_trans: 0,
    job_income: 0,
    allowance_income: 0,
    allowance_income_trans: 0,
    deposit_income: 0,
    deposit_income_trans: 0,
    online_expense: 0,
    online_expense_trans: 0,
    credit_expense: 0,
    credit_expense_trans: 0,
    deposit_savings: 0,
    deposit_savings_trans: 0,
    withdraw_savings: 0,
    withdraw_savings_trans: 0,
    total_savings: 0,
    total_savings_trans: 0,
    withdrawals_arr: [],
    withdrawals: 0,
    withdrawals_trans: 0,
    withdrawals_display: 'none',
    deposits_arr: [],
    deposits: 0,
    deposits_trans: 0,
    fees: 0,
    fees_data: [],
    fees_trans: 0,
    deposits_display: 'none',
    interest_rate: 0,
    transferChequingVisible: false,
    transferSavingVisible: false,
    visible: false,
    intervals: [false, true, false, false],
    email: '',
    transfer: '',
};

export default function chat(state = initialState, action) {
    let values = {};

    switch (action.type) {
        case actionTypes.REQUEST_CHEQUING:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.RECEIVE_CHEQUING:
            values = filterChequing(action.payload.data);

            return {
                ...state,
                isLoading: false,
                chequing_balance: action.payload.chequing_balance,
                min_balance: action.payload.min_balance,
                [action.payload.childId]: {
                    ...values,
                    chequing_balance: action.payload.chequing_balance,
                    min_balance: action.payload.min_balance,
                },
            };
        
        case actionTypes.RECEIVE_CHILDREN_CHEQUING_BALANCES:
            const { childIds, data } = action.payload;
            childIds.forEach((childId, index) => {
                state[childId] = { ...state[childId], balance: data[index] };
            });

            return { ...state };

        default:
            return state;
    }
}
