// import { appConstants } from "";
import * as actionTypes from '../../actions/parents/actionTypes'


const initialState = {
        isLoading: true,
        data:{},
        all:{},
        achieved:{}
};


export default  function chat(state = initialState, action) {

    switch (action.type) {
        case actionTypes.REQUEST_GOALS:

                return {
                    ...state, 
                    isLoading:true,   
                };

        
        case actionTypes.RECEIVE_GOALS:
            return {
                ...state,
                isLoading: false,
                data:action.payload.data?{...state.data,[action.payload.childId]:action.payload.data}:{},
                all:action.payload.all?{...state.all,[action.payload.childId]:action.payload.all}:{},
                achieved:action.payload.achieved?{...state.achieved,[action.payload.childId]:action.payload.achieved}:{},
            };
    
        case actionTypes.ADD_GOAL:

            return {
                ...state,
                isLoading: false,
                data:[...state.data,{ key: action.payload.goalId, ...action.payload.goal }]
            };

        case actionTypes.REMOVE_GOAL:
            
            return {
                ...state,
                data:state.data.filter(item => { return item.key !== action.payload.goalId })
            };
    
        case actionTypes.ACTIVATE_GOAL:
            
            return {
                ...state,
                // data:state.data.filter(item => { return item.key !== action.payload.goalId })
            };

        default:
            return state
    }
}