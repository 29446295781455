/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React from 'react';
import { FlatList, Image, StyleSheet, View } from 'react-native';

import { Resizable } from 'react-native-web-hooks';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import firebase from 'firebase/app';
import 'firebase/database';

import Text from '../../components/CustomText';

import { ParentHeaderCard } from '../../components/Header/HeaderBlock';

import GoalCard from '../../components/Goals/GoalCard';
import FooterBlock from '../../components/Footer/FooterBlock';

import * as goalsActions from '../../store/actions/parents/goalsActions';
import * as authActions from '../../store/actions/parents/authActions';
import { eventLog } from '../../helpers/analytics/analytics';

const database = firebase.database;

class Goals extends React.Component {
    constructor(props) {
        super(props);
        // this.unsubscribe = NetInfo.addEventListener(this.netInfoChange);
        // this.unsubscribe = NetInfo.isConnected.addEventListener('connectionChange', this.netInfoChange)

        this.state = {
            data: [],
            savings_balance: 0.0,
            isLoading: true,
            header: false,
            id: 0,
            connected: true,
        };
    }

    async componentDidMount() {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        eventLog({ eventName: 'goals_screen', properties: {} });

        const savings_source = database().ref(
            '/users/' + parentId + '/' + childIds[id] + '/savings/balance'
        );
        const savings_balance = (await savings_source.once('value')).val();

        this.setState({
            savings_balance: savings_balance,
        });

        if (!this.props.auth.parentId) {
            this.props.actions.clearAuth().then(() => {
                this.props.navigation.navigate('Auth');
            });
        }

        for (const i in childIds) {
            this.props.actions.getChildName(childIds[i]);
        }

        this.props.actions
            .getGoals(parentId, childIds[id])
            .then(() => this.setState({ isLoading: false }));
    }

    componentWillUnmount() {
        // this.unsubscribe.remove()
        // Platform.OS==='web'?null:NetInfo.removeEventListener(this.netInfoChange);
    }

    renderItem = ({ item, index, layout }) => {
        return (
            <GoalCard
                item={item}
                index={index}
                type={'parent'}
                data={this.state.data}
                balance={this.state.savings_balance}
                layout={layout}
            />
        );
    };

    emptyList = () => {
        return this.state.isLoading ? null : (
            <View style={{ alignItems: 'center', marginBottom: 50 }}>
                <Text style={{}}>No goals yet</Text>
            </View>
        );
    };

    async getGoals1(move) {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        const { getGoals, changeChildIndex } = this.props.actions;
        const index = id + move;

        changeChildIndex(move);
        if (index > childIds.length - 1) {
            getGoals(parentId, childIds[0]);
            const savings_source = database().ref(
                '/users/' + parentId + '/' + childIds[0] + '/savings/balance'
            );
            const savings_balance = (await savings_source.once('value')).val();

            this.setState({
                savings_balance: savings_balance,
                id: 0,
            });

            return 0;
        }

        if (index < 0) {
            getGoals(parentId, childIds[childIds.length - 1]);

            const savings_source = database().ref(
                '/users/' + parentId + '/' + childIds[childIds.length - 1] + '/savings/balance'
            );
            const savings_balance = (await savings_source.once('value')).val();

            this.setState({
                savings_balance: savings_balance,
                id: childIds.length - 1,
            });

            return 0;
        }

        getGoals(parentId, childIds[index]);
        const savings_source = database().ref(
            '/users/' + parentId + '/' + childIds[index] + '/savings/balance'
        );
        const savings_balance = (await savings_source.once('value')).val();

        this.setState({
            savings_balance: savings_balance,
            id: index,
        });
        return 0;
    }

    renderHeader = (vmin, layout) => {
        const { navigation } = this.props;

        const { childIds, id, names } = this.props.parent;

        return (
            <ParentHeaderCard
                name={names[childIds[id]]}
                pressNext={() => this.getGoals1(+1)}
                navigation={navigation}
                signOut={() => {}}
                // vmin={vmin} layout={layout}
                clearAuth={this.props.actions.clearAuth}
            />
        );
    };

    render() {
        const { data, navigation } = this.props;
        const { childIds, id, names } = this.props.parent;

        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    return (
                        <View style={{ backgroundColor: 'white', flex: 1 }}>
                            <FooterBlock
                                onPress={() => navigation.navigate('Store')}
                                label={
                                    <Image
                                        resizeMode={'contain'}
                                        style={{ width: '50%', height: '50%' }}
                                        source={require('assets/Store/Shopping-Basket.png')}
                                    />
                                }
                            />

                            <FlatList
                                layout={layout}
                                // contentContainerStyle={{ flex: 1, flexGrow: 1 }}
                                style={{
                                    flex: 1,
                                    zIndex: 1,
                                    // paddingVertical: 0.015 * layout.height,
                                }}
                                // onScroll={(e) => handleScroll.call(this, e, layout.height)}
                                data={
                                    this.state.isLoading
                                        ? []
                                        : data.data[childIds[id]]
                                }
                                renderItem={(item) =>
                                    this.renderItem({ ...item, layout })
                                }
                                ListHeaderComponent={this.renderHeader}
                                // renderItem={(item) => <View><Text>sdf</Text></View>	}
                                keyExtractor={(item, index) => index.toString()}
                                // ListHeaderComponentStyle={{ zIndex: 1, marginVertical: 30, }}
                                ListEmptyComponent={this.emptyList}
                            />
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({});

export default connect(
    (state) => {
        return {
            data: state.goals,
            auth: state.auth,
            parent: state.parent,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...goalsActions, ...authActions },
            dispatch
        ),
    })
)(Goals);
