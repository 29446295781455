/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useCallback, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Animated,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { withNavigation } from 'react-navigation';

import Colors from '../../../constants/Colors';
import { ROUTE_NAMES } from '../../../navigation/navigationConstants';

import { SCREEN_WIDTH } from '../../../constants/constants';
import globalStyles, { defaultStyles } from '../../../constants/style';
import { getVideoThumbnail } from '../../../helpers/academy/videoThumbnails';

import {
    requestAcademyVideos,
    requestAcademyArticles,
    requestAcademyQuizzes,
} from '../../../requests/academy/AcademyDataRequests';
import { AcademyHeader } from '../../../components/Academy/AcademyHeader';

const HARDCODED_ACADEMY_URL =
    'https://static.wixstatic.com/media/5b0236_c7a4d2f1af30406a8ba976b635bc9d7b~mv2.png';

const HARDCODED_ACADEMY_VIDEOS = [
    {
        // url: 'https://www.youtube.com/embed/KryOOZQLAEg',
        // url: 'https://www.youtube.com/watch?v=9lnqVP2LJfc',
        // url: 'https://www.dropbox.com/s/apiqc9vya7n1mt6/STEM%20FELLOWSHGIP%20CYBER%20SECURITY%20EP2%20v3.mp4',
        url: 'https://player.vimeo.com/video/677449372',
        id: 677449372,
        // url: 'https://vod-progressive.akamaized.net/exp=1644882462~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F4967%2F11%2F299836040%2F1144723369.mp4~hmac=e2a7ea565fc27d5867982ec9dbdcf26185a6f6c740742908c4dea43665c8eab2/vimeo-prod-skyfire-std-us/01/4967/11/299836040/1144723369.mp4',
        image: 'https://i.vimeocdn.com/video/1372993492-3143a09c0bf5e718fd61aec9e5b9e156bc257e91c0e94132da08d7d504774768-d_960',
        type: 'vimeo',
        title: 'Phishing',
    },
    {
        type: 'vimeo',
        id: 677447649,
        // url: '//player.vimeo.com/video/68481134',
        // url: 'https://vimeo.com/677449372',
        url: 'https://player.vimeo.com/video/677447649',
        // url: 'https://player.vimeo.com/video/68481134/config',
        // url: 'https://135vod-adaptive.akamaized.net/exp=1635432151~acl=%2F68481134%2F%2A~hmac=b0b904f11bac1205c204a7f8a54b87f181d0925158b93bb3309ae70869850f13/68481134/sep/video/171895132,171470556,171470550/master.m3u8',
        title: 'Cyber Security',
        image: 'https://i.vimeocdn.com/video/1372989065-1b47e85dcc4b5f0f066279a0f9587937b5404348c6e139a763602838f79df8ad-d_960',
    },
];

const AcademyCaretoryCard = withNavigation((props) => {
    const { title, description, url, image, navigation, id } = props;
    const uri = image || HARDCODED_ACADEMY_URL;

    const onCardPress = () => {
        navigation.navigate(props.route, {
            title,
            description,
            url,
            id,
        });
    };

    return (
        <TouchableOpacity
            onPress={onCardPress}
            style={styles.academyCardContainer}
        >
            <Image source={{ uri }} style={styles.academyImage} />
            <Text style={styles.academyCardTitle}>{title}</Text>
            <Text style={styles.academyCardDescription}>{description}</Text>
        </TouchableOpacity>
    );
});

const CardListContainer = (props) => {
    const { getData, title, description } = props;
    // const data = useSelector(getData);
    const data = HARDCODED_ACADEMY_VIDEOS;

    return (
        <>
            <View style={styles.titleContainer}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.description}>{description}</Text>
            </View>
            <ScrollView horizontal>
                {data.map((item) => (
                    <AcademyCaretoryCard
                        key={item.title}
                        route={props.route}
                        {...item}
                    />
                ))}
                 {/* <AcademyCaretoryCard
                    title={'More videos'}
                    route={props.routeMore}
                /> */}
            </ScrollView>
        </>
    );
};

const QuizButton = ({navigate}) => {
    const left = useRef(new Animated.Value(0)).current;

    const animatedStyle = {
        transform: [
            { scale: left.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0.9]
            }) }
        ],
        // left:left.interpolate({
        //              inputRange: [0, 1],
        //            outputRange: ['0%', '10%']
        //          })
    }

    const onPressIn = () => {
        Animated.timing(left, {
            duration: 300,
            toValue: 1,
            useNativeDriver: true
        }).start()
    };

    const onPressOut = () => {
        Animated.timing(left, {
            duration: 300,
            toValue: 0,
            useNativeDriver: true
        }).start()
    };


    return (
        <TouchableOpacity onPressIn={onPressIn} onPressOut={onPressOut}
        // style={styles.quizCard}
         onPress={navigate}>
        <Animated.View style={[styles.quizCard, animatedStyle]}>
        <Text style={{fontSize: 25, textAlign: 'center', color: Colors.green1 }}>Take a Quiz</Text>
        <Text style={{ textAlign: 'center'}}>Test your knowledge</Text>
        </Animated.View>
    </TouchableOpacity>
    )
}

export const Academy = (props) => {
    // const vi
    // const {  } = useSelector(store => store);
    const dispatch = useDispatch();

    useEffect(() => {
        requestAcademyVideos();
        requestAcademyQuizzes();
        requestAcademyArticles();

        getVideoPlaceholders();
        // dispatch();
    }, []);

    const getVideoPlaceholders = useCallback(() => {
        const placeholders = [];

        HARDCODED_ACADEMY_VIDEOS.forEach(async (item) => {
            const resp = await getVideoThumbnail(item.url);
            console.log('THUMBNAIL - ', resp);
            placeholders.push(resp);
        });

        // setVideoPlaceholders(placeholders);

        return placeholders;
    }, []);

    const getAcademyVideos = (store) => store.academy?.videos || [];
    const getAcademyQuizzes = (store) => store.academy?.quizzes || [];
    const getAcademyArticles = (store) => store.academy?.articles || [];

    const navigateToQuiz = ({id}) => {
        props.navigation.navigate(ROUTE_NAMES.QUIZ, {
            id
        })
    }

    // TODO move container to component
    return (
        <View style={{ flex: 1 }}>
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.contentContainer}
            >
                {/* <View style={styles.contentContainer}> */}
                <AcademyHeader />
                <Text style={{ color: Colors.green1, fontSize: 40}}>Cybersecurity Academy</Text>

                <CardListContainer
                    title={'Videos'}
                    routeMore={ROUTE_NAMES.VIDEOS + 1}
                    route={ROUTE_NAMES.VIDEO}
                    getData={getAcademyVideos}
                />
                {/* <CardListContainer
                    title={'Quizzes'}
                    routeMore={ROUTE_NAMES.QUIZZES + 1}
                    route={ROUTE_NAMES.QUIZ}
                    getData={getAcademyQuizzes}
                /> */}
                <Text style={{fontSize: 35}}>
                    Quizzes
                </Text>
                <View style={{ flexDirection: 'row'}}>
                    <QuizButton navigate={() => navigateToQuiz({id:1})} />

                    <TouchableOpacity style={styles.quizCard} onPress={() => navigateToQuiz({id:2})}>
                        <Text style={{fontSize: 25, textAlign: 'center',  color: Colors.green1}}>Social Engineering Quiz 1</Text>
                        <Text style={{ textAlign: 'center'}}>Test your knowledge</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.quizCard} onPress={() => navigateToQuiz({id:3})}>
                        <Text style={{fontSize: 25, textAlign: 'center',  color: Colors.green1}}>Social Engineering Quiz 2</Text>
                        <Text style={{ textAlign: 'center'}}>Identify phishing email</Text>
                    </TouchableOpacity>
                </View>
                {/* <CardListContainer
                    title={'Articles'}
                    routeMore={ROUTE_NAMES.ARTICLES + 1}
                    route={ROUTE_NAMES.ARTICLE}
                    getData={getAcademyArticles}
                /> */}
                {/* </View> */}
            </ScrollView>
        </View>
    );
};

const MAX_BLOCK_WIDTH = Math.min(SCREEN_WIDTH, 700);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // height: '100%',
        // borderWidth: 1,
        backgroundColor: Colors.white,
    },
    // TODO get max width for web
    contentContainer: {
        // flex: 1,
        alignItems: 'center',
        // maxWidth: 650,
    },
    academyCardContainer: {
        ...defaultStyles.shadow,
        width: MAX_BLOCK_WIDTH / 2,
        height: 180,
        margin: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
    },
    academyImage: {
        width: '80%',
        height: 120,
        borderRadius: 10,
    },
    academyCardTitle: {
        color: Colors.black,
        fontWeight: 'bold',
        marginVertical: 10,
    },
    academyCardDescription: {
        color: Colors.green3,
    },
    titleContainer: {
        width: '100%',
        alignItems: 'center',
        marginTop: 25,
    },
    title: {
        ...globalStyles.h2,
        // color: Colors.green1,
    },
    quizCard: {
        justifyContent: 'center',
        margin: 40,
        width: MAX_BLOCK_WIDTH / 2,
        borderRadius: 20, backgroundColor: 'white', padding: 15,
        ...defaultStyles.shadow
    }
});
