




/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    Image,
    Platform,
    TouchableOpacity,
    View,
} from 'react-native';

import Text from '../CustomText'
import TouchableNativeFeedback from '../TouchableFeedback';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import { MenuIcon } from '../Header/HeaderBlock';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const maxWidth = 700;
const widthThreshold = 600;
const cardBackgroundColor = 'rgba(255,255,255,0.5)'
const borderRadius = 25


const StoreHeader = ({ style, navigation, signOut, vmin, layout, clearAuth, dashboard, children }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const [submit, setSubmit] = useState(false);

    const height = layout.height;
    const cardWidth = '90%'
    return <View style={[{
        marginTop: Platform.OS === 'android' ? height*0.04 : 0,
        paddingBottom: 15,
        flexDirection: 'row', borderRadius, width: cardWidth, maxWidth: maxWidth,
        backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'center'
    }, style]}>
        {/* <View> */}
            <TouchableNativeFeedback disabled={dashboard} style={{ }} onPress={() => navigation.navigate('Dashboard')}>
                <Image style={{ width: 40, height: 40, alignSelf: 'flex-start' }} source={require('assets/Pennygem_Logo.png')} />
            </TouchableNativeFeedback>
        {/* </View> */}
        {children}
        <MenuIcon navigation={navigation} vmin={vmin} height={height} signOut={signOut} clearAuth={clearAuth} setSubmit={setSubmit}  />
        {/* <TouchableOpacity onPress={() => setModalVisible(true)}>
            <View style={{ flex: 1, justifyContent: 'flex-start', marginTop: 10 }}>
                <View style={{ justifyContent: 'space-between', height: 20 }}>
                    {[1, 2, 3].map((item, index) => <View key={index} style={{ width: 27, height: 2, margin: 1, backgroundColor: Colors.black }}></View>)}
                </View>
            </View>
        </TouchableOpacity> */}
        <Modal
            animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setModalVisible(false)}
            isVisible={modalVisible}
            style={{ margin: 0 }}
            onRequestClose={() => setModalVisible(false)}>
            <View elevation={5} style={{
                flex: 1, margin: 0, justifyContent: 'center',
                backgroundColor: 'white', height: '100%', width: '100%',
                padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}>
                <TouchableNativeFeedback style={{ position: 'absolute', right: 0.015 * vmin, top: 0.01 * height }}
                    onPress={() => setModalVisible(false)}>
                    <View style={{ position: 'absolute', right: 0.015 * vmin, top: 0.02 * height }}>
                    <Image style={{height:30, width:30}} source={require('assets/Delete-X.png')} />
                        {/* <Text style={[{ color: Colors.rose, }, globalStyle.h1, defaultStyles.bold]}>X</Text> */}
                    </View>
                </TouchableNativeFeedback>
                <View style={{ marginTop: -0.1 * layout.height }}>
                    {[{ title: 'Settings' }, { title: 'Dashboard' }, { title: 'Chequing', params: { cards: 'Chequing' } }, { title: 'Savings', path: 'Chequing', params: { cards: 'Savings' } }, { title: 'Jobs' },
                    { title: 'Goals' }, { title: 'Purchases' }, { title: 'Store' }].map((item, index) => {
                        return <TouchableNativeFeedback key={index} onPress={() => { navigation.navigate(item.path || item.title, item.params); setModalVisible(false) }}>
                            <View style={{ margin: 10 }}>
                                <Text style={[{ textAlign: "center", color: Colors.green1, }, globalStyle.h3, defaultStyles.bold]}>{item.title}</Text>
                            </View>
                        </TouchableNativeFeedback>
                    })}

                    <TouchableNativeFeedback onPress={() => {
                        signOut();
                        clearAuth().then(() => {
                            navigation.navigate('Login')
                        }).then(() => { });
                    }}>
                        <View style={{ margin: 15 }}>
                            <Text style={[{ textAlign: "center", color: Colors.green1 }, globalStyle.h3, defaultStyles.bold]}>Logout</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </Modal>
    </View>
}

export default StoreHeader

{/* <View style={{ alignSelf: 'center', width: '90%', flexDirection: 'row', justifyContent: 'space-between' }}>
    <TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Home') }}>
        <View><Image style={{ width: 50, height: 50 }} source={require('../assets/Pennygem_Logo.png')} /></View>
    </TouchableNativeFeedback>
    <View style={{ justifyContent: 'center', alignItems: 'center', }}><Text>Menu</Text></View>
</View> */}