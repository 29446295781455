/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Image, StyleSheet, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import Text from '../../../components/CustomText'
import Stars from '../../../components/Stars';
import { Button } from '../../../components/newChild/Buttons';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import globalStyles, { defaultStyles } from '../../../constants/style';


class Welcome extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rememberMe: false
        };
    };


    render() {
        const { layout } = this.props;
        const vmin = Math.min(...[layout.width, layout.height]);
        // const titleFontSize = Platform.OS === 'web' ? (layout.width > 850 ? '3em' : '2.5em') : 48
        let titleFontSize = layout.width > 850 ? (layout.width > 1500 ? 30 : layout.width / 25) : 30
        titleFontSize = 25


        return (<View style={{
            ...defaultStyles.shadow, elevation: 5,
            top: layout.height / 2, justifyContent: 'space-between', alignItems: 'center', borderRadius: 15,
            backgroundColor: 'white',
            transform: [{ translateY: Platform.OS === 'web' ? '-50%' : -layout.width / 2 }],
            minWidth: 350, width: layout.width > 850 ? '70%' : '90%',
            maxWidth: 650,
        }}>
            <View style={{ width: '100%', height: layout.width > 850 ? layout.width * 0.22 : layout.width * 0.3 }}>
                <Image resizeMode={"cover"}
                    style={{ borderRadius: 10, width: '100%', height: '100%', }}
                    source={require('assets/pop-up-banner.png')} />
            </View>
            <View style={{ padding: 20, }}>
                <Text style={[{ textAlign: 'center', marginVertical: 10, color: Colors.gray1 },
                defaultStyles.bold, globalStyles.h2]}>Welcome!</Text>
                <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
                    Welcome to Pennygem, a smart parenting app for your child's financial well-being! Thank you for being such a great parent and helping your children learn the invaluable skills of money management.
				</Text>
                {/* <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
                    We will now proceed to the account setup process. It takes about 10 minutes, on average.
				</Text> */}

            </View>
            <View style={{}}>
                <Button title={'Proceed'} onPress={() => this.props.proceed()} />
            </View>
            {/* </View> */}
        </View>)
    }
}

class NewKid extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            headerTitle: <Text style={{ color: 'white', fontSize: 18 }}>Test</Text>,
            // headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 })
            // this.props.navigation.navigate('Profile')
        }
    }

    createCustomToken() {
        this.props.actions.createCustomToken()
            .then(res => console.log('cUSTOM TOKEM', res));
    }

    render() {

        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    return <View style={{ flex: 1, backgroundColor: 'white', alignItems: 'center' }}>

                        {/* {this.props.parent.childIds.length ? <View style={{ zIndex: 1, position: "absolute", right: 10, top: 10 }}>
                            <TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Dashboard') }}>
                                <Image style={{ height: 30, width: 30 }} source={require('assets/Delete-X.png')} />
                            </TouchableNativeFeedback>
                        </View> : null} */}

                        <Welcome layout={layout} parent={this.props.parent} proceed={() => this.props.navigation.navigate('ParentSetup')} />

                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(NewKid);
