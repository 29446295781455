import React from 'react';
import { Platform } from 'react-native';
import { createAppContainer, createSwitchNavigator, createBottomTabNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import Dashboard from "../screens/Kids/Dashboard";
import Settings from "../screens/Kids/Settings";
import Chequing from "../screens/Kids/Chequing";
import Jobs from "../screens/Kids/Jobs";
import Goals from "../screens/Kids/Goals";
import Purchases from "../screens/Kids/Purchases";
// import KidTestScreen from "../screens/TestScreen";
import Store from "../screens/Kids/Store";

import {Academy} from "../screens/Kids/Academy/Academy";
// import Store from "../screens/Kids/Academy/Articles";
import {Videos} from "../screens/Kids/Academy/Videos";
import {VideoScreen} from "../screens/Kids/Academy/Video";
// import { Articles } from "../screens/Kids/Academy/Artices";
import {Article} from "../screens/Kids/Academy/Article";
import {Quizzes} from "../screens/Kids/Academy/Quizzes";
import {Quiz} from "../screens/Kids/Academy/Quiz";


import { AcademyStack } from "./AcademyStack";
// import StoreItem from "../screens/Kids/StoreItem";

import title from "../helpers/generateTitle";

const parentStack = {
  Dashboard,
  Goals,
  Jobs,
  Settings,
  Purchases,
  Store,
  // AcademyStack,
  Academy,
  Quizzes,
  Quiz,
  Videos,
  VideoScreen,
  // Articles,
  Article
};

const stackNavigator =
  Platform.OS === 'web' ?
    createStackNavigator({
      ...parentStack,
      Chequing: { screen: Chequing, path: ':cards' },
    },
      {
        headerMode: 'none',
        header: null,
        navigationOptions: ({ navigation }) => {
          const current = navigation.state.routes && navigation.state.routes.length && navigation.state.routes[navigation.state.routes.length - 1].routeName
          return { title: current && title(current) }
        },
      }
      // { initialRouteName: "Login",}
    )
    :
    createStackNavigator({
      ...parentStack,
      Chequing,
    },
      { initialRouteName: "Dashboard", }
    )

export default stackNavigator
