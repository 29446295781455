/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet, View, Platform, Image, ScrollView } from 'react-native';
import { createElement } from "react-native-web";

const HARDCODED_ACADEMY_URL = 'https://static.wixstatic.com/media/5b0236_c7a4d2f1af30406a8ba976b635bc9d7b~mv2.png';

// export const Video = createElement('video');

export const VideoView = (props) => {
    const { route } = props;

    const { source } = props;

    console.log('VIDEO - ', props);

    // return (<View></View>);

    // TODO move container to component
    return (
    //     <video width="400" height="300" controls="controls" poster="video/duel.jpg">
    //         <source src={source.uri} />
    //    </video>
    <iframe
        width="100%" height="325"
        src={source.uri}
        // src={'https://www.dropbox.com/s/apiqc9vya7n1mt6/STEM%20FELLOWSHGIP%20CYBER%20SECURITY%20EP2%20v3.mp4'}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
    </iframe>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        // height: 200,
    },
});
