import { Platform, StyleSheet, Dimensions } from 'react-native';
import { useHover, useFocus, useActive, useDimensions, Resizable } from 'react-native-web-hooks';
import { FONTS } from './fonts';

const minWidth1 = 700 // 700px or 43.75em
const minWidth2 = 900 // 900px or 56.25em

const constantsWeb = {
	pageFontSize: ['1em', '1em', '1em'],
	h1FontSize: ['2em', '2.5em', '3em'],
	h2FontSize: ['1.625em', '2em', '2.25em'],
	h3FontSize: ['1.375em', '1.5em', '1.75em'],
	h4FontSize: ['1.125em', '1.125em', '1.125em'],

	pageLineHeight: ['1.25em', '1.375em', '1.375em'],
	h1LineHeight: ['1.25em', '1.125em', '1.05em'],
	h2LineHeight: ['1.15384615em', '1.25em', '1.25em'],
	h3LineHeight: ['1.13636364em', '1.25em', '1.25em'],
	h4LineHeight: ['1.11111111em', '1.22222222em', '1.22222222em']
}

const constantsMobile = {
	pageFontSize: [16, 16, 16],
	h1FontSize: [32, 40, 48],
	h2FontSize: [26, 32, 36],
	h3FontSize: [22, 24, 28],
	h4FontSize: [18, 18, 18],

	pageLineHeight: [20, 22, 22],
	h1LineHeight: [36, 42, 50],
	h2LineHeight: [26, 32, 38],
	h3LineHeight: [24, 26, 30],
	h4LineHeight: [18, 20, 20]
}

const constants = Platform.OS === 'web' ? constantsWeb : constantsMobile
// const pageFontSize = ['1em', '1em','1em']
// const h1FontSize = ['2em', '2.5em','3em']
// const h2FontSize = ['1.625em', '2em','2.25em']
// const h3FontSize = ['1.375em', '1.5em','1.75em']
// const h4FontSize = ['1.125em', '1.125em','1.125em']

// const pageLineHeight = ['1.25em', '1.375em','1.375em']
// const h1LineHeight = ['2em', '2.5em','3em']
// const h2LineHeight = ['1.625em', '2em','2.25em']
// const h3LineHeight = ['1.375em', '1.5em','1.75em']
// const h4LineHeight = ['1.125em', '1.125em','1.125em']

function media({ width, results }) {
	if (width > minWidth2) {
		return results[2]
	}
	if (width > minWidth1) {
		return results[1]
	}
	return results[0]
}

let { width, height } = Dimensions.get('screen');


const stylesWeb = () => {
	return {
		page: { fontSize: media({ width, results: constants.pageFontSize }), lineHeight: media({ width, results: constants.pageLineHeight }) },
		h1: { fontSize: media({ width, results: constants.h1FontSize }), lineHeight: media({ width, results: constants.h1LineHeight }) },
		h2: { fontSize: media({ width, results: constants.h2FontSize }),
		lineHeight: media({ width, results: constants.h2LineHeight }) },
		h3: { fontSize: media({ width, results: constants.h3FontSize }), lineHeight: media({ width, results: constants.h3LineHeight }) },
		h4: { fontSize: media({ width, results: constants.h4FontSize }), lineHeight: media({ width, results: constants.h4LineHeight }) },

		...['h1', 'h2', 'h3', 'h4'].map(item => {
			return {
				[item]: {
					['fontSize']: media({ width, results: constants[item + 'FontSize'] }),
					['lineHeight']: media({ width, results: constants[item + 'LineHeight'] })
				}
			}
		})
		// h1: {}
	}
};

export const defaultStyles = {
	boxShadow: '0 0 15px 5px rgba(76, 76, 76, 0.1)',
	shadow: Platform.OS==='web'?{ boxShadow: '0 0 20px 4px rgba(76, 76, 76, 0.06)', }:{
		...Platform.select({
			android: {elevation: 5,},
			ios: {
				shadowColor: "rgba(76, 76, 76, 0.15)",
				shadowOffset: {
					width: 0,
					height: 0,
				},
				shadowOpacity: 1,
				shadowRadius: 10,
			},
		}),
	},
	greenShadow: { boxShadow: '-5px 5px 15px 2px rgba(126, 193, 61, 0.6)', },
	bold: { fontFamily: FONTS.FONT_BOLD },
	intervalBoxShadow: '-5px 5px 20px 5px rgba(253, 182, 8, 0.5)',
};

export const styleHook = () => {
	const {
		window: { width, height, fontScale, scale },
		screen,
	} = useDimensions();

	// Dimensions.addEventListener('change', ({window, screen})=>{console.log('width', window, screen)})

	return {
		page: { fontSize: media({ width, results: constants.pageFontSize }), lineHeight: media({ width, results: constants.pageLineHeight }) },
		h1: { fontSize: media({ width, results: constants.h1FontSize }), lineHeight: media({ width, results: constants.h1LineHeight }) },
		h2: { fontSize: media({ width, results: constants.h2FontSize }), lineHeight: media({ width, results: constants.h2LineHeight }) },
		h3: { fontSize: media({ width, results: constants.h3FontSize }), lineHeight: media({ width, results: constants.h3LineHeight }) },
		h4: { fontSize: media({ width, results: constants.h4FontSize }), lineHeight: media({ width, results: constants.h4LineHeight }) },

		...['h1', 'h2', 'h3', 'h4'].map(item => {
			return {
				[item]: {
					['fontSize']: media({ width, results: constants[item + 'FontSize'] }),
					['lineHeight']: media({ width, results: constants[item + 'LineHeight'] })
				}
			}
		})
		// h1: {}
	}
};

// export styleHook;

export default stylesWeb()