import firebase from 'firebase/app';
import 'firebase/database';
import { useSelector } from 'react-redux';

const database = firebase.database;

export async function deposit({transfer, parentId, childId}) {

    var balance_ref = database().ref('/users/' + parentId + '/' + childId + '/chequing/balance');

    database().ref('/users/' + parentId + '/' + childId + '/chequing/transactions').push({
        amount: parseFloat(transfer.replace('$', '')),
        transaction_date: (new Date()).toISOString(),
        type: 'parent deposit'
    })

    const snapshot = await balance_ref.once('value')
    let old_balance = snapshot.val()

    await database().ref().child('users').child(parentId).child(childId).child('chequing')
        .update({ balance: parseFloat(old_balance) + parseFloat(transfer.replace('$', '')) });

    return 0
}

export async function withdrawal({transfer, parentId, childId}) {

    var balance_ref = database().ref('/users/' + parentId + '/' + childId + '/chequing/balance');

    database().ref('/users/' + parentId + '/' + childId + '/chequing/transactions').push({
        amount: parseFloat(transfer.toString().replace('$', '')),
        transaction_date: (new Date()).toISOString(),
        type: 'parent withdrawal'
    })

    database().ref('/users/' + parentId + '/' + childId + '/purchases/approved').push({
        price: parseFloat(transfer.toString().replace('$', '')),
        approval_date: (new Date()).toISOString(),
        item_name: 'Parent withdrawal',
        type: 'credit purchase'
    })

    const snapshot = await balance_ref.once('value')
    let old_balance = snapshot.val()

    await database().ref().child('users').child(parentId).child(childId).child('chequing')
        .update({ balance: parseFloat(old_balance) - parseFloat(transfer.toString().replace('$', '')) });

    return 0
}
