import React from 'react';

import TransferBlock from "../../components/chequing/TransferBlock";
import { customModalRef } from "../../components/Modal/CustomModal";
import { sendNotification } from "../notifications/notifications";
import { deposit, withdrawal } from "../parentTransfer";
import { store } from "../../index";
import { requestChildrenChequingBalances } from '../../store/actions/parents/chequingActions';
import { getDashboardData } from '../../store/actions/parents/authActions';


const transferElements = {
    deposit: {
        transferFn: deposit,
        title: 'Deposit to Chequing',
        label: 'Amount to Deposit',
        notificationTitle: 'Money deposit',
        notificationBody: 'Money was deposited by parent',
        notificationAction: 'parent_deposit',
    },
    withdrawal: {
        transferFn: withdrawal,
        title: 'Withdraw from Chequing',
        label: 'Amount to Withdraw',

        notificationTitle: 'Money withdrawal',
        notificationBody: 'Money was withdrawn by parent',
        notificationAction: 'parent_withdraw',
    },
};

// type = deposit|withdrawal
export const onTransferCompleteButtonPress = ({type, transfer, childId}) => {
    const {transferFn, notificationTitle, notificationBody, notificationAction} = transferElements[type];
    const parentId = store.getState().auth.parentId;
    const dispatch = store.dispatch;

    transferFn({
        transfer,
        parentId,
        childId,
    })
    .then(() => {
        sendNotification({
            type: 'child',
            title: notificationTitle,
            id: childId,
            body: notificationBody,
            data: {
                action: notificationAction,
            },
        });

        dispatch(requestChildrenChequingBalances({ parentId, childIds: [childId] }));
        dispatch(getDashboardData({ parentId, childId }))
        // actions.requestChildrenChequingBalances({ parentId, childIds: [childId] });
        // actions.getDashboardData({ parentId, childId });
    })
    .finally(() => customModalRef.current.hide());
}

export const onTransferPress = ({type, childId}) => {
    const {title, label} = transferElements[type];

    customModalRef.current.show(() => <TransferBlock
        type={type}
        title={title}
        label={label}
        onPress={(transfer) => {
            onTransferCompleteButtonPress({type, transfer, childId})
        }}
    />);
}