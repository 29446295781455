/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Platform,
    TouchableOpacity,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';
import Modal from 'react-native-modal';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

import TouchableNativeFeedback from '../TouchableFeedback';
import Text from '../CustomText';
import { Loader } from '../Loader';

import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';

const storage = firebase.storage;

var re = /^[0-9]\. /;

const ContractModal = ({ fileName, title, visible, setVisible }) => {
    const [loading, setLoding] = useState(true);
    const [document, setDocument] = useState('');

    // TODO optimize donwolad only on modal open
    useEffect(() => {
        storage()
            .ref('legals/' + fileName)
            .getDownloadURL()
            .then((documentUrl) => fetch(documentUrl))
            .then((res) => res.text())
            .then((res) => {
                // console.log(res);
                setLoding(false);
                setDocument(res);
            })
            .catch((err) => console.log('err'));
    }, [fileName]);

    const closeModal = () => {
        setVisible(false);
    };

    return (
        <Modal
            isVisible={visible}
            onBackdropPress={closeModal}
            backdropColor={Colors.gray1}
            backdropOpacity={1}
        >
            {/* TermsAndConditions.txt */}
            <Loader loading={loading} />
            <View style={contractStyles.headerContainer}>
                <Text style={contractStyles.title}>{title}</Text>
                <TouchableNativeFeedback onPress={closeModal}>
                    <Image
                        source={require('assets/Delete-X.png')}
                        style={contractStyles.close}
                    />
                </TouchableNativeFeedback>
            </View>
            <ScrollView
                nestedScrollEnabled={true}
                style={contractStyles.contractScroll}
                contentContainerStyle={{}}
            >
                {/* <View> */}
                {document.split('\n').map((item, index) => (
                    <Text
                        key={`${item}_${index}`}
                        style={{
                            fontFamily: `${re.test(item) ? 'Carmen Sans Bold'
                            : 'Carmen Sans Regular'}`,
                        }}
                    >
                        {item}
                    </Text>
                ))}
                {/* {this.state.text.split('\n').map(item=>item).join('\n')} */}
                {/* </View> */}
            </ScrollView>
        </Modal>
    );
};

export const ContractCheckboxRow = ({
    selected,
    setSelected,
    checkboxStyle,
}) => {
    const [privacyContractVisible, setPrivacyContractVisible] = useState(false);
    const [termsContractVisible, setTermsContractVisible] = useState(false);

    const onPrivacyPress = () => {
        console.log('12312312');
        setPrivacyContractVisible(true);
    };

    const onTermsPress = () => {
        setTermsContractVisible(true);
    };

    const onCheckboxPress = () => {
        setSelected(!selected);
    };

    return (
        <View style={contractStyles.container}>
            <ContractModal
                fileName={'PrivacyPolicy.txt'}
                title={'Privacy Policy'}
                visible={privacyContractVisible}
                setVisible={setPrivacyContractVisible}
            />
            <ContractModal
                fileName={'TermsAndConditions.txt'}
                title={'Terms And Conditions'}
                visible={termsContractVisible}
                setVisible={setTermsContractVisible}
            />
            <TouchableNativeFeedback
                style={[contractStyles.checkbox, checkboxStyle]}
                onPress={onCheckboxPress}
            >
                <Icon
                    style={{
                        zIndex: 2,
                        opacity: selected ? 1 : 0,
                    }}
                    name={'check'}
                    size={16}
                    color={Colors.gray1}
                />
            </TouchableNativeFeedback>
            <Text style={contractStyles.text}>
                I have read, understood and I agree to the{' '}
                <Text style={contractStyles.link} onPress={onPrivacyPress}>
                    Privacy Policy
                </Text>{' '}
                {' '}and{' '}
                <Text style={contractStyles.link} onPress={onTermsPress}>
                    Terms and Conditions
                </Text>
            </Text>
        </View>
    );
};

const contractStyles = StyleSheet.create({
    container: {
        // width: '50%',
        maxWidth: 350,
        alignSelf: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerContainer: {
        backgroundColor: 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 10,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    },
    checkbox: {
        height: 25,
        width: 25,
        borderRadius: 5,
        borderWidth: 2,
        borderColor: Colors.gray1,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    text: {
        // color: Colors.green1,
        width: '90%',
        color: Colors.gray1,
        fontSize: 14,
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        ...defaultStyles.bold,
        textDecorationLine: 'underline',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.gray1,
        // color: Colors.green1,
    },
    contractScroll: {
        paddingVertical: 20,
        overflow: 'hidden',
        paddingHorizontal: 10,
        borderColor: Colors.gray,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderWidth: 1,
        flex: 1,
        // margin: 2,
        backgroundColor: 'white',
        // marginBottom: 20,
    },
    contractsContainer: {
        marginVertical: 20,
        paddingHorizontal: 20,
        borderRadius: 10,
        maxWidth: 400,
    },
    title: { marginVertical: 10, paddingVertical: 10, ...defaultStyles.bold },
    close: {
        width: 30,
        height: 30,
    },
});
