/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState, useCallback, useReducer } from 'react';
import {
    TextInput,
    StyleSheet,
    View,
    ScrollView,
    TouchableOpacity,
    KeyboardAvoidingView,
    Keyboard,
} from 'react-native';

import {} from 'react-redux';

import Text from '../CustomText';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';

import ModalContainer from '../Modal/Modal';
import { ContactUs } from './ContactUs';

import config from '../../constants/config';

const submit = ({ title, description, navigation, issueType, auth }) => {

    const type = auth.type;
    const userName = auth.user.user.displayName;
    const email = auth.user.user.email;
    const userId = auth.userId;
    const currentScreen = navigation?.state?.routeName;
    const rawData =
        type === '0' || type === 0 || type === 'parent'
            ? info?.parent
            : info?.child;

    console.log('AUTH f - ', description);

    const body = {
        userName,
        email,
        userId,
        type,
        title,
        description,
        issueType,
        currentScreen,
        rawData,
    };

    console.log('BODYbody, ', body);

    // return null;

    return fetch(config.url + 'handleFeedback', {
        method: 'POST',
        // headers: {
        //   'Accept': 'application/json',
        //   'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body),
    });

    return 0;
};

export const Feedback = (props) => {
    const descriptionRef = useRef();
    const [issueTitle, setTitle] = useState('');
    const [text, setText] = useState('');
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const info = useReducer(store => store.auth);
    const auth = info?.auth;

    const { visible, onClose, title, parent, navigation, layout } = props;

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', keyboardDidShow);
		const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', keyboardDidHide);

        return () => {
            keyboardDidShowListener.remove();
    		keyboardDidHideListener.remove();
        }
    }, []);

    const keyboardDidShow = e => {
        console.log('EEEE - ', e)
		setKeyboardHeight(243)
	}

	const keyboardDidHide = () => {
		setKeyboardHeight(0);
	}

    const onChangeText = useCallback((changeText) => setText(changeText), []);

    const descriptionPlaceholder = useCallback(() => {
        return (
            !text && (
                <View
                    pointerEvents={'none'}
                    style={styles.descriptionContainer}
                >
                    <Text style={{ zIndex: 10, color: 'lightgray' }}>
                        Description
                    </Text>
                </View>
            )
        );
    }, [text]);

    return (
        <ModalContainer visible={visible} onClose={() => onClose()}>
            <KeyboardAvoidingView enabled behavior={'padding'} style={{width: '100%',
        }}>
            <View
                onStartShouldSetResponder={Keyboard.dismiss}
                style={[
                    styles.container,
                    { height: (layout?.height || 800) * 0.5,
                        marginBottom: keyboardHeight
                     },
                ]}
            >
                <Text style={styles.title}>{title}</Text>
                <TextInput
                    placeholder={'Title'}
                    placeholderTextColor={'lightgray'}
                    style={styles.titleInput}
                    value={issueTitle}
                    onChangeText={(text) => setTitle(text)}
                />
                <View style={styles.textInputContainer}>
                    <TextInput
                        ref={descriptionRef}
                        style={styles.textInput}
                        multiline={true}
                        value={text}
                        onChangeText={onChangeText}
                    />
                    {descriptionPlaceholder()}
                </View>
                <TouchableOpacity
                    style={styles.submitButton}
                    onPress={() => {
                        if (text) {
                            submit({
                                description: text,
                                navigation,
                                title: issueTitle,
                                issueType: title,
                                auth,
                            });

                            onClose();
                            setText('');
                            setTitle('');
                        }
                    }}
                >
                    <Text style={styles.submitText}>Submit</Text>
                </TouchableOpacity>
            </View>
            </KeyboardAvoidingView>
        </ModalContainer>
    );
};

const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15,
        // flex: 1
    },
    title: {
        fontFamily: 'Carmen Sans Bold',
        marginTop: 15,
        ...globalStyles.h3,
    },
    titleInput: {
        fontFamily: 'Carmen Sans Regular',
        borderWidth: 1,
        padding: 5,
        width: '100%',
        borderColor: 'gray',
        borderRadius: 10,
        marginTop: 10,
    },
    textInputContainer: {
        flex: 1,
        margin: 10,
        borderWidth: 1,
        width: '100%',
        marginVertical: 20,
        marginTop: 10,
        borderColor: 'gray',
        borderRadius: 10,
        padding: 5,
    },
    textInput: { flex: 1, fontFamily: 'Carmen Sans Regular' },
    descriptionContainer: { position: 'absolute', left: 5, top: 10 },
    submitButton: {
        width: '100%',
        marginBottom: 15,
        alignItems: 'center',
        backgroundColor: Colors.green1,
        borderRadius: 20,
    },
    submitText: {
        color: 'white',
        padding: 10,
        ...globalStyles.h4,
    },
});
