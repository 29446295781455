/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { StyleSheet, TextInput, View, Platform, Linking } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Resizable, Hoverable } from 'react-native-web-hooks';
import LinearGradient from 'react-native-linear-gradient';
// import * as Google from 'expo-auth-session/providers/google';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import TouchableNativeFeedback from '../components/TouchableFeedback'
import Text from '../components/CustomText';
import { ConfirmationView } from '../components/confirmation';
// import GoogleSignInButton from '../components/Auth/GoogeSignInButton';
import ModalContainer from '../components/Modal/Modal';
import { AuthContainer } from '../components/Auth/AuthContainer';
import { AuthButton } from '../components/Auth/AuthButton';
import PopupModal from '../components/Popup/PopupModal';

import * as authActions from '../store/actions/authActions';

import Colors from '../constants/Colors';
import config from '../constants/config';
import globalStyle, { defaultStyles } from '../constants/style';
import { IS_ANDROID, IS_WEB } from '../constants/constants';

import { callTooltip } from '../helpers/tooltip/tooltip';

// const Constants = require('../app.json');

// const manifest = Constants.expo;
const manifest = {slug: 'pennygem-parents'};

const auth = firebase.auth;
const database = firebase.database;

const error_codes = {
    'auth/email-already-in-use': 1,
    'auth/invalid-email': 2,
    'auth/operation-not-allowed': 3,
    'auth/weak-password': 4,
};

const CLIENT_ID =
    '141497077893-f8p9s827f548urku53dq0cfpec1uke3c.apps.googleusercontent.com';

var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'https://pennygembank.com/',
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'pennygembank.com'
};

const RejectionView = ({ title, subtitles, okText, okPress }) => {
    return (
        <View style={rejectionViewStyles.rejectionViewContainer}>
            <View style={rejectionViewStyles.rejectionViewTextContainer}>
                <Text style={rejectionViewStyles.rejectionViewTitle}>
                    {title}
                </Text>
                {subtitles.map((subtitle, index) => (
                    <Text
                        key={index}
                        style={rejectionViewStyles.rejectionViewText}
                    >
                        {subtitle}
                    </Text>
                ))}
            </View>

            <View style={rejectionViewStyles.rejectionViewButtonContainer}>
                <View style={{ width: '80%' }}>
                    <TouchableNativeFeedback onPress={okPress}>
                        <LinearGradient
                            // start={[0, 1]}
                            // end={[1, 0]}
                            start={{x: 0, y: 1}}
                            end={{x: 1, y: 0}}
                            colors={[Colors.green2, Colors.green1]}
                            style={rejectionViewStyles.rejectionViewButton}
                        >
                            <Text
                                style={[
                                    rejectionViewStyles.rejectionViewButtonText,
                                    defaultStyles.bold,
                                    globalStyle.h3,
                                ]}
                            >
                                {okText ? okText : 'OK'}
                            </Text>
                        </LinearGradient>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    );
};

const rejectionViewStyles = StyleSheet.create({
    rejectionViewContainer: { justifyContent: 'center', alignItems: 'center' },
    rejectionViewTextContainer: { marginVertical: 15, alignItems: 'center' },
    rejectionViewTitle: {
        textAlign: 'center',
        color: Colors.gray0,
        fontSize: 18,
        margin: 5,
    },
    rejectionViewText: { fontSize: 20, margin: 5, color: '#5aca3c' },
    rejectionViewButtonContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        marginHorizontal: 15,
    },
    rejectionViewButton: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        borderRadius: 20,
    },
    rejectionViewButtonText: {
        textAlign: 'center',
        marginVertical: 5,
        marginHorizontal: 15,
        color: 'white',
    },
});

export const AuthTextInput = ({
    value,
    onSubmitEditing,
    onChangeText,
    placeholder,
    inputRef,
    secureTextEntry,
    keyboardType,
}) => {
    return (
        <TextInput
            value={value}
            onSubmitEditing={onSubmitEditing}
            ref={inputRef}
            secureTextEntry={secureTextEntry || false}
            keyboardType={keyboardType || 'default'}
            onChangeText={(text) => onChangeText(text)}
            style={authInputStyles.style}
            multiline={false}
            placeholder={placeholder}
        />
    );
};

const authInputStyles = StyleSheet.create({
    style: {
        color: Colors.gray1,
        ...(Platform.OS === 'web' ? { outlineWidth: 0 } : {}),
        borderRadius: 20,
        borderWidth: 3,
        borderColor: Colors.gray,
        backgroundColor: 'white',
        height: 40,
        width: '90%',
        fontFamily: 'Carmen Sans Regular',
        paddingLeft: 10,
        justifyContent: 'center',
        marginVertical: 10,
    },
});

// const AuthTextInput = AuthTextInput({})

export const Button = ({ title, onPress }) => {
    return (
        <TouchableNativeFeedback
            style={styles.buttonContainer}
            onPress={onPress}
        >
            <LinearGradient
                // start={[0, 1]}
                // end={[1, 0]}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                colors={[Colors.green2, Colors.green1]}
                style={[styles.buttonGradient]}
            >
                <View style={{ width: '100%' }}>
                    <Text style={[styles.buttonText, defaultStyles.bold]}>
                        {title.toUpperCase()}
                    </Text>
                </View>
            </LinearGradient>
        </TouchableNativeFeedback>
    );
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.inputBlockRef = React.createRef();
        this.emailInput = React.createRef();
        this.passwordInput = React.createRef();

        this.state = {
            email: this.props.auth.email,
            password: '',
            rememberMe: false,
        };
    }

    loginParent(user, type) {
        if (type === 0) {
            this.props.actions.login(user, 0).then(() => {
                this.state.rememberMe
                    ? this.props.actions.rememberMe(email)
                    : this.props.actions.rememberMe('');

                this.props.navigation.navigate('Parent');
            });
        } else {
            throw {
                code: 'error/use-child-version',
                message: 'Please use kids version of an app (Pennygem Kids)',
            };
        }
    }

    loginKid(user, type) {
        if (type === 1) {
            this.props.actions.login(user, 1).then(() => {
                this.state.rememberMe
                    ? this.props.actions.rememberMe(email)
                    : this.props.actions.rememberMe('');

                this.props.navigation.navigate('Kid');
            });
        } else {
            throw {
                code: 'error/use-parent-version',
                message:
                    'Please use parent version of an app (Pennygem Parents)',
            };
        }
    }

    login = async () => {
        let email = this.state.email;
        const password = this.state.password;

        if (email.indexOf('@') === -1) {
            // let username = (await database().ref('children/').orderByChild('username').equalTo(email.toLowerCase()).once('value')).val()
            const username = (
                await database()
                    .ref('username/')
                    .orderByKey()
                    .equalTo(email.toLowerCase())
                    .once('value')
            ).val();

            if (username) {
                console.log('username', username);
                // email = Object.values(username)[0].email
                email = Object.values(username)[0];
            }
        }

        auth()
            .signInWithEmailAndPassword(email, password)
            .then(async (user) => {
                // if (auth().currentUser.emailVerified === false) {
                // 	if (__DEV__) { }
                // 	else { throw ({ message: 'Email not verified', code: 'auth/email-not-verified' }) }
                // }
                const user_type = (
                    await database()
                        .ref()
                        .child('user_type/' + user.user.uid + '/type')
                        .once('value')
                ).val();

                return { type: user_type, user: user };
            })
            .then((res) => {
                const appType = this.props.appType;

                if (appType === 'pennygem-parents') {
                    this.loginParent(res.user, res.type);
                } else if (appType === 'pennygem-kids') {
                    this.loginKid(res.user, res.type);
                } else {
                    if (res.type === 0 || res.type === 1) {
                        this.props.actions
                            .login(res.user, res.type)
                            .then(() => {
                                this.state.rememberMe
                                    ? this.props.actions.rememberMe(email)
                                    : this.props.actions.rememberMe('');

                                res.type
                                    ? this.props.navigation.navigate('Kid')
                                    : this.props.navigation.navigate('Parent');
                            });
                    } else {
                        throw {
                            code: 'error/no-type',
                            message: 'No user type',
                        };
                    }

                    console.log('something went wrong');
                }
            })
            .catch((error) => {
                auth().signOut();
                const errorMessage = error.message;

                console.log(error);
                this.setState({ authModalVisible: true });
                switch (error.code) {
                    case 'auth/invalid-email':
                        this.setState({
                            errorBlock: true,
                            errorText: errorMessage,
                        });

                        return 0;
                    case 'auth/user-disabled':
                        this.setState({
                            errorBlock: true,
                            errorText: errorMessage,
                        });

                        return 0;
                    case 'auth/user-not-found':
                        this.setState({
                            errorBlock: true,
                            errorText: errorMessage,
                        });

                        return 0;
                    case 'auth/wrong-password':
                        this.setState({
                            errorBlock: true,
                            errorPassword: true,
                            errorText: errorMessage,
                        });

                        return 0;
                    case 'auth/email-not-verified':
                        this.setState({
                            verifyEmail: true,
                            errorText: errorMessage,
                        });

                        return 0;
                    case 'error/use-parent-version':
                        this.setState({
                            authModalVisible: false,
                            errorText: errorMessage,
                            errorButtonText: 'Download app',
                            parrentApp: true,
                        });

                        return 0;
                    case 'error/use-child-version':
                        this.setState({
                            errorBlock: true,
                            errorText: errorMessage,
                            errorButtonText: 'Download app',
                            childApp: true,
                        });

                        return 0;
                    default:
                        this.setState({
                            errorBlock: true,
                            errorText: 'Something went wrong =(',
                        });

                        return 0;
                }
            });
    };

    onDownloadParentAppPress() {
        if (Platform.OS === 'android') {
            // Linking.openURL('');
            Linking.openURL('market://details?id=com.pennygemapp.parents');
            this.setState({ parrentApp: false });
        } else {
            this.setState({ parrentApp: false });
            Linking.openURL('itms-apps://apps.apple.com/app/id1536825037');
        }
    }

    onDownloadKidAppPress() {
        if (Platform.OS === 'android') {
            // Linking.openURL('');
            Linking.openURL('market://details?id=com.pennygemapp.kids');
            this.setState({ parrentApp: false });
        } else {
            this.setState({ parrentApp: false });
            Linking.openURL('itms-apps://apps.apple.com/app/id1537264477');
        }
    }

    render() {
        return (
            <View style={styles.loginContainer}>
                <ModalContainer
                    visible={this.state.parrentApp}
                    onClose={() => this.setState({ parrentApp: false })}
                >
                    <View style={styles.downloadParentApp}>
                        <RejectionView
                            title={this.state.errorText}
                            subtitles={[]}
                            okText={'Download Pennygem Parents'}
                            okPress={() => this.onDownloadParentAppPress()}
                        />
                    </View>
                </ModalContainer>
                <ModalContainer
                    visible={this.state.childApp}
                    onClose={() => this.setState({ childApp: false })}
                >
                    <View style={styles.downloadParentApp}>
                        <RejectionView
                            title={this.state.errorText}
                            subtitles={[]}
                            okText={'Download Pennygem Kids'}
                            okPress={() => this.onDownloadKidAppPress()}
                        />
                    </View>
                </ModalContainer>
                <ModalContainer
                    visible={this.state.authModalVisible}
                    onClose={() => this.setState({ authModalVisible: false })}
                >
                    <View style={styles.authModal}>
                        {this.state.verifyEmail ? (
                            <ConfirmationView
                                textStyle={styles.emailNotVerifiedText}
                                image={null}
                                title={
                                    "Your email isn't verified, please check your email for confirmaion link."
                                }
                                subtitles={[
                                    'Should we send you another verificaiton email?',
                                ]}
                                confirmText={'Yes'}
                                rejectText={'No'}
                                confirmPress={() => {
                                    this.props.actions.sendVerificationEmail(
                                        this.state.userName,
                                        this.state.email
                                    );

                                    this.setState({
                                        authModalVisible: false,
                                        verifyEmail: false,
                                    });
                                }}
                                rejectPress={() => {
                                    this.setState({
                                        authModalVisible: false,
                                        verifyEmail: false,
                                    });
                                }}
                            />
                        ) : (
                            <RejectionView
                                title={this.state.errorText}
                                subtitles={[]}
                                okText={this.state.errorButtonText || 'Ok'}
                                okPress={() => {
                                    this.setState({ authModalVisible: false });
                                }}
                            />
                        )}
                    </View>
                </ModalContainer>
                <React.Fragment>
                    <Text
                        style={[
                            styles.signIn,
                            defaultStyles.bold,
                            globalStyle.h2,
                        ]}
                    >
                        {'Sign In'.toUpperCase()}
                    </Text>

                    <View collapsable={false} ref={this.inputBlockRef} style={styles.inputContainer}>
                        <AuthTextInput
                            keyboardType={'email-address'}
                            value={this.state.email}
                            onChangeText={(text) =>
                                this.setState({
                                    errorBlock: false,
                                    email: text,
                                })
                            }
                            onSubmitEditing={() => {
                                this.passwordInput.current.focus();
                            }}
                            multiline={false}
                            placeholder={'Email/Username'}
                        />
                        <AuthTextInput
                            inputRef={this.passwordInput}
                            onSubmitEditing={() => {
                                this.login(
                                    this.state.email,
                                    this.state.password
                                );
                            }}
                            secureTextEntry={true}
                            value={this.state.password}
                            onChangeText={(text) =>
                                this.setState({
                                    errorBlock: false,
                                    password: text,
                                })
                            }
                            placeholder={'Password'}
                        />
                    </View>

                    {/* <View style={{marginVertical:10, opacity:this.state.errorBlock?1:0}}>
                    <Text>{this.state.errorText}</Text>
                    </View> */}
                    <AuthButton
                        title={'Log in'}
                        onPress={() => {
                            // callTooltip({ ref: this.inputBlockRef, text: 'Login info is a very sensitive data' })
                            setTimeout(() => {
                                this.login(this.state.email, this.state.password)
                            }, 0);
                        }}
                        // onPress={() => {
                        //     // console.log()
                        //     callTooltip({ ref: this.inputBlockRef, text: 'Login info is a very sensitive data' })
                        // }}
                    />

                    <View
                        style={{
                            flexDirection: 'row',
                            marginVertical: 15,
                            marginBottom: 30,
                            justifyContent: 'space-between',
                        }}
                    >
                        <TouchableNativeFeedback
                            style={{ flexDirection: 'row' }}
                            onPress={() => {
                                this.setState({
                                    rememberMe: !this.state.rememberMe,
                                });
                            }}
                        >
                            <View style={{ margin: 10, flexDirection: 'row' }}>
                                <View
                                    style={{
                                        borderRadius: 15,
                                        borderColor: Colors.black,
                                        borderWidth: 2,
                                        width: 15,
                                        height: 15,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            display: this.state.rememberMe
                                                ? 'flex'
                                                : 'none',
                                            borderRadius: 30,
                                            backgroundColor: Colors.black,
                                            width: '50%',
                                            height: '50%',
                                        }}
                                    />
                                </View>
                                <Text
                                    style={[
                                        {
                                            marginLeft: 5,
                                            fontSize: 14,
                                            alignSelf: 'center',
                                            color: Colors.black,
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    Remember me
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                        <TouchableNativeFeedback
                            style={{ justifyContent: 'center' }}
                            onPress={() => {
                                console.log(123);
                                this.props.navigation.navigate(
                                    'RecoverPassword'
                                );
                            }}
                        >
                            <View>
                                <Text
                                    style={[
                                        {
                                            zIndex: 1,
                                            margin: 10,
                                            fontSize: 14,
                                            alignSelf: 'center',
                                            color: Colors.black,
                                            textDecorationLine: 'underline',
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    Forgot Password?
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </React.Fragment>
                {/* <GoogleSignInButton /> */}
            </View>
        );
    }
}

const loginStyles = StyleSheet.create({

});

class LoginScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
        };

        this.onSignUpPress = this.onSignUpPress.bind(this);
    }

    onSignUpPress() {
        const appType = this.props.appType;
        // this.setState({ getParentAppModal: true });
        // return
        if (appType === 'pennygem-kids') {
            this.setState({ getParentAppModal: true });
        } else {
            this.props.navigation.navigate('SignUp');
            // this.setState({ login: false })
        }
    }

    onParentAppDownloadPress = () => {
        this.setState({ getParentAppModal: false });
        // TODO MOVE links constant
        const appUrl = 'itms-apps://apps.apple.com/app/id1536825037';
        if (IS_ANDROID) {
            appUrl = 'market://details?id=com.pennygem.parents';
        }
        Linking.openURL(appUrl);

    };

    render() {
        return (
            <Resizable>
                {(layout) => {
                    return (
                        <AuthContainer
                            clearAuth={this.props.actions.clearAuth}
                            footerButtonText={'Parent/Teacher Sign Up'}
                            footerInfoText={"Don't have an account?"}
                            onFooterButtonPress={this.onSignUpPress}
                        >
                            {/* <PopupModal visible={true} title={'Password Protection'} text={'Security measures such as a strong password protects your personal information that you have inputted into the app. This prevent unauthorized access and keep you protected against cyberattacks'} /> */}

                            <ModalContainer
                                visible={this.state.getParentAppModal}
                                onClose={() =>
                                    this.setState({ getParentAppModal: false })
                                }
                            >
                                <Text style={{ textAlign: 'center' }}>
                                    Please install Pennygem Parents in order to
                                    create parent account
                                </Text>
                                <AuthButton
                                    title={'Go to app'}
                                    onPress={this.onParentAppDownloadPress}
                                />
                            </ModalContainer>
                            {<Login layout={layout} {...this.props} />}
                        </AuthContainer>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    loginContainer: {
        // backgroundColor: 'white',
        minWidth: 300,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 15,
    },
    downloadParentApp: {
        borderRadius: 20,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 100,
        paddingVertical: 15,
        justifyContent: 'center',
        // ...(IS_WEB && { boxShadow: '2px 2px 15px #272827' }),
    },
    signIn: {
        color: Colors.black,
        textAlign: 'center',
        marginTop: 25,
        marginBottom: 15,
    },
    authModal: {
        borderRadius: 20,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 100,
        paddingVertical: 15,
        justifyContent: 'center',
    },
    emailNotVerifiedText: {
        fontSize: 35,
        color: Colors.gray,
        margin: 10,
        textAlign: 'center',
    },
    buttonContainer: { marginVertical: 12, width: '90%' },
    buttonGradient: {
        borderRadius: 20,
        justifyContent: 'center',
        height: 40,
        width: '100%',
        backgroundColor: '#7ec13d',
    },
    buttonText: { color: 'white', alignSelf: 'center', paddingHorizontal: 10 },
    inputContainer: { width: '100%', alignItems: 'center' },
});

export default connect(
    (state) => {
        return {
            // fullState:state,
            auth: state.auth,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions }, dispatch),
    })
)(LoginScreen);
