/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    TouchableOpacity,
    TouchableWithoutFeedback,
    StatusBar,
} from 'react-native';

// import Carousel from 'react-native-snap-carousel';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    useDimensions,
    useActive,
    useFocus,
    useHover,
    useREM,
    useScaledSize,
    Hoverable,
    Resizable,
} from 'react-native-web-hooks';
import Icon from 'react-native-vector-icons/FontAwesome';

// import ModalMobile from 'react-native-modal';
// import ModalWeb from 'modal-enhanced-react-native-web';

import firebase from 'firebase/app';
import 'firebase/database';

import Stars from '../../components/Stars';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';
import CounterTextInput from '../../components/CounterTextInput';

import { SectionTable, SectionRow } from '../../components/chequing/table';
import { ChildHeaderCard } from '../../components/Header/HeaderBlock';

import CarouselComponent from '../../components/Carousel/Carousel';
import ChildTransferButton from '../../components/chequing/ChildTransferButton';

import Intervals from '../../components/chequing/Intervals';
import Balance, {
    ChequingTotalActivity,
    Movement,
    Interest,
} from '../../components/chequing/balance';
import TransferBlock from '../../components/chequing/TransferBlock';

import ModalContainer from '../../components/Modal/Modal';

import * as authActions from '../../store/actions/kids/authActions';
import * as chequingActions from '../../store/actions/kids/chequingActions';
import * as savingsActions from '../../store/actions/kids/savingsActions';

import Colors from '../../constants/Colors';
import { defaultStyles } from '../../constants/style';
import { CHEQUING_TABLES } from '../../constants/screens/chequing';
import PopupModal, { openAcademyModal } from '../../components/Popup/PopupModal';
import { ROUTE_NAMES } from '../../navigation/navigationConstants';

const database = firebase.database;

class Chequing extends React.Component {
    static navigationOptions ({ navigation }){
        return {
            header: null,
        };
    };

    constructor(props) {
        super(props);
        this._carousel = React.createRef();

        this.state = {
            chequingPopupVisible: false,
            firstTimeSafetyPopup: true,
            type: 'Chequing',
            movement: 0,
            debit_card: 0,
            credit_card: 0,
            isLoading: true,
            interval: new Date(),
            intervalId: 1,
            interest_rate: 0,
            transferChequingVisible: false,
            transferSavingVisible: false,
            visible: false,
            intervals: [false, true, false, false],
            transfer: '',
            confirmation: false,
            scrolledHeader: new Animated.Value(0),
            scrolledPadding: new Animated.Value(0),
            header: false,
            conditions: {},
        };
    }

    async getCards() {
        const { parentId, childId } = this.props.auth;
        var settings_ref = database().ref(
            '/users/' + parentId + '/' + childId + '/settings'
        );
        const snapshot = await settings_ref.once('value');
        let settings_val = snapshot.val();
        this.setState({
            debit_card: settings_val.debit_card,
            credit_card: settings_val.credit_card,
        });
    }

    async componentDidMount() {
        const { parentId, childId } = this.props.auth;
        const { actions, navigation } = this.props;

        if (!this.props.auth.childId) {
            this.props.actions.clearAuth().then(() => {
                this.props.navigation.navigate('Auth');
            });
        }

        this.focusListener = navigation.addListener('didFocus', () => {
            this.getCards();

            this.state.interval.setMonth(new Date().getMonth() - 1);
            this.setState({ type: navigation.getParam('cards', 'Chequing') });

            navigation.getParam('cards', 'Chequing') === 'Chequing'
                ? actions.getChequing(
                      this.state.interval,
                      parentId,
                      childId,
                      navigation
                  )
                : // .then(()=>{actions.getSavings(this.state.interval, parentId, childId, navigation)})
                  actions.getSavings(
                      this.state.interval,
                      parentId,
                      childId,
                      navigation
                  );
        });

        // });
    }

    componentWillUnmount() {
        this.focusListener.remove();
    }

    parseInt(value) {
        return value !== '' ? parseInt(value, 10) : 0;
    }

    parseFloat(value) {
        return value ? parseFloat(value) : 0;
    }

    confirmationTitle() {
        const { chequing } = this.props;
        const conditions = this.state.conditions;
        const fees =
            conditions.transaction_fee_amount + conditions.monthly_fee_amount;

        if (conditions.available === false) {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 18,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Can’t transfer ${this.state.transfer.replace('$', '')}{' '}
                        {fees > 0 ? 'plus $' + fees + ' fees ' : ''}due to
                        insufficient funds.
                    </Text>
                    <Text
                        style={{
                            fontSize: 20,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Your chequing balance is only $
                        {this.parseFloat(chequing.chequing_balance).toFixed(2)}
                    </Text>
                </React.Fragment>
            );
        } else if (conditions.monthly_fee && !conditions.transaction_fee) {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 16,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Chequing balance will be below the minimum balance after
                        this transfer. Your account will be charged a monthly
                        fee of ${conditions.monthly_fee_amount}
                    </Text>
                    <Text
                        style={{
                            fontSize: 16,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        You can transfer up to $
                        {this.parseFloat(
                            chequing.chequing_balance - chequing.min_balance
                        ).toFixed(2)}{' '}
                        to stay above minimum balance. Still transfer?
                    </Text>
                </React.Fragment>
            );
        } else if (!conditions.monthly_fee && conditions.transaction_fee) {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 18,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        You are exceeding the maximum number of free
                        transactions.
                    </Text>
                    <Text
                        style={{
                            fontSize: 20,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        {' '}
                        This transfer will cost you $
                        {conditions.transaction_fee_amount}. Still continue?
                    </Text>
                </React.Fragment>
            );
        } else if (conditions.monthly_fee && conditions.transaction_fee) {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 15,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Chequing balance will be below the minimum balance after
                        this transfer. Your account will be charged a monthly
                        fee of ${conditions.monthly_fee_amount}
                    </Text>
                    <Text
                        style={{
                            fontSize: 15,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        You can transfer up to $
                        {this.parseFloat(
                            chequing.chequing_balance - chequing.min_balance
                        ).toFixed(2)}{' '}
                        to stay above minimum balance. Still transfer?
                    </Text>
                    <Text
                        style={{
                            fontSize: 15,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Also, you are exceeding the maximum number of free
                        transactions.
                    </Text>
                    <Text
                        style={{
                            fontSize: 15,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        This transfer will cost you $
                        {this.parseFloat(
                            conditions.transaction_fee_amount
                        ).toFixed(2)}
                        . Still continue?
                    </Text>
                    {/* <Text style={{fontSize:15, margin:5, color:'#5aca3c'}}>Transfering two fees</Text> */}
                    {/* <Text style={{fontSize:20, margin:5, color:'#5aca3c'}}>${this.state.transfer.replace('$','')} to Savings</Text> */}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 18,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Transfering
                    </Text>
                    <Text
                        style={{
                            fontSize: 20,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        ${this.state.transfer.replace('$', '')} to Savings
                    </Text>
                </React.Fragment>
            );
        }
    }

    confirmationChequing() {
        const { parentId, childId } = this.props.auth;

        const conditions = this.state.conditions;

        console.log(' TRANSFER conditions, ', conditions);

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* <Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('assets/Saving_color.png')} /> */}
                <View style={{ marginVertical: 15, alignItems: 'center' }}>
                    {this.confirmationTitle()}
                </View>
                {conditions.available === true ? (
                    <View
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'center',
                            marginHorizontal: 15,
                        }}
                    >
                        <View style={{ width: '40%' }}>
                            <TouchableNativeFeedback
                                onPress={() => {
                                    this.setState({
                                        transferChequingVisible: false,
                                        confirmation: false,
                                        transfer: '',
                                    });

                                    this.props.actions
                                        .transferChequing(
                                            this.state.transfer,
                                            parentId,
                                            childId
                                        )
                                        .then(() => {
                                            this.props.actions.getChequing(
                                                this.state.interval,
                                                parentId,
                                                childId,
                                                this.props.navigation
                                            );
                                        });
                                }}
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 35,
                                        borderRadius: 20,
                                        margin: 5,
                                        backgroundColor: 'rgba(126,193,61,1)',
                                    }}
                                >
                                    <Text
                                        style={[
                                            {
                                                fontSize: 19,
                                                marginVertical: 5,
                                                color: 'white',
                                            },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        YES
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                        <View style={{ width: '40%' }}>
                            <TouchableNativeFeedback
                                onPress={() =>
                                    this.setState({
                                        transferChequingVisible: false,
                                        confirmation: false,
                                        transfer: '',
                                    })
                                }
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 35,
                                        borderRadius: 20,
                                        margin: 5,
                                        backgroundColor: '#EE5060',
                                    }}
                                >
                                    <Text
                                        style={[
                                            {
                                                fontSize: 19,
                                                marginVertical: 5,
                                                color: 'white',
                                            },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        NO
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                    </View>
                ) : (
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                            marginHorizontal: 15,
                        }}
                    >
                        <View style={{ width: '40%' }}>
                            <TouchableNativeFeedback
                                onPress={() =>
                                    this.setState({
                                        transferChequingVisible: false,
                                        confirmation: false,
                                        transfer: '',
                                    })
                                }
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 35,
                                        borderRadius: 20,
                                        margin: 5,
                                        backgroundColor: '#EE5060',
                                    }}
                                >
                                    <Text
                                        style={[
                                            {
                                                fontSize: 19,
                                                marginVertical: 5,
                                                color: 'white',
                                            },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        OK
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                    </View>
                )}
            </View>
        );
    }

    confirmationSavingsTitle() {
        const conditions = this.state.conditions;
        const fees = conditions.withdraw_fee_amount;

        if (conditions.available === false) {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 18,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Can’t transfer ${this.state.transfer.replace('$', '')}{' '}
                        {fees > 0 ? 'plus $' + fees + ' fees ' : ''}due to
                        insufficient funds.
                    </Text>
                    <Text
                        style={{
                            fontSize: 20,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Your savings balance is only $
                        {this.parseFloat(this.state.savings_balance).toFixed(2)}
                    </Text>
                </React.Fragment>
            );
        } else if (conditions.withdraw_fee) {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 18,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        You are exceeding the maximum number of free
                        transactions.
                    </Text>
                    <Text
                        style={{
                            fontSize: 20,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        {' '}
                        This transfer will cost you $
                        {conditions.withdraw_fee_amount} Still continue?
                    </Text>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Text
                        style={{
                            fontSize: 18,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        Transfering
                    </Text>
                    <Text
                        style={{
                            fontSize: 20,
                            margin: 5,
                            ...defaultStyles.bold,
                        }}
                    >
                        ${this.state.transfer.replace('$', '')} to Chequing
                    </Text>
                </React.Fragment>
            );
        }
    }

    confirmationSavings() {
        const { parentId, childId } = this.props.auth;
        const conditions = this.state.conditions;

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Image
                    style={{ width: 70, height: 70, marginTop: '10%' }}
                    source={require('assets/Saving_color.png')}
                />
                <View style={{ marginVertical: 15 }}>
                    {this.confirmationSavingsTitle()}
                </View>
                {conditions.available === false ? (
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                            marginHorizontal: 15,
                        }}
                    >
                        <View style={{ width: '40%' }}>
                            <TouchableNativeFeedback
                                onPress={() =>
                                    this.setState({
                                        transferSavingVisible: false,
                                        confirmation: false,
                                        transfer: '',
                                    })
                                }
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 35,
                                        borderRadius: 20,
                                        margin: 5,
                                        backgroundColor: '#EE5060',
                                    }}
                                >
                                    <Text
                                        style={[
                                            {
                                                fontSize: 19,
                                                marginVertical: 5,
                                                color: 'white',
                                            },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        OK
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                    </View>
                ) : (
                    <View
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'center',
                            marginHorizontal: 15,
                        }}
                    >
                        <View style={{ width: '40%' }}>
                            <TouchableNativeFeedback
                                onPress={() => {
                                    this.setState({
                                        transferSavingVisible: false,
                                        confirmation: false,
                                        transfer: '',
                                    });
                                    this.props.actions
                                        .transferSaving(
                                            this.state.transfer,
                                            parentId,
                                            childId
                                        )
                                        .then(() => {
                                            this.props.actions.getSavings(
                                                this.state.interval,
                                                parentId,
                                                childId,
                                                this.props.navigation
                                            );
                                        });
                                }}
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 35,
                                        borderRadius: 20,
                                        margin: 5,
                                        backgroundColor: 'rgba(126,193,61,1)',
                                    }}
                                >
                                    <Text
                                        style={[
                                            {
                                                fontSize: 19,
                                                marginVertical: 5,
                                                color: 'white',
                                            },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        YES
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                        <View style={{ width: '40%' }}>
                            <TouchableNativeFeedback
                                onPress={() =>
                                    this.setState({
                                        transferSavingVisible: false,
                                        confirmation: false,
                                        transfer: '',
                                    })
                                }
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 35,
                                        borderRadius: 20,
                                        margin: 5,
                                        backgroundColor: '#EE5060',
                                    }}
                                >
                                    <Text
                                        style={[
                                            {
                                                fontSize: 19,
                                                marginVertical: 5,
                                                color: 'white',
                                            },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        NO
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                    </View>
                )}
            </View>
        );
    }

    openChequingModal() {
        this.setState({chequingPopupVisible: true})
    }

    render() {
        const { navigation, chequing, savings, actions } = this.props;
        const { parentId, childId } = this.props.auth;
        const { name } = this.props.child;

        console.log('SAVINGS DEPOSIT - ', chequing.savings_deposit);

        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    // console.log('global.window.screen.height',global.window.screen.height)
                    return (
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: 'white',
                                paddingTop: layout.height * 0.015,
                            }}
                        >
                            <PopupModal visible={true} title={'Beware Phishing'}
                                text={'With more and more of the world turning to digital solutions it is important to protect both your online business and banking infastruce. Refer to the video to learn more about protection against malware and other cyber attacks'}
                                // renderContent={this.renderPopupContent}
                                onButtonPress={() => {
                                    this.props.navigation.navigate(ROUTE_NAMES.VIDEO, {
                                        // title,
                                        // description,
                                        url: 'https://player.vimeo.com/video/677449372',
                                        // id,
                                    })
                                }}
                            />
                            <View
                                style={{
                                    position: 'absolute',
                                    width: 50,
                                    height: 50,
                                    borderRadius: 50,
                                    zIndex: 2,
                                    // shadowColor: "#272827", shadowOffset: { width: 2, height: 2, }, shadowOpacity: 0.75, shadowRadius: 10,
                                    // ...layout.width > 850 ? { right: '5%' } : { alignSelf: 'center' },
                                    alignSelf: 'center',
                                    flex: 1,
                                    bottom: 30,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: Colors.green1,
                                }}
                            >
                                <TouchableNativeFeedback
                                    onPress={() => {
                                        let makeVisible =
                                            this.state.type === 'Savings'
                                                ? 'transferSavingVisible'
                                                : 'transferChequingVisible';

                                        this.setState({
                                            [makeVisible]: true,
                                        });
                                    }}
                                >
                                    <View
                                        style={{
                                            borderRadius: 80,
                                            width: 70,
                                            height: 70,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor:
                                                'rgba(126,193,61,1)',
                                        }}
                                    >
                                        <Image
                                            resizeMode={'contain'}
                                            style={{
                                                zIndex: 3,
                                                width: 50,
                                                height: 50,
                                            }}
                                            source={
                                                this.state.type === 'Savings'
                                                    ? require('assets/Chequing_2.png')
                                                    : require('assets/Saving_4.png')
                                            }
                                        />
                                    </View>
                                </TouchableNativeFeedback>
                            </View>

                            {/* <ChildTransferButton interval={this.state.interval} /> */}

                            <ScrollView
                                style={{ flex: 1 }}
                                contentContainerStyle={{ paddingBottom: 20 }}
                            >
                                <ModalContainer
                                    onClose={() =>
                                        this.setState({
                                            transferSavingVisible: false,
                                            transfer: '',
                                            confirmation: false,
                                        })
                                    }
                                    visible={this.state.transferSavingVisible}
                                >
                                    {this.state.loading && (
                                        <View
                                            style={{
                                                position: 'absolute',
                                                zIndex: 1,
                                                justifyContent: 'center',
                                                height: '100%',
                                                width: '100%',
                                                backgroundColor:
                                                    'rgba(126, 193, 61, 0.1)',
                                            }}
                                        >
                                            <ActivityIndicator
                                                size={'large'}
                                                color={Colors.green1}
                                                style={{}}
                                            />
                                        </View>
                                    )}
                                    {this.state.confirmation ? (
                                        this.confirmationSavings()
                                    ) : (
                                        <TransferBlock
                                            title={'Transfer to Chequing'}
                                            label={'Amount to Transfer'}
                                            onPress={(transfer) => {
                                                this.setState({
                                                    loading: true,
                                                    transfer,
                                                });

                                                this.props.actions
                                                    .requestSavings(
                                                        transfer,
                                                        parentId,
                                                        childId
                                                    )
                                                    .then((resp) =>
                                                        this.setState({
                                                            transfer,
                                                            loading: false,
                                                            conditions: resp,
                                                            confirmation: true,
                                                        })
                                                    );
                                            }}
                                        />
                                    )}
                                </ModalContainer>

                                <ModalContainer
                                    onClose={() =>
                                        this.setState({
                                            transferChequingVisible: false,
                                            confirmation: false,
                                            transfer: '',
                                        })
                                    }
                                    visible={this.state.transferChequingVisible}
                                >
                                    {this.state.loading ? (
                                        <View
                                            style={{
                                                position: 'absolute',
                                                zIndex: 1,
                                                justifyContent: 'center',
                                                height: '100%',
                                                width: '100%',
                                                backgroundColor:
                                                    'rgba(126, 193, 61, 0.1)',
                                            }}
                                        >
                                            <ActivityIndicator
                                                size={'large'}
                                                color={Colors.green1}
                                                style={{}}
                                            />
                                        </View>
                                    ) : null}
                                    {this.state.confirmation ? (
                                        this.confirmationChequing()
                                    ) : (
                                        <TransferBlock
                                            title={'Transfer to Savings'}
                                            label={'Amount to Transfer'}
                                            onPress={(transfer) => {
                                                this.setState({
                                                    loading: true,
                                                    transfer,
                                                });

                                                this.props.actions
                                                    .requestChequing(
                                                        transfer,
                                                        parentId,
                                                        childId
                                                    )
                                                    .then((resp) =>
                                                        this.setState({
                                                            transfer,
                                                            loading: false,
                                                            conditions: resp,
                                                            confirmation: true,
                                                        })
                                                    );
                                            }}
                                        />
                                    )}
                                </ModalContainer>

                                <ChildHeaderCard
                                    name={name}
                                    navigation={navigation}
                                    signOut={() => {}}
                                    vmin={vmin}
                                    layout={layout}
                                    clearAuth={this.props.actions.clearAuth}
                                />

                                <CarouselComponent
                                    carouselRef={this._carousel}
                                    type={this.state.type}
                                    layout={layout}
                                    debit_card={this.state.debit_card}
                                    credit_card={this.state.credit_card}
                                    onSnapToItem={(index) => {
                                        let month = new Date();
                                        month.setMonth(month.getMonth() - 1);
                                        // navigation.setParams({'cards':index===0?'Chequing':'Savings'});
                                        this.setState(
                                            {
                                                intervalId: 1,
                                                isLoading: true,
                                                type:
                                                    index === 1
                                                        ? 'Chequing'
                                                        : 'Savings',
                                            },
                                            () => {
                                                index === 1
                                                    ? actions.getChequing(
                                                          month,
                                                          parentId,
                                                          childId,
                                                          navigation
                                                      )
                                                    : actions.getSavings(
                                                          month,
                                                          parentId,
                                                          childId,
                                                          navigation
                                                      );
                                            }
                                        );
                                    }}
                                />

                                <View
                                    style={{
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Intervals
                                        focused={this.state.intervalId}
                                        setFocused={(index) =>
                                            this.setState({ intervalId: index })
                                        }
                                        onPress={
                                            this.state.type === 'Chequing'
                                                ? actions.getChequing
                                                : actions.getSavings
                                        }
                                        type={this.state.type}
                                        parentId={parentId}
                                        childId={childId}
                                        navigation={navigation}
                                    />
                                </View>

                                {this.state.type === 'Chequing' ? (
                                    <View
                                        stlye={{
                                            witdh: '100%',
                                            paddingBottom: 0.1 * vmin,
                                        }}
                                    >
                                        <View
                                            style={{
                                                alignSelf: 'center',
                                                width: '90%',
                                                maxWidth: 600,
                                                marginTop: 30,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Balance
                                                layout={layout}
                                                balance={
                                                    chequing.chequing_balance
                                                }
                                                movement={chequing.movement}
                                            />
                                            <Movement
                                                balance={
                                                    chequing.chequing_balance
                                                }
                                                movement={chequing.movement}
                                                value={chequing.movement}
                                                isLoading={chequing.isLoading}
                                            />
                                        </View>

                                        <ChequingTotalActivity
                                            income={chequing.income}
                                            expense={chequing.expense}
                                            saved={chequing.saved}
                                            isLoading={chequing.isLoading}
                                        />

                                        <Hoverable
                                            onHoverIn={() => {
                                                if (this.state.firstTimeSafetyPopup) {
                                                    this.setState({firstTimeSafetyPopup: false})
                                                openAcademyModal({
                                                    title: 'Banking Safety Tip!',
                                                       text: `
Before reviewing your banking details, be sure to protect your information.

Acces in a safe and secure space
Use a secure wireless network
Use a trusted device (mobile or web)
Be sure to log off, once complete
`
                                                });
                                            }
                                                console.log('AFSDKLFJDSKLFJ');
                                                // this.openChequingModal()
                                            }}
                                        >{isHovered => {

                                            // if (isHovered) {
                                            // }
                                            return <View><SectionTable
                                            title={CHEQUING_TABLES.INCOME}
                                            value={chequing.income}
                                            transactions={chequing.income_trans}
                                        >
                                            <SectionRow
                                                data={chequing.allowance}
                                                title={'Allowance'}
                                                value={
                                                    chequing.allowance_income
                                                }
                                                transactions={
                                                    chequing.allowance_income_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={chequing.job}
                                                title={'Jobs'}
                                                value={chequing.job_income}
                                                transactions={
                                                    chequing.job_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={chequing.deposit}
                                                title={'Deposits'}
                                                value={chequing.deposit_income}
                                                transactions={
                                                    chequing.deposit_income_trans
                                                }
                                            />
                                        </SectionTable>
                                        </View>
                                        }}</Hoverable>


                                        <SectionTable
                                            title={CHEQUING_TABLES.EXPENSE}
                                            value={chequing.expense}
                                            transactions={
                                                chequing.expense_trans
                                            }
                                        >
                                            <SectionRow
                                                data={chequing.online_purchase}
                                                title={'Online Purchases'}
                                                value={chequing.online_expense}
                                                transactions={
                                                    chequing.online_expense_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={chequing.credit_purchase}
                                                title={'Credit Purchases'}
                                                value={chequing.credit_expense}
                                                transactions={
                                                    chequing.credit_expense_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View> */}
                                            <SectionRow
                                                data={
                                                    chequing.expence_fees_data
                                                }
                                                title={'Fees'}
                                                value={chequing.expence_fees}
                                                transactions={
                                                    chequing.expence_fees_trans
                                                }
                                            />
                                        </SectionTable>

                                        <SectionTable
                                            title={CHEQUING_TABLES.SAVING}
                                            value={chequing.saved}
                                            transactions={chequing.saved_trans}
                                        >
                                            <SectionRow
                                                data={chequing.savings_deposit}
                                                title={'Savings Deposits'}
                                                value={chequing.deposit_savings}
                                                transactions={
                                                    chequing.deposit_savings_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
                                            <SectionRow
                                                data={
                                                    chequing.savings_withdrawal
                                                }
                                                title={'Savings Withdrawals'}
                                                value={
                                                    chequing.withdraw_savings
                                                }
                                                transactions={
                                                    chequing.withdraw_savings_trans
                                                }
                                                // bottomBorder={true}
                                            />
                                        </SectionTable>
                                    </View>
                                ) : (
                                    <View>
                                        <View
                                            style={{
                                                alignSelf: 'center',
                                                width: '90%',
                                                maxWidth: 600,
                                                marginTop: 30,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Balance
                                                layout={layout}
                                                balance={
                                                    savings.savings_balance
                                                }
                                                movement={savings.total_savings}
                                            />
                                            <Interest
                                                interest={savings.interest}
                                                rate={savings.interest_rate}
                                            />
                                        </View>

                                        <SectionTable
                                            title={'Saved'}
                                            value={savings.total_savings}
                                            transactions={
                                                savings.total_savings_trans
                                            }
                                        >
                                            <SectionRow
                                                data={savings.deposits_arr}
                                                title={'Deposits'}
                                                value={savings.deposits}
                                                transactions={
                                                    savings.deposits_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={savings.withdrawals_arr}
                                                title={'Withdrawals'}
                                                value={savings.withdrawals}
                                                transactions={
                                                    savings.withdrawals_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={savings.savings_fees}
                                                title={'Fees'}
                                                value={
                                                    savings.savings_fees_amount
                                                }
                                                transactions={
                                                    savings.savings_fees_trans
                                                }
                                            />
                                        </SectionTable>
                                    </View>
                                )}
                                {/* </View> */}
                            </ScrollView>
                            {/* </ImageBackground> */}
                            {/* <Button title="to Homen" onPress={()=>{this.props.navigation.navigate('Home')}}/> */}
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({});

// export default Chequing
export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            child: state.child,
            chequing: state.kidChequing,
            savings: state.kidSavings,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...chequingActions, ...savingsActions },
            dispatch
        ),
    })
)(Chequing);
