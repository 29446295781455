
import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import firebase from 'firebase/app';
import 'firebase/database';

import { withNavigation } from 'react-navigation';
import config from '../../../constants/config'

const database = firebase.database;

function requestStore() {
    //fixme
    return {
        type: actionTypes.REQUEST_STORE,
        payload: {
            isLoading: true,
            data: []
        }
    }
}


function receiveStoreItems(data) {
    //fixme
    return {
        type: actionTypes.RECEIVE_STORE,
        payload: {
            data: data,
        }
    }
}

let mapOrder = {
    'Price Low to High': 'asc',
    'Price High to Low': 'desc',
    null:null,
}

export function getStoreItems({parentId, childId, sort, categories}) {
    return async dispatch => {
        let data= []
        dispatch(requestStore())
        let categoriesArr = Object.keys(categories)
        if (categoriesArr.includes('All')) {
            categoriesArr = []
        }
        const order = mapOrder[sort]
        // if (categoriesArr.length===0)
        console.log('parentId, childId, sort,categoriesArr ', parentId, childId, sort, order, categoriesArr)
        const body = {parentId, childId, sort:order, categories: categoriesArr}

        return  fetch(config.url + 'searchChildStore', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body)
        })
            .then(response => {return response.json()})
            .then(response => {console.log('response CHILD STOER', response); return response.data})



    }
}

export function getStoreItemsOld(type, filter, parentId, childId) {
    return async dispatch => {
        dispatch(requestStore())
        let data_child_items = (await database().ref('/users/' + parentId + '/' + childId + '/store/').once('value')).val();
        let data_child_items_obj = data_child_items ? data_child_items : {}

        // data_child_items = data_child_items ? Object.keys(data_child_items) : []
        // console.log('Object.keys(data_child_items).filter(item=>data_child_items[item])',Object.keys(data_child_items).filter(item=>data_child_items[item]))
        data_child_items = data_child_items ? Object.keys(data_child_items).filter(item => data_child_items[item]) : []

        console.log('Child Keys - ', data_child_items)
        if (type.includes('price')) {
            var data_source = database().ref('/store/').orderByChild('price');
        }
        else {
            var data_source = database().ref('/store/')
        }
        let data = []
        let filterList = filter['All'] ? [] : Object.keys(filter)
        data_source.once('value')
            // console.log('1')

            .then((snapshot) => {
                snapshot.forEach(function (child) {
                    data.push(child.val()); // NOW THE CHILDREN PRINT IN ORDER
                });
                console.log('3')
                type === 'priceLow' ? data : data.reverse();
                console.log('data - ', data)
                console.log('type - ', type)

                // data.filter(item=>item)

                console.log(filter)
                data = filterList.length ? data.filter(item => { return filterList.includes(item.category) && data_child_items.includes(item.amazon_id) }) :
                    data.filter(item => { return data_child_items.includes(item.amazon_id) })
                if (type.includes('new')) {
                    data = data.map(item => {
                        console.log('item, data_child_items_obj', item, data_child_items_obj);
                        return { ...item, created: data_child_items_obj[item.amazon_id] }
                    })
                    data.sort((a, b) => a.created > b.created)
                    console.log('SORT BY NEWEST', data)
                }
                // this.setState({data:Object.values(data), isLoading:false})
                dispatch(receiveStoreItems(data))

            })


    }
}

function checkPurchaseConditions() {

    return true
}


export function purchaseItem(item, parentId, childId, quantity) {
    return async dispatch => {

        console.log('1')
        const request_date = new Date();
        request_date.setDate(1);
        const root = database().ref();


        const old_chequing = (await root.child('users').child(parentId).child(childId).child('chequing').once('value')).val();
        let fee = 0;
        // let additional = (await database().ref('/users/' + parentId + '/' + childId + '/chequing/additional').once('value')).val();
        let additional = old_chequing.additional

        console.log('2')

        var data_source = database().ref('/users').child(parentId).child(childId).child('chequing').child('transactions')
        const snapshot = await data_source.orderByChild('transaction_date').startAt(request_date.toISOString()).once('value')
        let data = (snapshot.val())
        const transactions_number = data ? Object.keys(data).length : 0
        console.log('3')

        if (!additional) {
            if (item.price * quantity > old_chequing.balance) {
                return { result: 'fail' }
            }
            else {
                return {
                    result: 'confirm',
                    info: {
                        text: '', transaction_fee: fee, monthly_fee: 0,
                        chequing: { min_balance: 0, chequing_balance: old_chequing.balance }
                    }
                }
            }
        }

        if (transactions_number > old_chequing.transact_limit) {
            fee = old_chequing.transact_fee
        }
        else { fee = 0 }
        console.log('4 ', old_chequing.balance, typeof item.price, fee)
        if (old_chequing.balance < (item.price + fee) * quantity) {
            console.log('NOT ENOUGH MONEY')
            return { result: 'fail' }
        }

        if (old_chequing.balance - (item.price + fee) < old_chequing.min_balance && old_chequing.balance - (item.price + fee) * quantity > 0) {
            console.log('NOT ENOUGH MONEY')
            // fee = 5
            if (old_chequing.balance > old_chequing.min_balance) {
                return {
                    result: 'confirm', info: {
                        text: '', transaction_fee: fee, monthly_fee: old_chequing.monthly_fee,
                        chequing: { min_balance: old_chequing.min_balance, chequing_balance: old_chequing.balance }
                    }
                }
            }
            else {
                return {
                    result: 'confirm', info: {
                        text: '', transaction_fee: fee, monthly_fee: 0,
                        chequing: { min_balance: old_chequing.min_balance, chequing_balance: old_chequing.balance }
                    }
                }
            }

        }

        await root.child('users').child(parentId).child(childId).child('chequing').update({ balance: old_chequing.balance - (item.price + fee) * quantity });

        for (var i = 0; i < quantity; i++) {
            await root.child('users').child(parentId).child(childId).child('purchases').child('requested')
                .push({
                    item_id: 2,
                    item_name: item.name,
                    // link:  'https://www.amazon.ca/dp/' + item.link || 'NA',
                    link: item.link,
                    price: item.price,
                    purchase_id: 1,
                    request_date: (new Date()).toISOString(),
                    type: 'online purchase'
                })

            console.log('5')
            console.log(old_chequing.balance, item.price, fee)

            await root.child('users').child(parentId).child(childId).child('chequing/transactions')
                .push({
                    type: 'online purchase',
                    name: item.name,
                    amount: item.price,
                    transaction_date: (new Date()).toISOString()
                });

            if (fee) {
                await root.child('users').child(parentId).child(childId).child('chequing/transactions')
                    .push({
                        type: 'purchase transaction fee',
                        amount: fee,
                        transaction_date: (new Date()).toISOString()
                    });

            }
        }



        return { result: 'success' }
    }
}



export function finishPurchaseItem(item, parentId, childId, transact_fee, monthly_fee, quantity) {
    return async dispatch => {

        const root = database().ref();
        const old_chequing_balance = (await root.child('users').child(parentId).child(childId).child('chequing').child('balance').once('value')).val();

        console.log('old_chequing_balance', old_chequing_balance)

        await root.child('users').child(parentId).child(childId).child('chequing').update({ balance: old_chequing_balance - (item.price + monthly_fee + transact_fee) * quantity });

        for (var i = 0; i < quantity; i++) {

            await root.child('users').child(parentId).child(childId).child('purchases').child('requested')
                .push({
                    item_id: 2,
                    item_name: item.name,
                    // link:  'https://www.amazon.ca/dp/' + item.link || 'NA',
                    link: item.link || 'NA',
                    price: item.price,
                    purchase_id: 1,
                    request_date: (new Date()).toISOString(),
                    type: 'online purchase'
                })

            console.log('5')
            console.log(old_chequing_balance, item.price, transact_fee, monthly_fee)

            await root.child('users').child(parentId).child(childId).child('chequing/transactions')
                .push({
                    type: 'online purchase',
                    name: item.name,
                    amount: item.price,
                    transaction_date: (new Date()).toISOString()
                });

            if (transact_fee) {
                await root.child('users').child(parentId).child(childId).child('chequing/transactions')
                    .push({
                        type: 'purchase transaction fee',
                        amount: transact_fee,
                        transaction_date: (new Date()).toISOString()
                    });

            }
            if (monthly_fee) {
                await root.child('users').child(parentId).child(childId).child('chequing/transactions')
                    .push({
                        type: 'purchase account fee',
                        amount: monthly_fee,
                        transaction_date: (new Date()).toISOString()
                    });

            }
        }
        return { result: 'success' }
    }
}


export function increaseStoreCounter(parentId, childId, item) {
    return async dispatch => {
        const store = 'amazon'
        console.log('item ', item)
        const root = database().ref();

        // const root = database().ref();
        let storeItem = (await database().ref('store/' + store).orderByChild(store + '_id').equalTo(item[store + '_id']).once('value')).val()
        console.log('storeItem - ', storeItem)
        let storeItemId = Object.keys(storeItem)[0]
        storeItem = Object.values(storeItem)[0]

        let kids = storeItem.kids || 0;

        root.child('store/' + store + '/' + storeItemId)
            .update({
                kids: kids + 1
            });
    }
}


function dispatchClearStore() {
    return {
        type: actionTypes.CLEAR_STORE,
        payload: {
            data: [],
        }
    }
}

export function clearStoreData() {
    return dispatch => {
        // const body = { parentId, childId, }

        dispatch(dispatchClearStore())

    }
}

export function requestStoreItems(parentId, childId) {
    return dispatch => {
        const body = { parentId, childId, }

        return fetch(config.url + 'requestStoreItems', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
    }
}
