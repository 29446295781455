/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Image,
    ImageBackground,
    KeyboardAvoidingView,
    StyleSheet,
    View,
    Platform,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withNavigationFocus } from 'react-navigation';
import { throttle } from 'lodash';

import LinearGradient from 'react-native-linear-gradient';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
// import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText';

import ChangeAvatarBlock from '../../../components/settings/ChangeAvatar';
import { BlockWrapper } from '../../../components/newChild/BlockWrapper';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import avatars from '../../../constants/avatars';
import { kidInfo } from '../../../constants/info';
import globalStyle, { defaultStyles } from '../../../constants/style';
import { InputRow } from '../../../components/settings/rows/InputRow';
// import ModalContainer from '../../../components/Modal/Modal';

import {
    DEFAULT_CHEQUING,
    DEFAULT_SAVINGS,
    NEW_CHILD_SCREENS,
} from '../../../constants/data';
import { Loader } from '../../../components/Loader';
import { ContractCheckboxRow } from '../../../components/Contracts/ContractCheckboxRow';
import {
    createNewChild,
    createNewChildMain,
    sendChildRegistrationEmail,
} from '../../../helpers/newChild/newChildRequests';
import { XCloseButton } from '../../../components/XCloseButton';
import ModalContainer from '../../../components/Modal/Modal';
import { IS_ANDROID } from '../../../constants/constants';
import { pickCameraImage, pickGalleryImage, resizeImage } from '../../../helpers/changeAvatar';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const auth = firebase.auth;
const database = firebase.database;

const FULL_SCREEN_MODAL_PROPS = {
    animationIn: 'slideInLeft',
    animationOut: 'slideOutLeft',
    animationInTiming: 0,
    animationOutTiming: 0,
    backdropColor: 'darkgrey',
    backdropOpacity: 0.1,
    style: { margin: 0 },
};

function validateEmailInit(email) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
}

const validateEmail = throttle(validateEmailInit, 1000);

const PROFILE_TITLE = NEW_CHILD_SCREENS.CREATE_CHILD_PROFILE;
const description = 'Let’s now create your child’s profile.';

// TODO continue refactor move modals
const SelectChildAvatarModal = ({
    changeAvatarVisible,
    imagePickerVisible,
    onChangeAvatarClose,
    onImagePickerClose,
    onAvatarPress,
    layout,
    state,
}) => {
    return (
        <>
            <Modal
                {...FULL_SCREEN_MODAL_PROPS}
                onBackdropPress={onChangeAvatarClose}
                isVisible={changeAvatarVisible}
                onRequestClose={onChangeAvatarClose}
            >
                <View elevation={5} style={selectChildStyles.container}>
                    <XCloseButton
                        onPress={onChangeAvatarClose}
                        closeStyle={{ tintColor: Colors.rose }}
                    />
                    <View
                        elevation={5}
                        style={{
                            position: 'absolute',
                            top: layout.height * 0.085,
                            alignItems: 'center',
                            marginVertical: 5,
                        }}
                    >
                        <Text
                            style={[
                                { textAlign: 'center' },
                                defaultStyles.bold,
                                globalStyle.h2,
                            ]}
                        >
                            Change Avatar
                        </Text>
                    </View>
                    <ChangeAvatarBlock
                        image={state.image}
                        avatar={state.avatar}
                        layout={layout}
                        onPress={onAvatarPress}
                    />
                </View>
            </Modal>
            <Modal
                {...FULL_SCREEN_MODAL_PROPS}
                isVisible={imagePickerVisible}
                onBackdropPress={onImagePickerClose}
                onRequestClose={onImagePickerClose}
            >
                <View
                    elevation={5}
                    style={{
                        alignSelf: 'center',
                        flex: 1,
                        justifyContent: 'center',
                        maxHeight: 500,
                        maxWidth: 500,
                        backgroundColor: 'white',
                        height: '100%',
                        width: '100%',
                        padding: 15,
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                    }}
                >
                    <XCloseButton
                        onPress={onImagePickerClose}
                        closeStyle={{ tintColor: Colors.rose }}
                    />
                    <View>
                        <Text
                            style={[
                                { color: '#7ec13d' },
                                defaultStyles.bold,
                                globalStyle.h2,
                            ]}
                        >
                            Select
                        </Text>
                    </View>
                    <TouchableNativeFeedback
                        onPress={() => {
                            this.setState({
                                imagePickerVisible: false,
                            });

                            this._launchCamera().then(() => {
                                this.setState({
                                    changeAvatarVisible: false,
                                });
                            });
                        }}
                    >
                        <View
                            style={{
                                width: '80%',
                                borderRadius: 10,
                                backgroundColor: '#7ec13d',
                                padding: 10,
                                paddingHorizontal: 15,
                                margin: 10,
                            }}
                        >
                            <Text style={{ color: 'white' }}>Open Camera</Text>
                        </View>
                    </TouchableNativeFeedback>
                    <TouchableNativeFeedback
                        onPress={() => {
                            this.setState({
                                imagePickerVisible: false,
                            });

                            this._pickImage().then(() => {
                                this.setState({
                                    changeAvatarVisible: false,
                                });
                            });
                        }}
                    >
                        <View
                            style={{
                                width: '80%',
                                borderRadius: 10,
                                backgroundColor: '#7ec13d',
                                padding: 10,
                                paddingHorizontal: 15,
                                margin: 10,
                            }}
                        >
                            <Text style={{ color: 'white' }}>Pick Image</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </Modal>
        </>
    );
};

const selectChildStyles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 0,
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        padding: 15,
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 1)',
    },
});

export const Button = ({ title, onPress }) => {
    return (
        <TouchableNativeFeedback onPress={onPress}>
            <LinearGradient
                // start={[0, 1]}
                // end={[1, 0]}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                colors={[Colors.green2, Colors.green1]}
                // colors={['#de3c95', '#ffd71b']}
                style={buttonStyles.gradient}
            >
                <Text style={[buttonStyles.text, defaultStyles.bold]}>
                    {title.toUpperCase()}
                </Text>
            </LinearGradient>
            {/* <View style={{justifyContent: 'center', height: 40, width: '100%', backgroundColor: '#7ec13d', borderRadius: 5,}}>
                <Text style={{ fontSize: 19, color: 'white', alignSelf: 'center', margin: 5, }}>
                    {title.toUpperCase()}</Text></View> */}
        </TouchableNativeFeedback>
    );
};

export const ProfileButton = ({ title, onPress }) => {
    return (
        <TouchableNativeFeedback
            style={buttonStyles.container}
            onPress={onPress}
        >
            <LinearGradient
                // start={[0, 1]}
                // end={[1, 0]}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                colors={[Colors.green2, Colors.green1]}
                // colors={['#de3c95', '#ffd71b']}
                style={buttonStyles.gradient}
            >
                <Text style={[buttonStyles.text, defaultStyles.bold]}>
                    {title.toUpperCase()}
                </Text>
            </LinearGradient>
        </TouchableNativeFeedback>
    );
};

const buttonStyles = StyleSheet.create({
    container: { flex: 1, marginHorizontal: 10 },
    gradient: {
        // width: '100%',
        zIndex: 1,
        marginVertical: 10,
        justifyContent: 'center',
        height: 40,
        backgroundColor: '#7ec13d',
        borderRadius: 20,
    },
    text: {
        fontSize: 19,
        color: 'white',
        alignSelf: 'center',
        margin: 5,
    },
});

class CreateProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => {
                return { ...prev, [curr]: '' };
            }, {}),
            confirmPassword: '',
            avatar: 1,
            nameInput: true,
            usernameInput: true,
            passwordInput: true,
            confirmPasswordInput: true,
            // usernameInput: true,
            emailInput: true,
            avatarImage: '',
            available: null,
        };

        this.onNameInput = this.onNameInput.bind(this);
        this.onUserNameInput = this.onUserNameInput.bind(this);
        this.onCreateChildPress = this.onCreateChildPress.bind(this);
        this.renderAvailabilityPlaceholder =
            this.renderAvailabilityPlaceholder.bind(this);

        this.onEmailInput = this.onEmailInput.bind(this);
    }

    componentDidMount() {
        const { newKid } = this.props.parent;

        console.log('newKid Profile ', newKid);
        this.setState({
            ...Object.keys(newKid.profile).reduce(
                (prev, curr, currIndex, arr) => {
                    return { ...prev, [curr]: newKid.profile[curr] };
                },
                {}
            ),
            available:
                newKid.profile.userName &&
                this.checkIfAvailable(newKid.profile.userName),
            confirmPassword: newKid.profile.password || '',
        });
        // Object.keys(newKid).map(item=>this.setState)

        // this.checkIfAvailable(newKid.profile.username)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFocused !== this.props.isFocused) {
            if (this.props.isFocused) {
                const { newKid } = this.props.parent;

                console.log(
                    'newKid Profile IS FOCUSED',
                    newKid,
                    Object.keys(newKid.profile)
                );

                Object.keys(newKid.profile).length
                    ? this.setState({
                          ...Object.keys(newKid.profile).reduce(
                              (prev, curr, currIndex, arr) => {
                                  return {
                                      ...prev,
                                      [curr]: newKid.profile[curr],
                                  };
                              },
                              {}
                          ),
                          available:
                              newKid.profile.userName &&
                              this.checkIfAvailable(newKid.profile.userName),
                          confirmPassword: newKid.profile.password || '',
                      })
                    : this.setState({
                          name: '',
                          userName: '',
                          password: '',
                          confirmPassword: '',
                          email: '',
                          age: '',
                          // available: newKid.profile.userName && this.checkIfAvailable(newKid.profile.userName),
                          // confirmPassword: newKid.profile.password || ''
                      });
            }
            // Use the `this.props.isFocused` boolean
            // Call any action
        }
    }

    componentWillUnmount() {
        // Remove the event listener
        // this.focusListener.remove();
    }

    checkIfAvailable(name) {
        if (!name) {
            return Promise.resolve();
        }

        return (
            database()
                .ref('username/')
                .orderByKey()
                .equalTo(name.toLowerCase())
                // .startAt(name.toUpperCase())
                // .endAt(name.toLowerCase())
                .once('value')
                .then((snap) => {
                    console.log('snap.val() - ', snap.val(), !snap.val());
                    (!snap.val() && name) !== this.state.available
                        ? this.setState({ available: !snap.val() && name })
                        : null;

                    return snap.val();
                })
        );
    }

    uriToBlob = (uri) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.onload = function () {
                // return the blob
                console.log(xhr.response);
                resolve(xhr.response);
            };

            xhr.onerror = function () {
                // something went wrong
                reject(new Error('uriToBlob failed'));
            };

            // this helps us get a blob
            xhr.responseType = 'blob';

            xhr.open('GET', uri, true);
            xhr.send(null);
        });
    };

    _launchCamera = async () => {
        const result = await pickCameraImage({});

        console.log(result);

        if (!result.cancelled) {
            this.uriToBlob(result.uri).then((blob) => {
                // storage().ref('children/' + childId + '.png').put(blob)
                // 	.then((res) => {
                // 		return res
                // 	})
            });

            this.setState({ avatarImage: result.uri });
            AsyncStorage.setItem('avatar', result.uri);
            // this.props.actions.saveAvatar(parentId, childId, 5, result.uri)
        }
    };

    _pickImage = async () => {
        const result = await pickGalleryImage({});

        console.log(result);
        // ImageManipulator.manipulateAsync(uri, actions, saveOptions)

        if (!result.cancelled) {
            let uri = result.uri;

            console.log(result);
            if (Platform.OS === 'web') {
                const manipResult = resizeImage({path: result.uri});
                // const manipResult = await ImageManipulator.manipulateAsync(
                //     result.uri,
                //     //  [{ crop: { originX:0, originY:0, width:'100%', height:'100%' } }, ],
                //     { compress: 0, format: ImageManipulator.SaveFormat.PNG }
                // );

                console.log('manipResult, ', manipResult);
                uri = manipResult.uri;
            }

            this.uriToBlob(uri).then((blob) => {
                // storage().ref('children/' + childId + '.png').put(blob)
                // 	.then((res) => {
                // 		return res
                // 	})
            });

            this.setState({ avatarImage: uri });
            AsyncStorage.setItem('avatar', uri);
            // this.props.actions.saveAvatar(parentId, childId, 5, uri)
        }
    };

    formatUsername(text) {
        return text.replace(/( |-|,)/g, '');
    }

    formatAge(text) {
        return text.replace(/\D/g, '');
    }

    checkConditions() {
        const { name, userName, available, email, age, contractApproved } = this.state;
        const { childIds } = this.props.parent;

        console.log('name,userName,available ', name, userName, available, age);

        this.setState({
            nameInput: !!name,
            usernameInput: this.state.available,
            emailInput: email ? validateEmail(email) : true,
            contractApprovedError: !contractApproved,
        });

        return (
            name &&
            userName &&
            available &&
            (childIds.length ? true : contractApproved)
        );
    }

    onEmailInput(text) {
        this.setState({ emailInput: true, email: text });
    }

    async generateAvailable(name) {
        console.log('Starting!');

        let tmpName = name;
        let counter = 0;

        while (await this.checkIfAvailable(tmpName.toLowerCase())) {
            console.log('new name  - ', counter, tmpName);
            tmpName = name + counter;
            counter += 1;
        }

        return this.setState({ userName: tmpName });
    }

    // TODO add throttle / debounce
    onNameInput(text) {
        this.checkIfAvailable(text).then((res) => {
            res ? this.generateAvailable(text) : null;
        });

        this.setState({
            usernameInput: true,
            nameInput: true,
            name: text,
            userName: this.formatUsername(text),
        });
    }

    onUserNameInput(text) {
        console.log('chaingin?');
        // this.checkIfAvailable(text);
        this.setState(
            {
                usernameInput: true,
                userName: this.formatUsername(text),
            },
            () => {
                this.checkIfAvailable(text);
            }
        );
    }

    onCreateChildPress() {
        const { name, userName, email, age } = this.state;
        const { parentId } = this.props.auth;
        const { actions, startLoader, stopLoader } = this.props;

        // TODO add create child func
        // TODO add email check func
        if (this.checkConditions()) {
            startLoader();

            // console.log('CHECK CONDIITONS EMAIL ', this.props.parent?.user?.user?.email)
            // console.log('CHECK CONDIITONS EMAIL', 'sdfsdf', !!email)

            createNewChildMain({
                parentId,
                profile: { name, userName, email, age },
                chequing: DEFAULT_CHEQUING,
                savings: DEFAULT_SAVINGS,
                email: email || this.props.parent?.user?.user?.email,
                childEmail: !!email,
            })
                .then(() => {
                    return actions.getChildNodes(parentId);
                })
                .then(() => {
                    stopLoader();
                    this.setState({ loading: false });
                    //   navigation.navigate('Settings')
                    this.props.navigation.navigate('Dashboard');
                })
                .catch(() => {
                    this.setState({ loading: false, errorModalVisible: true });
                });

            // TODO move to cloud function
            // sendChildRegistrationEmail({
            //     email: email || this.props.parent?.user?.user?.email,
            // });

            // this.props.proceed();
        }
    }

    renderAvailabilityPlaceholder() {
        if (!this.state.userName) {
            return <Text> </Text>;
        }

        console.log('AVAILABILITY - ', this.state.available);

        if (this.state.available) {
            return <Text style={{ color: Colors.green1 }}>Available</Text>;
        }

        return <Text style={{ color: Colors.red }}>Not Available</Text>;
    }

    render() {
        const { navigation } = this.props;
        const { childIds } = this.props.parent;

        const { contractApprovedError } = this.state;

        console.log('this.props.parent?.user?.user?.email ', this.props.parent?.user?.user?.email)


        return (
            <BlockWrapper
                navigation={navigation}
                title={PROFILE_TITLE}
                description={description}
                showProgress={true}
                onClosePress={() => navigation.navigate('AccountSettings')}
            >
                {({ layout }) => (
                    <>
                        <SelectChildAvatarModal
                            changeAvatarVisible={this.state.changeAvatarVisible}
                            imagePickerVisible={this.state.imagePickerVisible}
                            onChangeAvatarClose={() => {
                                this.setState({ changeAvatarVisible: false });
                            }}
                            onImagePickerClose={() => {
                                this.setState({ imagePickerVisible: false });
                            }}
                            onAvatarPress={(index) => {
                                this.setState({
                                    imagePickerVisible:
                                        index === 5 ? true : false,
                                    changeAvatarVisible:
                                        index === 5 ? true : false,
                                });

                                // this.save_avatar(index)
                                this.setState({ avatar: index });
                            }}
                            layout={layout}
                            state={this.state}
                        />
                        {/* TODO show error message */}
                        <ModalContainer
                            visible={this.state.errorModalVisible}
                            // visible={true}
                            fullScreen={false}
                            onClose={() =>
                                this.setState({ errorModalVisible: false })
                            }
                        >
                            <Text style={styles.errorModalTitle}>
                                Something went wrong
                            </Text>
                            <Text style={styles.errorModalText}>
                                Please try again later
                            </Text>
                        </ModalContainer>
                        <View
                            style={{
                                ...styles.container,
                                flexDirection:
                                    layout.width > 850 ? 'row' : 'column',
                            }}
                        >
                            <TouchableNativeFeedback
                                style={[styles.avatarContainer]}
                                onPress={() =>
                                    this.setState({
                                        changeAvatarVisible: true,
                                    })
                                }
                            >
                                <ImageBackground
                                    resizeMode={'contain'}
                                    style={styles.avatar}
                                    source={
                                        this.state.avatarImage
                                            ? {
                                                  uri: this.state.avatarImage,
                                              }
                                            : avatars[this.state.avatar]
                                    }
                                >
                                    <View style={styles.avatarSelectContainer}>
                                        <Icon
                                            name={'pen'}
                                            size={22}
                                            color={'white'}
                                        />
                                    </View>
                                </ImageBackground>
                            </TouchableNativeFeedback>
                            <View style={styles.inputContainer}>
                                <InputRow
                                    text={this.state.name}
                                    onChangeText={this.onNameInput}
                                    error={!this.state.usernameInput}
                                    inputParams={{}}
                                    label={kidInfo.name.label}
                                    infoButtonText={kidInfo.name.text}
                                />

                                <InputRow
                                    text={this.state.userName}
                                    onChangeText={this.onUserNameInput}
                                    error={!this.state.usernameInput}
                                    inputParams={{}}
                                    label={kidInfo.userName.label}
                                    infoButtonText={kidInfo.userName.text}
                                />

                                <View style={styles.availabilityInfoContainer}>
                                    {this.renderAvailabilityPlaceholder()}
                                </View>

                                <InputRow
                                    text={this.state.email}
                                    onChangeText={this.onEmailInput}
                                    error={!this.state.emailInput}
                                    inputParams={{}}
                                    label={kidInfo.email.label}
                                    infoButtonText={kidInfo.email.text}
                                />

                                <InputRow
                                    text={this.state.age}
                                    onChangeText={(text) => {
                                        this.setState({
                                            age: this.formatAge(text),
                                        });
                                    }}
                                    label={kidInfo.age.label}
                                    infoButtonText={kidInfo.age.text}
                                />
                            </View>
                        </View>

                        {childIds.length > 0 ? null : <ContractCheckboxRow
                            selected={this.state.contractApproved}
                            setSelected={() =>
                                this.setState({
                                    contractApproved:
                                        !this.state.contractApproved,
                                    contractApprovedError: false,
                                })
                            }
                            checkboxStyle={
                                contractApprovedError && {
                                    borderColor: Colors.red,
                                }
                            }
                        />}

                        <View style={styles.buttonContainer}>
                            <ProfileButton
                                title={'Done'}
                                onPress={this.onCreateChildPress}
                            />
                        </View>
                    </>
                )}
                {/* <View style={{justifyContent: "flex-end", flexDirection: 'row' }}>
                </View> */}
                {/* </View> */}
            </BlockWrapper>
        );
    }
}

const WithNavigationProfile = withNavigationFocus(CreateProfile);

class Profile extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            headerShown: false,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false,
        };
    }

    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 });
        }
    }

    render() {
        // console.log('this.props.state', this.props.fullState)
        // if (!this.state.fontsLoaded) {
        // return <AppLoading />
        // }
        return (
            <KeyboardAvoidingView
                behavior={IS_ANDROID ? 'height' : 'position'}
                style={{ height: '100%', flex: 1 }}
                contentContainerStyle={{flex: 1}}
            >
                <Loader loading={this.state.loading} />

                <WithNavigationProfile
                    actions={this.props.actions}
                    navigation={this.props.navigation}
                    childIds={this.props.parent.childIds}
                    // childIds={[]}
                    auth={this.props.auth}
                    parent={this.props.parent}
                    back={() =>
                        // this.setState({ section: this.state.section - 1 })
                        this.props.navigation.goBack()
                    }
                    kidData={this.props.actions.kidData}
                    proceed={() => {
                        this.props.navigation.navigate('FirstJob');
                    }}
                    startLoader={() => this.setState({ loading: true })}
                    stopLoader={() => this.setState({ loading: false })}
                />
            </KeyboardAvoidingView>
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    container: {
        width: '100%',
        maxWidth: 450,
        borderRadius: 10,
        paddingBottom: 10,
    },
    avatarContainer: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        overflow: 'hidden',
        height: 100,
        width: 100,
        borderWidth: 0,
        borderRadius: 200,
        padding: 0,
    },
    avatar: {
        height: 110,
        width: 110,
        overflow: 'hidden',
        borderRadius: 55,
        alignItems: 'center',
        justifyContent: 'flex-end',
        // height: vmin * 0.5, width: vmin * 0.5, maxHeight: 100, maxWidth: 100,
    },
    avatarSelectContainer: {
        bottom: 0,
        backgroundColor: 'rgba(128,128,128,0.5)',
        width: '90%',
        height: '40%',
        alignItems: 'center',
        paddingTop: 5,
        // justifyContent:'center'
    },
    inputContainer: {
        zIndex: -1,
        paddingHorizontal: 35,
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    buttonContainer: {
        justifyContent: 'space-around',
        width: '100%',
        maxWidth: 381,
        paddingHorizontal: 35,
    },
    availabilityInfoContainer: {
        // marginTop: 2,
        marginBottom: 5,
        alignSelf: 'flex-start',
        marginLeft: 20,
    },
    errorModalTitle: {
        ...defaultStyles.bold,
        fontSize: 18,
    },
    errorModalText: {
        color: Colors.gray1,
    },
});

export default connect(
    (state) => {
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...kidAuthActions },
            dispatch
        ),
    })
)(Profile);
