// import { appConstants } from "";
import * as actionTypes from '../../actions/parents/actionTypes';

function insert(element, array) {
    let placeIndex = 0;

    if (!array.length) {
        return 0;
    }

    if (array[array.length - 1].deadline < element.deadline) {
        return array.length;
    }

    const found = array.find((item, index) => {
        console.log(
            'item.deadline,element.deadline \n',
            item.deadline,
            '\n',
            element.deadline,
            '\n',
            index
        );

        placeIndex = item.deadline > element.deadline ? index : 0;

        return item.deadline > element.deadline;
    });

    return placeIndex;
}

const initialState = {
    isLoading: true,
    data: {},
    completed: {},
    pending: {},
};

export default function chat(state = initialState, action) {
    const data = {};

    switch (action.type) {
        case actionTypes.REQUEST_JOBS:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.RECEIVE_JOBS:
            // console.log('action.payload.data')
            // console.log(action.payload.data)

            return {
                ...state,
                isLoading: false,
                data: action.payload.data
                    ? {
                          ...state.data,
                          [action.payload.childId]: action.payload.data,
                      }
                    : {},
                completed: action.payload.completed
                    ? {
                          ...state.completed,
                          [action.payload.childId]: action.payload.completed,
                      }
                    : {},
                pending: action.payload.pending
                    ? {
                          ...state.pending,
                          [action.payload.childId]: action.payload.pending,
                      }
                    : {},
                // data:action.payload.data?{...state.data,[action.payload.childId]:action.payload.data}:{}
            };

        case actionTypes.COMPLETE_JOB:
            console.log('actionTypes.COMPLETE_JOB parents ', action);

            return {
                ...state,
                isLoading: false,
                data: state.data.filter((item) => {
                    return item.key !== action.payload.jobId;
                }),
            };

        case actionTypes.ADD_JOB:
            // console.log('2', action.payload)
            const data = state.data;
            const newData = action.payload.data;

            console.log('1', action.payload);
            const prevData = data[action.payload.childId] || [];
            const placeIndex = insert(newData, prevData);

            console.log('placeIndex', placeIndex);
            // const newData =
            prevData.splice(placeIndex, 0, newData);
            console.log(
                'data, prevData - ',
                data[action.payload.childId],
                prevData
            );
            // console.log( 'newData',  newData)

            return {
                ...state,
                data: { ...data, [action.payload.childId]: prevData },
                // data:{...data, [action.payload.childId]:[...prevData, action.payload.data]}
            };

        default:
            return state;
    }
}
