/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import { MaterialIcons } from 'react-native-vector-icons';

import Text from '../CustomText';

import TouchableNativeFeedback from '../TouchableFeedback';

import Colors from '../../constants/Colors';

export function AddChildren({
    allKids,
    selectedKids,
    setSelectedKids,
    kids,
    setKid,
    removeKid,
    names,
}) {
    const [childrenVisible, setChildrenVisible] = useState(false);

    // const allKids = Object.keys(kids).map((item, index) => { return { id: index, name: names[item] } })
    // const selectedKids = Object.keys(kids).filter(item => kids[item]).map((item, index) => { return { id: index, name: names[item] } })

    function SelectedChild({ title, onPress }) {
        return (
            <View
                style={{
                    margin: 5,
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginLeft: 0,
                    padding: 5,
                    paddingLeft: 0,
                    borderRadius: 10,
                    backgroundColor: 'white',
                }}
            >
                <View>
                    <Text style={{ color: Colors.gray1 }}>{title}</Text>
                </View>
                <TouchableNativeFeedback onPress={onPress}>
                    <View
                        style={{
                            marginHorizontal: 5,
                            width: 18,
                            height: 18,
                            borderRadius: 10,
                            marginLeft: 5,
                            backgroundColor: Colors.rose,
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0.7,
                        }}
                    >
                        <Text style={{ color: 'white' }}>x</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        );
    }

    const kidSelectorMobile = () => {
        // const allKids = Object.values(kids)
        // const selectedKids = Object.values(kids)
        console.log('kids, names - ', kids, names);

        return (
            <SectionedMultiSelect
                styles={{
                    container: {
                        width: '50%',
                        alignSelf: 'center',
                        maxHeight: 40 * (allKids.length + 1),
                        justifyContent: 'center',
                    },
                    modalWrapper: { justifyContent: 'center' },
                    selectToggle: {
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderWidth: 2,
                        borderRadius: 20,
                        borderColor: Colors.gray,
                        height: 40,
                        paddingTop: 5,
                    },
                    selectToggleText: {
                        paddingLeft: 10,
                        fontFamily: 'Carmen Sans Regular',
                        color: Colors.gray1,
                        paddingBottom: 0,
                        marginBottom: 0,
                        textAlignVertical: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                }}
                items={allKids}
                // items={['Dfd', 'sdf']}
                IconRenderer={MaterialIcons}
                uniqueKey={'id'}
                // subKey="children"
                selectText={'Select children'}
                showDropDowns={true}
                hideSearch={true}
                dropDownToggleIconUpComponent={
                    <View
                        style={{
                            height: 10,
                            width: 10,
                            backgroundColor: 'red',
                        }}
                    ></View>
                }
                dropDownToggleIconDownComponent={
                    <View
                        style={{
                            height: 10,
                            width: 10,
                            backgroundColor: 'red',
                        }}
                    ></View>
                }
                // alwaysShowSelectText={true}
                // readOnlyHeadings={true}
                onToggleSelector={(item) => {
                    console.log('item , ', item);
                }}
                onSelectedItemsChange={(item) => {
                    console.log('iten!! ,', item);
                    setSelectedKids(item);
                }}
                selectedItems={selectedKids}
                // selectedItems={se}
                modalWithTouchable={true}
            />
        );
    };

    return Platform.OS !== 'web' ? (
        kidSelectorMobile()
    ) : (
        <TouchableNativeFeedback
            style={{ zIndex: 2, width: '80%' }}
            onPress={() => setChildrenVisible(!childrenVisible)}
        >
            {/* <View style={{ }}> */}
            <View
                style={{
                    marginBottom: 10,
                    marginTop: 30,
                    minHeight: 40,
                    borderWidth: 2,
                    borderColor: Colors.gray1,
                    borderRadius: 20,
                    justifyContent: 'center',
                    borderBottomLeftRadius: childrenVisible ? 0 : 20,
                    borderBottomRightRadius: childrenVisible ? 0 : 20,
                    alignItems: 'center',}}>
                {
                    <View
                        style={{
                            width: '100%',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            paddingRight: 30,
                            paddingLeft: '10%',
                        }}
                    >
                        {Object.keys(kids).filter((item) => kids[item])
                            .length ? (
                            Object.keys(kids).map((item) => {
                                return kids[item] ? (
                                    <SelectedChild
                                        title={names[item]}
                                        onPress={() => {
                                            removeKid(item);
                                        }}
                                    />
                                ) : null;
                            })
                        ) : (
                            <Text style={{ color: Colors.gray1 }}>
                                Please select children
                            </Text>
                        )}
                    </View>
                }
                <View
                    style={[
                        {
                            right: '5%',
                            width: 0,
                            height: 0,
                            position: 'absolute',
                            backgroundColor: 'transparent',
                            borderStyle: 'solid',
                            borderTopWidth: 10,
                            borderRightWidth: 5,
                            borderBottomWidth: 0,
                            borderLeftWidth: 5,
                            borderTopColor: Colors.gray1,
                            borderRightColor: 'transparent',
                            borderBottomColor: 'transparent',
                            borderLeftColor: 'transparent',
                        },
                    ]}
                ></View>
                {childrenVisible && (
                    <View
                        style={{
                            borderBottomLeftRadius: 20,
                            borderBottomRightRadius: 20,
                            borderColor: Colors.gray1,
                            backgroundColor: 'white',
                            width: '101%',
                            zIndex: 2,
                            position: 'absolute',
                            top: '98%',
                            borderWidth: 2,
                            marginBottom: 10,
                        }}
                    >
                        {Object.keys(kids).map((item) => {
                            return (
                                <TouchableNativeFeedback
                                    key={item}
                                    onPress={() => setKid(item)}
                                >
                                    <View
                                        style={{
                                            width: '100%',
                                            justifyContent: 'flex-start',
                                            flexDirection: 'row',
                                            marginVertical: 5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <View
                                            style={{
                                                marginLeft: 10,
                                                borderWidth: 2,
                                                borderRadius: 5,
                                                borderColor: Colors.gray1,
                                            }}
                                        >
                                            <Icon
                                                style={{
                                                    zIndex: 3,
                                                    opacity: kids[item] ? 1 : 0,
                                                }}
                                                name="check"
                                                size={16}
                                                color={Colors.gray1}
                                            />
                                        </View>
                                        <Text
                                            style={{
                                                marginLeft: 10,
                                                color: Colors.gray1,
                                            }}
                                        >
                                            {names[item]}
                                        </Text>
                                    </View>
                                </TouchableNativeFeedback>
                            );
                        })}
                        {/* {[1,2,3,4,5,6,7].map(item=><Text style={{margin:10}}>qweqwe</Text>)} */}
                    </View>
                )}
            </View>
            {/* </View> */}
        </TouchableNativeFeedback>
    );
}
