/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
// import '@expo/match-media'
import React, { useEffect } from 'react';
import {
    Dimensions,
    Platform,
    StyleSheet,
    View,
} from 'react-native';

import TouchableNativeFeedback from '../../components/TouchableFeedback'

import Colors from '../../constants/Colors'

const buttonSize = 70

const FooterBlock = ({ onPress, label }) => {

    return <View style={{
        position: 'absolute', bottom: 10, zIndex: 10, justifyContent: 'center',
        left: '50%',
        transform: [{ translateX: Platform.OS === 'web' ? '-50%' : -buttonSize / 2 }],
        flexDirection: 'row',
    }}>
        <TouchableNativeFeedback onPress={onPress}>
            <View elevation={5} style={{
                // shadowColor: "#272827", shadowOffset: { width: 2, height: 2, }, shadowOpacity: 0.75, shadowRadius: 10,
                borderRadius: 50, backgroundColor: 'rgba(126,193,61,1)', justifyContent: 'center', alignItems: 'center',
                width: buttonSize, height: buttonSize, alignSelf: 'flex-end', backgroundColor: Colors.green1,
            }}>
                {label}
                {/* <Text style={[{color:'white'}, globalStyle.h1]}>+</Text> */}
                {/* <Image resizeMode={'contain'} style={{ borderRadius: 55, width: 55, height: 55, }} source={require('assets/Jobs/notes_without_bg.png')} /> */}
            </View>
        </TouchableNativeFeedback>
    </View>
}


const styles = StyleSheet.create({
});


export default FooterBlock