export const REQUEST_GOALS = 'REQUEST_GOALS';
export const RECEIVE_GOALS = 'RECEIVE_GOALS';
export const ADD_GOAL = 'ADD_GOAL';
export const REMOVE_GOAL = 'REMOVE_GOAL';
export const ACTIVATE_GOAL = 'ACTIVATE_GOAL';
export const PURCHASE_GOAL = 'PURCHASE_GOAL';

export const REQUEST_JOBS = 'REQUEST_JOBS';
export const RECEIVE_JOBS = 'RECEIVE_JOBS';
export const COMPLETE_JOB_CHILD = 'COMPLETE_JOB_CHILD';

export const REQUEST_CHEQUING = 'REQUEST_CHEQUING';
export const RECEIVE_CHEQUING = 'RECEIVE_CHEQUING';

export const REQUEST_SAVINGS = 'REQUEST_SAVINGS';
export const RECEIVE_SAVINGS = 'RECEIVE_SAVINGS';

export const REQUEST_PURCHASES = 'REQUEST_PURCHASES';
export const RECEIVE_PURCHASES = 'RECEIVE_PURCHASES';

export const USER_LOGIN = 'USER_LOGIN';

export const USER_LOGOUT = 'USER_LOGOUT';

export const REQUEST_STORE = 'REQUEST_STORE';
export const RECEIVE_STORE = 'RECEIVE_STORE';
export const CLEAR_STORE = 'CLEAR_STORE';

export const CLEAR_AUTH = 'CLEAR_AUTH';

export const SAVE_AVATAR = 'SAVE_AVATAR';

export const SAVE_CHILD_NAME = 'SAVE_CHILD_NAME';

export const RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA_LOADING = 'SET_DASHBOARD_DATA_LOADING';

