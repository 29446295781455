/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, Image, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import Modal from 'react-native-modal';

import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { ConfirmationModal, ConfirmationView } from '../confirmation';

import NewJob from './NewJob';
import ModalContainer from '../Modal/Modal';

import { eventLog } from '../../helpers/analytics/analytics';
import { formatNumberFloat } from '../../helpers/formatNumber';
import { customModalRef } from '../Modal/CustomModal';
import { createJob, getJobs } from '../../store/actions/parents/jobsActions';
import { getDashboardData } from '../../store/actions/parents/authActions';

const jobFreqs = { None: 0, Daily: 1, Weekly: 2, 'Bi-weekly': 3, Monthly: 4 };

// TODO modal closes on press on child select wen keyboard ready
function NewJobModal(props) {
    const { visible, onClose, updateDashboard, onSubmit, childId } = props;

    // parentId={parentId}
    // parent={this.props.parent}
    // names={names}
    return (
        <Modal
            // animationIn={'slideInBottom'}
            // animationOut={'slideOutBottom'}
            animationInTiming={700}
            animationOutTiming={700}
            backdropColor={'darkgrey'}
            backdropOpacity={0.6}
            onBackdropPress={onClose}
            isVisible={visible}
            style={{ backgroundColor: '#F9FAF9', margin: 0 }}
            // style={fullScreen && styles.fullScreen}
            //
            // onRequestClose={onClose}
            // fullScreen={true}
            // visible={visible}
            // onClose={() => onClose()}
        >
            <NewJobBlock onClose={onClose} />
        </Modal>
    );
}

function NewJobBlock(props) {
    const { visible, onClose, onSubmit, childId } = props;
    const parentId = useSelector(state => state.parent.parentId);
    const childIds = useSelector(state => state.parent.childIds);
    const names = useSelector(state => state.parent.names);
    const dispatch = useDispatch();

    const [id] = useState(0)
    const [jobValue, setJobValue] = useState('')
    const [jobCategory, setJobCategory] = useState(undefined)
    const [jobFrequency, setJobFrequency] = useState('None')
    const [jobTitle, setJobTitle] = useState('')
    const [jobKids, setJobKids] = useState(childIds.map((item, index) => {
        return { id: item, name: names[item] };
    }));
    const [jobStopRepeat] = useState('')
    const [jobDeadline, setJobDeadline] = useState('')
    const [selectedKids, setSelectedKids] = useState([])
    const [confirmation, setConfirmation] = useState(false)

    function getKidsString() {
        // console.log(selectedKids, jobKids)

        const tmp = selectedKids.map(item => names[item]);
        // let tmp = Object.keys(this.state.jobKids).filter(item => this.state.jobKids[item]).map(item => names[item])
        return tmp.join(', ');
    }

    // parentId={parentId}
    // parent={this.props.parent}
    // names={names}
    return (
        <View style={{backgroundColor: 'white', flex: 1}}>
        <SafeAreaView style={{flex: 1,backdropColor: 'white', borderWidth: 1, borderColor: 'green'}}>
        {confirmation ? (
            <ConfirmationView
                image={require('assets/Jobs/Jobs_Learning.png')}
                title={'Add Job'}
                subtitles={[
                    getKidsString(),
                    jobTitle,
                    'Payment: $' + jobValue.toString().replace(/[^\d\.]/g, ''),
                    'Category: ' + jobCategory,
                    'Frequency: ' + jobFrequency,
                    jobFreqs[jobFrequency]
                        ? 'Stop recurrence: ' + jobStopRepeat
                        : 'Deadline: ' +
                            moment(jobDeadline).format('MM-DD-YYYY'),
                ]}
                confirmPress={() => {
                    dispatch(createJob(
                        parentId,
                        selectedKids,
                            // .filter((item) =>
                            //     selectedKids.includes(item.id)
                            // )
                        jobTitle,
                        jobValue.toString().replace(/[^\d\.]/g, ''),
                        jobDeadline,
                        jobCategory,
                        jobFreqs[jobFrequency],
                        jobStopRepeat
                    ))
                        .then(() => {
                            eventLog({
                                eventName: 'job_creation',
                                properties: {
                                    amount: jobValue.toString().replace(/\D/, ''),
                                    category: jobCategory,
                                    frequency: jobFrequency,
                                },
                            });

                            dispatch(getDashboardData({
                                parentId,
                                childId: childIds[id],
                            }));

                            setJobTitle('');
                            setJobCategory('Chores');
                            setJobFrequency('None');
                            setJobValue('');
                            setJobDeadline('');
                        });

                    setConfirmation(false);

                    this.setState({
                        addJobModal: false,
                    });

                    onClose();
                }}
                rejectPress={() => {
                    setJobTitle('');
                    setJobCategory('Chores');
                    setJobFrequency('None');
                    setJobValue('');
                    setJobDeadline('');
                    setConfirmation(false);
                }}
            />
        ) : (
            <NewJob
                onClose={() => {
                    console.log('LETS CLOSE');
                    onClose()
                }}
                jobCategory={jobCategory}
                setJobCategory={(text) => setJobCategory(text)}
                jobValue={jobValue}
                setJobValue={(text) => setJobValue(text)}
                jobTitle={jobTitle}
                setJobTitle={(text) => setJobTitle(text)}
                jobDeadline={jobDeadline}
                setJobDeadline={(text) => setJobDeadline(text)}
                jobFrequency={jobFrequency}
                setJobFrequency={(text) => setJobFrequency(text)}
                jobStopRepeat={jobStopRepeat}
                setStopRepeat={(t) => setJobStopRepeat(t)}
                kids={jobKids}
                selectedKids={selectedKids}
                setSelectedKids={(items) => setSelectedKids(items)}
                setKid={(items) => { setSelectedKids(items) }}
                removeKid={(item) => { setSelectedKids({
                    ...jobKids,
                    [item]: false,
                })
                }}
                names={names}
                checkNewJobConditions={() => {
                    // this.setState({ confirmation: true });
                    dispatch(createJob(
                            parentId,
                            selectedKids,
                                // .filter((item) =>
                                //     selectedKids.includes(item.id)
                                // )
                            jobTitle,
                            jobValue.toString().replace(/[^\d\.]/g, ''),
                            jobDeadline,
                            jobCategory,
                            jobFreqs[jobFrequency],
                            jobStopRepeat
                        ))
                        .then(() => {
                            eventLog({
                                eventName: 'job_creation',
                                properties: {
                                    amount: jobValue.toString().replace(/\D/, ''),
                                    category: jobCategory,
                                    frequency: jobFrequency,
                                },
                            });

                            dispatch(getDashboardData({
                                parentId,
                                childId: childIds[id],
                            }));

                            setJobTitle('');
                            setJobCategory('Chores');
                            setJobFrequency('None');
                            setJobValue('');
                            setJobDeadline('');
                            setConfirmation(false);
                        });

                    dispatch(getJobs(parentId, childIds[id]))
                    onClose();
                }}
            />
        )}
        </SafeAreaView>
        </View>
    );
}

export const openNewJobModal = ({parentId}) => {
    customModalRef.current.show(() => (<NewJobBlock
        // updateDashboard={this.props.actions.getDashboardData}
        // visible={this.state.addJobModal}
        visible={true}
        onClose={() => {
            // this.setState({ addJobModal: false });
            customModalRef.current.hide();
            // this.props.actions.getJobs(parentId, childIds[id]);
        }}
    />), {swipeDirection: undefined, });

}

const styles = StyleSheet.create({});

export default NewJobModal;
