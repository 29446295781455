/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect } from 'react';
import { StyleSheet, View, Animated, Platform } from 'react-native';
import styled from 'styled-components';

import {
    Svg,
    Defs,
    Rect,
    Mask,
    Circle,
    ClipPath,
    G,
    Line,
} from 'react-native-svg';

import LinearGradient from 'react-native-linear-gradient';
// import MaskedView from '@react-native-masked-view/masked-view';

import Colors from '../../constants/Colors';
import { IS_WEB } from '../../constants/constants';

const MaskedView = IS_WEB
    ? require('../CounterTextInput')
    // : require('../CounterTextInput')
    : require('@react-native-masked-view/masked-view').default;

let SkeletonWeb = () => {
    return null;
};

if (IS_WEB) {
    const { keyframes } = require('styled-components');
    const gradient = keyframes`
    0% {
        opacity: 0.4;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0.4;
      }
    `;

    SkeletonWeb = styled.div.attrs((props) => ({
        // min: (new Date()).yyyymmdd(),
    }))`
        border-radius: 10px;
        width: ${(props) => props.width || 46}px;
        height: ${(props) => props.width || 18}px;
        background: linear-gradient(90deg, ${props => props.highlightColor}80, ${props => props.highlightColor}, ${props => props.highlightColor}80);
        background-size: 100% 100%;
        animation: 2s ${gradient} ease infinite;
    `;
}

// TODO
export const TextSkeleton = ({
    containerStyle,
    highlightColor = Colors.green1,
}) => {
    const animOpacity = useRef(new Animated.Value(IS_WEB ? 0.3 : 0.5)).current;
    // const highlightColor = Colors.green1;

    useEffect(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(animOpacity, {
                    toValue: IS_WEB ? 0.1 : 1,
                    duration: 1000,
                    useNativeDriver: true,
                }),
                Animated.timing(animOpacity, {
                    toValue: IS_WEB ? 0.4 : 0.5,
                    duration: 1000,
                    useNativeDriver: true,
                }),
            ])
        ).start();
    }, [animOpacity]);

    if (IS_WEB) {
        return <Animated.View
                style={[{borderRadius: 10,
                    backgroundColor: highlightColor
                }, containerStyle, { opacity: animOpacity}]}
            />
        return <SkeletonWeb highlightColor={highlightColor} {...containerStyle} />;
    }

    return (
        <MaskedView
            style={[containerStyle]}
            maskElement={
                <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    style={[StyleSheet.absoluteFill, {borderRadius: 10}]}
                    colors={['#00000080', 'black', '#00000080']}
                />
            }
        >
            <Animated.View
                style={[
                    StyleSheet.absoluteFill,
                    { backgroundColor: highlightColor },
                    { opacity: animOpacity },
                ]}
            />
        </MaskedView>
    );

    return (
        <Animated.View
            style={[
                {
                    height: 20,
                    width: 100,
                    backgroundColor: Colors.blue,
                },
            ]}
        />
    );
};

const styles = StyleSheet.create({});
