import React from 'react';
import {TouchableOpacity} from 'react-native';

class TouchableAwesomeFeedback extends React.Component {

    render() {
        return (
            <TouchableOpacity  {...this.props}>
                {this.props.children}
            </TouchableOpacity>
        );
    }

}

export default TouchableOpacity;
