import React, { useState, useEffect } from 'react';
import {
    Animated,
    Button,
    Dimensions,
    ImageBackground,
    Image,
    View,
    Platform,
    ScrollView,
    StyleSheet,
    // Text,
    // TouchableNativeFeedback,
    TouchableOpacity,
    WebView,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import {
    useHover,
    useFocus,
    useActive,
    useDimensions,
    Resizable,
    Hoverable,
} from 'react-native-web-hooks';

import TouchableNativeFeedback from '../TouchableFeedback';
import Text from '../CustomText';

import avatars from '../../constants/avatars';
import Colors from '../../constants/Colors';

import firebase from 'firebase/app';
import 'firebase/auth';

import {
    NavigationActions,
    StackActions,
    SwitchActions,
} from 'react-navigation';
import { defaultStyles } from '../../constants/style';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const auth = firebase.auth;
// const resetAction = StackActions.reset({
//   index: 0,
//   actions: [
//     NavigationActions.navigate({ routeName: 'Login'})
//   ] })

const resetAction = SwitchActions.jumpTo({ routeName: 'Auth' });

export function handleScroll(e, height) {
    if (
        e.nativeEvent.contentOffset.y / height > 0.1 &&
        this.state.header !== true
    ) {
        // this.props.navigation.setParams({scrolledHeader:true})
        Animated.timing(this.state.scrolledHeader, {
            toValue: 1,
            duration: 1000,
        }).start();

        Animated.timing(this.state.scrolledPadding, {
            toValue: 1,
            duration: 200,
        }).start();

        this.setState({ header: true });

        return 0;
    }

    if (e.nativeEvent.contentOffset.y / height < 0.1) {
        // this.props.navigation.setParams({scrolledHeader:false})
        Animated.timing(this.state.scrolledHeader, {
            toValue: 0,
            duration: 1000,
        }).start();

        Animated.timing(this.state.scrolledPadding, {
            toValue: 0,
            duration: 200,
        }).start();

        this.setState({ header: false });

        return 0;
    }
}

const Settings = ({ onPress }) => {
    return (
        <TouchableNativeFeedback onPress={onPress}>
            <View
                elevation={5}
                style={{
                    alignSelf: 'center',
                    padding: 5,
                    backgroundColor: '#7ec13d',
                    marginVertical: 10,
                    borderRadius: 30,
                    width: 30,
                    height: 30,
                    justifyContent: 'center',
                    shadowColor: '#272827',
                    shadowOffset: { width: 2, height: 2 },
                    shadowOpacity: 0.75,
                    shadowRadius: 15,
                }}
            >
                <Image
                    style={{ alignSelf: 'center', width: 22, height: 22 }}
                    source={require('assets/Settings.png')}
                />
            </View>
        </TouchableNativeFeedback>
    );
};

const HeaderButton = ({ title, onPress }) => {
    return (
        <Hoverable>
            {(isHovered) => (
                <TouchableNativeFeedback onPress={onPress}>
                    <View
                        elevation={5}
                        style={{
                            margin: 10,
                            backgroundColor: Colors.green1,
                            borderRadius: 10,
                            boxShadow: '2px 2px 15px #272827',
                            transform: [{ scale: isHovered ? 1.1 : 1 }],
                        }}
                    >
                        <Text
                            style={{
                                color: 'white',
                                textAlign: 'center',
                                width: 100,
                                margin: 5,
                            }}
                        >
                            {title}
                        </Text>
                    </View>
                </TouchableNativeFeedback>
            )}
        </Hoverable>
    );
};

const Dropdown = ({ visibleOutside }) => {
    const [isVisible, setVisibility] = useState(false);

    useEffect(() => {
        setVisibility(visibleOutside);
    }, [visibleOutside]);

    return (
        <React.Fragment>
            <TouchableOpacity
                onPress={() => {
                    setVisibility(!isVisible);
                }}
            >
                <Icon
                    style={{
                        alignSelf: 'flex-end',
                        zIndex: 3,
                        marginHorizontal: 10,
                        marginBottom: 10,
                    }}
                    name="bars"
                    size={30}
                    color="#F9CD30"
                />
            </TouchableOpacity>

            {isVisible ? (
                <View
                    elevation={5}
                    style={{
                        position: 'absolute',
                        maxWidth: 100,
                        top: 70,
                        right: 20,
                        backgroundColor: 'white',
                        alignSelf: 'flex-end',
                        width: '30%',
                        borderRadius: 10,
                        marginRight: 10,
                    }}
                >
                    <TouchableNativeFeedback onPress={() => {}}>
                        <View style={{}}>
                            <Text style={{ margin: 10, color: '#106433' }}>
                                Dashboard
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                    <TouchableNativeFeedback
                        onPress={() => {
                            this.props.navigation.navigate('Login');
                        }}
                    >
                        <View style={{ flex: 1 }}>
                            <Text
                                style={{
                                    flex: 1,
                                    margin: 10,
                                    color: '#106433',
                                }}
                            >
                                Logout
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            ) : null}
        </React.Fragment>
    );
    {
        /* </Animated.View> */
    }
};

export default Dropdown;

const DropdownElementWeb = ({
    elementTextStyle,
    disabled,
    categories,
    defaultValue,
    onPress,
    mainElementStyle,
    mainTextStyle,
    containerStyle,
    elementContainerStyle,
    arrowStyle,
    dropdownStyle,
}) => {
    const [isVisible, setVisibility] = useState(false);

    // return Picker

    return (
        <View style={[containerStyle, { opacity: disabled ? 0.7 : 1 }]}>
            <TouchableNativeFeedback
                disabled={disabled}
                onPress={() => {
                    setVisibility(!isVisible);
                }}
                onBlur={() => setVisibility(false)}
            >
                <View
                    style={[
                        {
                            ...styles.webDropdownElement,
                            borderBottomLeftRadius: isVisible ? 0 : 20,
                            borderBottomRightRadius: isVisible ? 0 : 20,
                        },
                        mainElementStyle,
                    ]}
                >
                    <Text
                        style={[
                            {
                                marginLeft: 18,
                                width: '100%',
                                color: Colors.green1,
                                color: Colors.gray1,
                            },
                            mainTextStyle,
                        ]}
                    >
                        {defaultValue}
                    </Text>
                    <View style={[styles.arrow, arrowStyle]} />
                </View>
            </TouchableNativeFeedback>
            {/* {isVisible&&<View style={{position:'absolute', top:50}}>
                <TouchableNativeFeedback onPress={()=>{console.log('I AM HERE HELP ME!')}}><Text>sdfsdfsdf</Text></TouchableNativeFeedback>
                </View>} */}
            {isVisible && (
                <View
                    persistentScrollbar={true}
                    style={[
                        {
                            zIndex: 100,
                            borderRightWidth: 4,
                            maxHeight: 400,
                            borderWidth: 1,
                            borderBottomRightRadius: 10,
                            borderColor: Colors.gray1,
                            borderBottomLeftRadius: 10,
                            borderTopWidth: 1,
                            alignSelf: 'center',
                            position: 'absolute',
                            // position: isVisible ? 'absolute' : 'relative',
                            top: 45,
                            width: '100%',
                            flex: 1,
                            backgroundColor: 'white',
                            ...dropdownStyle,
                        },
                    ]}
                >
                    {categories.map((item, index) => {
                        return (
                            <TouchableNativeFeedback
                                key={index}
                                onPress={() => {
                                    onPress(item);
                                }}
                            >
                                <View
                                    style={[
                                        {
                                            height: 40,
                                            justifyContent: 'center',
                                        },
                                        elementContainerStyle,
                                    ]}
                                >
                                    <Text
                                        style={{
                                            marginHorizontal: 20,
                                            color: Colors.green1,
                                            color: Colors.gray1,
                                            ...elementTextStyle,
                                        }}
                                    >
                                        {item}
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        );
                    })}
                </View>
            )}
        </View>
    );
};

const DropdownElementMobile = ({
    categories,
    defaultValue,
    onPress,
    containerStyle,
}) => {
    const [isVisible, setVisibility] = useState(false);

    return (
        <View style={{ marginTop: 10, width: '80%' }}>
            <TouchableNativeFeedback
                onPress={() => {
                    setVisibility(!isVisible);
                }}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: 40,
                        width: '95%',
                        borderWidth: 2,
                        borderRadius: 10,
                        borderColor: '#7ec13d',
                    }}
                >
                    <Text
                        style={{
                            marginLeft: 20,
                            width: '100%',
                            color: Colors.green1,
                        }}
                    >
                        {defaultValue}
                    </Text>
                </View>
            </TouchableNativeFeedback>
            {isVisible && (
                <ScrollView
                    style={{
                        borderWidth: 1,
                        zIndex: 10,
                        borderBottomRightRadius: 10,
                        borderColor: Colors.green1,
                        borderBottomLeftRadius: 10,
                        borderTopWidth: 1,
                        alignSelf: 'center',
                        position: isVisible ? 'absolute' : 'relative',
                        top: 40,
                        width: '100%',
                        flex: 1,
                        backgroundColor: 'white',
                        height: 100,
                    }}
                >
                    {categories.map((item, index) => {
                        return (
                            <TouchableOpacity
                                key={index}
                                onPress={() => {
                                    onPress(item);
                                    setVisibility(false);
                                }}
                            >
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        height: 40,
                                    }}
                                >
                                    <Text
                                        style={{
                                            marginLeft: 20,
                                            color: Colors.green1,
                                        }}
                                    >
                                        {item}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        );
                    })}
                </ScrollView>
            )}
        </View>
    );
};

export const DropdownElement =
    Platform.OS === 'web' ? DropdownElementWeb : DropdownElementMobile;

const styles = StyleSheet.create({
    arrow: {
        marginRight: '5%',
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 10,
        borderRightWidth: 5,
        borderBottomWidth: 0,
        borderLeftWidth: 5,
        borderTopColor: Colors.gray1,
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: 'transparent',
    },
    webDropdownElement: {
        zIndex: 10,
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%',
        marginTop: 10,
        alignSelf: 'center',
        borderColor: Colors.gray,
        borderWidth: 2,
        borderRadius: 20,
        height: 40,
        justifyContent: 'center',
    },
});
