const avatars = {
    0: require('assets/Avatars/icon_1.png'),
    1: require('assets/Avatars/icon_2.png'),
    2: require('assets/Avatars/icon_3.png'),
    3: require('assets/Avatars/icon_4.png'),
    4: require('assets/Avatars/icon_5.png'),
    5: require('assets/Avatars/icon_6.png'),
};

export default avatars;
