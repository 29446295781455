// import { appConstants } from "";
import * as actionTypes from '../../actions/kids/actionTypes'


const initialState = {
        isLoading: true,
        data:[]
};


export default  function goals(state = initialState, action) {

    switch (action.type) {
        case actionTypes.REQUEST_GOALS:

                return {
                    ...state, 
                    isLoading:true,   
                };

        
        case actionTypes.RECEIVE_GOALS:
            return {
                ...state,
                isLoading: false,
                data:action.payload.data?action.payload.data:[]
            };
    
        case actionTypes.ADD_GOAL:

            return {
                ...state,
                isLoading: false,
                data:[...state.data,{ key: action.payload.goalId, ...action.payload.goal }]
            };

        case actionTypes.REMOVE_GOAL:
            
            return {
                ...state,
                data:state.data.filter(item => { return item.key !== action.payload.goalId })
            };
    
        case actionTypes.ACTIVATE_GOAL:
            
            return {
                ...state,
                // data:state.data.filter(item => { return item.key !== action.payload.goalId })
            };

        case actionTypes.PURCHASE_GOAL:
        
            return {
                ...state,
                data:[]
                // data:state.data.filter(item => { return item.key !== action.payload.goalId })
            };

        default:
            return state
    }
}