/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Modal from 'react-native-modal';

import TouchableNativeFeedback from '../../components/TouchableFeedback';

const ModalContainer = ({ visible, fullScreen, onClose, children }) => {
    return (
        <Modal
            animationIn={'zoomIn'}
            animationOut={'zoomOut'}
            animationInTiming={500}
            animationOutTiming={500}
            backdropColor={'darkgrey'}
            backdropOpacity={0.6}
            onBackdropPress={onClose}
            isVisible={visible}
            style={fullScreen && styles.fullScreen}
            onRequestClose={onClose}
        >
            <View style={styles.container}>
                <TouchableNativeFeedback
                    style={styles.close}
                    onPress={() => onClose()}
                >
                    <Image
                        resizeMode={'contain'}
                        style={styles.image}
                        source={require('assets/Delete-X.png')}
                    />
                </TouchableNativeFeedback>
                {children}
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 20,
        backgroundColor: 'white',
        width: '80%',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 250,
    },
    close: { position: 'absolute', top: 20, right: 20, zIndex: 1 },
    image: { height: 12, width: 12 },
    fullScreen: { margin: 0 },
});

// export default Chequing
export default ModalContainer;
