// import { appConstants } from "";
import * as actionTypes from '../../actions/kids/actionTypes'


const initialState = {
        isLoading: true,
        data:[]
};


export default  function chat(state = initialState, action) {

    switch (action.type) {
        case actionTypes.REQUEST_JOBS:

                return {
                    ...state, 
                    isLoading:true,   
                };

        case actionTypes.RECEIVE_JOBS:
                console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                data:action.payload.data?action.payload.data:[]
            };

        case actionTypes.COMPLETE_JOB_CHILD:
            console.log("state: ", state)
            return {
                ...state,
                isLoading: false,
                // data:state.data.filter(item=>{return item.key!==action.payload.jobId})
            };

        default:
            return state
    }
}