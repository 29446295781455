// import { appConstants } from "";
import * as actionTypes from '../../actions/parents/actionTypes'


const initialState = {
    isLoading: true,
    data: {},
    featuredItems: []
};


export default function store(state = initialState, action) {

    switch (action.type) {
        case actionTypes.REQUEST_STORE:

            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.RECEIVE_STORE:

            return {
                ...state,
                isLoading: false,
                data: action.payload.data
            };
        case actionTypes.RECEIVE_FEATURED_STORE:

            return {
                ...state,
                isLoading: false,
                featuredItems: action.payload.data
            };

        case actionTypes.RECEIVE_STORE_ITEM:
            console.log('RECEVEIE ITEM,')
            const index = action.payload.index
            const dat = action.payload.data
            return {
                ...state,
                isLoading: false,
                data: { ...state.data, [index]: dat }
            };

        case actionTypes.DROP_STORE_ITEM:

            return {
                ...state,
            };

        default:
            return state
    }
}