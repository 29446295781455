import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";

import firebase from 'firebase/app';
import 'firebase/database';

import config from '../../../constants/config'

const database = firebase.database;

function requestPurchases() {
    return {
        type: actionTypes.REQUEST_PURCHASES,
        isLoading: true
    }
}

function receivePurchases(data_approved, data_pending, childId) {
    return {
        type: actionTypes.RECEIVE_PURCHASES,
        payload: {
            // obj: json,
            childId,
            data_approved, data_pending,
            isLoading: false,
        }
    }
}

export function getPurchases(interval, parentId, childId) {
    return async dispatch => {
        dispatch(requestPurchases())

        let data_source_approved = database().ref('/users/' + parentId + '/' + childId + '/purchases/approved');
        let snapshot = await data_source_approved.orderByChild('approval_date').startAt(interval.toISOString()).once('value')
        let data_approved = snapshot.val();
        // console.log('interval', interval)

        if (data_approved != null) {
            if (!Array.isArray(data_approved)) {
                data_approved = Object.keys(data_approved).map(item=>{return {...data_approved[item], key:item}});
            }
            data_approved = data_approved.filter(Boolean);
        }
        else {
            data_approved = []
        }

        var data_source_pending = database().ref('/users/' + parentId + '/' + childId + '/purchases/requested');
        snapshot = await data_source_pending.orderByChild('request_date').startAt(interval.toISOString()).once('value')
        let data_pending = snapshot.val();
        //   data_received ++;
        if (data_pending != null) {
            if (!Array.isArray(data_pending)) {
                data_pending = Object.keys(data_pending).map(item=>{return {...data_pending[item], key:item}});
            }
            data_pending = data_pending.filter(Boolean);
        }
        else {
            data_pending = []
        }
        // console.log('what data we have ', data_approved, data_pending)
        dispatch(receivePurchases(data_approved, data_pending, childId))
    }
}

export function confirmPurchase(parentId, childId, purchaseId) {
    return async dispatch => {
        // dispatch(requestPurchases())

        // let data_source = database().ref('/users/' + parentId + '/' + childId + '/purchases/requestes').child(purchaseId);
        // let snapshot = data_source.remove()
        // let data_approved = snapshot.val();
        let data_source = database().ref('/users/' + parentId + '/' + childId + '/purchases/requested').child(purchaseId);
        let data_source_approved = database().ref('/users/' + parentId + '/' + childId + '/purchases/approved')

        let snapshot = await data_source.once('value')
        let data_approved = snapshot.val();
        // console.log('data_approved')
        // console.log(data_approved)
        data_source.remove()

        data_source_approved.push({...data_approved, approval_date:(new Date()).toISOString()})

        // dispatch(receivePurchases(data_approved, data_pending, childId))
    }
}


export function declinePurchase(parentId, childId, purchaseId) {
    return async dispatch => {
        // dispatch(requestPurchases())

        let transaction_date = (new Date()).toISOString()
        // let data_source = database().ref('/users/' + parentId + '/' + childId + '/purchases/requestes').child(purchaseId);
        // let snapshot = data_source.remove()
        // let data_approved = snapshot.val();
        let data_source = database().ref('/users/' + parentId + '/' + childId + '/purchases/requested').child(purchaseId);
        let old_balance_source = database().ref('/users/' + parentId + '/' + childId + '/chequing/balance');

        // let data_source_declined = database().ref('/users/' + parentId + '/' + childId + '/purchases/approved')

        let snapshot = await data_source.once('value')
        let old_balance = (await old_balance_source.once('value')).val()

        let data_approved = snapshot.val();
        // console.log('data_approved')
        // console.log(data_approved)
        data_source.remove()

        database().ref('/users/' + parentId + '/' + childId + '/chequing').update({balance: old_balance+data_approved.price});
        database().ref('/users/' + parentId + '/' + childId + '/chequing/transactions').push({amount:data_approved.price,transaction_date:transaction_date,type:'refund'});
        // data_source_declined.push({...data_approved, approval_date:(new Date()).toISOString()})

        // dispatch(receivePurchases(data_approved, data_pending, childId))
    }
}


export function sendConfirmPurchaseEmail(parentId, childId, itemName) {
    return async dispatch => {


    const body = {parentId, childId, itemName }
    return fetch(config.url + 'sendConfirmPurchaseEmail', {
      method: 'POST',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json'
      // },
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp.json()
      })
      // .then((resp) => {
      //   dispatch({type: actionTypes.PURCHASE_GOAL,
      //     payload:{goalId:goalId},})
      //   return resp
      // })
  }
  }


  export function sendRejectPurchaseEmail(parentId, childId, itemName) {
    return async dispatch => {


    const body = {parentId, childId, itemName }
    return fetch(config.url + 'sendRejectPurchaseEmail', {
      method: 'POST',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json'
      // },
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp.json()
      })
      // .then((resp) => {
      //   dispatch({type: actionTypes.PURCHASE_GOAL,
      //     payload:{goalId:goalId},})
      //   return resp
      // })
  }
  }