import React from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import Text from '../CustomText';

import Colors from '../../constants/Colors';
import globalStyle from '../../constants/style';
import { IS_ANDROID, IS_IOS, IS_WEB } from '../../constants/constants';
import { FONTS } from '../../constants/fonts';

export function InputWithVariationButtons(props) {
    const { title, containerStyle, titleStyle, inputStyle, value, additionalSign, onChange, onDecrease, onIncrease } = props;

    return (
        <View style={styles.container}>
            {title ? <Text style={[styles.title, titleStyle]}>{title}</Text> : null}
            <View style={[styles.blockContainer, containerStyle]}>
                <TouchableOpacity onPress={onDecrease} style={styles.variationButton}>
                    <Text style={styles.variationText}>-</Text>
                </TouchableOpacity>
                <View style={{
                    flexDirection: 'row',
                    marginLeft: 30,
                // maxWidth: 150,
                justifyContent: 'center', alignItems: 'center'}}>
                    {additionalSign && <Text style={{ ...globalStyle.h1,
                    color: Colors.green1, marginTop: IS_IOS ? 8 : 0,
                    fontFamily: FONTS.FONT_BOLD,
                    // marginHorizonta: 30,
                    }}>{additionalSign}</Text>}
                    <TextInput keyboardType={'numeric'} value={value}
                    style={[styles.input, inputStyle]} onChangeText={onChange} />
                </View>
                <TouchableOpacity onPress={onIncrease} style={styles.variationButton}>
                    <Text style={styles.variationText}>+</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {width: '100%', maxWidth: 200},
    blockContainer: { width: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' },
    title: {
        ...globalStyle.h4,
        fontSize: 17,
        fontFamily: FONTS.FONT_BOLD,
        marginTop: 50,
        marginBottom: 20,
    },
    variationButton: {
        width: 35,
        height: 35,
        borderRadius: 35,
        backgroundColor: '#E4E4E4',
        justifyContent: 'center',
        alignItems: 'center',
    },
    variationText: {
        fontSize: 20,
    },
    input: {
        ...globalStyle.h1,
        color: Colors.green1,
        fontFamily: FONTS.FONT_BOLD,
        maxWidth: 150,
        // marginVertical
    },
});
