/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';

import Text from '../../../components/CustomText'

import { DropdownElement } from '../../../components/Dropdown';

import { InfoButton } from '../../../components/newChild/Buttons'

import Colors from '../../../constants/Colors';

export const PickerRow = ({
    value,
    onPress,
    categories,
    infoButtonText,
    title,
    containerStyle,
}) => {
    return (
        <>
            <View style={{width: '70%', alignSelf: 'flex-start', marginTop: 10 }}>
                <Text style={styles.title}>{title}</Text>
            </View>
            <View style={[styles.pickerContainer, containerStyle]}>
                <DropdownElement
                    dropdownStyle={{ width: '100%', zIndex: 1, maxHeight: 200 }}
                    containerStyle={{ zIndex: 1, width: '80%', marginHorizontal: 0, marginRight: 10, }}
                    mainElementStyle={{ width: '100%' }}
                    onPress={onPress} // text => {}
                    defaultValue={value}
                    categories={categories}
                />
                <InfoButton text={infoButtonText} />
            </View>
        </>
    )
}


const styles = StyleSheet.create({
    pickerContainer: {
        // borderWidth: 1,
        ...(Platform.OS === 'web' && { width: '100%' }),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        color: Colors.gray1,
        marginTop: 2,
        fontSize: 14,
    },
});
