import { IS_ANDROID } from "./constants";

// TODO check font names
const CARMEN_SANS_REGULAR = IS_ANDROID ? 'Carmen_Sans_Regular' : 'Carmen Sans Regular';
const CARMEN_SANS_BOLD = IS_ANDROID ? 'Carmen_Sans_Bold' : 'Carmen Sans Bold';

export const FONTS = {
    // CARMEN_SANS_REGULAR: '../assets/fonts/Carmen_Sans_Regular.otf',
    // CARMEN_SANS_BOLD: '../assets/fonts/Carmen_Sans_Bold.otf',
    CARMEN_SANS_REGULAR: 'Carmen_Sans_Regular',
    CARMEN_SANS_BOLD: 'Carmen_Sans_Bold',
    FONT_DEFAULT: CARMEN_SANS_REGULAR,
    FONT_BOLD: CARMEN_SANS_BOLD,
};
