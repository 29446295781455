/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    StyleSheet,
    ScrollView,
    TextInput,
    View,
    Platform,
} from 'react-native';

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput';
import Text from '../../../components/CustomText';

import Colors from '../../../constants/Colors';
import { defaultStyles } from '../../../constants/style';
import { RegularInput } from '../../RegularInput/RegularInput';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const InfoButton = ({ text }) => {
    const [visible, setVisible] = useState(false);

    return (
        <React.Fragment>
            <TouchableNativeFeedback
                style={{
                    marginLeft: 10,
                    height: 25,
                    width: 25,
                    borderRadius: 35,
                    backgroundColor: Colors.green1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onPress={() => setVisible(!visible)}
            >
                <Text style={[{ color: 'white' }, defaultStyles.bold]}>?</Text>
            </TouchableNativeFeedback>
            <Modal
                animationIn={'zoomIn'}
                animationOut={'zoomOut'}
                animationInTiming={300}
                animationOutTiming={300}
                backdropColor={'darkgrey'}
                backdropOpacity={0.1}
                onBackdropPress={() => setVisible(false)}
                isVisible={visible}
                onRequestClose={() => {
                    setVisible(false);
                }}
            >
                <View
                    elevation={5}
                    style={{
                        borderRadius: 15,
                        backgroundColor: 'white',
                        width: '80%',
                        alignSelf: 'center',
                        alignItems: 'center',
                        maxWidth: 400,
                        minHeight: 100,
                        paddingVertical: 15,
                        paddingHorizontal: 20,
                        justifyContent: 'center',
                        boxShadow: '2px 2px 15px #272827',
                    }}
                >
                    <Text
                        style={{
                            textAlign: 'center',
                            marginTop: 20,
                            color: Colors.gray1,
                        }}
                    >
                        {text}
                    </Text>
                    <TouchableNativeFeedback onPress={() => setVisible(false)}>
                        <View
                            style={{
                                backgroundColor: Colors.green1,
                                borderRadius: 5,
                                marginVertical: 10,
                                paddingHorizontal: 20,
                                paddingVertical: 10,
                            }}
                        >
                            <Text
                                style={[{ color: 'white' }, defaultStyles.bold]}
                            >
                                Got it
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </Modal>
        </React.Fragment>
    );
};

export const NewInputRow = ({
    text,
    onChangeText,
    error,
    inputParams,
    label,
    infoButtonText,
}) => {
    return (
        <View key={label} style={styles.container}>
            {/* <FloatingLabelInput
                {...inputParams}
                style={{ borderColor: error ? Colors.rose : Colors.gray }}
                value={text}
                onChangeText={onChangeText} // (text) => {}
                label={label}
            /> */}
            <RegularInput
                {...inputParams}
                style={{ borderColor: error ? Colors.rose : Colors.gray }}
                value={text}
                onChangeText={onChangeText} // (text) => {}
                label={label}
            />
            <InfoButton text={infoButtonText} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        // borderWidth: 1,
        // width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});
