/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Image,
    TouchableOpacity,
    View,
    StyleSheet,
} from 'react-native';

import Text from '../components/CustomText';

import Colors from '../constants/Colors';
import { FONTS } from '../constants/fonts';

const avatars = [
    { index: 0, source: require('assets/Avatars/icon_1.png') },
    { index: 1, source: require('assets/Avatars/icon_2.png') },
    { index: 2, source: require('assets/Avatars/icon_3.png') },
    { index: 3, source: require('assets/Avatars/icon_4.png') },
    { index: 4, source: require('assets/Avatars/icon_5.png') },
];

const getAvatarImage = ({ index, uri }) => {
    if (index > avatars.length-1) {
        return { uri, cache: 'force-cache' };
    }

    // if (index === -1) {
    //     return require('assets/Avatars/icon_1.png');
    // }
    if (index === 5) {
        return avatars[index]?.source;
    }
    return avatars[index]?.source;

    return require('assets/Avatars/icon_1.png');

};

export function Avatar({ uri, index, size = 80, margin = 20, onPress, containerStyle, textStyle, name, children }) {

    return (
        <TouchableOpacity style={{alignItems: 'center'}} onPress={onPress} disabled={!onPress}>
            <View style={[styles.container, { width: size+margin, height: size+margin }, containerStyle]}>
                    <Image
                        resizeMode={'cover'}
                        style={[styles.avatar, { width: size, height: size }]}
                        source={getAvatarImage({ index, uri })}
                    />
                    {children}
            </View>
            {name ? <Text style={[styles.text, textStyle]}>{name}</Text> : null}
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 160,
        justifyContent: 'center', alignItems: 'center',
        margin: 5,
    },
    avatar: {
        width: '100%',
        height: '100%',
        backgroundColor: Colors.gray,
        borderRadius: 160,
    },
    text: {
        fontFamily: FONTS.FONT_BOLD,
    }
});

