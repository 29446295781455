import React from 'react';
import { StyleSheet, Text } from 'react-native';

import globalStyle from '../constants/style';
import Colors from '../constants/Colors';
import { FONTS } from '../constants/fonts';

function CustomText({ style, fontScale, numberOfLines, children, ...props }, ) {
    return (
        <Text
            {...props}
            numberOfLines={numberOfLines}
            style={[styles.text, style]}
        >
            {children}
        </Text>
    );
}

const styles = StyleSheet.create({
    text: {
        fontFamily: FONTS.FONT_DEFAULT,
        color: Colors.black,
        ...globalStyle.page,
    },
});

export default CustomText;
