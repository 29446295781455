
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import Carousel from 'react-native-snap-carousel';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'


import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { expenseInfo, savingsInfo } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';

const walkthroughOld = {
    0: { image: require('assets/walkthrough/Parents/0.png'), title: 'Access settings for you and your child' },
    1: { image: require('assets/walkthrough/Parents/1.png'), title: 'Access the online store and add items for your child' },
    2: { image: require('assets/walkthrough/Parents/2.png'), title: 'Add and substract funds' },
    3: { image: require('assets/walkthrough/Parents/3.png'), title: 'Add a new job' },
    4: { image: require('assets/walkthrough/Parents/4.png'), title: 'Click on the card to see more details' },
    5: { image: require('assets/walkthrough/Parents/5.png'), title: 'Click through children' },
    6: { image: require('assets/walkthrough/Parents/6.png'), title: 'Create a new job' },
    7: { image: require('assets/walkthrough/Parents/7.png'), title: 'Delete the job' },
    8: { image: require('assets/walkthrough/Parents/8.png'), title: 'Edit the job details (deadline, payment, description, etc.)' },
    9: { image: require('assets/walkthrough/Parents/9.png'), title: 'This shows recurring job' }
}

const walkthrough = {
    0: { image: require('assets/walkthrough/Parents/0.png'), title: 'Access settings for you and your child' },
    1: { image: require('assets/walkthrough/Parents/1.png'), title: 'Access the online store and add items for your child' },
    2: { image: require('assets/walkthrough/Parents/2.png'), title: 'Add item to child store' },
    3: { image: require('assets/walkthrough/Parents/3.png'), title: 'See what item are currently in child store' },
    4: { image: require('assets/walkthrough/Parents/4.png'), title: 'Add and substract funds' },
    5: { image: require('assets/walkthrough/Parents/5.png'), title: 'Add a new job' },
    6: { image: require('assets/walkthrough/Parents/6.png'), title: 'Click on the card to see more details' },
    7: { image: require('assets/walkthrough/Parents/7.png'), title: 'Click through children' },
    8: { image: require('assets/walkthrough/Parents/8.png'), title: 'Create a new job' },
    9: { image: require('assets/walkthrough/Parents/9.png'), title: 'Delete the job' },
    10: { image: require('assets/walkthrough/Parents/10.png'), title: 'Edit the job details (deadline, payment, description, etc.)' },
    11: { image: require('assets/walkthrough/Parents/11.png'), title: 'This shows recurring job' },
}

class Chequing extends React.Component {

    constructor(props) {
        super(props);
        this._carousel = React.createRef()

        this.state = {
            ...Object.keys(savingsInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: savingsInfo[curr].default } }, {}),
            advanced: true,
            stage: 0,
        };
    };

    componentDidMount() {
    }

    row({ info, item, formatInput }) {
        const data = info[item]
        console.log('LABEL ', info, item, data)
        return <View key={item} style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} containerStyle={{ width: '100%', alignItems: 'center', alignSelf: 'center' }} label={data.label} value={this.state[item]}
                onChangeText={text => this.setState({ [item]: data.format ? data.format(text) : text })} />
            <InfoButton text={data.text} />
        </View>
    }

    proceed() {
        this.props.navigation.navigate('Dashboard')
    }

    renderCard({ item, index }, layout) {
        console.log('item!! ', item)
        return <Image resizeMode={'contain'} style={{ alignSelf: 'center', borderRadius: 10, width:'90%', height:layout.width*0.6, minHeight:200, maxHeight:400 }} source={item.image} />

    }


    render() {
        const { layout } = this.props;
        const { stage } = this.state;
        console.log('stage', stage)
        const title = 'Parent feature tour'

        return (
            <BlockWrapper layout={layout} description={<Text />} title={title}>
                {/* <View> */}
                { props=><><View style={{
                    flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                    marginVertical: 20, borderRadius: 10, width: '100%',
                }}>
                    {stage > 0 ?
                        <TouchableNativeFeedback onPress={() => stage > 0 ?
                        // this.setState({ stage: this.state.stage - 1 })
                        this._carousel.current.snapToItem(this.state.stage-1)
                        : null}>
                            <View style={{ width: 30, margin: 10, alignItems: 'center' }}>
                                <Icon style={{}} name="chevron-left" size={30} color={Colors.green1} />
                            </View>
                        </TouchableNativeFeedback> : <View style={{ width: 30, margin: 10 }}></View>}
                    <Carousel
                        ref={this._carousel}
                        data={Object.values(walkthrough)}
                        renderItem={item => this.renderCard(item, layout)}
                        lockScrollWhileSnapping={true}
                        // renderItem={itemthis._renderCard}
                        // itemWidth={layout.width*0.4>390?390:layout.width*0.4}
                        itemWidth={layout.width * 0.5 > 400 ? 400 : layout.width * 0.55}
                        sliderWidth={layout.width * 0.5 > 400 ? 400 : layout.width * 0.5}
                        containerCustomStyle={{ maxWidth:400 }}
                        // slideStyle={{width:'100%'}}
                        contentContainerCustomStyle={{ alignItems:'center', justifyContent:'center'}}
                        firstItem={0}
                        onSnapToItem={(index) => {
                            console.log('index')
                            // navigation.setParams({'cards':index===0?'Chequing':'Savings'});
                            this.setState({ stage: index })
                        }}
                    />

                    {/* <Image resizeMode={'contain'} style={{ borderRadius:10,width: '85%', maxWidth: 400,height: 300 }} source={walkthrough[this.state.stage].image} /> */}
                    {stage < (Object.keys(walkthrough).length - 1) ?
                        <TouchableNativeFeedback onPress={() => stage < (Object.keys(walkthrough).length - 1) ?
                        // this.setState({ stage: this.state.stage + 1 })
                        // console.log('this carousel - ', this._carousel)
                        this._carousel.current.snapToItem(this.state.stage+1)
                        : null}>
                            <View style={{ width: 30, margin: 10, alignItems: 'center' }}>
                                <Icon style={{}} name="chevron-right" size={30} color={Colors.green1} />
                            </View>
                        </TouchableNativeFeedback> : <View style={{ width: 30, margin: 10 }}></View>}
                </View>
                <View style={{ marginBottom: 20, height: 40, justifyContent: "center", }}>
                    <Text style={{ textAlign: 'center', color: Colors.gray1 }}>{walkthrough[this.state.stage].title}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    {[Object.keys(walkthrough)
                        .map(item => {
                            return <TouchableNativeFeedback onPress={() => {
                                this._carousel.current.snapToItem(parseInt(item))
                                this.setState({ stage: parseInt(item) }) }}><View style={{ width: 10, height: 10, margin: 7, borderRadius: 10, opacity: item === stage.toString() ? 1 : 0.5, backgroundColor: Colors.gray }}></View>
                            </TouchableNativeFeedback>
                        })]}
                </View>
                <View style={{ flex: 1, width: '50%', zIndex: -1, justifyContent: "center", flexDirection: 'row' }}>
                    {/* <Button title={'Back'} onPress={() => this.back()}></Button> */}
                    <Button title={'Done'} onPress={() => this.proceed()}></Button>
                </View>
                </>}
                {/* </View> */}
            </BlockWrapper>)
    }
}


class ChequingScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }
    }



    render() {

        return (
            <Resizable>
                {layout => {
                    return <View style={{ height: '100%', flex: 1 }}>
                                <Chequing layout={layout} auth={this.props.auth} parent={this.props.parent} actions={this.props.actions}
                                    navigation={this.props.navigation}
                                    kidData={this.props.actions.kidData}
                                    proceed={() => { }}
                                    startLoading={() => this.setState({ loading: true })} stopLoading={() => this.setState({ loading: false })}
                                />
                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(ChequingScreen);


