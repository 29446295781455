import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import firebase from 'firebase/app';
import 'firebase/database';

import config from '../../../constants/config'

const database = firebase.database;

function requestJobs() {
    return {
        type: actionTypes.REQUEST_JOBS,
        // data: [],
        isLoading:true
    }
}

function receiveJobs(json) {
    return {
        type: actionTypes.RECEIVE_JOBS,
        payload:{
            // obj: json,
            data:json,
            isLoading:false,
        }
    }
}
export function getJobs(parentId, childId) {
    return async dispatch => {
        dispatch(requestJobs())

        var data_source = database().ref('/users/'+parentId+'/'+childId+'/jobs');



    return data_source.orderByChild('completed').equalTo(0).once('value')
            .then(snapshot => {return snapshot.val()})
            .then(snap => {
                let json = snap?Object.keys(snap).map(item=>{return {...snap[item], key:item}}):[];
                const today = new Date();
				const newValue = new Date((new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate())).valueOf() + today.getTimezoneOffset()*60*1000)

                for (var i = 0; i < json.length; i++) {
                    // console.log('deadline string - ', json[i].deadline)

                    json[i].created = new Date((new Date(json[i].created)).valueOf() + today.getTimezoneOffset()*60*1000)
                    json[i].created = new Date(json[i].created);
                    json[i].deadline = new Date(json[i].deadline);
                    json[i].deadline = new Date(json[i].deadline.valueOf() + today.getTimezoneOffset()*60*1000)
                    json[i].deadline.setHours(23)
					json[i].deadline.setMinutes(59)
                    json[i].deadline.setSeconds(59)
                    // console.log('deadline from date - ', json[i].deadline)
                }
                json = json.filter(item=>{return item.deadline >=today && item.action===0});
                json.sort((a,b)=>{return a.deadline-b.deadline})

                return dispatch(receiveJobs(json))})
    }
}

function completeJobSuccess(jobId) {

    return {
        type: actionTypes.COMPLETE_JOB_CHILD,
        payload:{
            jobId:jobId,
            isLoading:false,
        }
    }
}



export function completeJob(jobId, parentId, childId) {
return async dispatch => {
const body = {jobId, parentId, childId}

return fetch(config.url +  'completeJob',{
    method: 'POST',
    // headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    // },
    body: JSON.stringify(body)
    })
    .then(response=>response.json())
    .then(()=>{dispatch(completeJobSuccess(jobId))})
//   .then(response=>response.json())

}
}



export function requestJob(parentId, childId) {
    return async dispatch => {
    const childName = (await database().ref('/children/'+childId +'/name').once('value')).val()
    // const childName = 'Test'

    const body = { parentId, childId, childName}


    return fetch(config.url + 'requestJob',{
        method: 'POST',
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body)
        })
        .then(response=>response.json())
        .then(()=>{})
    //   .then(response=>response.json())

    }
    }
