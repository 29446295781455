/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Image, StyleSheet, View, TouchableOpacity } from 'react-native';

import Modal from 'react-native-modal';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';

import globalStyles from '../../constants/style';
import Colors from '../../constants/Colors';
import { SHOW_ACADEMY } from '../../constants/config';
import { customModalRef } from '../Modal/CustomModal';


export const openAcademyModal = ({ title, text, renderContent = () => null, onButtonPress }) => {
    customModalRef.current.show(() => (<PopupModalContent
        // updateDashboard={this.props.actions.getDashboardData}
        // visible={this.state.addJobModal}
        title={title}
        text={text}
        onButtonPress={onButtonPress}
        renderContent={renderContent}
        closeModal={() => {
            // this.setState({ addJobModal: false });
            customModalRef.current.hide();
            // this.props.actions.getJobs(parentId, childIds[id]);
        }}
    />), {swipeDirection: undefined, });
}

const PopupModalContent = ({ title, text, renderContent = () => null, onButtonPress, closeModal }) => {
    return <View style={styles.container}>
    <TouchableNativeFeedback
        style={styles.close}
        onPress={closeModal}
    >
        <Image
            resizeMode={'contain'}
            style={styles.image}
            source={require('assets/Delete-X.png')}
        />
    </TouchableNativeFeedback>
    <Text style={[globalStyles.h2, styles.title]}>{title}</Text>
    <Text style={styles.text}>{text}</Text>
{onButtonPress ? <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.green1, borderRadius: 20 }} onPress={onPress}>
    <Text style={{color: Colors.white , paddingHorizontal: 10}}>Check Video to learn more</Text>
</TouchableOpacity> : null}
    {renderContent()}
</View>
}

const PopupModal = ({ isVisible = true, title, text, renderContent = () => null, onButtonPress }) => {
    const [visible, setVisible] = useState(isVisible);

    const closeModal = () => {
        setVisible(false);
    }

    const onPress = () => {
        onButtonPress();
        closeModal();
    }

    if (!SHOW_ACADEMY) {
        return null;
    }

return (
    <Modal
            animationIn={'zoomIn'}
            animationOut={'zoomOut'}
            animationInTiming={500}
            animationOutTiming={500}
            backdropColor={'lightgray'}
            backdropOpacity={0.5}
            onBackdropPress={closeModal}
            isVisible={visible}
            style={styles.fullScreen}
            onRequestClose={closeModal}
        >
            <View style={styles.container}>
                <TouchableNativeFeedback
                    style={styles.close}
                    onPress={closeModal}
                >
                    <Image
                        resizeMode={'contain'}
                        style={styles.image}
                        source={require('assets/Delete-X.png')}
                    />
                </TouchableNativeFeedback>
                <Text style={[globalStyles.h2, styles.title]}>{title}</Text>
                <Text style={styles.text}>{text}</Text>
            {onButtonPress ? <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.green1, borderRadius: 20 }} onPress={onPress}>
                <Text style={{color: Colors.white , paddingHorizontal: 10}}>Check Video to learn more</Text>
            </TouchableOpacity> : null}
                {renderContent()}
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 20,
        backgroundColor: 'white',
        width: '80%',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: 400,
    //  minHeight: 250,
    padding: 20,
    },
    close: { position: 'absolute', top: 20, right: 20, zIndex: 1 },
    image: { height: 12, width: 12 },
    fullScreen: { margin: 0 },
    title: {
        marginTop: 10,
    textAlign: 'center',
    },
    text: {
        marginVertical: 10,
    }
});

// export default Chequing
export default PopupModal;
