/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { Image, StyleSheet } from 'react-native';

import TouchableNativeFeedback from './TouchableFeedback';
// import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
// import Text from '../../../components/CustomText';
// import Colors from '../constants/Colors';

import closeIcon from 'assets/Delete-X.png';

export const XCloseButton = ({ onPress, containerStyle, closeStyle }) => {
    return (
        <TouchableNativeFeedback
            style={[styles.container, containerStyle]}
            onPress={onPress}
        >
            {/* <Text style={[{ color: Colors.rose, }, defaultStyles.bold]}>X</Text> */}
            <Image
                source={closeIcon}
                style={[styles.closeImage, closeStyle]}
                resizeMode={'contain'}
            />
        </TouchableNativeFeedback>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        right: 20,
        top: 20,
    },
    closeImage: {
        height: 30,
        width: 30,
        // marginTop: 60,
    },
});
