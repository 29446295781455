import * as actionTypes from './actionTypes';
// import { database, auth } from 'firebase';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { withNavigation } from 'react-navigation';
import config from '../../../constants/config';
import { getChildrenChequingBalances } from '../../../requests/dashboard/dashboardRequests';

const database = firebase.database;
const auth = firebase.auth;

function requestLogin() {
    return {
        type: actionTypes.USER_LOGIN,
        // data: [],
        isLoading: true,
    };
}

function receiveLogin(json) {
    //fixme
    return {
        type: actionTypes.USER_LOGIN,
        payload: {
            // obj: json,
            user: json,
            isLoading: false,
        },
    };
}

export function loginExample(email, password) {
    return async (dispatch) => {
        // dispatch(requestLogin())

        return auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                dispatch(receiveLogin(user));

                // this.props.navigation.navigate('Dashboard');
                return user;
            })
            .catch((error) => {
                console.log(error);
                console.log(error.userInfo.message);
                alert(error.userInfo.message);
            });
    };
}

function receiveChequing(childId, data, min_balance, chequing_balance) {
    //fixme
    return {
        type: actionTypes.RECEIVE_CHEQUING,
        payload: {
            childId,
            data: data,
            min_balance,
            chequing_balance,
            isLoading: false,
        },
    };
}

export function getChequing(interval, parentId, childId) {
    return async (dispatch) => {
        // THIS IS IMPORTANT, MOVE IT TO SCREEN
        // navigation.setParams({'cards':'Chequing'})

        var balance_source = database().ref(
            '/users/' + parentId + '/' + childId + '/chequing/balance'
        );
        var min_balance_source = database().ref(
            '/users/' + parentId + '/' + childId + '/chequing/min_balance'
        );

        const chequing_balance = (await balance_source.once('value')).val();
        const min_balance = (await min_balance_source.once('value')).val();

        var data_source = database().ref(
            '/users/' + parentId + '/' + childId + '/chequing/transactions'
        );
        const snapshot = await data_source
            .orderByChild('transaction_date')
            .startAt(interval.toISOString())
            .once('value');

        let data = snapshot.val();
        // console.log(data)

        data = data ? Object.values(data) : [];
        for (var i = 0; i < data.length; i++) {
            data[i].transaction_date = new Date(data[i].transaction_date);
        }

        data.sort(function (a, b) {
            return a.transaction_date - b.transaction_date;
        });

        dispatch(receiveChequing(childId, data, min_balance, chequing_balance));
    };
}

function receiveChequingConditions(data, min_balance, chequing_balance) {
    //fixme
    return {
        type: actionTypes.RECEIVE_CHEQUING,
        payload: {
            // obj: json,
            data: data,
            min_balance,
            chequing_balance,
            isLoading: false,
        },
    };
}

export function requestChequing(transfer, parentId, childId) {
    return async (dispatch) => {
        const body = { amount: transfer.replace('$', ''), parentId, childId };

        // console.log(body)
        return fetch(config.url + 'getTransferChequingConditions', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((resp) => {
                return resp.json();
            })
            .then((resp) => {
                //   dispatch(receiveChequingConditions(resp))
                // this.setState({conditions:resp})
                return resp;
            });
    };
}

export function transferChequing(transfer, parentId, childId) {
    return async (dispatch) => {
        const body = {
            amount: transfer.replace('$', ''),
            transaction_date: new Date(),
            parentId,
            childId,
        };

        return fetch(config.url + 'transferChequing', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((resp) => {
                return resp.json();
            })
            .then((resp) => {
                return resp;
            });
    };
}

function receiveChildrenBalances({ childIds, data }) {
    return {
        type: actionTypes.RECEIVE_CHILDREN_CHEQUING_BALANCES,
        payload: { childIds, data },
    };
}

export function requestChildrenChequingBalances({ parentId, childIds }) {
    return async (dispatch) => {
        
        const data = await getChildrenChequingBalances({ parentId, childIds });

        dispatch(receiveChildrenBalances({ childIds, data }));
    };
}
