import { Platform, Dimensions } from 'react-native';

export const NUMERIC_KEYBOARD_TYPE = 'number-pad';

export const SCREEN_WIDTH = Dimensions.get('screen').width;
export const SCREEN_HEIGHT = Dimensions.get('screen').height;

export const IS_ANDROID = Platform.OS === 'android';
export const IS_IOS = Platform.OS === 'ios';
export const IS_WEB = Platform.OS === 'web';

export const WEEKDAYS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];
export const REGULAR_ACTIVITY_MONTH_DAYS = [
    '1st of the month',
    'End of the month',
];

export const REGULAR_FREQS = { Monthly: 1, 'Bi-weekly': 2, Weekly: 4 };
export const REGULAR_DAYS = {
    '1st of the month': 0,
    'End of the month': 1,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0,
};

export const REGULAR_FREQS_REVERSE = {
    1: 'Monthly',
    2: 'Bi-weekly',
    4: 'Weekly',
};
