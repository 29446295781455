/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    Image,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    StyleSheet,
    View,
} from 'react-native';

import { Resizable } from 'react-native-web-hooks';

import { AuthFooter } from './AuthFooter';

import { defaultStyles } from '../../constants/style';
import { SHOW_ACADEMY } from '../../constants/config';

export class AuthContainer extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
        };
    }

    enterFunction = (event) => {
        if (event.keyCode === 13) {
            this.setState({ authModalVisible: false })
        }
    };

    componentDidMount() {
        const { clearAuth } = this.props;

        clearAuth();
        // kidClearAuth()

        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow);
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide);

        Platform.OS === 'web' ? global.document.addEventListener("keydown", this.enterFunction, false) : null;

        // if (this.props.auth.parentId && !this.props.kidAuth.parentId) {
        // 	this.props.navigation.navigate('Parent')
        // }
        // else if (this.props.kidAuth.childId) {
        // 	this.props.navigation.navigate('Kid')
        // }
    }

    componentWillUnmount() {
        this.keyboardDidShowListener.remove();
        this.keyboardDidHideListener.remove();

        Platform.OS === 'web' ? global.document.removeEventListener("keydown", this.enterFunction, false) : null;

    }

    _keyboardDidShow = () => {
        this.setState({
            keyboardState: true,
        });
    };

    _keyboardDidHide = () => {
        this.setState({
            keyboardState: false,
        });
    };

    imageStyle = (layout) => ({
        top: layout.height * 0.05,
        width: layout.height * 0.25,
        height: (layout.height * 0.25) / 2,
        maxHeight: 160 * 4,
        maxWidth: 90 * 4,
    });

    onLoginPress = () => {
        this.props.navigation.navigate('Login');
    };

    render() {
        const {
            footerButtonText,
            footerInfoText,
            onFooterButtonPress,
        } = this.props;

        return (
            <Resizable>
                {(layout) => {
                    return (
                        <KeyboardAvoidingView style={styles.container}>
                            {layout.height > 500 && (
                                <View style={{alignItems: 'center'}}>
                                    <Image
                                        resizeMode={'contain'}
                                        style={this.imageStyle(layout)}
                                        source={require('assets/Pennygem_Logo.png')}
                                    />
                                    {SHOW_ACADEMY ?
                                    <Image
                                        resizeMode={'contain'}
                                        style={this.imageStyle(layout)}
                                        source={require('assets/stem-main-logo.png')}
                                    />
                                    : null}
                                </View>
                            )}
                            <View style={[styles.authContainer]}>
                                {this.props.children}
                            </View>
                            <AuthFooter
                                infoText={footerInfoText}
                                onPress={onFooterButtonPress}
                                buttonText={footerButtonText}
                            />
                        </KeyboardAvoidingView>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        // height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    authContainer: {
        // backgroundColor: 'white',
        // elevation: 5,
        // defaultStyles.shadow,
        maxWidth: 850,
        paddingVertical: 10,
        justifyContent: 'center',
        borderRadius: 20,
    },
});
