/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';

import TouchableNativeFeedback from '../TouchableFeedback';

import Colors from '../../constants/Colors';

import { defaultStyles } from '../../constants/style';

export const ArrowUp = ({ style, onPress }) => {
    // { bottom: layout.height * 0.1 }
    // {top: layout.height * 0.85}
    return (
        <TouchableNativeFeedback
            style={[styles.container, style]}
            onPress={onPress}
        >
            <Icon
                style={styles.icon}
                name={'arrow-up'}
                size={30}
                color={'white'}
            />
        </TouchableNativeFeedback>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        right: 10,
        width: 40,
        height: 40,
        borderRadius: 30,
        backgroundColor: Colors.green1,
        zIndex: 10,
        justifyContent: 'center',
        alignItems: 'center',
        ...defaultStyles.shadow,
        shadowColor: Colors.gray1,
    },
    icon: { zIndex: 3 },
});
