import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import AsyncStorage from '@react-native-async-storage/async-storage';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import config from '../../constants/config';

const auth = firebase.auth;
const database = firebase.database;


function requestLogin() {
    return {
        type: actionTypes.USER_LOGIN,
        // data: [],
        isLoading: true
    }
}


function receiveSignup(json) {
    return {
        type: actionTypes.USER_SIGNUP,
        payload: {
            user: json,
            isLoading: false,
        }
    }
}

function receiveLogin(json, parentId, type) {
    //fixme
    return {
        type: actionTypes.USER_LOGIN,
        payload: {
            user: json,
            isLoading: false,
            parentId,
            type
        }
    }
}

// Parent Login
export function login(user, type) {
    return async dispatch => {
        const userId = user.user.uid;
        const parentId = type ? (await database().ref('/children/'+userId +'/parent').once('value')).val()
                                :(await database().ref('/user_type/' + userId + '/parentId').once('value')).val();
        // const parentId = (await database().ref('/children/'+childId +'/parent').once('value')).val()

        return dispatch(receiveLogin(user, parentId, type))

    }
}

export function signup(user) {
    return async dispatch => {
        // dispatch(requestLogin())
        return dispatch(receiveSignup(user))

    }
}

function handleLogout() {
    //fixme
    return {
        type: actionTypes.USER_LOGOUT,
        payload: {
        }
    }
}

function handleClearAuth() {
    //fixme
    return {
        type: actionTypes.CLEAR_AUTH,
        payload: {
        }
    }
}

export function clearAuth() {
    return async dispatch => {
        dispatch(handleClearAuth());
    }
}


export function logout() {
    return async dispatch => {
        await AsyncStorage.clear();
        dispatch(handleLogout());
    }
}

function saveRememberMeEmail(email) {
    return {
        type: actionTypes.REMEMBER_ME,
        payload: { email }
    }
}

export function rememberMe(email) {
    return dispatch => {
        return dispatch(saveRememberMeEmail(email))
    }
}

export function createCustomToken() {
    return async dispatch => {
        const body = { type: 'child', uid: 'U39RWpKPU7TWctjIUAN9yZ8imag1' }
        return fetch(config.url + 'createCustomToken', {
            method: 'POST',
            body: JSON.stringify(body)
        })
            // .then(response => {console.log('response',response); return response.json()})
            .then(async response => {
                console.log('response', response);
                if (response.status !== 200) {
                    throw await response.json();
                }
                return response.json()
            })
    }
}