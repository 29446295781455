
import * as actionTypes from './actionTypes'

import firebase from 'firebase/app';
import 'firebase/database';

import { withNavigation } from 'react-navigation';
import config from '../../../constants/config'
import { getChildrenSavingsBalances } from '../../../requests/dashboard/dashboardRequests';

const database = firebase.database;

function receiveSavings(childId,data,savings_balance,interest_rate) {
    //fixme
    return {
        type: actionTypes.RECEIVE_SAVINGS,
        payload:{
            childId,
            data:data,
            savings_balance,
            interest_rate,
            isLoading:false,
        }
    }
}


export function getSavings(interval, parentId, childId) {
    return async dispatch => {
        console.log('SAVIGNS START')

    // THIS IS IMPORTANT, MOVE IT TO SCREEN
    // navigation.setParams({'cards':'Savings'})

    let balance_source = database().ref('/users/'+parentId+'/'+childId+'/savings');
    let savings_balance = ((await balance_source.child('balance').once('value')).val())

    // let interest_source = database().ref('/users/'+parentId+'/'+childId+'/savings/int_rate');

    let interest_rate = ((await balance_source.child('int_rate').once('value')).val())*100;


    let today = new Date();
    today.setFullYear(today.getFullYear()-1)
    var data_source = database().ref('/users/'+parentId+'/'+childId+'/savings/transactions');
    const snapshot = await data_source.orderByChild('transaction_date').startAt(interval.toISOString()).once('value')
      // function(snapshot){
        // let data = Object.values(snapshot.val())
    let data = (snapshot.val())


    data = data?Object.values(data):[];
    for (var i = 0; i <data.length; i++) {
      data[i].transaction_date = new Date(data[i].transaction_date);
  }
    data.sort(function(a,b) {
      return a.transaction_date - b.transaction_date;
  });


    dispatch(receiveSavings(childId,data,savings_balance, interest_rate))

  }
}

export function requestSavings(transfer, parentId, childId) {
  return async dispatch => {
  //   console.log('transfer, transfer.replace(,)')
  // console.log(transfer, transfer.replace('$',''))
  const body = { amount: transfer.replace('$',''), parentId, childId}
  return fetch(config.url + 'getTransferSavingConditions', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then((resp) => {
      return resp.json()
    })
    .then((resp) => {
      // this.setState({conditions:resp})
      return resp
    })
  }
}

export function transferSaving(transfer, parentId, childId) {
  return async dispatch => {

  const body = { amount: transfer.replace('$',''), transaction_date: new Date(), parentId, childId}
  return fetch(config.url + 'transferSaving', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then((resp) => {
      return resp.json()
    })
    .then((resp) => {
      // this.getItem(resp.data)
      // this.setState({ setGoalVisible: false })
      return resp
    })
    }
}

function receiveChildrenBalances({ childIds, data }) {
  return {
      type: actionTypes.RECEIVE_CHILDREN_SAVINGS_BALANCES,
      payload: { childIds, data },
  };
}

export function requestChildrenSavingsBalances({ parentId, childIds }) {
  return async (dispatch) => {


      const data = await getChildrenSavingsBalances({ parentId, childIds });

      dispatch(receiveChildrenBalances({ childIds, data }));
  };
}