/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Platform,
    StyleSheet,
    ScrollView,
    View,
    StatusBar,
} from 'react-native';


import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'
import ParentComponentHeader from '../../components/ComponentHeader'
import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as authActions from '../../store/actions/parents/authActions'
import Colors from '../../constants/Colors';

const auth = firebase.auth;
const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly', }

function formatInputValue(str) {
    return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

function formatInterestRate(str) {
    return (str && str !== '%') ? parseFloat(str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")) + '%' : ''
}


Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
};

const HeaderElement = ({ title, focused, onPress }) => {

    return <TouchableNativeFeedback onPress={onPress}>
        <View style={{width:150,margin:20, padding:20, borderRadius:10, backgroundColor:focused?Colors.green1:'white',
        borderWidth:3, borderColor:Colors.green1, alignItems:'center'
        // borderBottomColor:Colors.green1, borderBottomWidth:3
        }}>
            <Text style={{fontSize:18, color:focused?'white':Colors.green1}}>{title}</Text>
            </View>
        </TouchableNativeFeedback>
}


class Settings extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            headerLeft: null,
            header: null
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
            isFocused: false,
            hasCameraPermission: null,
            //  image: '0',
            image: null,
            emailChange: false,
            nameChange: false,
            oldPassword: '',
            password: '',
            confirmPassword: '',
            id: 0,
            allowanceStart: (new Date()).yyyymmdd()
            //  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
            // type: Camera.Constants.Type.back,
        };
    };

    componentDidMount() {
        console.log('this.props.settings')

    }



    render() {

        // const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
        //     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}
        return (<View style={{ justifyContent: 'center', flexDirection: 'row' }}>
            <HeaderElement onPress={()=>this.setState({focused:0})} focused={this.state.focused===0} title={'General'} />
            <HeaderElement onPress={()=>this.setState({focused:1})} focused={this.state.focused===1} title={'Additional'} />
            <HeaderElement onPress={()=>this.setState({focused:2})} focused={this.state.focused===2} title={'Other'} />
        </View>
        )
    }
}

const styles = StyleSheet.create({

});

export default Settings;
// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
