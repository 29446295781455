import { Platform } from 'react-native';

// import * as Analytics from 'expo-firebase-analytics';
import * as amplitudeLib from 'expo-analytics-amplitude';
// import * as amplitude from 'amplitude-js';

// const amplitudeLib = {
//     initializeAsync: () => null,
//     logEventWithPropertiesAsync: () => null,
//     setUserIdAsync: () => null,
//     setUserPropertiesAsync: () => null
// }
// import amplitude from 'amplitude-js';

import ExpoMixpanelAnalytics from '@benawad/expo-mixpanel-analytics';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
// ReactPixel.init('yourPixelIdGoesHere', advancedMatching, options);

// const amplitude = require('amplitude-js');
const amplitude = Platform.OS === 'web' ? {
    logEventWithPropertiesAsync: () => null,
    setUserIdAsync: () => null
} : amplitudeLib

const appType = 'pennygem-parents';
// TODO REWRITE THIS
const version = '1.2.1';

// amplitude.getInstance().init("1fb24c11616f12a468112f58b5e18d7b");
// TODO fix analytics for web
const mixpanel =  Platform.OS === 'web'? {track:()=> null}: new ExpoMixpanelAnalytics('bc5fb9d2467c5bf1fdb496520118f818');
const Analytics =  true ? {
// const Analytics =  Platform.OS === 'web'? {
    track:()=> null, logEvent: () => null, setUserId: () => null,
}: require('expo-firebase-analytics').default;

// const mixpanel =  Platform.OS === 'web'? {track:()=> null} : {track:()=> null};

if (Platform.OS !== 'web') {
    // if (false) {
    amplitude.initializeAsync("1fb24c11616f12a468112f58b5e18d7b");
}

export const ANALYTICS = {
    ACTIONS: {
        SCREEN: 'Screen',
        ACTION: 'Action',
        BUTTON: 'Button'
    },
    EVENTS: {

    },
}


function firebaseLog(eventName, properties) {
    if (appType === 'pennygem-kids') {
        return;
    } else {
        Analytics.logEvent(eventName, properties);
        return;
    }
}

function amplitudeLog(eventName, properties) {
    if (appType === 'pennygem-kids') {
        return;
    } else {
        // amplitude.getInstance().logEvent(eventName);
        amplitude.logEventWithPropertiesAsync(eventName, properties);
        return;
    }
}

// TODO
function mixpanelLog(eventName, properties) {
    mixpanel?.track(eventName, properties);
}

function pixelLog(eventName, properties) {
    ReactPixel?.track(eventName, properties);
    ReactPixel.pageView();
}

//
function firebaseSetUserId(userId) {
    Analytics.setUserId(userId);
}

function amplitudeSetUserId(userId) {
    amplitude.setUserIdAsync(userId);
}

function mixpanelSetUserId(userId) {
    mixpanel?.people?.set({
        userId: userId,
        type: 'parent',
        version: version,
        'udpated': new Date()
    });
}


function pixelSetUserId(userId) {
    // TODO add something?
}


//
function firebaseSetVersion() {

}

function amplitudeSetVersion() {
    // amplitude.setVersionName(version);
}

function mixpanelSetVersion() {

}

function pixelSetVersion() {

}

//
function firebaseSetUserProperties({ properties }) {
    Analytics.setUserProperties({ 'type': 'parent', version: version, });
}

function amplitudeSetUserProperties({ properties }) {
    amplitude.setUserPropertiesAsync({ 'type': 'parent', version: version, })
}

function mixpanelSetUserProperties({ properties }) {
    mixpanel?.register({ version: version });
}

function pixelSetUserProperties({ properties }) {
    // TODO add something?
    // ReactPixel;
}

export function eventLog({ eventName, properties }) {
    firebaseLog(eventName, properties);
    amplitudeLog(eventName, properties);
    mixpanelLog(eventName, properties);
    pixelLog(eventName, properties);
}

export function setUserId({ userId }) {
    firebaseSetUserId(userId);
    amplitudeSetUserId(userId);
    mixpanelSetUserId(userId);
}

export function setVersion() {
    firebaseSetVersion();
    amplitudeSetVersion();
    mixpanelSetVersion();
}

export function setUserProperties({ properties }) {
    firebaseSetUserProperties({});
    amplitudeSetUserProperties({});
    mixpanelSetUserProperties({});
}