import React, { useState, useEffect } from 'react';
import {
    Animated,
    View,
    Platform,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';

import { Picker } from '@react-native-picker/picker';

import Icon from 'react-native-vector-icons/FontAwesome';

import TouchableNativeFeedback from './TouchableFeedback';
import Text from './CustomText';

import Colors from '../constants/Colors';

import ModalContainer from './Modal/Modal';

export function handleScroll(e, height) {
    if (
        e.nativeEvent.contentOffset.y / height > 0.1 &&
        this.state.header !== true
    ) {
        // this.props.navigation.setParams({scrolledHeader:true})
        Animated.timing(this.state.scrolledHeader, {
            toValue: 1,
            duration: 1000,
        }).start();

        Animated.timing(this.state.scrolledPadding, {
            toValue: 1,
            duration: 200,
        }).start();

        this.setState({ header: true });

        return 0;
    }

    if (e.nativeEvent.contentOffset.y / height < 0.1) {
        // this.props.navigation.setParams({scrolledHeader:false})
        Animated.timing(this.state.scrolledHeader, {
            toValue: 0,
            duration: 1000,
        }).start();

        Animated.timing(this.state.scrolledPadding, {
            toValue: 0,
            duration: 200,
        }).start();

        this.setState({ header: false });

        return 0;
    }
}

const Dropdown = ({ visibleOutside }) => {
    const [isVisible, setVisibility] = useState(false);

    useEffect(() => {
        setVisibility(visibleOutside);
    }, [visibleOutside]);

    return (
        <React.Fragment>
            <TouchableOpacity
                onPress={() => {
                    setVisibility(!isVisible);
                }}
            >
                <Icon
                    style={{
                        alignSelf: 'flex-end',
                        zIndex: 3,
                        marginHorizontal: 10,
                        marginBottom: 10,
                    }}
                    name="bars"
                    size={30}
                    color="#F9CD30"
                />
            </TouchableOpacity>

            {isVisible && (
                <View
                    elevation={5}
                    style={{
                        position: 'absolute',
                        maxWidth: 100,
                        top: 70,
                        right: 20,
                        backgroundColor: 'white',
                        alignSelf: 'flex-end',
                        width: '30%',
                        borderRadius: 10,
                        marginRight: 10,
                    }}
                >
                    <TouchableNativeFeedback onPress={() => {}}>
                        <View style={{}}>
                            <Text style={{ margin: 10, color: '#106433' }}>
                                Dashboard
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                    <TouchableNativeFeedback
                        onPress={() => {
                            this.props.navigation.navigate('Login');
                        }}
                    >
                        <View style={{ flex: 1 }}>
                            <Text
                                style={{
                                    flex: 1,
                                    margin: 10,
                                    color: '#106433',
                                }}
                            >
                                Logout
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            )}
        </React.Fragment>
    );
};

export default Dropdown;

const DropdownElementWeb = ({
    categories,
    defaultValue,
    onPress,
    mainElementStyle,
    mainTextStyle,
    containerStyle,
    elementContainerStyle,
    arrowStyle,
    dropdownStyle,
}) => {
    const [isVisible, setVisibility] = useState(false);

    return (
        <View
            style={[
                {
                    width: '50%',
                    height: 40,
                    borderRadius: 20,
                    borderColor: Colors.gray,
                    justifyContent: 'center',
                },
                containerStyle,
            ]}
        >
            <Picker
                selectedValue={defaultValue}
                style={{
                    height: 40,
                    borderRadius: 20,
                    borderWidth: 2,
                    borderColor: Colors.gray,
                    backgroundColor: 'white',
                    width: '100%',
                    color: Colors.gray1,
                    paddingHorizontal: 10,
                    fontFamily: 'Carmen Sans Regular',
                }}
                itemStyle={{
                    width: '100%',
                    height: 40,
                    backgroundColor: 'red',
                    color: 'red',
                    paddingHorizontal: 10,
                    fontFamily: 'Carmen Sans Regular',
                }}
                onValueChange={(itemValue, itemIndex) => onPress(itemValue)}
                mode={'dropdown'}
            >
                {categories.map((item, index) => (
                    <Picker.Item
                        style={{height: 40, backgroundColor: 'red'}}
                        key={`${item}${index}`}
                        label={item}
                        value={item}
                    />
                ))}
            </Picker>
            <View style={styles.arrow} />
        </View>
    );
};

const DropdownElementAndroid = ({
    categories,
    defaultValue,
    onPress,
    containerStyle,
}) => {
    const [isVisible, setVisibility] = useState(false);

    return (
        <View style={[{ width: '80%', borderWidth: 2, height: 40, borderRadius: 20, borderColor: Colors.gray }, containerStyle,]}>
            <Picker
                selectedValue={defaultValue}
                style={{ height: 40, width: '100%', color: Colors.gray1, fontFamily: 'Carmen Sans Regular' }}
                itemStyle={{ fontFamily: 'Carmen Sans Regular' }}
                onValueChange={(itemValue, itemIndex) => onPress(itemValue)}
                mode={'dropdown'}
            >
                {categories.map((item) => (
                    <Picker.Item label={item} value={item} />
                ))}
            </Picker>
        </View>
    );
};

const DropdownElementIOS = ({
    categories,
    defaultValue,
    onPress,
    containerStyle,
    mainElementStyle,
}) => {
    const [isVisible, setVisibility] = useState(false);
    // const [isVisible, setVisibility] = useState(false);

    return (
        <>
            <ModalContainer
                visible={isVisible}
                onClose={() => setVisibility(false)}
            >
                {categories.map((item, index) => <TouchableNativeFeedback key={index}
                    style={{
                        marginVertical: 5,
                        marginTop: index === 0 ? 15 : 0,
                        marginBottom: index === categories.length - 1 ? 15 : 0,
                        width: '80%', paddingBottom: 5, alignItems: 'center',
                        borderBottomWidth: index === categories.length - 1 ? 0 : 1, borderColor: Colors.gray1
                    }}
                    onPress={() => { onPress(item); setVisibility(false) }}>
                    <Text>{item}</Text>
                </TouchableNativeFeedback>)}
            </ModalContainer>
            <TouchableNativeFeedback
                style={{ width: '100%', alignItems: 'center', ...containerStyle }}
                onPress={() => setVisibility(true)}
            >
                <View style={[{
                    justifyContent: 'center', width: '80%', height: 40,
                    borderRadius: 20, borderColor: Colors.gray, borderWidth: 2,
                    ...mainElementStyle
                }]}>
                    <Text style={{ marginLeft: 10, color: Colors.gray1 }}>{defaultValue}</Text>
                </View>
            </TouchableNativeFeedback>
        </>
    );
};

export const DropdownElement =
    Platform.OS === 'android'
        ? DropdownElementAndroid
        : Platform.OS === 'ios'
        ? DropdownElementIOS
        : DropdownElementWeb;

const stylesWeb = StyleSheet.create({

});

const styles = StyleSheet.create({
    arrow: {
        position: 'absolute',
        right: 10,
        // marginRight: Platform.OS==='ios'?10:'5%',
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 10,
        borderRightWidth: 5,
        borderBottomWidth: 0,
        borderLeftWidth: 5,
        borderTopColor: Colors.gray1,
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: 'transparent',
    },
    mainElement: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%',
        marginTop: 10,
        alignSelf: 'center',
        borderColor: Colors.gray,
        borderWidth: 2,
        borderRadius: 20,
        height: 40,
        justifyContent: 'center',
    },
    bottomBorderZero: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
});
