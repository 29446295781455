// import { appConstants } from "";
import * as actionTypes from '../../actions/kids/actionTypes'


const initialState = {
    isLoading: true,
    data: []
};


export default function store(state = initialState, action) {

    switch (action.type) {
        case actionTypes.REQUEST_STORE:

            return {
                ...state,
                isLoading: true,
                data:[]
            };

        case actionTypes.RECEIVE_STORE:

            return {
                ...state,
                isLoading: false,
                data: action.payload.data
            };
        case actionTypes.CLEAR_STORE:

            return {
                ...initialState
            };
        default:
            return state
    }
}