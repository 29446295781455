/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useRef, useState } from 'react';
import {
    findNodeHandle,
    Image,
    Platform,
    StyleSheet,
    View,
    UIManager,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';

import Text from '../CustomText';
import TouchableNativeFeedback from '../TouchableFeedback';
import { useAppTour } from '../AppTour/useAppTour';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import { formatNumberFloat } from '../../helpers/formatNumber';
import { TextSkeleton } from '../skeleton/TextSkeleton';

const maxWidth = 700;
const widthThreshold = 600;
const borderRadius = 25;

const BottomButton = ({ color, title, onPress, image, style }) => {
    return (
        <TouchableNativeFeedback
            style={[{ width: '100%' }]}
            {...(Platform.OS === 'web'
                ? { onPress: onPress }
                : { onPress: onPress })}
        >
            <View
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    borderBottomLeftRadius: borderRadius,
                    borderBottomRightRadius: borderRadius,
                    backgroundColor: color,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 45,
                }}
            >
                {image ? (
                    <Image
                        style={{
                            height: 35,
                            width: 35,
                            alignSelf: 'flex-start',
                        }}
                        resizeMode={'cover'}
                        source={image}
                    />
                ) : (
                    <Text style={[{ color: 'white' }, globalStyle.h1]}>
                        {title}
                    </Text>
                )}
                {/* <Icon style={{ zIndex: 3, marginVertical: 10 }} name="plus" size={25} color={'white'} /> */}
            </View>
        </TouchableNativeFeedback>
    );
};

const CornerIcon = ({ color, image, layout }) => {
    const resizeConst = 360 / 450;
    const width = layout.width * 0.1;
    const height = resizeConst * width;
    const minWidth = 45,
        minHeight = resizeConst * minWidth;
    const maxWidth = 65,
        maxHeight = resizeConst * maxWidth;

    return (
        <View
            style={{
                zIndex: -1,
                position: 'absolute',
                left: 0,
                top: 0,
            }}
        >
            <Image
                resizeMode={'contain'}
                style={{
                    borderTopLeftRadius: borderRadius,
                    width,
                    height,
                    minWidth,
                    minHeight,
                    maxHeight,
                    maxWidth,
                    alignSelf: 'flex-start',
                }}
                source={image}
            />
        </View>
    );
};

const InfoBlock = ({
    title,
    amount,
    onPress,
    style,
    amountStyle,
    titleStyle,
    center,
    isLoading,
}) => {
    return (
        <TouchableNativeFeedback style={style} onPress={onPress}>
            <View
                style={{
                    justifyContent: 'center',
                    ...(center && { alignItems: 'center' }),
                    ...style,
                }}
            >
                {isLoading ? (
                    <TextSkeleton
                        // highlightColor={'blue'}
                        containerStyle={[
                            { height: amountStyle?.lineHeight || 20, width: 45, opacity: 0.4, },
                            amountStyle,
                        ]}
                    />
                ) : (
                    <Text
                        style={[
                            globalStyle.h2,
                            defaultStyles.bold,
                            amountStyle,
                        ]}
                    >
                        {amount}
                    </Text>
                )}
                <Text style={[{ marginTop: 5 }, titleStyle]}>{title}</Text>
            </View>
        </TouchableNativeFeedback>
    );
};

export const ChequingCard = ({
    layout,
    upButton,
    downButton,
    style,
    navigation,
    earned,
    saved,
    spent,
    type,
    isLoading,
}) => {
    const buttonBlockRef = useRef();
    const AppTour = useAppTour({
        tooltipText: 'Click on the card to see more details',
    });
    const ref = useRef();

    useEffect(() => {
        if (buttonBlockRef.current) {
            buttonBlockRef.current.measure(
                (x, y, width, height, pageX, pageY) => {
                    console.log(
                        'MEASURE LAYOUT 2 - ',
                        x,
                        y,
                        width,
                        height,
                        pageX,
                        pageY
                    );
                }
            );
        }
        // const t = findNodeHandle(ref.current);
        // console.log('FIND NODE HANDLE - ', typeof t);
    }, []);

    const width = layout.width;
    const vmin = Math.min(...[layout.width, layout.height]);
    const squareSize = Platform.OS === 'web' ? '8vh' : vmin * 0.13;
    const imageSize = Platform.OS === 'web' ? '18vh' : vmin * 0.3;

    const cardWidth = '90%';

    return (
        <View
            onLayout={AppTour.onLayout}
            ref={AppTour.componentRef}
            // ref={ref}
            elevation={15}
            style={[
                {
                    flexDirection: 'row',
                    borderRadius,
                    backgroundColor: 'white',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingHorizontal: 0.04 * vmin,
                    paddingVertical: 0.03 * layout.height,
                    width: cardWidth,
                    maxWidth: maxWidth,
                },
                defaultStyles.shadow,
                style,
            ]}
        >
            {/* <AppTour.AppTourModal /> */}
            <View
                style={{
                    flex: 0.8,
                    height: '100%',
                    justifyContent: 'space-between',
                }}
            >
                {/* <View style={{ flexDirection:'row', borderColor:'red'}}> */}
                <InfoBlock
                    onPress={() =>
                        navigation.navigate('Chequing', { cards: 'Chequing' })
                    }
                    title={'Chequing'}
                    amount={'$' + formatNumberFloat(earned)}
                    amountStyle={{ ...globalStyle.h3, color: Colors.green1 }}
                    titleStyle={{}}
                    isLoading={isLoading}
                />
                {/* </View> */}
                <View
                    style={{
                        marginTop: 10,
                        width: width > widthThreshold ? '60%' : '90%',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        // borderWidth: 1
                    }}
                >
                    <InfoBlock
                        onPress={() =>
                            navigation.navigate('Chequing', {
                                cards: 'Savings',
                            })
                        }
                        style={{ flex: 1 }}
                        title={'Savings'}
                        amount={'$' + formatNumberFloat(saved)}
                        titleStyle={{ ...style.h4 }}
                        amountStyle={{ ...globalStyle.h4 }}
                        isLoading={isLoading}
                    />
                    <View
                        style={{
                            height: '100%',
                            width: 3,
                            height: 50,
                            backgroundColor: 'white',
                            marginHorizontal: 15,
                        }}
                    />
                    <InfoBlock
                        style={{ flex: 1 }}
                        onPress={() => navigation.navigate('Purchases')}
                        title={'Spent'}
                        amount={'$' + formatNumberFloat(spent)}
                        titleStyle={{}}
                        amountStyle={{ ...globalStyle.h4 }}
                        isLoading={isLoading}
                    />
                </View>
            </View>
            {type === 'child' ? (
                <Image
                    style={{
                        position: 'absolute',
                        top: -20,
                        right: Platform.OS === 'web' ? -20 : -20,
                        height: imageSize,
                        width: imageSize,
                    }}
                    resizeMode={'contain'}
                    source={require('assets/cape_new.gif')}
                />
            ) : (
                <View
                    ref={buttonBlockRef}
                    // 0 0 63 102 286.5 173.5
                    // onLayout={() => {
                    //     buttonBlockRef.current.measure((x, y, width, height, pageX, pageY) => {
                    //         console.log("MEASURE LAYOUT 2 - ", x, y, width, height, pageX, pageY);
                    //     });
                    // }}
                    style={{
                        width: '100%',
                        maxWidth: 70,
                        flex: 0.2,
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        height: '100%',
                    }}
                >
                    {/* <Button style={{ flex: 0.45, aspectRatio: 1 }} onPress={upButton} color={Colors.green1} title={'plus'} iconSize={25} /> */}
                    {/* <Button style={{ flex: 0.45, aspectRatio: 1 }} onPress={downButton} color={Colors.rose} title={'minus'} iconSize={22} /> */}
                    {[
                        {
                            color: Colors.green1,
                            sign: 'plus',
                            onPress: upButton,
                        },
                        { type: 'sep' },
                        {
                            color: Colors.red,
                            sign: 'minus',
                            onPress: downButton,
                        },
                    ].map((item, index) =>
                        item.type === 'sep' ? (
                            <View key={index} style={{ height: 10 }} />
                        ) : (
                            <TouchableNativeFeedback
                                key={index}
                                style={[
                                    styles.depositButton,
                                    {
                                        backgroundColor: item.color,
                                        width: squareSize,
                                        height: squareSize,
                                    },
                                ]}
                                onPress={item.onPress}
                            >
                                <Icon
                                    style={{ zIndex: 3 }}
                                    name={item.sign}
                                    size={25}
                                    color={'white'}
                                />
                            </TouchableNativeFeedback>
                        )
                    )}
                </View>
            )}
        </View>
    );
};

export const ActivityCard = ({
    layout,
    title,
    onPress,
    buttonTitle,
    onButtonPress,
    style,
    image,
    amount,
    color,
    cornerImage,
    type,
    isLoading,
}) => {
    const width = layout.width;
    const vmin = Math.min(...[layout.width, layout.height]);

    const cardWidth = width > 850 ? '48%' : '90%';

    return (
        <View
            elevation={15}
            style={[
                {
                    flex: 1,
                    borderRadius,
                    width: cardWidth,
                    alignItems: 'center',
                    backgroundColor: 'white',
                },
                defaultStyles.shadow,
                style,
            ]}
        >
            <CornerIcon color={color} image={cornerImage} layout={layout} />
            <InfoBlock
                style={{ width: '100%', flex: 1, justifyContent: 'center' }}
                onPress={onPress}
                title={title}
                center={true}
                amount={amount}
                titleStyle={{}}
                amountStyle={globalStyle.h3}
                isLoading={isLoading}
            />
            {type === 'child' ? null : (
                <BottomButton
                    style={{ flex: 1, borderWidth: 1, width: '100%' }}
                    image={image}
                    onPress={onButtonPress}
                    color={color}
                    title={buttonTitle}
                />
            )}
        </View>
    );
};

export const PurchasesCard = ({
    layout,
    info,
    onPress,
    onButtonPress,
    style,
    isLoading,
}) => {
    const vmin = Math.min(...[layout.width, layout.height]);

    return (
        <TouchableNativeFeedback
            style={{
                flex: 0.48,
                backgroundColor: 'white',
                // elevation: 10,
                borderRadius,
                // flex: 1,
                maxWidth: maxWidth,
                width: '100%',
                flexDirection: 'row',
                backgroundColor: 'white',
                //    borderWidth: 1,
                paddingTop: 0.05 * vmin,
                paddingBottom: 0.02 * vmin,
                ...defaultStyles.shadow,
            }}
            onPress={onPress}
        >
            {/* <View  style={[{
            // elevation: 10,
            borderRadius,
             flex: 1, maxWidth: maxWidth, width: '100%', flexDirection: 'row',
            backgroundColor: 'white',
            borderWidth: 1,
            // paddingTop: 0.05 * vmin, paddingBottom: 0.02 * vmin,
        }, defaultStyles.shadow,]}> */}
            <View style={{ paddingLeft: 0.05 * vmin }}>
                <View
                    style={{
                        flex: 0.2,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {/* <Icon style={{ zIndex: 3, marginRight: 10, }} name="dollar-sign" size={20} color={Colors.red} /> */}
                    <Text style={[{ color: Colors.red }, defaultStyles.bold]}>
                        Purchases
                    </Text>
                    <Image
                        style={{ width: 20, height: 20, marginLeft: 5 }}
                        source={require('assets/dashboard/Money_1.png')}
                    />
                </View>
                <View style={{ flex: 1, justifyContent: 'center' }}>
                    {info.map((item, index) => (
                        isLoading ?
                            <TextSkeleton
                            // highlightColor={'blue'}
                            containerStyle={[
                                { height: 16, width: 75, marginVertical: 5, opacity: 0.4, },
                                // amountStyle,
                            ]}
                        />
                            : <Text key={index} style={{ marginVertical: 5 }}>
                            <Text style={{}}>{item[0]}</Text> {item[1]}
                        </Text>
                    ))}
                </View>
            </View>
            {/* </View> */}
        </TouchableNativeFeedback>
    );
};

export const StoreCard = ({ layout, onPress, style }) => {
    const vmin = Math.min(...[layout.width, layout.height]);
    const smallCircleSize = layout.height * 0.1;
    const circleSize =
        layout.width > 600 ? layout.height * 0.12 : layout.height * 0.17;
    const storeImageSize =
        layout.width > 600 ? layout.height * 0.13 : layout.height * 0.16;
    const textTopMargin =
        layout.width > 600 ? layout.height * 0.09 : layout.height * 0.11;

    return (
        <>
            <TouchableNativeFeedback
                style={{
                    flex: 0.48,
                    zIndex: 2,
                    backgroundColor: Colors.green1,
                    overflow: 'hidden',
                    alignItems: 'center',
                    borderRadius,
                    ...defaultStyles.shadow,
                }}
                onPress={onPress}
            >
                {/* <View style={[{
                alignItems: 'center',
                backgroundColor: Colors.green1,
                width: '100%', flex: 1, zIndex: 1,
                borderRadius,
                // borderWidth: 3, borderColor: Colors.green1,
                // justifyContent: 'center',
                overflow: "hidden",
            }, defaultStyles.shadow,]}> */}
                <Text
                    style={[
                        { color: 'white', marginTop: textTopMargin },
                        defaultStyles.bold,
                    ]}
                >
                    Store
                </Text>
                <View
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        left: -smallCircleSize / 4,
                        top: -smallCircleSize / 2.5,
                        overflow: 'visible',
                        height: smallCircleSize,
                        width: smallCircleSize,
                        borderRadius: smallCircleSize / 2,
                        backgroundColor: '#9ee459',
                    }}
                />
                <View
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        right: -circleSize / 4,
                        bottom: -circleSize / 4,
                        overflow: 'visible',
                        height: circleSize,
                        width: circleSize,
                        borderRadius: circleSize / 2,
                        backgroundColor: '#9ee459',
                    }}
                />
                {/* </View> */}
            </TouchableNativeFeedback>
            <View
                pointerEvents="none"
                style={{
                    elevation: 10,
                    zIndex: 11,
                    position: 'absolute',
                    overflow: 'visible',
                    right: -storeImageSize / 10,
                    bottom: -storeImageSize / 10,
                }}
            >
                <Image
                    style={{
                        zIndex: -1,
                        height: storeImageSize,
                        width: storeImageSize,
                        zIndex: 10,
                    }}
                    resizeMode={'contain'}
                    source={require('assets/dashboard/store_icon.png')}
                />
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    depositButton: {
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 70,
        maxWidth: 70,
    },
});
