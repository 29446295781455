// import { appConstants } from "";
import * as actionTypes from '../../actions/kids/actionTypes'


const initialState = {
    isLoading: true,
    user: {},
    childId: '',
    parentId: '',
    avatar: 0,
    avatarImage: null,
    dashboard: {
        chequing: 0,
        savings: 0,
        spent: 0,
        goals: 0,
        jobs: 0,
        purchases_approved: 0,
        purchases_pending: 0
    }
};


export default function chat(state = initialState, action) {

    switch (action.type) {

        case actionTypes.USER_LOGIN:
            return {
                ...state,
                isLoading: false,
                user: action.payload.user,
                childId: action.payload.user.user.uid,
                parentId: action.payload.parentId

            };

        case actionTypes.USER_LOGOUT:
            return initialState;

        case actionTypes.CLEAR_AUTH:
            return initialState;
        // return {...state, ...initialState};


        case actionTypes.SAVE_AVATAR:
            return {
                ...state,
                avatar: action.payload.avatar,
                avatarImage: action.payload.avatarImage,
                avatarHash: action.payload.avatarHash,
            };

        case actionTypes.RECEIVE_DASHBOARD_DATA:
            return {
                ...state,
                dashboard: {...action.payload}
            };
        case actionTypes.SAVE_CHILD_NAME:
            return {
                ...state,
                name: action.payload.name
            };
        case actionTypes.SET_DASHBOARD_DATA_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            };



        default:
            return state
    }
}