/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { ActivityIndicator, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
// import 'firebase/sto'

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput';
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton, InfoButtonControlled } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { kidInfo, parentInfo } from '../../../constants/info';
import { InputRow } from '../../../components/settings/rows/InputRow';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
const database = firebase.database;

class CreateProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(parentInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            nameInput: true,
            usernameInput: true,
        };
    }

    componentDidMount() {
        const { parentProfile } = this.props.parent;
        // Object.keys(newKid).map(item=>this.setState)
        this.setState({
            ...Object.keys(parentProfile).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: parentProfile[curr] } }, {}),
            available: parentProfile.username && this.checkIfAvailable(parentProfile.username)
        })
        // this.checkIfAvailable(newKid.profile.username)

    }

    checkIfAvailable(name) {
        return database().ref('username/').orderByKey()
            // return database().ref('children/').orderByChild('username')
            .equalTo(name.toLowerCase())
            // .startAt(name.toUpperCase())
            // .endAt(name.toLowerCase())
            .once('value')
            .then(snap => {
                console.log('snap.val() - ', snap.val(), !snap.val());
                (!snap.val() && name) !== this.state.available ? this.setState({ available: !snap.val() && name }) : null;
                return snap.val()
            })

    }

    formatUsername(text) {
        return text.replace(/( |-|,)/g, '')
    }

    checkConditions() {
        const { name, username, available } = this.state;
        console.log('name, userName, password, available, ', name, username);
        this.setState({
            nameInput: name, usernameInput: username && available
        })

        return (name && username && this.checkIfAvailable(username)) ? true : false
    }

    async generateAvailable(name) {
        console.log('Starting!')

        let tmpName = name
        let counter = 0;
        while (await this.checkIfAvailable(tmpName.toLowerCase())) {
            console.log('new name  - ', counter, tmpName)
            tmpName = name + counter
            counter += 1
        }
        return this.setState({ username: tmpName })
    }

    proceed() {
        const { user } = this.props.auth;
        const email = user.user.email;
        const username = this.state.username.toLowerCase()

        firebase.auth().currentUser.updateProfile({ displayName: this.state.name });
        this.props.sendVerificationEmail(this.state.name, email);

        database().ref('username/').update({
            [username]: email
        });


        this.setState({ section: this.state.section + 1 })
        this.props.parent.childIds.length ? this.props.navigation.navigate('Profile') :
        this.props.navigation.navigate('PrivacyPolicy')
    }


    render() {
        const { layout } = this.props;
        const { name, username, nameInput, usernameInput, } = this.state;
        const title = 'Create Parent Profile'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
            Let’s start by creating your profile.
		</Text>

        const vmin = Math.min(...[layout.width, layout.height]);
        const top = vmin * 0.1 < 50 ? 50 : vmin * 0.1
        console.log(vmin, vmin * 0.1)

        return (
            <BlockWrapper layout={layout} title={title} description={description} hideCloseIcon={true}>
                {(props) => <><View style={{
                    borderRadius: 10, borderColor: 'lightgray',
                    paddingBottom: 10,
                    flexDirection: layout.width > 850 ? 'row' : 'column'
                }}>
                    <View style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                        <InputRow
                            text={this.state.name}
                            onChangeText={text => {
                            this.checkIfAvailable(text)
                                .then((res) => { res ? this.generateAvailable(text) : null });
                            this.setState({ usernameInput: true, nameInput: true, name: text, username: this.formatUsername(text) })
                            }}
                            error={!nameInput}
                            inputParams={{}}
                            label={parentInfo['name'].label}
                            infoButtonText={parentInfo['name'].text}
                            />

                        <InputRow
                            text={this.state.username}
                            onChangeText={text => {
                                console.log('chaingin?');
                                // this.checkIfAvailable(text);
                                this.setState({ usernameInput: true, username: this.formatUsername(text) },
                                () => { this.checkIfAvailable(text) })
                            }}
                            error={!usernameInput}
                            inputParams={{}}
                            label={parentInfo['username'].label}
                            infoButtonText={parentInfo['username'].text}
                        />
                        {this.state.username ? <View style={{ marginTop: 7, marginBottom: 5, alignSelf: 'flex-start', marginLeft: 20 }}>{this.state.available ? <Text style={{ color: Colors.green1 }}>Available</Text> :
                            <Text style={{ color: Colors.red }}>Not Available</Text>}</View> : null}

                    </View>

                </View>
                    <View style={{ flexDirection: 'row' }}>
                        {<Button title={'Back'} onPress={() => { this.props.back() }} />}
                        <Button title={'Proceed'} onPress={() => {
                            this.checkConditions() ? this.proceed() : null
                            this.props.saveParentProfile({ type: 'parent', name, username, });
                        }}></Button>
                    </View>
                </>
            }
        </BlockWrapper>)

    }

}


class LoginScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        const currentUser = auth().currentUser
        console.log('currentUser', currentUser)
        if (childIds.length > 0 || (currentUser && currentUser.displayName)) {
            // this.setState({ section: 2 })
            // this.props.navigation.navigate('Profile')
        }
        // kidClearAuth()
    }

    createCustomToken() {
        this.props.actions.createCustomToken()
            .then(res => console.log('cUSTOM TOKEM', res));
    }

    render() {

        return (
            <Resizable>
                {(layout) => {
                    return (
                        <View style={{ height: '100%', flex: 1 }}>

                            {this.state.loading && <View style={{
                                position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                                justifyContent: 'center', height: '100%', width: '100%'
                            }}>
                                <ActivityIndicator color={Colors.green1} size={'large'} />
                            </View>}
                            {this.props.parent.childIds.length && <View style={{ zIndex: 1, position: "absolute", right: 10, top: 10 }}>
                                <TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Dashboard') }}>
                                    <Text style={{ fontSize: 40,}}>X</Text>
                                </TouchableNativeFeedback>
                            </View>}

                            <CreateProfile {...this.props.actions} layout={layout} navigation={this.props.navigation} auth={this.props.auth}
                                parent={this.props.parent}
                                back={() =>
                                    // this.setState({ section: this.state.section - 1 })
                                    this.props.navigation.navigate('Welcome')
                                }
                                proceed={() => {}}
                            />

                        </View>)
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(LoginScreen);
