/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { ActivityIndicator, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton, InfoButtonControlled } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { allowanceInfo, allowanceTable } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
const database = firebase.database;
const storage = firebase.storage;


class Allowance extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            day: 'Friday',
            frequency: 'Weekly',
            amount: 10,
            year: 10,
            regular: true,
            loading: true,
            text: '',
        };
    };

    componentDidMount() {
        const { newKid } = this.props.parent;
        const allowance = newKid.allowance
        newKid.profile.age ? this.setState({ year: newKid.profile.age, amount: newKid.profile.age }) : null
        this.setState({ ...allowance })

        // for (let i in allowance){
        //     console.log('i - ', i, allowance[i])
        //     this.setState({[i]:i==='amount'?parseFloat(allowance[i]):allowance[i]})
        // }

        // const privacyPolicy =
        return storage().ref('legals/TermsAndConditions.txt').getDownloadURL()
            .then(privacyPolicy => fetch(privacyPolicy))
            .then(res => res.text())
            .then(res => { this.setState({ loading: false, text: res }) })
            .catch(err => console.log('err'))
    }


    proceed() {
        this.props.proceed()

    }

    back() {
        this.props.back()
    }

    render() {
        const { layout } = this.props;
        const { amount, frequency, day } = this.state;

        const title = 'Terms & Conditions'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
            By signing up for Pennygem, you accept these Terms and Conditions.
		</Text>

        let amountText = `The amount of recurring allowance for your child. You can always change it later. For your reference, an average family pays approximately 50 cents to $1 per week for each year of a child\'s age. For example, a ${this.state.year}-year-old would get $${this.state.year * 0.5}-$${this.state.year} per week. Refer to this table or blog post for more detail.`
        amountText = amountText.split(' ').map(item => item === 'this' ?
            <TouchableNativeFeedback onPress={() => { this.setState({ amountVisible: false, tableVisible: true }) }}><Text><Text style={{ textDecorationLine: 'underline' }}>this</Text> </Text></TouchableNativeFeedback>
            : <Text>{item} </Text>)


        return (
            <BlockWrapper
                layout={layout}
                description={description}
                title={title}
                hideCloseIcon={true}
            >
                {/* <View> */}
                {(props) => (
                    <>
                        <View style={styles.container}>
                            {this.state.loading ? <View style={{ height: layout.height*0.4, justifyContent: 'center' }}><ActivityIndicator size={"large"} color={Colors.green1} /></View>
                                :
                                <View style={{ height: layout.height*0.4 }}>
                                    <ScrollView nestedScrollEnabled={true}
                                        style={{
                                            paddingVertical: 20, overflow: 'hidden', paddingHorizontal: 10,
                                            borderColor: Colors.gray, borderRadius: 10, borderWidth: 1, flex: 1
                                        }}
                                        contentContainerStyle={{}}>
                                        <View style={{ flexDirection: 'column' }}>
                                            {this.state.text.split('\n').map(item => {
                                                var re = /^[0-9]\. /;
                                                return <Text style={{ fontFamily: re.test(item) ? 'Carmen Sans Bold' : 'Carmen Sans Regular' }}>{item}</Text>
                                            })}
                                            {/* {this.state.text.split('\n').map(item=>item).join('\n')} */}
                                        </View>
                                    </ScrollView>
                                </View>}
                            <TouchableNativeFeedback onPress={() => this.setState({ regular: !this.state.regular })}>
                                <View style={{ zIndex: -1, marginLeft: 0, marginTop: 10, width: '100%', flexDirection: 'row', marginVertical: 10, alignItems: 'center' }}>
                                    <View style={{
                                        borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
                                        borderColor: Colors.green1, borderWidth: 3
                                    }}>
                                        <Icon style={{ zIndex: -1, opacity: this.state.regular ? 0 : 1 }} name="check" size={16} color={Colors.green1} />
                                    </View>
                                    {/* <View style={{ borderWidth: 3, borderRadius: 5 }}><Text> ? null : '1'}</Text></View> */}
                                    <View style={{ marginLeft: 10, width: '90%', }}>
                                        <Text style={[{ color: Colors.green1, width: '90%', }, defaultStyles.bold]}>
                                            I have read, understood, and I agree to the Term and Conditions
                                        </Text>
                                    </View>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                        <View style={{ width: '50%', zIndex: -1, justifyContent: "center", flexDirection: 'row' }}>
                            <Button title={'Back'} onPress={() => this.back()}></Button>
                            <Button disabled={this.state.regular} title={'Proceed'} onPress={() => (this.state.amount || (!this.state.regular)) ? this.proceed() : null}></Button>
                        </View>
                    </>)}
                {/* </View> */}
            </BlockWrapper>)
    }
}


class LoginScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        // kidClearAuth()
    }



    render() {

        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    return <View style={{ height: '100%', flex: 1 }}>

                        {this.state.loading ? <View style={{
                            position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                            justifyContent: 'center',
                            height: '100%', width: '100%'
                        }}>
                            <ActivityIndicator color={Colors.green1} size={'large'} />
                        </View> : null}

                        <Allowance layout={layout} auth={this.props.auth}
                            parent={this.props.parent} actions={this.props.actions}
                            navigation={this.props.navigation}
                            kidData={this.props.actions.kidData}
                            back={() =>
                                this.props.navigation.navigate('PrivacyPolicy')
                            }
                            // proceed={() => this.setState({ section: this.state.section })}
                            proceed={() => this.props.navigation.navigate('Profile')}
                            startLoading={() => this.setState({ loading: true })} stopLoading={() => this.setState({ loading: false })}
                        />

                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        marginVertical: 20,
        paddingHorizontal: 20,
        borderRadius: 10,
        maxWidth: 400,
    },
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(LoginScreen);
