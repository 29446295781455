// import { appConstants } from "";
import * as actionTypes from '../../actions/parents/actionTypes'
import {filterPurchases} from '../../../helpers/purchases/filterPurchases'



const initialState = {
    isLoading: true,
};


export default function chat(state = initialState, action) {
    let values = {}

    switch (action.type) {
        case actionTypes.REQUEST_PURCHASES:

            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.RECEIVE_PURCHASES:
            values = filterPurchases(action.payload.data_approved, action.payload.data_pending)
            // console.log(values)
            // console.log(action.payload.childId)
            console.log("I AM HERE!!!!!", values, action.payload)
            return {
                ...state,
                isLoading: false,
                [action.payload.childId]: {
                    ...values,
                }
            };

        default:
            return state
    }
}