/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useCallback, useEffect } from 'react';
import { StyleSheet, View, Text, Platform, TouchableOpacity, Image, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { withNavigation } from 'react-navigation';

import { ParentHeaderCard } from '../../../components/Header/HeaderBlock';
import Colors from '../../../constants/Colors';
import { ROUTE_NAMES } from '../../../navigation/navigationConstants';

import { SCREEN_WIDTH } from '../../../constants/constants';
import globalStyles, { defaultStyles } from '../../../constants/style';
import { getVideoThumbnail } from '../../../helpers/academy/videoThumbnails';

import { requestAcademyVideos, requestAcademyArticles, requestAcademyQuizzes } from '../../../requests/academy/AcademyDataRequests';

const HARDCODED_ACADEMY_URL = 'https://static.wixstatic.com/media/5b0236_c7a4d2f1af30406a8ba976b635bc9d7b~mv2.png';

const HARDCODED_ACADEMY_VIDEOS = [
    {
        url: 'https://www.youtube.com/embed/KryOOZQLAEg',
        image: 'https://img.youtube.com/vi/KryOOZQLAEg/default.jpg',
        title: 'Introducing Pennygem',
    },
    {
        url: '//player.vimeo.com/video/68481134',
        title: 'Money',
        image: 'https://i.vimeocdn.com/video/201854018-4a1fbd2e5db84be0e2a84b1d03a4acc4881a2d8da836bcdd8171454c1caa6989-d_295x166',
}
];

const AcademyCaretoryCard = withNavigation((props) => {
    const { title, description, url, image, navigation } = props;
    const uri = image || HARDCODED_ACADEMY_URL;

    const onCardPress = () => {
        navigation.navigate(props.route, {
            title, description, url
        });
    }

    return (<TouchableOpacity onPress={onCardPress} style={styles.academyCardContainer}>
        <Image source={{ uri }} style={styles.academyImage} />
        <Text style={styles.academyCardTitle}>{title}</Text>
        <Text style={styles.academyCardDescription}>{description}</Text>
    </TouchableOpacity>)
})

const CardListContainer = (props) => {
    const { getData, title, description } = props;
    // const data = useSelector(getData);
    const data = HARDCODED_ACADEMY_VIDEOS;

    return (<>
        <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
        </View>
        <ScrollView horizontal>
            {data.map(item => <AcademyCaretoryCard key={item.title} route={props.route} {...item} />)}
            <AcademyCaretoryCard title={'More videos'} route={props.routeMore}  />
        </ScrollView>
    </>);
}

export const Articles = (props) => {
    // const vi
    // const {  } = useSelector(store => store);
    const dispatch = useDispatch();

    useEffect(() => {
        requestAcademyVideos();
        requestAcademyQuizzes();
        requestAcademyArticles();

        getVideoPlaceholders();
        // dispatch();
    }, []);

    const getVideoPlaceholders = useCallback(() => {
        const placeholders = [];
        HARDCODED_ACADEMY_VIDEOS.forEach(async (item) => {
            const resp = await getVideoThumbnail(item.url);
            console.log('THUMBNAIL - ', resp);
            placeholders.push(resp);
        });

        // setVideoPlaceholders(placeholders);

        return placeholders;
    }, []);

    const getAcademyVideos = (store) => store.academy?.videos || [];
    const getAcademyQuizzes = (store) => store.academy?.quizzes || [];
    const getAcademyArticles = (store) => store.academy?.articles || [];

    // TODO move container to component
    return (
        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
            {/* <View style={styles.contentContainer}> */}
            <CardListContainer title={'Videos'} routeMore={ROUTE_NAMES.VIDEOS} route={ROUTE_NAMES.VIDEO} getData={getAcademyVideos} />
            <CardListContainer title={'Quizzes'} routeMore={ROUTE_NAMES.QUIZZES} route={ROUTE_NAMES.QUIZ} getData={getAcademyQuizzes} />
            <CardListContainer title={'Articles'} routeMore={ROUTE_NAMES.ARTICLES} route={ROUTE_NAMES.ARTICLE} getData={getAcademyArticles} />
            {/* </View> */}
        </ScrollView>
    );
}

const MAX_BLOCK_WIDTH = Math.min(SCREEN_WIDTH, 700);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // height: '100%',
        borderWidth: 1,
        backgroundColor: Colors.white,
    },
    // TODO get max width for web
    contentContainer: {
        // flex: 1,
        // maxWidth: 650,
    },
    academyCardContainer: {
        ...defaultStyles.shadow,
        width: MAX_BLOCK_WIDTH / 2,
        height: 180,
        margin:40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
    },
    academyImage: {
        width: '80%',
        height: 120,
        borderRadius: 10,
    },
    academyCardTitle: {
        color: Colors.black,
        fontWeight: 'bold',
        marginVertical: 10,
    },
    academyCardDescription: {
        color: Colors.green3,
    },
    titleContainer: {
        width: '100%',
        alignItems: 'center',
    },
    title: {
        ...globalStyles.h1,
        color: Colors.green1,
    }
});
