/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Platform, StyleSheet, View, TouchableOpacity, ScrollView } from 'react-native';
import Modal from 'react-native-modal';

import { Resizable } from 'react-native-web-hooks';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import firebase from 'firebase/app';
import 'firebase/database';

import Text from '../../components/CustomText';
import TouchableNativeFeedback from '../../components/TouchableFeedback';

import TransferBlock from '../../components/chequing/TransferBlock';

import {
    DevNotificationTokenBlock,
    ParentHeaderCard,
} from '../../components/Header/HeaderBlock';

import ModalContainer from '../../components/Modal/Modal';
import { openNewJobModal } from '../../components/jobs/NewJobModal';

import { withdrawal, deposit } from '../../helpers/parentTransfer';

import * as goalsActions from '../../store/actions/parents/goalsActions';
import * as authActions from '../../store/actions/parents/authActions';
import * as jobsActions from '../../store/actions/parents/jobsActions';
import * as chequingActions from '../../store/actions/parents/chequingActions';
import * as purchasesActions from '../../store/actions/parents/purchasesActions';
import * as savingsActions from '../../store/actions/parents/savingsActions';

import { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import {
    eventLog,
    setVersion,
    setUserId,
} from '../../helpers/analytics/analytics';
import { sendNotification, setUpParentNotifications } from '../../helpers/notifications/notifications';
import { IS_WEB } from '../../constants/constants';
import { formatNumberFloat } from '../../helpers/formatNumber';
import { BalanceCard, JobsCard, GoalsCard } from '../../components/Dashboard/StatusBlocks';

const appType = 'pennygem-parents';

const database = firebase.database;

const maxWidth = 700;
const widthThreshold = 600;

const DASHBOARD_MENU = [
    { title: 'Allowance', path: 'RegularSettings' },
    { title: 'Expenses', path: 'ExpenseSettings' },
    { title: 'Chequing Account', path: 'ChequingSettings' },
    { title: 'Savings Account', path: 'SavingsSettings' },
];

// String.prototype.toUpperCase()
// return String?
Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

function WithdrawModal({ isVisible, toggle, error, onPress }) {
    // const [withdrawVisible, setWithdrawVisible] = useState(false)
    const t = 1;
    t = 3;

    return (
        <ModalContainer visible={isVisible} onClose={() => toggle()}>
            <TransferBlock
                type={'withdraw'}
                title={'Withdraw from Chequing'}
                label={'Amount to Withdraw'}
                error={error}
                onPress={(value) => {
                    // setValueUpdated(false);
                    onPress(value);
                    // toggle();
                    // setValue('');
                }}
            />
        </ModalContainer>
    );
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.descriptionRef = React.createRef();
        this.dateRef = React.createRef();

        this.myRef = {};

        this.state = {
            data: {},
            modalVisible: true,
            id: 0,
            isLoading: true,
            jobKids: {},
        };
    }

    componentDidMount() {
        const { actions, auth, parent, navigation, onAddJobPress } = this.props;
        const { parentId } = auth;
        const { childId } = this.props.navigation.state.params;

        actions.getJobs(parentId, childId);
        actions.getGoals(parentId, childId);

        console.log({1: Object.keys(this.props), 2: Object.keys(navigation)})
        console.log(this.props.navigation.state.params)
        console.log(this.props.navigation.router)

        // ANALYTICS BLOCK
        // eventLog({ eventName: 'dashboard_screen', properties: {} });
        // setUserId({ userId: parentId });
        // setVersion();
        // Analytics.setCurrentScreen('parents/Dashboard');
        // Analytics.logEvent('page_view', { page_path: 'parents/Dashboard', page_title: 'Dashboard' })
    }

    componentWillUnmount() {
        // Remove the event listener
        console.log('UNMOUNTED MAIN!');
        // this.focusListener.remove();
    }

    render() {
        const { withdrawModal } = this.state;

        const { childId } = this.props.navigation.state.params;
        const { navigation, auth, parent, chequing, savings, jobs, goals } = this.props;
        // const { chequing } = this.props.parent.dashboard;

        const chequingBalance = chequing[childId]?.balance;
        const savingsBalance = savings[childId]?.balance;

        const pendingJobs = jobs.pending[childId] || [];
        const currentJobs = jobs.data[childId] || [];
        const currentGoals = goals.data[childId] || [];

        const childJobs = [ ...pendingJobs, ...currentJobs];
        const childGoals = [ ...currentGoals];

        // completed, pending, data
        // return (<View></View>)
        return (
            <ScrollView style={{ flex: 1, backgroundColor: 'white', }}
                contentContainerStyle={{ alignItems: 'center',  paddingVertical: 50,paddingBottom: 100}}
            >
                <Modal
                    // animationIn={'slideInBottom'}
                    // animationOut={'slideOutBottom'}
                    animationInTiming={500}
                    animationOutTiming={500}
                    backdropColor={'darkgrey'}
                    backdropOpacity={0.6}
                    onBackdropPress={() => this.setState({
                            dashboardMenuVisible: false,
                        })
                    }
                    style={{justifyContent: 'flex-end'}}
                    isVisible={this.state.dashboardMenuVisible}
                    // isVisible={true}
                    onRequestClose={() => {
                        this.setState({
                            dashboardMenuVisible: false,
                        });
                    }}
                >
                    <View style={{ justifyContent: 'flex-end'}}>
                        <View style={{ backgroundColor: 'white', borderRadius: 25 }}>
                            {DASHBOARD_MENU.map((item, index) => {
                                return <React.Fragment
                                key={item.title}
                                >
                                {<View
                                    style={{width: '100%', height:1, backgroundColor: 'lightgray'}} />}
                                <TouchableOpacity
                                    style={{paddingVertical: 20, width: '100%', alignItems: 'center'}}
                                    onPress={() => {
                                        this.setState({dashboardMenuVisible: false});
                                        navigation.navigate(item.path, { childId });
                                }}>
                                    <Text style={{color: Colors.green1}}>{item.title}</Text>
                                </TouchableOpacity>
                                </React.Fragment>
                            })}
                        </View>
                        <TouchableOpacity
                            style={{ marginVertical: 20, backgroundColor: 'white', borderRadius: 20, paddingVertical: 20, width: '100%', alignItems: 'center'}}
                            onPress={() => { this.setState({dashboardMenuVisible: false}) }}
                        >
                            <Text style={{color: Colors.green1, ...defaultStyles.bold}}>Cancel</Text>
                        </TouchableOpacity>
                    </View>
                </Modal>

                <BalanceCard
                    childId={childId}
                    onMenuPress={() => this.setState({dashboardMenuVisible: true})}
                    upButton={() => this.setState({transferChequingVisible: true})}
                    downButton={() => this.setState({withdrawModal: true})}
                    style={{}}
                    navigation={navigation}
                    earned={chequingBalance}
                    saved={savingsBalance}
                    spent={0}
                    type
                    isLoading={false}
                />

                <View style={{width: '100%', alignItems: 'center', maxWidth: 700}}>
                <JobsCard
                    childId={childId}
                    style={{}}
                    onPress={() => {}}
                    jobs={childJobs}
                    isLoading={jobs.isLoading}
                    onAddJobPress={() => {
                        openNewJobModal({})
                        // this.setState({addJobModal: true})
                    }}
                />
        <View></View>
                <GoalsCard
                    isLoading={goals.isLoading}
                    childId={childId}
                    mainGoal={childGoals[0]}
                    balance={savingsBalance}
                />
                </View>

            </ScrollView>
        );
    }
}

export default connect(
    (state) => {
        return {
            parent: state.parent,
            chequing: state.chequing,
            savings: state.savings,
            auth: state.auth,
            goals: state.goals,
            jobs: state.jobs,
            purchases: state.purchases,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            {
                ...authActions,
                ...goalsActions,
                ...purchasesActions,
                ...chequingActions,
                ...jobsActions,
                ...savingsActions,
            },
            dispatch
        ),
    })
)(Dashboard);

const styles = StyleSheet.create({
    emailStyles: {
        // sfwef
    },
});

const emailStyles = StyleSheet.create({
    container: {
        elevation: 5,
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 20,
        backgroundColor: 'rgba(128,128,128,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContainer: {
        zIndex: 20,
        backgroundColor: 'rgba(255,255,255,0.9)',
        paddingHorizontal: 20,
        paddingVertical: 10,
        width: 300,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        marginTop: 10,
        paddingTop: 10,
        borderTopWidth: 1,
        textAlign: 'center',
    },
    download: {
        marginTop: 10,
        backgroundColor: Colors.green1,
        borderRadius: 20,
        padding: 10,
    },
});
