// import { NUMERIC_KEYBOARD_TYPE } from './constants';

const CREATE_CHILD_PROFILE = 'Create Child Profile';
const FIRST_JOB = 'First Job For The Kid';
const ALLOWANCE = 'Allowance';
const EXPENSE = 'Expense';
const CHEQUING_ACCOUNT_SETUP = 'Chequing Account Setup';
const SAVINGS_ACCOUNT_SETUP = 'Savings Account Setup';

export const NEW_CHILD_SCREENS_ARRAY = [
    CREATE_CHILD_PROFILE,
    FIRST_JOB,
    ALLOWANCE,
    EXPENSE,
    CHEQUING_ACCOUNT_SETUP,
    SAVINGS_ACCOUNT_SETUP,
];

export const NEW_CHILD_SCREENS = {
    CREATE_CHILD_PROFILE,
    FIRST_JOB,
    ALLOWANCE,
    EXPENSE,
    CHEQUING_ACCOUNT_SETUP,
    SAVINGS_ACCOUNT_SETUP,
};

const getNewChildScreenTitle = (name) => {
    return NEW_CHILD_SCREENS[name];
};

export const NEW_CHILD_SCREENS_ORDER = NEW_CHILD_SCREENS_ARRAY.reduce(
    (prev, currItem, currIndex) => ({ ...prev, [currItem]: currIndex }),
    {}
);

export const NEW_CHILD_SCREENS_LENGTH = NEW_CHILD_SCREENS_ARRAY.length;

// DATA FOR CHILD ACCOUNT
export const DEFAULT_ALLOWANCE = {
    amount: 5,
    title: 'Default Allowance',
    frequency: 'Weekly',
    day: 'Friday',
};
export const DEFAULT_CHEQUING = {
    min_balance: 10,
    monthly_fee: 1,
    transact_limit: 5,
    transact_fee: 0.5,
    advanced: true,
};
export const DEFAULT_SAVINGS = {
    int_rate: 0.12,
    withdraw_limit: 20,
    withdraw_fee: 1,
    advanced: true,
};
export const DEFAULT_EXPENSE = {
    amount: 5,
    title: 'Default Expense',
    frequency: 'Weekly',
    day: 'Friday',
    regular: false,
};
export const DEFAULT_EXPENSES = [];
