import React from 'react';
import { TouchableOpacity, Text } from 'react-native';

export const devFunc = (callback) => {
    if (__DEV__) {
        callback();
    }
};

export const DevComponent = ({ children }) => {
    if (__DEV__) {
        return children;
    }

    return null;
};

export const TODORemove = DevComponent;

export const TODORemoveButton = ({ text, onPress }) => {
    if (__DEV__) {
        return (
            <TouchableOpacity onPress={onPress}>
                <Text>{text || 'Test something'}</Text>
            </TouchableOpacity>
        );
    }

    return null;
};

export function copyFbRecord(oldRef, newRef) {
    oldRef
    .once('value')
    .then((snap) => {
        return newRef.set(snap.val());
    })
    .then(() => {
        console.log('Done!');
        // resolve();
    })
    .catch((err) => {
        console.log(err.message);
        // reject();
    });

    return;
    return new Promise((resolve, reject) => {
        oldRef
            .once('value')
            .then((snap) => {
                return newRef.set(snap.val());
            })
            .then(() => {
                console.log('Done!');
                resolve();
            })
            .catch((err) => {
                console.log(err.message);
                reject();
            });
    });
}
