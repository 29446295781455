
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { ActivityIndicator,StyleSheet, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import ParentSettings from '../../../components/settings/ParentSettings'

import Colors from '../../../constants/Colors';
import { expenseInfo, chequingInfo } from '../../../constants/info';

class ChequingScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const childIds = this.props?.parent?.childIds;

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }

    }



    render() {
        const {navigation} = this.props;
        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    return <View style={{ height: '100%', flex: 1 }}>

                                {this.state.loading ? <View style={{
                                    position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                                    justifyContent: 'center',
                                    height: '100%', width: '100%'
                                }}>
                                    <ActivityIndicator color={Colors.green1} size={'large'} />
                                </View> : null}

                                <BlockWrapper onClosePress={() => navigation.navigate('Settings')} navigation={navigation} description={<Text></Text>} title={'Security'}>
                                    {props => <ParentSettings {...props} actions={this.props.actions} navigation={navigation} auth={this.props.auth} parent={this.props.parent} />}
                                </BlockWrapper>

                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(ChequingScreen);


