import React, { useRef } from 'react';
import { Animated, StyleSheet, View, Text, Platform, TouchableOpacity, Image } from 'react-native';
// import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { withNavigation } from 'react-navigation';

import Colors from '../../constants/Colors';
import { SCREEN_WIDTH } from '../../constants/constants';
import globalStyle, { defaultStyles } from '../../constants/style';

const AcademyHeaderComponent = (props) => {
    const { navigation } = props;

    const onAcademyButtonPress = () => {
        navigation.goBack();
    }

    // TODO remake with gradients
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%',
         borderWidth: 1, borderColor: 'lightgray', marginBottom: 20,}}>
            <TouchableOpacity
            // activeOpacity={0.85}
            onPress={onAcademyButtonPress}
            style={styles.container}>
                <Text style={{...defaultStyles.bold, marginLeft: 20,}}>Back</Text>
            </TouchableOpacity>
            <View style={{flex: 1, alignItems: 'center', flexDirection: 'row',justifyContent: 'center',}}>
                <Image
                    resizeMode={'contain'}
                    style={{width: 120, height: 50}}
                    source={require('assets/stem-main-logo.png')}
                />
            </View>
        </View>
    );
}

export const AcademyHeader = withNavigation(AcademyHeaderComponent);

const styles = StyleSheet.create({
    container: {
    },
    text: {
        color: Colors.white,
        ...defaultStyles.bold,
        ...globalStyle.h2,
        zIndex: 1,
    },
    gradientWrapper: { position: 'absolute', height: '100%', width: '120%' },
});

