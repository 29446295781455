/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper';

import ChildSwitch from '../../../components/settings/ChildSwitch';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import RegularSettings from '../../../components/settings/RegularSettings';

class ChequingScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            loading: false,
        };
    }

    componentDidUpdate() {
        console.log('NEW PROPS: ', this.props.navigation.state.params);
    }

    render() {
        const { navigation, parent, actions } = this.props;
        const { childId } = this.props.navigation.state.params;
        const { id, childIds, names } = parent;
        const name = names[childIds[id]];
        const title = 'Allowance';

        return (
            <BlockWrapper
                navigation={navigation}
                description={null}
                title={title}
            >
                {(layout) => (
                    <>
                        {/* <ChildSwitch
                            name={name}
                            changeIndex={actions.changeChildIndex}
                        /> */}
                        <RegularSettings
                            layout={layout}
                            navigation={navigation}
                            settings={{}}
                            auth={this.props.auth}
                            parent={parent}
                            id={id}
                            childId={childId}
                            actions={this.props.actions}
                        />
                    </>
                )}
            </BlockWrapper>
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...kidAuthActions },
            dispatch
        ),
    })
)(ChequingScreen);
