/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import config from '../../constants/config';
import { createExpence, sendAllowanceJob } from '../jobs/jobRequests';
import { createJob } from '../../requests/jobs/parentJobRequests';

const auth = firebase.auth;

export const getStoreItemComments = async ({ itemId, parentId, childId }) => {
    const body = {
        itemId, parentId, childId,
    };

    // Send request to create new child
    let resp = await fetch(config.url + 'getStoreItemComments', {
        method: 'POST',
        body: JSON.stringify(body),
    });

    console.log('resp1 ', resp);

    resp = await resp.json();
    const items = resp.items;

    console.log('resp2 ', resp);

    if (items.some((item) => item.parentId)) {
        return { items, commented: true };
    }

    return { items, commented: false };
};

export const postStoreItemComment = async ({ itemId, parentId, childId, name, comment, rating }) => {
    const body = {
        itemId,
        parentId,
        childId,
        name,
        comment,
        rating,
    };

    // Send request to create new child
    try {
        let resp = await fetch(config.url + 'postStoreItemComment', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        console.log('resp1 ', resp);

        // resp = await resp.json();

        return resp;
    } catch (err) {
        console.log('ERROR - ', err);
        return false;
    }

};
