import config from "../../constants/config";

export async function schedulePhishingEmail(email) {
    const body = { email };

    return fetch(config.url + 'schedulePhishingEmail', {
        method: 'POST',
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body)
    })
        // .then(response => {console.log('response',response); return response.json()})
        .then(async response => {
            console.log('response', response);
            if (response.status !== 200) {
                throw await response.json();
            }
            return response.json()
        })
}

export async function sendPhishingEmail(email) {
    const body = { email };

    return fetch(config.url + 'sendPhishingEmail', {
        method: 'POST',
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body)
    })
        // .then(response => {console.log('response',response); return response.json()})
        .then(async response => {
            console.log('response', response);
            if (response.status !== 200) {
                throw await response.json();
            }
            return response.json()
        })
}

export async function createAcademyAccount(email) {
    const body = { email };

    return fetch(config.url + 'createStudentAccount', {
        method: 'POST',
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body)
    })
        // .then(response => {console.log('response',response); return response.json()})
        .then(async response => {
            console.log('response', response);
            if (response.status !== 200) {
                throw await response.json();
            }
            return response.json()
        })
}