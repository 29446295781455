import React, { useRef } from 'react';
import { Animated, StyleSheet, View, Text, Platform, TouchableOpacity } from 'react-native';
// import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { withNavigation } from 'react-navigation';
import LinearGradient from 'react-native-linear-gradient';

import { ROUTE_NAMES } from '../../navigation/navigationConstants';
import Colors from '../../constants/Colors';
import { SCREEN_WIDTH } from '../../constants/constants';
import globalStyle, { defaultStyles } from '../../constants/style';
import { SHOW_ACADEMY } from '../../constants/config';

const AcademyButton = (props) => {
    const { navigation } = props;
    const left = useRef(new Animated.Value(0)).current;

    const animatedStyle = {
        transform: [
            { translateX: left.interpolate({
                inputRange: [0, 1],
                outputRange: [0, Math.min(SCREEN_WIDTH/12, 60)]
            }) }
        ],
        // left:left.interpolate({
        //              inputRange: [0, 1],
        //            outputRange: ['0%', '10%']
        //          })
    }

    const onPressIn = () => {
        Animated.timing(left, {
            duration: 500,
            toValue: 1,
            useNativeDriver: true
        }).start()
    };

    const onPressOut = () => {
        Animated.timing(left, {
            duration: 500,
            toValue: 0,
            useNativeDriver: true
        }).start()
    };

    const onAcademyButtonPress = () => {
        navigation.navigate(ROUTE_NAMES.ACADEMY);
    }

    if (!SHOW_ACADEMY) {
        return null;
    }

    // TODO remake with gradients
    return (
        <View style={{
            flexDirection: 'row',
            width: '90%',
            marginVertical: 10,
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 700, }}>
        <TouchableOpacity
          activeOpacity={0.85}
          onPress={onAcademyButtonPress}
          onPressIn={onPressIn}
          onPressOut={onPressOut}
          style={styles.container}>
            <Animated.View style={[styles.gradientWrapper, animatedStyle]}>
                <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    locations={[0.2, 0.5, 0.8]}
                    useAngle={true}
                    angle={45}
                    style={StyleSheet.absoluteFill}
                    colors={[Colors.green1, Colors.green2, `${Colors.green}55`]}
                >
                </LinearGradient>
            </Animated.View>
            {/* <View> */}
                <Text style={styles.text}>CYBERSECURITY ACADEMY</Text>
            {/* </View> */}
        </TouchableOpacity>
        {/* <TouchableOpacity>
            <Text>popup</Text>
        </TouchableOpacity> */}
        </View>
    );
}

export const AcademyMainButton = withNavigation(AcademyButton);

const styles = StyleSheet.create({
    container: {
        // height: 60,
        paddingVertical: 5,
        width: '100%',
        marginVertical: 10,
        maxWidth: 700,
        // borderWidth: 1,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        ...defaultStyles.boxShadow,
    },
    text: {
        color: Colors.white,
        ...defaultStyles.bold,
        ...globalStyle.h3,
        zIndex: 1,
    },
    gradientWrapper: { position: 'absolute', height: '100%', width: '120%' },
});

