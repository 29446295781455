import React, {
useState,
useImperativeHandle,
useCallback,
useRef,
} from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import ModalContainer from 'react-native-modal';

const { width, height } = Dimensions.get('screen');

// constants
const ANIMATION_DURATION_IN_MS = 500;
export const MagicModalHideTypes = {
    BACKDROP_PRESSED: 'BACKDROP_PRESSED',
    SWIPE_COMPLETED: 'SWIPE_COMPLETED',
    BACK_BUTTON_PRESSED: 'BACK_BUTTON_PRESSED',
    MODAL_OVERRIDE: 'MODAL_OVERRIDE',
}

export const customModalRef = React.createRef();

export const CustomModal = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [config, setConfig] = useState({});
    const [modalContent, setModalContent] = useState(() => <></>);

    const onHideRef = useRef(() => {});

    const show = async (newComponent,newConfig = {}) => {
        if (isVisible) await hide(MagicModalHideTypes.MODAL_OVERRIDE);

        setModalContent(newComponent);
        setConfig(newConfig);
        setIsVisible(true);

        return new Promise((resolve) => {
            onHideRef.current = resolve;
        });
    }

    const hide = useCallback(async (props = () => null) => {
        setIsVisible(false);

        const timeoutDuration =
            config?.animationOutTiming ?? ANIMATION_DURATION_IN_MS;

        await new Promise((resolve) => setTimeout(resolve, timeoutDuration));
        onHideRef.current(props);
    }, [config?.animationOutTiming]
    );

    useImperativeHandle(customModalRef, () => ({
        show,
        hide,
    }));

    return (
        <ModalContainer
        ref={customModalRef}
        isVisible={isVisible}
        backdropTransitionOutTiming={0}
        avoidKeyboard
        swipeDirection="down"
        deviceHeight={height}
        deviceWidth={width}
        animationOutTiming={ANIMATION_DURATION_IN_MS}
        statusBarTranslucent
        onBackdropPress={() => hide(MagicModalHideTypes.BACKDROP_PRESSED)}
        onSwipeComplete={() => hide(MagicModalHideTypes.SWIPE_COMPLETED)}
        onBackButtonPress={() => hide(MagicModalHideTypes.BACK_BUTTON_PRESSED)}
        {...config}
        style={[styles.container, config?.style]}
        >
        {modalContent}
        </ModalContainer>
    );
};

const styles = StyleSheet.create({
    container: {flex: 1, margin: 0,}
});