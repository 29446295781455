/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper';
import { Button } from '../../../components/newChild/Buttons';

import * as authActions from '../../../store/actions/authActions';
import * as parentActions from '../../../store/actions/parents/authActions';

import * as kidAuthActions from '../../../store/actions/kids/authActions';
import { createNewChild } from '../../../helpers/newChild/newChildRequests';

import {
    DEFAULT_ALLOWANCE,
    DEFAULT_CHEQUING,
    DEFAULT_SAVINGS,
    DEFAULT_EXPENSE,
    DEFAULT_EXPENSES,
} from '../../../constants/data';

const title = 'Proceed with process';
const description = `On next screens you will have an option to set spectial options for child account, like regular allowance, expenses, acount limits and more.
 These steps will take approximately 2 minutes. You can skip this process and fill these values later in settings.`;

class Chequing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            advanced: true,
        };
    }

    proceed() {
        this.props.proceed();
    }

    back() {
        const { navigation, actions } = this.props;
        const { parentId } = this.props.parent;
        const { profile, firstJob } = this.props.parent.newKid;
        // this.props.back();
        const onChildCreate = () => {
            // TODO ADD MODALS
            navigation.navigate('Walkthrough');
        };

        const clearChildData = () => {
            actions.clearKidData();
        };

        createNewChild({
            parentId,
            profile,
            firstJob,
            allowance: DEFAULT_ALLOWANCE,
            chequing: DEFAULT_CHEQUING,
            savings: DEFAULT_SAVINGS,
            expense: DEFAULT_EXPENSE,
            expenses: DEFAULT_EXPENSES,
            onChildCreate,
            clearChildData,
        });
    }

    render() {
        return (
            <BlockWrapper
                description={description}
                title={title}
                showProgress={false}
            >
                {/* <View> */}
                {() => (
                    <>
                        <View style={styles.container}>
                            <View style={styles.contentContainer} />
                        </View>
                        <View style={styles.buttonContainer}>
                            {/* TODO with border */}
                            <Button
                                title={'Skip'}
                                onPress={() => this.back()}
                            />
                            <Button
                                title={'Proceed'}
                                onPress={() => this.proceed()}
                            />
                        </View>
                    </>
                )}
                {/* </View> */}
            </BlockWrapper>
        );
    }
}

class ChequingScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false,
        };
    }

    render() {
        return (
            <Chequing
                auth={this.props.auth}
                parent={this.props.parent}
                actions={this.props.actions}
                navigation={this.props.navigation}
                kidData={this.props.actions.kidData}
                back={() => this.props.navigation.navigate('FirstJob')}
                proceed={() => this.props.navigation.navigate('Allowance')}
                startLoading={() => this.setState({ loading: true })}
                stopLoading={() => this.setState({ loading: false })}
            />
        );
    }
}

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        padding: 10,
        borderRadius: 10,
        maxWidth: 400,
        width: '100%',
    },
    contentContainer: {
        zIndex: 1,
        width: '100%',
        alignSelf: 'center',
        alignItems: 'center',
    },
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    loading: {
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'rgba(126, 193, 61, 0.5)',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    confirmationContainer: {
        borderRadius: 15,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 250,
        justifyContent: 'center',
    },
    buttonContainer: {
        flex: 1,
        width: '50%',
        zIndex: -1,
        justifyContent: 'center',
        flexDirection: 'row',
    },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...parentActions, ...kidAuthActions },
            dispatch
        ),
    })
)(ChequingScreen);
