/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
	ActivityIndicator,
	Animated,
	Dimensions,
	Image,
	ImageBackground,
	FlatList,
	ScrollView,
	StyleSheet,
	TextInput,
	//   Text as DefaultText,
	View,
	Platform,
	TouchableOpacity,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { useHover, Resizable, Hoverable } from 'react-native-web-hooks';

import Text from '../../components/CustomText'

import TouchableNativeFeedback from '../../components/TouchableFeedback';

import * as storeActions from '../../store/actions/kids/storeActions'
import * as authActions from '../../store/actions/kids/authActions'
import * as goalsActions from '../../store/actions/kids/goalsActions'
import { RejectionView, ConfirmationView } from '../../components/confirmation';

import { ChildStoreCard as StoreCard, } from '../../components/store/StoreCard';
import StoreHeader from '../../components/store/StoreHeader';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';
import { DropdownElement } from '../../components/Dropdown/Dropdown';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const side_bar_items = [
	{ title: 'All', image: require('assets/Store/Store_1.png') },
	{ title: 'Beauty', image: require('assets/Store/beauty.png') },
	{ title: 'Sports', image: require('assets/Store/sport.png') },
	{ title: 'Arts', image: require('assets/Store/arts.png') },
	{ title: 'Toys', image: require('assets/Store/toys.png') },
	{ title: 'Activities', image: require('assets/Store/activities.png') },
	{ title: 'Food', image: require('assets/Store/food.png') },
	{ title: 'Other', image: require('assets/Jobs/Other.png') },
	// { title: 'Electronics', image: require('assets/Store/electronics.png') },
	// { title: 'Donations', image: require('assets/Store/donations.png') },
	// { title: 'Accessories', image: require('assets/Store/accessories.png') },
]

const RenderCategory = ({ item, index, isFocused, onPress }) => {
	console.log('isFocused ', item.title, isFocused)
	return <TouchableNativeFeedback key={index} onPress={() => onPress()}>
		<View style={[{
			margin: 10, justifyContent: 'center', alignItems: 'center',
			backgroundColor: isFocused ? Colors.green1 : 'white', height: 105, width: 95,
			borderRadius: 20, borderWidth: 3, borderColor: isFocused ? Colors.green1 : Colors.gray,
		}, isFocused && defaultStyles.greenShadow]}>
			<Text style={{ color: isFocused ? 'white' : Colors.black, }}>{item.title}</Text>
		</View>
	</TouchableNativeFeedback>
}

const ButtonContainer = React.forwardRef(
	({ title, image, onPress, isFocused }, ref) => {
		const vmin = 1000;
		const [animation, setAnimation] = useState(new Animated.Value(0));

		const { isHovered } = useHover(ref);
		// console.log(ref)
		useEffect(() => {
			Animated.timing(animation, {
				toValue: isHovered ? 1 : 0,
				duration: 200,
			}).start();
		});

		let scale = animation.interpolate({
			inputRange: [0, 1],
			outputRange: [1, 1.1]
		})

		return <Resizable>
			{layout => {
				const width = layout.width;
				return <TouchableNativeFeedback onPress={onPress}>
					<View style={{ marginHorizontal: 10, borderWidth: 1, justifyContent: 'center', alignItems: 'center' }}>
						<Animated.View
							ref={ref}
							style={{
								backgroundColor: 'white', borderRadius: 80, width: width > 600 ? 80 : width * 0.15,
								height: width > 600 ? 80 : width * 0.15, marginTop: 40,
								// boxShadow: '2px 2px 15px #272827',
								borderWidth: isFocused ? 3 : 1, borderColor: Colors.gray,
								opacity: isFocused ? 1 : 0.7, alignSelf: 'center',
								transform: [{ scale: scale }], marginTop: 0.02 * vmin
							}}>
							<Image resizeMode={'contain'} style={{ zIndex: 4, borderRadius: 80, width: '100%', height: '100%' }}
								source={image} />
						</Animated.View>
						<View style={{ alignSelf: 'center', width: '100%', }}>
							<Text style={{ marginTop: 20, alignSelf: 'center', textAlign: 'center', }}>{title}</Text>
						</View>
					</View>
				</TouchableNativeFeedback>
			}}
		</Resizable>
	})

const ButtonContainerMobile = ({ title, image, onPress, isFocused, width }) => {
	const vmin = 1000;
	const [animation, setAnimation] = useState(new Animated.Value(0));

	let scale = animation.interpolate({
		inputRange: [0, 1],
		outputRange: [1, 1.1]
	})


	return <TouchableNativeFeedback onPress={onPress}>
		<View style={{ alignItems: 'center', justifyContent: 'center', width: width * 0.3 }}>
			<Animated.View
				style={{
					elevation: 5,
					backgroundColor: 'white', borderRadius: 80, width: 80,
					height: 80, marginTop: 40, borderWidth: 3, backgroundColor: 'white',
					justifyContent: 'center', alignItems: 'center', borderColor: isFocused ? Colors.green1 : Colors.gray0,
					opacity: isFocused ? 1 : 0.7, transform: [{ scale: scale }], marginTop: 0.02 * vmin
				}}>
				<Image resizeMode={'contain'} style={{
					alignSelf: 'center',
					borderColor: Colors.green1, zIndex: 4, borderRadius: 180, width: '100%', height: '100%'
				}}
					source={image} />
			</Animated.View>
			<Text style={{ marginTop: 20, alignSelf: 'center', textAlign: 'center' }}>{title}</Text>
		</View>
	</TouchableNativeFeedback>

}


class Dashboard extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			headerTransparent: true,
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			type: 'new',
			order: 'Order',
			avatar: null,
			modalVisible: false,
			settingsMobileVisible: false,
			sortButtons: [false, true, false],
			// categories:[false,false,false,false,false,false,false,false,false,false,],
			categories: { 'All': true },
			tempCategories: {},
			scrolledHeader: new Animated.Value(0),
			scrolledPadding: new Animated.Value(0),
			sortMobile: 0,
			loading: false,
			quantity: 1,
		};
	};



	async componentDidMount() {
		const { parentId, childId } = this.props.auth;

		this.props.actions.clearStoreData();

		this.props.actions.getStoreItems({ parentId, childId, categories: this.state.categories, sort: this.state.ordrer === 'Order' ? null : this.state.order })
			.then(res => this.setState({ data: res }))

		if (!this.props.auth.childId) {
			this.props.actions.clearAuth()
				.then(() => { this.props.navigation.navigate('Auth') })
		}
		// this.focusListener = this.props.navigation.addListener('didFocus', () => {
		//   this.props.actions.login(email, password).then(async ()=>{await this.getData()});
		//   console.log()


		// });

	}

	componentWillUnmount() {
	}


	parseInt(value) {
		return value !== '' ? parseInt(value) : 0
	}


	defineValue(width, height, a, b, c) {
		if (width < 850 && width < height) {
			return a
		}
		else if (width < 850 && width > height) {
			return b
		}
		else {
			return c
		}
	}

	logout = async () => {
		// this.props.actions.logout();
		AsyncStorage.clear();
		// this.props.navigation.navigate('Login')
	}

	renderCard({ item }, layout) {
		// const title = item.title;
		console.log('item.price!!! ', item)
		// console.log('LAYOUT ', layout)
		return item && <StoreCard item={item} layout={layout} auth={this.props.auth} navigation={this.props.navigation}
			onGoal={() => { this.setState({ modalVisible: true, confirmationGoal: true, title: item.title, price: parseFloat(item.price), itemUrl: item.link }) }}
			onBuy={() => { this.setState({ item: item, modalVisible: true, confirmationPurchase: true, title: item.title, price: item.price, itemUrl: item.link }) }} />
	}

	confirmationGoal() {
		const { parentId, childId } = this.props.auth;
		console.log('this.state.price,this.state.title')
		console.log(this.state.price, this.state.title)

		return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('assets/Goals/Goal_2.png')} />
			<View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Add</Text>
				<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c', }}>{this.state.title}</Text>
			</View>
			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => {
						this.setState({
							modalVisible: false, confirmationGoal: false,
							confirmationPurchase: false,
						});
						this.props.actions.addGoal(this.state.price.toString(), this.state.title, parentId, childId, this.state.itemUrl, 'savings online purchase')
					}}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={{ fontSize: 19, marginVertical: 5, color: 'white' }}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({
						modalVisible: false, confirmationGoal: false,
						confirmationPurchase: false,
					})}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={{ fontSize: 19, marginVertical: 5, color: 'white' }}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}

	confirmationTitle(conditions) {
		// const {chequing} = this.props;
		// const chequing = conditions.chequing;
		const chequing = conditions.chequing;
		// const conditions = this.state.conditions;
		const fees = conditions.transact_fee + conditions.monthly_fee

		if (conditions.available === false) {
			return <React.Fragment>
				<Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Can’t spend ${this.state.price.replace('$', '')} {fees > 0 ? 'plus $' + fees + ' fees ' : ''}due to insufficient funds.</Text>
				<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>Your chequing balance is only ${chequing.chequing_balance.toFixed(2)}</Text>
			</React.Fragment>
		}
		else if (conditions.monthly_fee && !conditions.transaction_fee) {
			return <React.Fragment>
				<Text style={{ fontSize: 16, margin: 5, color: '#5aca3c' }}>Chequing balance will be below the minimum balance after this purchase. Your account will be charged a monthly fee of ${conditions.monthly_fee}</Text>
				<Text style={{ fontSize: 16, margin: 5, color: '#5aca3c' }}>You can spend up to ${(chequing.chequing_balance - chequing.min_balance).toFixed(2)} to stay above minimum balance. Still transfer?</Text>
			</React.Fragment>
		}
		else if (!conditions.monthly_fee && conditions.transaction_fee) {
			return <React.Fragment>
				<Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>You are exceeding the maximum number of free transactions.</Text>
				<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}> This purchase will cost you ${conditions.transaction_fee}. Still continue?</Text>
			</React.Fragment>
		}
		else if (conditions.monthly_fee && conditions.transaction_fee) {
			return <React.Fragment>
				<Text style={{ fontSize: 15, margin: 5, color: '#5aca3c' }}>Chequing balance will be below the minimum balance after this purchase. Your account will be charged a monthly fee of ${conditions.monthly_fee}</Text>
				<Text style={{ fontSize: 15, margin: 5, color: '#5aca3c' }}>You can spend up to ${(chequing.chequing_balance - chequing.min_balance).toFixed(2)} to stay above minimum balance. Still transfer?</Text>
				<Text style={{ fontSize: 15, margin: 5, color: '#5aca3c' }}>Also, you are exceeding the maximum number of free transactions.</Text>
				<Text style={{ fontSize: 15, margin: 5, color: '#5aca3c' }}>This purchase will cost you ${conditions.transaction_fee}. Still continue?</Text>
				{/* <Text style={{fontSize:15, margin:5, color:'#5aca3c'}}>Transfering two fees</Text> */}
				{/* <Text style={{fontSize:20, margin:5, color:'#5aca3c'}}>${this.state.transfer.replace('$','')} to Savings</Text> */}
			</React.Fragment>
		} else {
			return <React.Fragment>
				<Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Purchase</Text>
				{/* <Text style={{fontSize:20, margin:5, color:'#5aca3c'}}>${this.state.price.replace('$','')} to Savings</Text> */}
			</React.Fragment>
		}

	}

	confirmationPurchase() {
		const { parentId, childId } = this.props.auth;
		// const link = ''

		const goal = { name: this.state.title, price: this.state.price, link: this.state.itemUrl }
		return this.state.buyError ?
			this.state.result === 'fail' ? <RejectionView title={'Not enough money to buy item'} subtitles={[]}
				okPress={() => { this.setState({ quantity: 1, buyError: false, modalVisible: false, confirmationGoal: false, confirmationPurchase: false, isLoading: true }) }} />
				: <ConfirmationView image={require('assets/Store/gem.png')} title={this.confirmationTitle(this.state.errorInfo)}
					subtitles={[]}
					confirmPress={() => {
						this.props.actions.increaseStoreCounter(parentId, childId, this.state.item);
						this.props.actions.finishPurchaseItem(goal, parentId, childId, this.state.errorInfo.transaction_fee, this.state.errorInfo.monthly_fee, this.parseInt(this.state.quantity)).then(() => {
							this.props.actions.sendRequestPurchaseEmail(parentId, childId, goal.name);
						});
						this.setState({ quantity: 1, buyError: false, modalVisible: false, confirmationGoal: false, confirmationPurchase: false, })
					}}
					rejectPress={() => { this.setState({ quantity: 1, buyError: false, modalVisible: false, confirmationGoal: false, confirmationPurchase: false, }) }}
				/>
			: <View style={{ justifyContent: 'center', alignItems: 'center' }}>
				<Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('assets/Goals/Goal_2.png')} />
				<View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Buy</Text>
					<Text style={{ fontSize: 20, margin: 5, color: Colors.gray0, textAlign: 'center' }}>{this.state.title}</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 20, alignItems: 'center', justifyContent: 'center', }}>
					<View style={{ marginRight: 10, }}><Text style={{ textAlign: 'left' }}>Quantity:</Text></View>
					<View style={{ flexDirection: 'row', alignItems: 'center', }}>
						<TouchableNativeFeedback onPress={() => { this.state.quantity > 1 ? this.setState({ quantity: this.state.quantity - 1 }) : null }}>
							<View style={{ backgroundColor: Colors.rose, height: 30, width: 30, borderRadius: 15, justifyContent: 'center', alignItems: 'center' }}><Text style={{ color: 'white' }}>-</Text></View>
						</TouchableNativeFeedback>
						<View style={{ marginHorizontal: 5, width: 25, alignItems: 'center' }}><Text style={{ textAlign: 'center' }}>{this.state.quantity}</Text></View>
						<TouchableNativeFeedback onPress={() => this.setState({ quantity: this.state.quantity + 1 })}>
							<View style={{ backgroundColor: Colors.green1, height: 30, width: 30, borderRadius: 15, justifyContent: 'center', alignItems: 'center' }}><Text style={{ color: 'white' }}>+</Text></View>
						</TouchableNativeFeedback>
					</View>
				</View>
				<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
					<View style={{ width: '40%', }}>
						<TouchableNativeFeedback onPress={() => {
							this.setState({ loading: true });
							this.props.actions.purchaseItem(goal, parentId, childId, parseInt(this.state.quantity)).then((resp) => {
								if (resp.result === 'success') {
									this.props.actions.increaseStoreCounter(parentId, childId, this.state.item);
									this.props.actions.sendRequestPurchaseEmail(parentId, childId, goal.name);
									this.setState({ quantity: 1, modalVisible: false, loading: false, confirmationGoal: false, confirmationPurchase: false, isLoading: true });
									this.props.actions.getGoals(parentId, childId);
								}
								else {
									console.log('FAIL')
									this.setState({ buyError: true, loading: false, result: resp.result, errorInfo: resp.info });
								}
							})
						}}>
							<View style={{
								justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
								margin: 5, backgroundColor: 'rgba(126,193,61,1)'
							}}>
								<Text style={{ fontSize: 19, marginVertical: 5, color: 'white' }}>YES</Text></View>
						</TouchableNativeFeedback>
					</View>
					<View style={{ width: '40%', }}>
						<TouchableNativeFeedback onPress={() => this.setState({ quantity: 1, modalVisible: false, confirmationGoal: false, confirmationPurchase: false, })}>
							<View style={{
								justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
								backgroundColor: '#EE5060'
							}}>
								<Text style={{ fontSize: 19, marginVertical: 5, color: 'white' }}>NO</Text></View>
						</TouchableNativeFeedback>
					</View>
				</View>
			</View>
	}

	settingsMobile(width) {
		const { childId, parentId } = this.props.auth;
		// const { parentId } = this.props.child;

		const order = [{ title: 'Price\nHigh to\nLow', type: 'priceHigh' }, { title: 'Price\nLow to\nHigh', type: 'priceLow' }]
		return <ScrollView style={{ flex: 1 }}>
			<TouchableNativeFeedback onPress={() => {
				let tmp = this.state.categories;
				this.setState({ settingsMobileVisible: false, tempCategories: Object.assign({}, this.state.categories) },
					() => { console.log('temp categories', this.state.tempCategories === this.state.categories) })
			}}>
				<View style={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
					<Text style={{ fontSize: 45, margin: 15, }}>X</Text>
				</View>
			</TouchableNativeFeedback>

			<View style={{ marginLeft: 20 }}>
				<Text>Order</Text>
			</View>
			<View style={{
				width: width * 0.9, alignSelf: "center", flexDirection: 'row',
				marginVertical: 15,
			}}>
				{order.map((item, index) => {
					return <View key={index} style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: width * 0.3, }}>
						<TouchableNativeFeedback onPress={() => { this.setState({ sortButtons: order.map((_, localIndex) => index === localIndex), type: order[index].type }) }}>
							<View style={{
								backgroundColor: 'white', height: 80, width: 80, borderRadius: 80,
								justifyContent: 'center', alignItems: 'center', borderWidth: 3,
								borderColor: item ? Colors.green1 : Colors.gray0
							}}>
								<Text style={{ alignItems: 'center', justifyContent: 'center', textAlign: "center" }}>{order[index].title}</Text>
							</View>
						</TouchableNativeFeedback>
					</View>
				})}
			</View>
			{/* boxShadow: '2px 2px 15px #272827',  */}
			<View style={{ marginLeft: 20 }}>
				<Text>Category</Text>
			</View>
			<View style={{ flexDirection: 'row', flex: 1, justifyContent: 'center', flexWrap: 'wrap', }}>
				{side_bar_items.map((item, index) => {
					return <ButtonContainerMobile width={width}
						onPress={() => {
							let newCategories = this.state.tempCategories //copy the array
							if (newCategories[item.title] === true) {
								delete newCategories[item.title]
							} //execute the manipulations
							else {
								newCategories[item.title] = true
							}
							this.setState({ tempCategories: newCategories })
						}}
						isFocused={this.state.tempCategories[item.title]}
						key={item.title} image={item.image} title={item.title} />
				})}
			</View>
			<TouchableNativeFeedback onPress={() => {
				let tmp = this.state.tempCategories;
				this.setState({
					settingsMobileVisible: false,
					categories: Object.assign({}, this.state.tempCategories),
				}, () => { this.props.actions.getStoreItems({ categories: this.state.categories, parentId, childId, sort: this.state.ordrer === 'Order' ? null : this.state.order }).then(data => this.setState({ data })) })
			}}>
				<View style={{ marginVertical: 10, alignSelf: 'center', borderRadius: 10, width: '90%', paddingVertical: 10, backgroundColor: Colors.green1 }}>
					<Text style={{ flex: 1, color: 'white', textAlign: 'center' }}>Apply</Text>
				</View>
			</TouchableNativeFeedback>
		</ScrollView>
	}


	listEmptyComponent = (layout) => {
		const isLoading = this.props.store.isLoading;
		const vmin = Math.min(...[layout.width, layout.height]);

		return isLoading ? null : <View elevation={5} style={{
			flex: 1, marginTop: vmin, marginBottom: 0.05 * vmin, paddingVertical: 0.05 * vmin,
			borderRadius: 15, alignSelf: 'center',
			backgroundColor: 'rgba(255,255,255,0.9)', width: '90%', maxWidth: 628,
			flexDirection: 'column',
			justifyContent: 'center', alignItems: 'center'
		}}>

			<View style={{}}>
				<Text numberOfLines={3} ellipsizeMode={'tail'} style={{
					color: '#272827', marginTop: 15,
					marginLeft: 0.02 * vmin, marginBottom: 0.02 * vmin,
				}}>No items</Text>
			</View>

			<TouchableNativeFeedback style={{ marginVertical: 50, }} onPress={() => { this.setState({ requestStoreItem: true }) }}>
					<View elevation={7} style={{
						backgroundColor: Colors.green1, borderRadius: 10, padding: 5,
						marginRight: 0.02 * vmin,
						marginLeft: 0.02 * vmin, marginTop: 0.02 * vmin,
					}}>
						<Text style={{ paddingVertical: 5, paddingHorizontal: 10, textAlign: 'center', color: 'white' }}>Request to add items</Text>
					</View>
				</TouchableNativeFeedback>
		</View>
	}

	returnData() {
		console.log('this.props.store.data ', this.props.store.data);
		if (this.state.sortButtons[1]) {
			return this.props.store.data ? Object.values(this.props.store.data).sort((a, b) => a.price > b.price ? -1 : 1) : []
		}
		if (this.state.sortButtons[2]) {
			return this.props.store.data.sort((a, b) => a.price > b.price ? 1 : -1)
		}
		return this.props.store.data
	}

	render() {
		const { navigation, actions } = this.props;
		const { parentId, childId } = this.props.auth;

		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					return <View style={{ backgroundColor: 'white', flex: 1, }}>

						<StoreHeader style={{ paddingTop: 0.03 * vmin, }} navigation={navigation} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth}>
						</StoreHeader>

						{<Modal
							animationIn={'zoomIn'} animationOut={'zoomOut'}
							animationInTiming={500} animationOutTiming={500}
							backdropColor={'darkgrey'}
							backdropOpacity={0.6}
							onBackdropPress={() => this.setState({
								buyError: false,
								quantity: 1,
								modalVisible: false, confirmationDelete: false, confirmationGoal: false,
								confirmationPurchase: false, confirmationActivate: false
							})}
							isVisible={this.state.modalVisible}
							onRequestClose={() => {
								this.setState({
									buyError: false,
									quantity: 1,
									modalVisible: false, confirmationDelete: false, confirmationGoal: false,
									confirmationPurchase: false, confirmationActivate: false
								})
							}}>
							<View style={{
								borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
								maxWidth: 400, paddingBottom: 10
							}}>
								{this.state.loading && <View style={{ position: 'absolute', zIndex: 1, justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'rgba(126, 193, 61, 0.1)', }}>
									<ActivityIndicator size={'large'} color={Colors.green1} />
								</View>}
								<View style={{ opacity: this.state.loading ? 0.5 : 1 }}>
									{this.state.confirmationGoal ? this.confirmationGoal() : null}
									{this.state.confirmationPurchase ? this.confirmationPurchase() : null}
								</View>
							</View>
						</Modal>}
						{<Modal
							animationIn={'zoomIn'} animationOut={'zoomOut'}
							animationInTiming={500} animationOutTiming={500}
							isVisible={this.state.settingsMobileVisible}
							onRequestClose={() => { this.setState({ settingsMobileVisible: false }) }}>
							<View style={{ flex: 1, margin: 0, backgroundColor: 'white' }}>
								{this.settingsMobile(layout.width)}
							</View>
						</Modal>}
						{<Modal
							animationIn={'zoomIn'} animationOut={'zoomOut'}
							animationInTiming={500} animationOutTiming={500}
							style={{ margin: 0 }}
							// backdropOpacity={0.6}
							onBackdropPress={() => this.setState({ requestStoreItem: false })}
							isVisible={this.state.requestStoreItem}
							onRequestClose={() => { this.setState({ requestStoreItem: false }) }}>
							<View style={{
								borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
								maxWidth: 400, paddingBottom: 10
							}}>
								<ConfirmationView image={require('assets/Store/gem.png')} title={'Request store items?'}
									subtitles={[]}
									confirmPress={() => {
										this.props.actions.requestStoreItems(parentId, childId)
											.then(() => { });
										this.setState({ requestStoreItem: false, })
									}}
									rejectPress={() => { this.setState({ requestStoreItem: false, }) }}
								/>
							</View>
						</Modal>}

						<ScrollView ref={ref => this._scroll = ref}
							{...Platform.OS === 'web' ? { stickyHeaderIndices: [0] } : {}}
							style={{ flex: 1, zIndex: 3 }}
						>

							{false ? <ScrollView
								style={{ zIndex: 10, }}
								contentContainerStyle={{ zIndex: 1, borderWidth: 1, justifyContent: 'space-around', flexDirection: 'row', marginBottom: 15 }} style={{
									zIndex: 2, opacity: 0.9,
									width: layout.width > 850 ? 250 : '100%', position: 'absolute',
									display: (layout.width > 850 || this.state.showFilters) ? 'flex' : ('none'),
									borderRightColor: 'white', marginTop: 0.2 * vmin, maxHeight: layout.height * 0.9,
									borderRightWidth: 2,
								}}>
								<View style={{ justifyContent: 'flex-start', flex: 1, flexDirection: 'row', flexWrap: 'wrap', }}>
									{side_bar_items.map((item, index) => {
										return <View key={item.title} style={{ flexDirection: 'column' }}>
											<ButtonContainer
												onPress={() => {
													let newCategories = this.state.categories
													if (item.title !== 'All') {
														delete newCategories['All']
													}
													if (newCategories[item.title] === true) {
														delete newCategories[item.title]
													} //execute the manipulations
													else {
														newCategories[item.title] = true
													}
													if (item.title === 'All') {
														newCategories = { 'All': true }
													}
													this.setState({ categories: newCategories },
														() => { this.props.actions.getStoreItems({ categories: this.state.categories, parentId, childId, sort: this.state.ordrer === 'Order' ? null : this.state.order }).then(data => this.setState({ data })) })
												}}
												isFocused={this.state.categories[item.title]}
												ref={this[`ref${index}`]} image={item.image} title={item.title} />
										</View>
									})}</View>
								{/* <View style={{ justifyContent: 'center', flexDirection: layout.width > 850 ? 'column' : 'row' }}>
									{side_bar_items.slice(5, 10).map((item, index) => {
										return <ButtonContainer
											onPress={() => {
												let newCategories = this.state.categories
												if (item.title !== 'All') {
													delete newCategories['All']
												}
												if (newCategories[item.title] === true) {
													delete newCategories[item.title]
												} //execute the manipulations
												else {
													newCategories[item.title] = true
												}
												this.setState({ categories: newCategories },
													() => { this.props.actions.getStoreItems(this.state.type, this.state.categories, parentId, childId) })
											}}
											isFocused={this.state.categories[item.title]}
											ref={this[`ref${index + 5}`]} key={item.title} image={item.image} title={item.title} />
									})}</View> */}
							</ScrollView> : null}



							{/* {<TouchableNativeFeedback style={{ zIndex: 2 }} onPress={() => this._scroll.scrollTo({ x: 0, y: 0, animated: true })}>
								<View style={{
									zIndex: 2,
									position: 'absolute', top: layout.height * 0.9, right: 10, width: 40, height: 40, borderRadius: 30, backgroundColor: Colors.green1,
									// boxShadow: '2px 2px 15px #272827',
									justifyContent: 'center', alignItems: 'center'
								}}>
									<Icon style={{ zIndex: 3, }} name="arrow-up" size={30} color="white" />
								</View>
							</TouchableNativeFeedback>} */}


							<View style={{ flex: 1, flexDirection: 'column', }}>

								<View>
									<View style={{ zIndex: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
										<Text style={[{ color: Colors.green1, marginRight: 10 }, defaultStyles.bold, globalStyles.h4]}>Category</Text>
										<Image style={{ width: 30, height: 30 }} source={require('assets/Store/Features-Items.png')} />
									</View>
									<FlatList
										contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
										style={{ paddingVertical: 0.05 * vmin }} horizontal={true} data={side_bar_items} extraData={this.state} renderItem={item =>
											<RenderCategory key={item.index} {...item} isFocused={this.state.categories[item.item.title]}
												onPress={() => {
													// let item = item?.item
													let title = item?.item?.title
													console.log('item ', title, this.state.categories);
													let newCategories = this.state.categories
													if (title !== 'All') {
														delete newCategories['All']
													}
													if (newCategories[title] === true) {
														delete newCategories[title]
													} //execute the manipulations
													else {
														newCategories[title] = true
													}
													this.setState({ categories: newCategories },
														() => { this.props.actions.getStoreItems({ categories: this.state.categories, parentId, childId, sort: this.state.ordrer === 'Order' ? null : this.state.order }).then(data => this.setState({ data })) })
												}} />} />
								</View>

								<View style={{
									flex: 1, marginTop: layout.width > 850 ? 0.05 * vmin : 0, alignItems: 'center',
									// marginLeft: layout.width > 850 ? 300 : 0
								}}>
									<View style={{ zIndex: 1, paddingBottom: 0.02 * vmin, width: '90%', maxWidth: 650, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
										<View style={{ flexDirection: 'row' }}>
											{/* <Dropdown title={'Filter'} /> */}
											{/* <DropdownElement
                                                        // disabled={!this.state.apiResults}
                                                        containerStyle={{width:'100%',zIndex: 100, height: 40, maxWidth: 130, }}
                                                        mainElementStyle={{
                                                            height: 40, marginTop: 0, backgroundColor: Colors.green1,
                                                            borderColor: Colors.green1, width: '100%', maxWidth: 130
                                                        }}
                                                        arrowStyle={{ borderTopColor: 'white' }}
														mainTextStyle={{ color: 'white',  }}
														dropdownStyle={{}}
														elementContainerStyle={{}}
                                                        // mainElementStyle, mainTextStyle, containerStyle, elementContainerStyle, arrowStyle, dropdownStyle
                                                        categories={[]}
                                                        defaultValue={'Filter'}
                                                        onPress={value => this.setState({ order: value })} /> */}
											<DropdownElement
												// disabled={!this.state.apiResults}
												containerStyle={{ width: 160, zIndex: 100, height: 40, }}
												mainElementStyle={{
													height: 40, marginTop: 0, backgroundColor: Colors.green1,
													borderColor: Colors.green1, width: '100%', maxWidth: 160
												}}
												arrowStyle={{ borderTopColor: 'white' }}
												mainTextStyle={{ color: 'white', }}
												dropdownStyle={{ top: 35, width: 160, }}
												elementContainerStyle={{ width: 160, }}
												elementTextStyle={{ marginHorizontal: 5 }}
												// mainElementStyle, mainTextStyle, containerStyle, elementContainerStyle, arrowStyle, dropdownStyle
												categories={['Price Low to High', 'Price High to Low']}
												defaultValue={this.state.order}
												onPress={value => {
													this.setState({ order: value, }); this.props.actions.getStoreItems({ parentId, childId, categories: this.state.categories, sort: value })
														.then(res => this.setState({ data: res }))
												}} />
											{/* <Dropdown title={'Order'} onPress={() => this.setState({ sortButtons: [false, true, false], type: 'priceHigh' },
												() => { this.props.actions.getStoreItems(this.state.type, this.state.categories, parentId, childId) })} /> */}
										</View>
										<Text style={{}}>
											{this.state.data.length || 0} {this.state.data.length === 1 ? 'item' : 'items'}
										</Text>
									</View>

									<FlatList
										style={{ flex: 1, width: '100%', marginTop: 10, paddingVertical: 0.05 * vmin }}
										// data={this.state.data}
										data={this.state.data}
										keyExtractor={(item, index) => index.toString()}
										renderItem={(item) => this.renderCard(item, layout)}
										ListEmptyComponent={() => this.listEmptyComponent(layout)}
									/>

								</View>
							</View>
						</ScrollView>
					</View>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({

});

// export default Dashboard;
export default connect(state => {
	// console.log(Object.keys(state.vacancies));
	return ({
		auth: state.auth,
		child: state.child,
		store: state.store,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...storeActions, ...authActions, ...goalsActions }, dispatch)
	})
)(Dashboard);
