import React, { useRef, useEffect, useState } from 'react';
import {
    ActivityIndicator,
    Animated,
    Image,
    FlatList,
    ScrollView,
    StyleSheet,
    View,
    Platform,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Icon from 'react-native-vector-icons/FontAwesome';

import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/database';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useHover, Resizable } from 'react-native-web-hooks';

import Text from '../components/CustomText';

import TouchableNativeFeedback from '../components/TouchableFeedback';
import Dropdown, { DropdownElement } from '../components/Dropdown';

import StoreChildren from '../components/StoreChildren';
import { AddChildren } from '../components/store/SelectChildren';
import FeaturedItems from '../components/store/FeaturedItems';
import { ParentStoreCard as StoreCard } from '../components/store/StoreCard';
import StoreHeader from '../components/store/StoreHeader';

import * as storeActions from '../store/actions/parents/storeActions';
import * as authActions from '../store/actions/parents/authActions';
import * as goalsActions from '../store/actions/parents/goalsActions';
import Colors from '../constants/Colors';
import globalStyles, { defaultStyles } from '../constants/style';
import ModalContainer from '../components/Modal/Modal';
import { COMPLETE_JOB } from '../store/actions/parents/actionTypes';
import { eventLog } from '../helpers/analytics/analytics';
import { Search } from '../components/store/Search';
import { ArrowUp } from '../components/store/ArrowUp';
import { IS_WEB } from '../constants/constants';
import { SearchWebFilter } from '../components/store/SearchWebFilter';
import { TextSkeleton } from '../components/skeleton/TextSkeleton';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const side_bar_items = [
    { title: 'All', image: require('assets/Store/accessories.png') },
    { title: 'Beauty', image: require('assets/Store/beauty.png') },
    { title: 'Sports', image: require('assets/Store/sport.png') },
    { title: 'Arts', image: require('assets/Store/arts.png') },
    { title: 'Accessories', image: require('assets/Store/accessories.png') },

    { title: 'Toys', image: require('assets/Store/toys.png') },
    { title: 'Electronics', image: require('assets/Store/electronics.png') },
    { title: 'Activities', image: require('assets/Store/activities.png') },
    { title: 'Food', image: require('assets/Store/food.png') },
    // { title: 'Donations', image: require('../assets/Store/donations.png') },
];

const sortCategories = {
    new: 'Most popular',
    priceLow: 'Price Low to High',
    priceHigh: 'Price High To Low',
};

const categories = [
    'Toys',
    'Beauty',
    'Electronics',
    'Sports',
    'Activities',
    'Arts & Crafts',
    'Food',
    'Accessories',
    'Donations',
    'Other',
];

// title, price, itemId,
function formatStoreData(item) {
    return {
        ...item,
        image: item.imageurl[0],
        title: item.productname[0],
        price: item.price[0]._,
        new: true,
        description: item.description[0].long[0],
    };
}

const ButtonContainerMobile = ({ title, image, onPress, isFocused, width }) => {
    return (
        <TouchableNativeFeedback onPress={onPress}>
            <View
                style={{
                    marginVertical: 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: width * 0.3,
                }}
            >
                <View
                    style={{
                        height: 20,
                        width: 20,
                        borderRadius: 5,
                        borderColor: Colors.gray1,
                        borderWidth: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Icon
                        style={{ zIndex: 3, opacity: isFocused ? 1 : 0 }}
                        name="check"
                        size={16}
                        color={Colors.gray1}
                    />
                </View>
                <Text
                    style={{
                        marginTop: 5,
                        alignSelf: 'center',
                        textAlign: 'center',
                    }}
                >
                    {title}
                </Text>
            </View>
        </TouchableNativeFeedback>
    );
};

const sortValues = {
    'Most popular': 'new',
    'Newest First': 'new',
    'Price Low to High': 'priceLow',
    'Price High To Low': 'priceHigh',
};

class Shop extends React.PureComponent {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
            headerMode: 'none',
            headerStyle: {
                zIndex: 2,
                // backgroundColor:'white',
                borderWidth: 0,
                borderBottomWidth: 0,
                shadowColor: 'transparent',
                elevation: 0,
                boxShadow: '',
                shadowOpacity: 0,
            },
        };
    };

    constructor(props) {
        super(props);
        // this.picker = React.createRef() ;
        this.picker = null;
        this._scroll = React.createRef();

        this.state = {
            data: [],
            storeData: [],
            storePage: 0,
            storePages: 1,
            storeItems: 0,
            apiData: [],
            apiPage: 1,
            apiPages: 1,
            apiResults: 0,

            isLoading: true,
            type: 'All',

            modalVisible: false,
            settingsMobileVisible: false,
            sortButtons: [true, false, false],
            sortButtonsTmp: [true, false, false],
            categories: {},
            tempCategories: {},
            scrolledHeader: new Animated.Value(0),
            scrolledPadding: new Animated.Value(0),
            raitingSelected: 0,
            age: [0, 18],
            sortDropdown: false,
            id: 0,
            sortBy: 'new',
            itemCategory: 'Select category',
            showing: 'all',
            stage: 0,
            storeKids: {},
            storeItemUrl: '',
            storeItemAge: [0, 18],
            search: '',
            childItems: 0,
            selectedStoreKids: [],
        };

        this.onArrowUpPress = this.onArrowUpPress.bind(this);
    }

    async componentDidMount() {
        const { childIds } = this.props.parent;
        const { type, categories, age, minPrice, maxPrice } = this.state;

        eventLog({ eventName: 'store_screen', properties: {} });

        const data = await this.props.actions.getStoreItems({
            sort: 'Most popular',
            type,
            filter: categories,
            ages: age,
            prices: [minPrice, maxPrice],
            page: 0,
        });

        this.props.actions.getFeaturedStoreItems();

        this.setState({
            storeData: data.hits,
            data: data.hits,
            storePage: data.page,
            storePages: data.nbPages,
            storeItems: data.nbHits,
            storeKids: childIds.reduce((curr, item) => {
                return { ...curr, [item]: false };
            }, {}),
            isLoading: false,
        });

        if (!this.props.auth.parentId) {
            // this.props.actions.clearAuth()
            //     .then(() => {
            //         this.props.navigation.navigate('Auth')
            //     })
        }
    }

    componentWillUnmount() {}

    defineValue(width, height, a, b, c) {
        if (width < 850 && width < height) {
            return a;
        } else if (width < 850 && width > height) {
            return b;
        } else {
            return c;
        }
    }

    logout = async () => {
        // this.props.actions.logout();
        await AsyncStorage.clear();
        // this.props.navigation.navigate('Login')
    };

    renderCard({ item }, layout) {
        const { names, childIds } = this.props.parent;

        // const itemValue = this.state.data[item]
        const itemValue = item;
        const title = itemValue ? itemValue.title : '';

        return (
            <StoreCard
                layout={layout}
                item={item}
                auth={this.props.auth}
                navigation={this.props.navigation}
                addNew={() => {
                    this.setState({
                        storeKids: childIds.reduce((curr, item) => {
                            return { ...curr, [item]: false };
                        }, {}),
                        modalVisible: true,
                        confirmationNewGoal: true,
                        title: title,
                        price: itemValue.price,
                        itemId: itemValue.key,
                        itemKey: item,
                    });
                }}
                onPress={() => {
                    this.setState({
                        storeKids: childIds.reduce((curr, item) => {
                            return { ...curr, [item]: false };
                        }, {}),
                        modalVisible: true,
                        confirmationGoal: true,
                        title: title,
                        price: itemValue.price,
                        itemId: itemValue.key,
                        itemKey: item,
                    });
                }}
            />
        );
    }

    selectChild(move) {
        const { parentId } = this.props.auth;
        const { childIds } = this.props.parent;

        const index = this.state.id + move;

        if (index > childIds.length - 1) {
            this.setState({ id: 0 });

            return 0;
        }

        if (index < 0) {
            this.setState({ id: childIds.length - 1 });

            return 0;
        }

        this.setState({ id: index });

        return 0;
    }

    confirmationGoal(layout) {
        const { parentId } = this.props.auth;
        const { childIds, names } = this.props.parent;

        const vmin = Math.min(...[layout.width, layout.height]);

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* <Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../assets/Goals/Goal_2.png')} /> */}
                <View>
                    <Text
                        style={[
                            { marginTop: '15%' },
                            defaultStyles.bold,
                            globalStyles.h4,
                        ]}
                    >
                        Add to store?
                    </Text>
                </View>

                <AddChildren
                    // mobile params
                    allKids={childIds.map((item, index) => {
                        return { id: index, name: names[item] };
                    })}
                    selectedKids={this.state.selectedStoreKids}
                    setSelectedKids={(items) =>
                        this.setState({ selectedStoreKids: items })
                    }
                    // web params
                    kids={this.state.storeKids}
                    names={names}
                    setKid={(item) => {
                        this.setState({
                            storeKids: {
                                ...this.state.storeKids,
                                [item]: !this.state.storeKids[item],
                            },
                        });
                    }}
                    removeKid={(item) => {
                        this.setState({
                            storeKids: {
                                ...this.state.storeKids,
                                [item]: false,
                            },
                        });
                    }}
                />

                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                        marginHorizontal: 15,
                    }}
                >
                    <View style={{ width: '40%' }}>
                        <TouchableNativeFeedback
                            onPress={() => {
                                const storeChildIds =
                                    Platform.OS === 'web'
                                        ? Object.keys(
                                              this.state.storeKids
                                          ).filter(
                                              (item) =>
                                                  this.state.storeKids[item]
                                          )
                                        : childIds.filter((item, index) =>
                                              this.state.selectedStoreKids.includes(
                                                  index
                                              )
                                          );

                                storeChildIds.length
                                    ? this.setState({
                                          modalVisible: false,
                                          confirmationGoal: false,
                                      })
                                    : null;

                                // this.props.actions.addGoal(this.state.price.toString(), this.state.title, parentId, childIds[this.state.id])
                                storeChildIds.length
                                    ? this.props.actions.addToChildStore(
                                          parentId,
                                          storeChildIds,
                                          'amazon',
                                          this.state.itemId
                                      )
                                    : null;
                            }}
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 35,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: 'rgba(126,193,61,1)',
                                }}
                            >
                                <Text
                                    style={[
                                        {
                                            fontSize: 19,
                                            marginVertical: 5,
                                            color: 'white',
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    YES
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ width: '40%' }}>
                        <TouchableNativeFeedback
                            onPress={() =>
                                this.setState({
                                    modalVisible: false,
                                    confirmationActivate: false,
                                })
                            }
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 35,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: '#EE5060',
                                }}
                            >
                                <Text
                                    style={[
                                        {
                                            fontSize: 19,
                                            marginVertical: 5,
                                            color: 'white',
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    NO
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </View>
            </View>
        );
    }

    confirmationNewGoal(layout) {
        const { itemKey } = this.state;
        const { parentId } = this.props.auth;
        const { childIds, names } = this.props.parent;

        const vmin = Math.min(...[layout.width, layout.height]);

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* <Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../assets/Goals/Goal_2.png')} /> */}
                <View>
                    <Text
                        style={{
                            marginTop: '10%',
                            fontSize: 24,
                            color: Colors.gray0,
                        }}
                    >
                        Add to store?
                    </Text>
                </View>

                {/* <ParentComponentHeader layout={layout} theme={'light'} containerStyle={{ width: '80%', marginTop: 0.03 * vmin, marginBottom: 0.03 * vmin }}
				title={names[childIds[this.state.id]]}
				pressLeft={() => this.getKidId(-1)}
				pressRight={() => this.getKidId(+1)}
			/> */}
                <AddChildren
                    kids={this.state.storeKids}
                    names={names}
                    setKid={(item) => {
                        this.setState({
                            storeKids: {
                                ...this.state.storeKids,
                                [item]: !this.state.storeKids[item],
                            },
                        });
                    }}
                    removeKid={(item) => {
                        this.setState({
                            storeKids: {
                                ...this.state.storeKids,
                                [item]: false,
                            },
                        });
                    }}
                />
                <TouchableNativeFeedback
                    style={{ width: '80%' }}
                    onPress={() => this.setState({ setNewGoalCategory: true })}
                >
                    <View
                        style={{
                            minHeight: 40,
                            justifyContent: 'center',
                            paddingLeft: '10%',
                            width: '100%',
                            borderColor: Colors.gray1,
                            borderWidth: 2,
                            borderRadius: 20,
                            marginBottom: 30,
                        }}
                    >
                        <Text style={{ color: Colors.gray0 }}>
                            {this.state.newItemCategory || 'Select Category'}
                        </Text>
                        <View
                            style={[
                                {
                                    right: '5%',
                                    width: 0,
                                    height: 0,
                                    position: 'absolute',
                                    backgroundColor: 'transparent',
                                    borderStyle: 'solid',
                                    borderTopWidth: 10,
                                    borderRightWidth: 5,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 5,
                                    borderTopColor: Colors.gray1,
                                    borderRightColor: 'transparent',
                                    borderBottomColor: 'transparent',
                                    borderLeftColor: 'transparent',
                                },
                            ]}
                        />
                    </View>
                </TouchableNativeFeedback>
                {
                    <ModalContainer
                        visible={this.state.setNewGoalCategory}
                        onClose={() => {
                            this.setState({ setNewGoalCategory: false });
                        }}
                    >
                        <View
                            style={{ marginVertical: 10, alignItems: 'center' }}
                        >
                            {categories.map((item, index) => (
                                <TouchableNativeFeedback
                                    style={{ marginVertical: 5 }}
                                    key={`${item}${index}`}
                                    onPress={() =>
                                        this.setState({
                                            setNewGoalCategory: false,
                                            newItemCategory: item,
                                        })
                                    }
                                >
                                    <Text>{item}</Text>
                                </TouchableNativeFeedback>
                            ))}
                        </View>
                    </ModalContainer>
                }
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                        marginHorizontal: 15,
                    }}
                >
                    <View style={{ width: '40%' }}>
                        <TouchableNativeFeedback
                            onPress={() => {
                                if (!this.state.newItemCategory) {
                                    return null;
                                }

                                let {
                                    mid,
                                    linkurl,
                                    imageurl,
                                    description,
                                    saleprice,
                                    merchantname,
                                    title,
                                    linkid,
                                } = itemKey;

                                const id = linkid[0];
                                const url = linkurl[0];
                                const category = 'Toys';
                                const price = saleprice[0]._;
                                const image = imageurl[0];

                                merchantname = merchantname[0];
                                Object.keys(this.state.storeKids).filter(
                                    (item) => this.state.storeKids[item]
                                ).length
                                    ? this.props.actions.addToStore({
                                          parentId,
                                          childIds: Object.keys(
                                              this.state.storeKids
                                          ).filter(
                                              (item) =>
                                                  this.state.storeKids[item]
                                          ),
                                          id,
                                          url,
                                          image,
                                          category,
                                          description,
                                          title,
                                          price,
                                          merchantname,
                                      })
                                    : null;

                                Object.keys(this.state.storeKids).filter(
                                    (item) => this.state.storeKids[item]
                                ).length
                                    ? this.setState({
                                          storeData: [
                                              ...this.state.storeData,
                                              {
                                                  id,
                                                  url,
                                                  image,
                                                  category,
                                                  description,
                                                  title,
                                                  price,
                                                  merchantname,
                                              },
                                          ],
                                          confirmationNewGoal: false,
                                          modalVisible: false,
                                          confirmationGoal: false,
                                      })
                                    : null;

                                this.setState({ newItemCategory: '' });
                            }}
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 35,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: 'rgba(126,193,61,1)',
                                }}
                            >
                                <Text
                                    style={[
                                        {
                                            fontSize: 19,
                                            marginVertical: 5,
                                            color: 'white',
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    YES
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ width: '40%' }}>
                        <TouchableNativeFeedback
                            onPress={() =>
                                this.setState({
                                    confirmationNewGoal: false,
                                    modalVisible: false,
                                    confirmationActivate: false,
                                })
                            }
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 35,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: '#EE5060',
                                }}
                            >
                                <Text
                                    style={[
                                        {
                                            fontSize: 19,
                                            marginVertical: 5,
                                            color: 'white',
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    NO
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </View>
            </View>
        );
    }

    confirmationBuy() {
        const { parentId } = this.props.auth;
        const { childId } = this.props.parent;

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Image
                    style={{ width: 70, height: 70, marginTop: '10%' }}
                    source={require('assets/Goals/Goal_2.png')}
                />
                <View style={{ marginVertical: 15, alignItems: 'center' }}>
                    <Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>
                        Activate
                    </Text>
                    <Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>
                        Goal
                    </Text>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                        marginHorizontal: 15,
                    }}
                >
                    <View style={{ width: '40%' }}>
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.setState({
                                    modalVisible: false,
                                    confirmationActivate: false,
                                });

                                this.props.actions
                                    .activateGoal(
                                        this.state.confirmGoalId,
                                        parentId,
                                        childId
                                    )
                                    .then(() => {
                                        this.setState({ isLoading: true });
                                        this.props.actions
                                            .getGoals(parentId, childId)
                                            .then(
                                                this.setState({
                                                    isLoading: false,
                                                })
                                            );
                                    });
                            }}
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 35,
                                    borderRadius: 10,
                                    margin: 5,
                                    backgroundColor: 'rgba(126,193,61,1)',
                                }}
                            >
                                <Text
                                    style={[
                                        {
                                            fontSize: 19,
                                            marginVertical: 5,
                                            color: 'white',
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    YES
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ width: '40%' }}>
                        <TouchableNativeFeedback
                            onPress={() =>
                                this.setState({
                                    modalVisible: false,
                                    confirmationActivate: false,
                                })
                            }
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 35,
                                    borderRadius: 10,
                                    margin: 5,
                                    backgroundColor: '#EE5060',
                                }}
                            >
                                <Text
                                    style={[
                                        {
                                            fontSize: 19,
                                            marginVertical: 5,
                                            color: 'white',
                                        },
                                        defaultStyles.bold,
                                    ]}
                                >
                                    NO
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </View>
            </View>
        );
    }

    settingsMobile(width) {
        // type, filter, ages, prices, search
        const { type, categories, age, search, sortButtons, sortButtonsTmp } =
            this.state;
        const isLoading = this.state.isLoading;

        return (
            <ScrollView style={{ flex: 1 }}>
                <TouchableNativeFeedback
                    onPress={() => {
                        this.setState({
                            sortButtonsTmp: sortButtons,
                            settingsMobileVisible: false,
                            tempCategories: Object.assign(
                                {},
                                this.state.categories
                            ),
                        });
                    }}
                >
                    <View
                        style={{
                            alignSelf: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Image
                            style={{ margin: 20, height: 30, width: 30 }}
                            source={require('assets/Delete-X.png')}
                        />
                    </View>
                </TouchableNativeFeedback>

                <View style={{ alignItems: 'center' }}>
                    <Text style={[defaultStyles.bold, { textAlign: 'center' }]}>
                        Order
                    </Text>
                </View>
                <View
                    style={{
                        width: width * 0.9,
                        alignSelf: 'center',
                        flexDirection: 'row',
                        marginVertical: 15,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: width * 0.3,
                        }}
                    >
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.setState({
                                    sortButtonsTmp: [true, false, false],
                                    type: 'Newest',
                                });
                            }}
                        >
                            <View
                                elevation={5}
                                style={{
                                    ...defaultStyles.shadow,
                                    backgroundColor: 'white',
                                    boxShadow: '2px 2px 15px #272827',
                                    height: 80,
                                    width: 80,
                                    borderRadius: 80,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderWidth: sortButtonsTmp[0] ? 3 : 0,
                                    borderColor: Colors.gray1,
                                }}
                            >
                                <Text style={{ textAlign: 'center' }}>
                                    Newest first
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: width * 0.3,
                        }}
                    >
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.setState({
                                    sortButtonsTmp: [false, true, false],
                                    sortBy: 'priceHigh',
                                });
                            }}
                        >
                            <View
                                elevation={5}
                                style={{
                                    ...defaultStyles.shadow,
                                    backgroundColor: 'white',
                                    boxShadow: '2px 2px 15px #272827',
                                    height: 80,
                                    width: 80,
                                    borderRadius: 80,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderWidth: sortButtonsTmp[1] ? 3 : 0,
                                    borderColor: Colors.gray1,
                                }}
                            >
                                <Text style={{ textAlign: 'center' }}>
                                    Price High to Low
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: width * 0.3,
                        }}
                    >
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.setState({
                                    sortButtonsTmp: [false, false, true],
                                    sortBy: 'priceLow',
                                });
                            }}
                        >
                            <View
                                elevation={5}
                                style={{
                                    ...defaultStyles.shadow,
                                    backgroundColor: 'white',
                                    boxShadow: '2px 2px 15px #272827',
                                    height: 80,
                                    width: 80,
                                    borderRadius: 80,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderWidth: sortButtonsTmp[2] ? 3 : 0,
                                    borderColor: Colors.gray1,
                                }}
                            >
                                <Text
                                    style={{ margin: 5, textAlign: 'center' }}
                                >
                                    Price Low to High
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </View>
                <View style={{ marginLeft: 20 }}>
                    <Text>Category</Text>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    {side_bar_items.map((item, index) => {
                        return (
                            <ButtonContainerMobile
                                key={index}
                                width={width}
                                onPress={() => {
                                    const newCategories =
                                        this.state.tempCategories; // Lena's mark

                                    if (newCategories[item.title] === true) {
                                        delete newCategories[item.title];
                                    } else {
                                        newCategories[item.title] = true;
                                    }

                                    this.setState({
                                        tempCategories: newCategories,
                                    });
                                }}
                                isFocused={
                                    this.state.tempCategories[item.title]
                                }
                                key={item.title}
                                image={item ? item.image : null}
                                title={item.title}
                            />
                        );
                    })}
                </View>
                <TouchableNativeFeedback
                    onPress={() => {
                        this.setState(
                            {
                                settingsMobileVisible: false,
                                categories: Object.assign(
                                    {},
                                    this.state.tempCategories
                                ),
                                data: [],
                                storeData: [],
                                apiData: [],
                                isLoading: true,
                            },
                            () => {
                                // this.props.actions.getStoreItems({ type, filter: categories, ages: age, prices: [minPrice, maxPrice], search, page: 0 })
                                //     .then(res => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                                this.props.actions
                                    .getStoreItems({
                                        type,
                                        filter: this.state.tempCategories,
                                        ages: age,
                                        sort: this.state.sortBy,
                                        prices: [
                                            this.state.minPrice,
                                            this.state.maxPrice,
                                        ],
                                        search,
                                        page: 0,
                                    })
                                    .then((res) =>
                                        this.setState({
                                            isLoading: false,
                                            storeData: res.hits,
                                            storePage: res.page,
                                            storePages: res.nbPages,
                                            storeItems: res.nbHits,
                                        })
                                    );
                            }
                        );
                    }}
                >
                    <View
                        style={{
                            boxShadow: '2px 2px 15px #272827',
                            margin: 10,
                            alignSelf: 'center',
                            borderRadius: 10,
                            width: 100,
                            padding: 5,
                            backgroundColor: Colors.green1,
                        }}
                    >
                        <Text style={{ color: 'white', textAlign: 'center' }}>
                            Apply
                        </Text>
                    </View>
                </TouchableNativeFeedback>
            </ScrollView>
        );
    }

    sortKeys(data) {
        const sortable = Object.keys(data).map((item) => {
            return { ...data[item], key: item };
        });

        sortable.sort((a, b) => {
            return this.state.sortBy === 'priceLow'
                ? a.price - b.price
                : b.price - a.price;
            // return a.price - b.price;
        });

        return sortable.map((item) => item.key);
    }

    checkAmazonUrl() {
        const pattern = RegExp(
            '(amazon.(com|ca)(?:/.*){0,1}(?:/dp/|/gp/product/))(.*?)(?:/.*|$)'
        );

        return this.state.storeItemUrl.match(pattern);
    }

    loadMore() {
        const { actions, auth } = this.props;

        const {
            storePage,
            storePages,
            type,
            categories,
            search,
            apiPage,
            apiPages,
            apiData,
        } = this.state;

        if (storePage < storePages) {
            // let newStoreData =
            this.setState({ isLoading: true }, () => {
                actions
                    .getStoreItems({
                        type,
                        filter: categories,
                        prices: [this.state.minPrice, this.state.maxPrice],
                        search,
                        page: storePage + 1,
                    })
                    .then((res) =>
                        this.setState({
                            isLoading: false,
                            storeData: [...this.state.storeData, ...res.hits],
                            storePage: res.page,
                            storePages: res.nbPages,
                            storeItems: res.nbHits,
                        })
                    );
            });
        }

        if (search && type !== 'Rated' && auth.type === 0) {
            this.setState({ isLoading: true });
            this.props.actions
                .rakutenSearch({ keyword: search, pagenumber: apiPage + 1 })
                .then((res) => {
                    const obj = res.items.item
                        ? res.items.item.map((item) => {
                              return formatStoreData(item);
                          })
                        : [];
                    // let obj = res.items.item && res.items.item.reduce((curr, item) => { return { ...curr, [item.linkid[0]]: formatStoreData(item), } }, {})

                    this.setState({
                        apiPage: parseInt(res.items.PageNumber[0]),
                        apiData: [...apiData, ...obj],
                        apiPages: parseInt(res.items.TotalPages[0]),
                        apiItems: parseInt(res.items.TotalMatches[0]),
                        isLoading: false,
                        // data: [...data, ...obj]
                    });
                });
        }
    }

    firestoreTest() {}

    buttonsWidth(numColumns, layout, vmin) {
        if (layout.width < 800) {
            return '90%';
        }

        const blockWidth = layout.width * 0.2 > 300 ? 300 : layout.width * 0.2;
        const margin = vmin * 0.02;

        return blockWidth * numColumns + margin * 2 * numColumns;
    }

    footer() {
        return (
            this.state.isLoading && (
                <View>
                    <ActivityIndicator color={Colors.green1} size={'large'} />
                </View>
            )
        );
    }

    onEndReached({ distanceFromEnd }) {
        this.loadMore();

        if (distanceFromEnd > 100) {
            return;
        } else {
            // this.loadMore()
        }
    }

    topBlock(layout) {
        const { navigation, store, auth, parent, actions } = this.props;
        const {
            type,
            categories,
            age,
            search,
            minPrice,
            maxPrice,
            storeData,
            apiData,
        } = this.state;
        const vmin = Math.min(...[layout.width, layout.height]);

        return (
            <>
                <FeaturedItems
                    layout={layout}
                    // getFeaturedStoreItems={actions.getFeaturedStoreItems}
                    getFeaturedStoreItems={() => {
                        actions.getFeaturedStoreItems();
                    }}
                    featuredData={Object.values(this.props.store.featuredItems)}
                    navigation={navigation}
                />
                <View
                    style={{
                        alignSelf: 'center',
                        width: '90%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            paddingHorizontal:
                                layout.width > 800 ? vmin * 0.02 : 0,
                            paddingLeft: 0,
                            paddingTop: 20,
                            width: layout.width > 800 ? '100%' : '100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* <View style={{ flexDirection: 'row' }}> */}
                        {layout.width > 850 ? (
                            <DropdownElement
                                containerStyle={{
                                    zIndex: 127,
                                    width: 170,
                                    // borderWidth: 4,
                                    backgroundColor: Colors.green1,
                                    borderColor: Colors.green1,
                                    // maxWidth: 160,
                                }}
                                mainElementStyle={{
                                    width: '100%',
                                    marginTop: 0,
                                    height: 45,
                                    // paddingHorizontal: 10,
                                    // justifyContent: 'center',
                                    // borderRadius: 20,
                                    backgroundColor: Colors.green1,
                                    borderColor: Colors.green1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                arrowStyle={{ borderTopColor: 'white' }}
                                elementContainerStyle={{
                                    height: 60,
                                    width: '100%',
                                    borderTopWidth: 1,
                                    borderColor: Colors.green1,
                                }}
                                mainTextStyle={{
                                    color: 'white',
                                    marginLeft: 0,
                                    width: '80%',
                                    textAlign: 'center',
                                }}
                                onPress={(val) => {
                                    this.props.actions
                                        .getStoreItems({
                                            sort: val,
                                            type,
                                            filter: categories,
                                            ages: age,
                                            prices: [minPrice, maxPrice],
                                            search,
                                            page: 0,
                                        })
                                        .then((res) =>
                                            this.setState({
                                                storeData: res.hits,
                                                storePage: res.page,
                                                storePages: res.nbPages,
                                                storeItems: res.nbHits,
                                            })
                                        );

                                    this.setState({ sortBy: sortValues[val] });
                                }}
                                defaultValue={sortCategories[this.state.sortBy]}
                                // }} defaultValue={sortBy}
                                categories={[
                                    'Most popular',
                                    'Price Low to High',
                                    'Price High To Low',
                                ]}
                            />
                        ) : (
                            <TouchableNativeFeedback
                                style={{ width: '30%' }}
                                onPress={() =>
                                    this.setState({
                                        settingsMobileVisible: true,
                                    })
                                }
                            >
                                <View
                                    style={[
                                        {
                                            maxWidth: 120,
                                            backgroundColor: Colors.green1,
                                            height: 45,
                                            justifyContent: 'center',
                                            borderRadius: 20,
                                        },
                                    ]}
                                >
                                    <Text
                                        style={[
                                            {
                                                color: 'white',
                                                textAlign: 'center',
                                            },
                                        ]}
                                    >
                                        Filter
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        )}

                        {false ? (
                            // <Dropdown categories={[{title:'All'}, {title:'New'}]} onPress={item=>this.setState({type:item})} defaultValue={this.state.type} />
                            <DropdownElement
                                disabled={!this.state.apiResults}
                                containerStyle={{ width: '30%', maxWidth: 120 }}
                                mainElementStyle={{
                                    height: 45,
                                    marginTop: 0,
                                    backgroundColor: Colors.green1,
                                    borderColor: Colors.green1,
                                    width: '100%',
                                    maxWidth: 120,
                                }}
                                arrowStyle={{ borderTopColor: 'white' }}
                                mainTextStyle={{ color: 'white' }}
                                // mainElementStyle, mainTextStyle, containerStyle, elementContainerStyle, arrowStyle, dropdownStyle
                                categories={['All', 'Store', 'New']}
                                defaultValue={this.state.type}
                                onPress={(value) =>
                                    this.setState({ type: value })
                                }
                            />
                        ) : null}
                        {/* </View> */}
                        {/* <TouchableNativeFeedback onPress={() => this.firestoreTest()}><Text>{this.state.type}</Text></TouchableNativeFeedback> */}

                        {auth.type === 0 ? (
                            <TouchableNativeFeedback
                                // style={Platform.OS && { alignItems: 'flex-end', width: '30%', }}
                                onPress={() => {
                                    this.setState({
                                        showing:
                                            this.state.showing === 'all'
                                                ? 'children'
                                                : 'all',
                                    });
                                }}
                            >
                                <View
                                    style={{
                                        width: '100%',
                                        maxWidth: 120,
                                        padding: 10,
                                        height: 45,
                                        justifyContent: 'center',
                                        borderWidth: 3,
                                        borderRadius: 20,
                                        borderColor: Colors.gray,
                                    }}
                                >
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            alignSelf: 'center',
                                            color: Colors.black,
                                        }}
                                    >
                                        {this.state.showing === 'all'
                                            ? 'Child Store'
                                            : 'All Items'}
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                        ) : null}
                    </View>
                    {this.state.isLoading ?
                    <TextSkeleton containerStyle={{width: 65, height: 20,
                        marginVertical: 0.04 * vmin,
                        alignSelf: 'flex-end',}} /> :
                    <Text
                        style={{
                            marginVertical: 0.04 * vmin,
                            alignSelf: 'flex-end',
                        }}
                    >
                        {this.state.showing === 'all'
                            ? this.state.storeItems
                            : this.state.childItems}{' '}
                        {this.state.data.length === 1 ? 'item' : 'items'}
                    </Text>}
                </View>
            </>
        );
    }

    onArrowUpPress() {
        this._scroll?.current?.scrollTo({
            x: 0,
            y: 0,
            animated: true,
        });
    }

    render() {
        const { navigation, store, auth, parent, actions } = this.props;
        const {
            type,
            categories,
            age,
            search,
            minPrice,
            maxPrice,
            storeData,
            apiData,
        } = this.state;

        let data = [];

        //    [...storeData, ...apiData]
        if (type === 'Rated') {
            data = [...storeData];
        } else if (type === 'New') {
            data = [...apiData];
        } else {
            data = [...storeData, ...apiData];
        }

        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    const width = layout.width;
                    const numColumns =
                        width > 1300
                            ? 2
                            : width > 1000
                            ? 1
                            : width > 850
                            ? 1
                            : 1;

                    return (
                        <View style={styles.container}>
                            <Modal
                                animationIn={'zoomIn'}
                                animationOut={'zoomOut'}
                                animationInTiming={500}
                                animationOutTiming={500}
                                backdropColor={'darkgrey'}
                                backdropOpacity={0.6}
                                onBackdropPress={() =>
                                    this.setState({
                                        modalVisible: false,
                                        confirmationDelete: false,
                                        confirmationNewGoal: false,
                                        confirmationPurchase: false,
                                        confirmationActivate: false,
                                    })
                                }
                                isVisible={this.state.modalVisible}
                                onRequestClose={() => {
                                    this.setState({
                                        modalVisible: false,
                                        confirmationDelete: false,
                                        confirmationNewGoal: false,
                                        confirmationPurchase: false,
                                        confirmationActivate: false,
                                    });
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 15,
                                        backgroundColor: 'white',
                                        width: '80%',
                                        alignSelf: 'center',
                                        maxWidth: 400,
                                        paddingBottom: 10,
                                    }}
                                >
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            zIndex: 5,
                                        }}
                                    >
                                        <TouchableNativeFeedback
                                            onPress={() =>
                                                this.setState({
                                                    modalVisible: false,
                                                    confirmationDelete: false,
                                                    confirmationNewGoal: false,
                                                    confirmationPurchase: false,
                                                    confirmationActivate: false,
                                                })
                                            }
                                        >
                                            <View
                                                elevation={10}
                                                style={{
                                                    alignSelf: 'center',
                                                    padding: 5,
                                                    margin: 10,
                                                }}
                                            >
                                                <Image
                                                    style={{
                                                        height: 10,
                                                        width: 10,
                                                    }}
                                                    source={require('assets/Delete-X.png')}
                                                />
                                                {/* <Text style={{ fontSize: closeFontSize, color: Colors.gray0, ...defaultStyles.bold, }}>X</Text> */}
                                            </View>
                                        </TouchableNativeFeedback>
                                    </View>
                                    <View style={{}}>
                                        {this.state.confirmationGoal
                                            ? this.confirmationGoal(layout)
                                            : null}
                                        {this.state.confirmationNewGoal
                                            ? this.confirmationNewGoal(layout)
                                            : null}
                                        {/* { this.confirmationNewGoal(layout)} */}
                                        {/* {this.state.confirmationBuy ? this.confirmationBuy() : null} */}
                                    </View>
                                </View>
                            </Modal>

                            <Modal
                                animationIn={'zoomIn'}
                                animationOut={'zoomOut'}
                                animationInTiming={500}
                                animationOutTiming={500}
                                style={{ margin: 0 }}
                                // backdropOpacity={0.6}
                                // onBackdropPress={() => this.setState({settingsMobileVisible:false})}
                                isVisible={this.state.settingsMobileVisible}
                                onRequestClose={() => {
                                    this.setState({
                                        sortButtonsTmp: sortButtons,
                                        settingsMobileVisible: false,
                                    });
                                }}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        margin: 0,
                                        paddingTop: 50,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {this.settingsMobile(layout.width)}
                                </View>
                            </Modal>

                            {/* MAIN PART  */}
                            <ArrowUp
                                onPress={this.onArrowUpPress}
                                style={{ bottom: layout.height * 0.1 }}
                            />

                            <StoreHeader
                                style={{}}
                                navigation={this.props.navigation}
                                signOut={() => {}}
                                vmin={vmin}
                                layout={layout}
                                clearAuth={this.props.actions.clearAuth}
                                dashboard={false}
                            >
                                <Search
                                    value={this.state.search}
                                    onChange={(text) =>
                                        this.setState({ search: text })
                                    }
                                    onSubmitEditing={() => {
                                        this.setState({
                                            isLoading: true,
                                            data: [],
                                            storeData: [],
                                            apiData: [],
                                        });

                                        actions
                                            .getStoreItems({
                                                type,
                                                filter: categories,
                                                prices: [minPrice, maxPrice],
                                                search,
                                                page: 0,
                                            })
                                            .then((res) => {
                                                if (res?.hits?.length) {
                                                    return this.setState({
                                                        isLoading: false,
                                                        storeData: res.hits,
                                                        storePage: res.page,
                                                        storePages: res.nbPages,
                                                        storeItems: res.nbHits,
                                                    });
                                                } else {
                                                    return this.props.actions
                                                        .rakutenSearch({
                                                            keyword: search,
                                                            pagenumber: 1,
                                                        })
                                                        .then((res) => {
                                                            console.log('RES: ', res);
                                                            if (!res || !res.items) {
                                                            this.setState({
                                                                isLoading:
                                                                    false,
                                                                // data: [...data, ...obj]
                                                            });
                                                                return
                                                            }
                                                            const obj = res
                                                                .items.item
                                                                ? res.items.item.map(
                                                                      (
                                                                          item
                                                                      ) => {
                                                                          return formatStoreData(
                                                                              item
                                                                          );
                                                                      }
                                                                  )
                                                                : [];

                                                            this.setState({
                                                                apiPage:
                                                                    parseInt(
                                                                        res
                                                                            .items
                                                                            .PageNumber[0]
                                                                    ),
                                                                apiData: [
                                                                    ...obj,
                                                                ],
                                                                apiPages:
                                                                    parseInt(
                                                                        res
                                                                            .items
                                                                            .TotalPages[0]
                                                                    ),
                                                                apiItems:
                                                                    parseInt(
                                                                        res
                                                                            .items
                                                                            .TotalMatches[0]
                                                                    ),
                                                                isLoading:
                                                                    false,
                                                                // data: [...data, ...obj]
                                                            });
                                                        });
                                                }
                                            });
                                    }}
                                />
                            </StoreHeader>

                            {IS_WEB && layout.width > 850 && (
                                <SearchWebFilter
                                    onSubmit={() => {
                                        this.props.actions
                                            .getStoreItems({
                                                type,
                                                filter: categories,
                                                prices: [minPrice, maxPrice],
                                                search,
                                                page: 0,
                                            })
                                            .then((res) =>
                                                this.setState({
                                                    storeData: res.hits,
                                                    storePage: res.page,
                                                    storePages: res.nbPages,
                                                    storeItems: res.nbHits,
                                                })
                                            );
                                    }}
                                    onChangeMinPrice={(text) =>
                                        this.setState({
                                            minPrice: text.replace(/\D/, ''),
                                        })
                                    }
                                    onChangeMaxPrice={(text) =>
                                        this.setState({
                                            maxPrice: text.replace(/\D/, ''),
                                        })
                                    }
                                    minPrice={this.state.minPrice}
                                    maxPrice={this.state.maxPrice}
                                    onCategoryPress={(item) => {

                                        let newCategories =
                                            this.state.categories; //Lena's mark

                                        if (item.title !== 'All') {
                                            delete newCategories.All;
                                        }

                                        if (
                                            newCategories[item.title] === true
                                        ) {
                                            delete newCategories[item.title];
                                        } //execute the manipulations
                                        else {
                                            newCategories[item.title] = true;
                                        }

                                        if (item.title === 'All') {
                                            newCategories = {
                                                All: true,
                                            };
                                        }

                                        this.setState(
                                            {
                                                categories: newCategories,
                                            },
                                            () => {
                                                this.props.actions
                                                    .getStoreItems({
                                                        type,
                                                        filter: newCategories,
                                                        prices: [
                                                            minPrice,
                                                            maxPrice,
                                                        ],
                                                        search,
                                                        page: 0,
                                                    })
                                                    .then((res) =>
                                                        this.setState({
                                                            storeData: res.hits,
                                                            storePage: res.page,
                                                            storePages:
                                                                res.nbPages,
                                                            storeItems:
                                                                res.nbHits,
                                                        })
                                                    );
                                            }
                                        );
                                    }}
                                    layout={layout}
                                    vmin={vmin}
                                    isCategoryFocused={(item) =>
                                        this.state.categories[item.title]
                                    }
                                />
                            )}

                            <ScrollView
                                ref={this._scroll}
                                onScroll={(e) => {
                                    const finish =
                                        (e.nativeEvent.contentOffset.y +
                                            e.nativeEvent.layoutMeasurement
                                                .height) /
                                        e.nativeEvent.contentSize.height;
                                    const threshold = 0.9;

                                    if (finish > threshold) {
                                        this.loadMore();
                                    }
                                }}
                                style={{
                                    flex: 1,
                                    // alignItems: 'center',
                                    ...(layout.width > 850 && {
                                        marginLeft: 300,
                                    }),
                                }}
                            >
                                {this.topBlock(layout)}
                                {this.state.showing === 'all' ? (
                                    <FlatList
                                        key={numColumns}
                                        nestedScrollEnabled={false}
                                        numColumns={numColumns}
                                        showsVerticalScrollIndicator={false}
                                        // ListHeaderComponent={() => this.topBlock(layout)}
                                        ListFooterComponent={(f) =>
                                            this.footer()
                                        }
                                        contentContainerStyle={{
                                            paddingTop: 5,
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                        }}
                                        style={{
                                            zIndex: -1,
                                            flex: 1,
                                            width: '100%',
                                            alignSelf: 'center',
                                            paddingVertical: 0.04 * vmin,
                                        }}
                                        data={data}
                                        keyExtractor={(item, index) =>
                                            index.toString()
                                        }
                                        renderItem={(item) =>
                                            this.renderCard(item, layout)
                                        }
                                        onEndReachedThreshold={0.5}
                                        {...(Platform.OS !== 'web' && {
                                            onEndReached: ({
                                                distanceFromEnd,
                                            }) =>
                                                this.onEndReached({
                                                    distanceFromEnd,
                                                }),
                                        })}

                                        // ItemSeparatorComponent={()=><View style={{width:10, borderWidth:1, height:10}}></View>}
                                        // columnWrapperStyle={{borderWidth:1,flex:0.5,alignItems:'flex-start', justifyContent:'flex-start'}}
                                        // data={this.state.data}
                                        // data={Object.keys(this.state.data)}
                                        // onEndReached={() => { console.log('end reached') }}
                                        // onScroll={({ nativeEvent }) => { console.log('nativeEvent ', nativeEvent) }}
                                    />
                                ) : (
                                    <StoreChildren
                                        // parentId={parentId} childIds={this.props.parent.childIds}
                                        id={this.props.parent.id}
                                        items={this.state.items}
                                        setItems={(val) =>
                                            this.setState({
                                                childItems: val,
                                            })
                                        }
                                        setLoading={(val) =>
                                            this.setState({
                                                isLoading: val,
                                            })
                                        }
                                        navigation={navigation}
                                        categories={JSON.stringify(
                                            this.state.categories
                                        )}
                                        isLoading={this.state.isLoading}
                                        type={this.state.sortBy}
                                    />
                                )}
                            </ScrollView>
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        width: '100%',
        height: Platform.OS === 'web' ? '100vh' : '100%',
    },
});

export default connect(
    (state) => {
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...storeActions, ...authActions, ...goalsActions },
            dispatch
        ),
    })
)(Shop);

// let amazon = (storeJson.store.amazon)
// let rakuten = (storeJson.store.rakuten)
// let storeRef = firebase.database().ref('store');

// let keys = Object.values(amazon);
// for (let i in keys) {
//     let {amazon_id, category, description, image, parents, kids, price, title} = keys[i]
//     let newPush = storeRef.push()

//     newPush.update({id:amazon_id, key:newPush.key, category, description, image, link:keys[i].link, parents:parents||1, kids:kids||1, price, title})
// }

// let rakutenKeys = Object.values(rakuten);

// for (let i in rakutenKeys) {
//     let {id, category, description, image, parents, kids, price, merchantname, title} = rakutenKeys[i]
//     let newPush = storeRef.push()

//     newPush.update({id, key:newPush.key, category, merchantname:merchantname||'LEGO Brand Retail, Inc.', description, image, link:rakutenKeys[i].url, parents:parents||1, kids:kids||1, price, title})
// }
// for (let i in amazon) {
//     let { title, category, description, image, link, price, amazon_id } = amazon[i]
//     firestore().collection("store")
//         .doc(amazon_id.toString()).set({
//             // .add({
//             title, category, description, image, link, price, merchantname: 'Amazon',
//             id: amazon_id
//         })
//         .then(function () {
//             console.log("Document successfully written!");
//         })
//         .catch(function (error) {
//             console.error("Error writing document: ", error);
//         });
// }
// for (let i in rakuten) {
//     let { title, category, description, image, url, price, merchantname, id } = rakuten[i]
//     firestore().collection("store")
//         .doc(id).set({
//             // .add({
//             title, category: 'Toys', description, image, link: url, price, merchantname: merchantname || "LEGO Brand Retail, Inc.", id
//         })
// }
