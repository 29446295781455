/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
	StyleSheet,
} from 'react-native';


export function setDate(id) {
    let date = new Date();
    switch (id) {
        case 0:
            date.setDate(date.getDate()-7);
            break
        case 1:
            date.setMonth(date.getMonth()-1);
            break
        case 2:
            date.setMonth(date.getMonth()-6);
            break
        case 3:
            date.setFullYear(date.getFullYear()-1);
            break
        }
    return date
}



const styles = StyleSheet.create({

});
