/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Resizable } from 'react-native-web-hooks';

import firebase from 'firebase/app';
import 'firebase/database';

import { ParentHeaderCard } from '../../components/Header/HeaderBlock';

import CarouselComponent from '../../components/Carousel/Carousel';
import Balance, {
    ChequingTotalActivity,
    Movement,
    Interest,
} from '../../components/chequing/balance';
import { SectionTable, SectionRow } from '../../components/chequing/table';
import Intervals from '../../components/chequing/Intervals';
import ParentTransferButton from '../../components/chequing/ParentTransferButton';

import * as authActions from '../../store/actions/parents/authActions';
import * as chequingActions from '../../store/actions/parents/chequingActions';
import * as savingsActions from '../../store/actions/parents/savingsActions';

import { eventLog } from '../../helpers/analytics/analytics';
import { CHEQUING_TABLES } from '../../constants/screens/chequing';

const database = firebase.database;

class Chequing extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            header: null,
        };
    };

    constructor(props) {
        super(props);
        this._carousel = React.createRef();

        this.state = {
            type: this.props.navigation.getParam('cards', 'Chequing'),
            movement: 0,
            isLoading: true,
            interval: new Date(),
            debit_card: 0,
            credit_card: 0,
            visible: false,
            intervalId: 1,
            transfer: '',
            confirmation: false,
            header: false,
            conditions: {},
            id: 0,
        };
    }

    async getCards() {
        const { parentId } = this.props.auth;
        const { childIds, names, id } = this.props.parent;

        var settings_ref = database().ref(
            '/users/' + parentId + '/' + childIds[id] + '/settings'
        );
        const snapshot = await settings_ref.once('value');
        const settings_val = snapshot.val();

        this.setState({
            debit_card: settings_val.debit_card,
            credit_card: settings_val.credit_card,
        });
    }

    componentDidMount() {
        const { parentId } = this.props.auth;
        const { childIds, names, id } = this.props.parent;
        const { actions, navigation } = this.props;

        eventLog({ eventName: 'chequing_screen', properties: {} });

        if (!this.props.auth.parentId) {
            this.props.actions.clearAuth().then(() => {
                this.props.navigation.navigate('Auth');
            });
        }

        // this.focusListener = navigation.addListener('didFocus', () => {
        this.getCards();
        const cards = navigation.getParam('cards', 'Chequing');

        console.log("CARDS! - ", cards);

        const month = new Date();

        month.setMonth(month.getMonth() - 1);
        // this.state.interval.setMonth(new Date().getMonth() - 1);
        // this.setState({ type: navigation.getParam('cards', 'Chequing') });
        this._carousel.current.snapToItem(cards === 'Savings' ? 2 : 1);

        // cards !== 'Savings'
        //     ? actions.getChequing(month, parentId, childIds[0], navigation)
        //     : actions.getSavings(month, parentId, childIds[0], navigation);
        // if (cards === 'Chequing') {
        //     navigation.setParams({'cards':'Chequing'});
        // }

        actions
            .getChequing(month, parentId, childIds[id])
            .then(() => this.setState({ isLoading: false }));

        actions.getSavings(month, parentId, childIds[id]);
        // });
    }

    componentWillUnmount() {
        console.log('123');
        this.focusListener?.remove();
    }

    getData(move) {
        const { parentId } = this.props.auth;
        const { childIds, names, id } = this.props.parent;
        const {
            getChequing,
            getSavings,
            changeChildIndex,
        } = this.props.actions;
        const { navigation } = this.props;
        const index = id + move;

        console.log('this.state.type - ', this.state.type, index, names);

        changeChildIndex(move);
        if (index > childIds.length - 1) {
            this.setState({ id: 0 }, () => {
                this.getCards();
            });

            this.state.type === 'Chequing'
                ? getChequing(
                      this.state.interval,
                      parentId,
                      childIds[0],
                      navigation
                  )
                : getSavings(
                      this.state.interval,
                      parentId,
                      childIds[0],
                      navigation
                  );

            return 0;
        }

        if (index < 0) {
            this.setState({ id: childIds.length - 1 }, () => {
                this.getCards();
            });

            this.state.type === 'Chequing'
                ? getChequing(
                      this.state.interval,
                      parentId,
                      childIds[childIds.length - 1],
                      navigation
                  )
                : getSavings(
                      this.state.interval,
                      parentId,
                      childIds[childIds.length - 1],
                      navigation
                  );

            return 0;
        }

        this.setState({ id: index }, () => {
            this.getCards();
        });

        this.state.type === 'Chequing'
            ? getChequing(
                  this.state.interval,
                  parentId,
                  childIds[index],
                  navigation
              )
            : getSavings(
                  this.state.interval,
                  parentId,
                  childIds[index],
                  navigation
              );

        return 0;
    }

    parseFloat(value) {
        return value ? parseFloat(value) : 0;
    }

    intervalText() {
        const INTERVALS = ['1 week', '1 month', '6 months', '1 year'];

        return `in ${INTERVALS[this.state.intervalId]}`;
    }

    render() {
        const { navigation, actions } = this.props;
        const { parentId } = this.props.auth;
        const { childIds, names, id } = this.props.parent;

        const chequing = this.props.chequing[childIds[id]]
            ? this.props.chequing[childIds[id]]
            : {};
        const savings = this.props.savings[childIds[id]]
            ? this.props.savings[childIds[id]]
            : {};

        console.log('SAVINGS DEPOSIT - ', chequing);

        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    if (!chequing || !savings) {
                        return <View />;
                    }

                    return (
                        <View style={styles.container}>
                            {this.state.type === 'Chequing' && (
                                <ParentTransferButton
                                    interval={this.state.intervalId}
                                />
                            )}

                            <ScrollView
                                style={{ flex: 1, zIndex: 1 }}
                                contentContainerStyle={{ paddingBottom: 55 }}
                                // onScroll={(e) => handleScroll.call(this, e, layout.height)}
                            >
                                <ParentHeaderCard
                                    style={{ paddingBottom: 0, zIndex: -1 }}
                                    name={names[childIds[id]]}
                                    pressNext={() => this.getData(+1)}
                                    navigation={navigation}
                                    signOut={() => {}}
                                    vmin={vmin}
                                    layout={layout}
                                    clearAuth={this.props.actions.clearAuth}
                                />

                                <CarouselComponent
                                    carouselRef={this._carousel}
                                    type={this.state.type}
                                    layout={layout}
                                    debit_card={this.state.debit_card}
                                    credit_card={this.state.credit_card}
                                    onSnapToItem={(index) => {
                                        const month = new Date();

                                        month.setMonth(month.getMonth() - 1);

                                        console.log('month ', month);
                                        navigation.setParams({'cards':index===0?'Chequing':'Savings'});
                                        this.setState(
                                            {
                                                intervalId: 1,
                                                // isLoading: true,
                                                type:
                                                    index === 1
                                                        ? 'Chequing'
                                                        : 'Savings',
                                            },
                                            () => {
                                                index === 1
                                                    ? actions.getChequing(
                                                          month,
                                                          parentId,
                                                          childIds[id],
                                                          navigation
                                                      )
                                                    : actions.getSavings(
                                                          month,
                                                          parentId,
                                                          childIds[id],
                                                          navigation
                                                      );
                                            }
                                        );
                                    }}
                                />

                                <View
                                    style={{
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Intervals
                                        focused={this.state.intervalId}
                                        setFocused={(index) =>
                                            this.setState({
                                                intervalId: index,
                                            })
                                        }
                                        onPress={
                                            this.state.type === 'Chequing'
                                                ? actions.getChequing
                                                : actions.getSavings
                                        }
                                        type={this.state.type}
                                        parentId={parentId}
                                        childId={childIds[id]}
                                        navigation={navigation}
                                    />
                                </View>

                                {this.state.type === 'Chequing' ? (
                                    <View style={{ alignItems: 'center' }}>
                                        <View
                                            style={{
                                                width: '90%',
                                                maxWidth: 600,
                                                marginTop: 30,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Balance
                                                isLoading={this.state.isLoading}
                                                layout={layout}
                                                balance={
                                                    chequing.chequing_balance
                                                }
                                                movement={chequing.movement}
                                            />
                                            <Movement
                                                isLoading={this.state.isLoading}
                                                balance={
                                                    chequing.chequing_balance
                                                }
                                                movement={chequing.movement}
                                                value={chequing.movement}
                                            />
                                        </View>

                                        <ChequingTotalActivity
                                            income={chequing.income}
                                            expense={chequing.expense}
                                            saved={chequing.saved}
                                            period={this.intervalText()}
                                        />

                                        <SectionTable
                                            title={CHEQUING_TABLES.INCOME}
                                            value={chequing.income}
                                            transactions={chequing.income_trans}
                                        >
                                            <SectionRow
                                                data={chequing.allowance}
                                                title={'Allowance'}
                                                value={
                                                    chequing.allowance_income
                                                }
                                                transactions={
                                                    chequing.allowance_income_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
                                            <SectionRow
                                                data={chequing.job}
                                                title={'Jobs'}
                                                value={chequing.job_income}
                                                transactions={
                                                    chequing.job_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
                                            <SectionRow
                                                data={chequing.deposit}
                                                title={'Deposits'}
                                                value={chequing.deposit_income}
                                                transactions={
                                                    chequing.deposit_income_trans
                                                }
                                            />
                                        </SectionTable>

                                        <SectionTable
                                            title={CHEQUING_TABLES.EXPENSE}
                                            value={chequing.expense}
                                            transactions={
                                                chequing.expense_trans
                                            }
                                        >
                                            <SectionRow
                                                data={chequing.online_purchase}
                                                title={'Online Purchases'}
                                                value={chequing.online_expense}
                                                transactions={
                                                    chequing.online_expense_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={chequing.credit_purchase}
                                                title={'Credit Purchases'}
                                                value={chequing.credit_expense}
                                                transactions={
                                                    chequing.credit_expense_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={
                                                    chequing.expence_fees_data
                                                }
                                                title={'Fees'}
                                                value={chequing.expence_fees}
                                                transactions={
                                                    chequing.expence_fees_trans
                                                }
                                            />
                                        </SectionTable>

                                        <SectionTable
                                            title={CHEQUING_TABLES.SAVING}
                                            value={chequing.saved}
                                            transactions={chequing.saved_trans}
                                        >
                                            <SectionRow
                                                data={chequing.savings_deposit}
                                                title={'Savings Deposits'}
                                                value={chequing.deposit_savings}
                                                transactions={
                                                    chequing.deposit_savings_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
                                            <SectionRow
                                                data={
                                                    chequing.savings_withdrawal
                                                }
                                                title={'Savings Withdrawals'}
                                                value={
                                                    chequing.withdraw_savings
                                                }
                                                transactions={
                                                    chequing.withdraw_savings_trans
                                                }
                                            />
                                            {/* <SectionRow data={chequing.savings_fees_data} title={'Fees'}
                                                    value={chequing.savings_fees} transactions={chequing.savings_fees_trans}
                                                /> */}
                                        </SectionTable>
                                    </View>
                                ) : (
                                    <View style={{ alignItems: 'center' }}>
                                        <View
                                            style={{
                                                width: '90%',
                                                maxWidth: 600,
                                                marginTop: 30,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Balance
                                                layout={layout}
                                                balance={
                                                    savings.savings_balance
                                                }
                                                movement={savings.total_savings}
                                            />
                                            <Interest
                                                interest={savings.interest}
                                                rate={savings.interest_rate}
                                            />
                                        </View>

                                        <SectionTable
                                            title={'Saved'}
                                            value={savings.total_savings}
                                            transactions={
                                                savings.total_savings_trans
                                            }
                                        >
                                            <SectionRow
                                                data={savings.deposits_arr}
                                                title={'Deposits'}
                                                value={savings.deposits}
                                                transactions={
                                                    savings.deposits_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={savings.withdrawals_arr}
                                                title={'Withdrawals'}
                                                value={savings.withdrawals}
                                                transactions={
                                                    savings.withdrawals_trans
                                                }
                                                bottomBorder={true}
                                            />
                                            <SectionRow
                                                data={savings.savings_fees}
                                                title={'Fees'}
                                                value={
                                                    savings.savings_fees_amount
                                                }
                                                transactions={
                                                    savings.savings_fees_trans
                                                }
                                            />
                                        </SectionTable>
                                    </View>
                                )}
                                {/* </View> */}
                            </ScrollView>
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

// export default Chequing
export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            chequing: state.chequing,
            savings: state.savings,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...chequingActions, ...savingsActions },
            dispatch
        ),
    })
)(Chequing);

const styles = StyleSheet.create({
    container: { zIndex: 1, flex: 1, backgroundColor: 'white' },
});
