import React from 'react';
import { Platform } from 'react-native';
import { createAppContainer, createSwitchNavigator, createBottomTabNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import { Academy } from "../screens/Kids/Academy/Academy";
import { Quizzes } from "../screens/Kids/Academy/Quizzes";
import { Videos } from "../screens/Kids/Academy/Videos";
import { Quiz } from "../screens/Kids/Academy/Quiz";
import { VideoScreen } from "../screens/Kids/Academy/Video";
import { Article } from "../screens/Kids/Academy/Article";
import { Articles } from "../screens/Kids/Academy/Articles";
import AddStudents from '../screens/Parents/Academy/AddStudents';

// import StoreItem from "../screens/Kids/StoreItem";

const AcademyStack = createStackNavigator({
    Academy,
    Quizzes,
    Quiz,
    Videos,
    VideoScreen,
    Articles,
    Article,
    AddStudents
  }
  );

export { AcademyStack };
