
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {Alert, Platform, Linking } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

import ImageResizer from 'react-native-image-resizer';
// import ImagePicker from 'react-native-image-crop-picker';

import { useDispatch } from 'react-redux'

import { Buffer } from 'buffer'

import * as authActions from '../store/actions/kids/authActions';
import { IS_WEB } from '../constants/constants';

const ImagePicker = !IS_WEB ? require('react-native-image-crop-picker').default : {
    openPicker: () => null,
    openCamera: () => null,
}
// import { Avatar } from './Settings';

const database = firebase.database;
const storage = firebase.storage;

function save_avatar(data) {
    const { parentId, childId } = this.props.auth;

    database().ref('/users/' + parentId + '/' + childId + '/settings').update({
        avatar: data
    });
}

function getImageHash(image) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', image, true);
    xhr.responseType = 'arraybuffer'; // this will accept the response as an ArrayBuffer
    xhr.onload = function (buffer) {
        var words = new Uint32Array(buffer),
            hex = '';
        console.log('words', words)

        for (var i = 0; i < words.length; i++) {
            hex += words.get(i).toString(16);  // this will convert it to a 4byte hex string
        }
        console.log('hex -', hex);
    };
    xhr.send();
}

const checkCameraPermission = async () => {
    if (Platform.OS === 'web') {
        return {granted: true}
    }

    // TODO fix permissions
    let permission = {granted: true};
    // let permission = await ImagePicker.requestCameraPermissionsAsync();

    return permission
}

const checkCameraRollPermission = async () => {
    if (Platform.OS === 'web') {
        return {granted: true}
    }

    // TODO fix permissions
    let permission = {granted: true};
    // let permission = await ImagePicker.requestMediaLibraryPermissionsAsync();

    return permission
}
const uriToBlob = (uri) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.onload = function () {
            // return the blob
            // console.log(xhr.response)
            resolve(xhr.response);
        };

        xhr.onerror = function () {
            // something went wrong
            reject(new Error('uriToBlob failed'));
        };

        // this helps us get a blob
        xhr.responseType = 'blob';

        xhr.open('GET', uri, true);
        xhr.send(null);

    });

}

const allowPermissionAlert = (permissionName) => {
    Alert.alert(
        "Permission",
        `Please allow ${permissionName || camera} permission for this feature`,
        [
          {
            text: "Cancel",
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel"
          },
          { text: "Open Settings", onPress: () => Linking.openSettings() }
        ],
        { cancelable: true }
      );
}

export async function resizeImage({ path, maxWidth = 60, maxHeight = 60 }) {

    const resp = await ImageResizer.createResizedImage(path, maxWidth, maxHeight, 'PNG', 0, 0)
    return { ...resp, uri: resp.uri }
}

export async function pickCameraImage({ width = 60, height = 60 }) {

    const resp = await ImagePicker.openCamera({
        width,
        height,
        cropping: true,
    })
    return { ...resp, uri: resp.path }
}

export async function pickGalleryImage({ width = 60, height = 60 }) {

    const resp = await ImagePicker.openPicker({
        width,
        height,
        cropping: true,
    })
    return { ...resp, uri: resp.path }
}

export const _launchCamera = async ({parentId, childId, dispatch }) => {
    // const {  } = this.props.auth;
    // const dispatch = useDispatch()

    let permissionResult = await checkCameraPermission();

    console.log('permissionResult , ', permissionResult)
    if (permissionResult.granted === false) {
        if (permissionResult?.canAskAgain === false) {
            allowPermissionAlert('camera');
        }
        return;
    }

    let result = await pickCameraImage({});

    // let result = await ImagePicker.launchCameraAsync({
    //     mediaTypes: ImagePicker.MediaTypeOptions.Image,
    //     allowsEditing: true,
    //     aspect: [1, 1],
    //     quality: 0.1
    // });

    console.log('result, ', result)

    if (!result.cancelled) {
        console.log('lets start, ', result)
        uriToBlob(result.uri).then(blob => {
            console.log('blob , ,', blob);
            storage().ref('children/' + childId + '.png').put(blob)
        });

        AsyncStorage.setItem('avatar', result.uri)
        dispatch(authActions.saveAvatar(parentId, childId, 5, result.uri))
    }
};

export const _pickImage = async ({ parentId, childId, dispatch }) => {
    // const dispatch = useDispatch()

    let permissionResult = await checkCameraRollPermission();

    if (permissionResult.granted === false) {
        if (permissionResult?.canAskAgain === false) {
            allowPermissionAlert('file storage');
        }
        return;
    }

    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0
    });

    console.log(result);

    if (!result.cancelled) {
        let uri = result.uri
        console.log(result)
        if (Platform.OS === 'web') {
            const manipResult = await resizeImage({ path: result.uri })

            console.log('manipResult, ', manipResult)
            uri = manipResult.uri
        }

        uriToBlob(uri).then(blob => {
            storage().ref('children/' + childId + '.png').put(blob)
                .then((res) => {
                    return res
                })
        });
        // this.setState({ image: uri });
        AsyncStorage.setItem('avatar', uri)
        dispatch(authActions.saveAvatar(parentId, childId, 5, uri))

    }
};


