import { LayoutAnimation } from "react-native";
import { tooltipRef } from "../../index";
import Colors from "../../constants/Colors";

const indent = 45;

export function callTooltip({ref, text, timeout = 3000 }) {
    ref.current.measure((x, y, width, height, pageX, pageY) => {
        // console.log('x, y, width, height - ', x, y, width, height, pageX, pageY);
       LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        tooltipRef.current.setNativeProps({
            text,
            style: {
                top: pageY - indent,
                left: pageX,
                backgroundColor: Colors.green1,
                opacity: 1,
            }
        })

        setTimeout(() => {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            tooltipRef.current.setNativeProps({
                text,
                style: {
                    top: -100,
                    backgroundColor: 'transparent',
                    // right: 0,
                    // backgroundColor: 'red',
                    // opacity: 0,
                }
            })
        }, timeout);
    });
}