/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet, TextInput, Platform } from 'react-native';

import Colors from '../../constants/Colors';

export const AuthTextInput = ({
    value,
    onSubmitEditing,
    onChangeText,
    placeholder,
    inputRef,
    secureTextEntry,
    keyboardType,
}) => {
    return (
        <TextInput
            value={value}
            onSubmitEditing={onSubmitEditing}
            ref={inputRef}
            secureTextEntry={secureTextEntry || false}
            keyboardType={keyboardType || 'default'}
            onChangeText={(text) => onChangeText(text)}
            style={styles.textInput}
            multiline={false}
            placeholder={placeholder}
        />
    );
};

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    textInput: {
        color: Colors.gray1,
        ...(Platform.OS === 'web' ? { outlineWidth: 0 } : {}),
        borderRadius: 20,
        borderWidth: 3,
        borderColor: Colors.gray,
        backgroundColor: 'white',
        height: 40,
        width: '90%',
        fontFamily: 'Carmen Sans Regular',
        paddingLeft: 10,
        justifyContent: 'center',
        marginVertical: 10,
    },
});
