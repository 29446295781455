/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    ActivityIndicator,
    StyleSheet,
    ScrollView,
    TextInput,
    View,
    Platform,
} from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import * as WebBrowser from 'expo-web-browser';

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput';
import Text from '../../../components/CustomText';

import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper';
import {
    Button,
    InfoButton,
    InfoButtonControlled,
} from '../../../components/newChild/Buttons';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { allowanceInfo, allowanceTable } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';
import { InputRow } from '../../../components/settings/rows/InputRow';
import { PickerRow } from '../../../components/settings/rows/PickerRow';
import { NUMERIC_KEYBOARD_TYPE } from '../../../constants/constants';
import { NEW_CHILD_SCREENS } from '../../../constants/data';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

class Allowance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            day: 'Friday',
            frequency: 'Weekly',
            amount: '10',
            year: 10,
            regular: true,
            amountError: false,
        };
    }

    componentDidMount() {
        const { newKid } = this.props.parent;
        const allowance = newKid.allowance;

        newKid.profile.age
            ? this.setState({
                  year: newKid.profile.age,
                  amount: newKid.profile.age,
              })
            : null;

        this.setState({
            ...allowance,
            // frequency: salaryFreqsReverse[allowance.frequency],
            // day: salaryFreqsReverse[allowance.day]
        });

        // for (let i in allowance){
        //     console.log('i - ', i, allowance[i])
        //     this.setState({[i]:i==='amount'?parseFloat(allowance[i]):allowance[i]})
        // }
    }

    formatAmount(text) {
        return text.replace(/([^(\d|.)])/g, '');
    }

    changeDay(text) {
        console.log('text', text);

        if (text === 'Monthly') {
            console.log('MONTHLY');

            return this.setState({ day: 'End of the month' });
        }

        if (text !== 'none') {
            return this.setState({ day: 'Friday' });
        }
    }

    checkConditions() {
        const { amount } = this.state;

        return 0;
    }

    proceed() {
        const { amount, frequency, day, regular } = this.state;
        const { expense } = this.props.parent.newKid;

        // this.props.kidData({ type: 'allowance', data: { amount, frequency, day: salaryDays[day], regular } });
        this.props.kidData({
            type: 'allowance',
            data: { amount, frequency, day, regular },
        });

        this.props.kidData({
            type: 'expense',
            data: { ...expense, frequency, day },
        });

        this.props.proceed();
    }

    back() {
        const { amount, frequency, day, regular } = this.state;

        this.props.kidData({
            type: 'allowance',
            data: { amount, frequency, day, regular },
        });

        this.props.back();
    }

    render() {
        const { layout } = this.props;
        const { amount, frequency, day } = this.state;

        const title = NEW_CHILD_SCREENS.ALLOWANCE;
        const description = `Now, let's set up allowance. The amounts suggested below are based on your information and what other parents do. Feel free to change it to your liking!`;

        let amountText = `The amount of recurring allowance for your child. You can always change it later. For your reference, an average family pays approximately 50 cents to $1 per week for each year of a child\'s age. For example, a ${
            this.state.year
        }-year-old would get $${this.state.year * 0.5}-$${
            this.state.year
        } per week. Refer to this table or blog post for more detail.`;

        amountText = amountText.split(' ').map((item) =>
            item === 'this' ? (
                <TouchableNativeFeedback
                    onPress={() => {
                        this.setState({
                            amountVisible: false,
                            tableVisible: true,
                        });
                    }}
                >
                    <Text>
                        <Text style={{ textDecorationLine: 'underline' }}>
                            this
                        </Text>{' '}
                    </Text>
                </TouchableNativeFeedback>
            ) : (
                <Text>{item} </Text>
            )
        );

        return (
            <BlockWrapper
                layout={layout}
                description={description}
                title={title}
                showProgress={true}
            >
                {/* <View> */}
                {(props) => (
                    <>
                        <Modal
                            animationIn={'zoomIn'}
                            animationOut={'zoomOut'}
                            animationInTiming={300}
                            animationOutTiming={300}
                            backdropColor={'darkgrey'}
                            backdropOpacity={0.1}
                            onBackdropPress={() =>
                                this.setState({ tableVisible: false })
                            }
                            isVisible={this.state.tableVisible}
                            onRequestClose={() =>
                                this.setState({ tableVisible: false })
                            }
                        >
                            <View
                                elevation={5}
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: 'white',
                                    width: '80%',
                                    alignSelf: 'center',
                                    alignItems: 'center',
                                    maxWidth: 400,
                                    minHeight: 100,
                                    paddingVertical: 15,
                                    paddingHorizontal: 20,
                                    justifyContent: 'center',
                                    boxShadow: '2px 2px 15px #272827',
                                }}
                            >
                                <View>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {'Weekly allowance ranges for kids aged 4 to 18, according to'
                                            .split(' ')
                                            .map((item, index) => (
                                                <Text key={`${item}_${index}`}>
                                                    {item}{' '}
                                                </Text>
                                            ))}
                                        <TouchableNativeFeedback
                                            onPress={() => {
                                                // Linking.openURL('https://www.cpacanada.ca/en/the-cpa-profession/financial-literacy/blog/2016/october/market-rates-on-kids-allowances')
                                                WebBrowser.openBrowserAsync(
                                                    'https://www.cpacanada.ca/en/the-cpa-profession/financial-literacy/blog/2016/october/market-rates-on-kids-allowances'
                                                );
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: 'blue',
                                                    textDecorationLine:
                                                        'underline',
                                                }}
                                            >
                                                CPA Canada:
                                            </Text>
                                        </TouchableNativeFeedback>
                                    </View>
                                    <View
                                        style={{
                                            alignSelf: 'center',
                                            marginTop: 20,
                                        }}
                                    >
                                        {allowanceTable.map((item, index) => (
                                            <Text key={`${item}_${index}`}>
                                                {item}
                                            </Text>
                                        ))}
                                    </View>
                                </View>
                                <TouchableNativeFeedback
                                    onPress={() =>
                                        this.setState({ tableVisible: false })
                                    }
                                >
                                    <View
                                        style={{
                                            backgroundColor: Colors.green1,
                                            borderRadius: 5,
                                            marginVertical: 10,
                                            paddingHorizontal: 20,
                                            paddingVertical: 10,
                                        }}
                                    >
                                        <Text
                                            style={[
                                                { color: 'white' },
                                                defaultStyles.bold,
                                            ]}
                                        >
                                            Got it
                                        </Text>
                                    </View>
                                </TouchableNativeFeedback>
                            </View>
                        </Modal>

                        <View
                            style={{
                                marginVertical: 20,
                                padding: 20,
                                borderRadius: 10,
                                maxWidth: 400,
                                paddingHorizontal: 15,
                                width: '90%',
                                alignItems: 'center',
                                alignSelf: 'center',
                            }}
                        >
                            {this.state.regular ? null : (
                                <View
                                    style={{
                                        zIndex: 3,
                                        // backgroundColor:'rgba(128,128,128,0.5)',
                                        left: -30,
                                        opacity: 0.5,
                                        position: 'absolute',
                                        height: '100%',
                                        width: '120%',
                                    }}
                                />
                            )}

                            <InputRow
                                text={this.state.amount}
                                onChangeText={(text) =>
                                    this.setState({
                                        amount: this.formatAmount(text),
                                        amountError: false,
                                    })
                                }
                                error={this.state.amountError}
                                inputParams={{
                                    additionalSign: '$',
                                    keyboardType: NUMERIC_KEYBOARD_TYPE,
                                }}
                                label={'Allowance amount *'}
                                infoButtonText={allowanceInfo.amount.text}
                            />

                            <PickerRow
                                value={this.state.frequency}
                                onPress={(text) => {
                                    this.changeDay(text);
                                    this.setState({ frequency: text });
                                }}
                                categories={['Weekly', 'Bi-weekly', 'Monthly']}
                                infoButtonText={allowanceInfo.frequency.text}
                                title={'Frequency *'}
                                // containerStyle={{ marginTop: 10 }}
                            />

                            <PickerRow
                                value={this.state.day}
                                onPress={(text) => this.setState({ day: text })}
                                categories={
                                    frequency === 'Monthly'
                                        ? [
                                              '1st of the month',
                                              'End of the month',
                                          ]
                                        : [
                                              'Monday',
                                              'Tuesday',
                                              'Wednesday',
                                              'Thursday',
                                              'Friday',
                                              'Saturday',
                                              'Sunday',
                                          ]
                                }
                                infoButtonText={allowanceInfo.frequency.text}
                                title={'Day of the week to pay allowance *'}
                            />
                        </View>
                        <View
                            style={{
                                width: '100%',
                                maxWidth: 381,
                                paddingHorizontal: 35,
                                flexDirection: 'row',
                            }}
                        >
                            <Button
                                title={'Back'}
                                onPress={() => this.back()}
                            />
                            <Button
                                title={'Proceed'}
                                onPress={() =>
                                    this.state.amount || !this.state.regular
                                        ? this.proceed()
                                        : this.setState({ amountError: true })
                                }
                            />
                        </View>
                    </>
                )}
                {/* </View> */}
            </BlockWrapper>
        );
    }
}

class LoginScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false,
        };
    }

    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 });
        }
        // kidClearAuth()
    }

    render() {
        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    return (
                        <View style={{ height: '100%', flex: 1 }}>
                            {this.state.loading ? (
                                <View
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        backgroundColor:
                                            'rgba(126, 193, 61, 0.5)',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <ActivityIndicator
                                        color={Colors.green1}
                                        size={'large'}
                                    />
                                </View>
                            ) : null}

                            <Allowance
                                layout={layout}
                                auth={this.props.auth}
                                parent={this.props.parent}
                                actions={this.props.actions}
                                navigation={this.props.navigation}
                                kidData={this.props.actions.kidData}
                                back={
                                    () =>
                                        this.props.navigation.navigate(
                                            'Profile'
                                        )
                                    // this.props.navigation.goBack()
                                }
                                // proceed={() => this.setState({ section: this.state.section })}
                                proceed={() =>
                                    this.props.navigation.navigate('Expense')
                                }
                                startLoading={() =>
                                    this.setState({ loading: true })
                                }
                                stopLoading={() =>
                                    this.setState({ loading: false })
                                }
                            />
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...kidAuthActions },
            dispatch
        ),
    })
)(LoginScreen);
