
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import config from '../../constants/config';

var firestore = firebase.firestore();
var storage = firebase.storage();

export async function requestAcademyVideos(email) {
    return;
    const value = await firestore.collection().get()

    console.log('is email valid = ', value.val());

    return !value.val();
}

export async function requestAcademyQuizzes(email) {

}

export async function requestAcademyArticles(email) {

}

export async function requestAcademyArticle(id) {

    const url = await storage.ref('academy/Articles/' + id).getDownloadURL()

    const resp = await fetch(url);
    console.log('resp fetch - ', resp)

    return await resp.text();
}

export async function sendStudendEmailsForRegistration({emails}) {

    const body = { emails };

    const resp = await fetch(`${config.url}removeExpense`, {
        method: 'POST',
        body: JSON.stringify(body),
    });

    return await resp;
}
