/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    ActivityIndicator,
    StyleSheet,
    ScrollView,
    View,
    Platform,
} from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Resizable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
import Text from '../../../components/CustomText';
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper';
import {
    Button,
    InfoButton,
    InfoButtonControlled,
} from '../../../components/newChild/Buttons';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { allowanceInfo, allowanceTable } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';
import { Loader } from '../../../components/Loader';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const database = firebase.database;
const storage = firebase.storage;

var re = /^[0-9]\. /;

const CheckboxRow = ({ onPress, selected, text }) => {
    return (
        <TouchableNativeFeedback style={rowStyles.container} onPress={onPress}>
            <View style={rowStyles.iconContainer}>
                <Icon
                    style={{
                        zIndex: -1,
                        opacity: selected ? 0 : 1,
                    }}
                    name={'check'}
                    size={16}
                    color={Colors.green1}
                />
            </View>
            {/* <View style={{ borderWidth: 3, borderRadius: 5 }}><Text> ? null : '1'}</Text></View> */}
            <View style={rowStyles.textContainer}>
                <Text style={[rowStyles.text, defaultStyles.bold]}>{text}</Text>
            </View>
        </TouchableNativeFeedback>
    );
};

const rowStyles = StyleSheet.create({
    container: {
        zIndex: -1,
        marginLeft: 0,
        marginTop: 10,
        width: '100%',
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center',
    },
    iconContainer: {
        borderRadius: 10,
        height: 30,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: Colors.green1,
        borderWidth: 3,
    },
    textContainer: { marginLeft: 10, width: '90%' },
    text: {
        color: Colors.green1,
        width: '90%',
    },
});

class Allowance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            day: 'Friday',
            frequency: 'Weekly',
            amount: 10,
            year: 10,
            regular: true,
            loading: true,
            termsLoading: true,
            text: '',
            terms: '',
        };
    }

    componentDidMount() {
        storage()
            .ref('legals/PrivacyPolicy.txt')
            .getDownloadURL()
            .then((privacyPolicy) => fetch(privacyPolicy))
            .then((res) => res.text())
            .then((res) => {
                console.log(res);
                this.setState({ loading: false, text: res });
            })
            .catch((err) => console.log('err'));

        storage()
            .ref('legals/TermsAndConditions.txt')
            .getDownloadURL()
            .then((privacyPolicy) => fetch(privacyPolicy))
            .then((res) => res.text())
            .then((res) => {
                console.log(res);
                this.setState({ termsLoading: false, terms: res });
            })
            .catch((err) => console.log('err'));
    }

    proceed() {
        this.props.proceed();
    }

    back() {
        this.props.back();
    }

    renderContractBlock({ layout, loading, title, text }) {
        if (loading) {
            return null;
        }

        return (
            <View style={{ height: layout.height * 0.4 }}>
                <Text style={styles.title}>{title}</Text>
                <ScrollView
                    nestedScrollEnabled={true}
                    style={styles.contractScroll}
                    contentContainerStyle={{}}
                >
                    <View>
                        {text.split('\n').map((item, index) => (
                            <Text
                                key={`${item}_${index}`}
                                style={{
                                    fontFamily: `${re.test(
                                        item
                                    )} ? 'Carmen Sans Bold'
                                    : 'Carmen Sans Regular'`,
                                }}
                            >
                                {item}
                            </Text>
                        ))}
                        {/* {this.state.text.split('\n').map(item=>item).join('\n')} */}
                    </View>
                </ScrollView>
            </View>
        );
    }

    contractLoading({ loading, layout }) {
        const style = {
            height: layout.height * 0.4,
            justifyContent: 'center',
        };

        if (!loading) {
            return null;
        }

        return (
            <View style={style}>
                <ActivityIndicator size={'large'} color={Colors.green1} />
            </View>
        );
    }

    render() {
        const { layout } = this.props;

        const title = 'Privacy and Terms and Conditions';
        const description = (
            <Text
                style={{
                    textAlign: 'center',
                    marginVertical: 5,
                    color: Colors.gray1,
                }}
            >
                By signing up for Pennygem, you accept our Privacy Policy and Terms and Conditions
            </Text>
        );

        return (
            <BlockWrapper
                layout={layout}
                description={description}
                title={title}
                hideCloseIcon={true}
            >
                {/* <View> */}
                {(props) => (
                    <>
                        <View style={styles.contractsContainer}>
                            {this.contractLoading({
                                loading: this.state.termsLoading && this.state.loading,
                                layout,
                            })}
                            {this.renderContractBlock({
                                layout,
                                title: 'Terms And Conditions',
                                loading: this.state.termsLoading,
                                text: this.state.terms,
                            })}

                            {/* {this.contractLoading({
                                loading: this.state.loading,
                                layout,
                            })} */}
                            {this.renderContractBlock({
                                layout,
                                title: 'Privacy Policy',
                                loading: this.state.loading,
                                text: this.state.text,
                            })}

                            <CheckboxRow
                                selected={this.state.regular}
                                onPress={() => this.setState({regular: !this.state.regular})}
                                text={
                                    'I have read, understood, and agree to the Privacy Policy and Terms and Conditions'
                                }
                            />
                        </View>
                        <View
                            style={{
                                width: '50%',
                                zIndex: -1,
                                justifyContent: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            {/* <Button title={'Back'} onPress={() => this.back()}></Button> */}
                            <Button
                                disabled={this.state.regular}
                                title={'Proceed'}
                                onPress={() =>
                                    this.state.amount || !this.state.regular
                                        ? this.proceed()
                                        : null
                                }
                            />
                        </View>
                    </>
                )}
                {/* </View> */}
            </BlockWrapper>
        );
    }
}

class LoginScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    return (
                        <View style={{ height: '100%', flex: 1 }}>
                            <Loader loading={this.state.loading} />

                            <Allowance
                                layout={layout}
                                auth={this.props.auth}
                                parent={this.props.parent}
                                actions={this.props.actions}
                                navigation={this.props.navigation}
                                kidData={this.props.actions.kidData}
                                back={() =>
                                    this.props.navigation.navigate(
                                        'ParentSetup'
                                    )
                                }
                                // proceed={() => this.setState({ section: this.state.section })}
                                proceed={() =>
                                    this.props.navigation.navigate('Profile')
                                }
                                startLoading={() =>
                                    this.setState({ loading: true })
                                }
                                stopLoading={() =>
                                    this.setState({ loading: false })
                                }
                            />
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    contractScroll: {
        paddingVertical: 20,
        overflow: 'hidden',
        paddingHorizontal: 10,
        borderColor: Colors.gray,
        borderRadius: 10,
        borderWidth: 1,
        flex: 1,
        margin: 2,
        // marginBottom: 20,
    },
    contractsContainer: {
        marginVertical: 20,
        paddingHorizontal: 20,
        borderRadius: 10,
        maxWidth: 400,
    },
    title: { marginVertical: 10, paddingVertical: 10, ...defaultStyles.bold },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...kidAuthActions },
            dispatch
        ),
    })
)(LoginScreen);
