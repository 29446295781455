/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    ActivityIndicator,
    Animated,
    Platform,
    StyleSheet,
    View,
    ScrollView,
    Image,
} from 'react-native';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';
import { Button } from '../../components/Buttons';

import { InputRow } from '../../components/settings/rows/InputRow';
import { PickerRow } from '../../components/settings/rows/PickerRow';

import Colors from '../../constants/Colors';
import { allowanceInfo, expenseInfo } from '../../constants/info';

import globalStyle, { defaultStyles } from '../../constants/style';
import {
    REGULAR_ACTIVITY_MONTH_DAYS,
    REGULAR_DAYS,
    REGULAR_FREQS,
    REGULAR_FREQS_REVERSE,
    WEEKDAYS,
} from '../../constants/constants';
import { Loader } from '../Loader';
import { formatNumberFloat } from '../../helpers/formatNumber';
import { InputWithVariationButtons } from '../Input/InputWithVariationButtons';
import { ItemSelectRow } from '../Select/ItemSelectRow';
import { RegularInput } from '../RegularInput/RegularInput';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

function firstLetterUpperCase(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function formatInputValue(str) {
    return str || str === 0
        ? str
            .toString()
            .replace(/[^\d\.]/g, '')
            .replace(/\./, 'x')
            .replace(/\./g, '')
            .replace(/x/, '.')
        : '';
}

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

function defaultDay(freq) {
    if (freq === 1 || freq === 'Monthly') {
        return '1st of the month';
    }

    return 'Friday';
}

const weekdays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
};

function salaryDay(freq, day) {
    if (freq === 1 || freq === 'Monthly') {
        return day ? 'End of the month' : '1st of the month';
    }

    return weekdays[day];
}

function ExpenseTable({ expenses, removeExpense }) {
    console.log('expenses ', expenses);

    function row({ key, title, amount, frequency, header }) {
        console.log('title,amount, frequency} ', title, amount, frequency);

        return (
            <View
                key={key}
                style={{
                    paddingVertical: 5,
                    paddingTop: header ? 10 : 5,
                    borderTopWidth: header ? 0 : 1,
                    flex: 1,
                    borderColor: `${Colors.gray1}CC`,
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <View style={{ flex: 1, paddingLeft: header ? 10 : 10 }}>
                    <Text numberOfLines={1} style={{ fontSize: 14 }}>
                        {title}
                    </Text>
                </View>
                <View style={{ flex: 1, paddingLeft: header ? 10 : 10 }}>
                    <Text style={{ fontSize: 14 }}>
                        {!header && '$'}
                        {amount}
                    </Text>
                </View>
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text style={{ fontSize: 14 }}>
                        {header
                            ? 'Frequency'
                            : REGULAR_FREQS_REVERSE[frequency]}
                    </Text>
                    {
                        header ? null : (
                            <TouchableNativeFeedback
                                style={{
                                    position: 'absolute',
                                    right: -7,
                                    top: 3,
                                }}
                                onPress={() => {
                                    console.log('sdfsdf');
                                    removeExpense(key);
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 10,
                                        width: 15,
                                        opacity: 0.7,
                                        backgroundColor: Colors.red,
                                        height: 15,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Image
                                        source={require('assets/Delete-X.png')}
                                        style={{
                                            width: 8,
                                            height: 8,
                                            tintColor: 'white',
                                        }}
                                    />
                                </View>
                            </TouchableNativeFeedback>
                        )
                        // </View>
                    }
                </View>
            </View>
        );
    }

    if (!expenses || !Object.keys(expenses).length) {
        return (
            <View>
                <Text>No expenses</Text>
            </View>
        );
    }

    return (
        <View
            style={{
                paddingHorizontal: 5,
                paddingRight: 10,
                borderWidth: 2,
                borderRadius: 10,
                width: '100%',
                marginTop: 20,
                borderColor: `${Colors.gray1}CC`,
            }}
        >
            {row({
                title: 'Title',
                amount: 'Amount',
                frequency: 'Frequency',
                header: true,
            })}
            {expenses &&
                Object.keys(expenses).map((item) => {
                    const expense = expenses[item];

                    console.log('item', item);

                    return expense.info && row({ key: item, ...expense.info });
                })}
        </View>
    );
}

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
            isFocused: false,
            hasCameraPermission: null,
            //  image: '0',
            image: null,
            emailChange: false,
            nameChange: false,
            oldPassword: '',
            password: '',
            confirmPassword: '',
            id: 0,
            allowanceStart: new Date().yyyymmdd(),

            expence_title: '',
            expence_amount: 0,
            expence_frequency: 'Weekly',
            expense_day: 'Friday',
            expense_title_error: false,
            expense_amount_error: false,

            expense_opacity: new Animated.Value(0),

            // expenseVisible: true,

            allowance_amount_error: false,
            ...Object.keys(expenseInfo).reduce((prev, curr, currIndex, arr) => {
                return { ...prev, [curr]: '' };
            }, {}),
            ...Object.keys(allowanceInfo).reduce(
                (prev, curr, currIndex, arr) => {
                    return { ...prev, [curr]: '' };
                },
                {}
            ),

            allowanceChanges: new Animated.Value(0),
            //  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
            // type: Camera.Constants.Type.back,
        };

        this.onCreateExpensePress = this.onCreateExpensePress.bind(this);
        this.onSaveAllowancePress = this.onSaveAllowancePress.bind(this);
    }

    componentDidMount() {
        const { parentId } = this.props.auth;
        const { childIds } = this.props.parent;
        const { childId } = this.props;

        this.props.actions
            .getSettings(parentId, childId)
            .then((settings) => {
                console.log('settings!!', settings);
                this.setState({
                    ...settings,
                    salary_day: settings.salary_day
                        ? salaryDay(settings.salary_freq, settings.salary_day)
                        : defaultDay(settings.salary_freq),
                    current_salary_amt: settings.salary_amt,
                    current_salary_freq: settings.salary_freq,
                    current_salary_day: settings.salary_day
                        ? salaryDay(settings.salary_freq, settings.salary_day)
                        : defaultDay(settings.salary_freq),
                    isLoading: false,
                });
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.childId !== prevProps.childId) {
            const { parentId } = this.props.auth;
            const { childIds } = this.props.parent;
            const { childId } = this.props;

            this.setState({ isLoading: true });
            this.props.actions
                .getSettings(parentId, childId)
                .then((settings) => {
                    console.log('settings!!', settings);
                    this.setState({
                        ...settings,
                        salary_day:
                            salaryDay(
                                settings.salary_freq,
                                settings.salary_day
                            ) || defaultDay(settings.salary_freq),
                        isLoading: false,
                    });
                });
        }
    }

    changeDay(freq) {
        if (freq === 'Monthly') {
            return this.setState({ expense_day: '1st of the month' });
        }

        return this.setState({ expense_day: 'Friday' });
    }

    // rgba(126, 193, 61, 0.1)

    expence_row({ info, key }) {
        return info ? (
            <View
                key={key}
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    marginVertical: 10,
                    justifyContent: 'space-around',
                }}
            >
                <Text>Title: {info.title}</Text>
                <Text>Amount: ${info.amount}</Text>
                <Text>Frequency: {REGULAR_FREQS_REVERSE[info.frequency]}</Text>
            </View>
        ) : null;
    }

    onSaveAllowancePress() {
        const { id } = this.props;
        const { parentId } = this.props.auth;
        const { childIds, names, isLoading } = this.props.parent;
        const { childId } = this.props;

        if (parseInt(this.state.salary_amt.toString().replace(/\D/g, ''), 10)) {
            // if () {
            //     this.props.actions.updateAllowance(parentId, childIds[id], {
            //         salary_amt: this.state.salary_amt,
            //         salary_freq: this.state.salary_freq,
            //         allowanceStart: this.state.allowanceStart,
            //     });
            // }
            if (this.allowanceChanged()) {
                this.setState({ isLoading: true });
                this.props.actions.sendAllowanceJob(parentId, childId, {
                        salary_amt: this.state.salary_amt,
                        salary_freq: this.state.salary_freq,
                        salary_day: this.state.salary_day,
                    })
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            current_salary_amt: this.state.salary_amt,
                            current_salary_freq: this.state.salary_freq,
                            current_salary_day: this.state.salary_day,
                        });

                        Animated.timing(this.state.allowanceChanges, {
                            duration: 200,
                            toValue: 1,
                            useNativeDriver: true,
                        }).start(() => {
                            Animated.timing(this.state.allowanceChanges, {
                                duration: 200,
                                toValue: 0,
                                delay: 2000,
                                useNativeDriver: true,
                            }).start();
                        });
                    });
            }
        } else {
            this.setState({
                allowance_amount_error: true,
            });

            console.log(111);
        }
    }

    onCreateExpensePress() {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;
        const { childId } = this.props;
        const {
            expence_amount,
            expence_title,
            expence_frequency,
            expense_day,
        } = this.state;

        console.log(
            'expence_amount && parseInt(expence_amount) ',
            expence_amount,
            parseInt(expence_amount)
        );

        this.setState({ isLoading: true });
        expence_amount && formatNumberFloat(expence_amount) && expence_title
            ? this.props.actions
                .createExpence({
                    parentId,
                    childId: childId,
                    amount: expence_amount,
                    title: expence_title,
                    frequency: REGULAR_FREQS[expence_frequency],
                    day: REGULAR_DAYS[expense_day],
                })
                .then(() =>
                    this.props.actions.getSettings(parentId, childId)
                )
                .then((settings) => {
                    this.setState({
                        ...settings,
                        salary_day:
                            settings.salary_day ||
                            defaultDay(settings.salary_freq),
                        isLoading: false,
                    });
                })
            : this.setState({
                isLoading: false,
                expense_amount_error: formatNumberFloat(expence_amount)
                    ? false
                    : true,
                expense_title_error: this.state.expence_title ? false : true,
            });
    };

    allowanceChanged() {
        console.log("AAAAA - ", this.state.current_salary_freq, this.state.salary_freq)

        return (
            formatInputValue(this.state.current_salary_amt) !== formatInputValue(this.state.salary_amt) ||
            this.state.current_salary_freq !== this.state.salary_freq ||
            this.state.current_salary_day !== this.state.salary_day
        );
    }

    render() {
        let { layout, actions, id, childId } = this.props;
        const { allowance_amount_error } = this.state;

        const { parentId } = this.props.auth;
        const { childIds, names, isLoading } = this.props.parent;

        layout = layout?.layout;
        const vmin = Math.min(...[layout?.width, layout?.height]) || 100;

        // const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
        //     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}

        return (
            <View style={{ width: '90%', flex: 1, marginBottom: 150 }}>
                <Loader loading={this.state.isLoading} />
                <View
                    style={{
                        opacity: this.state.isLoading ? 0.5 : 1,
                        width: '100%',
                    }}
                >
                    <View
                        style={{
                            width: '100%',
                            alignItems: 'center',
                            marginTop: 10,
                        }}
                    >

                        {this.state.isLoading ? null : (
                            <ExpenseTable
                                expenses={this.state.expences}
                                removeExpense={(id) => {
                                    console.log('ID', id);
                                    actions.removeExpenseSchedule({
                                        parentId,
                                        childId,
                                        expenseId: id,
                                    });
                                }}
                            />
                        )}

                        {this.state.expenseVisible ? (
                            <Animated.View
                                style={{
                                    width: '100%',
                                    alignItems: 'center',
                                    opacity: this.state.expense_opacity,
                                }}
                            >
                                <View style={{ height: 30 }} />
                                <Text
                                    style={{
                                        ...defaultStyles.bold,
                                        color: Colors.gray0,
                                        marginVertical: 0.04 * vmin,
                                        ...globalStyle.h3,
                                    }}
                                >
                                    Add New Expense
                                </Text>

                                <RegularInput
                                    text={this.state.expence_title}
                                    onChangeText={(text) =>
                                        this.setState({
                                            expense_title_error: false,
                                            expence_title: text,
                                        })
                                    }
                                    error={this.state.expense_title_error}
                                    label={'Expense Title *'}
                                    // additionalSign
                                    placeholder={'e.g. 30 mins computer time'}
                                    labelStyle={{marginBottom: 20, marginTop: 50, ...defaultStyles.bold, ...globalStyle.page, color: '#282828'}}
                                    inputContainerStyle={{borderWidth: 0,}}
                                    containerStyle={{width: '100%', borderWidth: 0, }}
                                    inputStyle={{ backgroundColor: '#f1f1f1', width: '100%', borderRadius: 20, paddingLeft: 20 }}
                                    //
                                    infoButtonText={expenseInfo.title.text}
                                />

<View
                        style={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // marginTop: 10,
                        }}
                    >

                    <InputWithVariationButtons
                        title={'Amount'}
                        additionalSign={'$'}
                        value={formatNumberFloat(this.state.expence_amount || 0)}
                        onChange={text=> this.setState({
                            expense_amount_error: false,
                            expence_amount: text,
                        })}
                        onIncrease={(text) => {
                            this.setState({
                                expense_amount_error: false,
                                expence_amount: parseFloat(this.state.expence_amount || 0) + 1,
                            })
                        }}
                        onDecrease={() => {this.state.salary_amt>0 ?
                            this.setState({
                                expense_amount_error: false,
                                expence_amount: parseFloat(this.state.expence_amount || 0) - 1,
                            }): null}}
                    />
                        <ItemSelectRow
                            items={['Weekly', 'Bi-weekly', 'Monthly']}
                            title={"Frequency"}
                            selectedItem={this.state.expence_frequency}
                            onItemPress={(text) => () => {
                                this.setState({
                                    expense_day:
                                        text === 'Monthly'
                                            ? '1st day of the month'
                                            : 'Friday',
                                            expence_frequency: text,
                                });
                            }} // (item, index) => (() => null)
                            itemTextStyle={{...globalStyle.page, marginVertical: 3}}
                        />

                        <ItemSelectRow
                            items={this.state.expence_frequency === 'Monthly'
                                ? REGULAR_ACTIVITY_MONTH_DAYS
                                : WEEKDAYS}
                            title={this.state.expence_frequency === 'Monthly' ? 'Pay Week' : 'Pay Day'}
                            selectedItem={this.state.expense_day}
                            parseText={(text) => this.state.expence_frequency === 'Monthly' ? text : firstLetterUpperCase(text.slice(0,3))}
                            onItemPress={(text) => () => this.setState({ expense_day: text })} // (item, index) => (() => null)
                            itemsContainerStyle={this.state.expence_frequency === 'Monthly' ? {flexDirection: 'column', alignItems: 'center'} : null}
                            itemStyle={this.state.expence_frequency === 'Monthly' ? {marginLeft: 0, width: '80%',  marginBottom: 10} : {}}
                            itemTextStyle={this.state.expence_frequency === 'Monthly' ? {...globalStyle.page, marginVertical: 3 } : {}}
                        />
                    </View>

                    <View
                        style={{
                            zIndex: -11,
                            width: '100%',
                            alignSelf: 'center',
                        }}
                    >
                        <Button
                            styles={{
                                ...defaultStyles.shadow,
                                width: '100%',
                                marginVertical: vmin * 0.1,
                                backgroundColor: Colors.white,
                                alignSelf: 'flex-end',
                                // marginVertical: 10,
                                // opacity: this.allowanceChanged() ? 1 : 0.5,
                            }}
                            textStyle={{ ...globalStyle.h3, ...defaultStyles.bold, marginVertical: 15, color: Colors.green1 }}
                            title={'Save Changes'}
                            onPress={this.onCreateExpensePress}
                        />
                    </View>

                            </Animated.View>
                        ) : (
                            <TouchableNativeFeedback
                                // style={{borderWidth: 1,}}
                                onPress={() => {
                                    this.setState({ expenseVisible: true });
                                    Animated.timing(
                                        this.state.expense_opacity,
                                        {
                                            toValue: 1,
                                            duration: 1000,
                                        }
                                    ).start();
                                }}
                            >
                                <View
                                    style={{
                                        zIndex: -1,
                                        marginLeft: -20,
                                        width: '100%',
                                        flexDirection: 'row',
                                        marginVertical: 30,
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            borderRadius: 10,
                                            height: 30,
                                            width: 30,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderColor: Colors.green1,
                                        }}
                                    >
                                        {
                                            <Text
                                                style={{
                                                    ...defaultStyles.bold,
                                                    paddingTop: 3,
                                                    justifyContent: 'center',
                                                    fontSize: 30,
                                                    color: Colors.green1,
                                                }}
                                            >
                                                +
                                            </Text>
                                        }
                                    </View>
                                    <View style={{ marginLeft: 10 }}>
                                        <Text
                                            style={[
                                                { color: Colors.green1 },
                                                defaultStyles.bold,
                                            ]}
                                        >
                                            Add another expense
                                        </Text>
                                    </View>
                                </View>
                            </TouchableNativeFeedback>
                        )}
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({});

export default Settings;

// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
