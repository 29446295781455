/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Platform, StyleSheet, View, ScrollView, TouchableOpacity } from 'react-native';

import {Calendar} from 'react-native-calendars';

import moment from 'moment';
import 'firebase/database';

import Text from '../CustomText';
import TouchableNativeFeedback from '../TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';
import { IS_ANDROID, IS_IOS, IS_WEB } from '../../constants/constants';
import ModalContainer from '../Modal/Modal';
import { useSelector } from 'react-redux';
import { InputImitation } from '../Input/InputImitation';
import { InputWithVariationButtons } from '../Input/InputWithVariationButtons';
import { FONTS } from '../../constants/fonts';
import { Input } from '../Input/Input';
import { Avatar } from '../Avatar';
import { formatNumberFloat } from '../../helpers/formatNumber';


const jobFreqs = { None: 0, Daily: 1, Weekly: 2, 'Bi-weekly': 3, Monthly: 4 };

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

function ItemSelectRow({
    items,
    title,
    selectedItem,
    containerStyle,
    itemsContainerStyle,
    titleStyle,
    itemStyle,
    itemTextStyle,
    isSelected = (item) => item === selectedItem, // (item, index) => boolean
    onItemPress, // (item, index) => (() => null)
    parseText = item => item, // (item, index) => text
}) {
    return (
    <>
        <View style={[styles.container, containerStyle]}>
            {title ? <Text style={[styles.title, titleStyle]}>{title}</Text> : null}
            <View style={[styles.itemsContainer, itemsContainerStyle]}>
                {items.map((item, index) => <TouchableOpacity
                    key={item}
                    onPress={onItemPress(item)}
                    style={[styles.item,
                        // ...{flex: 1, borderRadius: 20, alignItems: 'center'},
                        {backgroundColor: isSelected(item, index) ? Colors.green1 : '#e4e4e4',
                        marginLeft: index !== 0 ? 5 : 0, paddingVertical: 5,},
                        itemStyle,
                ]}>
                    <Text style={[
                        styles.itemText,
                        {color: isSelected(item, index) ? Colors.white : Colors.black },
                        itemTextStyle
                    ]}>
                        {parseText(item)}
                    </Text>
                </TouchableOpacity>)}
            </View>
        </View>
    </>);
}

const styles = StyleSheet.create({
    container: { width: '100%'},
    itemsContainer: { flexDirection: 'row', width: '100%' },
    title: {
        ...globalStyle.h4,
        fontSize: 17,
        fontFamily: FONTS.FONT_BOLD,
        marginTop: 50,
        marginBottom: 20,
    },
    item: {
        flex: 1, borderRadius: 20, alignItems: 'center',
    },
    itemText: {
        fontSize: 10,
        // fontWeight: '500',
        ...defaultStyles.bold,
        paddingVertical: 3.5,
    }
});

export { ItemSelectRow };
