/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

 import React, { useState } from 'react';
 import {
     ActivityIndicator,
     Image,
     ImageBackground,
     KeyboardAvoidingView,
     Platform,
     SafeAreaView,
     TouchableOpacity,
     // Platform,
     TouchableWithoutFeedback,
     StyleSheet,
     ScrollView,
     View,
     StatusBar,
 } from 'react-native';

 import {
     useDimensions,
     useActive,
     useFocus,
     useHover,
     useREM,
     useScaledSize,
     Hoverable, Resizable
 } from 'react-native-web-hooks';

 import { connect } from "react-redux";
 import { bindActionCreators } from 'redux';

 import firebase from 'firebase/app';
 import 'firebase/auth';
 import 'firebase/database';
 // import 'firebase/analytics';
 import * as Analytics from 'expo-firebase-analytics';


 import TouchableNativeFeedback from '../../components/TouchableFeedback'
 import FloatingLabelInput from '../../components/FloatingLabelInput/FloatingLabelInput'
 import Text from '../../components/CustomText'
 import { Button } from '../../components/Buttons'
 import ParentComponentHeader from '../../components/ComponentHeader'
 import { ConfirmationView, RejectionView } from '../../components/confirmation'
 import { DropdownElement } from '../../components/Dropdown'

 import MainSettings from '../../components/settings/MainChildSettings'
 import GeneralSettings from '../../components/settings/GeneralChildSettings'
 import AdditionalSettings from '../../components/settings/AdditionalChildSettings'
 import OtherSettings from '../../components/settings/OtherChildSettings'
 import SettingsHeader from '../../components/settings/ChildSettingsHeader'
 import ParentSettings from '../../components/settings/ParentSettings'
 import AddChildSettings from '../../components/settings/AddChildSettings'

 import * as chequingActions from '../../store/actions/parents/chequingActions'
 import * as authActions from '../../store/actions/parents/authActions'
 import Colors from '../../constants/Colors';
 import globalStyle, { defaultStyles } from '../../constants/style';
 import { eventLog } from '../../helpers/analytics/analytics';
import { SHOW_ACADEMY } from '../../constants/config';

 const auth = firebase.auth;
 const database = firebase.database;
 // const analytics = firebase.analytics;

 const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

 function emailIsValid(email) {
     return /\S+@\S+\.\S+/.test(email)
 }

 Date.prototype.yyyymmdd = function () {
     var mm = this.getMonth() + 1; // getMonth() is zero-based
     var dd = this.getDate();

     return [this.getFullYear(),
     (mm > 9 ? '' : '0') + mm,
     (dd > 9 ? '' : '0') + dd
     ].join('-');
 };

 const HeaderElement = ({ title, focused, onPress, layout }) => {
     const vmin = Math.min(layout.width, layout.height);
     return <TouchableNativeFeedback style={{ width: '100%', flex: 1, }} onPress={onPress}>
         <View style={{
             marginVertical: vmin * 0.013, boxShadow: focused ? '2px 2px 15px #272827' : 'none', padding: vmin * 0.015, borderRadius: 25,
             backgroundColor: focused ? Colors.green1 : 'white',
             borderWidth: 3, borderColor: Colors.gray, alignItems: 'center',
             // borderBottomColor:Colors.green1, borderBottomWidth:3
         }}>
             <Text style={{ textAlign: 'center', color: Colors.gray0 }}>{title}</Text>
         </View>
     </TouchableNativeFeedback>
 }

 const ManagementSettings = ({ layout, settings, id, actions, navigation, addNewParent, parentId }) => {
     const [email, setEmail] = useState('')
     const [name, setName] = useState('')
     const [loading, setLoading] = useState(false)
     const [success, setSuccess] = useState(false)
     const [modalVisible, setModalVisible] = useState(false)
     const [error, setError] = useState({ code: 0, message: 'Something went wrong' })

     function run() {
         setLoading(true);
         return addNewParent(name, email, parentId)
             .then(() => { setModalVisible(true); setSuccess(true); setLoading(false) })
             .catch((error => { setModalVisible(true); setError(error); setLoading(false); }))
     }

     function emptyFields() {
         setModalVisible(true);
         setError({ message: 'Please fill name and email' });
         setLoading(false)
     }
     return <View style={{ width: '100%', alignItems: 'center', }}>
         {<Modal
             animationIn={'zoomIn'} animationOut={'zoomOut'}
             animationInTiming={500} animationOutTiming={500}
             backdropColor={'darkgrey'} backdropOpacity={0.6}
             onBackdropPress={() => setModalVisible(false)}
             isVisible={modalVisible}
             onRequestClose={() => { setModalVisible(false) }}>
             <View style={{
                 borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                 maxWidth: 400, minHeight: 200, justifyContent: 'center', alignItems: 'center'
             }}>
                 {/* <View> */}
                 <Text style={{ fontSize: 20, textAlign: 'center' }}>{success ? 'Confirmation email has been sent' : error.message}</Text>
                 <TouchableNativeFeedback onPress={() => { setModalVisible(false); setSuccess(false) }}>
                     <View style={{ marginTop: 10, backgroundColor: Colors.green1, borderRadius: 10, padding: 10 }}>
                         <Text style={{ color: 'white' }}>OK</Text>
                     </View>
                 </TouchableNativeFeedback>
                 {/* </View> */}
             </View>
         </Modal>}
         {loading && <View style={{
             position: 'absolute', backgroundColor: 'rgba(126, 193, 61, 0.1)', height: '100%', zIndex: 5, width: '90%', borderRadius: 10,
             justifyContent: 'center',
         }}>
             <ActivityIndicator style={{ color: Colors.green1 }} size={'large'} color={Colors.green1} />
         </View>}
         <View stlye={{}}><Text style={{ fontSize: 22 }}>Add another parent!</Text></View>
         <View style={{ width: '100%', alignItems: 'center', flex: 1 }}>
             <FloatingLabelInput label={'Parent Name'} value={name}
                 onChangeText={text => setName(text)} />
             <FloatingLabelInput label={'Parent Email'} value={email} keyboardType={'email-address'}
                 onChangeText={text => setEmail(text)} />
             <TouchableNativeFeedback style={{ width: '80%', }}
                 onPress={() => {
                     setLoading(true); name && email ? run() : emptyFields()
                 }}>
                 <View style={{ marginVertical: 10, marginBottom: 50, borderRadius: 10, width: '100%', backgroundColor: Colors.green1, padding: 10, alignItems: 'center' }}>
                     <Text style={[{ textAlign: 'center', color: 'white', }, defaultStyles.bold]}>Submit</Text></View></TouchableNativeFeedback>
         </View>
     </View>
 }

 class Settings extends React.Component {
     static navigationOptions = ({ navigation }) => {
         return {
             // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
             headerTransparent: true,
             headerLeft: null,
             header: null
         }
     }

     constructor(props) {
         super(props);
         this.childIdRef = React.createRef()
         this.childAgeRef = React.createRef()
         this.state = {
             data: [],
             isLoading: true,
             avatars: [false, false, false, false, false, false],
             debitCards: [false, false, false, false],
             creditCards: [false, false, false, false],
             email: '',
             username: '',
             age: '',
             isFocused: false,
             hasCameraPermission: null,
             //  image: '0',
             image: null,
             emailChange: false,
             nameChange: false,
             oldPassword: '',
             password: '',
             confirmPassword: '',
             id: 0,
             allowanceStart: (new Date()).yyyymmdd(),
             focused: 0,
             category: -1,
             //  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
             // type: Camera.Constants.Type.back,
         };
     };


     async componentDidMount() {
         const { parentId, user } = this.props.auth;
         const { childIds, settings, id } = this.props.parent;


         eventLog({eventName: 'settings_screen', properties: {}})


         const category = this.props.navigation.getParam('category', null)
         if (category === 'child') {
             this.setState({ category: 0 })
         }

         this.props.actions.getSettings(parentId, childIds[id])
             .then(settings => { console.log('settings!!', settings); this.setState({ ...settings }) })
             .catch((error) => {
                 if (error.code === 'PERMISSION_DENIED') {
                     this.props.navigation.navigate('Auth')
                 }
                 console.log(error.code)
             })
         // this.setState
         // const currentUser = auth().currentUser
         console.log('currentUser -', user)
         let parentEmail = user.user.email
         let parentName = user.user.displayName
         this.setState({ parentName, parentEmail, old_parentname: parentName, old_parentemail: parentEmail })
         console.log('settings', settings)

         if (!this.props.auth.parentId) {
             this.props.actions.clearAuth()
                 .then(() => {
                     this.props.navigation.navigate('Auth')
                 })
         }
     }

     changeEmailTransaction() {
         const { parentId, } = this.props.auth;

         // let name_ref = database().ref('/children/'+childId);
         database().ref('/emails/').update({
             [parentId]: this.state.parentEmail
         });
         var user = auth().currentUser;
         console.log('this.state.parentEmail', this.state.parentEmail)
         user.updateEmail(this.state.parentEmail).then(() => {
             let userParam = { user: user }
             this.props.actions.login(userParam)
             console.log('is this even working, ', user)
         }).catch(function (error) {
             // An error happened.
             console.log('errror updating EMAIL, - ', error)
         });
         this.setState({ old_parentemail: this.state.parentEmail })

         this.props.actions.sendVerificationEmail(this.state.old_parentname, this.state.parentEmail)
         // auth().currentUser.updateEmail(this.state.email).then(() => { auth().currentUser.sendEmailVerification(); });
     }


     changeEmail() {
         // this.state.emailChange?this.setState({emailConfirmVisible:true}):null
         return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
             <Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Changing Email to</Text>
             <Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>{this.state.parentEmail}</Text>

             <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%', marginHorizontal: 15, }}>
                 <View style={{ width: '40%' }}>
                     <TouchableNativeFeedback onPress={() => { this.changeEmailTransaction(); this.setState({ emailChange: false }) }}>
                         <View style={{
                             justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                             margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                         }}>
                             <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
                     </TouchableNativeFeedback>
                 </View>
                 <View style={{ width: '40%', }}>
                     <TouchableNativeFeedback onPress={() => this.setState({ emailChange: false })}>
                         <View style={{
                             justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
                             backgroundColor: '#EE5060'
                         }}>
                             <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
                     </TouchableNativeFeedback>
                 </View>
             </View>
         </View>
     }

     changeNameTransaction() {
         const { parentId, childId } = this.props.auth;

         var user = auth().currentUser;

         user.updateProfile({
             displayName: this.state.parentName,
         }).then(() => {
             let userParam = { user: user }
             this.props.actions.login(userParam)

         }).catch(function (error) {
             // An error happened.
             console.log('errror updating name, - ', error)
         });
         this.setState({ old_parentname: this.state.parentName })
     }

     changeName() {
         // this.state.nameChange?this.setState({nameConfirmVisible:true}):null
         return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
             <Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Changing User Name to</Text>
             <Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>{this.state.parentName}</Text>
             <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%', marginHorizontal: 15, }}>
                 <View style={{ width: '40%' }}>
                     <TouchableNativeFeedback onPress={() => { this.changeNameTransaction(); this.setState({ nameChange: false }) }}>
                         <View style={{
                             justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                             margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                         }}>
                             <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
                     </TouchableNativeFeedback>
                 </View>
                 <View style={{ width: '40%', }}>
                     <TouchableNativeFeedback onPress={() => this.setState({ nameChange: false })}>
                         <View style={{
                             justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
                             backgroundColor: '#EE5060'
                         }}>
                             <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
                     </TouchableNativeFeedback>
                 </View>
             </View>
         </View>
     }

     checkPasswordConditions() {
         if (this.state.password === this.state.confirmPassword && this.state.password != '') {
             // this.setState({})
             return true
         }
         if (this.state.password === this.state.confirmPassword && this.state.password === '') {
             // this.setState({})
             this.setState({ errorOpacity: 1, errorText: "Password cannot be empty" })
             return false
         }
         else {
             this.setState({ errorOpacity: 1, errorText: "Passwords don't match" })
             return false
         }
     }

     changePasswordTransaction() {
         // this.setState({ oldPassword:'', password: '', confirmPassword: '', passwordChangeVisible: false, })
         var user = auth().currentUser;
         const credential = auth.EmailAuthProvider.credential(
             user.email,
             this.state.oldPassword
         );
         const newPassword = this.state.password
         console.log('user!!!! -', user)
         // Prompt the user to re-provide their sign-in credentials

         user.reauthenticateWithCredential(credential).then(() => {
             // User re-authenticated.
             console.log('Here changing')
             auth().currentUser.updatePassword(newPassword)
             this.setState({ password: '', confirmPassword: '', passwordChangeVisible: false, startPasswordChange: false })

         }).catch((error) => {
             console.log('error, ', error)
             this.setState({ errorOpacity: 1, errorText: "Old password is incorrect" })
             // An error happened.
         });

     }


     changePassword() {
         // this.state.nameChange?this.setState({nameConfirmVisible:true}):null
         return <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
             <Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Change Password</Text>

             <View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
                 <FloatingLabelInput secureTextEntry={true} label={'Old Password'} value={this.state.oldPassword}
                     onChangeText={text => this.setState({ errorOpacity: 0, oldPassword: text })} /></View>

             <View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
                 <FloatingLabelInput secureTextEntry={true} label={'New Password'} value={this.state.password}
                     onChangeText={text => this.setState({ errorOpacity: 0, password: text })} /></View>

             <View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
                 <FloatingLabelInput secureTextEntry={true} label={'Confirm New Password'} value={this.state.confirmPassword}
                     onChangeText={text => this.setState({ errorOpacity: 0, confirmPassword: text })} /></View>

             <Text style={{ marginVertical: 4, color: '#EE5060', opacity: this.state.errorOpacity }}>{this.state.errorText}</Text>

             <View style={{ width: '50%', alignSelf: 'center', }}>
                 <TouchableNativeFeedback onPress={() => { if (this.checkPasswordConditions()) { this.changePasswordTransaction() } }}>
                     <View style={{
                         width: '80%', marginBottom: 30, backgroundColor: '#5aca3c', height: 40, borderRadius: 10,
                         justifyContent: 'center', alignSelf: 'center'
                     }}>
                         <Text style={{ textAlign: 'center', fontSize: 25, color: 'white' }}>OK</Text></View>
                 </TouchableNativeFeedback>
             </View>
         </View>

     }
     getSettings1() {
         const { parentId, } = this.props.auth;
         const { childIds, settings, id } = this.props.parent;

         this.props.actions.getSettings(parentId, childIds[id])
             .then(settings => { console.log('settings!!', settings); this.setState({ ...settings }) })
         console.log('settings[childIds[id]]', childIds[id], settings[childIds[id]])
         // this.setState({ ...settings[childIds[id]] })
     }

     getSettings(move) {
         const { childIds, id } = this.props.parent;
         const { changeChildIndex } = this.props.actions;
         let index = id + move;
         changeChildIndex(move)
         if (index > childIds.length - 1) { this.setState({ id: 0 }, () => { this.getSettings1() }); return 0 }
         if (index < 0) { this.setState({ id: childIds.length - 1 }, () => { this.getSettings1() }); return 0 }
         this.setState({ id: index }, () => { this.getSettings1() }); return 0
     }



     closeModal() {
         return <View></View>
     }


     clearChildren() {
         const { parentId } = this.props.auth;

         database().ref('/users/' + parentId).remove();

         database().ref('/parents/' + parentId).remove();

         this.props.actions.getChildNodes(parentId)
     }



     render() {
         const { parentId, } = this.props.auth;
         const { childIds, names, isLoading, settings, id } = this.props.parent;

         const { navigation, auth, parent } = this.props;

         console.log('Object.keys(childIds).length')
         console.log(Object.keys(childIds).length)
         console.log((childIds))
         console.log('parentId - ', parentId)

         // const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
         //     transact_fee, transact_limit} = childIds[id]?settings[childIds[id]]:{}
         return (
             <Resizable>
                 {layout => {
                     const vmin = Math.min(...[layout.width, layout.height]);
                     let width = layout.width;
                     const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (40)
                     return <KeyboardAvoidingView style={{ flex: 1 }}>

                         <ScrollView ref={ref => this._scrollView = ref} style={{ backgroundColor: 'white', flex: 1, zIndex: 2 }}
                         >
                             <View style={{ position: 'absolute', top: 0.02 * vmin, right: 0.05 * vmin, zIndex: 5 }}>
                                 <TouchableNativeFeedback onPress={() => this.props.navigation.navigate('Home')}>
                                     <View elevation={10} style={{ alignSelf: 'center', padding: 5, margin: 10, marginTop: 40 }}>
                                         <Image style={{ height: 30, width: 30 }} source={require('assets/Delete-X.png')} />
                                     </View>
                                 </TouchableNativeFeedback>
                             </View>
                             {<Modal
                                 animationIn={'zoomIn'} animationOut={'zoomOut'}
                                 animationInTiming={1000} animationOutTiming={1000}
                                 backdropColor={'darkgrey'} backdropOpacity={0.6}
                                 onBackdropPress={() => this.setState({ modalVisible: false })}
                                 isVisible={this.state.modalVisible && (this.state.emailChange || this.state.nameChange)}
                                 onRequestClose={() => { this.setState({ modalVisible: false }) }}>
                                 <View style={{
                                     borderRadius: 15, backgroundColor: 'white', width: '80%', height: '50%', alignSelf: 'center',
                                     maxHeight: 500, maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
                                 }}>
                                     {this.state.nameChange ? this.changeName() :
                                         this.state.emailChange ? this.changeEmail() : this.closeModal()
                                     }
                                 </View>
                             </Modal>}
                             {<Modal
                                 animationIn={'zoomIn'} animationOut={'zoomOut'}
                                 animationInTiming={500} animationOutTiming={500}
                                 backdropColor={'darkgrey'} backdropOpacity={0.6}
                                 onBackdropPress={() => this.setState({ addChildModal: false })}
                                 isVisible={this.state.addChildModal}
                                 onRequestClose={() => { this.setState({ addChildModal: false }) }}>
                                 <View style={{
                                     borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                                     maxWidth: 400, minHeight: 250, justifyContent: 'center'
                                 }}>
                                     {(this.state.username && emailIsValid(this.state.email)) ?
                                         <ConfirmationView image={require('assets/Avatars/icon_2.png')} title={'Add'}
                                             subtitles={[this.state.username, this.state.email, this.state.age ? (this.state.age + ' years old') : null]}
                                             confirmPress={() => {
                                                 this.setState({ addChildModal: false, });

                                                 this.props.actions.sendRegistrationChildEmail(parentId, this.state.username, this.state.email)
                                                     .then((resp) => {
                                                         console.log('childId from REG', resp);
                                                         // if (childI)
                                                         if (resp.data === 'Success') {
                                                             let childId = resp.childId
                                                             console.log('parentId, childId, childIds.length, childIds, ', parentId, childId, childIds.length, childIds)
                                                             // this.props.actions.sendRegistrationChildEmail(this.state.username, this.state.email, childId);
                                                             this.props.actions.getSettings(parentId, childId)
                                                                 .then((settings) => {
                                                                     this.props.actions.changeChildIndex(childIds.length - id)
                                                                     this.setState({ id: childIds.length, username: '', email: '', age: '', ...settings });
                                                                 })
                                                         }
                                                         else {
                                                             // this.setState({addChildModal: true,})
                                                         }

                                                     });
                                             }}
                                             rejectPress={() => { this.setState({ addChildModal: false }) }}
                                         /> :
                                         <RejectionView title={'Please add child name and valid email'} subtitles={[]} okPress={() => { this.setState({ addChildModal: false }) }} />}
                                 </View>
                             </Modal>}
                             <Modal animationIn={'zoomIn'} animationOut={'zoomOut'}
                                 animationInTiming={500} animationOutTiming={500}
                                 backdropColor={'darkgrey'} backdropOpacity={0.6}
                                 onBackdropPress={() => this.setState({ parentModalVisible: false })}
                                 isVisible={this.state.parentModalVisible && (this.state.emailChange || this.state.nameChange)}
                                 onRequestClose={() => { this.setState({ parentModalVisible: false }) }}>
                                 <View style={{
                                     borderRadius: 15, backgroundColor: 'white', width: '80%', height: '30%', alignSelf: 'center',
                                     maxHeight: 500, maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
                                 }}>
                                     {this.state.nameChange ? this.changeName() :
                                         this.state.emailChange ? this.changeEmail() : this.closeModal()
                                     }
                                 </View>
                             </Modal>
                             {<Modal
                                 animationIn={'zoomIn'} animationOut={'zoomOut'}
                                 animationInTiming={500} animationOutTiming={500}
                                 backdropColor={'darkgrey'} backdropOpacity={0.6}
                                 onBackdropPress={() => this.setState({ passwordChangeVisible: false, })}
                                 isVisible={this.state.passwordChangeVisible}
                                 onRequestClose={() => { this.setState({ passwordChangeVisible: false, }) }}>
                                 <View style={{
                                     borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                                     maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
                                 }}>
                                     {this.changePassword()}
                                 </View>
                             </Modal>}

                             <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row' }}>

                             </View>

                             <View>
                                 <Text style={[{ alignSelf: 'center', marginTop: 100, }, globalStyle.h1, defaultStyles.bold]}>Settings</Text>

                                 {this.state.category === -1 && <View><Text style={{ marginVertical: vmin * 0.03, alignSelf: "center", fontSize: 19, color: Colors.gray0 }}>Select category</Text></View>}

                                 <View style={{ flex: 1, width: '80%', maxWidth: 500, alignSelf: 'center', flexDirection: 'column' }}>
                                     {/* <HeaderElement onPress={() => { console.log('sdfsd'); navigation.navigate('Security') }} focused={this.state.category === 2} title={'Security'} layout={layout} /> */}
                                     {/* <HeaderElement onPress={() => navigation.navigate('RegularSettings')} focused={this.state.category === 1} title={'Allowance and Expense'} layout={layout} />
                                     <HeaderElement onPress={() => navigation.navigate('ChequingSettings')} focused={this.state.category === 1} title={'Chequing Account'} layout={layout} />
                                     <HeaderElement onPress={() => navigation.navigate('SavingsSettings')} focused={this.state.category === 1} title={'Savings Account'} layout={layout} /> */}
                                     <HeaderElement onPress={() => navigation.navigate('NewParent')} focused={this.state.category === 3} title={'Add a Parent'} layout={layout} />
                                     <HeaderElement onPress={() => navigation.navigate('Profile')} focused={this.state.category === 0} title={'Add a Child'} layout={layout} />
                                     {SHOW_ACADEMY ? <HeaderElement onPress={() => navigation.navigate('RegisterAccounts')} focused={this.state.category === 2} title={'Academy settings'} layout={layout} /> : null}
                                 </View>

                                 <View style={{
                                     paddingTop: 0.1 * vmin, alignItems: 'center', width: '100%', maxWidth: 600, minWidth: 200, alignSelf: 'center',
                                     justifyContent: 'center', alignItems: 'center'
                                 }}>

                                     {this.state.category === 0 && <AddChildSettings navigateToChild={() => this.setState({ category: 1 })} actions={this.props.actions} parent={parent} auth={this.props.auth} navigation={this.props.navigation} layout={layout} />}
                                     {this.state.category === 2 && <ParentSettings actions={this.props.actions} auth={this.props.auth} parent={parent} layout={layout} />}
                                     {this.state.category === 1 && ((!isLoading && Object.keys(childIds).length === 0) ? <View style={{ marginVertical: 100, }}><Text>No children added yet</Text></View>
                                         :
                                         isLoading ? <View><Text></Text></View> :
                                             <View onLayout={e => { this.setState({ childPos: e.nativeEvent.layout.y }) }} style={{ width: '100%' }}>
                                                 <View style={{ width: '100%', }}>
                                                     {false ? null : <ParentComponentHeader containerStyle={{ marginTop: 10 }} layout={layout}
                                                         theme={'light'}
                                                         title={names[childIds[id]]}
                                                         pressLeft={() => this.getSettings(-1)} pressRight={() => this.getSettings(+1)}
                                                     />}
                                                 </View>
                                                 <View style={{ flex: 1, width: '82%', marginBottom: vmin * 0.05, alignSelf: 'center', flexDirection: layout.width > 600 ? 'row' : 'column' }}>
                                                     <HeaderElement onPress={() => this.setState({ focused: 0 })} focused={this.state.focused === 0} title={'General'} layout={layout} />
                                                     <HeaderElement onPress={() => this.setState({ focused: 1 })} focused={this.state.focused === 1} title={'Advanced'} layout={layout} />
                                                     {/* <HeaderElement onPress={() => this.setState({ focused:  })} focused={this.state.focused === 1} title={'Parents'} layout={layout} /> */}

                                                     {/* <HeaderElement onPress={() => this.setState({ focused: 2 })} focused={this.state.focused === 2} title={'Other'} layout={layout} /> */}
                                                 </View>
                                                 {/* {this.state.focused===0 && <MainSettings navigation={navigation} settings={{}} auth={this.props.auth} id={id} actions={this.props.actions} layout={layout} />} */}
                                                 {this.state.focused === 0 && <GeneralSettings navigation={navigation} settings={{}} auth={this.props.auth} parent={parent} id={id} actions={this.props.actions} layout={layout} />}
                                                 {this.state.focused === 1 && <AdditionalSettings navigation={navigation} settings={{}} auth={this.props.auth} parent={parent} id={id} actions={this.props.actions} layout={layout} />}
                                                 {this.state.focused === 2 && <OtherSettings navigation={navigation} settings={{}} auth={this.props.auth} parent={parent} id={id} actions={this.props.actions} layout={layout} />}
                                                 {/* <MainSettings settings={{}} auth={this.props.auth} id={id} actions={this.props.actions} layout={layout} /> */}
                                             </View>)
                                     }
                                     {this.state.category === 3 && <ManagementSettings parentId={parentId} addNewParent={this.props.actions.addNewParent} navigation={navigation} settings={{}} auth={this.props.auth} id={id} actions={this.props.actions} layout={layout} />}
 {/*
                                 <TouchableNativeFeedback onPress={()=> navigation.navigate('NewKid')}>
                                     <Text>sdf</Text>
                                 </TouchableNativeFeedback> */}

                                 </View>
                             </View>
                             {/* </ImageBackground> */}
                         </ScrollView>
                     </KeyboardAvoidingView>
                 }}
             </Resizable>)
     }
 }

 const styles = StyleSheet.create({

 });

 // export default Settings;
 export default connect(state => {
     // console.log(Object.keys(state.vacancies));
     return ({
         auth: state.auth,
         parent: state.parent
     })
 },
     (dispatch) => ({
         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
     })
 )(Settings);
