import React from 'react';
import { View, StyleSheet, TouchableOpacity, TextInput } from 'react-native';

import Text from '../CustomText';

import Colors from '../../constants/Colors';
import { IS_WEB } from '../../constants/constants';
import { FONTS } from '../../constants/fonts';
import { inputStyles } from './styles';

export function Input(props) {
    const { value, placeholder, onChangeText, containerStyle, title, inputStyle } = props;

    const textStyle = () => {
        return value ? styles.value : styles.placeholder;
    };

    return (
        <View style={[styles.container, containerStyle]}>
            <Text style={styles.title}>{title}</Text>
            <TextInput
                {...props}
                style={[inputStyles.input, inputStyle]}
                value={value}
                onChangeText={onChangeText}
                placeholder={placeholder}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: { width: '100%' },
    title: {
        fontFamily: FONTS.FONT_BOLD,
    },
    input: {
        backgroundColor: '#F1F1F1',
        height: 42,
        fontSize: 16,
        justifyContent: 'center',
        borderRadius: 20,
        paddingLeft: 15,
        marginTop: 15,
    },
    text: {
        color: '#282828',
    },
    value: {},
    placeholder: {
        opacity: 0.5,
    },
});
