import firebase from 'firebase/app';
import 'firebase/database';

const moment = require('moment');

import config from '../../constants/config';

const database = firebase.database;

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.floor((second - first) / (1000 * 60 * 60 * 24));
}

function calculateDeadline(frequency) {
    const today = moment();

    if (frequency === 1) {
        // console.log(today.add(1, 'days').format("YYYY-mm-DD"))
        return today.add(1, 'days').format('YYYY-MM-DD');
    }

    if (frequency === 2) {
        // console.log(today.add(7, 'days').format("YYYY-mm-DD"))
        return today.add(7, 'days').format('YYYY-MM-DD');
    }

    if (frequency === 3) {
        // console.log(today.add(14, 'days').format("YYYY-mm-DD"))
        return today.add(14, 'days').format('YYYY-MM-DD');
    }

    if (frequency === 4) {
        // console.log(today.add(1, 'months').format("YYYY-mm-DD"))
        return today.add(1, 'months').format('YYYY-MM-DD');
    }

    return today.format('YYYY-MM-DD');
}

export function createJob(
    parentId,
    childIds,
    description,
    value,
    deadline,
    category,
    frequency,
    stopRepeat
) {
    for (const i in childIds) {
        const childId = childIds[i];

        const now = new Date();

        deadline = frequency ? calculateDeadline(frequency) : deadline;
        const deadlineDate = new Date(
            new Date(deadline).valueOf() + now.getTimezoneOffset() * 60 * 1000
        );

        deadlineDate.setHours(23);
        deadlineDate.setMinutes(59);
        deadlineDate.setSeconds(59);

        let daysToComplete = datediff(now, deadlineDate);

        if (daysToComplete === 0) {
            daysToComplete = 1;
        }
        // console.log('daysToComplete - ')
        // console.log('deadline ', deadline);

        // deadline = deadline && '2020-05-30';
        var jobRef = database()
            .ref()
            .child('users/' + parentId + '/' + childId + '/' + 'jobs')
            .push({
                action: 0,
                amount: value,
                approved: 0,
                category: category,
                completed: 0,
                created: now.toISOString(),
                deadline: deadlineDate.yyyymmdd(),
                description: description,
                frequency: frequency || 0,
                stopRepeat: stopRepeat,
            }).key;
        // let jobRef = jobPush.getKey()

        const body = {
            parentId,
            childId,
            description,
            value,
            daysToComplete: daysToComplete,
            category,
            jobFrequency: frequency,
            jobRef: jobRef,
            stopRepeat,
        };

        fetch(config.url + 'sendNewJobNotifications', {
            method: 'POST',
            body: JSON.stringify({
                childId,
                description,
                deadline: deadlineDate.yyyymmdd(),
            }),
        }).catch((err) => console.log('Notification Error , ', err));

        if (frequency) {
            fetch(config.url + 'addRecurringJob', {
                method: 'POST',
                body: JSON.stringify(body),
            }).then((response) => response.json());
            // return

            // Dispatch?
        }
    }

    return jobRef;
}
