import firebase from 'firebase/app';
import 'firebase/database';

const database = firebase.database;

export async function checkIfEmailAvailable(email) {
    const value = await database()
        .ref('emails')
        .orderByValue()
        .equalTo(email)
        .once('value');

    console.log('is email valid = ', value.val());

    return !value.val();
}
