import * as actionTypes from './actionTypes';
import AsyncStorage from '@react-native-async-storage/async-storage';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import config from '../../../constants/config';
import { getDashboardData as getDashboardDataFuns } from '../../../helpers/dashboard/getDashboardData';
import { LayoutAnimation } from 'react-native';
import { getChildrenData } from '../../../requests/dashboard/dashboardRequests';

const auth = firebase.auth;
const database = firebase.database;

const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly' };
const salaryFreqs = { Monthly: 1, 'Bi-weekly': 2, Weekly: 4 };

const salaryDays = {
    '1st of the month': 0,
    'End of the month': 1,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0,
};

function requestLogin() {
    return {
        type: actionTypes.USER_LOGIN,
        // data: [],
        isLoading: true,
    };
}

function receiveLogin(json, parentId) {
    //fixme
    return {
        type: actionTypes.USER_LOGIN,
        payload: {
            // obj: json,
            user: json,
            isLoading: false,
            parentId,
        },
    };
}

function receiveSignup(json) {
    return {
        type: actionTypes.USER_SIGNUP,
        payload: {
            user: json,
            isLoading: false,
        },
    };
}

function receiveChildNodes(json) {
    //fixme
    return {
        type: actionTypes.RECEIVE_CHILDREN,
        payload: {
            // obj: json,
            children: json,
            isLoading: false,
        },
    };
}

export function login(user) {
    return async (dispatch) => {
        const userId = user.user.uid;
        const parentId = (
            await database()
                .ref('/user_type/' + userId + '/parentId')
                .once('value')
        ).val();

        // const userId = 'ciEn1XZ28aPp2WDydepFiBrBcNz1'
        // const parentId = 'EoquYvELsWb39pLl4R11aZT92dk1'
        // user = {user:{uid:parentId}}
        // dispatch(requestLogin())
        return dispatch(receiveLogin(user, parentId));
    };
}

export function signup(user) {
    return async (dispatch) => {
        // dispatch(requestLogin())
        return dispatch(receiveSignup(user));
    };
}

export function getChildNodes(parentId) {
    return async (dispatch) => {
        // const user = firebase.auth().currentUser
        // console.log('uyser', user)
        // parentId = user.uid
        if (!parentId) {
            console.log('I MA HERE PLZ HELP');
            dispatch(receiveChildNodes({}));

            return null;
        }

        return database()
            .ref('/parents/' + parentId + '/children')
            .once('value')
            .then((snapshot) => {
                return snapshot.val();
            })
            .then((snap) => {
                console.log('getChildNodes - snap - ', snap);
                dispatch(receiveChildNodes(snap));

                return snap;
            });
    };
}

function receiveChildName(childId, name) {
    //fixme
    return {
        type: actionTypes.RECEIVE_CHILD_NAME,
        payload: {
            // obj: json,
            name,
            childId,
            isLoading: false,
        },
    };
}

function handleLogout() {
    //fixme
    return {
        type: actionTypes.USER_LOGOUT,
        payload: {},
    };
}

function handleClearAuth() {
    //fixme
    return {
        type: actionTypes.CLEAR_AUTH,
        payload: {},
    };
}

export function clearAuth() {
    return async (dispatch) => {
        // this.props.actions.logout();
        // AsyncStorage.clear();
        dispatch(handleClearAuth());
    };
    // this.props.navigation.navigate('Login')
}

export function logout() {
    return async (dispatch) => {
        // this.props.actions.logout();
        await AsyncStorage.clear();
        dispatch(handleLogout());
    };
    // this.props.navigation.navigate('Login')
}

export function getChildName(childId) {
    return async (dispatch) => {
        return database()
            .ref('/children/' + childId)
            .once('value')
            .then((snapshot) => {
                return snapshot.val();
            })
            .then((snap) => {
                // console.log(snap.name)
                return dispatch(
                    receiveChildName(childId, snap ? snap.name : '')
                );
            });
    };
}

function addNewChild(childId, name) {
    //fixme
    return {
        type: actionTypes.CREATE_CHILD,
        payload: { childId, name },
    };
}

export function createNewChild(parentId, name, email) {
    return async (dispatch) => {
        // const user = firebase.auth().currentUser
        // parentId = user.uid;
        const now = new Date();

        return admin
            .auth()
            .createUser({
                // uid: childId,
                displayName: name,
                email: email,
                // emailVerified: true,
            })
            .then(async (userRecord) => {
                const childId = userRecord.uid;

                database()
                    .ref('children/' + childId)
                    .update({
                        name: name,
                        parent: parentId,
                        email: email,
                    });

                database()
                    .ref('user_type/' + childId)
                    .update({
                        type: 1,
                    });

                const children = (
                    await database()
                        .ref('parents/' + parentId + '/children')
                        .once('value')
                ).val();

                if (children && !Object.values(children).includes(childId)) {
                    const children_len = Object.keys(children).length + 1;
                    const child_count = 'child_' + children_len;

                    database()
                        .ref('parents/' + parentId + '/children/')
                        .update({
                            [child_count]: childId,
                        });
                } else if (
                    children &&
                    Object.values(children).includes(childId)
                ) {
                    // pass
                } else {
                    database()
                        .ref('parents/' + parentId + '/children/')
                        .set({
                            child_1: childId,
                        });
                }

                database()
                    .ref('users/' + parentId + '/' + childId + '/chequing')
                    .set({
                        balance: 0,
                        salary_amt: 0,
                        salary_freq: 1,
                        min_balance: 0,
                        monthly_fee: 0,
                        transact_fee: 1,
                        transact_limit: 20,
                    });

                // database().ref('users/' + parentId + '/' + childId + '/purchases').set({});
                // database().ref('users/' + parentId + '/' + childId + '/purchases/pending').set({});

                database()
                    .ref('users/' + parentId + '/' + childId + '/savings')
                    .set({
                        balance: 0,
                        int_rate: 0.1,
                        withdraw_limit: 10,
                        withdraw_fee: 10,
                    });

                database()
                    .ref('users/' + parentId + '/' + childId + '/settings')
                    .set({
                        avatar: 1,
                        credit_card: 1,
                        debit_card: 1,
                    });

                dispatch(addNewChild(childId, name));

                return childId;
            });

        // var childId = database().ref('children/').push().key;
    };
}

function saveSettings(childId, settings) {
    //fixme
    return {
        type: actionTypes.SAVE_SETTINGS,
        payload: { childId, settings },
    };
}

function saveAllowanceSettings(childId, settings) {
    //fixme
    return {
        type: actionTypes.SAVE_ALLOWANCE_SETTINGS,
        payload: { childId, amount: settings },
    };
}

export function getSettings(parentId, childId) {
    return async (dispatch) => {
        const now = new Date();
        const ref_source = database().ref('users/' + parentId + '/' + childId);

        const int_rate =
            (
                await ref_source
                    .child('savings')
                    .child('int_rate')
                    .once('value')
            ).val() * 100;
        const withdraw_fee = (
            await ref_source
                .child('savings')
                .child('withdraw_fee')
                .once('value')
        ).val();
        const withdraw_limit = (
            await ref_source
                .child('savings')
                .child('withdraw_limit')
                .once('value')
        ).val();

        const min_balance = (
            await ref_source
                .child('chequing')
                .child('min_balance')
                .once('value')
        ).val();
        const monthly_fee = (
            await ref_source
                .child('chequing')
                .child('monthly_fee')
                .once('value')
        ).val();
        const salary_amt = (
            await ref_source.child('chequing').child('salary_amt')
.once('value')
        ).val();
        const salary_freq = (
            await ref_source
                .child('chequing')
                .child('salary_freq')
                .once('value')
        ).val();
        const transact_fee = (
            await ref_source
                .child('chequing')
                .child('transact_fee')
                .once('value')
        ).val();
        const transact_limit = (
            await ref_source
                .child('chequing')
                .child('transact_limit')
                .once('value')
        ).val();
        const salary_day = (
            await ref_source.child('chequing').child('salary_day')
.once('value')
        ).val();

        const expences = (
            await ref_source.child('expences').once('value')
        ).val();

        const settings = {
            int_rate,
            withdraw_fee,
            withdraw_limit,
            min_balance,
            monthly_fee,
            salary_amt,
            salary_freq: salaryFreqsReversed[salary_freq],
            transact_fee,
            transact_limit,
            expences,
            salary_day,
        };

        dispatch(saveSettings(childId, settings));

        return settings;
    };
}

export function updateSettings(
    parentId,
    childId,
    chequingValues,
    savingsValues
) {
    return (dispatch) => {
        const now = new Date();
        const ref_source = database().ref('users/' + parentId + '/' + childId);

        console.log('chequing values', chequingValues);
        console.log('savings values', savingsValues);

        for (var key in chequingValues) {
            if (chequingValues[key] === false) {
                delete chequingValues[key];
            } else {
                chequingValues[key] = parseFloat(chequingValues[key]);
            }
        }

        for (var key in savingsValues) {
            if (savingsValues[key] === false) {
                delete savingsValues[key];
            } else {
                savingsValues[key] = parseFloat(savingsValues[key]);
            }
        }

        // if (salaryValues) {
        //     let salary = parseFloat(salaryValues.salary_amt.replace(/\D/g, ''))
        //     let savings_balance = parseFloat((await ref_source.child('chequing/balance').once('value')).val())
        //     ref_source.child('chequing').update({balance:savings_balance+salary})
        // }

        console.log('chequing values', chequingValues);
        console.log('savings values', savingsValues);

        ref_source.child('savings').update(savingsValues);
        ref_source.child('chequing').update(chequingValues);

        // const settings = {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
        //             transact_fee, transact_limit}

        dispatch(
            saveSettings(childId, { ...chequingValues, ...savingsValues })
        );
    };
}

export function updateAllowance(parentId, childId, salaryValues) {
    return async (dispatch) => {
        const now = new Date();
        const ref_source = database().ref('users/' + parentId + '/' + childId);

        console.log('chequing values', salaryValues);
        console.log('salaryValues, - ', salaryValues);

        if (salaryValues) {
            const allowanceStartDate = new Date(
                new Date(salaryValues.allowanceStart).valueOf() +
                    now.getTimezoneOffset() * 60 * 1000
            );
            const startDate = allowanceStartDate.getDate();
            const startMonth = allowanceStartDate.getMonth();

            console.log('startDate, startMonth - ', startDate, startMonth);
            console.log(now);
            console.log('Allowance start', allowanceStartDate);
            const salary = parseFloat(
                salaryValues.salary_amt.toString().replace(/\D/g, '')
            );
            const salariesFreq = salaryFreqs[salaryValues.salary_freq];

            ref_source
                .child('chequing')
                .update({ salary_amt: salary, salary_freq: salariesFreq });

            if (now.getDate() === startDate && now.getMonth() === startMonth) {
                const savings_balance = parseFloat(
                    (
                        await ref_source.child('chequing/balance').once('value')
                    ).val()
                );

                ref_source
                    .child('chequing')
                    .update({ balance: savings_balance + salary });

                await ref_source.child('chequing/transactions').push({
                    amount: salary,
                    transaction_date: now.toISOString(),
                    type: 'allowance',
                });
                // dispatch(saveAllowanceSettings(childId,{...savingsValues}))
            }
            // ref_source.child('chequing').update({balance:savings_balance+salary})
        }

        // console.log('savings values', savingsValues)

        // ref_source.child('savings').update(savingsValues)
        // ref_source.child('chequing').update(chequingValues)

        // const settings = {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
        //             transact_fee, transact_limit}
    };
}

export function sendAllowanceJob(parentId, childId, salaryValues) {
    return async (dispatch) => {
        const now = new Date();

        const body = {
            salaryFrequency: salaryFreqs[salaryValues.salary_freq],
            amount: parseFloat(salaryValues.salary_amt),
            salaryDay: salaryDays[salaryValues.salary_day],
            parentId,
            childId,
        };

        const day = salaryDays[salaryValues.salary_day];
        const frequency = salaryFreqs[salaryValues.salary_freq];
        const newDate = new Date();
        let date = newDate.getDate();
        if (date > 28) { date = 28 }

        if (day !== undefined) {
            date = day ? 28 : 1
        }

        // TODO unify this with expence code
        if (
            (parseInt(newDate.getUTCDay(), 10) === parseInt(day, 10) &&
                frequency !== 1) ||
            (frequency === 1 && date === 1)
        ) {
            const balance = (
                await database()
                    .ref()
                    .child(`users/${parentId}/${childId}/chequing/balance`)
                    .once('value')
            ).val();

            database()
                .ref()
                .child(`users/${parentId}/${childId}/chequing`)
                .update({
                    balance: balance + parseFloat(salaryValues.salary_amt),
                });

            database()
                .ref()
                .child(`users/${parentId}/${childId}/chequing/transactions`)
                .push({
                    amount: parseFloat(salaryValues.salary_amt),
                    transaction_date: newDate.toISOString(),
                    // name: title,
                    type: 'allowance',
                });
        }

        // const old_balance = (
        //     await database()
        //         .ref('/users/' + parentId + '/' + childId + '/chequing/balance')
        //         .once('value')
        // ).val();

        // console.log('old_balance ', old_balance);
        // database()
        //     .ref('/users/' + parentId + '/' + childId + '/chequing')
        //     .update({
        //         balance: old_balance + parseFloat(salaryValues.salary_amt),
        //     });

        // return;
        return fetch(config.url + 'setSalarySchedule', {
            method: 'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}

export function sendRegistrationEmail(userName, email) {
    return (dispatch) => {
        const body = { userName, email };
        // var sendEmail = functions().httpsCallable('sendRegistrationEmail');

        // return sendEmail(body)

        return fetch(config.url + 'sendRegistrationEmail', {
            method: 'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}

export function sendRegistrationChildEmail(parentId, userName, email) {
    return async (dispatch) => {
        const body = { userName, email, parentId };

        return fetch(config.url + 'sendRegistrationChildEmail', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((response) => {
                dispatch(addNewChild(response.childId, response.name));

                return response;
            });
    };
}

export function sendPasswordResetEmail(email) {
    return (dispatch) => {
        const body = { type: 'parent', email };

        // var actionCodeSettings = {
        //     url: 'https://pennygem-kids.firebaseapp.com/',
        //     link: 'https://pennygem-kids.firebaseapp.com/',
        //   };
        return (
            fetch(config.url + 'sendPasswordResetEmail', {
                method: 'POST',
                // headers: {
                //     'Accept': 'application/json',
                //     'Content-Type': 'application/json'
                // },
                body: JSON.stringify(body),
            })
                // .then(response => {console.log('response',response); return response.json()})
                .then(async (response) => {
                    console.log('response', response);
                    if (response.status !== 200) {
                        throw await response.json();
                    }

                    return response.json();
                })
        );

        //   .catch(function(error) {
        //       console.log('authActons - Error on sendPasswordReset - ', error)
        //     // An error happened.
        //   });
    };
}

export function setPassword(password, actionCode) {
    return async (dispatch) => {
        // dispatch(requestLogin())

        return auth()
            .verifyPasswordResetCode(actionCode)
            .then(function (email) {
                var accountEmail = email;

                // TODO: Show the reset screen with the user's email and ask the user for
                // the new password.

                // Save the new password.
                return auth()
                    .confirmPasswordReset(actionCode, password)
                    .then(function (resp) {
                        // Password reset has been confirmed and new password updated.

                        // TODO: Display a link back to the app, or sign-in the user directly
                        // if the page belongs to the same domain as the app:
                        return auth()
                            .signInWithEmailAndPassword(accountEmail, password)
                            .then(async (user) => {
                                console.log('user from restore pass - ', user);
                                const userId = user.user.uid;
                                // const parentId = (await database().ref('/children/'+childId +'/parent').once('value')).val()
                                const parentId = (
                                    await database()
                                        .ref(
                                            '/user_type/' + userId + '/parentId'
                                        )
                                        .once('value')
                                ).val();

                                dispatch(receiveLogin(user, parentId));

                                // this.props.navigation.navigate('Dashboard');
                                return user;
                            });
                    })
                    .catch(function (error) {
                        console.log('ERROR!!', error);
                        // Error occurred during confirmation. The code might have expired or the
                        // password is too weak.
                    });
            });
    };
}

export function sendVerificationEmail(userName, email) {
    return async (dispatch) => {
        const body = { email, type: 'parent', userName };

        // var sendEmail = functions().httpsCallable('sendVerificationEmail');

        // return sendEmail(body)

        return fetch(config.url + 'sendVerificationEmail', {
            method: 'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            //   },
            // body:body
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}

export function testCustomUser() {
    return async (dispatch) => {
        const body = {};

        return fetch(config.url + 'addChild', {
            method: 'POST',
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}

export function changeChildIndex(move) {
    return async (dispatch) => {
        const body = {};

        return dispatch({
            type: actionTypes.CHANGE_INDEX,
            payload: { move: move },
        });
    };
}

function saveRememberMeEmail(email) {
    return {
        type: actionTypes.REMEMBER_ME,
        payload: { email },
    };
}

export function rememberMe(email) {
    return (dispatch) => {
        return dispatch(saveRememberMeEmail(email));
    };
}

export function addNewParent(userName, email, parentId) {
    return async (dispatch) => {
        const body = { email, type: 'parent', userName, parentId };
        const password = '123456';

        // return auth().createUserWithEmailAndPassword(email, password).then(user => {

        // 	return user.user.updateProfile({
        // 		displayName: userName
        // 	})
        // })
        // 	.then(() => {
        // 		const user = firebase.auth().currentUser;
        //         console.log('NEQW USER? ', user)

        // 		database().ref('emails/').update({
        // 			[user.uid]: email
        // 		});

        // 		database().ref().child('user_type/' + user.uid).update({ type: 0, parentId })

        // 	})

        return fetch(config.url + 'createNewParent', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((resp) => {
                if (resp.data === 'Fail') {
                    console.log(resp);
                    throw resp.error;
                }
            });
    };
}

export function createCustomToken() {
    return async (dispatch) => {
        const body = { type: 'child', uid: 'U39RWpKPU7TWctjIUAN9yZ8imag1' };

        return (
            fetch(config.url + 'createCustomToken', {
                method: 'POST',

                body: JSON.stringify(body),
            })
                // .then(response => {console.log('response',response); return response.json()})
                .then(async (response) => {
                    console.log('response', response);
                    if (response.status !== 200) {
                        throw await response.json();
                    }

                    return response.json();
                })
        );
    };
}

function saveKidData(type, data) {
    return {
        type: actionTypes.SAVE_KID_DATA,
        payload: { type, data },
    };
}

export function kidData({ type, data }) {
    return async (dispatch) => {
        return dispatch(saveKidData(type, data));
    };
}

export function createNewKid({
    parentId,
    name,
    userName,
    password,
    email,
    age,
}) {
    return async (dispatch) => {
        const body = {
            type: 'child',
            parentId,
            name,
            userName,
            password,
            email,
            age,
        };

        return (
            fetch(config.url + 'createNewChild', {
                method: 'POST',

                body: JSON.stringify(body),
            })
                // .then(response => {console.log('response',response); return response.json()})
                .then(async (response) => {
                    console.log('response', response);
                    if (response.status !== 200) {
                        throw await response.json();
                    }

                    return response.json();
                })
        );
    };
}

export function createExpence({
    parentId,
    childId,
    amount,
    title,
    frequency,
    day,
}) {
    return (dispatch) => {
        const timestamp = Date.now();
        const timezone = new Date().getTimezoneOffset();

        const body = {
            parentId,
            childId,
            amount,
            title,
            frequency,
            day,
            timestamp,
            timezone,
        };

        // return;
        return (
            fetch(config.url + 'setExpenceSchedule', {
                method: 'POST',

                body: JSON.stringify(body),
            })
                // .then(response => {console.log('response',response); return response.json()})
                .then(async (response) => {
                    console.log('response', response);
                    if (response.status !== 200) {
                        throw await response.json();
                    }

                    return response.json();
                })
        );
    };
}

async function sendAllowanceJobLocal({ parentId, childId, allowance }) {
    // return async dispatch => {
    if (!allowance.regular) {
        return null;
    }

    const now = new Date();

    console.log('ASllowvance jov ,', parentId, childId, allowance);

    const db_ref = database()
        .ref()
        .child('users/' + parentId + '/' + childId + '/chequing');

    db_ref.update({
        balance: parseFloat(allowance.amount),
        salary_amt: parseFloat(allowance.amount),
        salary_day: allowance.day,
        salary_freq: salaryFreqs[allowance.frequency],
    });

    const body = {
        salaryFrequency: salaryFreqs[allowance.frequency],
        amount: allowance.amount,
        salaryDay: allowance.day,
        parentId,
        childId,
    };

    // return;
    return fetch(config.url + 'setSalarySchedule', {
        method: 'POST',
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body),
    });
    // .then(response => response.json())
    // }
}

export function saveParentProfile({ name, username }) {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.SAVE_PARENT_PROFILE,
            payload: { name, username },
        });
    };
}

export function saveChildExpence({ title, amount, frequency, day, number }) {
    return (dispatch) => {
        return dispatch({
            // type: actionTypes.SAVE_CHILD_EXPENCE,
            payload: { title, amount, frequency, day, number },
        });
    };
}

export function createNewKidMin({
    parentId,
    profile,
    allowance,
    expense,
    expenses,
    chequing,
    savings,
}) {
    return async (dispatch) => {
        const { name, userName, password, email, age } = profile;
        const body = {
            type: 'child',
            parentId,
            name,
            userName,
            password,
            email,
            age,
            chequing,
            savings,
        };
        let child = await fetch(config.url + 'createNewChild', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        child = await child.json();
        const childId = child.childId;

        console.log('child, childId ', child, childId);

        const initialBalance = parseFloat(allowance.amount);
        // database().ref('users/'+parentId + '/'+childId+'/chequing').update({balance:initialBalance})

        return childId;
        // await sendAllowanceJobLocal({ parentId, childId, allowance })
        // const { amount, title, frequency, day, regular } = expense;
        // console.log('sdfsdfs sf' ,expense)
        // await createExpenceSchedule({ parentId, childId, amount, title, frequency, day, regular });

        // await additionalExpenses({ parentId, childId, expenses, regular });

        // saveChildSettings({ parentId, childId, chequing, savings })

        // .then(() => {
        //     console.log('sdfsdfs sf')

        // })
        // .then(() => saveChildSettings({ parentId, childId, chequing, savings }))
        // .then(() => createExpenceSchedule({ parentId, childId, amount, title, frequency, day }))

        // .then(async response => {if (response.status !== 200) {throw await response.json();} return response.json()})
    };
}

export function createNewKidComplete({
    parentId,
    profile,
    allowance,
    expense,
    expenses,
    chequing,
    savings,
}) {
    return async (dispatch) => {
        const { name, userName, password, email, age } = profile;
        const body = {
            type: 'child',
            parentId,
            name,
            userName,
            password,
            email,
            age,
        };
        let child = await fetch(config.url + 'createNewChild', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        child = await child.json();
        const childId = child.childId;

        console.log('child, childId ', child, childId);

        await sendAllowanceJobLocal({ parentId, childId, allowance });
        const { amount, title, frequency, day, regular } = expense;

        console.log('sdfsdfs sf', expense);
        await createExpenceSchedule({
            parentId,
            childId,
            amount,
            title,
            frequency,
            day,
            regular,
        });

        await additionalExpenses({ parentId, childId, expenses, regular });

        saveChildSettings({ parentId, childId, chequing, savings });

        // .then(() => {
        //     console.log('sdfsdfs sf')

        // })
        // .then(() => saveChildSettings({ parentId, childId, chequing, savings }))
        // .then(() => createExpenceSchedule({ parentId, childId, amount, title, frequency, day }))

        // .then(async response => {if (response.status !== 200) {throw await response.json();} return response.json()})
    };
}

function saveChildSettings({ parentId, childId, chequing, savings }) {
    const ref_source = database().ref('users/' + parentId + '/' + childId);

    console.log('chequing values', chequing);
    console.log('savings values', savings);

    for (const key in chequing) {
        if (!chequing[key]) {
            chequing[key] = 0;
        } else {
            chequing[key] = parseFloat(chequing[key]);
        }
    }

    for (const key in savings) {
        if (!savings[key]) {
            savings[key] = 0;
        } else {
            savings[key] = parseFloat(savings[key]);
        }
    }

    console.log('chequing values', chequing);
    console.log('savings values', savings);

    ref_source.child('savings').update(savings);
    ref_source.child('chequing').update(chequing);
}

async function createExpenceSchedule({
    parentId,
    childId,
    amount,
    title,
    frequency,
    day,
    regular,
}) {
    // return async dispatch => {
    const body = {
        type: 'child',
        parentId,
        childId,
        amount,
        title,
        frequency: salaryFreqs[frequency],
        day,
    };

    console.log(
        'I AM HERE EXPENCE ',
        parentId,
        childId,
        amount,
        title,
        frequency,
        day
    );

    if (!regular) {
        return null;
    }

    // return;
    return fetch(config.url + 'setExpenceSchedule', {
        method: 'POST',
        body: JSON.stringify(body),
    }).then(async (response) => {
        console.log('response', response);
        if (response.status !== 200) {
            throw await response.json();
        }

        return response.json();
    });
    // .then((resp) => { })
    // }
}

async function additionalExpenses({ parentId, childId, expenses, regular }) {
    // return async dispatch => {
    console.log('I AM HERE ADDITIONAL: ', parentId, childId, expenses, regular);
    if (!regular) {
        return null;
    }

    for (const item in expenses) {
        const { title, amount, frequency, day } = expenses[item];

        console.log(
            'item, expenses[item], title, amount, frequency, day - ',
            item,
            expenses[item],
            title,
            amount,
            frequency,
            day
        );

        const body = {
            type: 'child',
            parentId,
            childId,
            amount,
            title,
            frequency: salaryFreqs[frequency],
            day: salaryDays[day],
        };

        // break;
        fetch(config.url + 'setExpenceSchedule', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json();
            })
            .catch((err) => console.log('err', err));
    }
    // .then((resp) => { })
    // }
}

export function clearKidData() {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.CLEAR_KID_DATA,
            payload: {},
        });
    };
}

export function removeExpenseSchedule({ parentId, childId, expenseId }) {
    return (dispatch) => {
        const body = { type: 'child', parentId, childId, expenseId };

        console.log('I AM HERE EXPENCE REMOVE ', parentId, childId, expenseId);

        return fetch(config.url + 'removeExpense', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json();
            })
            .then((resp) => {
                dispatch({
                    type: actionTypes.REMOVE_EXPENSE,
                    payload: { expenseId, childId, parentId },
                });
            });

        return dispatch({
            type: actionTypes.REMOVE_EXPENSE,
            payload: { expenseId, childId, parentId },
        });
    };
}

export function updateNotificationToken({ token, parentId }) {
    return async (dispatch) => {
        const ref_source = database().ref(
            'parents/' + parentId + '/notifications'
        );

        console.log('STARN SAVING', token, parentId);

        // let values = (await ref_source.once('value')).val()
        const values = (
            await ref_source.orderByValue().equalTo(token)
.once('value')
        ).val();

        console.log('values', values, Object.values(values || {}));

        if (values) {
            // if (token in Object.values(values || {})) {
            console.log('exists ', values);
            // let key = Object.keys(values)[0]
            // ref_source.update({})
        } else {
            console.log('new ', values);

            const pushKey = ref_source.push().key;

            console.log('new ', pushKey);

            ref_source.update({ [pushKey]: token });
        }
    };
}

function receiveDashboardData({
    chequing,
    savings,
    spent,
    goals,
    jobs,
    purchases_approved,
    purchases_pending,
}) {
    //fixme
    console.log('KLID CLEAR AUTH');

    return {
        type: actionTypes.RECEIVE_DASHBOARD_DATA,
        payload: {
            chequing,
            savings,
            spent,
            goals,
            jobs,
            purchases_approved,
            purchases_pending,
        },
    };
}

export function getDashboardData({ parentId, childId }) {
    return async (dispatch) => {
        console.log('LAST ONE');

        LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);

        dispatch({
            type: actionTypes.SET_DASHBOARD_DATA_LOADING,
            payload: {
                isLoading: true,
            },
        });

        const data = await getDashboardDataFuns({ parentId, childId });

        dispatch(receiveDashboardData(data));

        LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
        dispatch({
            type: actionTypes.SET_DASHBOARD_DATA_LOADING,
            payload: {
                isLoading: false,
            },
        });
    };
}

function handleChildAvatar({ childId, avatar, avatarImage, avatarHash }) {
    //fixme
    return {
        type: actionTypes.USER_LOGOUT,
        payload: {
            childId,
            avatar,
            avatarImage,
            avatarHash,
        },
    };
}

export function saveChildAvatar({ childId, avatar, avatarImage, avatarHash }) {
    return (dispatch) => {
        dispatch(
            handleChildAvatar({ childId, avatar, avatarImage, avatarHash })
        );
    };
}

export function setTutorialVisibility(visibility) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_TUTORIAL_VISIBILITY,
            payload: {
                visibility,
            },
        });
    };
}

function receiveChildrenData({ childIds, data }) {
    return {
        type: actionTypes.RECEIVE_CHILDREN_DATA,
        payload: { childIds, data },
    };
}

export function requestChildrenData({ parentId, childIds }) {
    return async (dispatch) => {

        const data = await getChildrenData({ parentId, childIds });

        dispatch(receiveChildrenData({ childIds, data }));
    };
}
