/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Platform, TouchableOpacity, StyleSheet, View, FlatList, Image, Dimensions, ScrollView, Linking } from 'react-native';

import { Resizable } from 'react-native-web-hooks';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';

import firebase from 'firebase/app';
import 'firebase/database';

import Text from '../../components/CustomText';
import TouchableNativeFeedback from '../../components/TouchableFeedback';

import TransferBlock from '../../components/chequing/TransferBlock';

import {
    DevNotificationTokenBlock,
    ParentHeaderCard,
} from '../../components/Header/HeaderBlock';

import ModalContainer from '../../components/Modal/Modal';
import NewJobModal, { openNewJobModal } from '../../components/jobs/NewJobModal';

import * as goalsActions from '../../store/actions/parents/goalsActions';
import * as authActions from '../../store/actions/parents/authActions';
import * as jobsActions from '../../store/actions/parents/jobsActions';
import * as chequingActions from '../../store/actions/parents/chequingActions';
import * as purchasesActions from '../../store/actions/parents/purchasesActions';
import * as savingsActions from '../../store/actions/parents/savingsActions';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import {
    eventLog,
    setVersion,
    setUserId,
} from '../../helpers/analytics/analytics';
import { sendNotification, setUpParentNotifications } from '../../helpers/notifications/notifications';
import { IS_WEB } from '../../constants/constants';
import { formatNumberFloat } from '../../helpers/formatNumber';
import { copyFbRecord, TODORemove, TODORemoveButton } from '../../helpers/dev/runDev';
import { TextSkeleton } from '../../components/skeleton/TextSkeleton';
import { ChildDashboardCard } from '../../components/Dashboard/ChildCard';
import { SHOW_ACADEMY } from '../../constants/config';

const appType = 'pennygem-parents';

const ACTIVITY_GUIDES = [
    {title: 'Art Module (v2)', item: require('assets/Academy/activity_guides/CCTP - Art Module (v2) -ENG.pptx')},
    {title: 'Business Module (v2)', item: require('assets/Academy/activity_guides/CCTP - Business Module (v2) -ENG.pptx')},
    {title: 'English Module (v3)', item: require('assets/Academy/activity_guides/CCTP - English Module (v3) -ENG.pptx')},
    {title: 'Health and Fitness Module (v2)', item: require('assets/Academy/activity_guides/CCTP - Health and Fitness Module (v2) -ENG.pptx')},
    {title: 'Science Module (v3)', item: require('assets/Academy/activity_guides/CCTP - Science Module (v3) -ENG.pptx')},
    {title: 'Social Sciences Module (v3)', item: require('assets/Academy/activity_guides/CCTP - Art Module (v2) -ENG.pptx')}
];
const TEACHER_GUIDES = [
    {title: 'Business Teacher`s Guide (v2)', item: require('assets/Academy/teacher_guides/CCTP -  Business Teacher_s Guide (v2).pptx')},
    {title: 'English Teacher`s Guide (v2)', item: require('assets/Academy/teacher_guides/CCTP -  English Teacher_s Guide (v2).pptx')},
    {title: 'Health and Fitness Teacher`s Guide (v1)', item: require('assets/Academy/teacher_guides/CCTP -  Health and Fitness Teacher_s Guide (v1)_.pptx')},
    {title: 'Science Teacher`s Guide (v1)', item: require('assets/Academy/teacher_guides/CCTP -  Science Teacher_s Guide (v1).pptx')},
    {title: 'Social Sciences Teacher`s Guide (v2)', item: require('assets/Academy/teacher_guides/CCTP -  Social Sciences Teacher_s Guide (v2).pptx')},
    {title: 'Art Teacher`s Guide (v1)', item: require('assets/Academy/teacher_guides/CCTP_- _Art_Teacher_s Guide_(v1).pptx')}
];

const database = firebase.database;

const VerifyEmailInfo = ({ navigation }) => {
    const onAppDownloadPress = () => {
        // TODO add functions for differeny systems
        // navigation.navigate('')
    };

    return (
        <View style={emailStyles.container}>
            <View style={emailStyles.innerContainer}>
                <Text>Please verify email</Text>
                {!IS_WEB && (
                    <>
                        <Text style={emailStyles.text}>
                            Install Pennygem Kids for your child can join you
                        </Text>
                        <TouchableNativeFeedback onPress={onAppDownloadPress}>
                            <View style={emailStyles.download}>
                                <Text style={{ color: 'white' }}>
                                    Download app
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </>
                )}
            </View>
        </View>
    );
};

const NoKidsInfo = ({ navigation }) => {
    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Text
                style={{
                    margin: 30,
                    marginBottom: 80,
                    textAlign: 'center',
                    fontFamily: 'Carmen Sans Bold',
                }}
            >
                No children yet
            </Text>
            <TouchableNativeFeedback
                onPress={() =>
                    navigation.navigate('Settings', {
                        category: 'child',
                    })
                }
            >
                <View
                    style={
                        {
                            // backgroundColor: Colors.green1, borderRadius: 10, padding: 10,shadowColor: "#272827", shadowOffset: { width: 2, height: 2, }, shadowOpacity: 0.75, shadowRadius: 10,
                        }
                    }
                >
                    <Text
                        style={[
                            {
                                textDecorationLine: 'underline',
                                fontSize: 35,
                            },
                            defaultStyles.bold,
                        ]}
                    >
                        Add child
                    </Text>
                </View>
            </TouchableNativeFeedback>
        </View>
    );
};

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.descriptionRef = React.createRef();
        this.dateRef = React.createRef();

        this.myRef = {};

        this.state = {
            data: {},
            modalVisible: true,
            id: 0,
            isLoading: true,
            jobKids: {},
        };

        this.renderCard = this.renderCard.bind(this);
    }

    componentDidMount() {
        const { actions, auth, parent, navigation } = this.props;
        const { parentId } = auth;
        const { childIds, id } = this.props.parent;
        const childId = childIds[id];

        const env_type = process.env;
        // const env_type = process.env.ENV_TYPE;

        console.log('env_type - ', env_type);
        // ANALYTICS BLOCK
        eventLog({ eventName: 'dashboard_screen', properties: {} });
        setUserId({ userId: parentId });
        setVersion();
        // Analytics.setCurrentScreen('parents/Dashboard');
        // Analytics.logEvent('page_view', { page_path: 'parents/Dashboard', page_title: 'Dashboard' })

        if (!auth.parentId) {
            this.props.actions.clearAuth().then(() => {
                this.props.navigation.navigate('Auth');
            });
        } else {
            // Analytics.setUserId(this.props.auth.parentId)
        }

        setUpParentNotifications();

        requestTrackingPermissionsAsync().then((status) => {
            if (status === 'granted') {
                console.log('Yay! I have user permission to track data');
            }
        });

        // Get data for dashboard
        actions.getDashboardData({ parentId, childId });

        actions
            .getChildNodes(parentId)
            .then((childNodes) => {
                //   navigation.navigate('Settings')
                const { childIds, id } = this.props.parent;
                const childId = childIds[id];

                this.setState({ isLoading: false });
                const children = childNodes
                    ? Object.values(childNodes)
                    : [];

                children.length > 0
                    ? actions.getDashboardData({ parentId, childId })
                    : this.props.navigation.navigate('NewKid');


                actions.requestChildrenData({ parentId, childIds });
                actions.requestChildrenChequingBalances({ parentId, childIds });
                actions.requestChildrenSavingsBalances({ parentId, childIds });

                for (const i in children) {
                    actions.getChildName(children[i]);
                }
            })
            .catch((error) => {
                if (error.code === 'PERMISSION_DENIED') {
                    this.props.navigation.navigate('Auth');
                }

                console.log(error.code);
            });
    }

    componentWillUnmount() {
        // Remove the event listener
        console.log('UNMOUNTED MAIN!');
        // this.focusListener.remove();
    }

    getAccumulateData(move) {
        const { actions } = this.props;
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;
        const { changeChildIndex } = this.props.actions;

        const index = id + move;

        changeChildIndex(move).then(() => {
            if (index > childIds.length - 1) {
                this.setState({ id: 0 });
                actions.getDashboardData({ parentId, childId: childIds[0] });

                return 0;
            }

            if (index < 0) {
                this.setState({ id: childIds.length - 1 });
                actions.getDashboardData({
                    parentId,
                    childId: childIds[childIds.length - 1],
                });

                return 0;
            }

            this.setState({ id: index });
            actions.getDashboardData({ parentId, childId: childIds[index] });

            return 0;
        });
    }

    defineValue(width, height, a, b, c) {
        if (width < 850 && width < height) {
            return a;
        } else if (width < 850 && width > height) {
            return b;
        } else {
            return c;
        }
    }

    renderCard({ item }) {
        const { childIds } = this.props.parent;
        const { navigation } = this.props;

        return <ChildDashboardCard
        key={item}
        onPress={() => { navigation.navigate('Dashboard', { childId: item }) }}
        childId={item}
        actions={this.props.actions}
        onNewJobPress={() => openNewJobModal({})}
    />
    }

    renderHeader() {

        return (<View style={{
            width: '90%',
            //  width: Dimensions.get('window').width*0.9,
            maxWidth: 600,
            flexDirection: 'row',
            // alignSelf: IS_WEB ? 'center' : 'flex-start',
            alignItems: 'center',
            justifyContent: 'flex-start'}}>
            <Image source={require('assets/Pennygem_Logo.png')} resizeMode={'contain'}
            style={{width: 65, height: 65 }} />
            <Text style={{ marginLeft: 10, ...globalStyle.h2, ...defaultStyles.bold}}>Hello, {'\n'}Parent!</Text>
         </View>
         )
    }

    renderAcademyParentResources() {

        const renderActivityGuide = (item, index) => {
            return <TouchableOpacity onPress={() => {
                Linking.openURL(item.item);
            }}>
                <Text style={{textDecorationLine: 'underline', color: 'blue'}}>{item.title}</Text>
            </TouchableOpacity>
        }
        return SHOW_ACADEMY ? <View style={{marginVertical: 50}}>
            <Text style={{...globalStyle.h3}}>Cybersecurity Resources for Teachers:</Text>
            <Text style={{...globalStyle.h4}}>Modules for classroom</Text>
            {/* <Text>Resources for Teachers:</Text> */}
            {TEACHER_GUIDES.map(renderActivityGuide)}
            <Text style={{...globalStyle.h4, marginTop: 30}}>Activity guides</Text>
            {ACTIVITY_GUIDES.map(renderActivityGuide)}
        </View> : null;
    }

    render() {
        const { parent } = this.props;
        const { childIds } = parent;

        return (
            <ScrollView style={{ flex: 1, backgroundColor: 'white', paddingBottom: 50, }}
                contentContainerStyle={{alignItems: 'center'}}>

                {/* TODO remove in future if this block is not nessessary */}
                {/* {!emailVerified && (
                    <VerifyEmailInfo navigation={navigation} />
                )} */}

                {childIds.length === 0 && !this.state.isLoading && (
                    <NoKidsInfo
                        navigation={this.props.navigation}
                    />
                )}

                <DevNotificationTokenBlock />

                <TODORemoveButton
                    title={'create test child'}
                    onPress={() => {
                        // ciEn1XZ28aPp2WDydepFiBrBcNz1/xTgmI6fzc8XrwHkHmFRtLI7pjha2
                        const oldRef = database().ref(`user_type/xTgmI6fzc8XrwHkHmFRtLI7pjha2`);
                        // zsmyEaYN6Qh6hnQQzfvA8VmGcjo1/TlalEaeIrGXk3Z5RL5fg6UX1ma93
                        const newRef = database().ref(`user_type/TlalEaeIrGXk3Z5RL5fg6UX1ma93`);

                        copyFbRecord(oldRef, newRef);
                    }}
                />

                {this.renderHeader()}
                {childIds.map((item, index) => this.renderCard({ item, index }))}
                {this.renderAcademyParentResources()}
            </ScrollView>
        );
    }
}

export default connect(
    (state) => {
        return {
            parent: state.parent,
            chequing: state.chequing,
            savings: state.savings,
            auth: state.auth,
            goals: state.goals,
            jobs: state.jobs,
            purchases: state.purchases,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            {
                ...authActions,
                ...goalsActions,
                ...purchasesActions,
                ...chequingActions,
                ...jobsActions,
                ...savingsActions,
            },
            dispatch
        ),
    })
)(Dashboard);

const styles = StyleSheet.create({
    emailStyles: {
        // sfwef
    },
});

const emailStyles = StyleSheet.create({
    container: {
        elevation: 5,
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 20,
        backgroundColor: 'rgba(128,128,128,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContainer: {
        zIndex: 20,
        backgroundColor: 'rgba(255,255,255,0.9)',
        paddingHorizontal: 20,
        paddingVertical: 10,
        width: 300,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        marginTop: 10,
        paddingTop: 10,
        borderTopWidth: 1,
        textAlign: 'center',
    },
    download: {
        marginTop: 10,
        backgroundColor: Colors.green1,
        borderRadius: 20,
        padding: 10,
    },
});
