/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useRef, useState } from 'react';
import {
    findNodeHandle,
    Image,
    Platform,
    StyleSheet,
    View,
    TouchableOpacity,
    LayoutAnimation,
} from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import moment from 'moment';
import { useNavigation } from 'react-navigation-hooks';

import { useSelector, useDispatch } from 'react-redux';

import Text from '../CustomText';
import TouchableNativeFeedback from '../TouchableFeedback';
import { useAppTour } from '../AppTour/useAppTour';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import { formatNumberFloat } from '../../helpers/formatNumber';
import { TextSkeleton } from '../skeleton/TextSkeleton';
import { Avatar } from '../Avatar';
import { FONTS } from '../../constants/fonts';
import { approveJob, declineJob, getJobs } from '../../store/actions/parents/jobsActions';
import { onTransferPress } from '../../helpers/chequing/transferModals';

const maxWidth = 700;
const widthThreshold = 600;
const borderRadius = 25;

const BottomButton = ({ color, title, onPress, image, style }) => {
    return (
        <TouchableNativeFeedback
            style={[{ width: '100%' }]}
            {...(Platform.OS === 'web'
                ? { onPress: onPress }
                : { onPress: onPress })}
        >
            <View
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    borderBottomLeftRadius: borderRadius,
                    borderBottomRightRadius: borderRadius,
                    backgroundColor: color,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 45,
                }}
            >
                {image ? (
                    <Image
                        style={{
                            height: 35,
                            width: 35,
                            alignSelf: 'flex-start',
                        }}
                        resizeMode={'cover'}
                        source={image}
                    />
                ) : (
                    <Text style={[{ color: 'white' }, globalStyle.h1]}>
                        {title}
                    </Text>
                )}
                {/* <Icon style={{ zIndex: 3, marginVertical: 10 }} name="plus" size={25} color={'white'} /> */}
            </View>
        </TouchableNativeFeedback>
    );
};

const CornerIcon = ({ color, image, layout }) => {
    const resizeConst = 360 / 450;
    const width = layout.width * 0.1;
    const height = resizeConst * width;
    const minWidth = 45,
        minHeight = resizeConst * minWidth;
    const maxWidth = 65,
        maxHeight = resizeConst * maxWidth;

    return (
        <View
            style={{
                zIndex: -1,
                position: 'absolute',
                left: 0,
                top: 0,
            }}
        >
            <Image
                resizeMode={'contain'}
                style={{
                    borderTopLeftRadius: borderRadius,
                    width,
                    height,
                    minWidth,
                    minHeight,
                    maxHeight,
                    maxWidth,
                    alignSelf: 'flex-start',
                }}
                source={image}
            />
        </View>
    );
};

const InfoBlock = ({
    title,
    amount,
    onPress,
    style,
    amountStyle,
    titleStyle,
    center,
    isLoading,
}) => {
    return (
        <TouchableNativeFeedback style={style} onPress={onPress}>
                {isLoading ? (
                    <TextSkeleton
                        // highlightColor={'blue'}
                        containerStyle={[
                            { height: amountStyle?.lineHeight || 20, width: 45, opacity: 0.4, },
                            amountStyle,
                        ]}
                    />
                ) : (
                    <Text
                        style={[
                            globalStyle.h2,
                            defaultStyles.bold,
                            amountStyle,
                        ]}
                    >
                        {amount}
                    </Text>
                )}
                <Text style={[{ marginTop: 5 }, titleStyle]}>{title}</Text>
        </TouchableNativeFeedback>
    );
};

const EmptyBlockPlaceholder = ({ source, title, subtitle }) => {
    return <View style={{alignItems: 'center', marginVertical: 20, marginTop: 50}}>
        <Image source={source} style={{width: 90, height: 90}} />
        <Text style={{ marginTop: 10, ...globalStyle.h4 }}>{title}</Text>
        <Text>{subtitle}</Text>
    </View>;
}

export const BalanceCard = ({
    style,
    navigation,
    earned,
    childId,
    saved,
    spent,
    type,
    onMenuPress,
    isLoading,
}) => {

    const { uri, avatar } =useSelector(state => state.parent.settings[childId] || {})
const cardWidth = '90%';

const upButton = () => {
    onTransferPress({type: 'deposit', childId})
 }
const downButton = () => {
    onTransferPress({type: 'withdrawal', childId})
 }

return (
        <View
            // ref={ref}
        elevation={15}
        style={[
        {
            // flexDirection: 'row',
            borderRadius,
            backgroundColor: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
            // overflow: 'hidden',
            width: cardWidth,
            maxWidth: maxWidth,
        },
        defaultStyles.shadow,
        style,
        ]}
    >
        <Avatar
        uri={uri}
        index={avatar}
        size={80}
        containerStyle={{position: 'absolute', top: -45}}
        // containerStyle, textStyle,
        />
        {/* <View
        // style={{position: 'absolute',right: 20, top: 10}}
        > */}
        <TouchableOpacity
        style={{ zIndex: 10, position: 'absolute',right: 20, top: 10}}

        hitSlop={{top: 15, bottom: 15, left: 15, right: 15}} onPress={onMenuPress}>
            {[1,2,3].map(item => <View key={item} style={{ margin: 2, backgroundColor: Colors.green1, width: 3, height: 3}} />)}
        </TouchableOpacity>
        {/* </View> */}
        <View
            style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                // alignItems: 'center',
                width: '100%',

                paddingVertical: 10,
                paddingTop: 50,
            }}
        >
            <InfoBlock
                style={{width: '50%', alignItems: 'center'}}
                onPress={() =>
                    navigation.navigate('Chequing', { cards: 'Chequing' })
                }
                title={'Chequing'}
                amount={'$' + formatNumberFloat(earned)}
                amountStyle={{ ...globalStyle.h3, color: Colors.green1 }}
                titleStyle={{...style.h3 }}
                isLoading={isLoading}
            />
                <InfoBlock
                style={{width: '50%', alignItems: 'center'}}
                onPress={() =>
                    navigation.navigate('Chequing', {
                        cards: 'Savings',
                    })
                }
                title={'Savings'}
                amount={'$' + formatNumberFloat(saved)}
                titleStyle={{ ...style.h3 }}
                amountStyle={{ ...globalStyle.h3 }}
                isLoading={isLoading}
                />
        </View>
        <View
            style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
            }}
        >
                {[
                        {
                            color: '#8DBF52',
                            sign: 'plus',
                            onPress: upButton,
                            text: 'Deposit',
                        },
                        {
                            color: '#D6757C',
                            sign: 'minus',
                            onPress: downButton,
                            text: 'Withdraw',
                        },
                    ].map((item, index) => (
                        <TouchableNativeFeedback
                            key={index}
                            style={{
                                    backgroundColor: item.color,
                                    width: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 45,
                                    borderTopLeftRadius: index === 0 ? 50 : 0,
                                    borderBottomLeftRadius: index === 0 ? 50 : 0,
                                    borderTopRightRadius: index === 0 ? 0 : 50,
                                    borderBottomRightRadius: index === 0 ? 0 : 50,
                                }}
                            onPress={item.onPress}
                        >
                            <Text style={{color: 'white'}}>{item.text}</Text>
                        </TouchableNativeFeedback>
                        )
                    )}
                </View>
        </View>
    );
};

const ActionButton = ({ onPress, text, icon, color }) => {
    return <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}} onPress={onPress}>
        <Image source={icon} resizeMode={'contain'} style={{
            marginHorizontal: 5,
            tintColor: color, margin: 3, width: 12, height: 12}} />
        <Text style={{  color, ...defaultStyles.bold }}>{text}</Text>
    </TouchableOpacity>
}

const JobRow = ({job, index, parentId, childId}) => {
    const dispatch = useDispatch();
    const [approved, setApproved] = useState(false);
    const [denied, setDenied] = useState(false);

    const calculateDaysDistance = (day1, day2) => {
        console.log({day1, day2});
        const diff = moment(day1).diff(moment(day2), 'days');
        return diff > 1 ? `${diff} days ago` : `Today`;
    }

    const daysDistanceTextColor = (day1, day2) => {
        const diff = moment(day1).diff(moment(day2), 'days');
        return diff > 1 ? 'rgba(40, 40, 40, 0.5)' : Colors.red;
    }

    const isPending = job.completed === 1 && job.approved === 0 && job.action === 0;
    const status = isPending ? 'Pending' : 'Active';
    // const isPending = job.completed === 1 && job.approved === 0 && job.action === 0;
    console.log({job})
    return <>
    {index!== 0 && <View style={{ height: 1, backgroundColor: '#ebebeb'}} />}
    <View style={{ width: '100%', alignSelf: 'center'   }}>
        {denied && <View style={{ zIndex: 2, position: 'absolute', width: '100%', height: '100%', backgroundColor: '#DC5B64', justifyContent: 'center', alignItems: 'center'}} >
            <Text style={{color: 'white'}}>Denied</Text></View>}
        {approved && <View style={{ zIndex: 2, position: 'absolute', width: '100%', height: '100%', backgroundColor: '#8DBF52', justifyContent: 'center', alignItems: 'center'}} >
            <Text style={{color: 'white'}}>Approved</Text></View>}
        <View style={{padding: 20}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <View style={{  backgroundColor: isPending ? Colors.yellow : Colors.navy, padding: 5, paddingHorizontal: 10, borderRadius: 20 }}>
                <Text style={{ fontSize: 11, color: 'white'}}>{status}</Text></View>
            {(isPending) ?
            (job.completedDate ? <Text>Completed: {job.completedDate}</Text> : null)
            : <Text style={{fontSize: 11, color: daysDistanceTextColor(
                moment(), new Date(job.deadline)
            )}}>Due: {calculateDaysDistance(moment(), new Date(job.deadline))}</Text>}
        </View>
        <Text style={{marginTop: 5, marginBottom: 10, fontFamily: FONTS.FONT_BOLD}}>{job.category}</Text>
        <Text style={{marginBottom: 10}}>{job.description}</Text>
        {isPending ? <View style={{width: '100%',flexDirection: 'row', justifyContent: 'flex-end'}}>
            <ActionButton color={Colors.green1} text={'Approve'}
                icon={require('assets/dashboard/Approve.png')} onPress={() => {
                    setApproved(true);
                    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    dispatch(approveJob(parentId, childId, job));
                    setTimeout(() => {
                        dispatch(getJobs(parentId, childId));
                        setApproved(false);
                        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    }, 1000);

                }} />
            <ActionButton color={Colors.red} text={'Deny'}
                icon={require('assets/dashboard/Deny.png')} onPress={() => {
                    setDenied(true);
                    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    dispatch(declineJob(parentId, childId, job));
                    setTimeout(() => {
                        dispatch(getJobs(parentId, childId));
                        setDenied(false);
                        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    }, 1000);

                }} />

        </View> : null}
        </View>
    </View>
    </>
}


export const JobsCard = ({
    childId,
    style,
    isLoading,
    onPress,
    jobs,
    onAddJobPress,
}) => {
    const navigation = useNavigation();
    const parentId = useSelector(state => state.parent.parentId);

    const renderJobInfo = ({job, index}) => {
        // const isPending = job.completed === 1 && job.approved === 0 && job.action === 0;
        console.log({job})
        return <JobRow job={job} index={index} parentId={parentId} childId={childId} />
    }

    return (
        <TouchableOpacity onPress={() => navigation.navigate('Jobs')}
        style={{backgroundColor: 'white', width: '90%', borderRadius: 20,marginVertical: 20, ...defaultStyles.shadow, alignItems: 'center'}}>
        <View
            style={[
                {
                    width: '100%',
                    paddingVertical: 20, borderRadius: 25, backgroundColor: 'white',
                    marginVertical: 10,
                },
                // defaultStyles.shadow,
                style,
            ]}
        >
            <TouchableOpacity
            onPress={onAddJobPress}
            style={{position: 'absolute',
            right: 15, top: 13, borderWidth: 1, borderColor: Colors.green1, borderRadius: 15, paddingHorizontal: 15, paddingVertical: 5, paddingHorizontal: 15}}
            >
                <Text style={{color: Colors.green1}}>Add</Text>
            </TouchableOpacity>
            <Text style={{alignSelf: 'center', ...defaultStyles.bold, ...globalStyle.h4, marginBottom: 20}}>Jobs</Text>
            {jobs.map((job, index) => renderJobInfo({ job, index }))}
            {(jobs.length === 0 && !isLoading) && <EmptyBlockPlaceholder source={require('assets/cape_new.gif')} title={'No jobs have been added!'} />}
        </View>
        </TouchableOpacity>
    );
};

export const GoalsCard = ({
    childId,
    style,
    onPress,
    isLoading,
    mainGoal,
    balance
}) => {
    const navigation = useNavigation();
    const percent = (balance / mainGoal?.amount) > 1 ? 100 : (balance / mainGoal?.amount)*100

    const renderGoalInfo = () => {
        // const isPending = job.completed === 1 && job.approved === 0 && job.action === 0;
        if (!mainGoal) return null;

        return <>
        {/* {index!== 0 && <View style={{height: 1, marginVertical: 20, backgroundColor: '#ebebeb'}} />} */}
        <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center'}}>
            <View style={{ width: 3, height: '100%', backgroundColor: Colors.green1, borderRadius: 5}} />
            <View style={{flex:1, marginLeft: 20, paddingTop: 5}}>
                <Text style={{ marginBottom: 7, fontFamily: FONTS.FONT_BOLD}}>${mainGoal?.amount}</Text>
                <Text style={{ marginBottom: 7,}}>{mainGoal.name}</Text>
            </View>
            <Image source={require('assets/ArrowSmall.png')} resizeMode={'contain'}
            style={{transform: [{rotate: '180deg'}], tintColor: 'black', width: 15, height: 15}} />
        </View>
        <AnimatedCircularProgress
                size={130}
                width={15}
                rotation={0}
                lineCap="round"
                fill={percent}
                tintColor={Colors.green1}
                backgroundColor={Colors.gray}>
                {
                    (fill) => (
                        <>
                    <Text style={{...globalStyle.h3, ...defaultStyles.bold,marginVertical: 4}}>
                        ${parseInt(balance)}
                    </Text>
                    <Text>
                        {fill}%
                    </Text>
                    </>
                    )
                }
            </AnimatedCircularProgress>
        </>
    }

    return (
        <TouchableOpacity onPress={() => navigation.navigate('Goals')}
        style={{width: '90%', ...defaultStyles.shadow, borderRadius:20, backgroundColor: 'white', alignItems: 'center'}}>
        <View
            style={[
                {
                    width: '90%',
                    padding: 15, borderRadius: 25, backgroundColor: 'white',
                    marginVertical: 10,
                    alignItems: 'center',
                },
                // defaultStyles.shadow,
                style,
            ]}
        >
            <Text style={{alignSelf: 'center',
        ...defaultStyles.bold, ...globalStyle.h4, marginBottom: 20}}>Goals</Text>
            {renderGoalInfo()}
            {(!mainGoal && !isLoading) && <EmptyBlockPlaceholder source={require('assets/Goals/Goal_4.png')}
            title={'No goals have been set!'} />}

        </View>
        </TouchableOpacity>
    );
};

export const ActivityCard = ({
    layout,
    title,
    onPress,
    buttonTitle,
    onButtonPress,
    style,
    image,
    amount,
    color,
    cornerImage,
    type,
    isLoading,
}) => {
    const width = layout.width;
    const vmin = Math.min(...[layout.width, layout.height]);

    const cardWidth = width > 850 ? '48%' : '90%';

    return (
        <View
            elevation={15}
            style={[
                {
                    flex: 1,
                    borderRadius,
                    width: cardWidth,
                    alignItems: 'center',
                    backgroundColor: 'white',
                },
                defaultStyles.shadow,
                style,
            ]}
        >
            <CornerIcon color={color} image={cornerImage} layout={layout} />
            <InfoBlock
                style={{ width: '100%', flex: 1, justifyContent: 'center' }}
                onPress={onPress}
                title={title}
                center={true}
                amount={amount}
                titleStyle={{}}
                amountStyle={globalStyle.h3}
                isLoading={isLoading}
            />
            {type === 'child' ? null : (
                <BottomButton
                    style={{ flex: 1, borderWidth: 1, width: '100%' }}
                    image={image}
                    onPress={onButtonPress}
                    color={color}
                    title={buttonTitle}
                />
            )}
        </View>
    );
};

export const PurchasesCard = ({
    layout,
    info,
    onPress,
    onButtonPress,
    style,
    isLoading,
}) => {
    const vmin = Math.min(...[layout.width, layout.height]);

    return (
        <TouchableNativeFeedback
            style={{
                flex: 0.48,
                backgroundColor: 'white',
                // elevation: 10,
                borderRadius,
                // flex: 1,
                maxWidth: maxWidth,
                width: '100%',
                flexDirection: 'row',
                backgroundColor: 'white',
                //    borderWidth: 1,
                paddingTop: 0.05 * vmin,
                paddingBottom: 0.02 * vmin,
                ...defaultStyles.shadow,
            }}
            onPress={onPress}
        >
            {/* <View  style={[{
            // elevation: 10,
            borderRadius,
            flex: 1, maxWidth: maxWidth, width: '100%', flexDirection: 'row',
            backgroundColor: 'white',
            borderWidth: 1,
            // paddingTop: 0.05 * vmin, paddingBottom: 0.02 * vmin,
        }, defaultStyles.shadow,]}> */}
            <View style={{ paddingLeft: 0.05 * vmin }}>
                <View
                    style={{
                        flex: 0.2,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {/* <Icon style={{ zIndex: 3, marginRight: 10, }} name="dollar-sign" size={20} color={Colors.red} /> */}
                    <Text style={[{ color: Colors.red }, defaultStyles.bold]}>
                        Purchases
                    </Text>
                    <Image
                        style={{ width: 20, height: 20, marginLeft: 5 }}
                        source={require('assets/dashboard/Money_1.png')}
                    />
                </View>
                <View style={{ flex: 1, justifyContent: 'center' }}>
                    {info.map((item, index) => (
                        isLoading ?
                            <TextSkeleton
                            // highlightColor={'blue'}
                            containerStyle={[
                                { height: 16, width: 75, marginVertical: 5, opacity: 0.4, },
                                // amountStyle,
                            ]}
                        />
                            : <Text key={index} style={{ marginVertical: 5 }}>
                            <Text style={{}}>{item[0]}</Text> {item[1]}
                        </Text>
                    ))}
                </View>
            </View>
            {/* </View> */}
        </TouchableNativeFeedback>
    );
};

export const StoreCard = ({ layout, onPress, style }) => {
    const vmin = Math.min(...[layout.width, layout.height]);
    const smallCircleSize = layout.height * 0.1;
    const circleSize =
        layout.width > 600 ? layout.height * 0.12 : layout.height * 0.17;
    const storeImageSize =
        layout.width > 600 ? layout.height * 0.13 : layout.height * 0.16;
    const textTopMargin =
        layout.width > 600 ? layout.height * 0.09 : layout.height * 0.11;

    return (
        <>
            <TouchableNativeFeedback
                style={{
                    flex: 0.48,
                    zIndex: 2,
                    backgroundColor: Colors.green1,
                    overflow: 'hidden',
                    alignItems: 'center',
                    borderRadius,
                    ...defaultStyles.shadow,
                }}
                onPress={onPress}
            >
                {/* <View style={[{
                alignItems: 'center',
                backgroundColor: Colors.green1,
                width: '100%', flex: 1, zIndex: 1,
                borderRadius,
                // borderWidth: 3, borderColor: Colors.green1,
                // justifyContent: 'center',
                overflow: "hidden",
            }, defaultStyles.shadow,]}> */}
                <Text
                    style={[
                        { color: 'white', marginTop: textTopMargin },
                        defaultStyles.bold,
                    ]}
                >
                    Store
                </Text>
                <View
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        left: -smallCircleSize / 4,
                        top: -smallCircleSize / 2.5,
                        overflow: 'visible',
                        height: smallCircleSize,
                        width: smallCircleSize,
                        borderRadius: smallCircleSize / 2,
                        backgroundColor: '#9ee459',
                    }}
                />
                <View
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        right: -circleSize / 4,
                        bottom: -circleSize / 4,
                        overflow: 'visible',
                        height: circleSize,
                        width: circleSize,
                        borderRadius: circleSize / 2,
                        backgroundColor: '#9ee459',
                    }}
                />
                {/* </View> */}
            </TouchableNativeFeedback>
            <View
                pointerEvents="none"
                style={{
                    elevation: 10,
                    zIndex: 11,
                    position: 'absolute',
                    overflow: 'visible',
                    right: -storeImageSize / 10,
                    bottom: -storeImageSize / 10,
                }}
            >
                <Image
                    style={{
                        zIndex: -1,
                        height: storeImageSize,
                        width: storeImageSize,
                        zIndex: 10,
                    }}
                    resizeMode={'contain'}
                    source={require('assets/dashboard/store_icon.png')}
                />
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    depositButton: {
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 70,
        maxWidth: 70,
    },
});
