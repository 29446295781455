import firebase from 'firebase/app';
import 'firebase/database';
import { useSelector } from 'react-redux';

const database = firebase.database;

function formatDeadline(time, today) {
    time = new Date(time);
    time = new Date(time.valueOf() + today.getTimezoneOffset()*60*1000)
    time.setHours(23)
    time.setMinutes(59)
    time.setSeconds(59)
    return time
}

export async function getDashboardData({ parentId, childId }) {
        console.log('LAST ONE')

        let today = new Date()
        let interval = new Date()
        interval.setMonth(interval.getMonth()-1)

        const db_ref = database().ref('/users/' + parentId + '/' + childId);
        // var balance_source = database().ref('/users/'+parentId+'/'+childId+'/chequing/balance');
        let chequing = (await db_ref.child('chequing/balance').once('value')).val();
        let savings = (await db_ref.child('savings/balance').once('value')).val();

        // var balance_source = database().ref('/users/'+parentId+'/'+childId+'/chequing/balance');
        let jobs = (await db_ref.child('jobs').orderByChild('completed').equalTo(0).once('value')).val()
        // console.log('JOVA!!! ', jobs)

        jobs = jobs ? Object.values(jobs).filter(item => { return formatDeadline(item.deadline, today) >= today && item.action === 0 }) : []
        jobs = jobs.length

        // console.log('JOVA!!! ', jobs)

        let goals = (await db_ref.child('goal').orderByChild('active').equalTo(1).once('value')).val()

        goals = goals ? Object.values(goals) : []
        goals = goals.length

        let purchases_approved = (await db_ref.child('purchases/approved').orderByChild('approval_date').startAt(interval.toISOString()).once('value')).val()
        let purchases_pending = (await db_ref.child('purchases/requested').orderByChild('request_date').startAt(interval.toISOString()).once('value')).val()
        purchases_approved = purchases_approved?Object.values(purchases_approved):[]
        purchases_pending = purchases_pending?Object.values(purchases_pending):[]
        let spent = purchases_approved.reduce((a, b) => a + (b.price || 0), 0) + purchases_pending.reduce((a, b) => a + (b.price || 0), 0);
        purchases_approved = purchases_approved.length
        purchases_pending = purchases_pending.length

        return ({chequing, savings, spent, goals, jobs, purchases_approved, purchases_pending})

}