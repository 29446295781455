/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useMemo } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

import Colors from '../constants/Colors';

export const Loader = ({ loading, color }) => {
    const loaderColor = useMemo(() => color || Colors.green1, [color]);

    if (!loading) {
        return null;
    }

    return (
        <View style={loaderStyles.container}>
            <ActivityIndicator size={'large'} color={loaderColor} />
        </View>
    );
};

const loaderStyles = StyleSheet.create({
    container: {
        position: 'absolute',
        zIndex: 10,
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.transparentWhite,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
