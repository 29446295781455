/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useMemo } from 'react';
import {
    Image,
    ScrollView,
    View,
    StyleSheet,
    useWindowDimensions,
} from 'react-native';

import { withNavigation } from 'react-navigation';
import { useSelector } from 'react-redux';
import { useNavigation } from 'react-navigation-hooks';

import Text from '../CustomText';

import TouchableNativeFeedback from '../../components/TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';

const CloseButton = ({ onClosePress}) => {
    const navigation = useNavigation();

    return (
        <View
            style={[
                closeButtonStyles.container,
            ]}
        >
            <TouchableNativeFeedback
                style={{ zIndex: 25 }}
                onPress={onClosePress ? onClosePress : () => {
                    console.log('Close button pressed');
                    navigation.goBack()
                }}
            >
                <View elevation={10} style={closeButtonStyles.imageContainer}>
                    <Image
                        style={closeButtonStyles.image}
                        source={require('assets/Delete-X.png')}
                    />
                </View>
            </TouchableNativeFeedback>
        </View>
    );
};

const closeButtonStyles = StyleSheet.create({
    container: {
        // position: 'absolute',
        zIndex: 10,
        alignSelf: 'flex-end',
    },
    imageContainer: {
        alignSelf: 'center',
        padding: 5,
        margin: 10,
        marginTop: 40,
    },
    image: { height: 30, width: 30 },
});

export const BlockDescription = ({ description }) => {
    return <Text style={styles.description}>{description}</Text>;
};

export const InfoWrapper = ({
    title,
    description,
    children,
    hideCloseIcon,
    showProgress,
    onClosePress,
}) => {
    const childIds = useSelector((store) => store.parent.childIds);

    const layout = useWindowDimensions();

    return (
        <ScrollView
            nestedScrollEnabled={true}
            style={styles.container}
            contentContainerStyle={styles.contentContainer}
        >
            {/* <Loader loading={loading} /> */}
            {hideCloseIcon || !childIds?.length ? null : <CloseButton onClosePress={onClosePress} />}

            <Text style={{...globalStyle.h2, color: '#282828'}}>{title}</Text>
            <View style={[styles.viewContainer,
                    styles.blockContainer,
                    { width: `${layout.width > 850 ? 70 : 90}%` }, ]}>
                {children({ layout: layout })}
            </View>
        </ScrollView>
    );
};

export const BlockWrapper = withNavigation(InfoWrapper);

const styles = StyleSheet.create({
    container: {
        zIndex: 1,
        backgroundColor: 'white',
    },
    contentContainer: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 60,
    },
    viewContainer: {
        width: '100%',
        alignItems: 'center',
    },
    imageContainer: {
        // borderWidth: 1,
        position: 'absolute',
        minWidth: 100,
        minHeight: 100,
        // top:100,
        zIndex: 1,
        left: '50%',
        backgroundColor: 'white',
        // ...defaultStyles.shadow,
    },
    image: {
        minWidth: 100,
        minHeight: 100,
    },
    blockContainer: {
        zIndex: 2,
        minWidth: 350,
        maxWidth: 650,
        // borderWidth:1,
        // top: layout.height / 2,
        // transform: [{ translateY: '-50%' }],
        // ...defaultStyles.shadow,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 15,
        backgroundColor: 'white',
    },
    cropImage: {
        position: 'absolute',
        minWidth: 100,
        minHeight: 100,
    },
    infoContainer: { marginHorizontal: 20 },
    title: {
        ...defaultStyles.bold,
        textAlign: 'center',
        color: Colors.gray0,
        ...globalStyle.h3,
    },
    skipButton: {
        position: 'absolute',
        right: 45,
    },
    skipText: {
        color: Colors.green,
        textDecorationLine: 'underline',
        ...defaultStyles.bold,
    },
    description: {
        textAlign: 'center',
        marginVertical: 5,
        color: Colors.gray1,
    },
    freeSpace: {
        height: 40,
    },
});
