/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useMemo, useState } from 'react';
import {
    ActivityIndicator,
    Image,
    ImageBackground,
    KeyboardAvoidingView,
    StyleSheet,
    ScrollView,
    TextInput,
    View,
    Platform,
} from 'react-native';

import LinearGradient from 'react-native-linear-gradient';
import { useHover } from 'react-native-web-hooks';

import TouchableNativeFeedback from '../TouchableFeedback';
import Text from '../CustomText';

import globalStyle, { defaultStyles } from '../../constants/style';

import Colors from '../../constants/Colors';
import ModalContainer from '../Modal/Modal';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

export const InfoButton = ({ text, textComponent, style }) => {
    const [visible, setVisible] = useState(false);
    const ref = useRef(null);
    const isHovered = useHover(ref);

    useEffect(() => {
        console.log('IS HOVERED - ', isHovered);
    }, [isHovered]);

    const infoContent = useMemo(() => {
        return (
            textComponent || <Text style={infoButtonStyles.text}>{text}</Text>
        );
    }, [text, textComponent]);

    return (
        <React.Fragment>
            <TouchableNativeFeedback
                ref={ref}
                style={[infoButtonStyles.buttonContainer, style]}
                onPress={() => setVisible(!visible)}
            >
                <Text style={infoButtonStyles.buttonText}>?</Text>
                {/* {isHovered && (
                    <View style={infoButtonStyles.webTextContainer}>
                        {infoContent}
                    </View>
                )} */}
            </TouchableNativeFeedback>

            <ModalContainer visible={visible} onClose={() => setVisible(false)}>
                <View style={infoButtonStyles.textContainer}>
                    {textComponent || (
                        <Text style={infoButtonStyles.text}>{text}</Text>
                    )}
                    <TouchableNativeFeedback
                        style={infoButtonStyles.button}
                        onPress={() => setVisible(false)}
                    >
                        <Text style={infoButtonStyles.buttonText}>Got it</Text>
                    </TouchableNativeFeedback>
                </View>
            </ModalContainer>
            {/* <Modal
                animationIn={'zoomIn'}
                animationOut={'zoomOut'}
                animationInTiming={300}
                animationOutTiming={300}
                backdropColor={'darkgrey'}
                backdropOpacity={0.1}
                onBackdropPress={() => setVisible(false)}
                isVisible={visible}
                onRequestClose={() => {
                    setVisible(false);
                }}
            >

            </Modal> */}
        </React.Fragment>
    );
};

const infoButtonStyles = StyleSheet.create({
    buttonContainer: {
        // margin: 10,
        zIndex: 26,
        height: 25,
        width: 25,
        borderRadius: 35,
        backgroundColor: Colors.green1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    textContainer: {
        borderRadius: 15,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        alignItems: 'center',
        maxWidth: 400,
        minHeight: 100,
        paddingVertical: 15,
        paddingHorizontal: 20,
        justifyContent: 'center',
        // boxShadow: '2px 2px 15px #272827',
    },
    text: {
        textAlign: 'center',
        marginTop: 20,
        color: Colors.gray1,
    },
    button: {
        backgroundColor: Colors.green1,
        borderRadius: 5,
        marginVertical: 10,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    buttonText: {
        ...defaultStyles.bold,
        color: 'white',
    },
    webTextContainer: {
        width: 200,
        elevation: 5,
        zIndex: 105,
        position: 'absolute',
        right: 40,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: Colors.gray1,
        borderRadius: 10,
    },
});

export const InfoButtonControlled = ({
    text,
    textComponent,
    visible,
    setVisible,
}) => {
    // const [visible, setVisible] = useState(false);
    return (
        <React.Fragment>
            <TouchableNativeFeedback onPress={() => setVisible(!visible)}>
                <View
                    style={{
                        // margin:10,
                        height: 25,
                        width: 25,
                        borderRadius: 35,
                        backgroundColor: Colors.green1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Text style={{ ...defaultStyles.bold, color: 'white' }}>
                        ?
                    </Text>
                </View>
            </TouchableNativeFeedback>
            {/* {visible ? <View style={{ left: '90%', width: 300, position: 'absolute' }}><Text>Knowing your kid’s age will help us better customize the app features. Only you and your kid will be able to see this
information. This is not a mandatory field.</Text></View> : null} */}
            <Modal
                animationIn={'zoomIn'}
                animationOut={'zoomOut'}
                animationInTiming={300}
                animationOutTiming={300}
                backdropColor={'darkgrey'}
                backdropOpacity={0.1}
                onBackdropPress={() => setVisible(false)}
                isVisible={visible}
                onRequestClose={() => {
                    setVisible(false);
                }}
            >
                <View
                    elevation={5}
                    style={{
                        borderRadius: 15,
                        backgroundColor: 'white',
                        width: '80%',
                        alignSelf: 'center',
                        alignItems: 'center',
                        maxWidth: 400,
                        minHeight: 100,
                        paddingVertical: 15,
                        paddingHorizontal: 20,
                        justifyContent: 'center',
                        boxShadow: '2px 2px 15px #272827',
                    }}
                >
                    {textComponent || (
                        <Text
                            style={{
                                textAlign: 'center',
                                marginTop: 20,
                                color: Colors.gray1,
                            }}
                        >
                            {text}
                        </Text>
                    )}
                    <TouchableNativeFeedback onPress={() => setVisible(false)}>
                        <View
                            style={{
                                backgroundColor: Colors.green1,
                                borderRadius: 5,
                                marginVertical: 10,
                                paddingHorizontal: 20,
                                paddingVertical: 10,
                            }}
                        >
                            <Text
                                style={{
                                    ...defaultStyles.bold,
                                    color: 'white',
                                }}
                            >
                                Got it
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </Modal>
        </React.Fragment>
    );
};

export const Button = ({ disabled, title, onPress, style }) => {
    // onPress={onPress}
    return (
        <TouchableNativeFeedback
            disabled={disabled}
            style={{ opacity: disabled ? 0.7 : 1 }}
            onPress={onPress}
            // {...Platform.OS === 'web' ? { onClick: onPress } : { onPress: onPress }}
        >
            <LinearGradient
                // start={[0, 1]}
                // end={[1, 0]}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                colors={[Colors.green2, Colors.green1]}
                // colors={['#de3c95', '#ffd71b']}
                style={[
                    {
                        opacity: disabled ? 0.7 : 1,
                        zIndex: 1,
                        width: 120,
                        margin: 10,
                        justifyContent: 'center',
                        height: 40,
                        backgroundColor: '#7ec13d',
                        borderRadius: 20,
                    },
                    style,
                ]}
            >
                <Text
                    style={{
                        color: 'white',
                        alignSelf: 'center',
                        ...defaultStyles.bold,
                        ...globalStyle.h4,
                    }}
                >
                    {title.toUpperCase()}
                </Text>
            </LinearGradient>
        </TouchableNativeFeedback>
    );
};
