
import firebase from 'firebase/app';

const VIMEO_API_URL = 'https://vimeo.com/api/oembed.json?url=';
const YOUTUBE_API_URL = url => `https://img.youtube.com/vi/${url}/default.jpg`;

export async function getVimeoVideoThumbnail(url) {
    const value = await fetch(VIMEO_API_URL + url);
    const valueJson = await value.json();

    // "thumbnail_url", "thumbnail_url_with_play_button"
    return valueJson.thumbnail_url;
}

// rc_Y6rdBqXM
export async function getYoutubeVideoThumbnail(url) {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const id = undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
    return YOUTUBE_API_URL(id);
}

export async function getVideoThumbnail(url) {
    if (url.indexOf('youtube') !== -1) {
        return await getYoutubeVideoThumbnail(url);
    }
    return await getVimeoVideoThumbnail(url);
}

//