/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Icon from 'react-native-vector-icons/FontAwesome5';

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
import Text from '../../../components/CustomText';
import { BlockWrapper } from '../../../components/newChild/BlockWrapper';
import { InputRow } from '../../../components/settings/rows/InputRow';
import { NewChildFooter } from '../../../components/newChild/NewChildFooter';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { expenseInfo, chequingInfo } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';

const title = 'Chequing Account Setup';
const description = `These Chequing account settings reflect the real-world banking. The settings below are our suggestions, you can change them.`;

class Chequing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(chequingInfo).reduce(
                (prev, curr, currIndex, arr) => {
                    return { ...prev, [curr]: chequingInfo[curr].default };
                },
                {}
            ),
            ...this.props.parent.newKid.chequing,
            advanced: true,
        };
    }

    row({ info, item }) {
        const data = info[item];

        console.log('LABEL ', info, item, data);

        return (
            <InputRow
                text={this.state[item]}
                onChangeText={(text) =>
                    this.setState({
                        [item]: data.format ? data.format(text) : text,
                    })
                }
                // error=
                inputParams={data.params}
                label={data.label}
                infoButtonText={data.text}
            />
        );
    }

    proceed() {
        const {
            min_balance,
            monthly_fee,
            transact_limit,
            transact_fee,
            advanced,
        } = this.state;

        this.props.kidData({
            type: 'chequing',
            data: {
                min_balance,
                monthly_fee,
                transact_limit,
                transact_fee,
                advanced,
            },
        });

        this.props.proceed();
    }

    back() {
        const {
            min_balance,
            monthly_fee,
            transact_limit,
            transact_fee,
        } = this.state;

        this.props.kidData({
            type: 'chequing',
            data: { min_balance, monthly_fee, transact_limit, transact_fee },
        });

        this.props.back();
    }

    render() {
        return (
            <BlockWrapper
                description={description}
                title={title}
                showProgress={true}
            >
                {/* <View> */}
                {(props) => (
                    <>
                        <View
                            style={{
                                marginVertical: 10,
                                padding: 10,
                                borderColor: 'lightgray',
                                maxWidth: 400,
                            }}
                        >
                            <View
                                style={{
                                    zIndex: 1,
                                    opacity: this.state.advanced ? 1 : 0.5,
                                    width: '100%',
                                    alignSelf: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {this.state.advanced ? null : (
                                    <View
                                        style={{
                                            zIndex: 3,
                                            // backgroundColor:'rgba(128,128,128,0.5)',
                                            left: -30,
                                            opacity: 0.5,
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    />
                                )}
                                {this.row({
                                    info: chequingInfo,
                                    item: 'min_balance',
                                })}
                                {this.row({
                                    info: chequingInfo,
                                    item: 'monthly_fee',
                                })}
                                {this.row({
                                    info: chequingInfo,
                                    item: 'transact_limit',
                                })}
                                {this.row({
                                    info: chequingInfo,
                                    item: 'transact_fee',
                                })}
                            </View>
                            <TouchableNativeFeedback
                                onPress={() =>
                                    this.setState({
                                        advanced: !this.state.advanced,
                                    })
                                }
                            >
                                <View
                                    style={{
                                        zIndex: -1,
                                        marginTop: 10,
                                        width: '100%',
                                        flexDirection: 'row',
                                        marginVertical: 10,
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            borderRadius: 10,
                                            height: 30,
                                            width: 30,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderColor: Colors.green1,
                                            borderWidth: 3,
                                        }}
                                    >
                                        <Icon
                                            style={{
                                                zIndex: -1,
                                                opacity: this.state.advanced
                                                    ? 0
                                                    : 1,
                                            }}
                                            name="check"
                                            size={16}
                                            color={Colors.green1}
                                        />
                                    </View>
                                    <View style={{ marginLeft: 10 }}>
                                        <Text
                                            style={[
                                                { color: Colors.green1 },
                                                defaultStyles.bold,
                                            ]}
                                        >
                                            No account limits
                                        </Text>
                                    </View>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                        <NewChildFooter
                            onBackPress={() => this.back()}
                            onProceedPress={() => this.proceed()}
                        />
                    </>
                )}
                {/* </View> */}
            </BlockWrapper>
        );
    }
}

class ChequingScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false,
        };
    }

    render() {
        return (
            <Chequing
                auth={this.props.auth}
                parent={this.props.parent}
                actions={this.props.actions}
                navigation={this.props.navigation}
                back={() => this.props.navigation.navigate('Expense')}
                kidData={this.props.actions.kidData}
                // proceed={() => this.setState({ section: this.state.section })}
                proceed={() => this.props.navigation.navigate('SavingsSetup')}
                startLoading={() => this.setState({ loading: true })}
                stopLoading={() => this.setState({ loading: false })}
            />
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...kidAuthActions },
            dispatch
        ),
    })
)(ChequingScreen);
