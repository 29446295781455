export function formatNumberFloat(value) {
    if (!value) {
        return '0.00';
    }

    let num = value;

    // num = value.toString().replace(/[^\\d.,-]/, '');

    return parseFloat(num || 0).toFixed(2);
}

export function formatNumberInt(value) {
    if (!value) {
        return 0;
    }

    const num = value.toString().replace(/[^\\d.,-]/, '');

    return parseInt(num || 0, 10);
}
