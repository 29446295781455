import React from 'react';
import { Platform } from 'react-native';
import { createAppContainer, createSwitchNavigator, createBottomTabNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBrowserApp } from '@react-navigation/web';

import { ParentStackNavigator, ParentSettingsStack } from './ParentNavigator';
import KidStackNavigator from './KidNavigator';

import Login from "../screens/Login";
import RecoverPassword from "../screens/RecoverPassword";
import SetPassword from "../screens/SetPassword";
import VerifyEmail from "../screens/VerifyEmail";
import SignUp from "../screens/SignUp";
import RegisterAccounts from "../screens/RegisterAccounts";

// import Store from "../screens/Store";

// import StoreItem from "../screens/Parents/StoreItem";

import AuthLoadingScreen from "../screens/AuthLoadingScreen";

import title from "../helpers/generateTitle";

const createApp = Platform.select({
	web: createBrowserApp,
	default: createAppContainer,
})

const AuthStack = createSwitchNavigator({
	Login,
	SignUp,
	RegisterAccounts,
	SetPassword,
	RecoverPassword,
	VerifyEmail,

})

// const StoreStack = createSwitchNavigator({
// 	Store: { screen: Store, path: '', },
// 	StoreItem: { screen: StoreItem, path: ':index', },
// })

const AppContainer = createAppContainer(
	createSwitchNavigator(
		{
			AuthLoading: { screen: AuthLoadingScreen, path: "" },
			Auth: { screen: AuthStack, path: "", navigationOptions: { title: title('Login/Sign Up') } },

			Parent: { screen: ParentStackNavigator, path: "parents" },
			Kid: { screen: KidStackNavigator, path: "kids" },
			// Store: { screen: StoreStack, path: 'Store' },
		},
		{
			initialRouteName: 'AuthLoading',
			// path:''
		},
	),
)

export default createApp(
	AppContainer
)



