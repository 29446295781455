/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    Image,
    ScrollView,
    StyleSheet,
    View,
    Platform,
} from 'react-native';

import Text from '../CustomText'
import TouchableNativeFeedback from '../TouchableFeedback';

import {getFeaturedStoreItems} from '../../store/actions/parents/storeActions';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';
import { useDispatch, useSelector } from 'react-redux';


function FeaturedShopCard({ item, layout, navigation, itemKey }) {

    let vmin = Math.min(...[layout.width, layout.height]);
    let imageSize = '100%'

    // return <Text>sdfsdf</Text>
    return (item ? <TouchableNativeFeedback  style={{ }}
        onPress={() => navigation.navigate('StoreItem', { store: item.merchantname || 'Amazon', index: item.key })}>
        <View elevation={5} style={[{
            zIndex: -1,
            minWidth: 120, minHeight: 120, width: layout.width * 0.1, height: layout.width * 0.1,
            justifyContent: 'center', margin: 15, backgroundColor: 'white', borderRadius: 20
        }, defaultStyles.shadow]}>
            <Image resizeMode={'cover'} style={{
                alignSelf: 'center', borderRadius: 20,
                width: imageSize, height: imageSize
            }}
                source={{ uri: item ? item.image : item }} />

        </View>
    </TouchableNativeFeedback> : null)
}

const FeaturedItems = ({  layout, navigation,}) => {

    const featuredData = useSelector(store => Object.values(store?.store?.featuredItems || {}))
    const _horizontalScroll = useRef(null);

    console.log('I AM RE RENDERED, ', new Date().toISOString())
    return (<View style={{ minHeight: 150, width: '100%', }}>
        <View style={{ zIndex: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
            <Text style={[{ color: Colors.green1, marginRight: 10 }, defaultStyles.bold, globalStyles.h4]}>Featured Items</Text>
            <Image style={{ width: 30, height: 30 }} source={require('assets/Store/Features-Items.png')} />
        </View>

        <ScrollView contentContainerStyle={Platform.OS==='android'&&{height: 145, zIndex: -1 }} style={{ zIndex: -1 }} ref={_horizontalScroll}
            horizontal={true} showsHorizontalScrollIndicator={false}>
            {featuredData.map((item, index) => <FeaturedShopCard key={index} itemKey={item} item={item} navigation={navigation} layout={layout} />)}
        </ScrollView>

    </View>
    )
}

const styles = StyleSheet.create({
});

export default FeaturedItems