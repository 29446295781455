/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { ActivityIndicator, StyleSheet, View, Platform } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput';
import Text from '../../../components/CustomText';
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper';
import { Button, InfoButton } from '../../../components/newChild/Buttons';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import ParentSettings from '../../../components/settings/ParentSettings';

import Colors from '../../../constants/Colors';
import { expenseInfo, chequingInfo } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';
import { validateEmail } from '../../../helpers/utils';
import { checkIfEmailAvailable } from '../../../requests/auth/parentRequests';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const ManagementSettings = ({ addNewParent, parentId }) => {
    const [email, setEmail] = useState('');
    const [usename, setUsername] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [error, setError] = useState({
        code: 0,
        message: 'Something went wrong',
    });

    // const checkIfEmailAvailable = (email) => {
    //     // return null;
    // };

    async function run() {
        setLoading(true);

        // const isAvailable = await checkIfEmailAvailable(email);

        // return;

        // if (isAvailable) {
        //     setModalVisible(true);

        //     return;
        // }

        return addNewParent(name, email, parentId)
            .then(() => {
                setModalVisible(true);
                setSuccess(true);
                setLoading(false);
            })
            .catch((error) => {
                setModalVisible(true);
                setError(error);
                setLoading(false);
            });
    }

    function emptyFields() {
        setModalVisible(true);
        setError({ message: 'Please fill correct email' });
        setLoading(false);
    }

    return (
        <View style={{ width: '100%', alignItems: 'center' }}>
            {
                <Modal
                    animationIn={'zoomIn'}
                    animationOut={'zoomOut'}
                    animationInTiming={500}
                    animationOutTiming={500}
                    backdropColor={'darkgrey'}
                    backdropOpacity={0.6}
                    onBackdropPress={() => setModalVisible(false)}
                    isVisible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(false);
                    }}
                >
                    <View
                        style={{
                            borderRadius: 15,
                            backgroundColor: 'white',
                            width: '80%',
                            alignSelf: 'center',
                            maxWidth: 400,
                            minHeight: 200,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {/* <View> */}
                        <Text style={{ fontSize: 20, textAlign: 'center' }}>
                            {success
                                ? 'Confirmation email has been sent'
                                : error.message}
                        </Text>
                        <TouchableNativeFeedback
                            onPress={() => {
                                setModalVisible(false);
                                setSuccess(false);
                            }}
                        >
                            <View
                                style={{
                                    marginTop: 10,
                                    backgroundColor: Colors.green1,
                                    borderRadius: 10,
                                    padding: 10,
                                }}
                            >
                                <Text style={{ color: 'white' }}>OK</Text>
                            </View>
                        </TouchableNativeFeedback>
                        {/* </View> */}
                    </View>
                </Modal>
            }
            {loading ? (
                <View
                    style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(126, 193, 61, 0.1)',
                        height: '100%',
                        zIndex: 5,
                        width: '90%',
                        borderRadius: 10,
                        justifyContent: 'center',
                    }}
                >
                    <ActivityIndicator
                        style={{ color: Colors.green1 }}
                        size={'large'}
                        color={Colors.green1}
                    />
                </View>
            ) : null}
            {/* <View stlye={{}}><Text style={{ fontSize: 22 }}>Add another parent!</Text></View> */}
            <View style={{ width: '100%', alignItems: 'center', flex: 1 }}>
                {/* <FloatingLabelInput
                    label={'Parent Name'}
                    value={name}
                    onChangeText={(text) => setName(text)}
                /> */}
                {/* <FloatingLabelInput
                    label={'Usename'}
                    value={usename}
                    onChangeText={(text) => setUsername(text)}
                /> */}
                <FloatingLabelInput
                    label={'Parent Email'}
                    value={email}
                    keyboardType={'email-address'}
                    onChangeText={(text) => setEmail(text)}
                />
                <TouchableNativeFeedback
                    style={{ width: '80%' }}
                    onPress={() => {
                        setLoading(true);
                        validateEmail(email) ? run() : emptyFields();
                    }}
                >
                    <View
                        style={{
                            marginVertical: 10,
                            marginBottom: 50,
                            borderRadius: 20,
                            width: '100%',
                            backgroundColor: Colors.green1,
                            padding: 10,
                            alignItems: 'center',
                        }}
                    >
                        <Text
                            style={[
                                { textAlign: 'center', color: 'white' },
                                defaultStyles.bold,
                            ]}
                        >
                            {'Submit'.toUpperCase()}
                        </Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </View>
    );
};

class ChequingScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false,
        };
    }



    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 });
        }
    }

    render() {
        const { parentId } = this.props.auth;
        const { addNewParent } = this.props.actions;

        return (
            <View style={{ height: '100%', flex: 1 }}>
                {this.state.loading ? (
                    <View
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            backgroundColor: 'rgba(126, 193, 61, 0.5)',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <ActivityIndicator
                            color={Colors.green1}
                            size={'large'}
                        />
                    </View>
                ) : null}

                <BlockWrapper
                onClosePress={() => this.props.navigation.navigate('AccountSettings')}
                    navigation={this.props.navigation}
                    description={null}
                    title={'Add a parent'}
                >
                    {(props) => (
                        <ManagementSettings
                            {...props}
                            addNewParent={addNewParent}
                            parentId={parentId}
                        />
                    )}
                </BlockWrapper>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...kidAuthActions },
            dispatch
        ),
    })
)(ChequingScreen);
