import { NUMERIC_KEYBOARD_TYPE } from './constants';

function formatInput(text) {
    return text.replace(/\D/g, '');
}

function formatFloatInput(text) {
    return text.replace(/([^(\d|.)])/g, '');
}

function formatInterestRate(str) {
    console.log('str, ', str);

    return str && str !== '%'
        ? parseFloat(
              str
                  .toString()
                  .replace(/[^\d\.]/g, '')
                  .replace(/\./, 'x')
                  .replace(/\./g, '')
                  .replace(/x/, '.')
          ) + '%'
        : '';
}

export const parentInfo = {
    name: {
        label: 'Your name *',
        text: 'This will be used to greet you in the app and refer to you in notifications to your child. It does not need to be the official name.',
        params: {},
    },
    username: {
        label: 'Your username *',
        text: 'You can use this username or your email to login. Username has to be unique. You can overwrite the automatically suggested entry.',
    },
};

export const kidInfo = {
    name: {
        label: "Child's name *",
        text: 'This will be used to greet your child in the app. It does not need to be the official name.',
        params: {},
    },
    userName: {
        label: "Child's username *",
        text: 'Username for your child to login. It has to be unique. You can override the automatically suggested username. Note that your child can also use their email to login, if provided.',
    },
    password: {
        label: 'Password *',
        text: 'This is the password your child will use to login. It can be changed anytime after the first login.',
        params: { secureTextEntry: true },
    },
    confirmPassword: {
        label: 'Confirm Password *',
        text: 'This is the password your child will use to login. It can be changed anytime after the first login.',
        params: { secureTextEntry: true },
    },
    email: {
        label: "(optional) Child's email",
        text: 'If you share your child’s email, they will be able to receive occasional notifications and reminders about the activities in the app (like new jobs posted, approval notifications, etc.) by email. We will also send a first greeting email to your child. This is not a mandatory field.',
        params: { keyboardType: 'email-address' },
    },
    age: {
        label: "(optional) Child's age",
        text: 'Knowing your child’s age will help us better customize the app features. Only you and your child will be able to see this information. This is not a mandatory field.',
    },
};

export const allowanceInfo = {
    amount: {
        label: 'Allowance amount *',
        text: "The amount of recurring allowance for your child. You can always change it later. For your reference, an average family pays approximately 50 cents to $1 per week for each year of a child's age. For example, a 10-year-old would get $5-$10 per week. Refer to this table or blog post for more detail.",
        params: { keyboardType: NUMERIC_KEYBOARD_TYPE },
    },
    frequency: {
        label: 'Allowance frequency *',
        text: 'Typically, children receive allowance once a week. Long intervals between allowance payments might be discouraging to a child.',
    },
};

export const expenseInfo = {
    title: {
        label: 'Expense title *',
        text: 'Short title for the expense as your child will see it on their reports. One of the recurring expense examples could be "30 mins daily computer gaming time", that you would charge $3 every week for.',
        params: {},
    },
    amount: {
        label: 'Expense amount *',
        text: 'The amount of recurring expense for your child. You can always change it later.',
        params: { additionalSign: '$', keyboardType: NUMERIC_KEYBOARD_TYPE },
    },
    frequency: {
        label: 'Expense frequency *',
        text: 'We recommend to align this with the allowance frequency.',
        // params: { keyboardType: NUMERIC_KEYBOARD_TYPE }
    },
    day: {
        label: 'Day of the week to charge the expense *',
        text: 'We recommend to align this with the allowance payment.',
    },
};

export const chequingInfo = {
    min_balance: {
        default: '10',
        label: 'Minimum Balance',
        text: 'Most bank accounts require to maintain the minimum balance in order not to be charged the monthly account fees. If the balance falls below the minimum required level at any point during the month, a monthly fee is charged. If you don’t want any monthly fees applied to your child’s account, please enter $0.',
        params: { additionalSign: '$', keyboardType: NUMERIC_KEYBOARD_TYPE },
        format: formatFloatInput,
    },
    monthly_fee: {
        default: '1',
        label: 'Monthly Fee',
        text: 'Most bank accounts charge a monthly fee if the minimum balance is not maintained. If you don’t want any monthly fees applied to your child’s account, please enter $0.',
        params: { additionalSign: '$', keyboardType: NUMERIC_KEYBOARD_TYPE },
        format: formatFloatInput,
    },
    transact_limit: {
        key: 'no_charge_monthy_transactions',
        default: '5',
        label: 'No-charge monthly transactions',
        text: 'Most bank accounts have an allowed number of monthly transactions (typically around 20). A transaction fee applies to every transaction over the allowed monthly limit. Please note that transaction limits and fees only apply to withdrawals; there is no limit on deposits. If you don’t want transaction fees to apply, please enter 0.',
        params: { keyboardType: NUMERIC_KEYBOARD_TYPE },
        format: formatInput,
    },
    transact_fee: {
        default: '0.5',
        label: 'Transaction fee once transactions exceeded',
        text: 'Most bank accounts charge transaction fees on every transactions over the allowed monthly limit. Please note that transaction limits and fees only apply to withdrawals; there is no limit on deposits. If you don’t want transaction fees to apply, please enter $0.',
        params: { additionalSign: '$', keyboardType: NUMERIC_KEYBOARD_TYPE },
        format: formatFloatInput,
    },
};

export const savingsInfo = {
    int_rate: {
        default: '12%',
        label: 'Annual Interest Rate',
        text: 'To motivate your child to save and see the money grow, we recommend an annual rate of 12%, which means that every month their Savings account interest will be 1% (=12%/12) of that month’s ending balance.',
        params: { keyboardType: NUMERIC_KEYBOARD_TYPE },
        format: formatInterestRate,
    },
    withdraw_limit: {
        default: '1',
        label: 'Allowed monthly withdrawals',
        text: "Many Savings accounts limit the number of withdrawals to 1. A transaction fee applies to every withdrawal over the allowed monthly limit. Limiting the number of withdrawals could be a great way to motivate your child to save. If you don't want to set a limit, please enter 0.",
        params: { keyboardType: NUMERIC_KEYBOARD_TYPE },
        format: formatInput,
    },
    withdraw_fee: {
        default: '2',
        label: 'Transaction fee once transactions exceeded',
        text: ' A transaction fee applies to every withdrawal over the allowed monthly limit. The transaction fees on Savings withdrawals are usually significantly higher than the Chequing transaction fees. Charging for the withdrawals over the limit could be a great way to motivate your child to save. If you don’t want transaction fees to apply, please enter $0.',
        params: { additionalSign: '$', keyboardType: NUMERIC_KEYBOARD_TYPE },
        format: formatFloatInput,
    },
};

export const allowanceTable = [
    'Age 4: $2.85',
    'Age 5: $3.15',
    'Age 6: $3.85',
    'Age 7: $4.10',
    'Age 8: $4.32',
    'Age 9: $5.52',
    'Age 10: $7.18',
    'Age 11: $7.92',
    'Age 12: $9.58',
    'Age 13: $9.52',
    'Age 14: $13.47',
    'Age 15: $15.57',
    'Age 16: $17.84',
    'Age 17: $30.66',
    'Age 18: $40.10',
];
