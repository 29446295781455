

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    WebView,
    Image,
    ImageBackground,
    KeyboardAvoidingView,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    // Text,
    TextInput,
    // TouchableNativeFeedback,
    ToucableOpacity,
    StatusBar,
} from 'react-native';
import { Resizable, Hoverable } from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import TouchableNativeFeedback from '../components/TouchableFeedback'
import FloatingLabelInput from '../components/FloatingLabelInput/FloatingLabelInput'
import { RejectionView } from '../components/confirmation'
import Text from '../components/CustomText'
import Stars from '../components/Stars'

import Colors from '../constants/Colors'

import * as authActions from '../store/actions/parents/authActions'
import * as kidAuthActions from '../store/actions/kids/authActions'

import { AuthTextInput, Button } from './Login'


const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const boldFontWeight = Platform.OS === 'web' ? { fontFamily: 'Carmen Sans Bold', } : { fontFamily: 'Carmen Sans Bold' }


class LoginScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            repeatPassword: '',
            login: true
        };
    };


    componentDidMount() {
        const { parentId } = this.props.auth;
        const { clearAuth, kidClearAuth } = this.props.actions;

        const mode = this.props.navigation.getParam('mode', null);
        const oobCode = this.props.navigation.getParam('oobCode', null);

        console.log('action, oobCode', mode, oobCode)
        console.log('this.props.auth.parentId', this.props.auth.parentId)
        console.log('this.props.fullState', this.props.fullState)

        clearAuth();
        kidClearAuth()
    }


    recoverPass() {

        this.props.actions.sendPasswordResetEmail(this.state.email)
            // .then(() => this.props.navigation.navigate('Dashboard'))
            .then(() => {
                this.setState({ authModalVisible: true, success: true })
            })
            .catch(error => {
                // console.log(error)
                // alert(error.message)
                const errorMessage = error.message;
                console.log('last')
                console.log(error)
                this.setState({ authModalVisible: true, })
                switch (error.code) {
                    case 'auth/wrong-password':
                        this.setState({ errorBlock: true, errorPassword: true, errorText: errorMessage }); return 0
                    default:
                        this.setState({ errorBlock: true, errorText: errorMessage || 'Something went wrong =(' }); return 0
                }
            })
    }

    render() {
        // return(this.props.navigation.getParam('login', true)?<Login/>:<SignUp/>)
        // const titleFontSize = Platform.OS === 'web' ? ('2.7em') : 48

        return (<Resizable>
            {layout => {
                let titleFontSize = layout.width > 850 ? (layout.width > 1500 ? 50.5 : layout.width / 30) : 25.5
                titleFontSize = 25

                return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

                    <View style={{zIndex:2, minWidth: 280, maxWidth: 550, flex: 1, justifyContent: 'center' }}>

                        <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor:Colors.transparentWhite, padding:10, borderRadius:10 }}>
                            <Modal
                                animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
                                backdropColor={'darkgrey'} backdropOpacity={0.1}
                                onBackdropPress={() => this.setState({ authModalVisible: false, success: false })}
                                isVisible={this.state.authModalVisible}
                                onRequestClose={() => { this.setState({ authModalVisible: false, success: false }) }}>
                                <View elevation={5} style={{
                                    borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                                    maxWidth: 400, minHeight: 100, padding: 15, justifyContent: 'center', boxShadow: "2px 2px 15px #272827"
                                }}>
                                    {this.state.success ?
                                        <RejectionView title={'Email confirmation has been sent, please check your inbox.'}
                                            subtitles={[]} okPress={() => { this.setState({ authModalVisible: false, success: false }) }} />
                                        :
                                        <RejectionView title={this.state.errorText} subtitles={[]} okPress={() => { this.setState({ authModalVisible: false, success: false }) }} />}
                                </View>
                            </Modal>
                            <Text style={{ color: 'white', margin: 10, fontSize: titleFontSize, ...boldFontWeight }}>Recover Password</Text>


                            <View style={{ width: '100%', alignItems: 'center' }}>
                                <AuthTextInput label={'Email Address'} value={this.state.email}
                                placeholder={'Email Address'}
                                    onSubmitEditing={() => { this.recoverPass() }}
                                    onChangeText={text => this.setState({ email: text })} />
                            </View>

                            <Button title="Reset Password" onPress={() => { this.recoverPass() }} />

                            <View style={{ alignItems: 'center', marginVertical:10, width: '80%' }}>
                                <TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Login') }}>
                                    <View>
                                        <Text style={{ ...boldFontWeight, fontSize: 14, textDecorationLine: 'underline' }}>Login / Sign Up</Text>
                                    </View>
                                </TouchableNativeFeedback>
                            </View>

                        </View>
                    </View>
                </View>
            }}
        </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    return ({
        fullState: state,
        auth: state.auth,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions }, dispatch)
    })
)(LoginScreen);

