/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    Animated,
    ImageBackground,
    Platform,
    StyleSheet,
    View,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { useHover, useFocus, useActive, useDimensions, Resizable } from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import firebase from 'firebase/app';
import 'firebase/auth';

import Stars from '../components/Stars';

import * as goalsActions from '../store/actions/parents/goalsActions'
import * as authActions from '../store/actions/parents/authActions'
import * as jobsActions from '../store/actions/parents/jobsActions'
import * as chequingActions from '../store/actions/parents/chequingActions'
import * as purchasesActions from '../store/actions/parents/purchasesActions'

const auth = firebase.auth;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

// String.prototype.toUpperCase()
// return String?
Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
};

function formatInputValue(str) {
    return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}


function handleVerifyEmail(actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth().applyActionCode(actionCode).then(function (resp) {
        // Email address has been verified.
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        console.log('success resp!!!!', resp)
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
    }).catch(function (error) {
        console.log('error verify email!', error)
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
    });
}

class Dashboard extends React.Component {
    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }

    constructor(props) {
        super(props);

        this.ref0 = React.createRef()
        this.ref1 = React.createRef()
        this.ref2 = React.createRef()
        this.ref3 = React.createRef()
        this.ref4 = React.createRef()
        this.myRef = {}


        this.state = {
            data: {},
            modalVisible: true,
            id: 0,
            scrolledHeader: new Animated.Value(0),
            scrolledPadding: new Animated.Value(0),
            jobCategory: 'Chores',
            jobFrequency: ' None',
            jobTitle: '',
            // addJobModal:true
        };
    };


    componentDidMount() {
        const { actions, auth, navigation } = this.props;

        const mode = navigation.getParam('mode', null);
        const oobCode = navigation.getParam('oobCode', null);

        console.log('mode, oobCode', mode, oobCode);

        // const mode = navigation.getParam('mode', null);
        if (mode === 'verifyEmail') {
            handleVerifyEmail(oobCode)
            // database().ref().child('user_type/'+)
            navigation.navigate('Login')
        } else if (mode === 'resetPassword') {
            this.props.navigation.navigate('SetPassword', {
                mode,
                oobCode,
            })
        }
        else if (mode === 'recoverPassword') {
            this.props.navigation.navigate('RecoverPassword', {
                mode, oobCode
            })
            // handleVerifyEmail(oobCode)
            // navigation.navigate('Parent')
        }
        else {
            navigation.navigate('Login')
        }

    }

    componentWillUnmount() {
        // Remove the event listener
        console.log("UNMOUNTED MAIN !!!!")
        // this.focusListener.remove();
        // this.focusListener.remove();
    }


    parseInt(value) {
        return value !== '' ? parseInt(value) : 0
    }


    logout = async () => {
        await AsyncStorage.clear();
        // this.props.actions.logout();
    }



    render() {
        const { navigation, auth } = this.props;


        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    const width = layout.width;
                    return <View style={{ flex: 1, }}>
                        <Modal
                            animationIn={'zoomIn'} animationOut={'zoomOut'}
                            animationInTiming={1000} animationOutTiming={1000}
                            backdropColor={'darkgrey'}
                            backdropOpacity={0.6}
                            onBackdropPress={() => this.setState({ confirmation: false, addJobModal: false })}
                            isVisible={this.state.addJobModal}
                            onRequestClose={() => { this.setState({ confirmation: false, addJobModal: false }) }}>
                            <View style={{
                                borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                                maxWidth: 400, minHeight: 250,
                            }}>

                            </View>
                        </Modal>


                        {/* {dropdown.call(this, vmin, layout.width, true)} */}
                        {/* <ImageBackground resizeMode={'stretch'} style={{
                            justifyContent: 'center', flex: 1, width: '100%',
                            height: '100%'
                        }} source={require('@assets/BG_1.jpg')}>
                            <Stars />

                        </ImageBackground> */}
                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({

});

export default connect(state => {
    return ({
        chequing: state.chequing,
        auth: state.auth,
        goals: state.goals,
        jobs: state.jobs,
        purchases: state.purchases
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...goalsActions, ...purchasesActions, ...chequingActions, ...jobsActions }, dispatch)
    })
)(Dashboard);
