import { Platform } from 'react-native';

import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';

// import { database, } from 'firebase';
import config from '../../constants/config';

// TODO replace
const appType = 'pennygem';

const database = firebase.database;

async function updateChildNotificationToken({ token, childId }) {

	const ref_source = database().ref('children/' + childId + '/notifications')
	console.log('STARN SAVING', token, childId)

	// let values = (await ref_source.once('value')).val()
	let values = (await ref_source.orderByValue().equalTo(token).once('value')).val()
	// console.log('values', values, Object.values(values || {}))

	if (values) {
		// if (token in Object.values(values || {})) {
		// console.log('exists ', values)
		// let key = Object.keys(values)[0]
		// ref_source.update({})
	}
	else {
		// console.log('new ', values)

		let pushKey = ref_source.push().key
		console.log('new ', pushKey)

		ref_source.update({ [pushKey]: token })
	}

}

export async function registerForPushNotificationsAsync() {
	let token;

	// TODO Add permissions and token
	return token;
}

export const setUpKidNotifications = () => {
	Platform.OS !== 'web' ? registerForPushNotificationsAsync().then(token => {
		console.log('NOTIFICATION TOKEN ', token)
		// token && updateChildNotificationToken({ token, childId: childId })
		// database().ref('parents/'+auth.parentId+'/notifications').update({[token]:true})
	})
		.catch((e) => console.log('NOTIFICATION ERROR', e))
		: null;
};

export const setUpParentNotifications = () => {
	Platform.OS !== 'web' ? registerForPushNotificationsAsync().then(token => {
		console.log('NOTIFICATION TOKEN ', token)
		// token && updateChildNotificationToken({ token, childId: childId })
		// database().ref('parents/'+auth.parentId+'/notifications').update({[token]:true})
	})
		.catch((e) => console.log('NOTIFICATION ERROR', e))
		: null;
};

const kidNotificationActions = {
	newJob: 'newJob',
};

export const kidNotificationsListener = ({navigation, action}) => {
	// TODO fix
	switch (action) {
		case kidNotificationActions.newJob:
			navigation.navigate('Jobs');
		default:
			return;
	}
};

export const getNotificationToken = async () => {
	return { data: 'TODO ADD TOKEN' };
};

export const sendNotification = ({type, id, title, body, data}) => {
	let notificationUrl = 'sendPushNotification';
	let fetchBody = {};

	// if (type === 'child') {
	// 	fetchBody.childId = id;
	// 	notificationUrl = '';
	// } else {
	// 	fetchBody.parentId = id;
	// 	notificationUrl = '';
	// }
	console.log('TYPE - ', type);
	// fetchBody.push({type, id, title, body, data});
	fetchBody = {...fetchBody, type, id, title, body, data}

	return fetch(config.url + notificationUrl, {
		method: 'POST',
		body: JSON.stringify(fetchBody)
	});

}