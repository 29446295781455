/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet } from 'react-native';

import { Feedback } from './Feedback';

export const ContactUs = (props) => {
    const { visible, onClose, title, navigation, layout } = props;

    return (
        <Feedback
            layout={layout}
            visible={visible}
            title={title}
            onClose={onClose}
            navigation={navigation}
        />
    );
};
