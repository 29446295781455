import React, { Component } from 'react';
import { View, StyleSheet, TextInput, Animated, Platform } from 'react-native';

import Text from '../CustomText';

import { defaultStyles } from '../../constants/style';

import Colors from '../../constants/Colors';
import { IS_WEB } from '../../constants/constants';

export default class FloatingLabelInput extends Component {
    state = {
        isFocused: false,
        _animatedIsFocused: new Animated.Value(this.props.value === '' ? 0 : 1),
    };

    //   componentDidMount() {
    //     this._animatedIsFocused = new Animated.Value(
    //       this.props.value === '' ? 0 : 1
    //     );
    //   }

    componentDidUpdate() {
        Animated.timing(this.state._animatedIsFocused, {
            useNativeDriver: false,
            toValue: this.state.isFocused || this.props.value !== '' ? 1 : 0,
            duration: 200,
        }).start();
    }

    render() {
        const {
            label,
            additionalSign,
            inputRef,
            centered,
            containerStyle,
            inputStyle,
            ...otherProps
        } = this.props;
        const labelStyle = {
            position: 'absolute',
            left: 0,
            right: 0,
            marginLeft: 22,
            alignItems: centered ? 'center' : 'flex-start',
            top: this.state._animatedIsFocused.interpolate({
                inputRange: [0, 1],
                outputRange: [10, 42],
            }),
        };
        // console.log('MAIN INPUT')

        const textStyle = {
            // ...defaultStyles.bold,
            fontFamily: 'Carmen Sans Regular',
            fontSize: 14,
            color: Colors.gray1,
        };

        return (
            <View style={[styles.container, containerStyle]}>
                <Animated.View
                    style={[labelStyle, this.props.customLabelStyle]}
                >
                    <Animated.Text style={textStyle}>{label}</Animated.Text>
                </Animated.View>
                <View style={[styles.inputContainer, this.props.style]}>
                    <View style={styles.additionalSignContainer}>
                        <Text style={styles.additionalSign}>
                            {additionalSign && this.props.value
                                ? additionalSign
                                : ''}
                        </Text>
                    </View>
                    <TextInput
                        className={'floating-label-input'}
                        {...otherProps}
                        ref={inputRef}
                        secureTextEntry={this.props.secureTextEntry}
                        // onSubmitEditing={()=>{ this.passwordTextInput.focus()}}
                        keyboardType={
                            this.props.keyboardType
                                ? this.props.keyboardType
                                : 'default'
                        }
                        style={[
                            styles.input,
                            centered
                                ? {
                                marginLeft: 0,
                                textAlign: 'center',
                            } : {
                                marginLeft: 1,
                                textAlign: 'left',
                            },
                            inputStyle,
                        ]}
                        multiline={false}
                        // placeholder={'Email'}
                        onFocus={() => this.setState({ isFocused: true })}
                        onBlur={() => this.setState({ isFocused: false })}
                    />
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: { width: '80%', zIndex: 1, marginVertical: 12 },
    inputContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        height: 40,
        width: '100%',
        borderWidth: 2,
        borderRadius: 20,
        borderColor: Colors.gray,
    },
    additionalSignContainer: {
        justifyContent: 'center',
        marginLeft: IS_WEB ? 20 : 40,
    },
    additionalSign: {
        // color: '#7ec13d',
        color: Colors.gray1,
        fontSize: 20,
        marginTop: 4,
    },
    input: {
        ...(IS_WEB ? { outlineWidth: 0 } : {}),
        height: 40,
        width: '100%',
        fontFamily: 'Carmen Sans Regular',
        fontSize: 20,
        color: Colors.gray1,
        paddingRight: 25,
        // color: '#7ec13d',
    },
});
