/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    View,
    Platform,
    TextInput,
    TouchableOpacity,
    StatusBar,
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/database';

import { getChequing } from '../../store/actions/parents/chequingActions';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';
import CounterTextInput from '../../components/CounterTextInput';
// import TransferBlock from '../../components/chequing/TransferBlock'
import ModalContainer from '../../components/Modal/Modal';

import Colors from '../../constants/Colors';
import { defaultStyles } from '../../constants/style';
import { eventLog } from '../../helpers/analytics/analytics';
import { sendNotification } from '../../helpers/notifications/notifications';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const database = firebase.database;
const buttonSize = 70;

function formatInputValue(str) {
    return str || str === 0
        ? str
              .toString()
              .replace(/[^\d\.]/g, '')
              .replace(/\./, 'x')
              .replace(/\./g, '')
              .replace(/x/, '.')
        : '';
}

function getDate(index) {
    const newDate = new Date();
    if (index === 0) {
        newDate.setDate(newDate.getDate() - 7);
    }

    if (index === 1) {
        newDate.setMonth(newDate.getMonth() - 1);

    }

    if (index === 2) {
        newDate.setMonth(newDate.getMonth() - 6);

    }

    if (index === 3) {
        newDate.setFullYear(newDate.getFullYear() - 7);
    }

    return newDate;
}

function getButtonTitle(type) {
    switch (type) {
        case 'deposit':
            return 'Deposit';
        case 'withdraw':
            return 'Widthdraw';
        default:
            return 'Transfer';
    }
}

async function deposit({ parentId, childId, transfer }) {
    var balance_ref = database().ref(
        '/users/' + parentId + '/' + childId + '/chequing/balance'
    );

    database()
        .ref('/users/' + parentId + '/' + childId + '/chequing/transactions')
        .push({
            amount: parseFloat(transfer.replace('$', '')),
            transaction_date: new Date().toISOString(),
            type: 'parent deposit',
        });

    const snapshot = await balance_ref.once('value');
    const old_balance = snapshot.val()

    await database()
        .ref()
        .child('users')
        .child(parentId)
        .child(childId)
        .child('chequing')
        .update({
            balance:
                parseFloat(old_balance) + parseFloat(transfer.replace('$', '')),
        });

    return 0;
}

async function withdrawal({ parentId, childId, transfer }) {
    console.log('parentId, childId, transfer ', parentId, childId, transfer);
    var balance_ref = database().ref(
        '/users/' + parentId + '/' + childId + '/chequing/balance'
    );

    database()
        .ref('/users/' + parentId + '/' + childId + '/chequing/transactions')
        .push({
            amount: parseFloat(transfer.toString().replace('$', '')),
            transaction_date: new Date().toISOString(),
            type: 'parent withdrawal',
        });

    database()
        .ref('/users/' + parentId + '/' + childId + '/purchases/approved')
        .push({
            price: parseFloat(transfer.toString().replace('$', '')),
            approval_date: new Date().toISOString(),
            item_name: 'Parent withdrawal',
            type: 'credit purchase',
        });

    const snapshot = await balance_ref.once('value');
    const old_balance = snapshot.val()

    await database()
        .ref()
        .child('users')
        .child(parentId)
        .child(childId)
        .child('chequing')
        .update({
            balance:
                parseFloat(old_balance) -
                parseFloat(transfer.toString().replace('$', '')),
        });

    return 0;
}

const TransferBlock = ({
    type,
    title,
    transfer,
    label,
    setTransfer,
    setModalVisible,
    interval,
    update,
    setTransfersVisible,
}) => {
    const dispatch = useDispatch();
    const parentId = useSelector((state) => state.auth.parentId);
    const { childIds, names, id } = useSelector((state) => state.parent);

    const buttonTitle = getButtonTitle(type);
    // console.log('parentId, childIds, names, id ',parentId, childIds, names, id)

    return (
        <React.Fragment>
            <View
                style={{
                    justifyContent: 'center',
                    marginTop: '5%',
                    marginBottom: 20,
                }}
            >
                <Text
                    style={{
                        textAlign: 'center',
                        fontFamily: 'Carmen Sans Bold',
                    }}
                >
                    {title}
                </Text>
            </View>
            <View
                style={{
                    width: '100%',
                    alignSelf: 'flex-start',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                {/* <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}> */}
                <CounterTextInput
                    label={label}
                    onPressUp={() =>
                        setTransfer(
                            '$' +
                                (parseInt(
                                    transfer.toString().replace('$', '')
                                ) +
                                    1)
                        )
                    }
                    onPressDown={() =>
                        parseInt(transfer.toString().replace('$', '')) > 0
                            ? setTransfer(
                                  '$' +
                                      (parseInt(
                                          transfer.toString().replace('$', '')
                                      ) -
                                          1)
                              )
                            : null
                    }
                    centered={true}
                    value={transfer ? '$' + formatInputValue(transfer) : ''}
                    keyboardType={'numeric'}
                    onChangeText={(text) =>
                        setTransfer(
                            text || text !== '$'
                                ? '$' + text.toString().replace('$', '')
                                : ''
                        )
                    }
                />

                <View style={{ width: '100%' }}>
                    <TouchableNativeFeedback
                        onPress={() => {
                            if (transfer) {
                                console.log('123');
                                setModalVisible(false);
                                setTransfersVisible(false);
                                update({
                                    parentId,
                                    childId: childIds[id],
                                    transfer,
                                }).then(() => {
                                    eventLog({
                                        eventName: `parent_${type}`,
                                        properties: {
                                            amount: transfer,
                                        },
                                    });
                                    sendNotification({
                                        type: 'child',
                                        title: `Money ${type}`,
                                        id: childIds[id],
                                        body: `Money was ${
                                            type === 'deposit'
                                                ? 'deposited'
                                                : 'withdrawn'
                                        } by parent`,
                                        data: { action: `parent_${type}` },
                                    });

                                    dispatch(
                                        getChequing(
                                            getDate(interval),
                                            parentId,
                                            childIds[id]
                                        )
                                    );
                                });

                                setTransfer('');
                            }

                            console.log('431');
                        }}
                    >
                        <View
                            style={{
                                // boxShadow: '-5px 5px 15px 5px rgba(126, 193, 61, 0.5)',
                                width: '80%',
                                alignSelf: 'center',
                                marginBottom: 30,
                                backgroundColor: Colors.green1,
                                height: 40,
                                borderRadius: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    color: 'white',
                                    ...defaultStyles.bold,
                                }}
                            >
                                {buttonTitle.toUpperCase()}
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </React.Fragment>
    );
};

const TransfersCard = ({ transfersVisible, setTransfersVisible, interval }) => {
    const [depositChequing, setDepositChequing] = useState(false);
    const [withdrawChequing, setWithdrawChequing] = useState(false);
    const [transfer, setTransfer] = useState(0);

    return (
        <React.Fragment>
            <ModalContainer
                visible={depositChequing}
                setVisible={setDepositChequing}
                onClose={() => setDepositChequing(false)}
            >
                <TransferBlock
                    setTransfersVisible={setTransfersVisible}
                    type={'deposit'}
                    title={'Deposit to Chequing'}
                    label={'Amount to Deposit'}
                    update={deposit}
                    interval={interval}
                    transfer={transfer}
                    setTransfer={setTransfer}
                    setModalVisible={setDepositChequing}
                />
            </ModalContainer>
            <ModalContainer
                visible={withdrawChequing}
                setVisible={setWithdrawChequing}
                onClose={() => setWithdrawChequing(false)}
            >
                <TransferBlock
                    setTransfersVisible={setTransfersVisible}
                    type={'withdraw'}
                    title={'Withdraw from Chequing'}
                    label={'Amount to Withdraw'}
                    update={withdrawal}
                    interval={interval}
                    transfer={transfer}
                    setTransfer={setTransfer}
                    setModalVisible={setWithdrawChequing}
                />
            </ModalContainer>
            {transfersVisible && (
                <View
                    style={{
                        ...defaultStyles.shadow,
                        borderWidth: 1,
                        borderColor: 'lightgray',
                        boxShadow: '0 0 15px 5px rgba(76, 76, 76, 0.3)',
                        zIndex: 2,
                        backgroundColor: 'white',
                        position: 'absolute',
                        bottom: buttonSize / 2 + 10,
                        borderRadius: 15,
                        backgroundColor: 'rgba(240,240,240,1)',
                        alignSelf: 'center',
                        width: '80%',
                        maxWidth: 300,
                        paddingTop: 10,
                        paddingBottom: 35,
                        alignItems: 'center',
                    }}
                >
                    {[
                        {
                            text: 'Deposit to Chequing',
                            setVisible: setDepositChequing,
                        },
                        {
                            text: 'Withdraw from Chequing',
                            setVisible: setWithdrawChequing,
                        },
                    ].map((item, index) => (
                        <TouchableNativeFeedback
                            key={index}
                            onPress={() => item.setVisible(true)}
                            style={{ width: '100%', alignItems: 'center' }}
                        >
                            <View
                                key={index}
                                style={{
                                    width: '90%',
                                    marginBottom: 10,
                                    borderRadius: 10,
                                    backgroundColor: 'white',
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: 'Carmen Sans Bold',
                                        marginLeft: 10,
                                        marginVertical: 3,
                                    }}
                                >
                                    {item.text}
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    ))}
                </View>
            )}
        </React.Fragment>
    );
};

const ParentTransferButtonMobile = ({
    onPress,
    parentId,
    childId,
    navigation,
    interval,
}) => {
    // const [focused, setFocused] = useState(1);
    const [transfersVisible, setTransfersVisible] = useState(false);
    const inputRef = React.useRef();
    const focused = inputRef.current && inputRef.current.isFocused();

    const positionStyle = {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        alignItems: 'center',
        zIndex: 2,
    };

    return (
        <React.Fragment>
            {
                <TransfersCard
                    transfersVisible={transfersVisible}
                    interval={interval}
                    setTransfersVisible={setTransfersVisible}
                />
            }

            {/* {(transfersVisible) && <View style={{
            borderWidth: 1, elevation: 1,
            zIndex: 2,
            position: 'absolute', bottom: 25,
            borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
            maxWidth: 400, minHeight: 250
        }}>
        </View>} */}
            <View
                pointerEvents={'box-none'}
                onPress={() => {
                    setTransfersVisible(true);
                }}
                style={positionStyle}
            >
                <View
                    style={{
                        padding: 10,
                        backgroundColor: Colors.navy,
                        borderRadius: 50,
                    }}
                >
                    <ImageBackground
                        onPress={() => {
                            console.log('321');
                        }}
                        resizeMode={'contain'}
                        style={{
                            height: 50,
                            width: 50,
                            borderRadius: 40,
                            backgroundColor: Colors.navy,
                            // height: vmin * 0.5, maxHeight: 50, width: vmin * 0.15,
                        }}
                        source={require('assets/Icons/Transfer.png')}
                    >
                        <View
                            style={{ height: '100%', zIndex: 1 }}
                            onBlur={() => {
                                console.log('TOUCH AQWAT');
                            }}
                            onStartShouldSetResponder={() => {
                                console.log('sdfsdf');
                                setTransfersVisible(!transfersVisible);
                            }}
                        >
                            <TextInput
                                style={{
                                    zIndex: -1,
                                    height: 1,
                                    borderColor: 'green',
                                }}
                                // onFocus={() => {console.log('123123123123'); setTransfersVisible(true); inputRef.current.blur()}}
                                onBlur={() => setTransfersVisible(false)}
                                ref={inputRef}
                                caretHidden={true}
                                showSoftInputOnFocus={false}
                            />
                        </View>
                    </ImageBackground>
                </View>
                {/* <Image resizeMode={'contain'} style={{
            height: 50, width: 50,
            backgroundColor: Colors.navy
            // height: vmin * 0.5, maxHeight: 50, width: vmin * 0.15,
        }} source={require('assets/Icons/Transfer.png')} /> */}
            </View>
        </React.Fragment>
    );
};

const ParentTransferButtonWeb = ({
    onPress,
    parentId,
    childId,
    navigation,
    interval,
}) => {
    const [focused, setFocused] = useState(1);
    const [transfersVisible, setTransfersVisible] = useState(false);
    const [depositChequing, setDepositChequing] = useState(false);

    const inputRef = React.useRef();

    const positionStyle = {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        alignItems: 'center',
        zIndex: 2,
    };

    return (
        <React.Fragment>
            <TransfersCard
                transfersVisible={transfersVisible}
                interval={interval}
                setTransfersVisible={setTransfersVisible}
            />
            <View style={positionStyle}>
                <TouchableNativeFeedback
                    onPress={() => setTransfersVisible(!transfersVisible)}
                    // onBlur={() => setTransfersVisible(false)}
                >
                    <View
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: Colors.green1,
                            height: buttonSize,
                            width: buttonSize,
                            borderRadius: '50%',
                        }}
                    >
                        <Image
                            resizeMode={'contain'}
                            onPress={() => {
                                console.log('sdf');
                                setTransfersVisible(true);
                            }}
                            style={{
                                height: 40,
                                width: 40,
                                // height: vmin * 0.5, maxHeight: 50, width: vmin * 0.15,
                            }}
                            source={require('assets/Icons/Transfer.png')}
                        />
                    </View>
                </TouchableNativeFeedback>
            </View>
        </React.Fragment>
    );
};



export default Platform.OS === 'web'
    ? ParentTransferButtonWeb
    : ParentTransferButtonMobile;
