/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Animated,
    Dimensions,
    FlatList,
    Image,
    StyleSheet,
    View,
    UIManager,
    Platform,
} from 'react-native';

import { Resizable } from 'react-native-web-hooks';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';
import { ConfirmationView } from '../../components/confirmation';

import JobCard from '../../components/jobs/JobCard';

import { ChildHeaderCard } from '../../components/Header/HeaderBlock';

// import { Platform } from '@unimodules/core';
import * as jobsActions from '../../store/actions/kids/jobsActions';
import * as authActions from '../../store/actions/kids/authActions';
import { defaultStyles } from '../../constants/style';
import ModalContainer from '../../components/Modal/Modal';

UIManager.setLayoutAnimationEnabledExperimental &&
    UIManager.setLayoutAnimationEnabledExperimental(true);

const layout = Dimensions.get('window');

class JobEmptyItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scaleValue: new Animated.Value(0),
        };
    }

    componentDidMount() {
        Animated.timing(this.state.scaleValue, {
            toValue: 1,
            duration: 350,
            useNativeDriver: true,
            delay: 100,
        }).start();
    }
    render() {
        const { isLarge } = this.props;

        return (
            <Animated.View
                style={[
                    {
                        backgroundColor: 'white',
                        opacity: this.state.scaleValue,
                        width: isLarge > 1200 ? '60%' : '80%',
                        maxWidth: 550,
                        paddingTop: 15,
                        margin: 10,
                        borderRadius: 15,
                        alignSelf: 'center',
                    },
                    defaultStyles.shadow,
                ]}
            >
                <View
                    style={{
                        backgroundColor: 'white',
                        flex: 1,
                        width: '80%',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <View>
                        <Image
                            style={{ width: 70, height: 70 }}
                            source={require('assets/Jobs/Jobs_Nojobs.png')}
                        />
                    </View>
                    <View
                        style={{
                            flex: 1,
                            margin: 10,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <Text
                            style={[{ color: '#94B42D' }, defaultStyles.bold]}
                        >
                            No jobs available
                        </Text>
                    </View>
                </View>
                <TouchableNativeFeedback
                    onPress={() => {
                        this.props.requestJob();
                    }}
                >
                    <View
                        elevation={-2}
                        style={{
                            width: '80%',
                            marginTop: 10,
                            alignSelf: 'center',
                            backgroundColor: 'rgba(238, 80, 96, 0.5)',
                            marginBottom: 10,
                            padding: 6,
                            borderRadius: 30,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <View style={{ justifyContent: 'center' }}>
                            <Text style={{ color: 'white' }}>
                                Request a Job!
                            </Text>
                        </View>
                    </View>
                </TouchableNativeFeedback>
            </Animated.View>
        );
    }
}

class Jobs extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
            width: 0,
        };
    }

    async componentDidMount() {
        const { parentId, childId } = this.props.auth;

        console.log(
            'this.props.auth, this.props.jobs',
            this.props.auth,
            this.props.jobs
        );

        this.props.actions
            .getJobs(parentId, childId)
            .then(() => this.setState({ isLoading: false }));

        if (!this.props.auth.childId) {
            this.props.actions.clearAuth().then(() => {
                this.props.navigation.navigate('Auth');
            });
        }
    }

    renderItem = ({ item, index }, layout) => {
        return (
            <JobCard
                layout={layout}
                type={'child'}
                category={this.state.category}
                openModal={(jobId) => this.openModal(jobId)}
                isLarge={this.state.width}
                item={item}
                index={index}
            />
        );
    };

    openModal(goalId) {
        this.setState({
            modalVisible: true,
            confirmation: true,
            confirmGoalId: goalId,
        });
    }

    emptyList = () => {
        return this.state.isLoading ? null : (
            <JobEmptyItem
                isLarge={this.state.width}
                requestJob={() => {
                    this.setState({ requestJobVisible: true });
                }}
            />
        );
    };

    listHeader = () => {
        const { navigation, jobs, auth, child } = this.props;
        const { name } = child;

        // TODO FIX this vmin
        const vmin = Math.min(layout?.width, layout?.height);

        return (
            <ChildHeaderCard
                style={{ marginBottom: 40, paddingTop: layout.height * 0.015 }}
                name={name}
                signOut={() => {}}
                vmin={vmin}
                layout={layout}
                clearAuth={this.props.actions.clearAuth}
                navigation={navigation}
            />
        );
    };

    render() {
        const { navigation, actions } = this.props;

        const { parentId, childId } = this.props.auth;
        const { name } = this.props.child;

        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    return (
                        <View style={{ flex: 1, backgroundColor: 'white' }}>
                            <ModalContainer
                                onClose={() =>
                                    this.setState({ modalVisible: false })
                                }
                                visible={this.state.modalVisible}
                            >
                                <ConfirmationView
                                    title={'Job completed?'}
                                    subtitles={[]}
                                    // subtitles={[this.state.jobTitle, '$' + this.state.jobValue, 'due to ' + this.state.jobDeadline]}
                                    confirmPress={() => {
                                        this.setState({
                                            modalVisible: false,
                                            confirmation: false,
                                        });

                                        this.props.actions
                                            .completeJob(
                                                this.state.confirmGoalId,
                                                parentId,
                                                childId
                                            )
                                            .then(() => {
                                                this.props.actions.getJobs(
                                                    parentId,
                                                    childId
                                                );
                                            });
                                    }}
                                    rejectPress={() => {
                                        this.setState({ modalVisible: false });
                                    }}
                                />
                            </ModalContainer>

                            <ModalContainer
                                onClose={() =>
                                    this.setState({ requestJobVisible: false })
                                }
                                visible={this.state.requestJobVisible}
                            >
                                <ConfirmationView
                                    image={require('assets/Avatars/icon_2.png')}
                                    title={'Request Job'}
                                    subtitles={[]}
                                    confirmPress={() => {
                                        this.props.actions
                                            .requestJob(parentId, childId)
                                            .then(() => {});

                                        this.setState({
                                            requestJobVisible: false,
                                        });
                                    }}
                                    rejectPress={() => {
                                        this.setState({
                                            requestJobVisible: false,
                                        });
                                    }}
                                />
                            </ModalContainer>

                            <FlatList
                                data={
                                    this.state.isLoading
                                        ? []
                                        : this.props.jobs.data
                                }
                                style={{ zIndex: 1 }}
                                renderItem={(item) =>
                                    this.renderItem(item, layout)
                                }
                                keyExtractor={(item, index) => index.toString()}
                                ListHeaderComponent={this.listHeader}
                                ListEmptyComponent={this.emptyList}
                            />
                            {/* </ImageBackground> */}
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({});

export default connect(
    (state) => {
        return {
            auth: state.auth,
            child: state.child,
            jobs: state.kidJobs,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...jobsActions, ...authActions },
            dispatch
        ),
    })
)(Jobs);
