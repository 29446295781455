/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput';
import Text from '../../components/CustomText';
import { Button, buttonStylesV2 } from '../../components/Buttons';

import { InfoButton } from '../../components/newChild/Buttons';

import Colors from '../../constants/Colors';
import { savingsInfo } from '../../constants/info';
import { InputRow } from './rows/InputRow';
import { inputConvigV2, RegularInput } from '../RegularInput/RegularInput';

const auth = firebase.auth;
const database = firebase.database;

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

const iconsVisible = ['interestRateVisible'];

class Settings extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            headerLeft: null,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
            ...Object.keys(savingsInfo).reduce((prev, curr, currIndex, arr) => {
                return { ...prev, [curr]: '' };
            }, {}),
            // int_rate: '10',
            // withdraw_fee: '10',
            // withdraw_limit: '10',
        };
    }

    componentDidMount() {
        const { parentId } = this.props.auth;
        const { childIds } = this.props.parent;

        // const settings = this.props.auth.settings
        // this.setState({ ...this.props.auth.settings[childIds[this.props.id]] })
        this.props.actions
            .getSettings(parentId, childIds[this.props.id])
            .then((settings) => {
                console.log('settings!!', settings);
                this.setState({ ...settings, isLoading: false });
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            const { parentId } = this.props.auth;
            const { childIds } = this.props.parent;

            this.setState({ isLoading: true });
            this.props.actions
                .getSettings(parentId, childIds[this.props.id])
                .then((settings) => {
                    console.log('settings!!', settings);
                    this.setState({ ...settings, isLoading: false });
                });
        }
    }

    // rgba(126, 193, 61, 0.1)

    clearChildren() {
        const { parentId } = this.props.auth;

        database()
            .ref('/users/' + parentId)
            .remove();

        database()
            .ref('/parents/' + parentId)
            .remove();

        this.props.actions.getChildNodes(parentId);
    }

    row({ key, item, onChange }) {
        const t = savingsInfo[item];
        const value = this.state[item];
        const title = t.label;

        return (
            <RegularInput
                {...inputConvigV2}
                value={t.format(value.toString())}
                onChangeText={onChange}
                // error=
                {...t.params}
                label={title}
                infoButtonText={t.text}
            />
        );
    }

    render() {
        const { layout, actions, id, settings } = this.props;

        const { parentId } = this.props.auth;
        const { childIds, names, isLoading } = this.props.parent;

        const vmin = Math.min(...[layout.width, layout.height]);

        // const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
        //     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}
        return (
            <View
                style={{ width: '100%', alignItems: 'center' }}
                onStartShouldSetResponder={() => {
                    this.setState({
                        ...iconsVisible.reduce((prev, curr, currIndex, arr) => {
                            return { ...prev, [curr]: false };
                        }, {}),
                    });
                }}
            >
                {this.state.isLoading ? (
                    <View
                        style={{
                            opacity: 0.5,
                            zIndex: 1,
                            position: 'absolute',
                            borderRadius: 15,
                            backgroundColor: 'transparent',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <ActivityIndicator
                            style={{
                                margin: 85,
                                top: 10,
                                alignSelf: 'center',
                                top: 0.04 * vmin,
                            }}
                            size={vmin > 650 ? 'large' : 'small'}
                            color={Colors.green1}
                        />
                    </View>
                ) : null}
                {
                    <View
                        style={{
                            opacity: this.state.isLoading ? 0.5 : 1,
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                width: '100%',
                                paddingHorizontal: 10,
                                alignItems: 'center',
                                marginTop: 10,
                            }}
                        >
                            {Object.keys(savingsInfo).map((item, index) =>
                                this.row({
                                    key: index,
                                    item: item,
                                    onChange: (text) =>
                                        this.setState({ [item]: text }),
                                })
                            )}

                            <Button
                                {...buttonStylesV2}
                                title={'Save Settings'}
                                onPress={() => {
                                    console.log(
                                        'INT RATE,',
                                        this.state.int_rate
                                    );

                                    this.props.actions.updateSettings(
                                        parentId,
                                        childIds[id],
                                        {},
                                        {
                                            ...Object.keys(savingsInfo).reduce(
                                                (prev, cur) => {
                                                    return {
                                                        ...prev,
                                                        [cur]: this.state[cur],
                                                    };
                                                },
                                                {}
                                            ),
                                            int_rate:
                                                parseFloat(
                                                    this.state.int_rate
                                                        .toString(10)
                                                        .replace('$', '')
                                                ) / 100,
                                        }
                                    );

                                    console.log('sdf');

                                    this.props.navigation.navigate('Settings');
                                }}
                            />
                        </View>
                    </View>
                }
            </View>
        );
    }
}

const styles = StyleSheet.create({});

export default Settings;
// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
