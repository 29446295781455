/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Resizable } from 'react-native-web-hooks';
import moment from 'moment';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import { formatNumberFloat } from '../../helpers/formatNumber';

export class SectionRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 0,
            data_display: 'none',
        };
    }

    returnType(item) {
        if (item.type === 'job') {
            return item.category;
        } else if (item.type.includes('fee')) {
            return item.type.replace('savings', '').replace('purchase', '');
        } else if (item.type.includes('purchase')) {
            return item.name;
        }
        // else if (item.type==='withdrawal') {
        //   return item.name
        // }
        else {
            return item.name || item.type;
        }
    }

    renderList(data, vmin) {
        if (!data) {
            return null;
        }

        const priceLength = parseInt(
            Math.max(
                ...data.map(
                    (item) =>
                        parseFloat(item.amount).toFixed(2).toString().length
                )
            ),
            10
        );
        // const priceLength = 1

        return this.props.renderList
            ? this.props.renderList(data)
            : data.map((item, index) => (
                  <View
                      key={index.toString()}
                      style={{
                          width: '100%',
                          marginTop: 0.02 * vmin,
                          paddingRight: 0.02 * vmin,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                      }}
                  >
                      <Text style={{ color: Colors.black }}>
                          {
                              moment(item.transaction_date).format('MM/DD/YYYY')
                              // new Date((new Date(item.transaction_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
                              // new Date(item.transaction_date).toLocaleDateString("en-US")
                          }
                      </Text>
                      <Text
                          numberOfLines={2}
                          style={{
                              flex: 0.9,
                              marginHorizontal: 10,
                              color: Colors.black,
                              textAlign: 'center',
                          }}
                      >
                          {this.returnType(item)}
                      </Text>
                      {/* <Text style={{ flex: 1, color: '#106433', textAlign: 'left' }}>{item.type === 'job' ? item.category : item.type}</Text> */}
                      {/* <Text style={{ flex: 1, color: '#106433', textAlign: 'left' }}>{item.type === 'job' ? item.category : item.type.split(' ')[item.type.split(' ').length - 1]}</Text> */}
                      <View
                          style={{
                              // minWidth: Platform.OS === 'web' ? 'fit-content' : (item.amount ? ('$' + item.amount) : '').length * 10,
                              width: (priceLength + 1) * 11,
                          }}
                      >
                          <Text
                              numberOfLines={1}
                              style={{
                                  color: Colors.black,
                                  textAlign: 'left',
                              }}
                          >
                              ${formatNumberFloat(item.amount)}
                          </Text>
                      </View>
                  </View>
              ));
    }

    display(value) {
        return value === 'none' ? 'flex' : 'none';
    }

    render() {
        const { bottomBorder } = this.props;

        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    return (
                        <View style={{ width: '100%', paddingVertical: 10 }}>
                            <TouchableNativeFeedback
                                onPress={() => {
                                    this.setState({
                                        data_display: this.display(
                                            this.state.data_display
                                        ),
                                    });
                                }}
                            >
                                <View
                                    style={[
                                        styles.rowHeader,
                                        {
                                            marginTop: 0.02 * vmin,
                                        },
                                    ]}
                                >
                                    <View style={styles.rowTitleContainer}>
                                        <Text
                                            style={[
                                                styles.rowTitle,
                                                defaultStyles.bold,
                                            ]}
                                        >
                                            {this.props.title}
                                        </Text>
                                        <Text
                                            style={[
                                                { textAlign: 'center' },
                                                defaultStyles.bold,
                                            ]}
                                        >
                                            $
                                            {formatNumberFloat(
                                                this.props.value
                                            )}
                                        </Text>
                                    </View>
                                    <View style={styles.rowTransactions}>
                                        <Text
                                            style={[
                                                styles.rowTransactionsText,
                                                {
                                                    marginBottom: 0.01 * vmin,
                                                },
                                            ]}
                                        >
                                            {this.props.transactions}{' '}
                                            Transactions
                                        </Text>
                                        <Text
                                            style={[
                                                styles.rowIcon,
                                                globalStyle.h1,
                                            ]}
                                        >
                                            {this.state.data_display === 'none'
                                                ? '+'
                                                : '-'}
                                        </Text>
                                    </View>
                                </View>
                            </TouchableNativeFeedback>
                            <View
                                style={{
                                    marginTop: 15,
                                    display: this.state.data_display,
                                    paddingBottom: 0.02 * vmin,
                                    marginLeft:
                                        layout.width > 850
                                            ? 0.1 * vmin
                                            : 0.05 * vmin,
                                }}
                            >
                                {this.renderList(this.props.data, vmin)}
                            </View>
                            {bottomBorder && (
                                <View style={styles.bottomBorder} />
                            )}
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

export class SectionTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 0,
            table_display: 'none',
        };
    }

    render() {
        console.log('TABLE - ', this.props);

        return (
            <Resizable>
                {(layout) => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    return (
                        <View
                            style={[
                                styles.tableContainer,
                                {
                                    marginTop: 0.05 * vmin,
                                },
                                defaultStyles.shadow,
                            ]}
                        >
                            <View
                                elevation={7}
                                style={[
                                    styles.tableHeader,
                                    defaultStyles.shadow,
                                ]}
                            >
                                <View
                                    style={[
                                        styles.tableTitle,
                                        {
                                            marginTop: 0.03 * vmin,
                                            marginHorizontal: 0.05 * vmin,
                                        },
                                    ]}
                                >
                                    <Text
                                        style={[
                                            { color: Colors.black },
                                            defaultStyles.bold,
                                        ]}
                                    >
                                        {this.props.title}
                                    </Text>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={[
                                                { color: Colors.black },
                                                defaultStyles.bold,
                                            ]}
                                        >
                                            $
                                            {formatNumberFloat(
                                                this.props.value
                                            )}
                                        </Text>
                                    </View>
                                </View>
                                <Text
                                    style={[
                                        styles.tableTransactions,
                                        {
                                            marginLeft: 0.05 * vmin,
                                            marginBottom: 0.02 * vmin,
                                        },
                                    ]}
                                >
                                    {this.props.transactions} Transactions
                                </Text>
                            </View>
                            {this.props.children}
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    tableContainer: {
        elevation: 4,
        backgroundColor: 'white',
        // borderWidth: 1,
        // shadowColor: "#000", shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 3.84,
        width: '90%',
        minWidth: 300,
        maxWidth: 800,
        justifyContent: 'center',
        alignSelf: 'center',
        borderRadius: 25,
    },
    tableHeader: {
        backgroundColor: 'white',
        width: '100%',
        borderRadius: 25,
    },
    tableTitle: {
        marginBottom: 10,
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tableTransactions: {
        flex: 1,
        color: Colors.black,
    },
    rowHeader: {
        flex: 1,
        marginBottom: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: '5%',
    },
    rowTitleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    rowTitle: {
        flex: 1,
        width: '70%',
        color: Colors.black,
    },
    rowTransactions: {
        flex: 0,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    rowTransactionsText: {
        flex: 1,
        color: Colors.black,
    },
    rowIcon: {
        marginRight: 15,
        textAlign: 'right',
        color: Colors.green1,
    },
    bottomBorder: {
        width: '90%',
        height: 0.5,
        opacity: 0.2,
        alignSelf: 'center',
        backgroundColor: Colors.black,
    },
});
