import firebase from 'firebase/app';
import 'firebase/database';

import config from '../../constants/config';

const database = firebase.database;
const storage = firebase.storage;


export async function getChildIds({ parentId }) {
    const childIds = database()
    .ref()
    .child(`parents/${parentId}/children` )
    .once('value')
    .val();

    return childIds;
}

export async function getChildBalance({ parentId, childId, type = 'chequing' }) {
    const childIds = await database()
    .ref()
    .child(`users/${parentId}/${childId}/${type}/balance` )
    .once('value');

    return childIds.val();
}

export async function getChildData({ parentId, childId }) {
    const childIds = await database()
    .ref()
    .child(`users/${parentId}/${childId}/settings` )
    .once('value');

    return childIds.val();
}

export async function getChildAvatarUrl({ parentId, childId }) {
    const metadata = await storage()
    .ref(`children/${childId}.png`)
    .getMetadata();

    const uri = await storage()
    .ref('children/' + childId + '.png')
    .getDownloadURL()
    return { uri, metadata };
}

export async function getChildrenChequingBalances({ parentId, childIds }) {
    const requests = childIds.map(childId => getChildBalance({ parentId, childId }));
    const resp = await Promise.all(requests);

    return resp;
}

export async function getChildrenSavingsBalances({ parentId, childIds }) {
    const requests = childIds.map(childId => getChildBalance({ parentId, childId, type: 'savings' }));
    const resp = await Promise.all(requests);

    return resp;
}

export async function getChildrenData({ parentId, childIds }) {
    const settingsArr = childIds.map(childId => getChildData({ parentId, childId }));
    let settings = await Promise.all(childIds.map(childId => getChildData({ parentId, childId })));
    // settings = settings.map(async (item, index) => {
    //     console.log({item})
    //     if (item.avatar === 5) {
    //         const data = await getChildAvatarUrl({childId: childIds[index]});
    //         return {...item, uri: data.uri, ...data.metadata};
    //     }
    //     return item;
    // });

    for (let i = 0; i < settings.length; i++) {
    // for await (const i of ) {
        if (settings[i].avatar === 5) {
            const data = await getChildAvatarUrl({childId: childIds[i]});
            settings[i] = {...settings[i], uri: data.uri, ...data.metadata};
        }
    }
    return settings;
}

