import React, { Component } from 'react';
import { View, TextInput, Animated, Platform, StyleSheet } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';

import TouchableNativeFeedback from '../components/TouchableFeedback';

import Colors from '../constants/Colors';
import { IS_WEB } from '../constants/constants';

export default class CounterTextInput extends Component {
    state = {
        isFocused: false,
        _animatedIsFocused: new Animated.Value(this.props.value === '' ? 0 : 1),
    };

    //   componentDidMount() {
    //     this._animatedIsFocused = new Animated.Value(
    //       this.props.value === '' ? 0 : 1
    //     );
    //   }

    componentDidUpdate() {
        Animated.timing(this.state._animatedIsFocused, {
            toValue: this.state.isFocused || this.props.value !== '' ? 1 : 0,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }

    render() {
        const {
            label,
            onPressDown,
            onPressUp,
            centered,
            inputRef,
            containerStyle,
            style,
            inputStyle,
            // input,
            ...otherProps
        } = this.props;
        const labelStyle = {
            position: 'absolute',
            left: 0,
            right: 0,
            justifyContent: 'center',
            alignItems: centered ? 'center' : 'flex-start',
            top: this.state._animatedIsFocused.interpolate({
                inputRange: [0, 1],
                outputRange: [11, 42],
            }),
        };
        const textStyle = {
            fontSize: 14,
            // color:'#5aca3c',
            fontFamily: 'Carmen Sans Regular',
            color: Colors.gray1,
        };
        const textStyle1 = {
            fontSize: this.state._animatedIsFocused.interpolate({
                inputRange: [0, 1],
                outputRange: [20, 14],
            }),
            color: this.state._animatedIsFocused.interpolate({
                inputRange: [0, 1],
                outputRange: ['#aaa', '#000'],
            }),
        };

        return (
            <View style={[styles.container, containerStyle]}>
                <Animated.View style={labelStyle}>
                    <Animated.Text style={textStyle}>
                        {this.props.label}
                    </Animated.Text>
                </Animated.View>
                <View style={[styles.inputContainer, style]}>
                    <TextInput
                        {...otherProps}
                        ref={inputRef}
                        // onSubmitEditing={()=>{ this.passwordTextInput.focus()}}
                        style={[styles.input, inputStyle]}
                        multiline={false}
                        keyboardType={
                            Platform.OS === 'web' ? 'default' : 'numeric'
                        }
                        // placeholder={'Email'}
                        onFocus={() => this.setState({ isFocused: true })}
                        onBlur={() => this.setState({ isFocused: false })}
                    />
                    <View
                        style={{
                            // flex: 1,
                            // width: '100%',
                            right: 15,
                            justifyContent: 'space-between',
                        }}
                    >
                        <TouchableNativeFeedback onPress={this.props.onPressUp}>
                            {/* position: 'absolute', alignSelf: 'flex-end', right: 10, top: 0, zIndex: 10  */}
                            <Icon
                                style={{ margin: 0, padding: 0 }}
                                name={'angle-up'}
                                size={15}
                                color={Colors.gray}
                            />
                        </TouchableNativeFeedback>
                        <TouchableNativeFeedback
                            onPress={this.props.onPressDown}
                        >
                            <Icon
                                style={{ margin: 0, padding: 0 }}
                                name={'angle-down'}
                                size={15}
                                color={Colors.gray}
                            />
                        </TouchableNativeFeedback>
                    </View>
                    {/* </View> */}
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: { width: '80%', zIndex: 1 },
    inputContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        height: 40,
        width: '100%',
        borderWidth: 2,
        borderRadius: 20,
        marginBottom: 20,
        borderColor: Colors.gray,
    },
    input: {
        ...(IS_WEB && { outlineWidth: 0 }),
        height: 40,
        color: Colors.gray1,
        fontFamily: 'Carmen Sans Regular',
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        marginBottom: 20,
    },
});

// green1 5aca3c
// red1 ffa0ad
// green2 6fc55e
// 6fc55e
