/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    Image,
    Keyboard,
    KeyboardAvoidingView,
    StyleSheet,
    TextInput,
    View,
    Platform,
    Linking,
} from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Resizable } from 'react-native-web-hooks';

import LinearGradient from 'react-native-linear-gradient';
// import * as Google from 'expo-auth-session/providers/google';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import TouchableNativeFeedback from '../components/TouchableFeedback';

import Text from '../components/CustomText';
import { ConfirmationView } from '../components/confirmation';
// import GoogleSignInButton from '../components/Auth/GoogeSignInButton';
import { AuthTextInput } from '../components/Auth/AuthTextInput';
import { AuthButton } from '../components/Auth/AuthButton';

import * as authActions from '../store/actions/authActions';
import * as parentActions from '../store/actions/parents/authActions';

import Colors from '../constants/Colors';
import config from '../constants/config';
import globalStyle, { defaultStyles } from '../constants/style';

import ModalContainer from '../components/Modal/Modal';
import { AuthContainer } from '../components/Auth/AuthContainer';
import { DEFAULT_MODAL_PROPS } from '../constants/Layout';
import { DevComponent } from '../helpers/dev/runDev';

// const Constants = require('../app.json');

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

// const manifest = Constants.expo;
const auth = firebase.auth;
const database = firebase.database;

const error_codes = {
    'auth/email-already-in-use': 1,
    'auth/invalid-email': 2,
    'auth/operation-not-allowed': 3,
    'auth/weak-password': 4,
};

const CLIENT_ID =
    '141497077893-f8p9s827f548urku53dq0cfpec1uke3c.apps.googleusercontent.com';

var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'https://pennygembank.com/',
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'pennygembank.com'
};

// TODO replace with common component
const RejectionView = ({ title, subtitles, okText, okPress }) => {
    return (
        <View style={rejectionStyles.container}>
            <View style={rejectionStyles.textContainer}>
                <Text style={rejectionStyles.title}>{title}</Text>
                {subtitles.map((subtitle, index) => (
                    <Text key={index} style={rejectionStyles.subtitle}>
                        {subtitle}
                    </Text>
                ))}
            </View>

            <View style={rejectionStyles.buttonContainer}>
                <View style={{ width: '80%' }}>
                    <TouchableNativeFeedback onPress={okPress}>
                        <LinearGradient
                            // start={[0, 1]}
                            // end={[1, 0]}
                            start={{x: 0, y: 1}}
                            end={{x: 1, y: 0}}
                            colors={[Colors.green2, Colors.green1]}
                            style={rejectionStyles.gradient}
                        >
                            <Text
                                style={[
                                    rejectionStyles.buttonText,
                                    defaultStyles.bold,
                                    globalStyle.h3,
                                ]}
                            >
                                {okText ? okText : 'OK'}
                            </Text>
                            {/* </View> */}
                        </LinearGradient>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    );
};

const rejectionStyles = StyleSheet.create({
    container: { justifyContent: 'center', alignItems: 'center' },
    textContainer: { marginVertical: 15, alignItems: 'center' },
    title: {
        textAlign: 'center',
        color: Colors.gray0,
        fontSize: 18,
        margin: 5,
    },
    subtitle: { fontSize: 20, margin: 5, color: '#5aca3c' },
    buttonContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        marginHorizontal: 15,
    },
    gradient: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        borderRadius: 20,
    },
    buttonText: {
        textAlign: 'center',
        marginVertical: 5,
        marginHorizontal: 15,
        color: 'white',
    },
});

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.passwordInput = React.createRef();
        this.confirmPasswordInput = React.createRef();

        this.state = {
            displayName: '',
            email: '',
            password: '',
            confirmPassword: '',
            success: false,
        };
    }

    initAsync = async () => {
        await GoogleSignIn.initAsync({
            // You may ommit the clientId when the firebase `googleServicesFile` is configured
            clientId: CLIENT_ID,
        });
    };

    signInAsync = async () => {
        try {
            await GoogleSignIn.askForPlayServicesAsync();
            const { type, user } = await GoogleSignIn.signInAsync();

            if (type === 'success') {
                console.log(type, user);
            }

            console.log(type, user);
        } catch ({ message }) {
            alert('login: Error:' + message);
        }
    };

    componentDidMount() {
        // this.initAsync();
    }

    signup() {
        const email = this.state.email;
        const password = this.state.password;
        const { sendRegistrationEmail } = this.props.actions;

        if (this.state.password === this.state.confirmPassword) {
            auth()
                .createUserWithEmailAndPassword(email, password)
                .then(() => {
                    return this.props.actions.login({
                        user: firebase.auth().currentUser,
                    });
                })
                .then(() => {
                    const user = firebase.auth().currentUser;

                    console.log(1);
                    this.props.actions.signup(user);
                    console.log(2);

                    database()
                        .ref('emails/')
                        .update({
                            [user.uid]: user.email,
                        });

                    console.log(3);

                    this.setState({
                        success: true,
                        authModalVisible: true,
                        email: user.email,
                    });

                    console.log(4);

                    database()
                        .ref()
                        .child('user_type/' + user.uid)
                        .update({ type: 0, parentId: user.uid });

                    console.log(5);

                    // TODO whats with name
                    sendRegistrationEmail('', email);

                    console.log(6);

                    this.props.actions
                        .login({ user: firebase.auth().currentUser })
                        .then(() => this.props.navigation.navigate('NewKid'));

                    console.log(7);
                })
                .catch((error) => {
                    console.log('ERROR - ', error);
                    this.setState({ authModalVisible: true });
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    switch (errorCode) {
                        case 'auth/email-already-in-use':
                            this.setState({
                                errorBlock: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        case 'auth/invalid-email':
                            this.setState({
                                errorBlock: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        case 'auth/operation-not-allowed':
                            this.setState({
                                errorBlock: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        case 'auth/weak-password':
                            this.setState({
                                errorBlock: true,
                                errorPassword: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        default:
                            this.setState({
                                errorBlock: true,
                                errorText: 'Something went wrong =(',
                            });

                            return 0;
                    }
                });
        } else {
            this.setState({
                authModalVisible: true,
                errorBlock: true,
                errorText: "Passwords don't match",
            });
        }
    }

    render() {
        const { layout } = this.props;

        const vmin = Math.min(...[layout.width, layout.height]);

        return (
            <View style={styles.container}>
                <Modal
                    {...DEFAULT_MODAL_PROPS}
                    onBackdropPress={() =>
                        this.setState({ authModalVisible: false })
                    }
                    isVisible={this.state.authModalVisible}
                    onRequestClose={() => {
                        this.setState({ authModalVisible: false });
                    }}
                >
                    <View elevation={5} style={styles.modalContainer}>
                        <RejectionView
                            title={this.state.errorText}
                            subtitles={[]}
                            okPress={() => {
                                this.setState({ authModalVisible: false });
                            }}
                        />
                    </View>
                </Modal>

                <React.Fragment>
                    <Text
                        style={[
                            styles.title,
                            {
                                marginTop: 0.04 * vmin,
                                margin: 0.02 * vmin,
                            },
                            defaultStyles.bold,
                            globalStyle.h2,
                        ]}
                    >
                        Sign Up for Free!
                    </Text>
                    <View style={{ width: '100%', alignItems: 'center' }}>
                        {/* <AuthTextInput value={this.state.displayName}
                    onSubmitEditing={() => { this.emailInput.current.focus() }}
                    onChangeText={text => this.setState({ errorBlock: false, displayName: text })}
                    placeholder={'Parent User Name'}/> */}
                        <AuthTextInput
                            inputRef={this.emailInput}
                            keyboardType={'email-address'}
                            value={this.state.email}
                            onChangeText={(text) =>
                                this.setState({
                                    errorBlock: false,
                                    email: text,
                                })
                            }
                            onSubmitEditing={() => {
                                this.passwordInput.current.focus();
                            }}
                            placeholder={'Email Address'}
                        />
                        <AuthTextInput
                            inputRef={this.passwordInput}
                            onSubmitEditing={() => {
                                this.confirmPasswordInput.current.focus();
                            }}
                            secureTextEntry={true}
                            value={this.state.password}
                            onChangeText={(text) =>
                                this.setState({
                                    errorBlock: false,
                                    password: text,
                                })
                            }
                            placeholder={'Password'}
                        />
                        <AuthTextInput
                            inputRef={this.confirmPasswordInput}
                            onSubmitEditing={() => {
                                this.signup();
                            }}
                            secureTextEntry={true}
                            value={this.state.confirmPassword}
                            onChangeText={(text) =>
                                this.setState({
                                    errorBlock: false,
                                    confirmPassword: text,
                                })
                            }
                            placeholder={'Confirm Password'}
                        />
                    </View>
                    <AuthButton
                        title={'Get Started'}
                        onPress={() => {
                            this.signup();
                            // this.setState({final:true})
                        }}
                    />

                    <View>
                        <DevComponent>
                            <TouchableNativeFeedback
                                onPress={() => {
                                    this.setState(
                                        {
                                            email: 'test_parent@pennygemapp.com',
                                            password: '123456',
                                            confirmPassword: '123456',
                                        },
                                        () => this.signup()
                                    );
                                }}
                            >
                                <Text>Create test user</Text>
                            </TouchableNativeFeedback>
                        </DevComponent>
                        {/* <GoogleSignInButton /> */}
                        {/* <TouchableNativeFeedback onPress={() => {this.signInAsync() }}>
                            <Text>text</Text>
                        </TouchableNativeFeedback> */}
                    </View>
                </React.Fragment>
            </View>
        );
    }
}

class SignUpScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
        };
    }

    onLoginPress = () => {
        this.props.navigation.navigate('Login');
    };

    render() {
        // if (!this.state.fontsLoaded) {
        // return <AppLoading />
        // }
        return (
            <Resizable>
                {(layout) => {
                    return (
                        <AuthContainer
                            clearAuth={this.props.actions.clearAuth}
                            footerButtonText={'Log in'}
                            footerInfoText={'Already have an account?'}
                            onFooterButtonPress={this.onLoginPress}
                        >
                            <SignUp layout={layout} {...this.props} />
                        </AuthContainer>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        minWidth: 300,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 15,
        // backgroundColor: Colors.transparentWhite,
    },
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    modalContainer: {
        borderRadius: 20,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 100,
        paddingVertical: 15,
        justifyContent: 'center',
        ...defaultStyles.shadow,
    },
    confirmationText: {
        padding: 10,
        color: Colors.gray0,
        margin: 10,
        textAlign: 'center',
        ...globalStyle.h2,
    },
    title: {
        color: Colors.black,
        textAlign: 'center',
    },
});

export default connect(
    (state) => {
        return {
            auth: state.auth,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...parentActions, ...authActions },
            dispatch
        ),
    })
)(SignUpScreen);
