import child from './auth';

import kidGoals from './goals';
import kidJobs from './jobs';

import kidPurchases from './purchases';
import kidChequing from './chequing';
import kidSavings from './savings';
import kidStore from './store';

export {
    child,
    
    kidGoals, 
    kidJobs,
    kidChequing,
    kidSavings,
    kidStore,
    kidPurchases
};
