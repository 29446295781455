/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { ActivityIndicator, StyleSheet, View, Platform } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import TouchableNativeFeedback from '../../../components/TouchableFeedback';
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput';
import Text from '../../../components/CustomText';

import { ConfirmationView } from '../../../components/confirmation';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper';
import { Button, InfoButton } from '../../../components/newChild/Buttons';

import * as authActions from '../../../store/actions/authActions';
import * as parentActions from '../../../store/actions/parents/authActions';

import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { savingsInfo } from '../../../constants/info';
import { InputRow } from '../../../components/settings/rows/InputRow';
import { defaultStyles } from '../../../constants/style';
import { Loader } from '../../../components/Loader';
import { createNewChild } from '../../../helpers/newChild/newChildRequests';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const appType = 'pennygem-parents';

const auth = firebase.auth;

const title = 'Savings Account Setup';
const description = `These Savings account settings reflect real-world banking
parameters. The settings below are our suggestions, you can
change them.`;

function formatInterestRate(str) {
    console.log('str, ', str);

    return str && str !== '%'
        ? parseFloat(
              str
                  .toString()
                  .replace(/[^\d\.]/g, '')
                  .replace(/\./, 'x')
                  .replace(/\./g, '')
                  .replace(/x/, '.')
          ) + '%'
        : '';
}

class Chequing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(savingsInfo).reduce((prev, curr, currIndex, arr) => {
                return { ...prev, [curr]: savingsInfo[curr].default };
            }, {}),
            advanced: true,
        };
    }

    componentDidMount() {
        const savings = this.props.parent.newKid.savings;

        this.setState({ ...savings });
    }

    row({ info, item, formatInput }) {
        const data = info[item];

        return (
            <InputRow
                text={this.state[item]}
                onChangeText={(text) =>
                    this.setState({
                        [item]: data.format ? data.format(text) : text,
                    })
                }
                inputParams={data.params}
                label={data.label}
                infoButtonText={data.text}
            />
        );
    }

    // formatInput? formatInput(text):

    modalConfirm() {
        // TODO check if it should be returned in future
    }

    modalReject() {
        // TODO check if it should be returned in future
    }

    changeDay(text) {
        console.log('text', text);

        if (text === 'Monthly') {
            console.log('MONTHLY');

            return this.setState({ day: 'End of the month' });
        }

        if (text !== 'none') {
            return this.setState({ day: 'Friday' });
        }
    }

    checkConditions() {
        const { amount } = this.state;

        return amount;
    }

    proceedKids() {
        const { int_rate, withdraw_limit, withdraw_fee, advanced } = this.state;
        const { parentId } = this.props.auth;
        const { newKid } = this.props.parent;
        const { profile, allowance, chequing, expense, expenses } = newKid;
        const { userName, password, email } = newKid.profile;
        // const { allowance, chequing, } = newKid;

        const login = email ? email : userName + '@pennygemapp.com';

        // const { name, userName, password, email, age } = newKid.profile;
        console.log('newKid ', newKid);

        // this.props.startLoading()
        this.setState({ loadingKidsSide: true });
        // :parseInt(int_rate.replace('%',''))
        // this.props.kidData({ type: 'savings', data: { int_rate, withdraw_limit, withdraw_fee } })
        const savings = {
            int_rate: parseFloat(int_rate.replace('%', '')) / 100,
            withdraw_limit,
            withdraw_fee,
            advanced,
        };

        this.props.actions
            .createNewKidMin({
                parentId,
                profile,
                allowance,
                expense,
                expenses,
                chequing,
                savings,
            })
            .then((childId) => {
                this.setState({ loading: false, finishModal: false });
                console.log('NEW KID ', childId);
                this.props.actions.sendAllowanceJob(parentId, childId, {
                    salary_amt: allowance.amount,
                    salary_day: allowance.day,
                    salary_freq: allowance.frequency,
                });

                this.props.actions.createExpence({
                    parentId,
                    childId,
                    amount: expense.amount,
                    title: expense.title,
                    frequency: expense.frequency,
                    day: expense.day,
                });

                for (const i in expenses) {
                    const expense = expenses[i];

                    this.props.actions.createExpence({
                        parentId,
                        childId,
                        amount: expense.amount,
                        title: expense.title,
                        frequency: expense.frequency,
                        day: expense.day,
                    });
                }

                this.props.actions.clearKidData();
                // this.props.navigation.navigate('Dashboard')
                // this.props.navigation.navigate('Walkthrough')
            })
            .then(() => auth().signOut())
            .then(() => this.props.actions.clearAuth())
            .then(() => auth().signInWithEmailAndPassword(login, password))
            .then((user) => {
                console.log('START NAVIGATING');
                console.log('NAVIGATE TO KIDS SIDE');
                console.log('user.uid - ', user.uid);

                return this.props.actions.login(user, 1);
            })
            .then(() => {
                // this.state.rememberMe ? this.props.actions.rememberMe(email) : this.props.actions.rememberMe('');
                this.props.navigation.navigate('Kid');
            });
    }

    proceedParents() {
        const { int_rate, withdraw_limit, withdraw_fee, advanced } = this.state;
        const { parentId } = this.props.auth;
        const { newKid, childIds } = this.props.parent;
        const { profile, allowance, chequing, expense, expenses, firstJob } = newKid;
        const { userName, email } = newKid.profile;
        // const { allowance, chequing, } = newKid;

        const login = email ? email : userName + '@pennygemapp.com';

        // const { name, userName, password, email, age } = newKid.profile;

        // this.props.startLoading()
        this.setState({ loadingKidsSide: true });
        // :parseInt(int_rate.replace('%',''))
        // this.props.kidData({ type: 'savings', data: { int_rate, withdraw_limit, withdraw_fee } })
        const savings = {
            int_rate: parseFloat(int_rate.replace('%', '')) / 100,
            withdraw_limit,
            withdraw_fee,
            advanced,
        };

        console.log('newKid ', newKid, { savings });
        console.log('');

        const onChildCreate = () => {
            if (childIds?.length) {
                this.props.navigation.navigate('Dashboard');
            } else {
                this.props.navigation.navigate('Walkthrough');
            }
        };

        const clearChildData = () => {
            this.props.actions.clearKidData();
        };

        createNewChild({
            parentId,
            profile,
            firstJob,
            allowance,
            chequing,
            savings,
            expense,
            expenses,
            onChildCreate,
            clearChildData,
        });
    }

    proceed() {
        if (appType === 'pennygem-kids') {
            this.proceedKids();
        } else {
            this.proceedParents();
        }
    }

    back() {
        const { int_rate, withdraw_limit, withdraw_fee } = this.state;

        this.props.kidData({
            type: 'savings',
            data: { int_rate, withdraw_limit, withdraw_fee },
        });

        this.props.back();
    }

    render() {
        return (
            <BlockWrapper
                description={description}
                title={title}
                showProgress={true}
            >
                {(props) => (
                    <>
                        <Modal
                            animationIn={'zoomIn'}
                            animationOut={'zoomOut'}
                            animationInTiming={500}
                            animationOutTiming={500}
                            backdropColor={'darkgrey'}
                            backdropOpacity={0.6}
                            style={{ margin: 0 }}
                            onBackdropPress={() =>
                                this.setState({ finishModal: false })
                            }
                            isVisible={this.state.finishModal}
                            onRequestClose={() =>
                                this.setState({ finishModal: false })
                            }
                        >
                            <React.Fragment>
                                {this.state.loading && (
                                    <View style={styles.loading}>
                                        <ActivityIndicator
                                            color={Colors.green1}
                                            size={'large'}
                                        />
                                    </View>
                                )}
                                <View style={styles.confirmationContainer}>
                                    <ConfirmationView
                                        title={'Add another child?'}
                                        subtitles={[]}
                                        confirmPress={this.modalConfirm}
                                        rejectPress={this.modalReject}
                                    />
                                </View>
                            </React.Fragment>
                        </Modal>
                        <Loader loading={this.state.loadingKidsSide} />
                        <View
                            style={{
                                marginVertical: 10,
                                padding: 10,
                                borderRadius: 10,
                                maxWidth: 400,
                                width: '100%',
                            }}
                        >
                            <View
                                style={{
                                    zIndex: 1,
                                    width: '100%',
                                    alignSelf: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {this.row({
                                    info: savingsInfo,
                                    item: 'int_rate',
                                    formatInput: formatInterestRate,
                                })}
                                <View
                                    style={{
                                        width: '100%',
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                        opacity: this.state.advanced ? 1 : 0.5,
                                    }}
                                >
                                    {this.state.advanced ? null : (
                                        <View
                                            style={{
                                                zIndex: 3,
                                                // backgroundColor:'rgba(128,128,128,0.5)',
                                                left: -30,
                                                opacity: 0.5,
                                                position: 'absolute',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />
                                    )}
                                    {this.row({
                                        info: savingsInfo,
                                        item: 'withdraw_limit',
                                    })}
                                    {this.row({
                                        info: savingsInfo,
                                        item: 'withdraw_fee',
                                    })}
                                </View>
                            </View>

                            <TouchableNativeFeedback
                                onPress={() =>
                                    this.setState({
                                        advanced: !this.state.advanced,
                                    })
                                }
                            >
                                <View
                                    style={{
                                        marginLeft: '5%',
                                        zIndex: -1,
                                        marginTop: 10,
                                        width: '100%',
                                        flexDirection: 'row',
                                        marginVertical: 10,
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            borderRadius: 10,
                                            height: 30,
                                            width: 30,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderColor: Colors.green1,
                                            borderWidth: 3,
                                        }}
                                    >
                                        <Icon
                                            style={{
                                                zIndex: -1,
                                                opacity: this.state.advanced
                                                    ? 0
                                                    : 1,
                                            }}
                                            name='check'
                                            size={16}
                                            color={Colors.green1}
                                        />
                                    </View>
                                    {/* <View style={{ borderWidth: 3, borderRadius: 5 }}><Text> ? null : '1'}</Text></View> */}
                                    <View style={{ marginLeft: 10 }}>
                                        <Text
                                            style={{
                                                color: Colors.green1,
                                                ...defaultStyles.bold,
                                            }}
                                        >
                                            No account limits or fees
                                        </Text>
                                    </View>
                                </View>
                            </TouchableNativeFeedback>
                        </View>
                        <View style={styles.buttonContainer}>
                            <Button
                                title={'Back'}
                                onPress={() => this.back()}
                            />
                            <Button
                                title={'Finish'}
                                onPress={() => this.proceed()}
                            />
                        </View>
                    </>
                )}
                {/* </View> */}
            </BlockWrapper>
        );
    }
}

class ChequingScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false,
        };
    }

    componentDidMount() {
        const { childIds } = this.props.parent;
        const { clearAuth, kidClearAuth } = this.props.actions;
        // clearAuth();

        if (childIds.length > 0) {
            this.setState({ section: 2 });
        }
        // kidClearAuth()
    }

    render() {
        return (
            <Chequing
                auth={this.props.auth}
                parent={this.props.parent}
                actions={this.props.actions}
                navigation={this.props.navigation}
                kidData={this.props.actions.kidData}
                back={
                    () => this.props.navigation.navigate('ChequingSetup')
                    // this.setState({ section: this.state.section - 1 })
                }
                // proceed={() => this.setState({ section: this.state.section })}
                proceed={() => {}}
                startLoading={() => this.setState({ loading: true })}
                stopLoading={() => this.setState({ loading: false })}
            />
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    loading: {
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'rgba(126, 193, 61, 0.5)',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    confirmationContainer: {
        borderRadius: 15,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 250,
        justifyContent: 'center',
    },
    buttonContainer: {
        flex: 1,
        width: '50%',
        zIndex: -1,
        justifyContent: 'center',
        flexDirection: 'row',
    },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...parentActions, ...kidAuthActions },
            dispatch
        ),
    })
)(ChequingScreen);
