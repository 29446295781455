/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Platform, StyleSheet, View, ScrollView, TouchableOpacity, Image, LayoutAnimation } from 'react-native';

import {Calendar} from 'react-native-calendars';

import moment from 'moment';
import 'firebase/database';

import Text from '../CustomText';
import TouchableNativeFeedback from '../TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';
import { IS_ANDROID, IS_IOS, IS_WEB } from '../../constants/constants';
import ModalContainer from '../Modal/Modal';
import { useSelector } from 'react-redux';
import { InputImitation } from '../Input/InputImitation';
import { InputWithVariationButtons } from '../Input/InputWithVariationButtons';
import { FONTS } from '../../constants/fonts';
import { Input } from '../Input/Input';
import { Avatar } from '../Avatar';
import { formatNumberFloat } from '../../helpers/formatNumber';

const JOB_CATEGORIES = [
    { title: 'Chore', color: '#FFE8AD' },
    { title: 'Education', color: '#FFC6AD' },
    { title: 'Arts', color: '#CCFFAD' },
    { title: 'Savings', color: '#ADFFEB' },
    { title: 'Other', color: '#ADCEFF' },
];

export const WebDatepicker =
    IS_WEB &&
    styled.input.attrs((props) => ({
        min: new Date().yyyymmdd(),
        onChange: props.onChange,
        value: props.jobDeadline ? props.jobDeadline : '',
        onFocus: () => {
            console.log('sdfsdf');
        },
        placeholder: 'Select Date',
        type: 'date',
        tabIndex: 0,
    }))`
        user-select: none;
        outline: none;
        font-family: Carmen Sans Regular;
        color: ${Colors.gray1};
        font-size: ${(props) => (props.jobDeadline ? '15px' : '0px')};
        height: 40px;
        padding-left: 20px;
        width: 100%;
        background: 0;
        -webkit-appearance: none;
        box-shadow: none;
        border-style: none;
        border-top-style: none;
        border-bottom-style: none;
        &::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }
        ::selection {
            color: red;
            background: yellow;
        }
    `;

const descriptionRef = React.createRef();

const jobFreqs = { None: 0, Daily: 1, Weekly: 2, 'Bi-weekly': 3, Monthly: 4 };

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

function formatInputValue(str) {
    return str || str === 0
        ? str
              .toString()
              .replace(/[^\d\.]/g, '')
              .replace(/\./, 'x')
              .replace(/\./g, '')
              .replace(/x/, '.')
        : '';
}

const JobFieldTitle = ({ title }) => {
    return (
        <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
        </View>
    );
};

function AddJob({
    jobCategory,
    setJobCategory,
    jobValue,
    setJobValue,
    jobTitle,
    setJobTitle,
    jobDeadline,
    setJobDeadline,
    jobStopRepeat,
    setStopRepeat,
    jobFrequency,
    setJobFrequency,
    checkNewJobConditions,
    kids,
    setKid,
    selectedKids,
    setSelectedKids,
    removeKid,
    hideProceedButton,
    onClose,
    showChildSelect = true,
}) {
    const childIds = useSelector(state => state.parent.childIds);
    const settings = useSelector(state => state.parent.settings);
    const names = useSelector(state => state.parent.names);
    const today = useMemo(() => new Date(), []);
    const [childrenVisible, setChildrenVisible] = useState(false);
    // const [blockHeight, setBlockHeight] = useState(40)
    const [show, setShow] = useState(false);
    const [markedDates, setMarkedDates] = useState({});
    const [categorySelectVisible, setCategorySelectVisible] = useState(false);
    const [jobCalendarVisible, setJobCalendarVisible] = useState(false);

    // const [selectedKids1, setSelectedKids] = useState([]);

    const onDateChange = useCallback(
        (event, selectedDate) => {
            const currentDate = new Date(selectedDate) || new Date(jobDeadline);

            setShow(false);

            if (Number.isNaN(currentDate?.getDate()) || !currentDate) {
                setJobDeadline(new Date());
            } else {
                setJobDeadline(currentDate);
            }
        },
        [jobDeadline, setJobDeadline]
    );

    const onRepeatChange = useCallback(
        (event, selectedDate) => {
            const currentDate =
                new Date(selectedDate) || new Date(jobStopRepeat);

            setShow(false);
            console.log('currentDate - ', currentDate);
            console.log('currentDate - ', Number.isNaN(currentDate));
            if (Number.isNaN(currentDate?.getDate()) || !currentDate) {
                setStopRepeat(new Date());
            } else {
                setStopRepeat(currentDate);
            }
        },
        [jobStopRepeat, setStopRepeat]
    );

    const checkConditions = () => {
        const todayMomemt = moment().startOf('day');
        const deadline = moment(jobDeadline).endOf('day');

        console.log('todayMomemt, ', todayMomemt, deadline, jobDeadline);

        const conditions = jobValue.toString().replace(/\D/, '') &&
        jobTitle &&
        // new Date(this.state.jobDeadline) >= today.setHours(0, 0, 0, 0)
        (jobDeadline ) &&
        deadline.diff(todayMomemt, 'days') >= 0 &&
        selectedKids.length

        console.log('CONDITIONS',!!conditions);

        // console.log('jobValue, jobTitle, deadline, jobFrequency, jobDeadline, selectedKids - ', jobValue, jobTitle, deadline, jobFrequency, jobDeadline, selectedKids );
        // TODO check dates
        return !!(conditions);
    };

    const onCreateJobPress = () => {
        if (checkConditions()) {
            checkNewJobConditions();
        }
    };

    console.log({jobDeadline});

    return (
        <ScrollView style={styles.container} contentContainerStyle={styles.scrollContentContainer}>
                <TouchableOpacity
                    style={{ zIndex: 2, position: 'absolute', right: 20, top: 20}}
                    onPress={() => {
                        console.log('LETS CLOSE 2');
                        onClose()}}
                >
                    <Image
                        resizeMode={'contain'}
                        style={{ width: 20, height: 20 }}
                        source={require('assets/Delete-X.png')}
                    />
                </TouchableOpacity>
            <View style={styles.mainTitleContainer}>
                <Text style={styles.mainTitle}>Create Job</Text>
            </View>

            <View style={styles.contentContainer}>
                {/* Category */}
                <InputImitation
                    title={"Category"}
                    containerStyle={{}}
                    inputStyle={{alignItems: 'center'}}
                    value={jobCategory}
                    renderValue={() => {
                        return jobCategory ?
                        <View
                            key={jobCategory} style={{
                            backgroundColor: JOB_CATEGORIES.find(item=>item.title===jobCategory)?.color,
                            width: undefined, borderRadius: 10, marginVertical: 5 }}>
                            <Text style={{paddingHorizontal: 20, paddingVertical: 5 }}>{jobCategory}</Text>
                        </View>
                        : <Text style={{ fontSize: 14, color: '#282828', opacity: 0.5}}>Select Category</Text>
                    }}
                    // placeholder={'Select Category'}
                    placeholderTextColor={'rgba(40, 40, 40, 0.5)'}
                    onPress={() => {
                        setCategorySelectVisible(val => !val);
                        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    }}
                />
                {categorySelectVisible && <View style={{ width: '100%', paddingLeft: 30, alignItems: 'flex-start', backgroundColor: 'white' }}>
                    {JOB_CATEGORIES.map(item => (
                    <TouchableOpacity
                        onPress={() => {
                            setCategorySelectVisible(val => !val);
                            setJobCategory(item.title);
                            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                        }}
                        key={item.title} style={{ backgroundColor: item.color, width: undefined, borderRadius: 10, marginVertical: 5 }}>
                        <Text style={{paddingHorizontal: 20, paddingVertical: 5 }}>{item.title}</Text>
                    </TouchableOpacity>))}
                </View>}

                {/* Description */}
                <Input
                    containerStyle={{ marginVertical: 50}}
                    value={jobTitle}
                    onChangeText={setJobTitle}
                    placeholder={'What is the job about?'}
                    title={'Description'}
                    placeholderTextColor={'rgba(40, 40, 40, 0.5)'}
                />

                <JobFieldTitle title={'Payment'} />

                <InputWithVariationButtons
                    additionalSign={'$'}
                    value={formatNumberFloat(jobValue || 0)}
                    onChange={text=> (setJobValue(text))}
                    // inputStyle
                    onIncrease={() => {setJobValue(parseFloat(jobValue || 0)+1)}}
                    onDecrease={() => {jobValue>0 ? setJobValue(parseFloat(jobValue || 0)-1): null}}
                />

                <InputImitation
                    title={"Due Date"}
                    containerStyle={{marginVertical: 50}}
                    inputStyle={{}}
                    value={jobDeadline}
                    placeholder={'DD/MM/YYYY'}
                    onPress={() => {
                        setJobCalendarVisible(val => !val);
                        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    }}
                />

                {jobCalendarVisible && <Calendar
                    // current={moment(jobDeadline).add(2, 'days').format('YYYY-MM-DD')}
                    // current={moment(jobDeadline).add(2, 'days').format('YYYY-MM-DD')}
                    current={jobDeadline}
                    markedDates={markedDates}
                    // current={'2022-08-24'}
                    // Initially visible month. Default = now
                    initialDate={moment().format('YYYY-MM-DD')}
                    // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
                    minDate={moment().add(1, 'days').format('YYYY-MM-DD')}
                    // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
                    // maxDate={'2012-05-30'}
                    // Handler which gets executed on day press. Default = undefined
                    onDayPress={day => {
                        console.log('selected day', day);
                        // setJobDeadline(day.dateString);
                        setMarkedDates({[day.dateString]: { selected:true }})
                        setJobDeadline(day.dateString);
                    }}
                    // Handler which gets executed on day long press. Default = undefined
                    onDayLongPress={day => {
                        console.log('selected day', day);
                    }}
                    // Handler which gets executed when visible month changes in calendar. Default = undefined
                    onMonthChange={month => {
                        console.log('month changed', month);
                    }}
                    renderArrow={direction => <View style={{width: 25, height: 25, backgroundColor: Colors.green1,
                     borderRadius: 20, justifyContent: 'center', alignItems: 'center' }} >
                        <Image source={require('assets/ArrowSmall.png')} style={{width: 6, height:10, transform: [{rotate: direction==='right' ? '180deg' : '0deg'}]}} />
                        </View>}
                    style={{
                        backgroundColor: '#F9FAF9',
                    }}
                    theme={{
                        backgroundColor: '#F9FAF9',
                        calendarBackground: '#F9FAF9',
                        selectedDayBackgroundColor: Colors.green1,
                        todayTextColor: Colors.green1,
                        textDayFontFamily: FONTS.FONT_DEFAULT,
                        textMonthFontFamily: FONTS.FONT_BOLD,
                        textDayHeaderFontFamily: FONTS.FONT_DEFAULT,
                      }}
                />}

                <JobFieldTitle title={'Frequency'} />

                <View style={{flexDirection: 'row', width: '100%', marginTop: 20, marginBottom: 50}}>
                    {Object.entries(jobFreqs).map((item, index) => <TouchableOpacity
                    onPress={() => setJobFrequency(item[1])}
                    key={index}
                    style={{
                        backgroundColor: jobFrequency === item[1] ? Colors.green1 : '#e4e4e4',
                        flex: 1, borderRadius: 20, alignItems: 'center',
                        marginLeft: index !== 0 ? 5 : 0, paddingVertical: 5,
                     }}>
                        <Text style={{fontSize: 10, color: jobFrequency === item[1] ? Colors.white : Colors.black }}>{item[0]}</Text>
                    </TouchableOpacity>)}
                </View>

                <JobFieldTitle title={'Assign To'} />

                <ScrollView horizontal={true}>
                    {childIds.map((childId) => {
                    const selected = selectedKids.includes(childId);

                    return <Avatar
                        key={childId}
                        containerStyle={{ opacity: selected ? 1 : 0.5, borderWidth: 5, borderColor: selected ? Colors.green1 : 'transparent' }}
                        textStyle={{ color: selected ? Colors.green1 : Colors.black }}
                        index={settings[childId].avatar}
                        uri={settings[childId].uri}
                        name={names[childId]}
                        onPress={() => setSelectedKids(selected ? selectedKids.filter(i => i!==childId) : [...selectedKids, childId])}
                    >
                        {selected && <View style={{ position: 'absolute',
                        right: 0, bottom: 0, width: 25, height: 25,
                        justifyContent: 'center', alignItems: 'center', borderRadius: 25,
                        backgroundColor: Colors.green1 }} >
                            <Image source={require('assets/dashboard/Approve.png')} style={{ tintColor: 'white', width: 12, height: 10 }} /></View>}
                    </Avatar>
                })}
                </ScrollView>

                {(
                    <TouchableNativeFeedback
                        style={styles.createJobButton}
                        onPress={onCreateJobPress}
                    >
                        <Text style={styles.createJobButtonText}>
                            {/* {'Create Job'} */}
                            Save Changes
                        </Text>
                    </TouchableNativeFeedback>
                )}
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: { width: '100%',
    flex: 1,
    backgroundColor: 'white' },
    scrollContentContainer: { paddingHorizontal: '5%',
    // flex: 1,
    alignItems: 'center',},
    titleContainer: {width: '100%'},
    title: {
        // fontSize: 14,
        fontFamily: FONTS.FONT_BOLD,
    },
    mainTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10%',
        marginBottom: '2%',
    },
    mainTitle: {
        color: Colors.black,
        ...globalStyle.h3,
        // ...defaultStyles.bold,
    },
    contentContainer: {
        width: '100%',
        maxWidth: 600,
        alignSelf: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    dropdown: {
        zIndex: 4,
        width: IS_IOS ? '100%' : '80%',
        alignSelf: 'center',
    },
    createJobButton: {
        ...defaultStyles.shadow,
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30,
        backgroundColor: Colors.white,
        height: 80,
        borderRadius: 20,
    },
    createJobButtonText: { color: Colors.green1, ...globalStyle.h2, fontFamily: FONTS.FONT_BOLD },
    mobileDatePickerPress: {
        alignItems: 'center',
        width: '100%',
        width: '80%',
        borderRadius: 20,
        borderWidth: 2,
        borderColor: Colors.gray,
        height: 40,
        justifyContent: 'center',
    },
    kidSelectorWeb: {
        zIndex: 2,
        width: '80%',
        marginTop: 10,
        minHeight: 40,
        borderWidth: 2,
        borderColor: Colors.gray,
        borderRadius: 20,
        justifyContent: 'center',
        // borderBottomLeftRadius: childrenVisible?0:10, borderBottomRightRadius: childrenVisible?0:10,
        alignItems: 'center',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    kidSelectorWebRow: {
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 18,
    },
});

export default AddJob;
