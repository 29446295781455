/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import config from '../../constants/config';
import { createExpence, sendAllowanceJob } from '../jobs/jobRequests';
import { createJob } from '../../requests/jobs/parentJobRequests';

const auth = firebase.auth;

export const createNewChild = async ({
    parentId,
    profile,
    allowance,
    firstJob,
    chequing,
    savings,
    expense,
    expenses,
    onChildCreate,
    clearChildData,
}) => {
    // old complete create child process
    // this.props.actions.createNewKidComplete({ parentId, name, userName, password, email, age,
    //     allowance, chequing, savings })
    //     .then(() => { this.props.stopLoading(); this.props.navigation.navigate('Dashboard') })
    // this.setState({ finishModal: true })

    // TODO use this outside, then remove
    // savings = {
    //     int_rate: parseFloat(int_rate.replace('%', '')) / 100,
    //     withdraw_limit,
    //     withdraw_fee,
    //     advanced,
    // };

    console.log('newKid ', {
        savings,
        parentId,
        profile,
        allowance,
        chequing,
        expense,
        expenses,
    });

    const body = {
        type: 'child',
        parentId,
        ...profile,
        chequing,
        savings,
    };

    // Send request to create new child
    let child = await fetch(config.url + 'createNewChild', {
        method: 'POST',
        body: JSON.stringify(body),
    });

    child = await child.json();
    const childId = child.childId;

    console.log('child, childId ', child, childId);

    // Callback for finish main step
    onChildCreate(childId);

    // Allowance and expense
    const salaryValues = {
        salary_amt: allowance.amount,
        salary_day: allowance.day,
        salary_freq: allowance.frequency,
    };

    sendAllowanceJob(parentId, childId, salaryValues);

    await createJob(
        parentId,
        [childId],
        firstJob.jobDescription,
        firstJob.jobValue,
        firstJob.jobDeadline,
        firstJob.jobCategory,
        firstJob.jobFrequency,
        firstJob.jobStopRepeat
    );

    if (expense.regular) {
        createExpence({
            parentId,
            childId,
            ...expense,
        });

        for (const i in expenses) {
            const additionalExpense = expenses[i];

            createExpence({
                parentId,
                childId,
                ...additionalExpense,
            });
        }
    }

    clearChildData();

    return childId;
};

function formatInterestRate(str) {
    console.log('str, ', str);

    return str && str !== '%'
        ? parseFloat(
              str
                  .toString()
                  .replace(/[^\d\.]/g, '')
                  .replace(/\./, 'x')
                  .replace(/\./g, '')
                  .replace(/x/, '.')
          ) + '%'
        : '';
}

// TODO move to global function
export const createNewChildMain = async ({
    parentId,
    profile,
    chequing,
    savings,
    email,
    childEmail,
}) => {
    const body = {
        type: 'child',
        parentId,
        ...profile,
        chequing,
        savings,
        email,
        childEmail,
    };

    // Send request to create new child
    let child = await fetch(config.url + 'createNewChild', {
        method: 'POST',
        body: JSON.stringify(body),
    });

    child = await child.json();
    const childId = child.childId;

    console.log('child, childId ', child, childId);

    return childId;
};

// TODO FINISH THIS
export const sendChildRegistrationEmail = ({ email, userName, parentId }) => {
    // Send request to create new child
    const body = { userName, email, parentId };

    return fetch(config.url + 'sendRegistrationChildEmail', {
        method: 'POST',
        body: JSON.stringify(body),
    })
        .then((response) => response.json())
        .then((response) => {
            // TODO MOVE TO ACTION / REDUCER
            // dispatch(addNewChild(response.childId, response.name));

            return response;
        });
};
