import React from 'react';
import {
  ActivityIndicator,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import * as authActions from '../store/actions/authActions';
// import * as kidAuthActions from '../store/actions/kids/authActions';

// export default
class AuthLoadingScreen extends React.Component {
  componentDidMount() {
    // this._bootstrapAsync();
    const { type } = this.props.auth
    if (type === 0 || type === 1) {
    	type===1?this.props.navigation.navigate('Kid'):this.props.navigation.navigate('Parent')
    }
    else {
      this.props.navigation.navigate('Auth');
    }
  }

  // Fetch the token from storage then navigate to our appropriate place
  _bootstrapAsync = async () => {
    const userToken = await AsyncStorage.getItem('userToken');

    // This will switch to the App screen or Auth screen and this loading
    // screen will be unmounted and thrown away.
    this.props.navigation.navigate(userToken ? 'App' : 'Auth');
  };

  // Render any loading content that you like here
  render() {
    return (
      <View>
        <ActivityIndicator />
        {/* <StatusBar barStyle="default" /> */}
      </View>
    );
  }
}


export default connect(state => {
	// console.log(Object.keys(state.vacancies));
	return ({
		// fullState: state,
		auth: state.auth,
		// kidAuth: state.kidAuth,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions, }, dispatch)
	})
)(AuthLoadingScreen);
