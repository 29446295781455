/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
    Text,
    TextInput,
    TouchableOpacity,
    Platform,
} from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import * as XLSX from 'xlsx';

import { Button } from '../../../components/Buttons';
import Colors from '../../../constants/Colors';

import globalStyles from '../../../constants/style';

const AddStudents = () => {
    const [individualEmail, setIndividualEmail] = useState();
    const [file, setFile] = useState(null);
    const [emailsData, setEmailsData] = useState([]);
    const [loading, setLoading] = useState(false);

    const onChangeEmail = (text) => {
        setIndividualEmail(text);
    }

    const onSubmitEmail = () => {
        setIndividualEmail('');
    }

    const submitMultipleEmails = () => {
        setFile();
    }

    const renderLoadedEmails = () => {
        console.log('renderLoadedEmails, ', emailsData);

        return <View>
            <Text>First Emails from file</Text>
            {emailsData.slice(0, 2).map(item => <View style={{borderWidth: 1,
            borderColor: Colors.gray1, borderRadius: 15, paddingHorizontal: 5,
            margin: 5,
            }}>{item}</View>)}
            <Text>...</Text>
            <Button title={'Submit'} onPress={addMultipleEmails} />
        </View>
    }

      // process CSV data
    const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/).map(
        line => line.split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)[0],
    );
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    console.log('dataStringLines ', dataStringLines);
    setEmailsData(dataStringLines);
    return;
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));

    // setData(list);
    // setColumns(columns);
    }


    const renderFileInput = () => {
        const openFileLoad = async (event) => {
            if (Platform.OS === 'web') {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = (evt) => {
                    /* Parse data */
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                    processData(data);
                };
                reader.readAsBinaryString(file);

                setFile(file);
            } else {
                try {
                    const pickerResult = await DocumentPicker.pickSingle({
                    //   presentationStyle: '',
                    //   copyTo: 'cachesDirectory',
                    })
                    console.log('pickerResult , ', pickerResult);
                    setFile([pickerResult])
                  } catch (e) {
                    // handleError(e)
                  }
            }
        }

        if (Platform.OS === 'web') {
            return <input type="file"
                name="myFile"
                onChange={openFileLoad}
            />
        }
        return <TouchableOpacity onPress={openFileLoad}>
            <Text>{file ? 'File loaded' : 'Load file'}</Text>
        </TouchableOpacity>
    }

    const addMultipleEmails = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const addIndividualStudent = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        {loading ? <View style={{position: 'absolute', flex: 1,
        width: '100%', height: '100%',
        zIndex: 10,
        justifyContent: 'center', alignItems: 'center',
        backgroundColor: `${Colors.green1}40`}}>
            <ActivityIndicator />
        </View> : null}
        <View style={{width: '100%', maxWidth: 700, alignItems: 'center'}}>
        <Text style={globalStyles.h2}>Add students with the file</Text>
        {renderFileInput()}
        <TouchableOpacity onPress={addMultipleEmails}>
            <Text style={{color: 'gray', fontStyle: 'italic'}}>Template of emails file (image)</Text>
        </TouchableOpacity>
        {emailsData.length ? renderLoadedEmails() : null}

        <Text style={[globalStyles.h2, {marginTop: 20,textAlign: 'center'}]}>Add students individually with email</Text>
        <View>
            <TextInput
                placeholder={'Input email'}
                style={{
                    width: '100%',
                    borderWidth: 1,
                    borderColor: Colors.gray0,
                    marginVertical: 10,
                    padding: 10,
                    borderRadius: 20,
                }}
                value={individualEmail}
                onChangeText={setIndividualEmail}
            />
            <TouchableOpacity style={{
                alignItems: 'center',
                width: '100%',
                backgroundColor: Colors.green1, padding: 10, borderRadius: 20}} onPress={addIndividualStudent}>
                <Text style={{color: 'white'}}>Add student</Text>
            </TouchableOpacity>
            {/* <Button title={'Submit'} onPress={submitMultipleEmails} /> */}

        </View>
        </View>
    </View>
}

const styles = StyleSheet.create({});

// export default Chequing
export default AddStudents;
