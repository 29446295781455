import * as actionTypes from './actionTypes';

import firebase from 'firebase/app';
import 'firebase/database';

const moment = require('moment');

import config from '../../../constants/config';
import { LayoutAnimation } from 'react-native';

const database = firebase.database;

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

function requestJobs() {
    return {
        type: actionTypes.REQUEST_JOBS,
        // data: [],
        isLoading: true,
    };
}

function receiveJobs(childId, json, completed, pending) {
    return {
        type: actionTypes.RECEIVE_JOBS,
        payload: {
            // obj: json,
            childId,
            data: json,
            completed,
            pending,
            isLoading: false,
        },
    };
}

function sortHelper(a, b) {
    if (a.completed === b.completed) {
        return a.deadline - b.deadline;
    } else {
        return a.completed ? -1 : 1;
    }
}

export function getJobs(parentId, childId) {
    return (dispatch) => {
        dispatch(requestJobs());

        var data_source = database().ref(
            '/users/' + parentId + '/' + childId + '/jobs'
        );

        return data_source
            .once('value')
            .then((snapshot) => {
                return snapshot.val();
            })
            .then((snap) => {
                // console.log(snap)
                let json = snap
                    ? Object.keys(snap).map((item) => {
                          return { ...snap[item], key: item };
                      })
                    : [];

                for (var i = 0; i < json.length; i++) {
                    json[i].created = moment(json[i].created);
                    json[i].deadline = moment(json[i].deadline).endOf('day');
                }

                const completed = json
                    .filter((item) => {
                        return item.approved === 1;
                    })
                    .sort((a, b) => (a.deadline > b.deadline ? -1 : 1));
                const pending = json.filter((item) => {
                    return (
                        item.completed === 1 &&
                        item.approved === 0 &&
                        item.action === 0
                    );
                });
                // console.log('json===pending')

                // tasks that are not completed?
                json = json.filter((item) => {
                    return (
                        item.completed === 0 &&
                        item.approved === 0 &&
                        item.action === 0 &&
                        item.deadline >= moment()
                    );
                });

                json.sort((a, b) => {
                    return sortHelper(a, b);
                });

                LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                return dispatch(receiveJobs(childId, json, completed, pending));
            });
    };
}

export function removeJob(parentId, childId, jobId) {
    return (dispatch) => {
        // dispatch(requestJobs())
        console.log('parentId, childId, jobId - ', parentId, childId, jobId);
        var data_source = database().ref(
            '/users/' + parentId + '/' + childId + '/jobs/' + jobId
        );

        return data_source
            .remove()
            .then(() => {
                console.log('successfully removed!!');
            })
            .catch((error) => console.log('ERROR', error));
    };
}

export function removeRecurringJob(jobId) {
    return (dispatch) => {
        // dispatch(requestJobs())
        const body = { jobId };

        return fetch(config.url + 'removeRecurringJob', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((resp) => console.log('resp.remove', resp));
    };
}

export function updateJob(parentId, childId, jobId, job) {
    return (dispatch) => {
        // dispatch(requestJobs())
        console.log('parentId, childId, jobId - ', parentId, childId, jobId);
        var data_source = database().ref(
            '/users/' + parentId + '/' + childId + '/jobs/' + jobId
        );

        // }

        if (job.frequency) {
            updateRecurringJob(parentId, childId, job);
        }

        return data_source
            .update(job)
            .then(() => {
                console.log('successfully udpated!!');
            })
            .catch((error) => console.log('ERROR', error));
    };
}

export function updateRecurringJob(parentId, childId, job) {
    return (dispatch) => {
        // if (job.frequency>0) {
        const now = new Date();
        const deadlineDate = new Date(
            new Date(job.deadline).valueOf() +
                now.getTimezoneOffset() * 60 * 1000
        );

        let daysToComplete = datediff(now, deadlineDate);

        if (daysToComplete === 0) {
            daysToComplete = 1;
        }

        console.log('job, job.scheduleName', job, job.scheduleName);
        const body = {
            parentId,
            childId,
            description: job.description,
            value: job.value,
            daysToComplete: daysToComplete,
            category: job.category,
            jobFrequency: job.frequency,
            scheduleName: job.key,
        };

        return fetch(config.url + 'updateRecurringJob', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((resp) => console.log('resp.update', resp.update));
    };
}

function receiveNewJob(
    category,
    childId,
    description,
    value,
    deadline,
    now,
    jobRef,
    frequency,
    scheduleName
) {
    return {
        type: actionTypes.ADD_JOB,
        payload: {
            // obj: json,
            childId,
            data: {
                // [jobRef]: {
                key: jobRef,
                action: 0,
                amount: value,
                approved: 0,
                category: category,
                completed: 0,
                created: now,
                deadline: moment(deadline).endOf('day'),
                description: description,
                frequency,
                scheduleName,
                // }
            },
        },
    };
}

function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.floor((second - first) / (1000 * 60 * 60 * 24));
}

function calculateDeadline(frequency) {
    const today = moment();

    if (frequency === 1) {
        // console.log(today.add(1, 'days').format("YYYY-mm-DD"))
        return today.add(1, 'days').format('YYYY-MM-DD');
    }

    if (frequency === 2) {
        // console.log(today.add(7, 'days').format("YYYY-mm-DD"))
        return today.add(7, 'days').format('YYYY-MM-DD');
    }

    if (frequency === 3) {
        // console.log(today.add(14, 'days').format("YYYY-mm-DD"))
        return today.add(14, 'days').format('YYYY-MM-DD');
    }

    if (frequency === 4) {
        // console.log(today.add(1, 'months').format("YYYY-mm-DD"))
        return today.add(1, 'months').format('YYYY-MM-DD');
    }

    return today.format('YYYY-MM-DD');
}

export function createJob(
    parentId,
    childIds,
    description,
    value,
    deadline,
    category,
    frequency,
    stopRepeat
) {
    return async (dispatch) => {
        dispatch(requestJobs());

        for (const i in childIds) {
            const childId = childIds[i];

            const now = new Date();

            deadline = frequency ? calculateDeadline(frequency) : deadline;
            const deadlineDate = new Date(
                new Date(deadline).valueOf() +
                    now.getTimezoneOffset() * 60 * 1000
            );

            deadlineDate.setHours(23);
            deadlineDate.setMinutes(59);
            deadlineDate.setSeconds(59);

            let daysToComplete = datediff(now, deadlineDate);

            if (daysToComplete === 0) {
                daysToComplete = 1;
            }
            // console.log('daysToComplete - ')
            // console.log('deadline ', deadline);

            // deadline = deadline && '2020-05-30';
            var jobRef = database()
                .ref()
                .child('users/' + parentId + '/' + childId + '/' + 'jobs')
                .push({
                    action: 0,
                    amount: value,
                    approved: 0,
                    category: category,
                    completed: 0,
                    created: now.toISOString(),
                    deadline: deadlineDate.yyyymmdd(),
                    description: description,
                    frequency: frequency || 0,
                    stopRepeat: stopRepeat,
                }).key;
            // let jobRef = jobPush.getKey()

            const body = {
                parentId,
                childId,
                description,
                value,
                daysToComplete: daysToComplete,
                category,
                jobFrequency: frequency,
                jobRef: jobRef,
                stopRepeat,
            };

            fetch(config.url + 'sendNewJobNotifications', {
                method: 'POST',
                body: JSON.stringify({
                    childId,
                    description,
                    deadline: deadlineDate.yyyymmdd(),
                }),
            }).catch((err) => console.log('Notification Error , ', err));

            if (frequency) {
                fetch(config.url + 'addRecurringJob', {
                    method: 'POST',
                    // headers: {
                    // 	'Accept': 'application/json',
                    // 	'Content-Type': 'application/json'
                    // },
                    body: JSON.stringify(body),
                })
                    .then((response) => {
                        // dispatch(receiveNewJob(childId, description, value, deadline, now, jobRef));
                        return response.json();
                    })
                    .then((resp) => {
                        return resp;
                    });
                // return

                dispatch(
                    receiveNewJob(
                        category,
                        childId,
                        description,
                        value,
                        deadline,
                        now,
                        jobRef,
                        frequency,
                        jobRef
                    )
                );
            } else {
                dispatch(
                    receiveNewJob(
                        category,
                        childId,
                        description,
                        value,
                        deadline,
                        now,
                        jobRef
                    )
                );
            }
        }

        return 0;
    };
}

export function sendNewJobEmail(parentId, childId, description, deadline) {
    return (dispatch) => {
        const body = { parentId, childId, description, deadline };

        console.log(
            'deadline, description, body = ',
            deadline,
            description,
            body
        );

        return fetch(config.url + 'sendNewJobEmail', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}

function completeJobSuccess(jobId) {
    return {
        type: actionTypes.COMPLETE_JOB,
        payload: {
            jobId: jobId,
            isLoading: false,
        },
    };
}

export function completeJob(jobId, parentId, childId) {
    return (dispatch) => {
        const body = { jobId, parentId, childId };

        console.log('WHY HERE PARENTS');

        return fetch(config.url + 'completeJob', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then(() => {
                dispatch(completeJobSuccess(jobId));
            });
        //   .then(response=>response.json())
    };
}

export function approveJob(parentId, childId, job) {
    return async (dispatch) => {
        console.log('0');
        const body = { jobName: job.description, parentId, childId };

        console.log('1');
        var data_source = database().ref(
            '/users/' + parentId + '/' + childId + '/jobs/' + job.key
        );

        data_source.update({ action: 1, approved: 1 });
        // console.log('2')

        var data_source_chequing = database().ref();
        // console.log('3')
        const old_balance = (
            await data_source_chequing
                .child('/users/' + parentId + '/' + childId + '/chequing/')
                .child('balance')
                .once('value')
        ).val();

        // console.log('old_balance',old_balance)
        data_source_chequing
            .child('/users/' + parentId + '/' + childId + '/chequing/')
            .update({ balance: old_balance + parseFloat(job.amount) });

        // console.log('3')
        database()
            .ref(
                '/users/' + parentId + '/' + childId + '/chequing/transactions'
            )
            .push({
                amount: parseFloat(job.amount),
                transaction_date: new Date().toISOString(),
                type: 'job',
                category: job.category,
            });

        return fetch(config.url + 'confirmJob', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}

export function approveAllJobs(parentId, childId, jobs) {
    return async (dispatch) => {
        console.log('0');

        for (const index in jobs) {
            const job = jobs[index];
            const body = { jobName: job.description, parentId, childId };

            console.log('1');
            var data_source = database().ref(
                '/users/' + parentId + '/' + childId + '/jobs/' + job.key
            );

            data_source.update({ action: 1, approved: 1 });
            // console.log('2')

            var data_source_chequing = database().ref();
            // console.log('3')
            const old_balance = (
                await data_source_chequing
                    .child('/users/' + parentId + '/' + childId + '/chequing/')
                    .child('balance')
                    .once('value')
            ).val();

            // console.log('old_balance',old_balance)
            data_source_chequing
                .child('/users/' + parentId + '/' + childId + '/chequing/')
                .update({ balance: old_balance + parseFloat(job.amount) });

            // console.log('3')
            database()
                .ref(
                    '/users/' +
                        parentId +
                        '/' +
                        childId +
                        '/chequing/transactions'
                )
                .push({
                    amount: parseFloat(job.amount),
                    transaction_date: new Date().toISOString(),
                    type: 'job',
                    category: job.category,
                });

            // return
            fetch(config.url + 'confirmJob', {
                method: 'POST',
                // headers: {
                // 	'Accept': 'application/json',
                // 	'Content-Type': 'application/json'
                // },
                body: JSON.stringify(body),
            });
            // .then(response => response.json())
        }
    };
}

export function declineJob(parentId, childId, job) {
    return (dispatch) => {
        const body = { jobName: job.description, parentId, childId };

        var data_source = database().ref(
            '/users/' + parentId + '/' + childId + '/jobs/' + job.key
        );

        data_source.update({ action: 1 });

        // return 0
        return fetch(config.url + 'declineJob', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}

export function removeJobRepeat(parentId, childId, job) {
    return (dispatch) => {
        const body = { job: job, parentId, childId };

        // var data_source = database().ref('/users/' + parentId + '/' + childId + '/jobs/' + job.key);
        // data_source.update({ action: 1, })

        // return 0
        return fetch(config.url + 'removeJobRepeat', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body),
        }).then((response) => response.json());
    };
}
