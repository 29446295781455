/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Image,
    Platform,
    TouchableOpacity,
    View,
    Dimensions,
    Linking,
    StyleSheet,
    useWindowDimensions,
    Clipboard,
} from 'react-native';

import { shallowEqual, useSelector } from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/database';

import { useDispatch } from 'react-redux';

import Text from '../CustomText';
import TouchableNativeFeedback from '../TouchableFeedback';
import ModalContainer from '../Modal/Modal';

import {
    getAvatar,
    getChildName,
    saveAvatarImage,
} from '../../store/actions/kids/authActions';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';

import { _launchCamera, _pickImage } from '../../helpers/changeAvatar';
import { SelectImageModal } from '../Camera/Camera';
import { ContactUs } from '../userFeedback/ContactUs';
import { IS_ANDROID } from '../../constants/constants';
import { getNotificationToken } from '../../helpers/notifications/notifications';
import { SHOW_ACADEMY } from '../../constants/config';

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const database = firebase.database;
const storage = firebase.storage;

// TODO FIX THIS - THIS IS WRONG APP TYPE
const appType = 'pennygem';

const maxWidth = 700;
const borderRadius = 25;
const cardWidth = '90%';

const avatars = [
    // { index: 0, source: require('assets/Avatars/icon_1.png') },
    { index: 1, source: require('assets/Avatars/icon_2.png') },
    { index: 2, source: require('assets/Avatars/icon_3.png') },
    { index: 3, source: require('assets/Avatars/icon_4.png') },
    { index: 4, source: require('assets/Avatars/icon_5.png') },
];

const MENU_ITEMS = [
    { title: 'Settings' },
    // { title: 'Dashboard' },
    // {
    //     title: 'Chequing',
    //     path: 'Chequing',
    //     params: { cards: 'Chequing' },
    // },
    // {
    //     title: 'Savings',
    //     path: 'Chequing',
    //     params: { cards: 'Savings' },
    // },
    // { title: 'Jobs' },
    // { title: 'Goals' },
    // { title: 'Purchases' },
    // { title: 'Store' },
    { title: 'Submit Issue', type: 'Submit Issue' },
    { title: 'Contact us', type: 'Contact Us' },
];

export const MenuIcon = ({
    navigation,
    vmin,
    height,
    signOut,
    clearAuth,
    setSubmit,
}) => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <>
            <TouchableOpacity onPress={() => setModalVisible(true)}>
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'flex-start',
                        marginTop: 10,
                    }}
                >
                    <View
                        style={{ justifyContent: 'space-between', height: 20 }}
                    >
                        {[1, 2, 3].map((item, index) => (
                            <View
                                key={index}
                                style={{
                                    width: 27,
                                    height: 2,
                                    margin: 1,
                                    backgroundColor: Colors.black,
                                }}
                            />
                        ))}
                    </View>
                </View>
            </TouchableOpacity>

            <Modal
                animationIn={'slideInLeft'}
                animationOut={'slideOutLeft'}
                animationInTiming={300}
                animationOutTiming={300}
                backdropColor={'darkgrey'}
                backdropOpacity={0.1}
                onBackdropPress={() => setModalVisible(false)}
                isVisible={modalVisible}
                style={{ margin: 0 }}
                onRequestClose={() => setModalVisible(false)}
            >
                <View
                    elevation={5}
                    style={{
                        flex: 1,
                        margin: 0,
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        height: '100%',
                        width: '100%',
                        padding: 15,
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    }}
                >
                    <View
                        style={{
                            position: 'absolute',
                            right: 0.015 * vmin,
                            top: 0.02 * height,
                        }}
                    >
                        <TouchableNativeFeedback
                            style={{
                                zIndex: 2,
                                right: 0.015 * vmin,
                                top: 0.045 * height,
                            }}
                            onPress={() => {
                                console.log('1231');
                                setModalVisible(false);
                            }}
                        >
                            <Image
                                style={{ height: 30, width: 30 }}
                                source={require('assets/Delete-X.png')}
                            />
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ marginTop: -0.1 * height }}>
                        {MENU_ITEMS.map((item, index) => {
                            return (
                                <TouchableNativeFeedback
                                    key={index}
                                    onPress={() => {
                                        item.type
                                            ? setTimeout(
                                                  () => setSubmit(item.type),
                                                  350
                                              )
                                            : item.path === 'Chequing'
                                            ? navigation.push(
                                                  item.path || item.title,
                                                  item.params
                                              )
                                            : navigation.navigate(
                                                  item.path || item.title,
                                                  item.params
                                              );

                                        setModalVisible(false);
                                    }}
                                >
                                    <View style={{ margin: 10 }}>
                                        <Text
                                            style={[
                                                {
                                                    textAlign: 'center',
                                                    color: Colors.green1,
                                                },
                                                globalStyle.h3,
                                                defaultStyles.bold,
                                            ]}
                                        >
                                            {item.title}
                                        </Text>
                                    </View>
                                </TouchableNativeFeedback>
                            );
                        })}

                        <TouchableNativeFeedback
                            onPress={() => {
                                signOut();
                                clearAuth().then(() => {
                                    navigation.navigate('Login');
                                });
                            }}
                        >
                            <View style={{ margin: 15 }}>
                                <Text
                                    style={[
                                        {
                                            textAlign: 'center',
                                            color: Colors.green1,
                                        },
                                        globalStyle.h3,
                                        defaultStyles.bold,
                                    ]}
                                >
                                    Logout
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </View>
            </Modal>
        </>
    );
};

const GemButton = ({ dashboard, navigation }) => {
    return (
        <TouchableNativeFeedback
            disabled={dashboard}
            style={{ zIndex: 1, position: 'absolute', right: -45 }}
            onPress={() => navigation.navigate('Dashboard')}
        >
            <Image
                style={{ width: 40, height: 40, alignSelf: 'flex-start' }}
                source={require('assets/Pennygem_Logo.png')}
            />
        </TouchableNativeFeedback>
    );
};

const NameRow = ({ name, children }) => {
    return (
        <View
            style={{
                // flex: 1,
                zIndex: -1,
                position: 'absolute',
                bottom: '15%',
                left: '80%',
                backgroundColor: Colors.red,
                flexDirection: 'row',
                alignItems: 'center',
                height: 25,
                minWidth: 120,
                width: (name?.length || 7) * 19,
                borderTopRightRadius: 30,
                borderBottomRightRadius: 30,
            }}
        >
            <Text
                style={[
                    {
                        marginLeft: 30,
                        paddingRight: 10,
                        paddingVertical: 5,
                        color: 'white',
                    },
                    defaultStyles.bold,
                ]}
            >
                {name}
            </Text>
            {children}
        </View>
    );
};

const Avatar = ({ source }) => {
    return (
        <Image
            resizeMode={'cover'}
            style={{
                width: 80,
                height: 80,
                backgroundColor: Colors.gray,
                borderRadius: 80,
                alignSelf: 'flex-start',
            }}
            source={source}
        />
    );
};

const getAvatarImage = ({ childId, currentAvatar, avatarImage }) => {
    // console.log('GET AVATAR - ', currentAvatar, avatarImage);
    if (currentAvatar > 3) {
        return { uri: avatarImage, cache: 'force-cache' };
    }

    if (currentAvatar === -1) {
        return require('assets/Avatars/icon_1.png');
    }

    return avatars[currentAvatar]?.source;
};

function setNewAvatar({ index, parentId, childId }) {
    var database_ref = database().ref('/users/' + parentId + '/' + childId);

    return database_ref.child('settings').update({ avatar: index });
}

export const ParentHeaderCard = ({
    style,
    name,
    navigation,
    signOut,
    clearAuth,
    pressNext,
    dashboard,
}) => {
    const layout = useWindowDimensions();

    const [submit, setSubmit] = useState(false);
    const [currentAvatar, setCurrentAvatar] = useState(0);
    const [avatarImage, setAvatarImage] = useState(null);
    const parentId = useSelector((state) => state.auth.parentId);
    const { childIds, id } = useSelector((state) => state.parent);

    const height = layout.height;
    const childId = childIds[id];
    const vmin = Math.min(layout.width, layout.height);

    useEffect(() => {
        var database_ref = database().ref('/users/' + parentId + '/' + childId);

        const avatar_ref = database_ref.child('settings/avatar');
        // const avatar =

        avatar_ref.once('value').then((val) => {
            // console.log('VALUE ', JSON.stringify(val.val()));
            setCurrentAvatar(val.val() - 1);
            if (val.val() > 5) {
            }
        });

        // TODO optimize this
        if (!avatarImage) {
            storage()
                .ref('children/' + childId + '.png')
                .getDownloadURL()
                .then((image) => {
                    setAvatarImage(image);
                })
                .catch((err) => console.log('ERR IMG ?', -err));
        }

        return () => {};
    }, [childId]);

    return (
        <View
            style={[
                styles.container,
                { marginTop: Platform.OS === 'android' ? height * 0.04 : 0 },
                style,
            ]}
        >
            <View>
                <Avatar
                    source={getAvatarImage({
                        childId,
                        currentAvatar,
                        avatarImage,
                    })}
                />
                <GemButton navigation={navigation} dashboard={dashboard} />
                <NameRow name={name}>
                    <TouchableNativeFeedback onPress={() => pressNext()}>
                        <Image
                            source={require('assets/arrow_white.png')}
                            resizeMode={'contain'}
                            style={{
                                height: globalStyle.page.fontSize,
                                width: globalStyle.page.fontSize,
                                //  marginRight: 5,
                                marginLeft: 10,
                            }}
                        />
                    </TouchableNativeFeedback>
                </NameRow>
            </View>
            <MenuIcon
                navigation={navigation}
                vmin={vmin}
                height={height}
                signOut={signOut}
                clearAuth={clearAuth}
                setSubmit={setSubmit}
            />
            <ContactUs
                layout={layout}
                title={submit}
                onClose={() => setSubmit(false)}
                visible={!!submit}
                navigation={navigation}
            />
        </View>
    );
};

// TODO refactor avatarImage check
export const ChildHeaderCard = ({
    navigation,
    signOut,
    vmin,
    layout,
    clearAuth,
    pressNext,
    dashboard,
    style,
}) => {
    // const avatar = useSelector(state => state.child.avatar);

    const [submit, setSubmit] = useState(false);
    const [currentAvatar, setCurrentAvatar] = useState(undefined);
    const [changeAvatarVisible, setAvatarVisible] = useState(false);
    const [imagePickerVisible, setImagePicker] = useState(false);
    const parentId = useSelector((state) => state.auth.parentId);
    const childId = useSelector((state) => state.child.childId);
    const avatarImageUrl = useSelector((state) => state.child.avatarImageUrl);
    const avatarImage = useSelector((state) => state.child.avatarImage);
    const avatarHash = useSelector((state) => state.child.avatarHash);
    const name = useSelector((state) => state.child.name);

    const dispatch = useDispatch();

    const checkAvatarMetadata = async () => {
        const metadata = await storage()
            .ref(`children/${childId}.png`)
            .getMetadata();
        // md5Hash

        return metadata.md5Hash;
    };

    useEffect(() => {
        var database_ref = database().ref('/users/' + parentId + '/' + childId);

        const avatar_ref = database_ref.child('settings/avatar');

        // const avatar = /;

        avatar_ref.on('value', function (val) {
            setCurrentAvatar(val.val() - 1);
        });

        if (!name) {
            dispatch(getChildName({ childId }));
        }

        // TODO optimize downloads?
        if (!avatarImage) {
            checkAvatarMetadata().then((hash) => {
                return storage()
                    .ref('children/' + childId + '.png')
                    .getDownloadURL()
                    .then(async (image) => {
                        // let image;

                        // if (avatarImage) {

                        if (Platform.OS === 'web') {
                        } else {
                            // TODO save image on device
                            // await FileSystem.deleteAsync(
                            //     FileSystem.documentDirectory + childId + '.png',
                            //     { idempotent: true }
                            // );

                            // image = (
                            //     await FileSystem.downloadAsync(
                            //         image,
                            //         FileSystem.documentDirectory +
                            //             childId +
                            //             '.png'
                            //     )
                            // ).uri;
                        }
                        // }

                        dispatch(saveAvatarImage(5, image, hash));
                    });
            });
        }

        return () => {};
    }, []);

    const onAvatarSuccess = () => {
        setCurrentAvatar(6);
        setAvatarVisible(false);
    };

    const height = layout.height;

    return (
        <View
            style={[
                styles.container,
                { marginTop: IS_ANDROID ? height * 0.03 : 0 },
                style,
            ]}
        >
            <View style={{ flexDirection: 'row' }}>
                <TouchableNativeFeedback
                    onPress={() => setAvatarVisible(!changeAvatarVisible)}
                >
                    <Avatar
                        source={getAvatarImage({ currentAvatar, avatarImage })}
                    />
                </TouchableNativeFeedback>
                {changeAvatarVisible ? (
                    <View
                        contentContainerStyle={{
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                        style={{
                            marginLeft: 5,
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {avatars
                            .filter(
                                (avatarItem, index) =>
                                    avatarItem.index !== currentAvatar + 1
                            )
                            .map((item, index) => (
                                <TouchableNativeFeedback
                                    key={index}
                                    onPress={() => {
                                        setNewAvatar({
                                            index: item.index,
                                            parentId,
                                            childId,
                                        });

                                        setCurrentAvatar(item.index - 1);
                                        setAvatarVisible(false);
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: layout.width * 0.12,
                                            height: layout.width * 0.12,
                                            maxWidth: 50,
                                            maxHeight: 50,
                                        }}
                                        source={item.source}
                                    />
                                </TouchableNativeFeedback>
                            ))}
                        <TouchableNativeFeedback
                            onPress={() => {
                                setImagePicker(true);
                            }}
                        >
                            <Image
                                style={{
                                    width: layout.width * 0.12,
                                    height: layout.width * 0.12,
                                    maxWidth: 50,
                                    maxHeight: 50,
                                }}
                                source={require('assets/Avatars/icon_6.png')}
                            />
                        </TouchableNativeFeedback>
                    </View>
                ) : (
                    <>
                        {SHOW_ACADEMY ? <TouchableOpacity
                            style={{position: 'absolute', zIndex: 2, left: 130, width: 40, height: 40}}

                        onPress={() => {
                            Linking.openURL('https://stemfellowship.org/')
                        }}>
                            <Image
                            style={{width: 40, height: 40}}

                            resizeMode={'contain'}
                            source={require('assets/stem-owl-2.png')}
                        />
                        </TouchableOpacity> : null}
                        <GemButton
                            navigation={navigation}
                            dashboard={dashboard}
                        />
                        <NameRow name={name} />
                    </>
                )}
            </View>
            <SelectImageModal
                imagePickerVisible={imagePickerVisible}
                setImagePicker={setImagePicker}
                onSuccess={onAvatarSuccess}
            />
            <MenuIcon
                navigation={navigation}
                vmin={vmin}
                height={height}
                signOut={signOut}
                clearAuth={clearAuth}
                setSubmit={setSubmit}
            />
            <ContactUs
                layout={layout}
                title={submit}
                onClose={() => setSubmit(false)}
                visible={!!submit}
                navigation={navigation}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingBottom: 15,
        flexDirection: 'row',
        borderRadius,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'center',
        width: cardWidth,
        maxWidth: maxWidth,
    },
});

export const DevNotificationTokenBlock = ({}) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (Platform.OS !== 'web') {
            getNotificationToken().then((data) => {
                // console.log('NOTIFICATION DATA - ', data);
                setToken(data.data);
            });
        }
    }, []);

    if (!__DEV__) {
        return null;
    }

    return (
        <View
            style={{
                flexDirection: 'row',
                width: '90%',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 5,
            }}
        >
            <Text
                numberOfLines={1}
                ellipsizeMode="middle"
                style={{ width: '90%' }}
            >
                {token || 'No token somehow '}
            </Text>
            <TouchableOpacity onPress={() => Clipboard.setString(token)}>
                <Text style={{ borderWidth: 1, borderRadius: 10, padding: 5 }}>
                    Copy
                </Text>
            </TouchableOpacity>
        </View>
    );
};
