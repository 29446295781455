/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Animated,
    Dimensions,
    FlatList,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    TouchableOpacity,
} from 'react-native';

import Carousel from 'react-native-snap-carousel';

import TouchableNativeFeedback from '../../components/TouchableFeedback';

const creditCards = {
    0: require('assets/Accounts/savings_1.png'),
    1: require('assets/Accounts/savings_2.png'),
    2: require('assets/Accounts/savings_3.png'),
    3: require('assets/Accounts/savings_4.png'),
};

const debitCards = {
    0: require('assets/Accounts/debit_1.png'),
    1: require('assets/Accounts/debit_2.png'),
    2: require('assets/Accounts/debit_3.png'),
    3: require('assets/Accounts/debit_4.png'),
};

const renderCard = ({ item, index }, width, carousel) => {
    const num = item.type === 'savings' ? 2 : 1;

    console.log(item.type);
    const scale = 500 / 314;
    const imageWidth = Platform.OS === 'web' ? width * 0.25 : width * 0.3;
    // let imageWidth = width * 0.25
    const imageHeight = Platform.OS === 'web' ? imageWidth / scale : imageWidth;
    // let imageHeight = 400

    const minWidth = 200;
    const minHeight = minWidth / scale;
    // let minHeight = 200

    const boxShadow = `-5px 5px 15px 5px ${
        item.type === 'chequing'
            ? 'rgba(10, 220, 209, 0.5)'
            : 'rgba(126, 193, 61, 0.6)'
    }`;
    const dropShadow = `-5px 5px 10px ${
        item.type === 'chequing'
            ? 'rgba(10, 220, 209, 0.5)'
            : 'rgba(126, 193, 61, 0.6)'
    }`;

    const borderRadius = Math.max(width / 70, 15);

    // return <View elevation={10-index} style={{ position:'absolute', left:width*0.1*index, zIndex:20-index,
    // backgroundColor:'red', borderWidth:1, height:100-10*index, width:width*0.5}}><Text style={{zIndex:10-index}}>{index}</Text></View>
    return item.type ? (
        <View style={{ zIndex: 10, marginVertical: 10 }}>
            <TouchableNativeFeedback
                style={{ zIndex: item.type === 'chequing' ? 3 : 1 }}
                onPress={() => {
                    setTimeout(() => carousel.current.snapToItem(num), 299);
                }}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        zIndex: item.type === 'chequing' ? 3 : 1,
                        alignItems: 'center',
                    }}
                >
                    {
                        <Image
                            resizeMode={'contain'}
                            // blurRadius={1}
                            style={{
                                // filter: `drop-shadow(${dropShadow})`,
                                zIndex: item.type === 'chequing' ? 3 : 1,
                                padding: 0,
                                margin: 0,
                                marginBottom: 10,
                                ...(Platform.OS === 'web' && {
                                    boxShadow: boxShadow,
                                }),
                                borderRadius,
                                minHeight,
                                minWidth,
                                width: imageWidth,
                                height: imageHeight,
                            }}
                            source={
                                item.type === 'chequing'
                                    ? debitCards[item.card]
                                    : creditCards[item.card]
                            }
                        />
                    }
                </View>
            </TouchableNativeFeedback>
        </View>
    ) : null;
};

// class CustomCarousel extends React.Component {
const CustomCarousel = ({
    carouselRef,
    layout,
    type,
    onSnapToItem,
    credit_card,
    debit_card,
}) => {
    const carousel = carouselRef;

    // const { navigation, actions } = this.props;
    // const { parentId } = this.props.auth;
    // const { childIds, names, id } = this.props.parent;
    console.log(
        'credit_card, debit_card , ',
        credit_card,
        debit_card,
        type,
        layout
    );

    return (
        <Carousel
            // layout={layout}
            useScrollView={true}
            ref={carousel}
            data={[
                { type: null },
                { type: 'chequing', card: debit_card },
                { type: 'savings', card: credit_card },
            ]}
            renderItem={(item) => renderCard(item, layout.width, carousel)}
            firstItem={type === 'Chequing' ? 1 : 2}
            inactiveSlideScale={0.6}
            inactiveSlideOpacity={1}
            itemWidth={layout.width * 0.3}
            sliderWidth={layout.width}
            style={{ zIndex: 10 }}
            // containerCustomStyle={{ }}
            contentContainerCustomStyle={{ zIndex: 2, paddingVertical: 12  }}
            slideInterpolatedStyle={(index, animatedValue, carouselProps) => {
                // let animatedOpacity = {};
                // let animatedTransform = {};
                const sizeRef = carouselProps.vertical
                    ? carouselProps.itemHeight
                    : carouselProps.itemWidth;
                const translateProp = carouselProps.vertical
                    ? 'translateY'
                    : 'translateX';

                console.log('carouselProps ', carousel.current._activeItem);

                return {
                    // animatedValue, carouselProps,
                    // elevation: carouselProps.data.length - index,
                    zIndex: index === carousel.current._activeItem ? 1 : -1,
                    // zIndex:animatedValue.interpolate({
                    //     inputRange: [-1, 0],
                    //     outputRange: [1, 10]
                    // }),
                    opacity: animatedValue.interpolate({
                        inputRange: [-1, 0, 1],
                        outputRange: [1, 0.4, 1],
                    }),
                    transform: [
                        {
                            scale: animatedValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: [
                                    carouselProps.inactiveSlideScale,
                                    1,
                                ],
                            }),
                        },
                    ],

                    //   zIndex: animatedValue.interpolate({
                    //     inputRange: [-1, 0, 1],
                    //     outputRange: [0, 10, 0],
                    //     extrapolate: 'clamp'
                    // })
                    //   ...animatedOpacity,
                    //   ...animatedTransform,
                };
            }}
            // style={{width:3400, zIndex:3, height:100}}
            // inverted={true}
            // contentContainerStyle={{justifyContent:'space-between', borderWidth:1,}}
            // contentContainerCustomStyle={{justifyContent:'space-between', flexGrow:1, alignItems:'stretch', borderWidth:1,}}
            // containerCustomStyle={{ paddingVertical: 15, borderWidth: 1 }}
            // slideStyle={{ zIndex: this.state.type === 'Chequing' ? 30 : 1 }}
            onSnapToItem={(index) => {
                // navigation.setParams({'cards':index===0?'Chequing':'Savings'});
                onSnapToItem(index);
            }}
        />
    );
};

const styles = StyleSheet.create({});

// export default Chequing
export default CustomCarousel;
