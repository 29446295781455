/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';

import Text from '../CustomText';
import { AuthButton } from './AuthButton';

import Colors from '../../constants/Colors';

const { height } = Dimensions.get('window');

export const AuthFooter = ({ infoText, onPress, buttonText }) => {
    return (
        <View style={styles.container}>
            <Text style={{ color: Colors.black }}>{infoText}</Text>
            <AuthButton title={buttonText} onPress={onPress} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        // top: '20%',
        bottom: 0,
        marginBottom: height * 0.03,
        width: 300,
        alignSelf: 'center',
        alignItems: 'center',
    },
});
