/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet, Platform } from 'react-native';

import LinearGradient from 'react-native-linear-gradient';

import TouchableNativeFeedback from '../../components/TouchableFeedback';

import Text from '../../components/CustomText';

import Colors from '../../constants/Colors';
import { defaultStyles } from '../../constants/style';

export const AuthButton = ({ title, onPress }) => {
    return (
        <TouchableNativeFeedback style={styles.container} onPress={onPress}>
            <LinearGradient
                // start={[0, 1]}
                // end={[1, 0]}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                colors={[Colors.green2, Colors.green1]}
                style={[styles.gradient]}
            >
                <Text style={[styles.text, defaultStyles.bold]}>
                    {title.toUpperCase()}
                </Text>
            </LinearGradient>
        </TouchableNativeFeedback>
    );
};

const styles = StyleSheet.create({
    container: { marginVertical: 12, width: '90%' },
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    gradient: {
        borderRadius: 20,
        justifyContent: 'center',
        height: 40,
        width: Platform.OS === 'web' ? '100%' : '100%',
        backgroundColor: '#7ec13d',
    },
    text: { color: 'white', alignSelf: 'center', paddingHorizontal: 10 },
});
