/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
	Image,
	StyleSheet,
	ScrollView,
	View,
	Linking,
	Platform,
} from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Resizable } from 'react-native-web-hooks';

import { ConfirmationView, RejectionView } from '../../components/confirmation';
import { SectionRow, SectionTable } from '../../components/chequing/table';

import Intervals from '../../components/chequing/Intervals'

import { ParentHeaderCard } from '../../components/Header/HeaderBlock'
import FooterBlock from '../../components/Footer/FooterBlock'
import {renderList, renderPendingList} from '../../components/Purchases/renderLists'

import {setDate} from '../../helpers/datetime/setDate'

import * as purchasesActions from '../../store/actions/parents/purchasesActions'
import * as authActions from '../../store/actions/parents/authActions'
import { eventLog } from '../../helpers/analytics/analytics';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

class Purchases extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			type: 0,
			id: 0,
			data: [],
			interval: new Date(),
			intervalId: 1,
			intervals: [false, true, false, false],
			header: false,
		};
	};

	getNextPurchases(move) {
		const { parentId, } = this.props.auth;
		const { childIds, id } = this.props.parent;
		const { getPurchases, changeChildIndex } = this.props.actions;
		let index = id + move;
		let date = setDate(this.state.intervalId);
		changeChildIndex(move)
		if (index > childIds.length - 1) {
			this.setState({ id: 0 });
			getPurchases(date, parentId, childIds[0]); return 0
		}
		if (index < 0) {
			this.setState({ id: childIds.length - 1 });
			getPurchases(date, parentId, childIds[childIds.length - 1]); return 0
		}
		this.setState({ id: index });
		getPurchases(date, parentId, childIds[index]); return 0
	}


	async componentDidMount() {
		const { parentId } = this.props.auth;
		const { childIds, names, id } = this.props.parent;

		eventLog({eventName: 'purchases_screen', properties: {}})

		this.state.interval.setMonth(new Date().getMonth() - 1);

		if (!this.props.auth.parentId) {
			this.props.actions.clearAuth()
				.then(() => {
					this.props.navigation.navigate('Auth')
				})
		}
		// this.getData(this.state.interval);
		console.log('start find ', childIds, names)
		this.props.actions.getPurchases(this.state.interval, parentId, childIds[id])
	}

	render() {
		const { navigation, actions } = this.props;

		const { parentId } = this.props.auth;
		const { childIds, names, id } = this.props.parent;

		const childId = childIds[id]
		const purchases = this.props.purchases[childId] ? this.props.purchases[childId] : {}
		console.log('render', childId)
		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);

					return <View style={{ flex: 1, backgroundColor:'white' }}>
						{<Modal
							animationIn={'zoomIn'} animationOut={'zoomOut'}
							animationInTiming={1000} animationOutTiming={1000} backdropColor={'darkgrey'} backdropOpacity={0.6}
							onBackdropPress={() => this.setState({ confirmation: false, confirmPurchaseModal: false })}
							isVisible={this.state.confirmPurchaseModal}
							onRequestClose={() => { this.setState({ confirmation: false, confirmPurchaseModal: false }) }}>
							<View style={{
								borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
								maxWidth: 400, minHeight: 250,
							}}>
								{!this.state.approvedPurchase ? <ConfirmationView image={require('assets/Purchases/binoculars_3.png')} title={'Confirm Purchase'}
									confirmText={'Aprove'} rejectText={'Decline'}
									subtitles={[this.state.jobTitle, this.state.purchaseTitle, '$' + this.state.purchaseValue]}
									confirmPress={() => {
										this.props.actions.confirmPurchase(parentId, childId, this.state.purchaseId)
											.then(() => {
												eventLog({eventName: `purchase_approval`, properties: {
													amount: this.state.purchaseValue,
												}})
												this.props.actions.sendConfirmPurchaseEmail(parentId, childId, this.state.purchaseTitle)
												this.props.actions.getPurchases(setDate(this.state.intervalId), parentId, childId)
											});
										this.setState({ approvedPurchase: true })
									}}
									rejectPress={() => {
										this.props.actions.declinePurchase(parentId, childId, this.state.purchaseId)
											.then(() => {
												this.props.actions.sendRejectPurchaseEmail(parentId, childId, this.state.purchaseTitle)
												this.props.actions.getPurchases(setDate(this.state.intervalId), parentId, childId)
											});
										this.setState({ confirmPurchaseModal: false })
									}}
								/> : (this.state.purchaseLink && this.state.purchaseLink !== 'NA') ?
										<RejectionView title={'Purchase confirmed\nPlease, go to store page page to complete purchase'} subtitles={['']} okText={'Complete purchase (external page)'}
											okPress={() => {
												let extUrl = this.state.purchaseLink
												Platform.OS === 'web' ? window.open(extUrl, '_blank') :
													Linking.openURL(extUrl);
												this.setState({ confirmPurchaseModal: false, approvedPurchase: false })
											}} /> :
										<RejectionView title={'Purchase confirmed\nPlease don\'t forget to complete purchase'} subtitles={['']}
											okText={''}
											okPress={() => {
												// let extUrl = 'https://amazon.ca/dp/B07VGRJDFY'
												// Platform.OS==='web'?window.open(extUrl, '_blank'):
												// Linking.openURL(extUrl);
												this.setState({ confirmPurchaseModal: false, approvedPurchase: false })
											}} />}
							</View>
						</Modal>}

						<FooterBlock onPress={() => navigation.navigate('Store')}
							label={<Image resizeMode={'contain'} style={{ width: '50%', height: '50%', }} source={require('assets/Store/Shopping-Basket.png')} />} />

						<ScrollView style={{ flex: 1,}}>

							<View style={{ flex: 1, zIndex: 1, alignItems:'center' }}>

								<ParentHeaderCard name={names[childIds[id]]} pressNext={()=>this.getNextPurchases(+1)} navigation={navigation} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} />

								<View style={{ width: '100%', alignItems: 'center' }}>
									<Intervals focused={this.state.intervalId} setFocused={(index)=>this.setState({intervalId:index})} onPress={actions.getPurchases} type={this.state.type}
										parentId={parentId} childId={childIds[id]} navigation={navigation} />
								</View>

								<View style={{ width: '100%', marginBottom: 20 }}>
									<SectionTable title={'Approved'} value={purchases.total_approved} transactions={purchases.total_approved_trans}>
										<SectionRow data={purchases.approved_online_purchases} title={'Online Purchases'} value={purchases.approved_online}
											transactions={purchases.approved_online_trans} renderList={(data) => renderList(data, vmin)}
											bottomBorder={true} />
										<SectionRow data={purchases.approved_credit_purchases} title={'Credit Purchases'} value={purchases.approved_credit}
											transactions={purchases.approved_credit_trans} renderList={(data) => renderList(data, vmin)} />
									</SectionTable>

									<SectionTable title={'Pending'} value={purchases.total_pending} transactions={purchases.total_pending_trans}>
										<SectionRow data={purchases.pending_online_purchases} title={'Online Purchases'} value={purchases.pending_online}
											transactions={purchases.pending_online_trans} renderList={(data) => renderPendingList.call(this,data, vmin)}
											bottomBorder={true} />
										{/* <View style={{height:0.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
										<SectionRow data={purchases.pending_credit_purchases} title={'Credit Purchases'} value={purchases.pending_credit}
											transactions={purchases.pending_credit_trans} renderList={(data) => renderPendingList.call(this, data, vmin)} />
									</SectionTable>
								</View>

							</View>
						</ScrollView>
						{/* </ImageBackground> */}
					</View>
				}}
			</Resizable>)
	}
}

const styles = StyleSheet.create({

});

export default connect(state => {
	return ({
		purchases: state.purchases,
		auth: state.auth,
		parent: state.parent,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions, ...purchasesActions }, dispatch)
	})
)(Purchases);

