import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import firebase from 'firebase/app';

import 'firebase/database';

import config from '../../../constants/config'

const database = firebase.database;

function requestPurchases() {
    return {
        type: actionTypes.REQUEST_PURCHASES,
        data: [],
        isLoading: true
    }
}

function receivePurchases(data_approved, data_pending, ) {
    return {
        type: actionTypes.RECEIVE_PURCHASES,
        payload: {
            data_approved, data_pending,
            isLoading: false,
        }
    }
}


export function getPurchases(parentId, childId, interval) {
    return async dispatch => {
        dispatch(requestPurchases())

        let data_source_approved = database().ref('/users/' + parentId + '/' + childId + '/purchases/approved');
        let snapshot = await data_source_approved.orderByChild('approval_date').startAt(interval.toISOString())
            .once('value')
        let data_approved = snapshot.val();
        // console.log('interval', interval)

        if (data_approved != null) {
            if (!Array.isArray(data_approved)) {
                data_approved = Object.keys(data_approved).map(item => { return { ...data_approved[item], key: item } });
            }
            data_approved = data_approved.filter(Boolean);
        }
        else {
            data_approved = []
        }

        var data_source_pending = database().ref('/users/' + parentId + '/' + childId + '/purchases/requested');
        snapshot = await data_source_pending.orderByChild('request_date').startAt(interval.toISOString()).once('value')
        let data_pending = snapshot.val();
        //   data_received ++;
        if (data_pending != null) {
            if (!Array.isArray(data_pending)) {
                data_pending = Object.keys(data_pending).map(item => { return { ...data_pending[item], key: item } });
            }
            data_pending = data_pending.filter(Boolean);
        }
        else {
            data_pending = []
        }
        // console.log('what data we have ', data_approved, data_pending)
        dispatch(receivePurchases(data_approved, data_pending))
    }
}
export function getPurchasesApproved(interval, parentId, childId) {
    return async dispatch => {
        dispatch(requestPurchases())
        let data_source_approved = database().ref('/users/' + parentId + '/' + childId + '/purchases/approved');


        return data_source_approved.orderByChild('approval_date').startAt(interval.toISOString()).once('value')
            .then(snapshot => { return snapshot.val() })
            .then(snap => {
                if (snap != null) {
                    let data_approved = []
                    if (!Array.isArray(snap)) {
                        data_approved = Object.keys(snap).map(item => { return { ...snap[item], key: item } });
                    }
                    data_approved = data_approved.filter(Boolean);
                }
                // let json = snap?Object.keys(snap).map(item => { return ({ key: item, ...snap[item] }) }):[]; 
                // json.sort(function (x, y) {return (x === y) ? 0 : x.activated === 1 ? -1 : 1;});
                return dispatch(receivePurchases(data_approved))
            })
    }
}

export function getPurchasesPending(interval, parentId, childId) {
    return async dispatch => {
        // dispatch(requestPurchases())
        let data_source_pending = database().ref('/users/' + parentId + '/' + childId + '/purchases/requested');



        return data_source_apending.orderByChild('request_date').startAt(interval.toISOString()).once('value')
            .then(snapshot => { return snapshot.val() })
            .then(snap => {
                if (snap != null) {
                    let data_approved = []
                    if (!Array.isArray(snap)) {
                        data_approved = Object.keys(snap).map(item => { return { ...snap[item], key: item } });
                    }
                    data_approved = data_approved.filter(Boolean);
                }
                // let json = snap?Object.keys(snap).map(item => { return ({ key: item, ...snap[item] }) }):[]; 
                // json.sort(function (x, y) {return (x === y) ? 0 : x.activated === 1 ? -1 : 1;});
                return dispatch(receivePurchases(data_approved))
            })
    }
}


export function cancelPurchase(parentId, childId, itemId) {
    return async dispatch => {
        // dispatch(requestPurchases())
        let requested_source = database().ref('/users/' + parentId + '/' + childId + '/purchases/requested/' + itemId);
        let chequing_transaction_source = database().ref('/users/' + parentId + '/' + childId + '/chequing/transactions');
        let savings_transaction_source = database().ref('/users/' + parentId + '/' + childId + '/savings/transactions');

        let old_balance_source = database().ref('/users/' + parentId + '/' + childId);

        let requested = (await requested_source.once('value')).val()
        if (requested.type.includes('savings')) {
            let old_balance = (await old_balance_source.child('savings/balance').once('value')).val()
            // let old_balance = (await old_balance_source.once('value')).val() 
            console.log('requested, old_balance', requested, old_balance)
            database().ref('/users/' + parentId + '/' + childId + '/savings').update({ balance: old_balance + requested.price })

            savings_transaction_source.push({ amount: requested.price, transaction_date: new Date().toISOString(), type: 'refund' })
        }
        else {
            let old_balance = (await old_balance_source.child('chequing/balance').once('value')).val() 
        // let old_balance = (await old_balance_source.once('value')).val() 
            console.log('requested, old_balance', requested, old_balance)
            database().ref('/users/' + parentId + '/' + childId + '/chequing').update({balance:old_balance + requested.price})
            
            chequing_transaction_source.push({amount:requested.price,transaction_date:new Date().toISOString(), type:'refund'})
        

        }
        // let old_balance = (await old_balance_source.once('value')).val()
        // // let old_balance = (await old_balance_source.once('value')).val() 
        // console.log('requested, old_balance', requested, old_balance)
        // database().ref('/users/' + parentId + '/' + childId + '/chequing').update({ balance: old_balance + requested.price })

        // chequing_transaction_source.push({ amount: requested.price, transaction_date: new Date().toISOString(), type: 'refund' })


        console.log('itemId, ', itemId)

        return requested_source.remove()
    }
}