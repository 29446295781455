import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { FONTS } from '../../constants/fonts';

export const inputStyles = StyleSheet.create({
    container: { width: '100%' },
    title: {
        fontFamily: FONTS.FONT_BOLD,
    },
    input: {
        fontFamily: FONTS.FONT_DEFAULT,
        backgroundColor: '#F1F1F1',
        height: 42,
        justifyContent: 'center',
        borderRadius: 20,
        paddingLeft: 15,
        marginTop: 15, 
    },
    text: {
        color: '#282828',
    },
    value: {},
    placeholder: {
        opacity: 0.5,
    },
});
