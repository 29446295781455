/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
// import '@expo/match-media'
import React, { useRef, useEffect } from 'react';
import {
    Animated,
    Dimensions,
    Image,
    StyleSheet,
    View,
} from 'react-native';


import Text from '../../components/CustomText';
import TouchableNativeFeedback from '../../components/TouchableFeedback';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';

const { width } = Dimensions.get('window');
const leftPosition = -15;

const ChildActivateButton = ({}) => {
    return <View />;
};

const ChildPurchaseButton = ({}) => {
    return <View />;
};

const ChildBalanceInfo = ({}) => {
    return <View />;
};

const ChildGoalInfo = () => {
    return (
        <View>
            <ChildActivateButton />
            <ChildPurchaseButton />
            <ChildBalanceInfo />
        </View>
    );
};

const ActivateButton = ({ onPress }) => {
    const isHovered = false;

    return (
        <TouchableNativeFeedback accessible onPress={onPress}>
            <View
                elevation={-2}
                style={{
                    boxShadow:
                        'inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08)',
                    width: '80%',
                    alignSelf: 'center',
                    backgroundColor: isHovered
                        ? 'rgba(126, 193, 61, 1)'
                        : 'rgba(126, 193, 61, 0.5)',
                    marginBottom: 20,
                    padding: 6,
                    borderRadius: 30,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <View style={{ justifyContent: 'center' }}>
                    <Text style={{ padding: 2, opacity: 1, color: 'white' }}>
                        Activate
                    </Text>
                </View>
            </View>
        </TouchableNativeFeedback>
    );
};

const PurchaseButton = ({ onPress }) => {
    const isHovered = false;

    return (
        <TouchableNativeFeedback onPress={onPress}>
            <View
                elevation={-2}
                style={{
                    width: '80%',
                    alignSelf: 'center',
                    backgroundColor: 'rgba(126, 193, 61, 1)',
                    marginBottom: 20,
                    padding: 6,
                    borderRadius: 30,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <View style={{ justifyContent: 'center' }}>
                    <Text style={{ padding: 2, opacity: 1, color: 'white' }}>
                        Purchase
                    </Text>
                </View>
            </View>
        </TouchableNativeFeedback>
    );
};

const Close = ({ onPress }) => {
    return (
        <>
            {/* <ModalContainer visible setVisible onClose children>
            <ConfirmationView />
        </ModalContainer> */}
            <TouchableNativeFeedback accessible onPress={onPress}>
                <View style={{ position: 'absolute', right: 10, top: 10 }}>
                    <Text>X</Text>
                </View>
            </TouchableNativeFeedback>
        </>
    );
};

// class GoalCard extends React.Component {
const GoalCard = ({
    item,
    index,
    type,
    data,
    balance,
    isLarge,
    layout,
    deleteGoal,
    openModal,
}) => {
    const progress_val = useRef(new Animated.Value(0)).current;
    const scaleValue = useRef(new Animated.Value(0)).current;
    // const [progress_val, setP] = useState(new Animated.Value(0.3));
    // const [scaleValue, setS] = useState(new Animated.Value(0))
    let imageSize = layout.width * 0.22;
    imageSize = 100;
    const maxImageSize = 100;

    useEffect(() => {
        Animated.timing(scaleValue, {
            toValue: 1,
            duration: 400,
            useNativeDriver: true,
            delay: index * 50,
        }).start();

        Animated.timing(progress_val, {
            toValue: 1,
            duration: 700,
            useNativeDriver: false,
        }).start();

        return () => {};
    }, []);

    const progress = progress_val.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', (balance / item.amount) * 100 + '%']
    });

    // let progress = '50%'

    return (
        <Animated.View
            elevation={5}
            style={[
                styles.container,
                {
                    opacity: scaleValue,
                },
                defaultStyles.shadow,
            ]}
        >
            {type === 'child' && (
                <Close onPress={() => openModal('delete', item.key)} />
            )}
            <View style={styles.titleContainer}>
                <View style={{ top: -25, alignItems: 'flex-start' }}>
                    <Image
                        resizeMode={'contain'}
                        style={{
                            width: imageSize,
                            height: imageSize,
                            maxWidth: maxImageSize,
                            maxHeight: maxImageSize,
                        }}
                        source={require('assets/Goals/goals.png')}
                    />
                    {balance / item.amount > 1 ? (
                        <Image
                            style={{
                                position: 'absolute',
                                width: 40,
                                height: 40,
                                bottom: 0,
                                left: '50%',
                            }}
                            source={require('assets/Goals/Goal_complete.png')}
                        />
                    ) : null}
                </View>
                <View
                    style={{
                        left: -10,
                        width: '80%',
                        marginTop: 10,
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        alignSelf: 'flex-start',
                    }}
                >
                    <Text
                        style={[
                            { color: Colors.yellow1, marginTop: 10 },
                            defaultStyles.bold,
                            globalStyle.h2,
                        ]}
                    >
                        ${item.amount}
                    </Text>
                    <Text numberOfLines={3} style={{ marginBottom: 5 }}>
                        {item.name}
                    </Text>
                </View>
            </View>

            {
                // !!item.activated &&
                <View
                    style={{
                        marginBottom: 20,
                        width: '80%',
                        alignSelf: 'center',
                        padding: 6,
                    }}
                >
                    {balance < item.amount ? (
                        <Text>
                            ${balance ? parseFloat(balance).toFixed(2) : 0}
                        </Text>
                    ) : (
                        <Text>Achieved</Text>
                    )}
                    <View
                        style={{
                            width: '100%',
                            backgroundColor: 'lightgray',
                            overflow: 'hidden',
                            height: 8,
                            borderRadius: 5,
                        }}
                    >
                        <Animated.View
                            style={{
                                width: progress,
                                backgroundColor: Colors.yellow1,
                                borderRadius: 5,
                                minWidth: '0%',
                                height: 8,
                            }}
                        />
                    </View>
                </View>
            }

            {type === 'child' && !item.activated && (
                <ActivateButton
                    onPress={() => openModal('activate', item.key)}
                />
            )}
            {type === 'child' &&
                !!item.activated &&
                balance / item.amount >= 1 && (
                    <PurchaseButton
                        onPress={() => openModal('purchase', item.key)}
                    />
                )}
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        maxWidth: 550,
        width: width > 850 ? '60%' : '90%',
        marginBottom: 25,
        borderRadius: 20,
        backgroundColor: 'white',
        alignSelf: 'center',
    },
    titleContainer: {
        left: leftPosition,
        flex: 1,
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
});

export default GoalCard;
