// import { appConstants } from "";
import * as actionTypes from '../../actions/kids/actionTypes'
import {filterPurchases} from '../../../helpers/purchases/filterPurchases'


const initialState = {
        isLoading: true,
        data:[],type:0,
        total_approved:0,
        total_pending:0,
        total_approved_trans:0,
        total_pending_trans:0,
        approved_online_purchases:[],
        approved_online:0,
        approved_online_trans:0,
        approved_online_display:'none',
        approved_credit_purchases:[],
        approved_credit:0,
        approved_credit_trans:0,
        approved_credit_display:'none',
        pending_online_purchases:[],
        pending_online:0,
        pending_online_trans:0,
        pending_online_display:'none',
        pending_credit_purchases:[],
        pending_credit:0,
        pending_credit_trans:0,
        pending_credit_display:'none',
};


export default  function purchases(state = initialState, action) {
    let values = {}

    switch (action.type) {
        case actionTypes.REQUEST_PURCHASES:

                return {
                    ...state,
                    isLoading:true,
                };


        case actionTypes.RECEIVE_PURCHASES:
            values = filterPurchases(action.payload.data)
            // valuesApproved = filterApprovedPurchases(action.payload.data)
            // valuesPending = filterPendingPurchases(action.payload.data)
            return {
                ...state,
                isLoading: false,
                ...values
            };

        default:
            return state
    }
}