/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    WebView,
    Button,
    Image,
    KeyboardAvoidingView,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    // Text,
    TextInput,
    // TouchableNativeFeedback,
    ToucableOpacity,
    StatusBar,
} from 'react-native';
import { Resizable, Hoverable } from 'react-native-web-hooks';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TouchableNativeFeedback from '../components/TouchableFeedback';
import FloatingLabelInput from '../components/FloatingLabelInput/FloatingLabelInput';
import { RejectionView } from '../components/confirmation';
import Text from '../components/CustomText';

import Colors from '../constants/Colors';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

// import * as authActions from '../store/actions/parents/authActions';
// import * as kidAuthActions from '../store/actions/kids/authActions';

import * as authActions from '../store/actions/authActions';

import { defaultStyles } from '../constants/style';
import PopupModal from '../components/Popup/PopupModal';

const database = firebase.database;
const auth = firebase.auth;

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

class SetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            repeatPassword: '',
        };
    }

    handleResetPassword(actionCode, password) {
        return auth()
            .verifyPasswordResetCode(actionCode)
            .then(function (email) {
                var accountEmail = email;

                console.log('SetPassword email - ', email);

                return auth()
                    .confirmPasswordReset(actionCode, password)
                    .then(function (resp) {
                        console.log('confirmPasswprdReset resp - ', resp)

                        return auth()
                            .signInWithEmailAndPassword(accountEmail, password)
                            .then((user) => {
                                console.log('signInWithemailandpassword resp - ', user);

                                return user;
                            });
                    })
                    .catch(function (error) {
                        console.log('ERROR!!', error);
                        // Error occurred during confirmation. The code might have expired or the
                        // password is too weak.
                    });
            });
    }

    componentDidMount() {
        const mode = this.props.navigation.getParam('mode', null);
        const oobCode = this.props.navigation.getParam('oobCode', '');

        auth()
            .verifyPasswordResetCode(oobCode)
            .then(function (email) {
                var accountEmail = email;

                console.log('gREAT!');
            })
            .catch((error) => {
                console.log('Problem1!');
                this.setState({
                    authModalVisible: true,
                    errorBlock: true,
                    errorCase: 'auth/invalid-action-code',
                    errorText:
                        'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
                });
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
            });
    }

    login() {
        const password = this.state.password;
        const repeatPassword = this.state.repeatPassword;
        const actionCode = this.props.navigation.getParam('oobCode', '');

        console.log('email, password');
        console.log(password, repeatPassword);
        if (password === repeatPassword && password.length >= 6) {
            return this.handleResetPassword(actionCode, password)
                .then(async (user) => {
                    const user_type = (
                        await database()
                            .ref()
                            .child('user_type/' + user.user.uid + '/type')
                            .once('value')
                    ).val();

                    console.log('user, type', user, user_type);

                    return { user, type: user_type };
                })
                .then((res) => {
                    if (res.type === 0 || res.type === 1) {
                        this.props.actions.login(res.user, res.type).then(() => {
                            this.props.navigation.navigate(res.type ? 'Kid' : 'Parent');
                        });
                    }
                    // else {
                    //     this.props.actions.kidLogin(res.user).then(() => {
                    //         this.props.navigation.navigate('Kid');
                    //     });
                    // }
                })
                .catch((error) => {
                    // console.log(error)
                    // alert(error.message)
                    const errorMessage = error.message;

                    console.log('last');
                    console.log(error);
                    this.setState({ authModalVisible: true });
                    switch (error.code) {
                        case 'auth/invalid-action-code':
                            this.setState({
                                errorCase: 'auth/invalid-action-code',
                                errorBlock: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        case 'auth/user-disabled':
                            this.setState({
                                errorBlock: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        case 'auth/user-not-found':
                            this.setState({
                                errorBlock: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        case 'auth/wrong-password':
                            this.setState({
                                errorBlock: true,
                                errorPassword: true,
                                errorText: errorMessage,
                            });

                            return 0;
                        default:
                            this.setState({
                                errorBlock: true,
                                errorText: errorMessage
                                    ? errorMessage
                                    : 'Something went wrong =(',
                            });

                            return 0;
                    }
                });
        } else {
            this.setState({
                authModalVisible: true,
                errorBlock: true,
                errorPassword: true,
                errorCase: 'auth/invalid-action-code',
                errorText: 'Passwords should match and have at least 6 symbols',
            });

            return 0;
        }
    }

    render() {
        const { layout } = this.props;
        const titleFontSize =
            layout.width > 850
                ? layout.width > 1500
                    ? 44
                    : layout.width / 35
                : 22;

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <PopupModal visible={true} title={'Password Protection'} text={'Security measures such as a strong password protects your personal information that you have inputted into the app. This prevent unauthorized access and keep you protected against cyberattacks'} />

                <Modal
                    animationIn={'zoomIn'}
                    animationOut={'zoomOut'}
                    animationInTiming={300}
                    animationOutTiming={300}
                    backdropColor={'darkgrey'}
                    backdropOpacity={0.1}
                    onBackdropPress={() =>
                        this.setState({ authModalVisible: false })
                    }
                    isVisible={this.state.authModalVisible}
                    onRequestClose={() => {
                        this.setState({ authModalVisible: false });
                    }}
                >
                    <View
                        elevation={5}
                        style={{
                            borderRadius: 15,
                            backgroundColor: 'white',
                            width: '80%',
                            alignSelf: 'center',
                            maxWidth: 400,
                            minHeight: 100,
                            padding: 15,
                            justifyContent: 'center',
                            boxShadow: '2px 2px 15px #272827',
                        }}
                    >
                        {this.state.errorCase !== 'auth/invalid-action-code' ? (
                            <RejectionView
                                title={this.state.errorText}
                                subtitles={[]}
                                okPress={() => {
                                    this.setState({ authModalVisible: false });
                                }}
                            />
                        ) : (
                            <RejectionView
                                title={this.state.errorText}
                                subtitles={['Please, request email again.']}
                                okText={'Recover Password'}
                                okPress={() => {
                                    this.setState({ authModalVisible: false });
                                    this.props.navigation.navigate(
                                        'RecoverPassword'
                                    );
                                }}
                            />
                        )}
                    </View>
                </Modal>
                <Text
                    style={[
                        { margin: 10, fontSize: titleFontSize },
                        defaultStyles.bold,
                    ]}
                >
                    Almost Ready To Go!
                </Text>

                <View style={{ width: '100%', alignItems: 'center' }}>
                    <FloatingLabelInput
                        label={'Password'}
                        value={this.state.password}
                        secureTextEntry={true}
                        onChangeText={(text) =>
                            this.setState({ password: text })
                        }
                    />
                    <FloatingLabelInput
                        onSubmitEditing={() => {
                            this.login(
                                this.state.password,
                                this.state.repeatPassword
                            );
                        }}
                        secureTextEntry={true}
                        label={'Repeat Password'}
                        value={this.state.repeatPassword}
                        onChangeText={(text) =>
                            this.setState({ repeatPassword: text })
                        }
                    />
                </View>
                <View style={{ width: '80%' }} />
                {/* <View style={{ marginVertical: 10, opacity: this.state.errorBlock ? 1 : 0 }}>
				<Text>{this.state.errorText}</Text>
			</View> */}
                <TouchableNativeFeedback
                    style={{
                        alignSelf: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                    onPress={() => {
                        this.login();
                    }}
                >
                    {/* onPress={()=>{this.login()}}> */}
                    <View
                        elevation={5}
                        style={{
                            height: 40,
                            justifyContent: 'center',
                            width: '80%',
                            margin: 10,
                            backgroundColor: '#7ec13d',
							// TODO create constant for borders
                            borderRadius: 20,
                        }}
                    >
                        <Text
                            style={[
                                {
                                    fontSize: 18,
                                    color: 'white',
                                    alignSelf: 'center',
                                    margin: 4,
                                },
                                defaultStyles.bold,
                            ]}
                        >
                            Log In
                        </Text>
                    </View>
                </TouchableNativeFeedback>

                <View style={{ marginTop: 15, width: '80%' }}>
                    <TouchableNativeFeedback
                        onPress={() => {
                            this.props.navigation.navigate('Login');
                        }}
                    >
                        <Text
                            style={{
                                alignSelf: 'center',
                                color: '#7ec13d',
                                textDecorationLine: 'underline',
                            }}
                        >
                            Login/Sign Up
                        </Text>
                    </TouchableNativeFeedback>
                </View>
            </View>
        );
    }
}

class LoginScreen extends React.Component {
    static navigationOptions({ navigation }) {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
        };
    }

    componentDidMount() {
        const { parentId } = this.props.auth;
        const { clearAuth, kidClearAuth } = this.props.actions;

        const mode = this.props.navigation.getParam('mode', null);
        const oobCode = this.props.navigation.getParam('oobCode', '');

        clearAuth();
        // kidClearAuth();

        auth().signOut();

        console.log('action, oobCode', mode, oobCode);
        console.log('this.props.auth.parentId', this.props.auth.parentId);
        console.log('this.props.fullState', this.props.fullState);
    }

    render() {
        // return(this.props.navigation.getParam('login', true)?<Login/>:<SignUp/>)
        return (
            <Resizable>
                {(layout) => (
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal
                            animationIn={'zoomIn'}
                            animationOut={'zoomOut'}
                            animationInTiming={300}
                            animationOutTiming={300}
                            backdropColor={'darkgrey'}
                            backdropOpacity={0.1}
                            onBackdropPress={() =>
                                this.setState({ authModalVisible: false })
                            }
                            isVisible={this.state.authModalVisible}
                            onRequestClose={() => {
                                this.setState({ authModalVisible: false });
                            }}
                        >
                            <View
                                elevation={5}
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: 'white',
                                    width: '80%',
                                    alignSelf: 'center',
                                    maxWidth: 400,
                                    minHeight: 100,
                                    padding: 15,
                                    justifyContent: 'center',
                                    boxShadow: '2px 2px 15px #272827',
                                }}
                            >
                                <RejectionView
                                    title={
                                        'Action code is invalid or expired. Please try to resend email'
                                    }
                                    subtitles={[]}
                                    okPress={() => {
                                        this.setState({
                                            authModalVisible: false,
                                            success: false,
                                        });

                                        this.props.navigation.navigate(
                                            'RecoverPassword'
                                        );
                                    }}
                                    okText={'Go to Recovery Page'}
                                />
                            </View>
                        </Modal>
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    minWidth: 280,
                                    maxWidth: 550,
                                    flex: 1,
                                    justifyContent: 'center',
                                }}
                            >
                                <SetPassword {...this.props} layout={layout} />
                            </View>
                        </View>
                    </View>
                )}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(
    (state) => {
        return {
            fullState: state,
            auth: state.auth,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions }, dispatch),
    })
)(LoginScreen);
