/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Animated, StyleSheet, View, TouchableOpacity, Platform, Image, ScrollView } from 'react-native';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

import { AcademyHeader } from '../../../components/Academy/AcademyHeader';

import Text from '../../../components/CustomText'
import Colors from '../../../constants/Colors';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../constants/constants';
import { defaultStyles } from '../../../constants/style';
import { ROUTE_NAMES } from '../../../navigation/navigationConstants';
import { customModalRef } from '../../../components/Modal/CustomModal';

const QUESTION_TYPES = {
    CHOICE: 'choice',
    MULTIPLE: 'multiple',
};

const HARDCODED_CHOICE_QUESTION = {
    title: 'First question',
    description: 'An email from your school or company asks for your name, addresses, and credit card information. The email says it is urgent and to please reply right away. You should reply right away.',
    type: QUESTION_TYPES.CHOICE,
    variants: [
        {
            title: 'True',
        },
        {
            title: 'False',
        },

    ],
    answer: 1,
};

const HARDCODED_MULTIPLE_CHOICE_QUESTION = {
    title: 'Second Question',
    description: 'Which statements apply to phishing? (select 2)',
    type: QUESTION_TYPES.MULTIPLE,
    variants: [
        {
            title: 'The art of catching aquatic animals with a rod',
        },
        {
            title: 'Disguising as a trustworthy source, to get users to reveal information',
        },
        {
            title: 'Usually an attack made via email',
        },
    ],
    answer: [1, 2],
};

const HARDCODED_CHOICE_QUESTION_2 = {
    title: 'Third Question',
    description: ' Which of these email Subject lines is an example of a phishing email?',
    type: QUESTION_TYPES.CHOICE,
    variants: [
        {
            title: 'Your bank account has been hacked. Login in here to protect yourself.',
        },
        {
            title: 'Credit Card Information Needed, Subscription ending',
        },
        {
            title: 'Help! I am stuck on an island with no way home! Need funds sent to offshore account',
        },
        {
            title: 'All of the above!',
        },
    ],
    answer: 3,
};

const HARDCODED_QUIZ = {
    questions: [
        HARDCODED_CHOICE_QUESTION,
        // HARDCODED_CHOICE_QUESTION,
        HARDCODED_MULTIPLE_CHOICE_QUESTION,
        // HARDCODED_CHOICE_QUESTION,
        // HARDCODED_MULTIPLE_CHOICE_QUESTION,
        HARDCODED_CHOICE_QUESTION_2,
    ],
};

const HARDCODED_IMAGES_QUIZ_1 = {
    images: [
        {source: require('assets/Academy/emails/email_1.png'), isPhishing: true, correctText: 'Correct! This is an example of a phishing email', errorText: 'Sorry, that was wrong'},
        // {source: require('assets/Academy/emails/email_2.png'), isPhishing: true, correctText: 'Correct! This is an Example of a phishing email', errorText: 'Sorry, that was wrong'},
        {source: require('assets/Academy/emails/email_3.png'), isPhishing: true, correctText: 'Correct! This is a phishing email', errorText: 'Sorry, that was wrong'},
        // {source: require('assets/Academy/emails/email_4.png'), isPhishing: true, correctText: 'Correct! This is a phishing email!', errorText: 'Sorry, that was wrong'},
        {source: require('assets/Academy/emails/email_5.png'), isPhishing: true, correctText: 'Correct! This is a phishing email', errorText: 'Sorry, that was wrong'},
        // {source: require('assets/Academy/emails/email_6.png'), isPhishing: false, correctText: 'This is a legitimate email!', errorText: 'Sorry, that was wrong'},
        {source: require('assets/Academy/emails/email_7.png'), isPhishing: false, correctText: 'This is a legitimate email!', errorText: 'Sorry, that was wrong'},
        // {source: require('assets/Academy/emails/email_8.png'), isPhishing: false, correctText: 'This is a legitimate email!',errorText: 'Sorry, that was wrong'},
    ],
};

const HARDCODED_IMAGES_QUIZ_2 = {
    images: [
        // {source: require('assets/Academy/emails/email_1.png'), isPhishing: true, correctText: 'Correct! This is an example of a phishing email', errorText: 'Sorry, that was wrong'},
        {source: require('assets/Academy/emails/email_2.png'), isPhishing: true, correctText: 'Correct! This is an Example of a phishing email', errorText: 'Sorry, that was wrong'},
        // {source: require('assets/Academy/emails/email_3.png'), isPhishing: true, correctText: 'Correct! This is a phishing email', errorText: 'Sorry, that was wrong'},
        {source: require('assets/Academy/emails/email_4.png'), isPhishing: true, correctText: 'Correct! This is a phishing email!', errorText: 'Sorry, that was wrong'},
        // {source: require('assets/Academy/emails/email_5.png'), isPhishing: true, correctText: 'Correct! This is a phishing email', errorText: 'Sorry, that was wrong'},
        {source: require('assets/Academy/emails/email_6.png'), isPhishing: false, correctText: 'This is a legitimate email!', errorText: 'Sorry, that was wrong'},
        // {source: require('assets/Academy/emails/email_7.png'), isPhishing: false, correctText: 'This is a legitimate email!', errorText: 'Sorry, that was wrong'},
        {source: require('assets/Academy/emails/email_8.png'), isPhishing: false, correctText: 'This is a legitimate email!',errorText: 'Sorry, that was wrong'},
    ],
};

const NavButton = ({ title, onPress, disabled = false }) => {
    return (<TouchableOpacity
            disabled={disabled}
        style={{
            opacity: disabled ? 0.5 : 1,
            alignItems: 'center', justifyContent: 'center',
            margin: 10, padding: 10,
            backgroundColor: Colors.green1, borderRadius: 5,
            width: Math.min(SCREEN_WIDTH / 3, 100),
        }} onPress={onPress}>
    <Text style={{color: Colors.white}}>{title}</Text>
</TouchableOpacity>)
}

export const Quiz = (props) => {
    // const {  } = useSelector(store => store);

    console.log({props})
    const questions = HARDCODED_QUIZ.questions;
    const id = props.navigation.state.params.id;

    const dispatch = useDispatch();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState(() => questions.map(item => item.type === QUESTION_TYPES.MULTIPLE ? [] : undefined));
    const [selectedAnswers, setSelectedAnswers] = useState(undefined);
    const [checkAnswers, setCheckAnswers] = useState(false);
    const [imagePressed, setImagePressed] = useState(false);
    const [currPhishingEmail, setCurrPhishingEmail] = useState(undefined);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [showResult, setShowResult] = useState(false);

    const HARDCODED_IMAGES_QUIZ = id === 2 ? HARDCODED_IMAGES_QUIZ_1 : HARDCODED_IMAGES_QUIZ_2;
    const currImage = HARDCODED_IMAGES_QUIZ.images[currentImageIndex];

    const questionAnim = useRef(new Animated.Value(0)).current;
    const questionPostion = questionAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [0, -SCREEN_WIDTH * questions.length],
    })


    useEffect(() => {
        // dispatch();
        Animated.timing(questionAnim, {
            toValue:  (currentQuestion) / questions.length,
            duration: 500,
            useNativeDriver: true,
        }).start();
    }, [currentQuestion]);

    const onNavButtonPress = useCallback(({type}) => {
        setCurrentQuestion(curr => type === 'back' ? curr - 1 : curr + 1);
    }, []);

    const onBackButtonPress = useCallback(() => {

        setCurrentQuestion(curr => {
            return curr - 1;
        });
    }, []);

    const onNextButtonPress = () => {
        setCurrentQuestion(curr => {
            return curr + 1
        });
        setCorrectAnswers(i=> i+1)
        setCheckAnswers(false);

        return;
        if (checkAnswers) {
            setSelectedAnswers(undefined);

            setCurrentQuestion(curr => {
                return curr + 1
            });
            setCheckAnswers(false);
        } else {
            setCheckAnswers(true);
        }

    };

    const onFinishButtonPress = () => {
        setCorrectAnswers(i=> i+1)
        setShowResult(true);

        // props.navigation.navigate(ROUTE_NAMES.ACADEMY);
        return;
        if (checkAnswers) {
            props.navigation.navigate(ROUTE_NAMES.ACADEMY);

            setCheckAnswers(false);
        } else {
            setCheckAnswers(true);
        }
    };

    const onVariantPress = ({type, questionIndex, index}) => {
        if (type === QUESTION_TYPES.MULTIPLE) {
            if (selectedAnswers) {
                setSelectedAnswers([...selectedAnswers, index]);
            } else {
            setSelectedAnswers([index]);

            }

        } else {
            setSelectedAnswers(index);
        }

        if (type === QUESTION_TYPES.MULTIPLE) {
            setAnswers(curr => {
                let ans = curr[currentQuestion];
                if (typeof ans === 'object') {
                    if (ans.includes(index)) {
                        // ans.
                    } else {
                        ans.push(index)
                    }
                } else {
                ans = [index];
                }
                console.log({ans})
                return ans;
            })
            return;
        }
        // console.log('answers - ', answers);
        let tmp = [...answers];
        tmp[questionIndex] = index;
        console.log('answers, tmp - ', answers, tmp);
        setAnswers(tmp);
    };

    const renderBackButton = useCallback(() => {
        return <NavButton title={'Back'} onPress={onBackButtonPress} />
    }, []);

    const renderNextButton = () => {
        return <NavButton title={'Next'} disabled={selectedAnswers === undefined} onPress={onNextButtonPress} />
    }

    const renderFinishButton = () => {
        return <NavButton title={'Finish'} disabled={selectedAnswers === undefined} onPress={onFinishButtonPress} />
    }

    const renderVariantCheckBox = ({ questionIndex, index, type }) => {
        const isSelected = type === QUESTION_TYPES.MULTIPLE ? selectedAnswers?.includes?.(index) : selectedAnswers === index
        const checkboxStyle = type === QUESTION_TYPES.MULTIPLE ? {} : {borderRadius: 10}
        const selectedStyle = type === QUESTION_TYPES.MULTIPLE ?
        (answers.includes(questionIndex) ? {backgroundColor: 'red'} : {}) :
        (answers === questionIndex ? {backgroundColor: 'red'} : {})
        ;

        console.log({selectedAnswers})


        return <View style={{ width: 15, borderWidth: 2, borderColor: Colors.green1, height: 15, alignItems: 'center', justifyContent: 'center', ...checkboxStyle}}>
            {isSelected && <View style={{ width: '80%', backgroundColor: Colors.green1, height: '80%', ...checkboxStyle }} />}
        </View>
    };

    const renderQuestionVariant = ({ variant, questionIndex, index, type }) => {
        const correctAnswers = questions[currentQuestion].answer;
        const isSelected = type === QUESTION_TYPES.MULTIPLE ? correctAnswers?.includes?.(index) : correctAnswers === index

        return (<TouchableOpacity onPress={() => onVariantPress({type, questionIndex, index})} key={variant.title} style={{margin: 6,
         alignItems: 'center', flexDirection: 'row'}}>
        {renderVariantCheckBox({index, questionIndex, type})}
        <Text style={{marginLeft: 10, fontWeight: (checkAnswers && isSelected) ? 'bold' : 'normal'}}>{variant.title}</Text>
    </TouchableOpacity>);
    };

    const renderQuestion = (question, index) => {
        return (
            <View key={question.title + index} style={{width: SCREEN_WIDTH, justifyContent: 'center', alignItems: 'center'}}>
                <View style={{width: '80%', justifyContent: 'center', alignItems: 'center', maxWidth: 500, borderRadius: 10, ...defaultStyles.boxShadow}}>
                    <Text style={{fontWeight: 'bold', marginBottom: 15, fontSize: 18, }}>{question.title}</Text>
                    <Text>{question.description}</Text>
                    <View style={{margin: 6, }}>
                        {question.variants.map((variant, variantIndex) => {
                            return renderQuestionVariant({variant,
                                questionIndex: index,
                                index: variantIndex, type: question.type})
                        })}
                    </View>
                    <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {index !== 0 ? renderBackButton() : null}
                        {index +1 !== questions.length ? renderNextButton() : renderFinishButton()}
                    </View>
                </View>
            </View>
        )
    };

    const renderImageSelect = ({source, index}) => {
        return <TouchableOpacity  onPress={() => {
            customModalRef.current.show(() => (
                <TouchableOpacity activeOpacity={0.9} onPress={() => { customModalRef.current.hide()}} style={{flex:1, alignItems: 'center', justifyContent: 'center'}}>
                <Image resizeMode={'contain'} style={{width: SCREEN_WIDTH*0.7, height: SCREEN_HEIGHT*0.7,}} source={HARDCODED_IMAGES_QUIZ.images[index].source} />
                </TouchableOpacity>
            ))
        }}>
            <Image style={{width: SCREEN_WIDTH*0.8, height: SCREEN_WIDTH*0.8, maxWidth: 350, maxHeight: 350,
            borderWidth:1, borderColor: 'black'}}
            source={source} />
        </TouchableOpacity>
    }

    console.log({selectedAnswers}, "B BB")

    // TODO move container to component
    return (
        <View style={styles.container}>
            <AcademyHeader />

            {(id === 1 && !showResult) ? <Animated.View style={{ position: 'absolute',top: '10%', flex: 1, justifyContent: 'center',flexDirection: 'row', transform: [{translateX: questionPostion}] }}>
                {HARDCODED_QUIZ.questions.map((question, index) => {
                    return renderQuestion(question, index)
                })}
            </Animated.View> : null}

            {/* {id === 2 ? <ScrollView style={{ flex: 1,
                 width: '100%' }}>
                {HARDCODED_IMAGES_QUIZ.images.map((question, index) => {
                    return renderImageSelect({source: question.source})
                })}
            </ScrollView> : null} */}


            {((id === 2 || id === 3) && !showResult) ? <View style={{ flex: 1, width: '100%', alignItems: 'center'}}>
                <TouchableOpacity onPress={() => {
                    customModalRef.current.show(() => (
                        <TouchableOpacity activeOpacity={0.9}
                        onPress={() => { customModalRef.current.hide()}}
                        >
                        <Image resizeMode={'contain'}
                    style={{width: SCREEN_WIDTH*0.7, alignSelf: 'center', height: SCREEN_HEIGHT*0.9,
                    // borderWidth: 1, borderColor: 'black'
                    }} source={HARDCODED_IMAGES_QUIZ.images[currentImageIndex].source} />
                        </TouchableOpacity>
                    ))
                }}>
                    <Image resizeMode={'contain'}
                    style={{width: SCREEN_WIDTH*0.7, height: SCREEN_HEIGHT*0.5, maxWidth: 550, maxHeight: 550}} source={HARDCODED_IMAGES_QUIZ.images[currentImageIndex].source} />
                </TouchableOpacity>

                {true ? <View style={{height: 120}}>
                    <Text>Is this a phishing email?</Text>
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity style={{margin: 10,  borderRadius: 20, backgroundColor: Colors.green1 , padding: 20, paddingHorizontal: 30}} onPress={() => {
                            setCurrPhishingEmail(true)
                            setCurrentImageIndex(i => i+1)
                            if (currentImageIndex >= HARDCODED_IMAGES_QUIZ.images.length-1) {
                                // props.navigation.navigate(ROUTE_NAMES.ACADEMY)
                                setShowResult(true)
                                // return;
                            }

                            if (currImage.isPhishing === true) {
                                setCorrectAnswers(i=> i+1)
                            }
                        }}>
                        <Text style={{color: 'white', fontWeight: 'bold'}}>Yes</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{margin: 10, borderRadius: 20, backgroundColor: Colors.red , padding: 20, paddingHorizontal: 30}} onPress={() => {
                            setCurrPhishingEmail(false)
                            setCurrentImageIndex(i => i+1)

                            if (currentImageIndex >= HARDCODED_IMAGES_QUIZ.images.length-1) {
                                // props.navigation.navigate(ROUTE_NAMES.ACADEMY)
                                setShowResult(true)
                                // return;
                            }

                            if (currImage.isPhishing === false) {
                                setCorrectAnswers(i=> i+1)
                            }
                        }}>
                        <Text style={{color: 'white', fontWeight: 'bold'}}>No</Text>
                    </TouchableOpacity>
                    </View>
                </View> : <View style={{height: 120, justifyContent: 'center'}}><Text>
                    {currImage.isPhishing === currPhishingEmail
                    ? currImage.correctText : currImage.errorText}</Text>
                    </View>}
                {/* <TouchableOpacity disabled={currPhishingEmail === undefined}
                    style={{opacity: currPhishingEmail === undefined? 0.5: 1, backgroundColor: Colors.green1, padding: 15, borderRadius: 20}}
                onPress={() => {
                    if (currentImageIndex >= HARDCODED_IMAGES_QUIZ.images.length-1) {
                        // props.navigation.navigate(ROUTE_NAMES.ACADEMY)
                        setShowResult(true)
                        return;
                    }
                    setCurrentImageIndex(i => i+1)
                    // setCurrPhishingEmail(undefined)
                }}>
                    <Text>Next</Text>
                </TouchableOpacity> */}
            </View> : null}

            {showResult ? <View style={{alignItems: 'center', height: 500 }}>
                <Text>Your score is: {correctAnswers} / {id === 1 ? HARDCODED_QUIZ.questions.length : HARDCODED_IMAGES_QUIZ.images.length}</Text>
                <TouchableOpacity
                    style={{ backgroundColor: Colors.green1, padding: 15, borderRadius: 20}}
                onPress={() => {
                    props.navigation.navigate(ROUTE_NAMES.ACADEMY)
                }}>
                    <Text>Next</Text>
                </TouchableOpacity>
            </View> : null}

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    // TODO get max width for web
    contentContainer: {
        maxWidth: 650,
    },
    academyCardContainer: {

    },
    academyImage: {
        width: 100,
        height: 100,
        borderRadius: 10,
    },
    academyCardTitle: {

    },
    academyCardDescription: {

    }
});
