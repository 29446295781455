import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import config from '../../constants/config';
import { REGULAR_DAYS, REGULAR_FREQS } from '../../constants/constants';

const auth = firebase.auth;
const database = firebase.database;

export function createExpence({
    parentId,
    childId,
    amount,
    title,
    frequency,
    day,
}) {
    const body = { parentId, childId, amount, title, frequency, day };

    // return;
    return fetch(config.url + 'setExpenceSchedule', {
        method: 'POST',
        body: JSON.stringify(body),
    }).then(async (response) => {
        console.log('response', response);
        if (response.status !== 200) {
            throw await response.json();
        }

        return response.json();
    });
}

export async function sendAllowanceJob(parentId, childId, salaryValues) {
    const body = {
        salaryFrequency: REGULAR_FREQS[salaryValues.salary_freq],
        amount: parseFloat(salaryValues.salary_amt),
        salaryDay: REGULAR_DAYS[salaryValues.salary_day],
        parentId,
        childId,
    };

    // TODO check date and do stuff accordingly
    const old_balance = (
        await database()
            .ref(`/users/${parentId}/${childId}/chequing/balance`)
            .once('value')
    ).val();

    console.log('old_balance ', old_balance);

    database()
        .ref(`/users/${parentId}/${childId}/chequing`)
        .update({
            balance: old_balance + parseFloat(salaryValues.salary_amt),
        });

    // return;
    return fetch(config.url + 'setSalarySchedule', {
        method: 'POST',
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body),
    }).then((response) => response.json());
}
