/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    ActivityIndicator,
    Animated,
    Image,
    FlatList,
    ScrollView,
    StyleSheet,
    TextInput,
    View,
    Platform,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';

import { useHover } from 'react-native-web-hooks';

import Text from '../CustomText';

import TouchableNativeFeedback from '../TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';

const PennygemLogo = require('assets/Pennygem_Logo.png');

const side_bar_items = [
    { title: 'All', image: require('assets/Store/accessories.png') },
    { title: 'Beauty', image: require('assets/Store/beauty.png') },
    { title: 'Sports', image: require('assets/Store/sport.png') },
    { title: 'Arts', image: require('assets/Store/arts.png') },
    { title: 'Accessories', image: require('assets/Store/accessories.png') },

    { title: 'Toys', image: require('assets/Store/toys.png') },
    { title: 'Electronics', image: require('assets/Store/electronics.png') },
    { title: 'Activities', image: require('assets/Store/activities.png') },
    { title: 'Food', image: require('assets/Store/food.png') },
    // { title: 'Donations', image: require('../assets/Store/donations.png') },
];

const CategoryContainer = React.forwardRef(
    ({ title, image, onPress, isFocused }, ref) => {
        const [animation, setAnimation] = useState(new Animated.Value(0));
        const { isHovered } = useHover(ref);

        useEffect(() => {
            Animated.timing(animation, {
                toValue: isHovered ? 1 : 0,
                duration: 200,
            }).start();
        });

        return (
            <TouchableNativeFeedback
                style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    margin: 10,
                }}
                onPress={() => {
                    onPress();
                }}
            >
                {/* <View style={{height:30, width:30, backgroundColor:'yellow'}}></View> */}
                <View
                    style={{
                        height: 20,
                        width: 20,
                        borderRadius: 5,
                        borderColor: Colors.green1,
                        borderWidth: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 5,
                    }}
                >
                    <Icon
                        style={{ zIndex: 3, opacity: isFocused ? 1 : 0 }}
                        name={'check'}
                        size={16}
                        color={Colors.green1}
                    />
                </View>
                <Text
                    style={{
                        marginTop: 3,
                        alignSelf: 'center',
                        textAlign: 'center',
                        ...defaultStyles.bold,
                    }}
                >
                    {title}
                </Text>
            </TouchableNativeFeedback>
        );
    }
);

const sortValues = {
    'Most popular': 'new',
    'Newest First': 'new',
    'Price Low to High': 'priceLow',
    'Price High To Low': 'priceHigh',
};

function raitingSelectionBlock() {
    function raitingSelected(num) {
        if (this.state.raitingSelected == num) {
            return;
        }

        const { type, sort, categories, age, minPrice, maxPrice } = this.state;
        const { childIds } = this.props.parent;

        this.props.actions
            .getStoreItems({
                rating: num,
                sort,
                type,
                filter: categories,
                ages: age,
                prices: [minPrice, maxPrice],
                page: 0,
            })
            .then((data) =>
                this.setState({
                    storeData: data.hits,
                    data: data.hits,
                    storePage: data.page,
                    storePages: data.nbPages,
                    storeItems: data.nbHits,
                    storeKids: childIds.reduce((curr, item) => {
                        return { ...curr, [item]: false };
                    }, {}),
                })
            );

        this.setState({ raitingSelected: num });
    }

    return (
        <View style={{ marginLeft: 5 }}>
            <Text
                style={{ marginTop: 10, marginLeft: 3, ...defaultStyles.bold }}
            >
                Raiting
            </Text>

            <View style={{ flexDirection: 'row' }}>
                <TouchableNativeFeedback
                    style={{ margin: 3 }}
                    onPress={() => {
                        raitingSelected.call(this, 1);
                    }}
                >
                    <Image
                        resizeMode={'cover'}
                        style={{ width: 40, height: 40 }}
                        source={PennygemLogo}
                    />
                </TouchableNativeFeedback>

                <TouchableNativeFeedback
                    style={{ margin: 3 }}
                    onPress={() => {
                        raitingSelected.call(this, 2);
                    }}
                >
                    <Image
                        resizeMode={'cover'}
                        style={{
                            width: 40,
                            height: 40,
                            opacity: this.state.raitingSelected < 2 ? 0.5 : 1,
                        }}
                        source={PennygemLogo}
                    />
                </TouchableNativeFeedback>

                <TouchableNativeFeedback
                    style={{ margin: 3 }}
                    onPress={() => {
                        raitingSelected.call(this, 3);
                    }}
                >
                    <Image
                        resizeMode={'cover'}
                        style={{
                            width: 40,
                            height: 40,
                            opacity: this.state.raitingSelected < 3 ? 0.5 : 1,
                        }}
                        source={PennygemLogo}
                    />
                </TouchableNativeFeedback>

                <TouchableNativeFeedback
                    style={{ margin: 3 }}
                    onPress={() => {
                        raitingSelected.call(this, 4);
                    }}
                >
                    <Image
                        resizeMode={'cover'}
                        style={{
                            width: 40,
                            height: 40,
                            opacity: this.state.raitingSelected < 4 ? 0.5 : 1,
                        }}
                        source={PennygemLogo}
                    />
                </TouchableNativeFeedback>

                <TouchableNativeFeedback
                    style={{ margin: 3 }}
                    onPress={() => {
                        raitingSelected.call(this, 5);
                    }}
                >
                    <Image
                        resizeMode={'cover'}
                        style={{
                            width: 40,
                            height: 40,
                            opacity: this.state.raitingSelected < 5 ? 0.5 : 1,
                        }}
                        source={PennygemLogo}
                    />
                </TouchableNativeFeedback>
            </View>
        </View>
    );
}

const PriceInput = ({ onSubmitEditing, value, placeholder, onChangeText }) => {
    return (
        <View style={{ width: '45%' }}>
            {value && (
                <Text
                    style={{
                        position: 'absolute',
                        height: 30,
                        justifyContent: 'center',
                        left: 12,
                        marginTop: 5,
                    }}
                >
                    $
                </Text>
            )}
            <TextInput
                placeholder={placeholder}
                style={{
                    outlineWidth: 0,
                    borderRadius: 10,
                    borderColor: Colors.green1,
                    width: '100%',
                    height: 30,
                    borderWidth: 2,
                    paddingLeft: 20,
                }}
                // paddingLeft: this.state.minPrice ? 20 : 10,
                onChangeText={onChangeText}
                value={value}
                onSubmitEditing={onSubmitEditing}
            />
        </View>
    );
};

export const SearchWebFilter = (props) => {
    const {
        isCategoryFocused,
        onSubmit,
        onChangeMinPrice,
        onChangeMaxPrice,
        minPrice,
        maxPrice,
        onCategoryPress,
        layout,
        vmin,
    } = props;

    return (
        <ScrollView
            style={[
                styles.container,
                {
                    marginTop: 0.1 * vmin,
                    maxHeight: layout.height * 0.9,
                    // display:
                    // layout.width > 850 || this.state.showFilters
                    //     ? 'flex'
                    //     : 'none',
                },
            ]}
            contentContainerStyle={{ justifyContent: 'center', }}
        >
            {side_bar_items.map((item, index) => {
                return (
                    <CategoryContainer
                        key={item.title}
                        onPress={() => onCategoryPress(item)}
                        isFocused={isCategoryFocused(item)}
                        image={item ? item.image : null}
                        title={item.title}
                    />
                );
            })}
            <View style={styles.priceContainer}>
                <PriceInput
                    onSubmitEditing={onSubmit}
                    value={minPrice}
                    placeholder={'Min Price, $'}
                    onChangeText={onChangeMinPrice}
                />
                <PriceInput
                    onSubmitEditing={onSubmit}
                    value={maxPrice}
                    placeholder={'Max Price, $'}
                    onChangeText={onChangeMaxPrice}
                />
            </View>

            {/* {raitingSelectionBlock.call(this)} */}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        // borderRightWidth: 2,
        zIndex: 2,
        backgroundColor: 'white',
        opacity: 0.9,
        paddingLeft: 15,
        position: 'absolute',
        // borderRightColor: 'white',
        borderRightWidth: 1.5,
        borderRightColor: Colors.gray0,
        width: 305,
    },
    priceContainer: {
        marginTop: 15,
        paddingRight: 15,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
});
