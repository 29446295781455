// import { appConstants } from "";
import * as actionTypes from '../actions/actionTypes'

const initialState = {
    email: '',
    isLoading: true,
    type: null, // possible values "parent" or "child"
    user: {},
    parentId: '',
    childId:'',
    test:42,
    academy: {
        videos: [],
        quizzes: [],
        articles: [],
    },
    // childIds: [],
    // id: 0,
    // settings: {},
    // names: {},
    // parentProfile: {},
    // parentId:'XcbB7fIw6pgbjoqxhmb9FwZb4BL2'
};


export default function auth(state = initialState, action) {
    let email = state.email;
    let type;

    switch (action.type) {

        case actionTypes.USER_LOGIN:
            console.log('user.user.uid parent -', action.payload.user.user.uid)
            console.log('type - ', action.payload.type)

            type = action.payload.type
            return {
                ...state,
                isLoading: false,
                type: type,
                user: action.payload.user,
                userId: action.payload.user.user.uid,
                parentId: action.payload.parentId,
                childId: type?action.payload.user.user.uid:'',
            };

        case actionTypes.USER_SIGNUP:
            console.log('I AM HERE AT SIGNUP ', action.payload.user.uid)
            return {
                ...state,
                isLoading: false,
                user: action.payload.user,
                parentId: action.payload.user.uid
            };

        case actionTypes.REMEMBER_ME:
            email = action.payload.email;
            return { ...state, email };


        case actionTypes.CLEAR_AUTH:
            email = state.email;
            return { ...initialState, email };
        // return {...state, ...initialState};

        case actionTypes.USER_LOGOUT:
            email = state.email;
            return { ...initialState, email };
        // return initialState;



        default:
            return state
    }
}