import {Platform, LayoutAnimation} from 'react-native';

import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import AsyncStorage from '@react-native-async-storage/async-storage';

import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import { getDashboardData as getDashboardDataFunc} from '../../../helpers/dashboard/getDashboardData';

import config from '../../../constants/config'
// import { database, auth, storage } from 'firebase';

const auth = firebase.auth;
const database = firebase.database;
const storage = firebase.storage;

// function requestLogin() {
//     return {
//         type: actionTypes.USER_LOGIN,
//         // data: [],
//         isLoading:true
//     }
// }

function receiveLogin(json, parentId) {
    //fixme
    return {
        type: actionTypes.USER_LOGIN,
        payload: {
            // obj: json,
            user: json,
            parentId,
            isLoading: false,
        }
    }
}
export function oldLogin(email, password) {
    return async dispatch => {
        // dispatch(requestLogin())


        return auth().signInWithEmailAndPassword(email, password).then(async user => {
            console.log(user)
            const childId = user.user.uid;
            const parentId = (await database().ref('/children/' + childId + '/parent').once('value')).val()

            dispatch(receiveLogin(user, parentId))
            // this.props.navigation.navigate('Dashboard');
            return user;
        })
        //   .catch(error=>{
        //     console.log(error)
        //     // console.log(error.userInfo.message)
        //     // alert(error.userInfo.message)
        //   })
    }
}

export function kidLogin(user) {
    return async dispatch => {
        // dispatch(requestLogin())

        const childId = user.user.uid;
        const parentId = (await database().ref('/children/' + childId + '/parent').once('value')).val()
        return dispatch(receiveLogin(user, parentId))
        //   .catch(error=>{
        //     console.log(error)
        //     // console.log(error.userInfo.message)
        //     // alert(error.userInfo.message)
        //   })
    }
}


// DEPRECATED - shoud be removed
export function handleInitialLogin(childId) {
    return async dispatch => {
        // dispatch(requestLogin())

        const user = { user: { uid: childId } }
        const parentId = (await database().ref('/children/' + childId + '/parent').once('value')).val()
        // const childId = 1
        // user.user['childId'] = childId
        dispatch(receiveLogin(user, parentId))
        return 0

    }
}

function handleLogout() {
    //fixme
    return {
        type: actionTypes.USER_LOGOUT,
        payload: {
        }
    }
}

export function logout() {
    return async dispatch => {

        // this.props.actions.logout();
        AsyncStorage.clear();
        dispatch(handleLogout());
    }
    // this.props.navigation.navigate('Login')
}


export function setPassword(password, actionCode) {
    return async dispatch => {
        // dispatch(requestLogin())

        return auth().verifyPasswordResetCode(actionCode).then(function (email) {
            var accountEmail = email;

            // TODO: Show the reset screen with the user's email and ask the user for
            // the new password.

            // Save the new password.
            return auth().confirmPasswordReset(actionCode, password).then(function (resp) {
                // Password reset has been confirmed and new password updated.

                // TODO: Display a link back to the app, or sign-in the user directly
                // if the page belongs to the same domain as the app:
                return auth().signInWithEmailAndPassword(accountEmail, password)
                    .then(async user => {
                        console.log(user)
                        const childId = user.user.uid;
                        const parentId = (await database().ref('/children/' + childId + '/parent').once('value')).val()

                        dispatch(receiveLogin(user, parentId))
                        // this.props.navigation.navigate('Dashboard');
                        return user;
                    })

                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.
            }).catch(function (error) {
                console.log('ERROR!!', error)
                // Error occurred during confirmation. The code might have expired or the
                // password is too weak.
            });
        })

        // return  auth().signInWithEmailAndPassword(email, password).then(async user=>{
        //     console.log(user)
        //     const childId = user.user.uid;
        //     const parentId = (await database().ref('/children/'+childId +'/parent').once('value')).val()

        //     dispatch(receiveLogin(user, parentId))
        //     // this.props.navigation.navigate('Dashboard');
        //     return user;
        //   })
        //   .catch(error=>{
        //     console.log(error)
        //     // console.log(error.userInfo.message)
        //     // alert(error.userInfo.message)
        //   })
    }
}


export function sendPasswordResetEmail(email) {
    return async dispatch => {
        const body = { type: 'child', email }
        // var actionCodeSettings = {
        //     url: 'https://pennygem-kids.firebaseapp.com/',
        //     link: 'https://pennygem-kids.firebaseapp.com/',
        //   };
        return fetch(config.url + 'sendPasswordResetEmail', {
            method: 'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body)
        })
            // .then(response => {console.log('response',response); return response.json()})
            .then(async response => {
                console.log('response', response);
                if (response.status !== 200) {
                    throw await response.json();
                }
                return response.json()
            })

        //   .catch(function(error) {
        //       console.log('authActons - Error on sendPasswordReset - ', error)
        //     // An error happened.
        //   });

    }
}


function handleClearAuth() {
    //fixme
    console.log('KLID CLEAR AUTH')

    return {
        type: actionTypes.CLEAR_AUTH,
        payload: {
        }
    }
}

export function clearAuth() {
    return async dispatch => {

        // this.props.actions.logout();
        // AsyncStorage.clear();
        dispatch(handleClearAuth());
    }
    // this.props.navigation.navigate('Login')
}

export function kidClearAuth() {
    return async dispatch => {

        // this.props.actions.logout();
        // AsyncStorage.clear();
        dispatch(handleClearAuth());
    }
    // this.props.navigation.navigate('Login')
}



function handleAvatar(avatar, avatarImage, avatarHash) {

    return {
        type: actionTypes.SAVE_AVATAR,
        payload: {
            avatar,
            avatarImage,
            avatarHash
        }
    }
}

export function saveAvatarImage(avatar, avatarImage, hash) {
    return handleAvatar(avatar, avatarImage, hash)
}


export function getAvatar(parentId, childId) {
    return async dispatch => {

        var database_ref = database().ref('/users/' + parentId + '/' + childId);

        const avatar_ref = database_ref.child('settings/avatar')
        const avatar = (await avatar_ref.once('value')).val();

        let image = await AsyncStorage.getItem('avatar', null)
        let avatarImage

        if (!image && avatar === 5) {
            // if (true) {
            image = await storage().ref('children/' + childId + '.png').getDownloadURL()

            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            var reader = new FileReader();
            let blob = ''

            xhr.onload = (event) => {
                blob = xhr.response;
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    var base64data = reader.result;
                    avatarImage = reader.result;
                    console.log('0 - avatarImage - ', avatarImage)
                    console.log('0 - avatarImage - ', base64data)

                    // this.setState({ image: avatar === 5 ? base64data : undefined, avatar: avatar })
                    AsyncStorage.setItem('avatar', base64data)
                }

            };
            xhr.open('GET', image);
            xhr.send();

            console.log('blob -', blob)
        } else if (image && avatar === 5) {
            avatarImage = image
        }
        else {
            // let metadata = await storage().ref('children/' + childId + '.png').getMetadata()
            // let image1 = new Image(image, childId + '.png' )
            // let file1 = new FileReader(image, childId + '.png' )

            // // file1.src = image

            // var hash1 = CryptoJS.MD5(image).toString();
            // var hash2 = CryptoJS.MD5(file1).toString();
            // var hash3 = CryptoJS.MD5(image1).toString();

            // console.log('metadata - ', metadata.md5Hash)
            // console.log(Buffer.from(metadata.md5Hash, 'base64').toString('hex'))
            // // console.log(Buffer.from(image, 'base64').toString('hex'))

            // console.log('HASH - ',hash1, hash2)
            // console.log(hash3)

        }
        console.log('avatarImage - ', avatarImage)
        dispatch(handleAvatar(avatar, avatarImage));
    }
    // this.props.navigation.navigate('Login')
}



export function saveAvatar(parentId, childId, avatar, avatarImage) {
    return async dispatch => {
        let image;

        // if (avatarImage) {

        //     console.log('avatarIMAGE 0- ', avatarImage)
        //     if (Platform.OS === 'web') {

        //     }
        //     else {
        //         await FileSystem.deleteAsync(FileSystem.documentDirectory + childId + '.png', { idempotent: true })
        //         image = (await FileSystem.downloadAsync(
        //             avatarImage,
        //             FileSystem.documentDirectory + childId + '.png'
        //         )).uri
        //     }
        // }

        database().ref('/users/' + parentId + '/' + childId + '/settings').update({
            avatar
        });

        dispatch(handleAvatar(avatar, avatarImage));
    }
    // this.props.navigation.navigate('Login')
}


function saveChildName({ name }) {

    return {
        type: actionTypes.SAVE_CHILD_NAME,
        payload: {
            name
        }
    }
}

export function setChildName({ name }) {
    return async dispatch => {
        dispatch(saveChildName({ name }))
    }
}

export function getChildName({ parentId, childId }) {
    return async dispatch => {

        return database().ref('/children/' + childId + '/name').once('value')
            .then((value) => { console.log(''); return dispatch(saveChildName({ name: value.val() })) })
        // .then((value)=>{ return })

    }
    // this.props.navigation.navigate('Login')
}


function receiveDashboardData({ chequing, savings, spent, goals, jobs, purchases_approved, purchases_pending }) {
    //fixme
    console.log('KLID CLEAR AUTH')

    return {
        type: actionTypes.RECEIVE_DASHBOARD_DATA,
        payload: {
            chequing, savings, spent, goals, jobs, purchases_approved, purchases_pending
        }
    }
}

export function getDashboardData({ parentId, childId }) {
    return async dispatch => {
        console.log('LAST ONE')

        // LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);

        dispatch({
            type: actionTypes.SET_DASHBOARD_DATA_LOADING,
            payload: {
                isLoading: true,
            },
        });

        const data = await getDashboardDataFunc({parentId, childId});

        dispatch(receiveDashboardData(data))

        LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
        dispatch({
            type: actionTypes.SET_DASHBOARD_DATA_LOADING,
            payload: {
                isLoading: false,
            },
        });
    }
}
