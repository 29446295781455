/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'firebase/database';
import 'firebase/auth';

import Text from '../../../components/CustomText';
import { BlockWrapper } from '../../../components/newChild/BlockWrapper';
import AddJob from '../../../components/jobs/NewJob';
import { NewChildFooter } from '../../../components/newChild/NewChildFooter';

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { NEW_CHILD_SCREENS } from '../../../constants/data';

const title = NEW_CHILD_SCREENS.FIRST_JOB;
const description =
    "Let's get started real quick. Let's create your child's first job.";

class FirstJob extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            id: 0,
            jobValue: '',
            jobCategory: 'Chores',
            jobFrequency: 'None',
            jobTitle: '',
            isLoading: true,
            jobKids: this.props.parent.childIds.map((item) => {
                return { id: item, name: this.props.parent.names[item] };
            }),
            jobStopRepeat: '',
            selectedKids: [],
        };

        this.back = this.back.bind(this);
        this.proceed = this.proceed.bind(this);
    }

    proceed() {
        const { jobCategory, jobValue, jobTitle, jobDeadline, jobFrequency, jobStopRepeat, selectedKids } = this.state;

        if (!(this.state.amount || !this.state.regular)) {
            this.setState({ amountError: true });

            return;
        }

        // this.props.kidData({ type: 'allowance', data: { amount, frequency, day: salaryDays[day], regular } });
        this.props.kidData({
            type: 'firstJob',
            data: {
                jobCategory,
                jobValue,
                jobTitle,
                jobDeadline,
                jobFrequency,
                jobStopRepeat,
                selectedKids,
            },
        });

        this.props.proceed();
    }

    back() {
        this.props.back();
    }

    setJobValue(key, value) {
        this.setState({ [key]: value });
    }

    render() {
        const { names } = this.props.parent;

        return (
            <BlockWrapper
                description={description}
                title={title}
                showProgress={true}
            >
                {/* <View> */}
                {({ layout }) => (
                    <>
                        <View
                            style={{
                                width: `${layout.width > 600 ? 80 : 100}%`,
                            }}
                        >
                            <AddJob
                                jobCategory={this.state.jobCategory}
                                setJobCategory={(value) =>
                                    this.setJobValue('jobCategory', value)
                                }
                                jobValue={this.state.jobValue}
                                setJobValue={(value) =>
                                    this.setJobValue('jobValue', value)
                                }
                                jobTitle={this.state.jobTitle}
                                setJobTitle={(value) =>
                                    this.setJobValue('jobTitle', value)
                                }
                                jobDeadline={this.state.jobDeadline}
                                setJobDeadline={(value) =>
                                    this.setJobValue('jobDeadline', value)
                                }
                                jobStopRepeat={this.state.jobStoprepeat}
                                setStopRepeat={(value) =>
                                    this.setJobValue('jobStopRepeat', value)
                                }
                                jobFrequency={this.state.jobFrequency}
                                setJobFrequency={(value) =>
                                    this.setJobValue('jobFrequency', value)
                                }
                                // checkNewJobConditions={this.state.job}
                                checkNewJobConditions={() => {}}
                                layout={layout}
                                kids={this.state.jobKids}
                                selectedKids={this.state.selectedKids}
                                setSelectedKids={(value) =>
                                    this.setJobValue('selectedKids', value)
                                }
                                // setKid={(item) => { this.setState({ jobKids: { ...this.state.jobKids, [item]: !this.state.jobKids[item] } }) }}
                                setKid={(items) => {
                                    this.setState({ selectedKids: items });
                                }}
                                removeKid={(item) => {
                                    this.setState({
                                        jobKids: {
                                            ...this.state.jobKids,
                                            [item]: false,
                                        },
                                    });
                                }}
                                names={names}
                                hideProceedButton={true}
                                showChildSelect={false}
                            />
                        </View>
                        <NewChildFooter
                            onBackPress={this.back}
                            onProceedPress={this.proceed}
                        />
                    </>
                )}
                {/* </View> */}
            </BlockWrapper>
        );
    }
}

class FirstJobScreen extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.state = {
            section: 1,
        };
    }

    render() {
        return (
            <FirstJob
                auth={this.props.auth}
                parent={this.props.parent}
                actions={this.props.actions}
                navigation={this.props.navigation}
                kidData={this.props.actions.kidData}
                back={() => this.props.navigation.navigate('Profile')}
                proceed={() => this.props.navigation.navigate('SkipFullProcess')}
            />
        );
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(
    (state) => {
        // console.log(Object.keys(state.vacancies));
        return {
            auth: state.auth,
            parent: state.parent,
            store: state.store,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...authActions, ...kidAuthActions },
            dispatch
        ),
    })
)(FirstJobScreen);
