/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button } from './Buttons';

const NewChildFooter = ({ onBackPress, onProceedPress, proceedTitle }) => {
    const renderProceedTitle = useMemo(() => proceedTitle || 'Proceed', [
        proceedTitle,
    ]);

    return (
        <View style={styles.container}>
            <Button title={'Back'} onPress={onBackPress} />
            <Button title={renderProceedTitle} onPress={onProceedPress} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        maxWidth: 381,
        paddingHorizontal: 35,
        flexDirection: 'row',
    },
});

export { NewChildFooter };
