export function filterPurchases(data_approved, data_pending) {
    var total_income = 0;
    let income = 0;
    let approved_online = 0, approved_online_trans = 0;
    let approved_credit = 0, approved_credit_trans = 0;
    let pending_online = 0, pending_online_trans = 0;
    let pending_credit = 0, pending_credit_trans = 0;

    let pending_online_purchases = [];
    let pending_credit_purchases = [];
    let approved_online_purchases = [];
    let approved_credit_purchases = [];

    for (let i in data_approved) {
        // console.log('data');
        // console.log(data[i])
        if (data_approved[i] === null) continue

        switch (data_approved[i].type) {
            // INCOME
            case 'online purchase':
                approved_online_purchases.push(data_approved[i]);
                approved_online += data_approved[i].price;
                approved_online_trans++;
                break;
            case 'credit purchase':
                approved_credit_purchases.push(data_approved[i])
                approved_credit += data_approved[i].price;
                approved_credit_trans++;
                break;
            case 'savings online purchase':
                approved_online_purchases.push(data_approved[i]);
                approved_online += data_approved[i].price;
                approved_online_trans++;
                break;
            case 'savings credit purchase':
                approved_credit_purchases.push(data_approved[i])
                approved_credit += data_approved[i].price;
                approved_credit_trans++;
                break;

            case 'parent_withdrawal':
                approved_credit_purchases.push(data_approved[i])
                approved_credit += data_approved[i].price;
                approved_credit_trans++;
                break;

        }
    }

    let total_approved = approved_online + approved_credit;
    let total_approved_trans = approved_online_trans + approved_credit_trans;


    for (let i in data_pending) {
        // console.log('data');
        // console.log(data[i])
        if (data_pending[i] === null) continue

        switch (data_pending[i].type) {
            // INCOME
            case 'online purchase':
                pending_online_purchases.push(data_pending[i]);
                pending_online += data_pending[i].price;
                pending_online_trans++;
                break;

            case 'credit purchase':
                pending_credit_purchases.push(data_pending[i])
                pending_credit += data_pending[i].price;
                pending_credit_trans++;

            case 'savings online purchase':
                pending_online_purchases.push(data_pending[i]);
                pending_online += data_pending[i].price;
                pending_online_trans++;

            case 'savings credit purchase':
                pending_credit_purchases.push(data_pending[i])
                pending_credit += data_pending[i].price;
                pending_credit_trans++;
        }
    }

    let total_pending = pending_online + pending_credit;
    let total_pending_trans = pending_online_trans + pending_credit_trans;

    return ({
        approved_online_purchases: approved_online_purchases,
        approved_online: approved_online,
        approved_online_trans: approved_online_trans,
        approved_credit_purchases: approved_credit_purchases,
        approved_credit: approved_credit,
        approved_credit_trans: approved_credit_trans,

        pending_online_purchases: pending_online_purchases,
        pending_online: pending_online,
        pending_online_trans: pending_online_trans,
        pending_credit_purchases: pending_credit_purchases,
        pending_credit: pending_credit,
        pending_credit_trans: pending_credit_trans,

        total_approved: total_approved,
        total_approved_trans: total_approved_trans,
        total_pending: total_pending,
        total_pending_trans: total_pending_trans,
        isLoading: false
    })
}
