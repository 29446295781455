/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    View,
    Platform,
    TextInput,
    TouchableOpacity,
    StatusBar,
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/database';

import { getChequing } from '../../store/actions/parents/chequingActions';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';
import CounterTextInput from '../../components/CounterTextInput';

import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';

// TODO better format of values
function formatInputValue(str) {
    return str || str === 0
        ? str
              .toString()
              .replace(/[^\d\.]/g, '')
              .replace(/\./, 'x')
              .replace(/\./g, '')
              .replace(/x/, '.')
        : '';
}

function getButtonTitle(type) {
    switch (type) {
        case 'deposit':
            return 'Deposit';
        case 'withdraw':
            return 'Widthdraw';
        default:
            return 'Transfer';
    }
}

const TransferBlock = ({ type, title, label, update, onPress, error }) => {
    const dispatch = useDispatch();
    const parentId = useSelector((state) => state.auth.parentId);
    const { childIds, names, id } = useSelector((state) => state.parent);
    const [transfer, setTransfer] = useState('');
    const [transferUpdated, setTransferUpdated] = useState(false);

    const buttonTitle = getButtonTitle(type);
    // console.log('parentId, childIds, names, id ',parentId, childIds, names, id)

    return (
        <View style={{backgroundColor: 'white', borderRadius: 20, width: '80%', alignSelf: 'center', justifyContent: 'space-between'}}>
            <View
                style={{
                    justifyContent: 'center',
                    marginTop: '5%',
                    marginBottom: 20,
                }}
            >
                <Text
                    style={{
                        textAlign: 'center',
                        fontFamily: 'Carmen Sans Bold',
                    }}
                >
                    {title}
                </Text>
            </View>
            <View
                style={{
                    width: '100%',
                    alignSelf: 'flex-start',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                {/* <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}> */}
                <CounterTextInput
                    label={label}
                    onPressUp={() =>
                        setTransfer(
                            '$' +
                                (parseInt(
                                    transfer.toString().replace('$', '')
                                ) +
                                    1)
                        )
                    }
                    onPressDown={() =>
                        parseInt(transfer.toString().replace('$', '')) > 0
                            ? setTransfer(
                                  '$' +
                                      (parseInt(
                                          transfer.toString().replace('$', '')
                                      ) -
                                          1)
                              )
                            : null
                    }
                    centered={true}
                    value={transfer ? '$' + formatInputValue(transfer) : ''}
                    keyboardType={'numeric'}
                    onChangeText={(text) => {
                        setTransfer(
                            text || text !== '$'
                                ? '$' + text.toString().replace('$', '')
                                : ''
                        );

                        setTransferUpdated(false);
                    }}
                />

                <View style={{ width: '100%' }}>
                    <TouchableNativeFeedback
                        onPress={() => {
                            if (transfer) {
                                console.log('123');
                                // setModalVisible(false);
                                onPress(transfer);
                                setTransferUpdated(true);
                                // update({ parentId, childId: childIds[id], transfer })
                                //     .then(() => { dispatch(getChequing(interval, parentId, childIds[id])) })
                            }

                            console.log('431');
                        }}
                    >
                        <View
                            style={{
                                width: '80%',
                                alignSelf: 'center',
                                marginBottom: 30,
                                backgroundColor: Colors.green1,
                                height: 40,
                                borderRadius: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={[{ color: 'white' }, defaultStyles.bold]}
                            >
                                {buttonTitle.toUpperCase()}
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                    <Text
                        style={{ alignSelf: 'center', color: Colors.red , height: 25 }}
                    >
                        {transferUpdated && error}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default TransferBlock;
