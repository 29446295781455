/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
	ActivityIndicator,
	Animated,
	Platform,
	SafeAreaView,
	TouchableOpacity,
	// Platform,
	TouchableWithoutFeedback,
	StyleSheet,
	ScrollView,
	View,
	StatusBar,
} from 'react-native';

import {
	useDimensions,
	useActive,
	useFocus,
	useHover,
	useREM,
	useScaledSize,
	Hoverable, Resizable
} from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome';

import firebase, { registerVersion } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'
import ParentComponentHeader from '../../components/ComponentHeader'
import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import {defaultStyles} from '../../constants/style'

import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as authActions from '../../store/actions/parents/authActions'
import Colors from '../../constants/Colors';

const auth = firebase.auth;
const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly', }

function formatInputValue(str) {
	return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

function formatInterestRate(str) {
	return (str && str !== '%') ? parseFloat(str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")) + '%' : ''
}


Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};

const iconMargin = (layout) => {
	const style = {}
	if (layout.width > 1600) return 30
	if (layout.width <= 1600 && layout.width > 800) return 10 + layout.width * 0.01
	return 10
}

function Information({ layout, text, visible, onPress }) {
	// let [visible, setVisible]= useState(true)
	// let visible1= this.state[value]

	return <React.Fragment>
		<TouchableNativeFeedback
			onPress={onPress}
			// onPress={() => { this.setState({[value]:true}); setVisible(!visible) }}
			style={{ position: 'absolute', left: iconMargin(layout), marginRight: 10, height: 20, width: 20, borderRadius: 15, justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.green1 }}>
			<View>
				<Text style={{ fontSize: 14, ...defaultStyles.bold, color: 'white' }}>i</Text>

			</View>
		</TouchableNativeFeedback>
		{visible && <View style={{
			opacity: 0.9,
			margin: 5, maxWidth: 200, borderRadius: 5, backgroundColor: Colors.green1, position: 'absolute',
			left: 0, bottom: 55, paddingHorizontal: 10, paddingTop: 5,
		}}>
			<Text style={{ color: 'white', marginHorizontal: 5, marginTop: 5, textAlign: 'center' }}>{text}</Text>
			<View style={{
				left: iconMargin(layout) - 15, bottom: -10, width: 10, borderTopWidth: 10, borderRightWidth: 10, borderBottomWidth: 0, borderLeftWidth: 10,
				borderTopColor: Colors.green1, borderRightColor: 'transparent', borderBottomColor: 'transparent', borderLeftColor: 'transparent',
			}}></View>
		</View>}
	</React.Fragment>
}

const iconsVisible = ['monthlyFeeVisible', 'minBalanceVisible', 'monthlyTransactionsVisible', 'transactionFeeVisible']

class Settings extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			headerLeft: null,
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isFocused: false,
			id: 0,
			allowanceStart: (new Date()).yyyymmdd(),
			scaleValue: new Animated.Value(0.5),
			isHoveredAnim: new Animated.Value(0),

			minBalanceVisible: false,
			monthlyFeeVisible: false,

			additional: false,
			isLoading: true,

			//  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
			// type: Camera.Constants.Type.back,
		};
	};

	async getSettings(parentId, childId) {
		const ref_source = database().ref('users/' + parentId + '/' + childId)

		let int_rate = ((await ref_source.child('savings').child('int_rate').once('value')).val()) * 100;
		let withdraw_fee = ((await ref_source.child('savings').child('withdraw_fee').once('value')).val());
		let withdraw_limit = ((await ref_source.child('savings').child('withdraw_limit').once('value')).val());

		let min_balance = ((await ref_source.child('chequing').child('min_balance').once('value')).val());
		let monthly_fee = ((await ref_source.child('chequing').child('monthly_fee').once('value')).val());
		let salary_amt = ((await ref_source.child('chequing').child('salary_amt').once('value')).val());
		let salary_freq = ((await ref_source.child('chequing').child('salary_freq').once('value')).val());
		let transact_fee = ((await ref_source.child('chequing').child('transact_fee').once('value')).val());
		let transact_limit = ((await ref_source.child('chequing').child('transact_limit').once('value')).val());

		const settings = {
			int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq: salaryFreqsReversed[salary_freq],
			transact_fee, transact_limit
		}
		this.setState({
			int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq: salaryFreqsReversed[salary_freq],
			transact_fee, transact_limit
		})
	}

	componentDidMount() {

		const { parentId, } = this.props.auth;
		const { childIds, id } = this.props.parent;

		// const settings = this.props.auth.settings
		const advanced = database().ref('users/' + parentId + '/' + childIds[id] + '/chequing/additional').once('value')
			.then(val => {
				console.log('val - ', val.val())
				this.setState({additional:val.val()?true:false})})

		// this.setState({ ...this.props.auth.settings[childIds[this.props.id]] })
		// this.getSettings(parentId, childIds[this.props.id])
		this.setState({ isLoading: true }, () => {
			Animated.timing(this.state.scaleValue, {
				toValue: 1,
				duration: 350,
				useNativeDriver: true,
				delay: this.props.index * 100
			}).start();
			this.props.actions.getSettings(parentId, childIds[this.props.id])
				.then(settings => {
					console.log('settings!!', settings);
					Animated.timing(this.state.scaleValue, {
						toValue: 0,
						duration: 350,
						useNativeDriver: true,
						delay: this.props.index * 100
					}).start();
					this.setState({ ...settings, isLoading: false })
				})
		})

	}

	componentDidUpdate(prevProps) {

		if ((this.props.id) !== (prevProps.id)) {
			const { parentId, } = this.props.auth;
			const { childIds } = this.props.parent;

			// this.setState({ ...this.props.auth.settings[childIds[this.props.id]] })
			// this.getSettings(parentId, childIds[this.props.id])

			this.setState({ isLoading: true }, () => {
				Animated.timing(this.state.scaleValue, {
					toValue: 1,
					duration: 350,
					useNativeDriver: true,
					delay: this.props.index * 100
				}).start();
				this.props.actions.getSettings(parentId, childIds[this.props.id])
					.then(settings => {
						console.log('settings!!', settings);
						Animated.timing(this.state.scaleValue, {
							toValue: 0,
							duration: 250,
							useNativeDriver: true,
							delay: this.props.index * 100
						}).start(() => { this.setState({ ...settings, isLoading: false }) });
					})
			})
		}
	}

	simplifiedView(vmin) {
		return <View style={{ height: 0.3 * vmin + 85 * 6, justifyContent: 'center', alignItems: 'center' }}>
			<Text style={{ fontSize: 25 }}>Please select advanced settings</Text>
		</View>
	}

	iconMargin(layout) {
		const style = {}
		if (layout.width > 1600) return 30
		if (layout.width <= 1600 && layout.width > 800) return 10 + layout.width * 0.01
		return 10
	}

	setAdditionalSettings() {
		const { parentId, } = this.props.auth;
		const { childIds, id } = this.props.parent;

		this.setState({ additional: !this.state.additional }, () => {
			database().ref('users/' + parentId + '/' + childIds[id] + '/chequing/').update({
				additional: this.state.additional ? 1 : 0
			})
		})

	}


	render() {
		const { layout, actions, id, settings, auth, parent } = this.props;
		const { parentId, } = auth;
		const { childIds, names, isLoading, } = parent;

		console.log('Object.keys(childIds).length')
		console.log(Object.keys(childIds).length)
		console.log((childIds))
		console.log('parentId - ', parentId)

		const vmin = Math.min(...[layout.width, layout.height]);
		let width = layout.width;
		const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (40)
		// const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
		//     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}
		if (this.state.isLoading) {
			return <View>
				<ActivityIndicator size={'large'} color={Colors.green1} />
			</View>
		}
		return (<View onStartShouldSetResponder={() => {
			this.setState({ ...iconsVisible.reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: false } }, {}) });
			Animated.timing(this.state.isHoveredAnim, {
				toValue: 0,
				duration: 350,
				useNativeDriver: true,
				delay: 0
			}).start(() => this.setState({ isHovered: false }));
		}} style={{ marginTop: 15 }}>

			<TouchableNativeFeedback style={{ alignSelf: 'center' }} onPress={() => { this.setAdditionalSettings() }}><View style={{ flexDirection: 'row' }}>
				<View style={{
					flex: 1, borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
					borderColor: Colors.green1, borderWidth: 3
				}}>
					<Icon style={{ zIndex: 3, opacity: this.state.additional ? 1 : 0 }} name="check" size={16} color={Colors.green1} />
				</View>
				<View style={{ marginLeft: 15, justifyContent: 'center' }}>
					<Text>Use additional settings</Text>
				</View>
			</View>
			</TouchableNativeFeedback>
			{!this.state.additional ? this.simplifiedView(vmin) : <View onLayout={e => { this.setState({ childPos: e.nativeEvent.layout.y }) }} style={{ width: '100%' }}>

				{this.state.isLoading && <Animated.View style={{
					opacity: this.state.scaleValue, zIndex: 1, position: 'absolute', justifyContent: 'center', borderRadius: 15,
					backgroundColor: 'rgba(126, 193, 61, 0.1)', height: '100%', width: '100%'
				}}>
					<ActivityIndicator style={{ margin: 30 }} size="large" color={Colors.green1} ></ActivityIndicator>
				</Animated.View>}
				<View style={{ opacity: this.state.isLoading ? 0.5 : 1, zIndex: -1, flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10 }}>
					<View style={{ width: '100%', flexDirection: "row", justifyContent: 'center' }}>
						<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin }}>Account Fees</Text>
					</View>
					<View style={{ alignItems: 'center', width: '100%', flexDirection: "row" }}>
						<Text style={{
							fontSize: 20, marginLeft: '10%', ...defaultStyles.bold, color: '#7ec13d',
						}}>Chequing Account</Text>
						<Hoverable>{isHovered => {
							isHovered ? Animated.timing(this.state.isHoveredAnim, {
								toValue: 1,
								duration: 350,
								useNativeDriver: true,
								delay: 0
							}).start() :
								Animated.timing(this.state.isHoveredAnim, {
									toValue: 0,
									duration: 350,
									useNativeDriver: true,
									delay: 0
								}).start()
							return <TouchableWithoutFeedback onPress={() => {
								this.setState({ isHovered: true }, () => {
									Animated.timing(this.state.isHoveredAnim, {
										toValue: 1,
										duration: 1050,
										useNativeDriver: true,
										delay: 0
									}).start()
								});
							}} ><View style={{ zIndex: 20, marginLeft: 15, borderRadius: 20, justifyContent: 'center', alignItems: 'center', height: 25, width: 25, backgroundColor: Colors.green1 }}>
									<Text style={{ ...defaultStyles.bold, color: 'white' }}>?</Text>
									<Animated.View style={{
										opacity: this.state.isHoveredAnim, zIndex: 20, display: (isHovered || this.state.isHovered) ? 'flex' : 'none', borderRadius: 15, boxShadow: '2px 2px 15px #272827', width: 250,
										backgroundColor: 'rgba(255,255,255,0.9)', padding: 20, position: 'absolute', top: 20
									}}>
										<Text><Text style={{ ...defaultStyles.bold }}>Monthly Fee</Text> - charged when balange is less than minimum, can be charged once a month max,</Text>
										<Text><Text style={{ ...defaultStyles.bold }}>Transactions Fee</Text> - charged when amount of transactions in this month is more than Allowed Monthly Transactions Value.</Text>
									</Animated.View>
								</View>
							</TouchableWithoutFeedback>
						}}
						</Hoverable>
					</View>
					{/* <View style={{flex:1, width:'100%',justifyContent:'center', alignItems:'center', flexDirection:'row'}}> */}
					<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
						<Information onPress={() => {
							let obj = iconsVisible.reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: false } }, {})
							obj['minBalanceVisible'] = true
							this.setState({ ...obj })
						}}
							visible={this.state.minBalanceVisible} layout={layout} text={'Minimum Balance'} />
						<FloatingLabelInput containerStyle={{ zIndex: -1 }} additionalSign={'$'} keyboardType={Platform.OS !== 'web' ? 'number-pad' : 'default'} label={'Minimum Balance'}
							value={formatInputValue(this.state.min_balance)}
							onChangeText={text => this.setState({ min_balance: text })} />
					</View>
					{/* </View> */}
					<View style={{ margin: 5 }}></View>
					<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
						<Information onPress={() => {
							let obj = iconsVisible.reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: false } }, {})
							obj['monthlyFeeVisible'] = true
							this.setState({ ...obj })
						}} visible={this.state.monthlyFeeVisible}
							layout={layout} text={'Monthly Fee'} />

						<FloatingLabelInput containerStyle={{ zIndex: -1 }} additionalSign={'$'} label={'Monthly Fee'} value={formatInputValue(this.state.monthly_fee)}
							onChangeText={text => this.setState({ monthly_fee: text })} />
					</View>

					<View style={{ margin: 5 }}></View>
					<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
						<Information onPress={() => {
							let obj = iconsVisible.reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: false } }, {})
							obj['monthlyTransactionsVisible'] = true
							this.setState({ ...obj })
						}} visible={this.state.monthlyTransactionsVisible}
							layout={layout} text={'Allowed Monthly Transactions'} />
						<FloatingLabelInput containerStyle={{ zIndex: -1 }} keyboardType={Platform.OS !== 'web' ? 'number-pad' : 'default'} label={'Allowed Monthly Transactions'}
							value={formatInputValue(this.state.transact_limit)}
							onChangeText={text => this.setState({ transact_limit: text })} />
					</View>

					<View style={{ margin: 5 }}></View>
					<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
						<Information onPress={() => {
							let obj = iconsVisible.reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: false } }, {})
							obj['transactionFeeVisible'] = true
							this.setState({ ...obj })
						}} visible={this.state.transactionFeeVisible}
							layout={layout} text={'Transactions Fee'} />
						<FloatingLabelInput additionalSign={'$'} label={'Transactions Fee'} value={formatInputValue(this.state.transact_fee)}
							onChangeText={text => this.setState({ transact_fee: text })} />
					</View>

					<Text style={{
						fontSize: 20, marginTop: 20, alignSelf: 'flex-start', marginLeft: '10%',
						...defaultStyles.bold, color: '#7ec13d', marginBottom: 10
					}}>Savigns Account</Text>


					<FloatingLabelInput label={'Allowed Monthly Withdrawals'} value={formatInputValue(this.state.withdraw_limit)}
						onChangeText={text => this.setState({ withdraw_limit: text })} />
					<View style={{ margin: 5 }}></View>
					<FloatingLabelInput additionalSign={'$'} label={'Withdrawal Fee'} value={formatInputValue(this.state.withdraw_fee)}
						onChangeText={text => this.setState({ withdraw_fee: text })} />
					<Button styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
						title={'Save Settings'} onPress={() => {
							this.props.actions.updateSettings(parentId, childIds[id],
								{ min_balance: this.state.min_balance, monthly_fee: this.state.monthly_fee, transact_limit: this.state.transact_limit, transact_fee: this.state.transact_fee },
								{ withdraw_limit: this.state.withdraw_limit, withdraw_fee: this.state.withdraw_fee, int_rate: this.state.int_rate / 100 }
							); this.props.navigation.navigate('Dashboard')
						}} />
				</View>

			</View>}
		</View>
		)
	}
}

const styles = StyleSheet.create({

});

export default Settings;
// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
