/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import { StyleSheet, View } from 'react-native';

import Colors from '../../../constants/Colors';
import { RegularInput } from '../../RegularInput/RegularInput';
import { InfoButton } from '../../newChild/Buttons';

export const InputRow = ({
    text,
    onChangeText,
    error,
    inputParams,
    label,
    infoButtonText,
}) => {
    return (
        <View key={label} style={styles.container}>
            {/* <FloatingLabelInput
                {...inputParams}
                style={{ borderColor: error ? Colors.rose : Colors.gray }}
                value={text}
                onChangeText={onChangeText} // (text) => {}
                label={label}
            /> */}
            <RegularInput
                {...inputParams}
                style={{ borderColor: error ? Colors.rose : Colors.gray }}
                value={text}
                onChangeText={onChangeText} // (text) => {}
                label={label}
            />
            <InfoButton text={infoButtonText} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        // borderWidth: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});
