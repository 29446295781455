/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    ActivityIndicator,
    FlatList,
    Image,
    StyleSheet,
    View,
    UIManager,
    Platform,
    Dimensions,
} from 'react-native';

import { Resizable, Hoverable } from 'react-native-web-hooks';
// import DatePicker from 'react-native-datepicker'
import DatePicker from '../../components/DatePicker/index.js';
import DateTimePicker from '@react-native-community/datetimepicker';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';
import { DropdownElement } from '../../components/Dropdown';
import { ConfirmationView } from '../../components/confirmation';
import FloatingLabelInput from '../../components/FloatingLabelInput/FloatingLabelInput';
// import HeaderButton from '../../components/HeaderButton'

import { ParentHeaderCard } from '../../components/Header/HeaderBlock';

import AddJob, { WebDatepicker } from '../../components/jobs/NewJob';
import NewJobModal from '../../components/jobs/NewJobModal';

import JobCard from '../../components/jobs/JobCard';

import * as jobsActions from '../../store/actions/parents/jobsActions';
import * as authActions from '../../store/actions/parents/authActions';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import ModalContainer from '../../components/Modal/Modal';
import { eventLog } from '../../helpers/analytics/analytics';
import { IS_WEB } from '../../constants/constants.js';

const moment = require('moment');

const layout = Dimensions.get('window');

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

UIManager.setLayoutAnimationEnabledExperimental &&
    UIManager.setLayoutAnimationEnabledExperimental(true);

const buttonSize = 70;

const jobFreqs = { None: 0, Daily: 1, Weekly: 2, 'Bi-weekly': 3, Monthly: 4 };
const jobFreqsRevese = ['None', 'Daily', 'Weekly', 'Bi-weekly', 'Monthly'];

const jobCategories = {
    posted: 'data',
    pending: 'pending',
    completed: 'completed',
};

const HeaderButton = ({ title, focused, onPress, layout, theme }) => {
    const vmin = Math.min(layout.width, layout.height);
    const mainColor = theme === 'light' ? Colors.green1 : 'white';

    return (
        <TouchableNativeFeedback style={{ width: '32%' }} onPress={onPress}>
            <View
                style={{
                    // ...Platform.OS ==='android' && {width: '30%'},
                    //    transform: [{ scale: focused?1.1:1.0 }],
                    paddingVertical: 5,
                    borderRadius: 25,
                    marginVertical: 0.02 * vmin,
                    borderColor: focused ? Colors.navy : Colors.gray,
                    borderWidth: 3,
                    backgroundColor: focused ? Colors.navy : 'white',
                    alignItems: 'center',
                    // borderBottomColor:Colors.green1, borderBottomWidth:3
                }}
            >
                <Text
                    style={[
                        { color: focused ? 'white' : Colors.black },
                        { fontSize: 14 },
                    ]}
                >
                    {title}
                </Text>
            </View>
        </TouchableNativeFeedback>
    );
};

function formatInputValue(str) {
    return str || str === 0
        ? str
              .toString()
              .replace(/[^\d\.]/g, '')
              .replace(/\./, 'x')
              .replace(/\./g, '')
              .replace(/x/, '.')
        : '';
}

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1;
    var dd = this.getDate();

    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
    ].join('-');
};

function checkDate(date) {
    const today = new Date();

    return (
        new Date(date).valueOf() + today.getTimezoneOffset() * 60 * 1000 >=
        today.setHours(0, 0, 0, 0)
    );
}

const XButton = ({ onPress }) => {
    return (
        <Hoverable
            onHoverIn={() => {
                console.log('start!');
            }}
            onHoverOut={() => {
                console.log('finish!!');
            }}
        >
            {(isHovered) => (
                <TouchableNativeFeedback accessible onPress={onPress}>
                    <View
                        style={{
                            zIndex: 10,
                            transform: [{ scale: isHovered ? 1.1 : 1 }],
                            flex: 1,
                            position: 'absolute',
                            top: -10,
                            right: 10,
                            top: Platform.OS === 'web' ? 10 : 10,
                            borderRadius: 25,
                            height: 25,
                            width: 25,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Image
                            resizeMode={'contain'}
                            style={{ height: 12, width: 12 }}
                            source={require('assets/Delete-X.png')}
                        />
                    </View>
                </TouchableNativeFeedback>
            )}
        </Hoverable>
    );
};

const EditJobBlock = ({ onPress, item, title, close, ifNewDeadline }) => {
    const [amount, setAmount] = useState(item.amount);
    const [category, setCategory] = useState(item.category);
    const [description, setDescription] = useState(item.description);
    const [frequency, setFrequency] = useState(
        item.frequency ? jobFreqsRevese[item.frequency] : 'None'
    );
    const [deadline, setDeadline] = useState(new Date(item.deadline));
    const [show, setShow] = useState(false);

    const onRepeatChange = (event, selectedDate) => {
        const currentDate = new Date(selectedDate) || new Date(deadline);

        setShow(false);
        console.log('currentDate - ', currentDate);
        console.log('currentDate - ', Number.isNaN(currentDate));
        if (Number.isNaN(currentDate?.getDate()) || !currentDate) {
            setDeadline(new Date());
        } else {
            setDeadline(currentDate);
        }
    };

    console.log(
        'deadline, item.deadline - ',
        deadline,
        item.deadline,
        typeof item.deadline
    );

    return (
        <View>
            <XButton
                onPress={() => {
                    close();
                }}
            />

            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10%',
                    marginBottom: '5%',
                }}
            >
                <Text style={[{}, defaultStyles.bold]}>{title} Job</Text>
            </View>
            <View
                style={{
                    width: '100%',
                    alignSelf: 'flex-start',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <DropdownElement
                    containerStyle={{
                        zIndex: 3,
                        width: '80%',
                        alignSelf: 'center',
                    }}
                    dropdownStyle={{ width: '80%' }}
                    onPress={(val) => {
                        setCategory(val);
                    }}
                    defaultValue={category}
                    categories={[
                        'Chores',
                        'Education',
                        'Arts',
                        'Saving',
                        'Other',
                    ]}
                />
                <View
                    style={{
                        alignSelf:
                            Platform.OS !== 'web' ? 'flex-start' : 'center',
                        width: '80%',
                    }}
                >
                    <Text
                        style={{
                            alignSelf: 'flex-start',
                            marginLeft: '20%',
                            fontSize: 14,
                            color: Colors.gray1,
                        }}
                    >
                        Category
                    </Text>
                </View>
                <FloatingLabelInput
                    additionalSign={'$'}
                    label={'Payment'}
                    value={formatInputValue(amount)}
                    onChangeText={(text) => {
                        console.log('text - ', text);
                        setAmount(text);
                    }}
                />
                <FloatingLabelInput
                    label={'Description'}
                    value={description}
                    onChangeText={(text) => {
                        setDescription(text);
                    }}
                />
                {
                    Platform.OS === 'web' ? (
                        <React.Fragment>
                            <View
                                style={{
                                    width: '80%',
                                    marginTop: 10,
                                    alignItems: 'center',
                                    borderColor: Colors.gray,
                                    borderWidth: 2,
                                    borderRadius: 20,
                                    height: 40,
                                    justifyContent: 'center',
                                }}
                            >
                                <WebDatepicker
                                    jobDeadline={deadline}
                                    onChange={(e) => {
                                        setDeadline(e.target.value);
                                    }}
                                />
                                <label htmlFor="myInput">
                                    <span
                                        style={{
                                            display: deadline ? 'none' : 'flex',
                                            zIndex: -1,
                                            position: 'absolute',
                                            top: 10,
                                            left: 10,
                                            color: Colors.gray,
                                            fontFamily: 'Carmen Sans Bold',
                                        }}
                                        className="place-holder"
                                    >
                                        Enter a date
                                    </span>
                                </label>
                            </View>
                        </React.Fragment>
                    ) : (
                        // <React.Fragment><View style={{ width: '80%', marginTop: 10, alignItems: 'center', borderColor: Colors.green1, borderWidth: 2, borderRadius: 10, height: 40, justifyContent: 'center' }}>
                        // 	<input min={(new Date()).yyyymmdd()} onChange={(e) => { console.log('e', e.target.value), setDeadline(e.target.value) }}
                        // 		value={deadline}
                        // 		style={{ color: Colors.green1, width: '100%', background: 0, boxShadow: 'none', borderStyle: 'none', borderTopStyle: 'none', borderBottomStyle: 'none' }}
                        // 		type="date" placeholder="YYYY DD MM" />
                        // </View>
                        // </React.Fragment>
                        <>
                            <TouchableNativeFeedback
                                style={{
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: 15,
                                }}
                                onPress={() => setShow(true)}
                            >
                                <View
                                    style={{
                                        width: '80%',
                                        borderRadius: 20,
                                        borderWidth: 2,
                                        borderColor: Colors.gray,
                                        height: 40,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: Colors.gray1,
                                            paddingLeft: 10,
                                        }}
                                    >
                                        {deadline
                                            ? deadline.yyyymmdd()
                                            : 'Enter a date'}
                                    </Text>
                                </View>
                            </TouchableNativeFeedback>
                            {show && (
                                <DateTimePicker
                                    style={{ width: '100%' }}
                                    testID='dateTimePicker'
                                    minimumDate={new Date()}
                                    value={deadline || new Date()}
                                    mode={'date'}
                                    // is24Hour={true}
                                    display='default'
                                    onChange={onRepeatChange}
                                />
                            )}
                        </>
                    )
                    // <View style={{ width: '100%', height: 40, marginTop: 5, zIndex: 1, borderWidth: 2, borderRadius: 10, borderColor: Colors.green1 }}>
                    // 	<DatePicker
                    // 		style={{ marginLeft: 0, width: 220, }}
                    // 		date={deadline}
                    // 		mode="date" placeholder="select date" format="YYYY-MM-DD"
                    // 		minDate={(new Date()).toISOString()}
                    // 		confirmBtnText="Confirm" cancelBtnText="Cancel"
                    // 		showIcon={false}
                    // 		customStyles={{
                    // 			dateInput: { alignSelf: 'center', marginRight: '5%', borderWidth: 0 }, dateText: { color: Colors.green1 },
                    // 			// ... You can check the source to find the other keys.
                    // 		}}
                    // 		onDateChange={(date) => { this.setState({ jobDeadline: date }) }}
                    // 	/>
                    // </View>
                }
                <View
                    style={{
                        alignSelf:
                            Platform.OS !== 'web' ? 'flex-start' : 'center',
                        width: '80%',
                    }}
                >
                    <Text
                        style={{
                            alignSelf: 'flex-start',
                            marginLeft: '20%',
                            fontSize: 14,
                            color: Colors.gray1,
                        }}
                    >
                        Deadline
                    </Text>
                </View>

                <View
                    style={{
                        opacity:
                            moment(deadline)
                                .endOf('day')
                                .diff(moment(), 'days') < 0
                                ? 1
                                : 0,
                        display:
                            moment(deadline)
                                .endOf('day')
                                .diff(moment(), 'days') < 0
                                ? 'flex'
                                : 'none',
                    }}
                >
                    <Text style={{ color: Colors.red }}>
                        Please select correct date
                    </Text>
                </View>

                <DropdownElement
                    containerStyle={{
                        zIndex: 2,
                        width: '80%',
                        alignSelf: 'center',
                    }}
                    dropdownStyle={{ width: '80%' }}
                    onPress={(val) => {
                        setFrequency(val);
                    }}
                    defaultValue={frequency}
                    categories={Object.keys(jobFreqs)}
                />

                <View
                    style={{
                        alignSelf:
                            Platform.OS !== 'web' ? 'flex-start' : 'center',
                        width: '80%',
                    }}
                >
                    <Text
                        style={{
                            alignSelf: 'flex-start',
                            marginLeft: '20%',
                            fontSize: 14,
                            color: Colors.gray1,
                        }}
                    >
                        Frequency
                    </Text>
                </View>
                <View style={{ width: '100%' }}>
                    <TouchableNativeFeedback
                        onPress={() => {
                            return moment(deadline)
                                .endOf('day')
                                .diff(moment(), 'days') >= 0
                                ? item.deadline.format('YYYY-MM-DD') !==
                                      deadline && frequency !== 'None'
                                    ? ifNewDeadline({
                                          category,
                                          description,
                                          deadline,
                                          amount,
                                          frequency: jobFreqs[frequency],
                                          key: item.key,
                                      })
                                    : onPress({
                                          category,
                                          description,
                                          deadline,
                                          amount,
                                          frequency: jobFreqs[frequency],
                                      })
                                : null;
                        }}
                    >
                        <View
                            style={{
                                width: '80%',
                                alignSelf: 'center',
                                marginTop: 30,
                                marginBottom: 30,
                                backgroundColor: Colors.green1,
                                height: 40,
                                borderRadius: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text style={[{ color: 'white' }, globalStyle.h4]}>
                                {`${title} Job`.toUpperCase()}
                            </Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    );
};

class Jobs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
            header: false,
            width: 0,
            id: 0,
            confirmJob: '',
            jobCategory: 'Chores',
            jobFrequency: 'None',
            jobTitle: '',
            category: 'data',
            jobKids: {},
            jobDeadlineOld: '',
            jobStopRepeat: '',
        };
    }

    async componentDidMount() {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        eventLog({ eventName: 'jobs_screen', properties: {} });

        // this.setState({ isLoading: false })
        const category = this.props.navigation.getParam('category', null);

        if (category) {
            this.setState({ category: jobCategories[category] });
        }

        if (!this.props.auth.parentId) {
            this.props.actions.clearAuth().then(() => {
                this.props.navigation.navigate('Auth');
            });
        }

        this.props.actions
            .getJobs(parentId, childIds[id])
            .then(() => this.setState({ isLoading: false }));
    }

    componentWillUnmount() {
        this.setState({ isLoading: true });
    }

    renderItem = ({ item, index, layout }) => {
        // const { parentId, childId } = this.props.auth;
        console.log(item, index, layout);

        return (
            <JobCard
                layout={layout}
                category={this.state.category}
                openModal={() => {
                    this.setState({ confirmJobModal: true, confirmJob: item });
                }}
                removeJob={() => {
                    this.setState({
                        jobModal: true,
                        removeJob: true,
                        job: item,
                    });
                }}
                editJob={() => {
                    this.setState({
                        jobModal: true,
                        editJob: true,
                        job: item,
                        jobDeadlineOld: item.deadline,
                    });
                }}
                frequencyJob={() => {
                    this.setState({
                        jobModal: true,
                        frequencyJob: true,
                        job: item,
                    });
                }}
                isLarge={this.state.width}
                item={item}
                index={index}
            />
        );
    };

    emptyList = () => {
        return this.state.isLoading ? null : (
            <View style={styles.emptyListContainer}>
                <Text style={styles.emptyListText}>No jobs yet</Text>
            </View>
        );
    };

    getJobs1(move) {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;
        const { getJobs, changeChildIndex } = this.props.actions;
        const index = id + move;

        changeChildIndex(move);
        if (index > childIds.length - 1) {
            this.setState({ id: 0 });
            getJobs(parentId, childIds[0]);

            return 0;
        }

        if (index < 0) {
            this.setState({ id: childIds.length - 1 });
            getJobs(parentId, childIds[childIds.length - 1]);

            return 0;
        }

        this.setState({ id: index });
        getJobs(parentId, childIds[index]);

        return 0;
    }

    title = () => {
        // const { childIds, names, id } = this.props.auth;
        const { navigation, jobs, auth, parent } = this.props;
        const { childIds, id, names } = parent;

        // TODO FIX this vmin
        const vmin = Math.min(layout?.width, layout?.height);
        // const vmin = 400;

        const fontSize =
            layout.width > 1600 ? 20 : layout.width > 600 ? 18 : 15;

        return (
            <View style={{ alignItems: 'center' }}>
                {/* <ParentComponentHeader layout={layout}
			title={(names[childIds[id]] + "'s jobs").toUpperCase()}
			pressLeft={() => this.getJobs1(-1)}
			pressRight={() => this.getJobs1(+1)}
		/> */}
                <ParentHeaderCard
                    style={{ paddingBottom: 5 }}
                    name={names[childIds[id]]}
                    pressNext={() => this.getJobs1(+1)}
                    navigation={navigation}
                    signOut={() => {}}
                    vmin={vmin}
                    layout={layout}
                    clearAuth={this.props.actions.clearAuth}
                />

                <View style={styles.headerButtonContainer}>
                    <HeaderButton
                        onPress={() => {
                            navigation.setParams({ category: 'posted' });
                            this.setState({ category: 'data' });
                        }}
                        focused={this.state.category === 'data'}
                        title={'Active'}
                        layout={layout}
                    />
                    <HeaderButton
                        onPress={() => {
                            navigation.setParams({ category: 'completed' });
                            this.setState({ category: 'completed' });
                        }}
                        focused={this.state.category === 'completed'}
                        title={'Completed'}
                        layout={layout}
                    />
                    <HeaderButton
                        onPress={() => {
                            navigation.setParams({ category: 'pending' });
                            this.setState({ category: 'pending' });
                        }}
                        focused={this.state.category === 'pending'}
                        title={'Pending'}
                        layout={layout}
                    />
                </View>

                {this.state.category === 'pending' &&
                jobs[this.state.category][childIds[id]].length ? (
                    <View style={styles.approveAllContainer}>
                        <TouchableNativeFeedback
                            style={{ width: '30%' }}
                            onPress={() => {
                                this.setState({ approveAllJobs: true });
                            }}
                        >
                            <View
                                style={[
                                    styles.approveAll_layer1,
                                    { marginVertical: 0.02 * vmin },
                                ]}
                            >
                                <View
                                    style={[
                                        styles.approveAll_layer2,
                                        {
                                            paddingVertical: Math.max(
                                                8,
                                                vmin * 0.01
                                            ),
                                        },
                                    ]}
                                >
                                    <Text style={{ fontSize, color: 'white' }}>
                                        Approve all
                                    </Text>
                                </View>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                ) : null}
            </View>
        );
    };

    footer = (width, height) => {
        const { childIds, names, id } = this.props.parent;

        return this.state.isLoading ? null : (
            <View style={styles.footerContainer}>
                <TouchableNativeFeedback
                    onPress={() => {
                        this.setState({
                            addJobModal: true,
                            jobKids: childIds.reduce((curr, item) => {
                                return {
                                    ...curr,
                                    [item]: false,
                                    [childIds[id]]: true,
                                };
                            }, {}),
                        });
                    }}
                >
                    <View elevation={5} style={styles.footerButtonContainer}>
                        <Text style={[{ color: 'white' }, globalStyle.h1]}>
                            +
                        </Text>
                        {/* <Image resizeMode={'contain'} style={{ borderRadius: 55, width: 55, height: 55, }} source={require('assets/Jobs/notes_without_bg.png')} /> */}
                    </View>
                </TouchableNativeFeedback>
            </View>
        );
    };

    removeJob() {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        const { data } = this.props.jobs;

        console.log('this.state.job', this.state.job);

        return (
            <ConfirmationView
                image={require('assets/Jobs/Jobs_Nojobs.png')}
                title={'Remove Job'}
                subtitles={[this.state.job.description]}
                confirmPress={() => {
                    this.state.job.frequency
                        ? this.setState({
                              removeRecurringJob: true,
                              removeJob: false,
                              editJob: false,
                          })
                        : this.props.actions
                              .removeJob(
                                  parentId,
                                  childIds[id],
                                  this.state.job.key
                              )
                              .then(() => {
                                  this.props.actions.getJobs(
                                      parentId,
                                      childIds[id]
                                  );

                                  this.setState({
                                      jobModal: false,
                                      removeJob: false,
                                      editJob: false,
                                      job: {},
                                  });
                              });
                }}
                rejectPress={() => {
                    this.setState({
                        jobModal: false,
                        removeJob: false,
                        editJob: false,
                        job: {},
                    });
                }}
            />
        );
    }

    editJob() {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        const { description, title, category } = this.state.job;

        return (
            <EditJobBlock
                close={() => {
                    this.setState({ jobModal: false, editJob: false });
                }}
                title={'Update'}
                ifNewDeadline={(item) =>
                    this.setState({
                        job: item,
                        editFreqJob: true,
                        editJob: false,
                    })
                }
                onPress={(item) => {
                    console.log('edit item - ', item);
                    // this.state.job.deadline!==jobDeadline?console.log('YES'):console.log('NO')
                    this.props.actions
                        .updateJob(
                            parentId,
                            childIds[id],
                            this.state.job.key,
                            item
                        )
                        .then(() => {
                            this.setState({ jobModal: false, editJob: false });
                            this.props.actions.getJobs(parentId, childIds[id]);
                        });
                }}
                item={this.state.job}
            />
        );
    }

    frequencyJob() {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        console.log('this.state.job', this.state.job);

        return (
            <ConfirmationView
                image={require('assets/Jobs/Jobs_Nojobs.png')}
                title={'Frequency: ' + jobFreqsRevese[this.state.job.frequency]}
                subtitles={[
                    this.state.job.description,
                    'Remove Job Frequency?',
                ]}
                confirmPress={() => {
                    this.props.actions
                        .removeJobRepeat(parentId, childIds[id], this.state.job)
                        .then(() => {
                            this.props.actions.getJobs(parentId, childIds[id]);
                        });

                    this.setState({
                        jobModal: false,
                        frequencyJob: false,
                        removeJob: false,
                        editJob: false,
                        job: {},
                    });
                }}
                rejectPress={() => {
                    this.setState({
                        jobModal: false,
                        frequencyJob: false,
                        removeJob: false,
                        editJob: false,
                        job: {},
                    });
                }}
            />
        );
    }

    removeRecurringJob(layout) {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ marginVertical: 15, alignItems: 'center' }}>
                    <Text
                        style={[
                            {
                                margin: 5,
                                marginHorizontal: 15,
                                textAlign: 'center',
                            },
                            defaultStyles.bold,
                        ]}
                    >
                        This is a recurring task. Delete this instance or the
                        whole sequence?
                    </Text>
                </View>
                <View
                    style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginHorizontal: 15,
                    }}
                >
                    <View style={{ width: '80%' }}>
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.setState({ modalLoading: true });
                                this.props.actions
                                    .removeJobRepeat(
                                        parentId,
                                        childIds[id],
                                        this.state.job
                                    )
                                    .then(() =>
                                        this.props.actions.removeJob(
                                            parentId,
                                            childIds[id],
                                            this.state.job.key
                                        )
                                    )
                                    .then(() => {
                                        this.props.actions.getJobs(
                                            parentId,
                                            childIds[id]
                                        );

                                        this.setState({
                                            modalLoading: false,
                                            jobModal: false,
                                            removeRecurringJob: false,
                                            removeJob: false,
                                            editJob: false,
                                            job: {},
                                        });
                                    });
                            }}
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    borderWidth: 3,
                                    borderColor: Colors.green1,
                                    alignItems: 'center',
                                    height: 45,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: 'white',
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: this.buttonFontSize(layout),
                                        marginVertical: 5,
                                        ...defaultStyles.bold,
                                        color: Colors.green1,
                                        textAlign: 'center',
                                    }}
                                >
                                    {'Sequence'}
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ width: '80%' }}>
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.props.actions
                                    .removeJob(
                                        parentId,
                                        childIds[id],
                                        this.state.job.key
                                    )
                                    .then(() => {
                                        this.props.actions.getJobs(
                                            parentId,
                                            childIds[id]
                                        );

                                        this.setState({
                                            jobModal: false,
                                            removeRecurringJob: false,
                                            removeJob: false,
                                            editJob: false,
                                            job: {},
                                        });
                                    });
                            }}
                        >
                            <View
                                style={{
                                    paddingHorizontal: 7,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 45,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: Colors.green1,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: this.buttonFontSize(layout),
                                        marginVertical: 5,
                                        ...defaultStyles.bold,
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                >
                                    {'Instance'}
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </View>
            </View>
        );
    }

    buttonFontSize(layout) {
        if (layout.width < 1200) {
            return 18;
        }

        if (layout.width < 600) {
            return 15;
        }

        return 19;
    }

    editFreqJob(layout) {
        const { parentId } = this.props.auth;
        const { childIds, id } = this.props.parent;

        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ marginVertical: 15, alignItems: 'center' }}>
                    <Text style={[{ margin: 5 }, defaultStyles.bold]}>
                        This is a recurring task. Change deadline for this
                        instance only or the whole sequence?
                    </Text>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '95%',
                        justifyContent: 'center',
                        marginHorizontal: 15,
                    }}
                >
                    <View style={{ width: '50%' }}>
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.setState({ modalLoading: true });
                                this.props.actions.updateRecurringJob(
                                    parentId,
                                    childIds[id],
                                    this.state.job
                                );

                                this.props.actions
                                    .updateJob(
                                        parentId,
                                        childIds[id],
                                        this.state.job.key,
                                        this.state.job
                                    )
                                    .then(() => {
                                        this.props.actions.getJobs(
                                            parentId,
                                            childIds[id]
                                        );

                                        this.setState({
                                            modalLoading: false,
                                            editFreqJob: false,
                                            jobModal: false,
                                            removeRecurringJob: false,
                                            removeJob: false,
                                            editJob: false,
                                            job: {},
                                        });
                                    });
                            }}
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    borderWidth: 3,
                                    borderColor: Colors.green1,
                                    alignItems: 'center',
                                    height: 45,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: 'white',
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: this.buttonFontSize(layout),
                                        marginVertical: 5,
                                        ...defaultStyles.bold,
                                        color: Colors.green1,
                                        textAlign: 'center',
                                    }}
                                >
                                    {'Sequence'}
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ width: '50%' }}>
                        <TouchableNativeFeedback
                            onPress={() => {
                                this.props.actions
                                    .updateJob(
                                        parentId,
                                        childIds[id],
                                        this.state.job.key,
                                        this.state.job
                                    )
                                    .then(() => {
                                        this.props.actions.getJobs(
                                            parentId,
                                            childIds[id]
                                        );

                                        this.setState({
                                            jobModal: false,
                                            editFreqJob: false,
                                            removeRecurringJob: false,
                                            removeJob: false,
                                            editJob: false,
                                            job: {},
                                        });
                                    });
                            }}
                        >
                            <View
                                style={{
                                    paddingHorizontal: 7,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 45,
                                    borderRadius: 20,
                                    margin: 5,
                                    backgroundColor: Colors.green1,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: this.buttonFontSize(layout),
                                        marginVertical: 5,
                                        ...defaultStyles.bold,
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                >
                                    {'Instance'}
                                </Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </View>
            </View>
        );
    }

    getKidsString() {
        const { names } = this.props.parent;
        const tmp = Object.keys(this.state.jobKids)
            .filter((item) => this.state.jobKids[item])
            .map((item) => names[item]);

        return tmp.join(', ');
    }

    render() {
        const { navigation } = this.props;
        const { parentId } = this.props.auth;
        const { childIds, id, names } = this.props.parent;

        const today = new Date();

        return (
            <Resizable>
                {(layout) => {
                    return (
                        <View style={styles.container}>
                            <NewJobModal
                                parentId={parentId}
                                createJob={this.props.actions.createJob}
                                parent={this.props.parent}
                                updateDashboard={
                                    this.props.actions.getDashboardData
                                }
                                visible={this.state.addJobModal}
                                onClose={() => {
                                    this.setState({ addJobModal: false });
                                    this.props.actions.getJobs(parentId, childIds[id]);
                                }}
                                names={names}
                                layout={layout}
                            />
                            <Modal
                                animationIn={'zoomIn'}
                                animationOut={'zoomOut'}
                                animationInTiming={500}
                                animationOutTiming={500}
                                backdropColor={'darkgrey'}
                                backdropOpacity={0.6}
                                onBackdropPress={() =>
                                    this.setState({
                                        jobCategory: 'Chores',
                                        jobTitle: '',
                                        jobValue: '',
                                        repost: false,
                                        declinedJob: false,
                                        confirmJobModal: false,
                                    })
                                }
                                isVisible={this.state.confirmJobModal}
                                onRequestClose={() => {
                                    this.setState({
                                        jobCategory: 'Chores',
                                        jobTitle: '',
                                        jobValue: '',
                                        repost: false,
                                        declinedJob: false,
                                        confirmJobModal: false,
                                    });
                                }}
                            >
                                <View style={styles.jobModalContainer}>
                                    {this.state.declinedJob ? (
                                        this.state.repost ? (
                                            <AddJob
                                                layout={layout}
                                                jobCategory={
                                                    this.state.jobCategory
                                                }
                                                setJobCategory={(t) =>
                                                    this.setState({
                                                        jobCategory: t,
                                                    })
                                                }
                                                jobValue={this.state.jobValue}
                                                setJobValue={(t) =>
                                                    this.setState({
                                                        jobValue: t,
                                                    })
                                                }
                                                jobTitle={this.state.jobTitle}
                                                setJobTitle={(t) =>
                                                    this.setState({
                                                        jobTitle: t,
                                                    })
                                                }
                                                jobDeadline={
                                                    this.state.jobDeadline
                                                }
                                                setJobDeadline={(t) =>
                                                    this.setState({
                                                        jobDeadline: t,
                                                    })
                                                }
                                                jobFrequency={
                                                    this.state.jobFrequency
                                                }
                                                setJobFrequency={(t) =>
                                                    this.setState({
                                                        jobFrequency: t,
                                                    })
                                                }
                                                jobStopRepeat={
                                                    this.state.jobStopRepeat
                                                }
                                                setStopRepeat={(t) =>
                                                    this.setState({
                                                        jobStopRepeat: t,
                                                    })
                                                }
                                                kids={this.state.jobKids}
                                                names={names}
                                                setKid={(item) => {
                                                    this.setState({
                                                        jobKids: {
                                                            ...this.state
                                                                .jobKids,
                                                            [item]: !this.state
                                                                .jobKids[item],
                                                        },
                                                    });
                                                }}
                                                removeKid={(item) => {
                                                    this.setState({
                                                        jobKids: {
                                                            ...this.state
                                                                .jobKids,
                                                            [item]: false,
                                                        },
                                                    });
                                                }}
                                                checkNewJobConditions={() => {
                                                    this.state.jobValue &&
                                                    this.state.jobTitle &&
                                                    this.state.jobDeadline &&
                                                    new Date(
                                                        this.state.jobDeadline
                                                    ) >=
                                                        today.setHours(
                                                            0,
                                                            0,
                                                            0,
                                                            0
                                                        )
                                                        ? this.props.actions
                                                              .createJob(
                                                                  parentId,
                                                                  Object.keys(
                                                                      this.state
                                                                          .jobKids
                                                                  ).filter(
                                                                      (item) =>
                                                                          this
                                                                              .state
                                                                              .jobKids[
                                                                              item
                                                                          ]
                                                                  ),
                                                                  this.state
                                                                      .jobTitle,
                                                                  this.state
                                                                      .jobValue,
                                                                  this.state
                                                                      .jobDeadline,
                                                                  this.state
                                                                      .jobCategory,
                                                                  jobFreqs[
                                                                      this.state
                                                                          .jobFrequency
                                                                  ],
                                                                  this.state
                                                                      .jobStopRepeat
                                                              )
                                                              // .then(() => {this.props.actions.sendNewJobEmail(parentId, childIds[id], this.state.jobTitle, this.state.jobDeadline)})
                                                              .then(() => {
                                                                  eventLog({
                                                                      eventName:
                                                                          'job_creation',
                                                                      properties:
                                                                          {
                                                                              amount: this
                                                                                  .state
                                                                                  .jobValue,
                                                                              category:
                                                                                  this
                                                                                      .state
                                                                                      .jobCategory,
                                                                              frequency:
                                                                                  this
                                                                                      .state
                                                                                      .jobFrequency,
                                                                              repost: true,
                                                                          },
                                                                  });

                                                                  this.setState(
                                                                      {
                                                                          confirmJobModal:
                                                                              false,
                                                                          declinedJob:
                                                                              false,
                                                                          repost: false,
                                                                          jobCategory:
                                                                              'Chores',
                                                                          jobFrequency:
                                                                              'None',
                                                                          jobTitle:
                                                                              '',
                                                                          jobValue:
                                                                              '',
                                                                          jobDeadline:
                                                                              '',
                                                                      }
                                                                  );
                                                              })
                                                        : null;
                                                }}
                                            />
                                        ) : (
                                            <ConfirmationView
                                                image={require('assets/Avatars/icon_2.png')}
                                                title={
                                                    'Would you like to repost?'
                                                }
                                                subtitles={[]}
                                                confirmText={'Yes'}
                                                rejectText={'No'}
                                                confirmPress={() => {
                                                    this.setState({
                                                        jobCategory:
                                                            this.state
                                                                .confirmJob
                                                                .category,
                                                        jobTitle:
                                                            this.state
                                                                .confirmJob
                                                                .description,
                                                        jobValue:
                                                            this.state
                                                                .confirmJob
                                                                .amount,
                                                        repost: true,
                                                        jobKids:
                                                            childIds.reduce(
                                                                (
                                                                    curr,
                                                                    item
                                                                ) => {
                                                                    return {
                                                                        ...curr,
                                                                        [item]: false,
                                                                        [childIds[
                                                                            id
                                                                        ]]: true,
                                                                    };
                                                                },
                                                                {}
                                                            ),
                                                    });
                                                }}
                                                rejectPress={() => {
                                                    this.setState({
                                                        repost: false,
                                                        declinedJob: false,
                                                        confirmJobModal: false,
                                                    });
                                                }}
                                            />
                                        )
                                    ) : (
                                        <ConfirmationView
                                            image={require('assets/Avatars/icon_2.png')}
                                            title={'Approve Job'}
                                            subtitles={[
                                                this.state.confirmJob
                                                    .description,
                                            ]}
                                            confirmText={'Approve'}
                                            rejectText={'Decline'}
                                            confirmPress={() => {
                                                this.props.actions
                                                    .approveJob(
                                                        parentId,
                                                        childIds[id],
                                                        this.state.confirmJob
                                                    )
                                                    .then(() => {
                                                        this.props.actions.getJobs(
                                                            parentId,
                                                            childIds[id]
                                                        );

                                                        eventLog({
                                                            eventName:
                                                                'jobs_approve',
                                                            properties: {
                                                                amount: this
                                                                    .state
                                                                    .confirmJob
                                                                    .amount,
                                                                category:
                                                                    this.state
                                                                        .confirmJob
                                                                        .category,
                                                                frequency:
                                                                    this.state
                                                                        .confirmJob
                                                                        .frequency,
                                                            },
                                                        });
                                                    });

                                                this.setState({
                                                    confirmJobModal: false,
                                                });
                                            }}
                                            rejectPress={() => {
                                                this.props.actions
                                                    .declineJob(
                                                        parentId,
                                                        childIds[id],
                                                        this.state.confirmJob
                                                    )
                                                    .then(() => {
                                                        this.props.actions.getJobs(
                                                            parentId,
                                                            childIds[id]
                                                        );
                                                    });

                                                this.setState({
                                                    repost: false,
                                                    declinedJob: true,
                                                });
                                                // this.setState({ confirmJobModal: false })
                                            }}
                                        />
                                    )}
                                </View>
                            </Modal>
                            <Modal
                                animationIn={'zoomIn'}
                                animationOut={'zoomOut'}
                                animationInTiming={500}
                                animationOutTiming={500}
                                backdropColor={'darkgrey'}
                                backdropOpacity={0.6}
                                onBackdropPress={() =>
                                    this.setState({
                                        editFreqJob: false,
                                        modalLoading: false,
                                        removeRecurringJob: false,
                                        jobModal: false,
                                        removeJob: false,
                                        editJob: false,
                                        frequencyJob: false,
                                    })
                                }
                                isVisible={
                                    this.state.jobModal &&
                                    (this.state.editFreqJob ||
                                        this.state.removeRecurringJob ||
                                        this.state.editJob ||
                                        this.state.removeJob ||
                                        this.state.frequencyJob)
                                }
                                onRequestClose={() => {
                                    this.setState({
                                        editFreqJob: false,
                                        modalLoading: false,
                                        removeRecurringJob: false,
                                        jobModal: false,
                                        removeJob: false,
                                        editJob: false,
                                        frequencyJob: false,
                                    });
                                }}
                            >
                                <View style={styles.jobModalContainer}>
                                    {this.state.modalLoading ? (
                                        <View style={styles.modalLoading}>
                                            <ActivityIndicator
                                                color={Colors.green1}
                                            />
                                        </View>
                                    ) : null}
                                    {this.state.editJob ? this.editJob() : null}
                                    {this.state.editFreqJob
                                        ? this.editFreqJob(layout)
                                        : null}
                                    {this.state.removeJob
                                        ? this.removeJob()
                                        : null}
                                    {this.state.frequencyJob
                                        ? this.frequencyJob(layout)
                                        : null}
                                    {this.state.removeRecurringJob
                                        ? this.removeRecurringJob(layout)
                                        : null}
                                </View>
                            </Modal>

                            <Modal
                                animationIn={'zoomIn'}
                                animationOut={'zoomOut'}
                                animationInTiming={500}
                                animationOutTiming={500}
                                backdropColor={'darkgrey'}
                                backdropOpacity={0.6}
                                onBackdropPress={() =>
                                    this.setState({ approveAllJobs: false })
                                }
                                isVisible={this.state.approveAllJobs}
                                onRequestClose={() => {
                                    this.setState({
                                        approveAllJobs: false,
                                    });
                                }}
                            >
                                <View style={styles.modalContainer}>
                                    {this.state.modalLoading ? (
                                        <View style={styles.modalLoading}>
                                            <ActivityIndicator
                                                color={Colors.green1}
                                            />
                                        </View>
                                    ) : null}
                                    <ConfirmationView
                                        image={require('assets/Jobs/Jobs_Nojobs.png')}
                                        title={'Approve All Jobs'}
                                        subtitles={[]}
                                        confirmPress={() => {
                                            this.props.actions
                                                .approveAllJobs(
                                                    parentId,
                                                    childIds[id],
                                                    this.props.jobs.pending[
                                                        childIds[id]
                                                    ]
                                                )
                                                .then(() => {
                                                    this.props.actions.getJobs(
                                                        parentId,
                                                        childIds[id]
                                                    );

                                                    this.setState({
                                                        modalLoading: false,
                                                        approveAllJobs: false,
                                                        job: {},
                                                    });
                                                });
                                        }}
                                        rejectPress={() => {
                                            this.setState({
                                                modalLoading: false,
                                                approveAllJobs: false,
                                                job: {},
                                            });
                                        }}
                                    />
                                </View>
                            </Modal>

                            {this.footer(layout.width, layout.height)}
                            {/* <ScrollView contentContainerStyle={{alignItems:'center'}}> */}
                            {/* <View><Text>{JSON.stringify(this.props.jobs.data['-M09l3GmfJTXELuNSJHi'])}</Text></View> */}
                            <FlatList
                                style={styles.jobList}
                                data={
                                    this.state.isLoading
                                        ? []
                                        : this.props.jobs[this.state.category][
                                              childIds[id]
                                          ]
                                }
                                renderItem={(item) =>
                                    this.renderItem({ ...item, layout })
                                }
                                keyExtractor={(item, index) => index.toString()}
                                ListHeaderComponent={this.title}
                                // ListHeaderComponentStyle={{ marginVertical: 40, }}
                                // ListFooterComponent={() => this.footerList(layout.width)}
                                ListEmptyComponent={this.emptyList}
                            />
                            {/* </ScrollView> */}
                        </View>
                    );
                }}
            </Resizable>
        );
    }
}

const styles = StyleSheet.create({
    container: { flex: 1, backgroundColor: 'white' },
    jobModalContainer: {
        borderRadius: 15,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 250,
        justifyContent: 'center',
        paddingBottom: 15,
    },
    modalLoading: {
        zIndex: 1,
        top: 0,
        bottom: 0,
        borderRadius: 15,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(126, 193, 61, 0.5)',
        position: 'absolute',
        justifyContent: 'center',
    },
    jobList: {
        flex: 1,
        width: '100%',
        paddingBottom: 60,
    },
    emptyListContainer: { alignItems: 'center' },
    emptyListText: {
        ...globalStyle.h2,
        marginVertical: 40,
        paddingVertical: 10,
    },
    headerButtonContainer: {
        alignSelf: 'center',
        width: layout.width > 1200 ? '50%' : '90%',
        justifyContent: 'space-between',
        maxWidth: 550,
        flexDirection: 'row',
    },
    approveAllContainer: {
        alignSelf: 'center',
        width: '90%',
        justifyContent: 'center',
        maxWidth: 550,
        flexDirection: 'row',
    },
    approveAll_layer1: {
        // ...Platform.OS=='android' && {width: '30%'},
        borderRadius: 20,
        backgroundColor: 'white',
        borderColor: 'rgba(126, 193, 61, 0.5)',
        alignItems: 'center',
        // borderBottomColor:Colors.green1, borderBottomWidth:3
    },
    approveAll_layer2: {
        borderRadius: 25,
        alignItems: 'center',
        flex: 1,
        width: '100%',
        backgroundColor: 'rgba(126, 193, 61, 0.5)',
    },
    footerContainer: {
        position: 'absolute',
        bottom: 10,
        right: 0,
        left: 0,
        zIndex: 10,
        justifyContent: 'center',
        flexDirection: 'row',
    },
    footerButtonContainer: {
        // shadowColor: "#272827", shadowOffset: { width: 2, height: 2, }, shadowOpacity: 0.75, shadowRadius: 10,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: buttonSize,
        height: buttonSize,
        margin: 5,
        backgroundColor: Colors.green1,
    },
    addJobImageContainer: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
        marginTop: '5%',
    },
    addJobImage: {
        width: 95,
        height: 95,
        alignSelf: 'flex-start',
    },
});

export default connect(
    (state) => {
        return {
            auth: state.auth,
            parent: state.parent,
            jobs: state.jobs,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...jobsActions, ...authActions },
            dispatch
        ),
    })
)(Jobs);
