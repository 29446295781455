/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    Image,
    Keyboard,
    KeyboardAvoidingView,
    StyleSheet,
    TextInput,
    View,
    Platform,
    ScrollView,
    Linking,
    TouchableOpacity,
    ActivityIndicator,
} from 'react-native';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Resizable } from 'react-native-web-hooks';

import LinearGradient from 'react-native-linear-gradient';
// import * as Google from 'expo-auth-session/providers/google';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import TouchableNativeFeedback from '../components/TouchableFeedback';

import Text from '../components/CustomText';
import { ConfirmationView } from '../components/confirmation';
// import GoogleSignInButton from '../components/Auth/GoogeSignInButton';
import { AuthTextInput } from '../components/Auth/AuthTextInput';
import { AuthButton } from '../components/Auth/AuthButton';

import * as authActions from '../store/actions/authActions';
import * as parentActions from '../store/actions/parents/authActions';

import Colors from '../constants/Colors';
import config from '../constants/config';
import globalStyle, { defaultStyles } from '../constants/style';

import ModalContainer from '../components/Modal/Modal';
import { AuthContainer } from '../components/Auth/AuthContainer';
import { DEFAULT_MODAL_PROPS } from '../constants/Layout';
import { DevComponent } from '../helpers/dev/runDev';
import { Button } from '../components/Buttons';
import { createAcademyAccount, schedulePhishingEmail, sendPhishingEmail } from '../helpers/academy/accounts';

// const Constants = require('../app.json');

const Modal =
    Platform.OS === 'web'
        ? require('modal-enhanced-react-native-web').default
        : require('react-native-modal').default;

const SignUpScreen = () => {
    const parentId = useSelector(store => store.auth.parentId);

    const [individualEmail, setIndividualEmail] = useState();
    const [file, setFile] = useState(null);
    const [emailsData, setEmailsData] = useState([]);
    const [phishingEmail, setPhishingEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const onChangeEmail = (text) => {
        setIndividualEmail(text);
    }

    const onSubmitEmail = () => {
        setLoading(true);

        console.log({individualEmail, parentId})
        createAcademyAccount({email: individualEmail, parentId })
            .finally(() => {
                setIndividualEmail('');
                setLoading(false);
            })
    }

    const openFileLoad = (event) => {
        let file = event.target.files[0];
        console.log(file);

        setFile(file);
        if (file) {
          let data = new FormData();
          data.append('file', file);
            console.log(data);

          // axios.post('/files', data)...
        }
    }

    const submitMultipleEmails = () => {
        setFile();
    }

    const onSubmitPhishingEmail = () => {
        setLoading(true);
        sendPhishingEmail(phishingEmail)
            .finally(() => {
                setLoading(false);
                setPhishingEmail('');
            });
    }

    const renderIndividualAccountRegistration = () => {
        return <View style={{ width: '80%', maxWidth: 500, borderWidth: 1, borderColor: 'gray', borderRadius: 10, padding: 15}}>
            <Text>Add individual email</Text>
            <TextInput
                placeholder={'Input email'}
                value={individualEmail}
                style={{marginVertical: 10, paddingVertical: 10, paddingLeft: 20, borderRadius: 20, backgroundColor: 'white'}}
                onChangeText={onChangeEmail}
                onSubmitEditing={onSubmitEmail}
            />
            <Button title={'Submit'} onPress={onSubmitEmail} />
        </View>
    }

    const renderLoadedEmails = () => {
        return <View>
            {emailsData.slice(item => <View>{item.email}</View>)}
            <Button title={'Submit'} onPress={submitMultipleEmails} />
        </View>
    }

    const renderEmailsFile = () => {
        return <View style={{ marginVertical: 20, width: '80%', maxWidth: 500, borderWidth: 1, borderColor: 'gray', borderRadius: 10, padding: 15}}>
            <Text>Load with file</Text>
            {/* <TouchableOpacity onPress={openFileLoad}>
                <Text>{file ? 'Load file with emails' : 'Load file with emails'}</Text>
            </TouchableOpacity> */}
            <TouchableOpacity>
                <Text style={{color: 'gray', fontStyle: 'italic'}}>Template of emails file (image)</Text>
            </TouchableOpacity>
            <input type="file"
                name="myFile"
                onChange={openFileLoad}
            />
            {file ? renderLoadedEmails() : null}
        </View>
    }

    const renderManualPhishingInput = () => {
        return <View style={{ width: '80%', maxWidth: 500, borderWidth: 1, borderColor: 'gray', borderRadius: 10, padding: 15}}>
            <Text>Send manual templale phishing email</Text>
            <TextInput
                placeholder={'Input email'}
                value={phishingEmail}
                style={{marginVertical: 10, paddingVertical: 10, paddingLeft: 20, borderRadius: 20, backgroundColor: 'white'}}
                onChangeText={(text) => setPhishingEmail(text)}
                onSubmitEditing={onSubmitPhishingEmail}
            />
            <Button title={'Submit'} onPress={onSubmitPhishingEmail} />
        </View>
    }

    const renderLoading = () => {
        return loading ? <View style={{flex: 1, width: '100%', height: '100%', zIndex: 2, position: 'absolute',
        justifyContent: 'center', alignItems: 'center',
        backgroundColor: '#00000055'}}>
            <ActivityIndicator />
        </View> : null
    }

    // if (!this.state.fontsLoaded) {
    // return <AppLoading />
    // }
        return (
            <ScrollView style={{flex: 1,}} contentContainerStyle={styles.container}>
                {renderLoading()}
                <Text style={{fontSize: 22}}>Create Accounts</Text>
                {renderIndividualAccountRegistration()}
                {renderEmailsFile()}

                {/* Send  */}
                <Text style={{fontSize: 22}}>Phishing emails</Text>
                {renderManualPhishingInput()}
            </ScrollView>
        );
}

const styles = StyleSheet.create({
    container: {
       flex: 1,
       justifyContent: 'center',
       alignItems: 'center',
        // backgroundColor: Colors.transparentWhite,
    },
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
    modalContainer: {
        borderRadius: 20,
        backgroundColor: 'white',
        width: '80%',
        alignSelf: 'center',
        maxWidth: 400,
        minHeight: 100,
        paddingVertical: 15,
        justifyContent: 'center',
        ...defaultStyles.shadow,
    },
    confirmationText: {
        padding: 10,
        color: Colors.gray0,
        margin: 10,
        textAlign: 'center',
        ...globalStyle.h2,
    },
    title: {
        color: Colors.black,
        textAlign: 'center',
    },
});

export default connect(
    (state) => {
        return {
            auth: state.auth,
        };
    },
    (dispatch) => ({
        actions: bindActionCreators(
            { ...parentActions, ...authActions },
            dispatch
        ),
    })
)(SignUpScreen);
