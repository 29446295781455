import React, { Component } from 'react';
import { Animated, StyleSheet, Dimensions, Platform, } from 'react-native';


import './Stars.css';

let { height, width } = Dimensions.get('window')

export default class Star extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    shouldComponentUpdate = () => {
        return false;
    };



    render() {
        // if (__DEV__) {
        //   return null
        // }
        if (this.props.layout) {
            width = this.props.layout.width
            height = this.props.layout.height
        }
        let N = this.props.dashboard ? Math.sqrt(height * width) / 4000 : Math.sqrt(height * width) / 1000
        N = Platform.OS === 'web' ? N / 20 : N / 4
        // N = N/100

        let arr = []

        const megaHeight = this.props.dashboard ? 40 : 99

        const color = this.props.color ? { color: this.props.color } : { color: 'white' }

        // if (false) {
        arr = []
        // N = (height * width) / 6000
        N = Math.sqrt(height * width) / 10

        N = this.props.dashboard ? N / 4 : N


        let path_html = <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
        for (let i = 0; i < N; i++) {
            // let scale = Math.random() * 0.1 + 0.05;
            const left = Math.random() * 100;
            const top = Math.random() * megaHeight;
            // const rotation = Math.random() * 360;
            arr.push(<svg key={i} className="star" style={{
                ...color,
                height: (Math.random() * 10 + 5) + 'px', position: 'absolute', top: top + '%',
                right: left + '%', transform: 'rotate(' + (Math.random() * 360) + 'deg)',
                animation: "twinkle " + (Math.random() * 20 + 5) + "s infinite alternate"
            }}
                viewBox="0 0 576 512">{path_html}</svg>)

        }


        return (
            <div>
                {arr}
            </div>
            // <React.Fragment>
            // 	{arr}
            // </React.Fragment> 
        );
    }
}


// Styles
const styles = StyleSheet.create({
    snow: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
    },
});