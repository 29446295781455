/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
} from 'react-native';

import { useDimensions, useHover } from 'react-native-web-hooks';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../../components/CustomText';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';
import { IS_WEB } from '../../constants/constants';

const intervalsData = [
    { number: 1, title: 'week' },
    { number: 1, title: 'month' },
    { number: 6, title: 'months' },
    { number: 1, title: 'year' },
];

function getDate(index) {
    const newDate = new Date();

    if (index === 0) {
        newDate.setDate(newDate.getDate() - 7);
    }

    if (index === 1) {
        newDate.setMonth(newDate.getMonth() - 1);
    }

    if (index === 2) {
        newDate.setMonth(newDate.getMonth() - 6);
    }

    if (index === 3) {
        newDate.setFullYear(newDate.getFullYear() - 7);
    }

    return newDate;
}

const IntervalButton = React.forwardRef(
    ({ onPress, type, number, title, focused }, ref) => {
        const {
            window: { width, height, fontScale },
        } = useDimensions();
        const [animation, setAnimation] = useState(new Animated.Value(0));

        let scale = animation.interpolate({
            inputRange: [0, 1],
            outputRange: [1, 1.1],
        });

        return (
            <TouchableNativeFeedback
                onPress={() => {
                    onPress();
                }}
            >
                <Animated.View
                    ref={ref}
                    style={[
                        styles.intervalContainer,
                        {
                            // transform: [{ scale: scale }],
                            width: width * 0.2,
                            height: width * 0.22,
                        },
                        focused && styles.intervalFocusedContainer,
                    ]}
                >
                    <Text
                        style={[
                            { color: focused ? 'white' : Colors.black },
                            defaultStyles.bold,
                            globalStyles.h3,
                        ]}
                    >
                        {number}
                    </Text>
                    <Text
                        style={{
                            ...styles.intervalText,
                            color: focused ? 'white' : Colors.black,
                        }}
                    >
                        {title}
                    </Text>
                </Animated.View>
            </TouchableNativeFeedback>
        );
    }
);

const Intervals = ({
    onPress,
    parentId,
    childId,
    navigation,
    focused,
    setFocused,
}) => {
    // const [focused, setFocused] = useState(1);
    return (
        <View style={styles.intervalsGlobalContainer}>
            {intervalsData.map((item, index) => (
                <IntervalButton
                    key={index}
                    onPress={() => {
                        setFocused(index);
                        // setFocused(index); console.log(getDate(index), typeof getDate(index));
                        onPress(getDate(index), parentId, childId, navigation);
                    }}
                    focused={focused === index}
                    number={item.number}
                    title={item.title}
                />
            ))}
        </View>
    );
};

export default Intervals;

const styles = StyleSheet.create({
    intervalsGlobalContainer: {
        width: '90%',
        maxWidth: 600,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    intervalContainer: {
        maxHeight: 90,
        maxWidth: 85,
        borderRadius: 20,
        borderColor: Colors.gray,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 3,
        ...(IS_WEB && { boxShadow: '' }),
        backgroundColor: Colors.white,
    },
    intervalFocusedContainer: {
        borderWidth: 0,
        ...(IS_WEB && { boxShadow: defaultStyles.intervalBoxShadow }),
        backgroundColor: Colors.yellow1,
    },
    intervalText: {
        textAlign: 'center',
        width: '100%',
    },
});
