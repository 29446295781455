

export const ROUTE_NAMES = {
    DASHBOARD: 'Dashboard',
    ACADEMY: 'Academy',
    QUIZZES: 'Quizzes',
    QUIZ: 'Quiz',
    VIDEOS: 'Videos',
    VIDEO: 'VideoScreen',
    ARTICLES: 'Articles',
    ARTICLE: 'Article',
}