import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
    window: {
        width,
        height,
    },
    isSmallDevice: width < 375,
};

export const DEFAULT_MODAL_PROPS = {
    animationIn: 'zoomIn',
    animationOut: 'zoomOut',
    animationInTiming: 300,
    animationOutTiming: 300,
    backdropColor: 'darkgrey',
    backdropOpacity: 0.1,
};
